import ListingFilterBadge from "../../../../../../../common/components/listing/filter/badge/listing-filter-badge";
import BillingModel from "../../../../../../../common/types/billing-model";
import dateService from "../../../../../../../common/utils/date/date.service";
import billingsTranslationsHelper from "../../../../../../../languages/billings-translations.helper";
import billingsTaxiOrderListingHelper from "../../billings-taxi-order-listing.helper";
import BillingsTaxiOrderListingFilter, {
  BillingsTaxiOrderListingBillingModelFilter,
  BillingsTaxiOrderListingBillingStatusFilter,
  BillingsTaxiOrderListingBillingTypeFilter,
  BillingsTaxiOrderListingCargoCompanyFilter,
  BillingsTaxiOrderListingDestinationTaxiFilter,
  BillingsTaxiOrderListingExcludeCargoCompanyFilter,
  BillingsTaxiOrderListingInternalOrderIdFilter,
  BillingsTaxiOrderListingOrderStartDateFilter,
  BillingsTaxiOrderListingPassengerFilter,
  BillingsTaxiOrderListingPlanEntryHumanIdsFilter,
} from "../../types/billings-taxi-order-listing-filter";
import BillingsTaxiOrderListingFilterType from "../../types/billings-taxi-order-listing-filter-type";
import BillingsTaxiOrderListingItemBillingStatus from "../../types/billings-taxi-order-listing-item-billing-status";
import BillingsTaxiOrderListingItemBillingType from "../../types/billings-taxi-order-listing-item-billing-type";

const getStartDateBadgeOption = (
  filter: BillingsTaxiOrderListingOrderStartDateFilter
): {
  badge: ListingFilterBadge;
  filter: BillingsTaxiOrderListingOrderStartDateFilter;
} => {
  const translations =
    billingsTranslationsHelper.getTaxiOrderBillingsListingTranslations()
      .filters;

  const formattedFromDate = dateService.formatDate(filter.value.from);

  const formattedToDate = dateService.formatDate(filter.value.to);

  return {
    badge: {
      text: translations.orderStartDateFilterLabel
        .replace("#{fromDate}", formattedFromDate)
        .replace("#{toDate}", formattedToDate),
      title: translations.orderStartDateFilterTitle
        .replace("#{fromDate}", formattedFromDate)
        .replace("#{toDate}", formattedToDate),
    },
    filter: {
      type: BillingsTaxiOrderListingFilterType.ORDER_START_DATE,
      value: filter.value,
    },
  };
};

const getBillingStatusBadgeOptions = (): {
  badge: ListingFilterBadge;
  filter: BillingsTaxiOrderListingBillingStatusFilter;
}[] => {
  const translations =
    billingsTranslationsHelper.getTaxiOrderBillingsListingTranslations()
      .filters;

  return Object.values(BillingsTaxiOrderListingItemBillingStatus).map(
    (statusFilterValue) => {
      return {
        badge: {
          text: translations.billingStatusFilterLabel.replace(
            "#{option}",
            billingsTaxiOrderListingHelper.getBillingStatusText(
              statusFilterValue
            )
          ),
          title: translations.billingStatusFilterTitle.replace(
            "#{option}",
            billingsTaxiOrderListingHelper.getBillingStatusText(
              statusFilterValue
            )
          ),
        },
        filter: {
          type: BillingsTaxiOrderListingFilterType.BILLING_STATUS,
          value: statusFilterValue,
        },
      };
    }
  );
};

const getBillingTypeBadgeOptions = (): {
  badge: ListingFilterBadge;
  filter: BillingsTaxiOrderListingBillingTypeFilter;
}[] => {
  const translations =
    billingsTranslationsHelper.getTaxiOrderBillingsListingTranslations()
      .filters;

  return Object.values(BillingsTaxiOrderListingItemBillingType).map(
    (typeFilterValue) => {
      return {
        badge: {
          text: translations.billingTypeFilterLabel.replace(
            "#{option}",
            billingsTaxiOrderListingHelper.getBillingTypeText(typeFilterValue)
          ),
          title: translations.billingTypeFilterTitle.replace(
            "#{option}",
            billingsTaxiOrderListingHelper.getBillingTypeText(typeFilterValue)
          ),
        },
        filter: {
          type: BillingsTaxiOrderListingFilterType.BILLING_TYPE,
          value: typeFilterValue,
        },
      };
    }
  );
};

const getBillingModelBadgeOptions = (): {
  badge: ListingFilterBadge;
  filter: BillingsTaxiOrderListingBillingModelFilter;
}[] => {
  const translations =
    billingsTranslationsHelper.getTaxiOrderBillingsListingTranslations()
      .filters;

  return Object.values(BillingModel).map((modelFilterValue) => {
    return {
      badge: {
        text: translations.billingModelFilterLabel.replace(
          "#{option}",
          billingsTaxiOrderListingHelper.getBillingModelText(modelFilterValue)
        ),
        title: translations.billingModelFilterTitle.replace(
          "#{option}",
          billingsTaxiOrderListingHelper.getBillingModelText(modelFilterValue)
        ),
      },
      filter: {
        type: BillingsTaxiOrderListingFilterType.BILLING_MODEL,
        value: modelFilterValue,
      },
    };
  });
};

const getBadgeOptions = (filter: BillingsTaxiOrderListingFilter) => {
  const translations =
    billingsTranslationsHelper.getTaxiOrderBillingsListingTranslations()
      .filters;

  const options: {
    badge: ListingFilterBadge;
    filter: BillingsTaxiOrderListingFilter;
  }[] = [
    {
      badge: {
        text: translations.internalOrderIdFilterLabel.replace(
          "#{query}",
          String(
            filter.value as BillingsTaxiOrderListingInternalOrderIdFilter["value"]
          )
        ),
        title: translations.internalOrderIdFilterTitle.replace(
          "#{query}",
          String(
            filter.value as BillingsTaxiOrderListingInternalOrderIdFilter["value"]
          )
        ),
      },
      filter: {
        type: BillingsTaxiOrderListingFilterType.INTERNAL_ORDER_ID,
        value:
          filter.value as BillingsTaxiOrderListingInternalOrderIdFilter["value"],
      },
    },
    {
      badge: {
        text: translations.planEntryHumanIdsFilterLabel.replace(
          "#{query}",
          String(
            filter.value as BillingsTaxiOrderListingPlanEntryHumanIdsFilter["value"]
          )
        ),
        title: translations.planEntryHumanIdsFilterTitle.replace(
          "#{query}",
          String(
            filter.value as BillingsTaxiOrderListingPlanEntryHumanIdsFilter["value"]
          )
        ),
      },
      filter: {
        type: BillingsTaxiOrderListingFilterType.PLAN_ENTRY_HUMAN_IDS,
        value:
          filter.value as BillingsTaxiOrderListingPlanEntryHumanIdsFilter["value"],
      },
    },
    {
      badge: {
        text: translations.cargoCompanyFilterLabel.replace(
          "#{query}",
          String(
            filter.value as BillingsTaxiOrderListingCargoCompanyFilter["value"]
          )
        ),
        title: translations.cargoCompanyFilterTitle.replace(
          "#{query}",
          String(
            filter.value as BillingsTaxiOrderListingCargoCompanyFilter["value"]
          )
        ),
      },
      filter: {
        type: BillingsTaxiOrderListingFilterType.CARGO_COMPANY,
        value:
          filter.value as BillingsTaxiOrderListingCargoCompanyFilter["value"],
      },
    },
    {
      badge: {
        text: translations.excludeCargoCompanyFilterLabel.replace(
          "#{query}",
          String(
            filter.value as BillingsTaxiOrderListingExcludeCargoCompanyFilter["value"]
          )
        ),
        title: translations.excludeCargoCompanyFilterTitle.replace(
          "#{query}",
          String(
            filter.value as BillingsTaxiOrderListingExcludeCargoCompanyFilter["value"]
          )
        ),
      },
      filter: {
        type: BillingsTaxiOrderListingFilterType.EXCLUDE_CARGO_COMPANY,
        value:
          filter.value as BillingsTaxiOrderListingExcludeCargoCompanyFilter["value"],
      },
    },
    {
      badge: {
        text: translations.passengerFilterLabel.replace(
          "#{query}",
          String(
            filter.value as BillingsTaxiOrderListingPassengerFilter["value"]
          )
        ),
        title: translations.passengerFilterTitle.replace(
          "#{query}",
          String(
            filter.value as BillingsTaxiOrderListingPassengerFilter["value"]
          )
        ),
      },
      filter: {
        type: BillingsTaxiOrderListingFilterType.PASSENGER,
        value: filter.value as BillingsTaxiOrderListingPassengerFilter["value"],
      },
    },
    {
      badge: {
        text: translations.destinationTaxiFilterLabel.replace(
          "#{query}",
          String(
            filter.value as BillingsTaxiOrderListingDestinationTaxiFilter["value"]
          )
        ),
        title: translations.destinationTaxiFilterTitle.replace(
          "#{query}",
          String(
            filter.value as BillingsTaxiOrderListingDestinationTaxiFilter["value"]
          )
        ),
      },
      filter: {
        type: BillingsTaxiOrderListingFilterType.DESTINATION_TAXI,
        value:
          filter.value as BillingsTaxiOrderListingDestinationTaxiFilter["value"],
      },
    },
    getStartDateBadgeOption(
      filter as BillingsTaxiOrderListingOrderStartDateFilter
    ),
    ...getBillingStatusBadgeOptions(),
    ...getBillingTypeBadgeOptions(),
    ...getBillingModelBadgeOptions(),
  ];

  return options;
};

const getBadge = (filter: BillingsTaxiOrderListingFilter) => {
  const options = getBadgeOptions(filter);

  return options.find(
    (item) =>
      item.filter.type === filter.type && item.filter.value === filter.value
  )?.badge;
};

const getBadges = (
  filters: BillingsTaxiOrderListingFilter[]
): ListingFilterBadge[] => {
  const badges: ListingFilterBadge[] = [];

  filters.forEach((filter) => {
    const badge = getBadge(filter);

    if (!badge) {
      return;
    }

    badges.push(badge);
  });

  return badges;
};

const billingsTaxiOrderListingFiltersBadgeListHelper = {
  getBadges,
};

export default billingsTaxiOrderListingFiltersBadgeListHelper;
