import UserStatus from "../../../../../common/types/user-status";
import userHelper from "../../../../../common/utils/user/user.helper";
import userTranslationsHelper from "../../../../../languages/user-translations.helper";
import DriverListingTableColumn from "../common/types/driver-listing-table-column";

const getColumns = (): DriverListingTableColumn[] => {
  const translations =
    userTranslationsHelper.getDriverListingTranslations().table.headers;

  const columns: DriverListingTableColumn[] = [
    {
      header: translations.firstNameLabel,
      title: translations.firstNameTitle,
      accessor: "firstName",
      colSpan: 10,
    },
    {
      header: translations.lastNameLabel,
      title: translations.lastNameTitle,
      accessor: "lastName",
      colSpan: 10,
    },
    {
      header: translations.taxiCorporationsLabel,
      title: translations.taxiCorporationsTitle,
      accessor: "taxiCorporations",
      colSpan: 10,
    },
    {
      header: translations.startingAddressLabel,
      title: translations.startingAddressTitle,
      accessor: "startingAddress",
      colSpan: 10,
    },
    {
      header: translations.phoneNumbersLabel,
      title: translations.phoneNumbersTitle,
      accessor: "phoneNumbers",
      colSpan: 10,
    },
    {
      header: translations.vehicleLabel,
      title: translations.vehicleTitle,
      accessor: "vehicle",
      colSpan: 10,
    },
    {
      header: translations.fleetPartnersLabel,
      title: translations.fleetPartnersTitle,
      accessor: "fleetPartners",
      colSpan: 10,
    },
    {
      header: translations.createdLabel,
      title: translations.createdTitle,
      accessor: "created",
      colSpan: 20,
    },
    {
      header: translations.modifiedLabel,
      title: translations.modifiedTitle,
      accessor: "modified",
      colSpan: 20,
    },
    {
      header: translations.lastPlanEntryEndTimeLabel,
      title: translations.lastPlanEntryEndTimeTitle,
      accessor: "lastPlanEntryEndTime",
      colSpan: 15,
    },
    {
      header: translations.candidatureActivityLabel,
      title: translations.candidatureActivityTitle,
      accessor: "activeCandidature",
      colSpan: 5,
    },
    {
      header: translations.activityStatusLabel,
      title: translations.activityStatusTitle,
      accessor: "status",
      colSpan: 10,
    },
    {
      header: "",
      title: "",
      accessor: "actions",
      colSpan: 10,
    },
  ];

  return columns;
};

const getStatusText = (status: UserStatus): string | undefined => {
  return userHelper.getActivityStatusText(status);
};

const driverListingTableHelper = {
  getColumns,
  getStatusText,
};

export default driverListingTableHelper;