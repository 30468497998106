import Joi from "joi";
import BillingsTaxiUnbilledRouteListingRouteQueryFilterParams from "./types/billings-taxi-unbilled-route-listing-route-query-filter-params";
import BillingsTaxiUnbilledRouteListingRouteQueryParams from "./types/billings-taxi-unbilled-route-listing-route-query-params";
import BillingsTaxiUnbilledRouteListingSortKey from "./types/billings-taxi-unbilled-route-listing-sort-key";
import BillingsTaxiUnbilledRouteListingFilter, {
  BillingsTaxiUnbilledRouteListingRouteStartDateFilter,
  BillingsTaxiUnbilledRouteListingDriverFilter,
  BillingsTaxiUnbilledRouteListingRouteIdFilter,
  BillingsTaxiUnbilledRouteListingRouteAddressFilter,
  BillingsTaxiUnbilledRouteListingRouteDestinationAddressFilter,
  BillingsTaxiUnbilledRouteListingRouteIntermediateAddressFilter,
  BillingsTaxiUnbilledRouteListingRoutePickupAddressFilter,
  BillingsTaxiUnbilledRouteListingOrderInternalIdFilter,
  BillingsTaxiUnbilledRouteListingClientFilter,
} from "./types/billings-taxi-unbilled-route-listing-filter";
import BillingsTaxiUnbilledRouteListingFilterType from "./types/billings-taxi-unbilled-route-listing-filter-type";

const getFilters = (
  routeQueryFilterParams: BillingsTaxiUnbilledRouteListingRouteQueryFilterParams
): BillingsTaxiUnbilledRouteListingFilter[] => {
  if (!validateFilters(routeQueryFilterParams)) {
    return [];
  }

  const filters: BillingsTaxiUnbilledRouteListingFilter[] = [];

  if (
    routeQueryFilterParams.startDateFrom &&
    routeQueryFilterParams.startDateTo
  ) {
    const orderStartDateFilter: BillingsTaxiUnbilledRouteListingRouteStartDateFilter =
      {
        type: BillingsTaxiUnbilledRouteListingFilterType.ORDER_START_DATE,
        value: {
          from: new Date(routeQueryFilterParams.startDateFrom),
          to: new Date(routeQueryFilterParams.startDateTo),
        },
      };

    filters.push(orderStartDateFilter);
  }

  if (routeQueryFilterParams.client) {
    const clientFilter: BillingsTaxiUnbilledRouteListingClientFilter = {
      type: BillingsTaxiUnbilledRouteListingFilterType.CLIENT,
      value: routeQueryFilterParams.client,
    };

    filters.push(clientFilter);
  }

  if (routeQueryFilterParams.driver) {
    const driverFilter: BillingsTaxiUnbilledRouteListingDriverFilter = {
      type: BillingsTaxiUnbilledRouteListingFilterType.DRIVER,
      value: routeQueryFilterParams.driver,
    };

    filters.push(driverFilter);
  }

  if (routeQueryFilterParams.orderInternalId) {
    const orderInternalIdFilter: BillingsTaxiUnbilledRouteListingOrderInternalIdFilter =
      {
        type: BillingsTaxiUnbilledRouteListingFilterType.ORDER_INTERNAL_ID,
        value: Number(routeQueryFilterParams.orderInternalId),
      };

    filters.push(orderInternalIdFilter);
  }

  if (routeQueryFilterParams.routeAddress) {
    const routeAddressFilter: BillingsTaxiUnbilledRouteListingRouteAddressFilter = {
      type: BillingsTaxiUnbilledRouteListingFilterType.ROUTE_ADDRESS,
      value: routeQueryFilterParams.routeAddress,
    };

    filters.push(routeAddressFilter);
  }

  if (routeQueryFilterParams.routeDestinationAddress) {
    const routeDestinationFilter: BillingsTaxiUnbilledRouteListingRouteDestinationAddressFilter =
      {
        type: BillingsTaxiUnbilledRouteListingFilterType.ROUTE_DESTINATION_ADDRESS,
        value: routeQueryFilterParams.routeDestinationAddress,
      };

    filters.push(routeDestinationFilter);
  }

  if (routeQueryFilterParams.routeId) {
    const routeIdFilter: BillingsTaxiUnbilledRouteListingRouteIdFilter = {
      type: BillingsTaxiUnbilledRouteListingFilterType.ROUTE_ID,
      value: Number(routeQueryFilterParams.routeId),
    };
    filters.push(routeIdFilter);
  }

  if (routeQueryFilterParams.routeIntermediateAddress) {
    const routeIntermediateAddressFilter: BillingsTaxiUnbilledRouteListingRouteIntermediateAddressFilter =
      {
        type: BillingsTaxiUnbilledRouteListingFilterType.ROUTE_INTERMEDIATE_ADDRESS,
        value: routeQueryFilterParams.routeIntermediateAddress,
      };

    filters.push(routeIntermediateAddressFilter);
  }

  if (routeQueryFilterParams.routePickupAddress) {
    const routePickupAddressFilter: BillingsTaxiUnbilledRouteListingRoutePickupAddressFilter =
      {
        type: BillingsTaxiUnbilledRouteListingFilterType.ROUTE_PICKUP_ADDRESS,
        value: routeQueryFilterParams.routePickupAddress,
      };

    filters.push(routePickupAddressFilter);
  }

  return filters;
};

const getSortKey = (
  routeQueryParams: BillingsTaxiUnbilledRouteListingRouteQueryParams
): BillingsTaxiUnbilledRouteListingSortKey | undefined => {
  if (!validateSortKey(routeQueryParams.sort)) {
    return undefined;
  }

  return routeQueryParams.sort;
};

const createRouteQueryParams = (
  filters: BillingsTaxiUnbilledRouteListingFilter[],
  sortKey: BillingsTaxiUnbilledRouteListingSortKey | null,
  page: number,
  pageSize: number
): BillingsTaxiUnbilledRouteListingRouteQueryParams => {
  const orderInternalIdFilterValue = filters.find(
    (filter) =>
      filter.type === BillingsTaxiUnbilledRouteListingFilterType.ORDER_INTERNAL_ID
  )?.value as
    | BillingsTaxiUnbilledRouteListingOrderInternalIdFilter["value"]
    | undefined;

  return {
    page,
    pageSize,
    sort: sortKey ?? undefined,
    startDateFrom: (
      filters.find(
        (filter) =>
          filter.type === BillingsTaxiUnbilledRouteListingFilterType.ORDER_START_DATE
      )?.value as
        | BillingsTaxiUnbilledRouteListingRouteStartDateFilter["value"]
        | undefined
    )?.from.toJSON(),
    startDateTo: (
      filters.find(
        (filter) =>
          filter.type === BillingsTaxiUnbilledRouteListingFilterType.ORDER_START_DATE
      )?.value as
        | BillingsTaxiUnbilledRouteListingRouteStartDateFilter["value"]
        | undefined
    )?.to.toJSON(),
    routeId: (
      filters.find(
        (filter) => filter.type === BillingsTaxiUnbilledRouteListingFilterType.ROUTE_ID
      )?.value as BillingsTaxiUnbilledRouteListingRouteIdFilter["value"] | undefined
    )?.toString(),
    driver: filters.find(
      (filter) => filter.type === BillingsTaxiUnbilledRouteListingFilterType.DRIVER
    )?.value as BillingsTaxiUnbilledRouteListingDriverFilter["value"] | undefined,
    client: filters.find(
      (filter) => filter.type === BillingsTaxiUnbilledRouteListingFilterType.CLIENT
    )?.value as BillingsTaxiUnbilledRouteListingDriverFilter["value"] | undefined,
    routeAddress: filters.find(
      (filter) =>
        filter.type === BillingsTaxiUnbilledRouteListingFilterType.ROUTE_ADDRESS
    )?.value as
      | BillingsTaxiUnbilledRouteListingRouteAddressFilter["value"]
      | undefined,
    routeDestinationAddress: filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiUnbilledRouteListingFilterType.ROUTE_DESTINATION_ADDRESS
    )?.value as
      | BillingsTaxiUnbilledRouteListingRouteDestinationAddressFilter["value"]
      | undefined,
    routeIntermediateAddress: filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiUnbilledRouteListingFilterType.ROUTE_INTERMEDIATE_ADDRESS
    )?.value as
      | BillingsTaxiUnbilledRouteListingRouteIntermediateAddressFilter["value"]
      | undefined,
    routePickupAddress: filters.find(
      (filter) =>
        filter.type === BillingsTaxiUnbilledRouteListingFilterType.ROUTE_PICKUP_ADDRESS
    )?.value as
      | BillingsTaxiUnbilledRouteListingRoutePickupAddressFilter["value"]
      | undefined,
    orderInternalId: orderInternalIdFilterValue
      ? String(orderInternalIdFilterValue)
      : undefined,
  };
};

const getPage = (
  routeQueryFilterParams: BillingsTaxiUnbilledRouteListingRouteQueryParams
): number | undefined => {
  if (!validatePage(routeQueryFilterParams.page)) {
    return undefined;
  }

  return routeQueryFilterParams.page
    ? Number(routeQueryFilterParams.page)
    : undefined;
};

const getPageSize = (
  routeQueryFilterParams: BillingsTaxiUnbilledRouteListingRouteQueryParams
): number | undefined => {
  if (!validatePageSize(routeQueryFilterParams.pageSize)) {
    return undefined;
  }
  return routeQueryFilterParams.pageSize
    ? Number(routeQueryFilterParams.pageSize)
    : undefined;
};

const validateFilters = (
  routeQueryFilterParams: BillingsTaxiUnbilledRouteListingRouteQueryFilterParams
) => {
  const filterParams: BillingsTaxiUnbilledRouteListingRouteQueryFilterParams = {
    startDateFrom: routeQueryFilterParams.startDateFrom,
    startDateTo: routeQueryFilterParams.startDateTo,
    routeId: routeQueryFilterParams.routeId,
    driver: routeQueryFilterParams.driver,
    client: routeQueryFilterParams.client,
    routeAddress: routeQueryFilterParams.routeAddress,
    routeDestinationAddress: routeQueryFilterParams.routeDestinationAddress,
    routeIntermediateAddress: routeQueryFilterParams.routeIntermediateAddress,
    routePickupAddress: routeQueryFilterParams.routePickupAddress,
    orderInternalId: routeQueryFilterParams.orderInternalId,
  };

  const validationSchema =
    Joi.object<BillingsTaxiUnbilledRouteListingRouteQueryFilterParams>({
      startDateFrom: Joi.string(),
      startDateTo: Joi.string(),
      routeId: Joi.number(),
      driver: Joi.string(),
      client: Joi.string(),
      routeAddress: Joi.string(),
      routeDestinationAddress: Joi.string(),
      routeIntermediateAddress: Joi.string(),
      routePickupAddress: Joi.string(),
      orderInternalId: Joi.number(),
    });

  return !validationSchema.validate(filterParams).error?.message;
};

const validateSortKey = (
  routeQuerySortParam: BillingsTaxiUnbilledRouteListingRouteQueryParams["sort"]
): boolean => {
  const validationSchema = Joi.valid(
    ...Object.values(BillingsTaxiUnbilledRouteListingSortKey)
  );

  return !validationSchema.validate(routeQuerySortParam).error?.message;
};

const validatePage = (
  routeQueryPageParam: BillingsTaxiUnbilledRouteListingRouteQueryParams["page"]
) => {
  const validationSchema = Joi.number().min(1);

  return !validationSchema.validate(routeQueryPageParam).error?.message;
};

const validatePageSize = (
  routeQueryPageSizeParam: BillingsTaxiUnbilledRouteListingRouteQueryParams["pageSize"]
) => {
  const validationSchema = Joi.number().valid(...[50, 100, 200]);

  return !validationSchema.validate(routeQueryPageSizeParam).error?.message;
};

const billingsTaxiUnbilledRouteListingRouteQueryParamsService = {
  getFilters,
  getSortKey,
  getPage,
  getPageSize,
  createRouteQueryParams,
};

export default billingsTaxiUnbilledRouteListingRouteQueryParamsService;
