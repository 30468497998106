import { FC, useEffect, useMemo, useState } from "react";
import useIsComponentMounted from "../../../../common/hooks/use-is-component-mounted";
import { useAppContext } from "../../../../context/app.context";
import useRouteQueryParams from "../../../../common/hooks/use-route-query-params";
import BillingsTaxiTransferredOrderListingRouteQueryParams from "./common/types/billings-taxi-transferred-order-listing-route-query-params";
import BillingsTaxiTransferredOrderListingFilter, {
  BillingsTaxiTransferredOrderListingOrderStartDateFilter,
} from "./common/types/billings-taxi-transferred-order-listing-filter";
import billingsTaxiTransferredOrderListingRouteQueryParamsService from "./common/billings-taxi-transferred-order-listing-route-query-params.service";
import BillingsTaxiTransferredOrderListingItem from "./common/types/billings-taxi-transferred-order-listing-item";
import BillingsTaxiTransferredOrderListingSortKey from "./common/types/billings-taxi-transferred-order-listing-sort-key";
import BillingsTaxiTransferredOrderListingSortSelectOption from "./common/types/billings-taxi-transferred-order-listing-sort-select-option";
import billingsTaxiTransferredOrderListingSortHelper from "./common/helper/billings-taxi-transferred-order-listing-sort.helper";
import usePagination from "../../../../common/hooks/use-pagination";
import BillingsTaxiTransferredOrderListingResponse, {
  BillingsTaxiTransferredOrderListingResponseData,
} from "./common/api/billings-taxi-transferred-order-listing.response";
import billingsTaxiTransferredOrderListingFactory from "./common/factory/billings-taxi-transferred-order-listing.factory";
import billingsTaxiTransferredOrderListingRequestFactory from "./common/factory/billings-taxi-transferred-order-listing-request.factory";
import billingsTaxiTransferredOrderListingApiService from "./common/api/billings-taxi-transferred-order-listing-api.service";
import BillingsTaxiTransferredOrderListingFilterType from "./common/types/billings-taxi-transferred-order-listing-filter-type";
import BillingsTaxiTransferredOrderListingReportResponse, {
  BillingsTaxiTransferredOrderListingReportResponseData,
} from "./common/api/billings-taxi-transferred-order-listing-report.response";
import fileDownloadService from "../../../../common/utils/file-download/file-download.service";
import notificationService from "../../../../common/utils/notification/notification.service";
import ButtonComponent from "../../../../common/components/button/button.component";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import BillingsTaxiTransferredOrderListingFiltersSelectComponent from "./common/filters/select/billings-taxi-transferred-order-listing-filters-select.component";
import BillingsTaxiTransferredOrderListingFiltersBadgeListComponent from "./common/filters/list/billings-taxi-transferred-order-listing-filters-badge-list.component";
import CardComponent from "../../../../common/components/card/card.component";
import BillingsTaxiTransferredOrderListingTableComponent from "./common/table/billings-taxi-transferred-order-listing-table.component";
import PaginationComponent from "../../../../common/components/pagination/pagination.component";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import billingsTaxiTransferredOrderListingReportRequestFactory from "./common/factory/billings-taxi-transferred-order-listing-report-request.factory";
import DateRange from "../../../../common/types/date-range";
import DateRangeInputComponent from "../../../../common/components/form/input/date-range/date-range-input.component";
import ListingSortSelectComponent from "../../../../common/components/listing/filter/sort/select/listing-sort-select.component";
import { useBillingsTaxiTransferredOrderStatuses } from "./common/status-legend/hooks/use-billings-taxi-transferred-order-statuses.hook";
import StatusLegendComponent from "../../../../common/components/status-legend/status-legend.component";
import billingsTranslationsHelper from "../../../../languages/billings-translations.helper";
import billingBreadcrumbsHelper from "../../common/breadcrumbs/billings-breadcrumbs.helper";

type BillingsTaxiTransferredOrderListingProps = {};

const BillingsTaxiTransferredOrderListingComponent: FC<
  BillingsTaxiTransferredOrderListingProps
> = () => {
  const isComponentMounted = useIsComponentMounted();
  const { selectedAppLanguage, setBreadcrumbs } = useAppContext();

  const { user } = useAppContext();
  const { statusOptions } = useBillingsTaxiTransferredOrderStatuses();

  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<BillingsTaxiTransferredOrderListingRouteQueryParams>();

  const translations =
    billingsTranslationsHelper.getTaxiRouteTransferredOrderBillingsListingTranslations();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(
    documentTitleTranslations.billingsTaxiTransferredOrderListing
  );

  const [filters, setFilters] = useState<
    BillingsTaxiTransferredOrderListingFilter[]
  >(() =>
    billingsTaxiTransferredOrderListingRouteQueryParamsService.getFilters(
      routeQueryParams
    )
  );

  const [isListingFetching, setIsListingFetching] = useState(false);
  const [isListingFetchingError, setIsListingFetchingError] = useState(false);
  const [listingItems, setListingItems] = useState<
    BillingsTaxiTransferredOrderListingItem[]
  >([]);
  const [totalResults, setTotalResults] = useState(0);

  const [isReportFetching, setIsReportFetching] = useState(false);

  const [selectedSortKey, setSelectedSortKey] =
    useState<BillingsTaxiTransferredOrderListingSortKey | null>(
      () =>
        billingsTaxiTransferredOrderListingRouteQueryParamsService.getSortKey(
          routeQueryParams
        ) ?? null
    );

  const sortSelectOptions: BillingsTaxiTransferredOrderListingSortSelectOption[] =
    useMemo(
      () => billingsTaxiTransferredOrderListingSortHelper.getSelectOptions(),
      [selectedAppLanguage]
    );

  const { page, pageSize, setPage, setPageSize } = usePagination({
    totalResults: totalResults,
    defaultPageSize:
      billingsTaxiTransferredOrderListingRouteQueryParamsService.getPageSize(
        routeQueryParams
      ),
    defaultPage:
      billingsTaxiTransferredOrderListingRouteQueryParamsService.getPage(
        routeQueryParams
      ),
  });

  const onListingFetchSuccess = (
    response: BillingsTaxiTransferredOrderListingResponseData
  ) => {
    const listingItems =
      billingsTaxiTransferredOrderListingFactory.createListingItems(
        response.data
      );

    setListingItems(listingItems);
    setTotalResults(response.total_count);
  };

  const onListingFetchFailure = () => {
    setIsListingFetchingError(true);
  };

  const handleListingResponse = (
    response: BillingsTaxiTransferredOrderListingResponse
  ) => {
    if (response.status === 200) {
      onListingFetchSuccess(response.data);
      return;
    }

    onListingFetchFailure();
  };

  const fetchListing = () => {
    setIsListingFetching(true);
    setIsListingFetchingError(false);

    const taxiCorporationId = user?.aspects.taxiOfficer?.taxiCorporationUuid;

    const request =
      billingsTaxiTransferredOrderListingRequestFactory.createRequest(
        taxiCorporationId,
        page,
        pageSize,
        filters,
        selectedSortKey
      );

    billingsTaxiTransferredOrderListingApiService
      .fetchListing(request)
      .then(handleListingResponse)
      .catch(onListingFetchFailure)
      .finally(() => setIsListingFetching(false));
  };

  useEffect(() => {
    const breadcrumbs =
      billingBreadcrumbsHelper.getTaxiTransferredOrderListingBreadcrumbs();
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  useEffect(() => {
    if (!page || !pageSize) {
      return;
    }

    fetchListing();
  }, [filters, selectedSortKey, page, pageSize]);

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    const queryParams =
      billingsTaxiTransferredOrderListingRouteQueryParamsService.createRouteQueryParams(
        filters,
        selectedSortKey,
        page,
        pageSize
      );

    setRouteQueryParams(queryParams);
  }, [filters, selectedSortKey, page, pageSize]);

  const addNewFilter = (
    newFilter: BillingsTaxiTransferredOrderListingFilter
  ) => {
    setFilters((curr) => [...curr, newFilter]);
    setPage(1);
  };

  const deleteFilter = (index: number) => {
    const newFilters = filters.filter((filter, _index) => _index !== index);

    setFilters(newFilters);
    setPage(1);
  };

  const deleteAllFilters = () => {
    setFilters([]);
    setPage(1);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    window.scroll({ top: 0, behavior: "smooth" });
  };

  const onPageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
    setPage(1);
  };

  const selectedSortSelectOption = useMemo(() => {
    return (
      sortSelectOptions.find((item) => item.value === selectedSortKey) ?? null
    );
  }, [selectedSortKey]);

  const startDateFilterValue = filters.find(
    (filter) =>
      filter.type ===
      BillingsTaxiTransferredOrderListingFilterType.ORDER_START_DATE
  )?.value as
    | BillingsTaxiTransferredOrderListingOrderStartDateFilter["value"]
    | undefined;

  const onOrderStartDateFilterValueChange = (dateRange: DateRange | null) => {
    if (!dateRange) {
      const newFilters = filters.filter(
        (filter) =>
          filter.type !==
          BillingsTaxiTransferredOrderListingFilterType.ORDER_START_DATE
      );

      setFilters(newFilters);
      return;
    }

    const isFilterExists = !!filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiTransferredOrderListingFilterType.ORDER_START_DATE
    );

    const newFilter: BillingsTaxiTransferredOrderListingOrderStartDateFilter = {
      type: BillingsTaxiTransferredOrderListingFilterType.ORDER_START_DATE,
      value: {
        from: dateRange.from!,
        to: dateRange.to!,
      },
    };

    if (isFilterExists) {
      const newFilters = [
        ...filters.filter(
          (filter) =>
            filter.type !==
            BillingsTaxiTransferredOrderListingFilterType.ORDER_START_DATE
        ),
        newFilter,
      ];

      setFilters(newFilters);
      setPage(1);
      return;
    }

    setFilters((curr) => [...curr, newFilter]);
    setPage(1);
  };

  const downloadReportFile = (
    responseData: BillingsTaxiTransferredOrderListingReportResponseData
  ) => {
    fileDownloadService.downloadFromBlob(
      responseData.data,
      responseData.filename
    );
  };

  const onReportFetchSuccess = async (
    response: BillingsTaxiTransferredOrderListingReportResponse
  ) => {
    if (response.status === 200) {
      downloadReportFile(response.data);
      return;
    }

    onReportFetchFailure();
  };

  const onReportFetchFailure = () => {
    notificationService.error(
      translations.report.failureDownloadingNotificationText
    );
  };

  const fetchReport = () => {
    setIsReportFetching(true);

    const taxiCorporationId = user?.aspects.taxiOfficer?.taxiCorporationUuid;

    const request =
      billingsTaxiTransferredOrderListingReportRequestFactory.createRequest(
        taxiCorporationId,
        filters,
        selectedSortKey
      );

    billingsTaxiTransferredOrderListingApiService
      .fetchReport(request)
      .then(onReportFetchSuccess)
      .catch(onReportFetchFailure)
      .finally(() => setIsReportFetching(false));
  };

  const onReportDownloadButtonClick = () => {
    fetchReport();
  };

  const ReportDownloadButton = (
    <ButtonComponent
      onClick={onReportDownloadButtonClick}
      type="primary"
      isLoading={isReportFetching}
      title={translations.report.downloadButtonTitle}
      idForTesting={`billings-taxi-transferred-order-listing-report-download-button`}
    >
      {translations.report.downloadButtonText}
    </ButtonComponent>
  );

  return (
    <div className="billings_taxi_transferred_order_listing">
      <HeadingComponent
        title={translations.header.headingText}
        actions={[ReportDownloadButton]}
      />
      <StatusLegendComponent statusData={statusOptions} />
      <>
        <div className="billings_taxi_transferred_order_listing_tools">
          <div className="d-flex">
            <BillingsTaxiTransferredOrderListingFiltersSelectComponent
              filters={filters}
              onAddNewFilter={addNewFilter}
            />
            <DateRangeInputComponent
              date={startDateFilterValue ?? null}
              onChange={onOrderStartDateFilterValueChange}
              classNames={{ root: "ml-2" }}
              placeholder={
                translations.filters.searchByStartDateSelectInputPlaceholder
              }
              idForTesting={`billings-taxi-transferred-order-listing-date-range`}
            />
          </div>
          <ListingSortSelectComponent
            onChange={(option) =>
              setSelectedSortKey(
                (
                  option as BillingsTaxiTransferredOrderListingSortSelectOption | null
                )?.value ?? null
              )
            }
            options={sortSelectOptions}
            value={selectedSortSelectOption}
            idForTesting={`billings-taxi-transferred-order-listing-sort`}
          />
        </div>
        <BillingsTaxiTransferredOrderListingFiltersBadgeListComponent
          filters={filters}
          onDeleteFilterClick={deleteFilter}
          onDeleteAllFiltersButtonClick={deleteAllFilters}
        />
        <CardComponent classNames={{ root: "mt-4" }}>
          <BillingsTaxiTransferredOrderListingTableComponent
            listingItems={listingItems}
            isError={isListingFetchingError}
            isLoading={isListingFetching}
          />
          <div className="billings_taxi_transferred_order_listing__pagination_wrapper">
            <PaginationComponent
              onPageChange={onPageChange}
              onPageSizeChange={onPageSizeChange}
              page={page}
              pageSize={pageSize}
              totalResults={totalResults}
            />
          </div>
        </CardComponent>
      </>
    </div>
  );
};

export default BillingsTaxiTransferredOrderListingComponent;
