import OrderAbandonedListingRouteParams from "../../common/routes/types/order-abandones-listing.route-params";
import OrderAbandonedListingFilter, {
  OrderAbandonedListingAddressFilter,
  OrderAbandonedListingClientFilter,
  OrderAbandonedListingDispatchFilter,
  OrderAbandonedListingEndAddressFilter,
  OrderAbandonedListingExternalOrderIdFilter,
  OrderAbandonedListingInternalOrderIdFilter,
  OrderAbandonedListingMidAddressFilter,
  OrderAbandonedListingPassengerFilter,
  OrderAbandonedListingStartAddressFilter,
  OrderAbandonedListingStartTimeFilter,
} from "../common/types/order-abandoned-listing-filter";
import OrderAbandonedListingFilterType from "../common/types/order-abandoned-listing-filter-type";
import OrderAbandonedListingSortKey from "../common/types/order-abandoned-listing-sort-key";

const getSortKey = (
  routeQueryParams: OrderAbandonedListingRouteParams
): OrderAbandonedListingSortKey | undefined => {
  return routeQueryParams.sort as OrderAbandonedListingSortKey | undefined;
};

const getPage = (
  routeQueryParams: OrderAbandonedListingRouteParams
): number | undefined => {
  return routeQueryParams.page ? Number(routeQueryParams.page) : undefined;
};

const getPageSize = (
  routeQueryParams: OrderAbandonedListingRouteParams
): number | undefined => {
  return routeQueryParams.pageSize
    ? Number(routeQueryParams.pageSize)
    : undefined;
};

const getFilters = (
  routeQueryParams: OrderAbandonedListingRouteParams
): OrderAbandonedListingFilter[] => {
  const filters: OrderAbandonedListingFilter[] = [];

  if (routeQueryParams.address) {
    const addressFilter: OrderAbandonedListingAddressFilter = {
      type: OrderAbandonedListingFilterType.ADDRESS,
      value: routeQueryParams.address,
    };

    filters.push(addressFilter);
  }

  if (routeQueryParams.client) {
    const clientFilter: OrderAbandonedListingClientFilter = {
      type: OrderAbandonedListingFilterType.CLIENT,
      value: routeQueryParams.client,
    };

    filters.push(clientFilter);
  }

  if (routeQueryParams.dispatch) {
    const dispatchFilter: OrderAbandonedListingDispatchFilter = {
      type: OrderAbandonedListingFilterType.DISPATCH,
      value: routeQueryParams.dispatch,
    };

    filters.push(dispatchFilter);
  }

  if (routeQueryParams.endAddress) {
    const endAddressFilter: OrderAbandonedListingEndAddressFilter = {
      type: OrderAbandonedListingFilterType.END_ADDRESS,
      value: routeQueryParams.endAddress,
    };

    filters.push(endAddressFilter);
  }

  if (routeQueryParams.externalId) {
    const externalIdFilter: OrderAbandonedListingExternalOrderIdFilter = {
      type: OrderAbandonedListingFilterType.EXTERNAL_ORDER_ID,
      value: Number(routeQueryParams.externalId),
    };

    filters.push(externalIdFilter);
  }

  if (routeQueryParams.internalId) {
    const internalIdFilter: OrderAbandonedListingInternalOrderIdFilter = {
      type: OrderAbandonedListingFilterType.INTERNAL_ORDER_ID,
      value: Number(routeQueryParams.internalId),
    };

    filters.push(internalIdFilter);
  }

  if (routeQueryParams.midAddress) {
    const midAddressFilter: OrderAbandonedListingMidAddressFilter = {
      type: OrderAbandonedListingFilterType.MID_ADDRESS,
      value: routeQueryParams.midAddress,
    };

    filters.push(midAddressFilter);
  }

  if (routeQueryParams.passenger) {
    const passengerFilter: OrderAbandonedListingPassengerFilter = {
      type: OrderAbandonedListingFilterType.PASSENGER,
      value: routeQueryParams.passenger,
    };

    filters.push(passengerFilter);
  }

  if (routeQueryParams.startAddress) {
    const startAddressFilter: OrderAbandonedListingStartAddressFilter = {
      type: OrderAbandonedListingFilterType.START_ADDRESS,
      value: routeQueryParams.startAddress,
    };

    filters.push(startAddressFilter);
  }

  if (routeQueryParams.startTimeFrom && routeQueryParams.startTimeTo) {
    const startTimeFilter: OrderAbandonedListingStartTimeFilter = {
      type: OrderAbandonedListingFilterType.START_TIME,
      value: {
        from: new Date(routeQueryParams.startTimeFrom),
        to: new Date(routeQueryParams.startTimeTo),
      },
    };

    filters.push(startTimeFilter);
  }

  return filters;
};

const createRouteQueryParams = (
  filters: OrderAbandonedListingFilter[],
  page: number,
  pageSize: number,
  sortKey: OrderAbandonedListingSortKey | null
): OrderAbandonedListingRouteParams => {
  const startTimeFilterValue = filters.find(
    (filter) => filter.type === OrderAbandonedListingFilterType.START_TIME
  )?.value as OrderAbandonedListingStartTimeFilter["value"] | undefined;

  return {
    page: String(page),
    pageSize: String(pageSize),
    sort: sortKey ?? undefined,
    address: filters.find(
      (filter) => filter.type === OrderAbandonedListingFilterType.ADDRESS
    )?.value as OrderAbandonedListingAddressFilter["value"] | undefined,
    client: filters.find(
      (filter) => filter.type === OrderAbandonedListingFilterType.CLIENT
    )?.value as OrderAbandonedListingClientFilter["value"] | undefined,
    dispatch: filters.find(
      (filter) => filter.type === OrderAbandonedListingFilterType.DISPATCH
    )?.value as OrderAbandonedListingDispatchFilter["value"] | undefined,
    endAddress: filters.find(
      (filter) => filter.type == OrderAbandonedListingFilterType.END_ADDRESS
    )?.value as OrderAbandonedListingEndAddressFilter["value"] | undefined,
    externalId: filters
      .find(
        (filter) =>
          filter.type === OrderAbandonedListingFilterType.EXTERNAL_ORDER_ID
      )
      ?.value.toString(),
    internalId: filters
      .find(
        (filter) =>
          filter.type === OrderAbandonedListingFilterType.INTERNAL_ORDER_ID
      )
      ?.value.toString(),
    midAddress: filters.find(
      (filter) => filter.type === OrderAbandonedListingFilterType.MID_ADDRESS
    )?.value as OrderAbandonedListingMidAddressFilter["value"] | undefined,
    passenger: filters.find(
      (filter) => filter.type === OrderAbandonedListingFilterType.PASSENGER
    )?.value as OrderAbandonedListingPassengerFilter["value"] | undefined,
    startAddress: filters.find(
      (filter) => filter.type === OrderAbandonedListingFilterType.START_ADDRESS
    )?.value as OrderAbandonedListingStartAddressFilter["value"] | undefined,
    startTimeFrom: startTimeFilterValue?.from.toJSON(),
    startTimeTo: startTimeFilterValue?.to.toJSON(),
  };
};

const orderAbandonedListingFiltersService = {
  getSortKey,
  getPage,
  getPageSize,
  getFilters,
  createRouteQueryParams,
};

export default orderAbandonedListingFiltersService;
