import billingsTranslationsHelper from "../../../../../../languages/billings-translations.helper";
import BillingsTaxiTransferredOrderListingSortKey from "../types/billings-taxi-transferred-order-listing-sort-key";
import BillingsTaxiTransferredOrderListingSortSelectOption from "../types/billings-taxi-transferred-order-listing-sort-select-option";

const getSelectOptions =
  (): BillingsTaxiTransferredOrderListingSortSelectOption[] => {
    const translations =
      billingsTranslationsHelper.getTaxiRouteTransferredOrderBillingsListingTranslations()
        .sort;

    return [
      {
        label: translations.orderStartDateAscOptionLabel,
        value: BillingsTaxiTransferredOrderListingSortKey.ORDER_START_DATE_ASC,
      },
      {
        label: translations.orderStartDateDescOptionLabel,
        value: BillingsTaxiTransferredOrderListingSortKey.ORDER_START_DATE_DESC,
      },
      {
        label: translations.internalOrderIdAscOptionLabel,
        value: BillingsTaxiTransferredOrderListingSortKey.INTERNAL_ORDER_ID_ASC,
      },
      {
        label: translations.internalOrderIdDescOptionLabel,
        value:
          BillingsTaxiTransferredOrderListingSortKey.INTERNAL_ORDER_ID_DESC,
      },
      {
        label: translations.producerAscOptionLabel,
        value: BillingsTaxiTransferredOrderListingSortKey.PRODUCER_ASC,
      },
      {
        label: translations.producerDescOptionLabel,
        value: BillingsTaxiTransferredOrderListingSortKey.PRODUCER_DESC,
      },
      {
        label: translations.consumerAscOptionLabel,
        value: BillingsTaxiTransferredOrderListingSortKey.CONSUMER_ASC,
      },
      {
        label: translations.consumerDescOptionLabel,
        value: BillingsTaxiTransferredOrderListingSortKey.CONSUMER_DESC,
      },
      {
        label: translations.billingModelAscOptionLabel,
        value: BillingsTaxiTransferredOrderListingSortKey.BILLING_MODEL_ASC,
      },
      {
        label: translations.billingModelDescOptionLabel,
        value: BillingsTaxiTransferredOrderListingSortKey.BILLING_MODEL_DESC,
      },
      {
        label: translations.distanceAscOptionLabel,
        value: BillingsTaxiTransferredOrderListingSortKey.DISTANCE_ASC,
      },
      {
        label: translations.distanceDescOptionLabel,
        value: BillingsTaxiTransferredOrderListingSortKey.DISTANCE_DESC,
      },
      {
        label: translations.baseDistanceRateAscOptionLabel,
        value: BillingsTaxiTransferredOrderListingSortKey.DISTANCE_RATE_ASC,
      },
      {
        label: translations.baseDistanceRateDescOptionLabel,
        value: BillingsTaxiTransferredOrderListingSortKey.DISTANCE_RATE_DESC,
      },
      {
        label: translations.amountForDistanceAscOptionLabel,
        value: BillingsTaxiTransferredOrderListingSortKey.DISTANCE_COST_ASC,
      },
      {
        label: translations.amountForDistanceDescOptionLabel,
        value: BillingsTaxiTransferredOrderListingSortKey.DISTANCE_COST_DESC,
      },
      {
        label: translations.stopoverCostAscOptionLabel,
        value: BillingsTaxiTransferredOrderListingSortKey.STOPOVER_COST_ASC,
      },
      {
        label: translations.stopoverCostDescOptionLabel,
        value: BillingsTaxiTransferredOrderListingSortKey.STOPOVER_COST_DESC,
      },
      {
        label: translations.tollRoadsCostAscOptionLabel,
        value: BillingsTaxiTransferredOrderListingSortKey.TOLL_ROADS_COST_ASC,
      },
      {
        label: translations.tollRoadsCostDescOptionLabel,
        value: BillingsTaxiTransferredOrderListingSortKey.TOLL_ROADS_COST_DESC,
      },
    ];
  };

const billingsTaxiTransferredOrderListingSortHelper = {
  getSelectOptions,
};

export default billingsTaxiTransferredOrderListingSortHelper;
