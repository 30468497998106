import OrderOptimizerDetailsResponse, {
  OrderOptimizerDetailsResponseItemDriver,
  OrderOptimizerDetailsResponseItemOptimizedStats,
  OrderOptimizerDetailsResponseItemOrders,
  OrderOptimizerDetailsResponseItemOrdersByDriverAba,
  OrderOptimizerDetailsResponseItemOrdersByDriverSabs,
  OrderOptimizerDetailsResponseItemSteps,
  OrderOptimizerDetailsResponseItemStepsEndToHome,
  OrderOptimizerDetailsResponseItemStepsHomeToStart,
  OrderOptimizerDetailsResponseItemStepsOrders,
  OrderOptimizerDetailsResponseItemStepsOrdersPoints,
  OrderOptimizerDetailsResponseItemUnoptimizedStats,
} from "../../../api/raily/order-optimizer/details/order-optimizer-details.response";
import OrderOptimizerDetails, {
  OrderOptimizerDetailsDriver,
  OrderOptimizerDetailsOptimizedStats,
  OrderOptimizerDetailsOrders,
  OrderOptimizerDetailsOrdersByDriverAba,
  OrderOptimizerDetailsOrdersByDriverSabs,
  OrderOptimizerDetailsSteps,
  OrderOptimizerDetailsStepsEndToHome,
  OrderOptimizerDetailsStepsHomeToStart,
  OrderOptimizerDetailsStepsOrders,
  OrderOptimizerDetailsStepsOrdersPoints,
  OrderOptimizerDetailsUnoptimizedStats,
} from "./order-optimizer-details";

const createStepsEndToHome = (
  stepsEndToHome: OrderOptimizerDetailsResponseItemStepsEndToHome
): OrderOptimizerDetailsStepsEndToHome => {
  return {
    displayName: stepsEndToHome.displayName,
    driverDistance: stepsEndToHome.driverDistance,
    driverDrivingTime: stepsEndToHome.driverDrivingTime,
    driverTime: stepsEndToHome.driverTime,
  };
};

const createStepsOrderPoint = (
  stepsOrderPoint: OrderOptimizerDetailsResponseItemStepsOrdersPoints
): OrderOptimizerDetailsStepsOrdersPoints => ({
  displayName: stepsOrderPoint.displayName,
  distance: stepsOrderPoint.distance,
  driverDistance: stepsOrderPoint.driverDistance,
  driverDrivingTime: stepsOrderPoint.driverDrivingTime,
  driverHaltingTime: stepsOrderPoint.driverHaltingTime,
  driverTime: stepsOrderPoint.driverTime,
  haltingTime: stepsOrderPoint.haltingTime,
  lat: stepsOrderPoint.lat,
  lon: stepsOrderPoint.lon,
  time: stepsOrderPoint.time,
});

const createStepsOrderPoints = (
  stepsOrderPoints: OrderOptimizerDetailsResponseItemStepsOrdersPoints[]
): OrderOptimizerDetailsStepsOrdersPoints[] => {
  return stepsOrderPoints.map(createStepsOrderPoint);
};

const createStepsOrder = (
  stepsOrder: OrderOptimizerDetailsResponseItemStepsOrders
): OrderOptimizerDetailsStepsOrders => ({
  distance: stepsOrder.distance,
  haltingTime: stepsOrder.haltingTime,
  points: createStepsOrderPoints(stepsOrder.points),
  totalTime: stepsOrder.totalTime,
});

const createStepsOrders = (
  stepsOrders: OrderOptimizerDetailsResponseItemStepsOrders[]
): OrderOptimizerDetailsStepsOrders[] => {
  return stepsOrders.map(createStepsOrder);
};

const createStepsHomeToStart = (
  stepsHomeToStart: OrderOptimizerDetailsResponseItemStepsHomeToStart
): OrderOptimizerDetailsStepsHomeToStart => {
  return {
    displayName: stepsHomeToStart.displayName,
    driverTime: stepsHomeToStart.driverTime,
  };
};

const createSteps = (
  steps: OrderOptimizerDetailsResponseItemSteps
): OrderOptimizerDetailsSteps => {
  return {
    endToHome: createStepsEndToHome(steps.endToHome),
    homeToStart: createStepsHomeToStart(steps.homeToStart),
    orders: createStepsOrders(steps.orders),
  };
};

const createOrdersBySabs = (
  ordersBySabs: OrderOptimizerDetailsResponseItemOrdersByDriverSabs
): OrderOptimizerDetailsOrdersByDriverSabs => {
  return {
    distance: ordersBySabs.distance,
    haltingTime: ordersBySabs.haltingTime,
    margin: ordersBySabs.margin,
    totalTime: ordersBySabs.totalTime,
  };
};

const createOrdersByAba = (
  ordersByABa: OrderOptimizerDetailsResponseItemOrdersByDriverAba
): OrderOptimizerDetailsOrdersByDriverAba => {
  return {
    distance: ordersByABa.distance,
    haltingTime: ordersByABa.haltingTime,
    margin: ordersByABa.margin,
    totalTime: ordersByABa.totalTime,
  };
};

const createOrder = (
  order: OrderOptimizerDetailsResponseItemOrders
): OrderOptimizerDetailsOrders => ({
  billingModel: order.billingModel,
  cargoCompany: order.cargoCompany,
  distance: order.distance,
  driverName: order.driverName,
  fleetPartner: order.fleetPartner,
  haltingTime: order.haltingTime,
  humanId: order.humanId,
  planEntryHumanId: order.planEntryHumanId,
  startTime: order.startTime,
  taxiCorporation: order.taxiCorporation,
  totalTime: order.totalTime,
});

const createOrders = (
  orders: OrderOptimizerDetailsResponseItemOrders[]
): OrderOptimizerDetailsOrders[] => {
  return orders.map(createOrder);
};

const createOptimizedStats = (
  optimizedStats: OrderOptimizerDetailsResponseItemOptimizedStats
): OrderOptimizerDetailsOptimizedStats => {
  return {
    contractRate: optimizedStats.contractRate,
    distance: optimizedStats.distance,
    haltingTime: optimizedStats.haltingTime,
    margin: optimizedStats.margin,
    totalTime: optimizedStats.totalTime,
  };
};

const createUnoptimizedStats = (
  optimizedStats: OrderOptimizerDetailsResponseItemUnoptimizedStats
): OrderOptimizerDetailsUnoptimizedStats => {
  return {
    contractRate: optimizedStats.contractRate,
    distance: optimizedStats.distance,
    haltingTime: optimizedStats.haltingTime,
    margin: optimizedStats.margin,
    totalTime: optimizedStats.totalTime,
  };
};

const createDriver = (
  driver: OrderOptimizerDetailsResponseItemDriver
): OrderOptimizerDetailsDriver => {
  return {
    contractRate: driver.contractRate,
    displayName: driver.displayName,
    driverId: driver.driverId,
    startLat: driver.startLat,
    startLon: driver.startLon,
    taxiCorporation: driver.taxiCorporation,
  };
};

const create = (
  response: OrderOptimizerDetailsResponse
): OrderOptimizerDetails => {
  return {
    driver: createDriver(response.data.driver),
    optimizedStats: createOptimizedStats(response.data.optimizedStats),
    orders: createOrders(response.data.orders),
    ordersByDriverAba: createOrdersByAba(response.data.ordersByDriverAba),
    ordersByDriverSabs: createOrdersBySabs(response.data.ordersByDriverSabs),
    reviewedAt: response.data.reviewedAt,
    reviewedBy: response.data.reviewedBy,
    saAndBsDistance: response.data.saAndBsDistance,
    steps: createSteps(response.data.steps),
    unoptimizedStats: createUnoptimizedStats(response.data.unoptimizedStats),
    score: response.data.score,
  };
};

const orderOptimizerDetailsFactory = {
  create,
};

export default orderOptimizerDetailsFactory;
