import { FC, useEffect, useMemo, useState } from "react";
import useIsComponentMounted from "../../../../../common/hooks/use-is-component-mounted";
import useRouteQueryParams from "../../../../../common/hooks/use-route-query-params";
import usePagination from "../../../../../common/hooks/use-pagination";
import HeadingComponent from "../../../../../common/components/heading/heading.component";
import ListingSortSelectComponent from "../../../../../common/components/listing/filter/sort/select/listing-sort-select.component";
import CardComponent from "../../../../../common/components/card/card.component";
import PaginationComponent from "../../../../../common/components/pagination/pagination.component";
import DateRange from "../../../../../common/types/date-range";
import FormFieldComponent from "../../../../../common/components/form/field/form-field.component";
import { useAppContext } from "../../../../../context/app.context";
import fileDownloadService from "../../../../../common/utils/file-download/file-download.service";
import notificationService from "../../../../../common/utils/notification/notification.service";
import ButtonComponent from "../../../../../common/components/button/button.component";
import DateRangeInputComponent from "../../../../../common/components/form/input/date-range/date-range-input.component";
import SingleSelectComponent from "../../../../../common/components/form/select/single-select/single-select.component";
import BillingsTaxiRouteListingViewBasicProps from "../common/types/billings-taxi-route-listing-view-basic-props";
import BillingsTaxiRouteListingByRailyRouteQueryParams from "./common/types/billings-taxi-route-listing-by-raily-route-query-params";
import billingsTranslationsHelper from "../../../../../languages/billings-translations.helper";
import BillingsTaxiRouteListingFilter, {
  BillingsTaxiRouteListingRouteStartDateFilter,
} from "../common/types/billings-taxi-route-listing-filter";
import billingsTaxiRouteListingByRailyRouteQueryParamsService from "./common/billings-taxi-route-listing-by-raily-route-query-params.service";
import BillingsTaxiRouteListingItem from "../common/types/billings-taxi-route-listing-item";
import BillingsTaxiRouteListingStatsSummary from "../common/types/billings-taxi-route-listing-stats-summary";
import BillingsTaxiRouteListingSortKey from "../common/types/billings-taxi-route-listing-sort-key";
import BillingsTaxiRouteListingByRailyTaxiCorporationSelectOption from "./common/types/billings-taxi-route-listing-by-raily-taxi-corporation-select-option";
import BillingsTaxiRouteListingSortSelectOption from "../common/types/billings-taxi-route-listing-sort-select-option";
import billingsTaxiRouteListingSortHelper from "../common/billings-taxi-route-listing-sort.helper";
import { BillingsTaxiOrderListingByRailyTaxiCorporationsResponseDataItem } from "../../order-listing/by-raily/common/api/billings-taxi-order-listing-by-raily-taxi-corporations.response";
import billingsTaxiRouteListingByRailyFactory from "./common/billings-taxi-route-listing-by-raily.factory";
import BillingsTaxiRouteListingByRailyTaxiCorporationsResponse from "./common/api/billings-taxi-route-listing-by-raily-taxi-corporations.response";
import billingsTaxiRouteListingByRailyApiService from "./common/api/billings-taxi-route-listing-by-raily-api.service";
import BillingsTaxiRouteListingResponse from "../common/api/billings-taxi-route-listing.response";
import billingsTaxiRouteListingRequestFactory from "../common/billings-taxi-route-listing-request.factory";
import BillingsTaxiRouteListingFilterType from "../common/types/billings-taxi-route-listing-filter-type";
import BillingsTaxiRouteListingReportResponse, {
  BillingsTaxiRouteListingReportResponseData,
} from "../common/api/billings-taxi-route-listing-report.response";
import billingsTaxiRouteListingReportRequestFactory from "../common/billings-taxi-route-listing-report-request.factory";
import BillingsTaxiRouteListingFiltersSelectComponent from "../common/filters/select/billings-taxi-route-listing-filters-select.component";
import BillingsTaxiRouteListingFiltersBadgeListComponent from "../common/filters/list/billings-taxi-route-listing-filters-badge-list.component";
import BillingsTaxiRouteListingStatsSummaryComponent from "../common/stats-summary/billings-taxi-route-listing-stats-summary.component";
import BillingsTaxiRouteListingTableComponent from "../common/table/billings-taxi-route-listing-table.component";

type BillingsTaxiRouteListingByRailyProps =
  BillingsTaxiRouteListingViewBasicProps;

const BillingsTaxiRouteListingByRailyComponent: FC<
  BillingsTaxiRouteListingByRailyProps
> = (props) => {
  const isComponentMounted = useIsComponentMounted();
  const { selectedAppLanguage } = useAppContext();

  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<BillingsTaxiRouteListingByRailyRouteQueryParams>();

  const translations =
    billingsTranslationsHelper.getTaxiRouteBillingsListingTranslations();

  const [filters, setFilters] = useState<BillingsTaxiRouteListingFilter[]>(() =>
    billingsTaxiRouteListingByRailyRouteQueryParamsService.getFilters(
      routeQueryParams
    )
  );

  const [isListingFetching, setIsListingFetching] = useState(false);
  const [isListingFetchingError, setIsListingFetchingError] = useState(false);
  const [listingItems, setListingItems] = useState<
    BillingsTaxiRouteListingItem[]
  >([]);
  const [statsSummary, setStatsSummary] =
    useState<BillingsTaxiRouteListingStatsSummary | null>(null);
  const [totalResults, setTotalResults] = useState(0);

  const [isReportFetching, setIsReportFetching] = useState(false);

  const [selectedSortKey, setSelectedSortKey] =
    useState<BillingsTaxiRouteListingSortKey | null>(
      () =>
        billingsTaxiRouteListingByRailyRouteQueryParamsService.getSortKey(
          routeQueryParams
        ) ?? null
    );

  const [selectedTaxiCorporationUuid, setSelectedTaxiCorporationUuid] =
    useState<string | null>(
      () =>
        billingsTaxiRouteListingByRailyRouteQueryParamsService.getTaxiCorporationUuid(
          routeQueryParams
        ) ?? null
    );

  const onTaxiCorporationChange = (
    selectedOption: BillingsTaxiRouteListingByRailyTaxiCorporationSelectOption
  ) => {
    setSelectedTaxiCorporationUuid(selectedOption.value.uuid);
    setPage(1);
  };

  const [isTaxiCorporationFetching, setIsTaxiCorporationFetching] =
    useState(false);
  const [isTaxiCorporationFetchingError, setIsTaxiCorporationFetchingError] =
    useState(false);

  const [taxiCorporationSelectOptions, setTaxiCorporationSelectOptions] =
    useState<BillingsTaxiRouteListingByRailyTaxiCorporationSelectOption[]>([]);

  const selectedTaxiCorporationSelectOption = taxiCorporationSelectOptions.find(
    (option) => option.value.uuid === selectedTaxiCorporationUuid
  );

  const sortSelectOptions: BillingsTaxiRouteListingSortSelectOption[] = useMemo(
    () => billingsTaxiRouteListingSortHelper.getSelectOptions(),
    [selectedAppLanguage]
  );

  const { page, pageSize, setPage, setPageSize } = usePagination({
    totalResults: totalResults,
    defaultPageSize:
      billingsTaxiRouteListingByRailyRouteQueryParamsService.getPageSize(
        routeQueryParams
      ),
    defaultPage:
      billingsTaxiRouteListingByRailyRouteQueryParamsService.getPage(
        routeQueryParams
      ),
  });

  const onTaxiCorporationFetchSuccess = (
    responseDataItems: BillingsTaxiOrderListingByRailyTaxiCorporationsResponseDataItem[]
  ) => {
    const taxiCorporationsSelectOptions =
      billingsTaxiRouteListingByRailyFactory.createTaxiCorporationSelectOptions(
        responseDataItems
      );

    setTaxiCorporationSelectOptions(taxiCorporationsSelectOptions);
  };

  const onTaxiCorporationFetchFailure = () => {
    setIsTaxiCorporationFetchingError(true);
  };

  const handleTaxiCorporationResponse = (
    response: BillingsTaxiRouteListingByRailyTaxiCorporationsResponse
  ) => {
    if (response.status === 200) {
      onTaxiCorporationFetchSuccess(response.data.data);
      return;
    }

    onTaxiCorporationFetchFailure();
  };

  useEffect(() => {
    setIsTaxiCorporationFetching(true);
    setIsTaxiCorporationFetchingError(false);

    billingsTaxiRouteListingByRailyApiService
      .fetchTaxiCorporations()
      .then(handleTaxiCorporationResponse)
      .catch(onListingFetchFailure)
      .finally(() => setIsTaxiCorporationFetching(false));
  }, []);

  const onListingFetchSuccess = (
    response: BillingsTaxiRouteListingResponse
  ) => {
    const listingItems =
      billingsTaxiRouteListingByRailyFactory.createListingItems(
        response.data.data
      );

    const statsSummary =
      billingsTaxiRouteListingByRailyFactory.createStatsSummary(
        response.data.stats
      );

    setListingItems(listingItems);
    setStatsSummary(statsSummary);
    setTotalResults(response.data.total_count);
  };

  const onListingFetchFailure = () => {
    setIsListingFetchingError(true);
  };

  const handleListingResponse = (
    response: BillingsTaxiRouteListingResponse
  ) => {
    if (response.status === 200) {
      onListingFetchSuccess(response);
      return;
    }

    onListingFetchFailure();
  };

  const fetchListing = () => {
    setIsListingFetching(true);
    setIsListingFetchingError(false);

    const request = billingsTaxiRouteListingRequestFactory.createRequest(
      page,
      pageSize,
      filters,
      selectedSortKey
    );

    billingsTaxiRouteListingByRailyApiService
      .fetchListing(selectedTaxiCorporationUuid!, request)
      .then(handleListingResponse)
      .catch(onListingFetchFailure)
      .finally(() => setIsListingFetching(false));
  };

  useEffect(() => {
    if (!page || !pageSize || !selectedTaxiCorporationUuid) {
      return;
    }

    fetchListing();
  }, [filters, selectedTaxiCorporationUuid, selectedSortKey, page, pageSize]);

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    const queryParams =
      billingsTaxiRouteListingByRailyRouteQueryParamsService.createRouteQueryParams(
        selectedTaxiCorporationUuid,
        filters,
        selectedSortKey,
        page,
        pageSize
      );

    setRouteQueryParams(queryParams);
  }, [selectedTaxiCorporationUuid, filters, selectedSortKey, page, pageSize]);

  const addNewFilter = (newFilter: BillingsTaxiRouteListingFilter) => {
    setFilters((curr) => [...curr, newFilter]);
    setPage(1);
  };

  const deleteFilter = (index: number) => {
    const newFilters = filters.filter((filter, _index) => _index !== index);

    setFilters(newFilters);
    setPage(1);
  };

  const deleteAllFilters = () => {
    setFilters([]);
    setPage(1);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    window.scroll({ top: 0, behavior: "smooth" });
  };

  const onPageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
    setPage(1);
  };

  const selectedSortSelectOption = useMemo(() => {
    return (
      sortSelectOptions.find((item) => item.value === selectedSortKey) ?? null
    );
  }, [selectedSortKey]);

  const startDateFilterValue = filters.find(
    (filter) =>
      filter.type === BillingsTaxiRouteListingFilterType.ORDER_START_DATE
  )?.value as BillingsTaxiRouteListingRouteStartDateFilter["value"] | undefined;

  const onOrderStartDateFilterValueChange = (dateRange: DateRange | null) => {
    if (!dateRange) {
      const newFilters = filters.filter(
        (filter) =>
          filter.type !== BillingsTaxiRouteListingFilterType.ORDER_START_DATE
      );

      setFilters(newFilters);
      return;
    }

    const isFilterExists = !!filters.find(
      (filter) =>
        filter.type === BillingsTaxiRouteListingFilterType.ORDER_START_DATE
    );

    const newFilter: BillingsTaxiRouteListingRouteStartDateFilter = {
      type: BillingsTaxiRouteListingFilterType.ORDER_START_DATE,
      value: {
        from: dateRange.from!,
        to: dateRange.to!,
      },
    };

    if (isFilterExists) {
      const newFilters = [
        ...filters.filter(
          (filter) =>
            filter.type !== BillingsTaxiRouteListingFilterType.ORDER_START_DATE
        ),
        newFilter,
      ];

      setFilters(newFilters);
      setPage(1);
      return;
    }

    setFilters((curr) => [...curr, newFilter]);
    setPage(1);
  };

  const downloadReportFile = (
    responseData: BillingsTaxiRouteListingReportResponseData
  ) => {
    fileDownloadService.downloadFromBlob(
      responseData.data,
      responseData.filename
    );
  };

  const onReportFetchSuccess = async (
    response: BillingsTaxiRouteListingReportResponse
  ) => {
    if (response.status === 200) {
      downloadReportFile(response.data);
      return;
    }

    onReportFetchFailure();
  };

  const onReportFetchFailure = () => {
    notificationService.error(
      translations.report.failureDownloadingNotificationText
    );
  };

  const fetchReport = () => {
    setIsReportFetching(true);

    const request = billingsTaxiRouteListingReportRequestFactory.createRequest(
      filters,
      selectedSortKey
    );

    billingsTaxiRouteListingByRailyApiService
      .fetchReport(selectedTaxiCorporationUuid!, request)
      .then(onReportFetchSuccess)
      .catch(onReportFetchFailure)
      .finally(() => setIsReportFetching(false));
  };

  const onReportDownloadButtonClick = () => {
    fetchReport();
  };

  const ReportDownloadButton = (
    <ButtonComponent
      onClick={onReportDownloadButtonClick}
      type="primary"
      isLoading={isReportFetching}
      title={translations.report.downloadButtonTitle}
      idForTesting="billings-taxi-route-listing-by-raily-report-download-button"
    >
      {translations.report.downloadButtonText}
    </ButtonComponent>
  );

  const isReportDownloadButtonVisible = !!selectedTaxiCorporationUuid;
  const isListingContentVisible = !!selectedTaxiCorporationUuid;

  return (
    <div className="billings_taxi_route_listing">
      <HeadingComponent
        title={translations.header.headingText}
        actions={[
          ...props.actionButtons,
          isReportDownloadButtonVisible && ReportDownloadButton,
        ]}
      />
      <FormFieldComponent label={translations.taxiCorporationLabel} isRequired>
        <SingleSelectComponent
          placeholder={translations.taxiCorporationPlaceholder}
          value={selectedTaxiCorporationSelectOption ?? null}
          options={taxiCorporationSelectOptions}
          onChange={(selectedOption) =>
            onTaxiCorporationChange(selectedOption!)
          }
          classNames={{
            root: "billings_taxi_route_listing_taxi_corporation_select",
          }}
          idForTesting={`billings-taxi-route-listing-by-raily-taxi-corporation-select`}
          isLoading={isTaxiCorporationFetching}
          isDisabled={isTaxiCorporationFetchingError}
          isSearchable
        />
      </FormFieldComponent>
      {isListingContentVisible && (
        <>
          <div className="billings_taxi_route_listing_tools">
            <div className="d-flex">
              <BillingsTaxiRouteListingFiltersSelectComponent
                filters={filters}
                onAddNewFilter={addNewFilter}
              />
              <DateRangeInputComponent
                date={startDateFilterValue ?? null}
                onChange={onOrderStartDateFilterValueChange}
                classNames={{ root: "ml-2" }}
                placeholder={
                  translations.filters.searchByStartDateSelectInputPlaceholder
                }
                idForTesting="billings-taxi-route-listing-by-raily-date-range"
              />
            </div>
            <ListingSortSelectComponent
              onChange={(option) => setSelectedSortKey(option?.value!)}
              options={sortSelectOptions}
              value={selectedSortSelectOption}
              idForTesting="billings-taxi-route-listing-by-raily-sort"
            />
          </div>
          <BillingsTaxiRouteListingFiltersBadgeListComponent
            filters={filters}
            onDeleteFilterClick={deleteFilter}
            onDeleteAllFiltersButtonClick={deleteAllFilters}
          />
          {!!listingItems.length && (
            <BillingsTaxiRouteListingStatsSummaryComponent
              isError={isListingFetchingError}
              isLoading={isListingFetching}
              statsSummary={statsSummary}
            />
          )}
          <CardComponent classNames={{ root: "mt-4" }}>
            <BillingsTaxiRouteListingTableComponent
              listingItems={listingItems}
              isError={isListingFetchingError}
              isLoading={isListingFetching}
            />
            <div className="billings_taxi_route_listing__pagination_wrapper">
              <PaginationComponent
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
                page={page}
                pageSize={pageSize}
                totalResults={totalResults}
              />
            </div>
          </CardComponent>
        </>
      )}
    </div>
  );
};

export default BillingsTaxiRouteListingByRailyComponent;
