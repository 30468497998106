import ListingFilterDefinition, {
  ListingFilterDefinitionOption,
  ListingFilterDefinitionOptionBadgeData,
  ListingFilterDefinitionOptionSelectData,
  ListingFilterDefinitionOptionSelectDataType,
} from "../../../../common/components/listing/filter/types/listing-filter-definition";
import ListingSortDefinition, {
  ListingSortDefinitionOption,
} from "../../../../common/components/listing/filter/types/listing-sort-definition";
import dateService from "../../../../common/utils/date/date.service";
import orderTranslationsHelper from "../../../../languages/order-translations.helper";
import OrderAbandonedListingFilter, {
  OrderAbandonedListingAddressFilter,
  OrderAbandonedListingClientFilter,
  OrderAbandonedListingDispatchFilter,
  OrderAbandonedListingEndAddressFilter,
  OrderAbandonedListingExternalOrderIdFilter,
  OrderAbandonedListingInternalOrderIdFilter,
  OrderAbandonedListingMidAddressFilter,
  OrderAbandonedListingPassengerFilter,
  OrderAbandonedListingStartAddressFilter,
  OrderAbandonedListingStartTimeFilter,
} from "../common/types/order-abandoned-listing-filter";
import OrderAbandonedListingFilterType from "../common/types/order-abandoned-listing-filter-type";
import OrderAbandonedListingSortKey from "../common/types/order-abandoned-listing-sort-key";

const orderAbandonedListingFiltersHelper = () => {
  const translation = orderTranslationsHelper.getAbandonedListingTranslations();

  const getSortDefinitionOptions = (): ListingSortDefinitionOption[] => {
    return [
      {
        label: translation.sort.internalOrderIdAscOptionLabel,
        value: OrderAbandonedListingSortKey.CARGO_COMPANY_INTERNAL_ID_ASC,
      },
      {
        label: translation.sort.internalOrderIdDescOptionLabel,
        value: OrderAbandonedListingSortKey.CARGO_COMPANY_INTERNAL_ID_DESC,
      },
    ];
  };

  const getSortDefinition = (): ListingSortDefinition => {
    return {
      options: getSortDefinitionOptions(),
    };
  };

  const getSelectDataForCient = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translation.filters.search.clientTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForDispatch = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translation.filters.search.dispatchTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForPassenger = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translation.filters.search.passengersTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForExternalId = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.NUMERIC_QUERY,
      options: [
        {
          label:
            translation.filters.search.externalOrderIdTemplateLabel.replace(
              "#{query}",
              query
            ),
          value: Number(query),
        },
      ],
    };
  };

  const getSelectDataForInternalId = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.NUMERIC_QUERY,
      options: [
        {
          label:
            translation.filters.search.internalOrderIdTemplateLabel.replace(
              "#{query}",
              query
            ),
          value: Number(query),
        },
      ],
    };
  };

  const getSelectDataForAddress = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translation.filters.search.addressTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForStartAddress = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translation.filters.search.startAddressTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForMidAddress = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translation.filters.search.midAddressTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForEndAddress = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translation.filters.search.endAddressTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForStartTime =
    (): ListingFilterDefinitionOptionSelectData => {
      return {
        type: ListingFilterDefinitionOptionSelectDataType.DATE_RANGE,
        option: {
          label: translation.filters.search.startTimeLabel,
        },
      };
    };

  const getSelectDataByType = (
    filterType: OrderAbandonedListingFilterType,
    query: string
  ) => {
    const options: Record<
      OrderAbandonedListingFilterType,
      (filterValue: string) => ListingFilterDefinitionOptionSelectData
    > = {
      [OrderAbandonedListingFilterType.ADDRESS]: getSelectDataForAddress,
      [OrderAbandonedListingFilterType.CLIENT]: getSelectDataForCient,
      [OrderAbandonedListingFilterType.DISPATCH]: getSelectDataForDispatch,
      [OrderAbandonedListingFilterType.END_ADDRESS]: getSelectDataForEndAddress,
      [OrderAbandonedListingFilterType.EXTERNAL_ORDER_ID]:
        getSelectDataForExternalId,
      [OrderAbandonedListingFilterType.INTERNAL_ORDER_ID]:
        getSelectDataForInternalId,
      [OrderAbandonedListingFilterType.MID_ADDRESS]: getSelectDataForMidAddress,
      [OrderAbandonedListingFilterType.PASSENGER]: getSelectDataForPassenger,
      [OrderAbandonedListingFilterType.START_ADDRESS]:
        getSelectDataForStartAddress,
      [OrderAbandonedListingFilterType.START_TIME]: getSelectDataForStartTime,
    };

    return options[filterType](query);
  };

  const createBadgeData = (
    value: string,
    textTemplate: string,
    titleTemplate: string
  ): ListingFilterDefinitionOptionBadgeData => {
    return {
      text: textTemplate.replace("#{query}", value),
      title: titleTemplate.replace("#{query}", value),
    };
  };

  const getBadgeForClient = (
    filterValue: OrderAbandonedListingClientFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translation.filters.clientFilterLabel,
      translation.filters.clientFilterTitle
    );
  };

  const getBadgeForDispatch = (
    filterValue: OrderAbandonedListingDispatchFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translation.filters.dispatchFilterLabel,
      translation.filters.dispatchFilterTitle
    );
  };

  const getBadgeForPassenger = (
    filterValue: OrderAbandonedListingPassengerFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translation.filters.passengerFilterLabel,
      translation.filters.passengerFilterTitle
    );
  };

  const getBadgeForExternalId = (
    filterValue: OrderAbandonedListingExternalOrderIdFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue.toString(),
      translation.filters.externalOrderIdFilterLabel,
      translation.filters.externalOrderIdFilterTitle
    );
  };

  const getBadgeForInternalId = (
    filterValue: OrderAbandonedListingInternalOrderIdFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue.toString(),
      translation.filters.internalOrderIdFilterLabel,
      translation.filters.internalOrderIdFilterTitle
    );
  };

  const getBadgeForAddress = (
    filterValue: OrderAbandonedListingAddressFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translation.filters.addressFilterLabel,
      translation.filters.addressFilterTitle
    );
  };

  const getBadgeForStartAddress = (
    filterValue: OrderAbandonedListingStartAddressFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translation.filters.startAddressFilterLabel,
      translation.filters.startAddressFilterTitle
    );
  };

  const getBadgeForMidAddress = (
    filterValue: OrderAbandonedListingMidAddressFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translation.filters.midAddressFilterLabel,
      translation.filters.midAddressFilterTitle
    );
  };

  const getBadgeForEndAddress = (
    filterValue: OrderAbandonedListingEndAddressFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translation.filters.endAddressFilterLabel,
      translation.filters.endAddressFilterTitle
    );
  };

  const getBadgeForStartTime = (
    filterValue: OrderAbandonedListingStartTimeFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      `${dateService.formatDate(filterValue.from)}-${dateService.formatDate(
        filterValue.to
      )}`,
      translation.filters.startTimeFilterLabel,
      translation.filters.startTimeFilterTitle
    );
  };

  const getBadgeDefinitionByType = (
    filterType: OrderAbandonedListingFilterType,
    filterValue: OrderAbandonedListingFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    const options: Record<
      OrderAbandonedListingFilterType,
      () => ListingFilterDefinitionOptionBadgeData
    > = {
      [OrderAbandonedListingFilterType.CLIENT]: () =>
        getBadgeForClient(
          filterValue as OrderAbandonedListingClientFilter["value"]
        ),
      [OrderAbandonedListingFilterType.DISPATCH]: () =>
        getBadgeForDispatch(
          filterValue as OrderAbandonedListingDispatchFilter["value"]
        ),
      [OrderAbandonedListingFilterType.PASSENGER]: () =>
        getBadgeForPassenger(
          filterValue as OrderAbandonedListingPassengerFilter["value"]
        ),
      [OrderAbandonedListingFilterType.EXTERNAL_ORDER_ID]: () =>
        getBadgeForExternalId(
          filterValue as OrderAbandonedListingExternalOrderIdFilter["value"]
        ),
      [OrderAbandonedListingFilterType.INTERNAL_ORDER_ID]: () =>
        getBadgeForInternalId(
          filterValue as OrderAbandonedListingInternalOrderIdFilter["value"]
        ),
      [OrderAbandonedListingFilterType.ADDRESS]: () =>
        getBadgeForAddress(
          filterValue as OrderAbandonedListingAddressFilter["value"]
        ),
      [OrderAbandonedListingFilterType.START_ADDRESS]: () =>
        getBadgeForStartAddress(
          filterValue as OrderAbandonedListingStartAddressFilter["value"]
        ),
      [OrderAbandonedListingFilterType.MID_ADDRESS]: () =>
        getBadgeForMidAddress(
          filterValue as OrderAbandonedListingMidAddressFilter["value"]
        ),
      [OrderAbandonedListingFilterType.END_ADDRESS]: () =>
        getBadgeForEndAddress(
          filterValue as OrderAbandonedListingEndAddressFilter["value"]
        ),
      [OrderAbandonedListingFilterType.START_TIME]: () =>
        getBadgeForStartTime(
          filterValue as OrderAbandonedListingStartTimeFilter["value"]
        ),
    };

    return options[filterType]();
  };

  const getFilterDefinitionByType = (
    filterType: OrderAbandonedListingFilterType
  ): ListingFilterDefinitionOption<OrderAbandonedListingFilter> => {
    return {
      getSelectData: (query: string) => getSelectDataByType(filterType, query),
      getBadgeData: (value) => getBadgeDefinitionByType(filterType, value),
      filterType,
    };
  };

  const getFilterDefinitionOptions =
    (): ListingFilterDefinitionOption<OrderAbandonedListingFilter>[] => {
      return Object.values(OrderAbandonedListingFilterType).map(
        getFilterDefinitionByType
      );
    };

  const getFilterDefinition =
    (): ListingFilterDefinition<OrderAbandonedListingFilter> => {
      return {
        options: getFilterDefinitionOptions(),
      };
    };

  return {
    getFilterDefinition,
    getSortDefinition,
  };
};

export default orderAbandonedListingFiltersHelper;
