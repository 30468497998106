import {
  faEdit,
  faEllipsisVertical,
  faRotateLeft,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import orderRouteEditPassengerHelper from "./order-route-edit-passenger.helper";
import debounce from "lodash/debounce";
import classNames from "classnames";
import ButtonComponent from "../../../../../../common/components/button/button.component";
import FormFieldComponent from "../../../../../../common/components/form/field/form-field.component";
import uuidService from "../../../../../../common/utils/uuid/uuid.service";
import orderTranslationsHelper from "../../../../../../languages/order-translations.helper";
import orderRouteEditApiService from "../../api/order-route-edit-api.service";
import OrderRouteEditPassengersRequest from "../../api/order-route-edit-passengers.request";
import OrderRouteEditPassengersResponse, {
  OrderRouteEditPassengersResponsePassenger,
} from "../../api/order-route-edit-passengers.response";
import orderRouteEditFactory from "../../factory/order-route-edit.factory";
import OrderRouteEditContractor from "../../types/order-route-edit-contractor";
import OrderRouteEditPassengerListItem from "../../types/order-route-edit-passenger-list-item";
import OrderRouteEditPassengerSelectOption from "../../types/order-route-edit-passenger-select-option";
import OrderRouteEditRoutesPassengerIconComponent from "../passenger-icon/order-route-edit-routes-passenger-icon.component";
import OrderRouteEditPassenger, {
  OrderRouteEditExternalPassenger,
  OrderRouteEditInternalPassenger,
  OrderRouteEditPassengerAddress,
  OrderRouteEditPassengerType,
} from "../../types/order-route-edit-passenger";
import OrderRouteEditPassengerEditComponent from "./edit/order-route-edit-passenger-edit.component";
import OrderRouteEditExternalPassengerAddComponent from "./add-external/order-route-edit-external-passenger-add.component";
import SingleSelectComponent from "../../../../../../common/components/form/select/single-select/single-select.component";
import OrderRouteEditRouteItem, { OrderRouteEditRouteWaypoint } from "../../types/order-route-edit-route-waypoint";
import OrderRouteEditHomeAddressModalComponent from "../add-home-address-modal/order-route-edit-home-address-modal.component";
import useOpen from "../../../../../../common/hooks/use-open";

type OrderRouteEditPassengersProps = {
  passengerList: OrderRouteEditPassengerListItem[];
  onPassengerListChange: (
    passengerList: OrderRouteEditPassengerListItem[]
  ) => void;
  selectedPassengerUuid: string | null;
  onSelectedPassengerUuidChange: (selectedPassengerUuid: string | null) => void;
  contractorUuid: OrderRouteEditContractor["uuid"] | null;
  isReturnRideActive: string[];
  setIsReturnRideActive: (isReturnRideActive: string[]) => void;
  addReturnRide: (passenger: OrderRouteEditPassenger, address: OrderRouteEditPassengerAddress) => void;
  routes: OrderRouteEditRouteItem[];
};

const OrderRouteEditPassengersComponent: FC<OrderRouteEditPassengersProps> = (
  props
) => {
  const translations = orderTranslationsHelper.getEditTranslations().passengers;

  const [arePassengersFetching, setArePassengersFetching] = useState(false);

  const [isPassengersFetchingError, setIsPassengersFetchingError] =
    useState(false);

  const [allPassengersSelectOptions, setAllPassengersSelectOptions] = useState<
    OrderRouteEditPassengerSelectOption[]
  >([]);

  const [passengerSearchQuery, setPassengerSearchQuery] = useState("");
  const { isOpen, open, close } = useOpen();
  const [selectedPassengerForModal, setSelectedPassengerForModal] = useState<string | null>(null);

  const [isEditPassengerModalOpen, setIsEditPassengerModalOpen] =
    useState(false);

  const [isAddExternalPassengerModalOpen, setIsAddExternalPassengerModalOpen] =
    useState(false);

  const [
    passengerSelectedToEditListingItemUuid,
    setPassengerSelectedToEditListingItemUuid,
  ] = useState<OrderRouteEditPassengerListItem["uuid"]>("");

  const openEditPassengerModal = () => {
    setIsEditPassengerModalOpen(true);
  };

  const closeEditPassengerModal = () => {
    setIsEditPassengerModalOpen(false);
  };

  const openAddExternalPassengerModal = () => {
    setIsAddExternalPassengerModalOpen(true);
  };

  const closeAddExternalPassengerModal = () => {
    setIsAddExternalPassengerModalOpen(false);
  };

  const onPassengersFetchSuccess = (
    responsePassengers: OrderRouteEditPassengersResponsePassenger[]
  ) => {
    const passengersSelectOptions =
      orderRouteEditFactory.createPassengerSelectOptions(responsePassengers);

    setAllPassengersSelectOptions(passengersSelectOptions);
  };

  const handlePassengersResponse = (
    response: OrderRouteEditPassengersResponse
  ) => {
    if (response.status === 200) {
      onPassengersFetchSuccess(response.data);
      return;
    }

    setIsPassengersFetchingError(true);
  };

  const fetchPassengersDebounced = useCallback(
    debounce((selectedContractorUuid: string, passengerSearchQuery: string) => {
      const request: OrderRouteEditPassengersRequest = {
        search_query: passengerSearchQuery,
      };

      orderRouteEditApiService
        .fetchPassengers(selectedContractorUuid, request)
        .then(handlePassengersResponse)
        .finally(() => {
          setArePassengersFetching(false);
        });
    }, 500),
    []
  );

  useEffect(() => {
    if (!props.contractorUuid || !passengerSearchQuery) {
      setAllPassengersSelectOptions([]);
      return;
    }

    setArePassengersFetching(true);

    fetchPassengersDebounced(props.contractorUuid, passengerSearchQuery);
  }, [props.contractorUuid, passengerSearchQuery, fetchPassengersDebounced]);

  const filteredPassengerSelectOptions = allPassengersSelectOptions.filter(
    (option) =>
      !props.passengerList.find(
        (passengerListItem) =>
          passengerListItem.passenger.uuid === option.value?.uuid
      )
  );

  const passengerSelectOptions: OrderRouteEditPassengerSelectOption[] = [
    {
      label: translations.addNewExternalPassengerSelectOptionLabel,
      value: null,
      onClick: openAddExternalPassengerModal,
    },
    ...filteredPassengerSelectOptions,
  ];

  const addPassenger = (passenger: OrderRouteEditPassenger) => {
    const newPassengerListItem: OrderRouteEditPassengerListItem = {
      uuid: uuidService.generate(),
      passenger,
    };

    const newPassengerList: OrderRouteEditPassengerListItem[] = [
      ...props.passengerList,
      newPassengerListItem,
    ];

    props.onPassengerListChange(newPassengerList);
  };

  const onEditPassengerButtonClick = (
    passengerListItemUuid: OrderRouteEditPassengerListItem["uuid"]
  ) => {
    setPassengerSelectedToEditListingItemUuid(passengerListItemUuid);
    openEditPassengerModal();
  };

  const onDeletePassengerButtonClick = (
    passengerListItemUuid: OrderRouteEditPassengerListItem["uuid"]
  ) => {
    const newPassengerList: OrderRouteEditPassengerListItem[] =
      props.passengerList.filter(
        (passengerListItem) => passengerListItem.uuid !== passengerListItemUuid
      );

    props.onPassengerListChange(newPassengerList);
  };

  const getReorderedPassengerList = (
    startIndex: number,
    endIndex: number
  ): OrderRouteEditPassengerListItem[] => {
    const result = [...props.passengerList];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragPassengerListItemEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const reorderedPassengerList = getReorderedPassengerList(
      result.source.index,
      result.destination.index
    );

    props.onPassengerListChange(reorderedPassengerList);
  };

  const isDraggingEnabled = props.passengerList.length > 1;

  const isSearchSelectDisabled =
    isPassengersFetchingError || !props.contractorUuid;

  const onPassengerIconClick = (
    passengerListItem: OrderRouteEditPassengerListItem
  ) => {
    if (props.selectedPassengerUuid === passengerListItem.passenger.uuid) {
      props.onSelectedPassengerUuidChange(null);
      return;
    }
    props.onSelectedPassengerUuidChange(passengerListItem.passenger.uuid);
  };

  const passengerSelectedToEdit: OrderRouteEditPassenger | undefined =
    props.passengerList.find(
      (listItem) => listItem.uuid === passengerSelectedToEditListingItemUuid
    )?.passenger;

  const onExternalPassengerAddSuccess = (
    passenger: OrderRouteEditExternalPassenger
  ) => {
    const newPassengerList: OrderRouteEditPassengerListItem[] = [
      ...props.passengerList,
      {
        passenger,
        uuid: passenger.uuid,
      },
    ];

    props.onPassengerListChange(newPassengerList);
    closeAddExternalPassengerModal();
  };

  const onPassengerEditSuccess = (passenger: OrderRouteEditPassenger) => {
    const passengerSelectedListItem = props.passengerList.find(
      (item) => item.uuid === passengerSelectedToEditListingItemUuid
    );
    if (!passengerSelectedToEditListingItemUuid || !passengerSelectedListItem) {
      return;
    }

    const indexOfEditingPassenger = props.passengerList.indexOf(
      passengerSelectedListItem
    );

    const newPassengerListItem: OrderRouteEditPassengerListItem = {
      uuid: passengerSelectedToEditListingItemUuid,
      passenger,
    };

    const newPassengerList: OrderRouteEditPassengerListItem[] = [
      ...props.passengerList.slice(0, indexOfEditingPassenger),
      newPassengerListItem,
      ...props.passengerList.slice(indexOfEditingPassenger + 1),
    ];

    props.onPassengerListChange(newPassengerList);

    setPassengerSelectedToEditListingItemUuid("");
    closeEditPassengerModal();
  };

  const checkIsModalNeeded = (passengerListItem: OrderRouteEditPassengerListItem, isTriggeredByReturnTrip = false) => {
    if (props.isReturnRideActive.includes(passengerListItem.passenger.uuid) || !passengerListItem.passenger.uuid) return;

    const passenger = passengerListItem.passenger;
    const isInternalPassenger =
      passenger.type === OrderRouteEditPassengerType.INTERNAL;
  
    const addresses = isInternalPassenger ? passenger.addresses : [];

    if (!isTriggeredByReturnTrip) {
      if (props.selectedPassengerUuid === passenger.uuid) {
        props.onSelectedPassengerUuidChange(null);
        return;
      }
      props.onSelectedPassengerUuidChange(passenger.uuid);
    }

    if (addresses.length > 1) {
      setSelectedPassengerForModal(passenger.uuid);
      open();
    } else {
      props.addReturnRide(passenger, addresses[0]);
      props.setIsReturnRideActive([...props.isReturnRideActive, passenger.uuid]);
    }
    
  };  
    
  const onRouteEditOrderReturnRideCloseButtonClick = () => {
    close();
    setSelectedPassengerForModal(null);
  };

  const isPassengerAssignedToOutboarding = (passengerUuid: string) => 
    props.routes.some(waypoint =>
      (waypoint as OrderRouteEditRouteWaypoint).outboardingPassengerListItems.some(item => item.passenger.uuid === passengerUuid)  
    );

  const disabledPassengersUuids = useMemo(() => {
    return props.passengerList
      .filter(
        (passengerListItem) =>
          !isPassengerAssignedToOutboarding(passengerListItem.passenger.uuid) || 
          props.isReturnRideActive.includes(passengerListItem.passenger.uuid)   
      )
      .map((passengerListItem) => passengerListItem.passenger.uuid);
  }, [props.passengerList, props.routes, props.isReturnRideActive]);
    

  const availableAddresses = useMemo(() => {
    if (!selectedPassengerForModal) return [];
    
    const selectedPassenger = props.passengerList.find(
      (passenger) => passenger.passenger.uuid === selectedPassengerForModal
    );
  
    if (
      selectedPassenger &&
      selectedPassenger.passenger.type === OrderRouteEditPassengerType.INTERNAL
    ) {
      return selectedPassenger.passenger.addresses;
    }
    else
  
    return [];
  }, [selectedPassengerForModal, props.passengerList]);

  const selectPassenger = (selectedAddress: OrderRouteEditPassengerAddress) => {
    const selectedPassenger = props.passengerList.find(
      (item) => item.passenger.uuid === selectedPassengerForModal
    );
    if (selectedPassenger) {
      props.addReturnRide(selectedPassenger.passenger, selectedAddress);
      props.setIsReturnRideActive([...props.isReturnRideActive, selectedPassenger.passenger.uuid]);
    }
    close();
  }

  return (
    <>
      <FormFieldComponent label={translations.headingText} isRequired>
        <DragDropContext onDragEnd={onDragPassengerListItemEnd}>
          <Droppable droppableId="order_edit_passenger_list_drop_area">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                <div className="order_edit_passenger_list">
                  {props.passengerList.map((passengerListItem, index) => {
                    const isSelected =
                      props.selectedPassengerUuid ===
                      passengerListItem.passenger.uuid;

                      const isAddReturnRideButtonDisabled = disabledPassengersUuids.includes(passengerListItem.passenger.uuid);

                    return (
                      <Draggable
                        key={passengerListItem.uuid}
                        draggableId={passengerListItem.uuid}
                        index={index}
                        isDragDisabled={!isDraggingEnabled}
                      >
                        {(provided) => (
                          <div
                            className={classNames(
                              "order_edit_passenger_list_item",
                              isSelected && `selected`
                            )}
                            key={`passenger-list-item-${passengerListItem.uuid}`}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <button
                              className="order_edit_passenger_list_item__drag_button"
                              {...provided.dragHandleProps}
                              title={translations.dragButtonTitle}
                            >
                              <FontAwesomeIcon icon={faEllipsisVertical} />
                            </button>

                            <div className="order_edit_passenger_list_item__index">
                              <ButtonComponent
                                onClick={() =>
                                  onPassengerIconClick(passengerListItem)
                                }
                                classNames={{ root: "p-0" }}
                              >
                                <OrderRouteEditRoutesPassengerIconComponent
                                  number={index + 1}
                                />
                              </ButtonComponent>
                            </div>
                            <div
                              className="order_edit_passenger_list_item__passenger_name"
                              title={orderRouteEditPassengerHelper.getPassengerTitle(
                                passengerListItem.passenger
                              )}
                            >
                              {orderRouteEditPassengerHelper.getPassengerLabel(
                                passengerListItem.passenger
                              )}
                            </div>
                            <div className="d-flex">
                              <ButtonComponent
                                onClick={() => {
                                  checkIsModalNeeded(passengerListItem, true); 
                                }}                            
                                classNames={{
                                  root: "order_edit_passenger_list_item__action_button",
                                }}
                                title={translations.addReturnRideButtonTitle}
                                isDisabled={isAddReturnRideButtonDisabled}
                              >
                              <FontAwesomeIcon icon={faRotateLeft}/>
                            </ButtonComponent>
                              <ButtonComponent
                                onClick={() =>
                                  onEditPassengerButtonClick(
                                    passengerListItem.uuid
                                  )
                                }
                                classNames={{
                                  root: "order_edit_passenger_list_item__action_button",
                                }}
                                title={translations.editPassengerButtonTitle}
                              >
                                <FontAwesomeIcon icon={faEdit} />
                              </ButtonComponent>

                              <ButtonComponent
                                onClick={() =>
                                  onDeletePassengerButtonClick(
                                    passengerListItem.uuid
                                  )
                                }
                                classNames={{
                                  root: "order_edit_passenger_list_item__action_button",
                                }}
                                title={translations.deletePassengerButtonTitle}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </ButtonComponent>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                </div>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className="order_edit_passenger_list_item m-0">
          <SingleSelectComponent
            classNames={{ root: "order_edit_passenger_list_item__select" }}
            placeholder={translations.selectPlaceholder}
            value={null}
            onChange={(value: OrderRouteEditPassengerSelectOption | null) => {
              if (!value?.value) return;

              addPassenger(value.value);
            }}
            options={passengerSelectOptions}
            isLoading={arePassengersFetching}
            isDisabled={isSearchSelectDisabled}
            isSearchable
            filterFunction={() => true}
            inputValue={passengerSearchQuery}
            onInputChange={setPassengerSearchQuery}
            noOptionsMessage={(inputValue) => {
              if (inputValue) {
                return translations.searchNoOptionsMessage;
              }
              return translations.searchTipMessage;
            }}
          />
        </div>
      </FormFieldComponent>
      <OrderRouteEditPassengerEditComponent
        isOpen={isEditPassengerModalOpen}
        onClose={closeEditPassengerModal}
        onSubmit={onPassengerEditSuccess}
        passenger={passengerSelectedToEdit}
      />
      <OrderRouteEditExternalPassengerAddComponent
        isOpen={isAddExternalPassengerModalOpen}
        onClose={closeAddExternalPassengerModal}
        onSubmit={onExternalPassengerAddSuccess}
      />
      {isOpen && (
        <OrderRouteEditHomeAddressModalComponent
          isOpen={isOpen}
          onClose={onRouteEditOrderReturnRideCloseButtonClick}
          availableAddresses={availableAddresses}
          onConfirm={selectPassenger}
        />
      )}
    </>
  );
};

export default OrderRouteEditPassengersComponent;
