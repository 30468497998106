import appTranslationsHelper from "./app-translations.helper";
import OrderOptimizerDetailsTranslations from "./types/order-optimizer/order-optimizer-details.translations";
import OrderOptimizerListingTranslations from "./types/order-optimizer/order-optimizer-listing.translations";

const getTranslations = <T>(internalFilePath: string): T => {
  const baseFilePath = `order-optimizer`;

  const filePath = `${baseFilePath}/${internalFilePath}`;

  return appTranslationsHelper.getTranslations(filePath);
};

const getListingTranslations = (): OrderOptimizerListingTranslations => {
  const translations = getTranslations<OrderOptimizerListingTranslations>(
    "order-optimizer-listing.translations.json"
  );

  return translations;
};

const getDetailsTranslations = (): OrderOptimizerDetailsTranslations => {
  const translations = getTranslations<OrderOptimizerDetailsTranslations>(
    "order-optimizer-details.translations.json"
  );

  return translations;
};

const orderOptimizerTranslationsHelper = {
  getListingTranslations,
  getDetailsTranslations,
};

export default orderOptimizerTranslationsHelper;
