import HttpResponse from "../../../../../../../common/utils/http/http.response";

export enum BillingsTaxiUnbilledOrderListingByRailyResponseDataItemLackingRailyBillings {
  CARGO_RAILY = "CARGO_RAILY",
  TAXI_RAILY = "TAXI_RAILY",
  RAILY_TAXI = "RAILY_TAXI",
}

export type BillingsTaxiUnbilledOrderListingByRailyResponseDataClient = {
  id: string;
  name: string;
};

export type BillingsTaxiUnbilledOrderListingByRailyResponseDataItem = {
  id: string;
  start_time: string;
  human_id: number;
  is_cancelled: boolean;
  ride_addresses: (string | string[])[];
  passengers: string[];
  dispatch: string;
  client: BillingsTaxiUnbilledOrderListingByRailyResponseDataClient;
  taxi_corporations: string[];
  drivers: string[];
  external_id: string | null;
  lacking_raily_billings: BillingsTaxiUnbilledOrderListingByRailyResponseDataItemLackingRailyBillings[];
};

export type BillingsTaxiUnbilledOrderListingByRailyResponseData = {
  data: BillingsTaxiUnbilledOrderListingByRailyResponseDataItem[];
  total_count: number;
};

type BillingsTaxiUnbilledOrderListingByRailyResponse =
  HttpResponse<BillingsTaxiUnbilledOrderListingByRailyResponseData>;

export default BillingsTaxiUnbilledOrderListingByRailyResponse;
