enum BillingsTaxiOrderListingFilterType {
  ORDER_START_DATE = "ORDER_START_DATE",
  INTERNAL_ORDER_ID = "INTERNAL_ORDER_ID",
  PLAN_ENTRY_HUMAN_IDS = "PLAN_ENTRY_HUMAN_IDS",
  CARGO_COMPANY = "CARGO_COMPANY",
  EXCLUDE_CARGO_COMPANY = "EXCLUDE_CARGO_COMPANY",
  PASSENGER = "PASSENGER",
  BILLING_MODEL = "BILLING_MODEL",
  BILLING_TYPE = "BILLING_TYPE",
  BILLING_STATUS = "BILLING_STATUS",
  DESTINATION_TAXI = "DESTINATION_TAXI",
}

export default BillingsTaxiOrderListingFilterType;
