import { FC } from "react";
import TableComponent, {
  TableProps,
} from "../../../../../common/components/table/table.component";
import BillingsTaxiUnbilledPrivateOrderListingItem from "../common/types/billings-taxi-unbilled-private-order-listing-item";
import BillingsTaxiUnbilledPrivateOrderListingTableColumn from "../common/types/billings-taxi-unbilled-private-order-listing-table-column";
import BillingsTaxiUnbilledPrivateOrderListingTableRow from "../common/types/billings-taxi-unbilled-private-order-listing-table-row";
import billingsTaxiUnbilledPrivateOrderListingTableHelper from "./billings-taxi-unbilled-private-order-listing-table.helper";

type BillingsTaxiUnbilledPrivateOrderListingTableProps = Pick<
  TableProps,
  "isError" | "isLoading"
> & {
  listingItems: BillingsTaxiUnbilledPrivateOrderListingItem[];
};

const BillingsTaxiUnbilledPrivateOrderListingTableComponent: FC<
  BillingsTaxiUnbilledPrivateOrderListingTableProps
> = (props) => {
  const columns: BillingsTaxiUnbilledPrivateOrderListingTableColumn[] =
    billingsTaxiUnbilledPrivateOrderListingTableHelper.getColumns();

  const rows: BillingsTaxiUnbilledPrivateOrderListingTableRow[] =
    billingsTaxiUnbilledPrivateOrderListingTableHelper.getRows(
      props.listingItems
    );

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isError={props.isError}
      isLoading={props.isLoading}
    />
  );
};

export default BillingsTaxiUnbilledPrivateOrderListingTableComponent;
