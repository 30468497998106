import isEqual from "lodash/isEqual";
import BillingsTaxiOrderListingFilterSelectOption, {
  BillingsTaxiOrderListingBillingModelFilterSelectOption,
  BillingsTaxiOrderListingBillingStatusFilterSelectOption,
  BillingsTaxiOrderListingBillingTypeFilterSelectOption,
  BillingsTaxiOrderListingInternalOrderIdFilterSelectOption,
  BillingsTaxiOrderListingPlanEntryHumanIdsFilterSelectOption,
} from "./types/billings-taxi-order-listing-filter-select-option";
import billingsTranslationsHelper from "../../../../../languages/billings-translations.helper";
import BillingsTaxiOrderListingFilterType from "./types/billings-taxi-order-listing-filter-type";
import BillingsTaxiOrderListingItemBillingStatus from "./types/billings-taxi-order-listing-item-billing-status";
import billingsTaxiOrderListingHelper from "./billings-taxi-order-listing.helper";
import BillingsTaxiOrderListingItemBillingType from "./types/billings-taxi-order-listing-item-billing-type";
import BillingModel from "../../../../../common/types/billing-model";
import BillingsTaxiOrderListingFilter, {
  BillingsTaxiOrderListingOrderStartDateFilter,
} from "./types/billings-taxi-order-listing-filter";

const getSearchQueryOptions = (
  query: string
): BillingsTaxiOrderListingFilterSelectOption[] => {
  if (!query) {
    return [];
  }

  const searchFilterTranslations =
    billingsTranslationsHelper.getTaxiOrderBillingsListingTranslations().filters
      .search;

  const parseStringToNumber = (string: string): number | undefined => {
    const stringAsNumber = Number(string);

    if (Number.isNaN(stringAsNumber)) {
      return undefined;
    }

    return stringAsNumber;
  };

  const getSearchByInternalOrderIdLabel = (query: string) => {
    return searchFilterTranslations.searchByInternalOrderIdTemplateLabel.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByPlanEntryHumanIdsLabel = (query: string) => {
    return searchFilterTranslations.searchByPlanEntryHumanIdsTemplateLabel.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByCargoCompanyLabel = (query: string) => {
    return searchFilterTranslations.searchByCargoCompanyTemplateLabel.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByExcludeCargoCompanyLabel = (query: string) => {
    return searchFilterTranslations.searchByExcludeCargoCompanyTemplateLabel.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByPassengerLabel = (query: string) => {
    return searchFilterTranslations.searchByPassengerTemplateLabel.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByDestinationTaxiLabel = (query: string) => {
    return searchFilterTranslations.searchByDestinationTaxiTemplateLabel.replace(
      `#{query}`,
      query
    );
  };

  const stringOptions = [
    {
      label: getSearchByCargoCompanyLabel(query),
      value: {
        type: BillingsTaxiOrderListingFilterType.CARGO_COMPANY,
        value: query,
      },
    },
    {
      label: getSearchByExcludeCargoCompanyLabel(query),
      value: {
        type: BillingsTaxiOrderListingFilterType.EXCLUDE_CARGO_COMPANY,
        value: query,
      },
    },
    {
      label: getSearchByPassengerLabel(query),
      value: {
        type: BillingsTaxiOrderListingFilterType.PASSENGER,
        value: query,
      },
    },
    {
      label: getSearchByDestinationTaxiLabel(query),
      value: {
        type: BillingsTaxiOrderListingFilterType.DESTINATION_TAXI,
        value: query,
      },
    },
  ];

  const options: BillingsTaxiOrderListingFilterSelectOption[] = [
    ...stringOptions,
  ] as BillingsTaxiOrderListingFilterSelectOption[];

  if (parseStringToNumber(query) !== undefined) {
    const internalIdFilterOption: BillingsTaxiOrderListingInternalOrderIdFilterSelectOption =
      {
        label: getSearchByInternalOrderIdLabel(query),
        value: {
          type: BillingsTaxiOrderListingFilterType.INTERNAL_ORDER_ID,
          value: Number(query),
        },
      };

    const planEntryHumanIdsFilterOption: BillingsTaxiOrderListingPlanEntryHumanIdsFilterSelectOption =
      {
        label: getSearchByPlanEntryHumanIdsLabel(query),
        value: {
          type: BillingsTaxiOrderListingFilterType.PLAN_ENTRY_HUMAN_IDS,
          value: Number(query),
        },
      };

    options.push(internalIdFilterOption, planEntryHumanIdsFilterOption);
  }

  return options;
};

const getSearchStatusOptions = (
  query: string
): BillingsTaxiOrderListingBillingStatusFilterSelectOption[] => {
  const searchFilterTranslations =
    billingsTranslationsHelper.getTaxiOrderBillingsListingTranslations().filters
      .search;

  const allSelectOptions: BillingsTaxiOrderListingBillingStatusFilterSelectOption[] =
    Object.values(BillingsTaxiOrderListingItemBillingStatus).map((status) => {
      const label =
        searchFilterTranslations.searchByBillingStatusTemplateLabel.replace(
          "#{option}",
          billingsTaxiOrderListingHelper.getBillingStatusText(status)
        );

      return {
        label,
        value: {
          type: BillingsTaxiOrderListingFilterType.BILLING_STATUS,
          value: status,
        },
      };
    });

  if (query) {
    return allSelectOptions.filter((option) =>
      option.label.toLowerCase().includes(query.toLowerCase())
    );
  }

  return allSelectOptions;
};

const getSearchTypeOptions = (
  query: string
): BillingsTaxiOrderListingBillingTypeFilterSelectOption[] => {
  const searchFilterTranslations =
    billingsTranslationsHelper.getTaxiOrderBillingsListingTranslations().filters
      .search;

  const allSelectOptions: BillingsTaxiOrderListingBillingTypeFilterSelectOption[] =
    Object.values(BillingsTaxiOrderListingItemBillingType).map((type) => {
      const label =
        searchFilterTranslations.searchByBillingTypeTemplateLabel.replace(
          "#{option}",
          billingsTaxiOrderListingHelper.getBillingTypeText(type)
        );

      return {
        label,
        value: {
          type: BillingsTaxiOrderListingFilterType.BILLING_TYPE,
          value: type,
        },
      };
    });

  if (query) {
    return allSelectOptions.filter((option) =>
      option.label.toLowerCase().includes(query.toLowerCase())
    );
  }

  return allSelectOptions;
};

const getSearchModelOptions = (
  query: string
): BillingsTaxiOrderListingBillingModelFilterSelectOption[] => {
  const searchFilterTranslations =
    billingsTranslationsHelper.getTaxiOrderBillingsListingTranslations().filters
      .search;

  const allSelectOptions: BillingsTaxiOrderListingBillingModelFilterSelectOption[] =
    Object.values(BillingModel).map((type) => {
      const label =
        searchFilterTranslations.searchByBillingModelTemplateLabel.replace(
          "#{option}",
          billingsTaxiOrderListingHelper.getBillingModelText(type)
        );

      return {
        label,
        value: {
          type: BillingsTaxiOrderListingFilterType.BILLING_MODEL,
          value: type,
        },
      };
    });

  if (query) {
    return allSelectOptions.filter((option) =>
      option.label.toLowerCase().includes(query.toLowerCase())
    );
  }

  return allSelectOptions;
};

const getSelectOptionsByQuery = (
  query: string
): BillingsTaxiOrderListingFilterSelectOption[] => {
  const statusOptions = getSearchStatusOptions(query);
  const typeOptions = getSearchTypeOptions(query);
  const modelOptions = getSearchModelOptions(query);
  const queryOptions = getSearchQueryOptions(query);

  return [...statusOptions, ...typeOptions, ...modelOptions, ...queryOptions];
};

const getSelectOptions = (
  query: string,
  selectedFilters: BillingsTaxiOrderListingFilter[]
): BillingsTaxiOrderListingFilterSelectOption[] => {
  const allFilterOptions = getSelectOptionsByQuery(query);

  return allFilterOptions.filter((option) => {
    return !selectedFilters.find((selectedFilter) =>
      isEqual(selectedFilter.type, option.value.type)
    );
  });
};

const getDefaultFilters = (
  filters: BillingsTaxiOrderListingFilter[]
): BillingsTaxiOrderListingFilter[] => {
  const currentDate = new Date();

  const defaultFilters = [
    {
      type: BillingsTaxiOrderListingFilterType.ORDER_START_DATE,
      value: {
        from: new Date(currentDate.setDate(1)),
        to: new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0),
      },
    } as BillingsTaxiOrderListingOrderStartDateFilter,
  ];

  defaultFilters.forEach((defaultFilter) => {
    const existingFilter = filters.find(
      (filter) => filter.type === defaultFilter.type
    );

    if (existingFilter) {
      return;
    }

    filters.push(defaultFilter);
  });

  return filters;
};

const billingsTaxiOrderListingFilterHelper = {
  getDefaultFilters,
  getSelectOptions,
};

export default billingsTaxiOrderListingFilterHelper;
