import paginationService from "../../../../../common/utils/pagination/pagination.service";
import BillingsTaxiUnbilledPrivateOrderListingRequest, {
  BillingsTaxiUnbilledPrivateOrderListingRequestOrder,
} from "./api/billings-taxi-unbilled-private-order-listing.request";
import BillingsTaxiUnbilledPrivateOrderListingFilter, {
  BillingsTaxiUnbilledPrivateOrderListingClientFilter,
  BillingsTaxiUnbilledPrivateOrderListingDispatchFilter,
  BillingsTaxiUnbilledPrivateOrderListingDriverFilter,
  BillingsTaxiUnbilledPrivateOrderListingExcludeClientFilter,
  BillingsTaxiUnbilledPrivateOrderListingExternalOrderIdFilter,
  BillingsTaxiUnbilledPrivateOrderListingInternalOrderIdFilter,
  BillingsTaxiUnbilledPrivateOrderListingPassengerFilter,
  BillingsTaxiUnbilledPrivateOrderListingRouteAddressFilter,
  BillingsTaxiUnbilledPrivateOrderListingRouteDestinationAddressFilter,
  BillingsTaxiUnbilledPrivateOrderListingRouteIntermediateAddressFilter,
  BillingsTaxiUnbilledPrivateOrderListingRoutePickupAddressFilter,
  BillingsTaxiUnbilledPrivateOrderListingStartDateFilter,
  BillingsTaxiUnbilledPrivateOrderListingStatusFilter,
} from "./types/billings-taxi-unbilled-private-order-listing-filter";
import BillingsTaxiUnbilledPrivateOrderListingFilterType from "./types/billings-taxi-unbilled-private-order-listing-filter-type";
import BillingsTaxiUnbilledPrivateOrderListingItemStatus from "./types/billings-taxi-unbilled-private-order-listing-item-status";
import BillingsTaxiUnbilledPrivateOrderListingSortKey from "./types/billings-taxi-unbilled-private-order-listing-sort-key";

const createRequestIsCancelled = (
  filters: BillingsTaxiUnbilledPrivateOrderListingFilter[]
): BillingsTaxiUnbilledPrivateOrderListingRequest["is_cancelled"] => {
  const filterValue = filters.find(
    (filter) =>
      filter.type === BillingsTaxiUnbilledPrivateOrderListingFilterType.STATUS
  )?.value as
    | BillingsTaxiUnbilledPrivateOrderListingStatusFilter["value"]
    | undefined;

  if (filterValue === undefined) {
    return undefined;
  }

  if (
    filterValue === BillingsTaxiUnbilledPrivateOrderListingItemStatus.CANCELLED
  ) {
    return true;
  }

  return false;
};

const createRequestOrder = (
  sortKey: BillingsTaxiUnbilledPrivateOrderListingSortKey | null
): BillingsTaxiUnbilledPrivateOrderListingRequest["order"] => {
  const options: {
    sortKey: BillingsTaxiUnbilledPrivateOrderListingSortKey;
    requestOrder: BillingsTaxiUnbilledPrivateOrderListingRequestOrder;
  }[] = [
    {
      requestOrder:
        BillingsTaxiUnbilledPrivateOrderListingRequestOrder.HUMAN_ID_ASC,
      sortKey:
        BillingsTaxiUnbilledPrivateOrderListingSortKey.INTERNAL_ORDER_ID_ASC,
    },
    {
      requestOrder:
        BillingsTaxiUnbilledPrivateOrderListingRequestOrder.HUMAN_ID_DESC,
      sortKey:
        BillingsTaxiUnbilledPrivateOrderListingSortKey.INTERNAL_ORDER_ID_DESC,
    },
    {
      requestOrder:
        BillingsTaxiUnbilledPrivateOrderListingRequestOrder.START_TIME_ASC,
      sortKey: BillingsTaxiUnbilledPrivateOrderListingSortKey.START_DATE_ASC,
    },
    {
      requestOrder:
        BillingsTaxiUnbilledPrivateOrderListingRequestOrder.START_TIME_DESC,
      sortKey: BillingsTaxiUnbilledPrivateOrderListingSortKey.START_DATE_DESC,
    },
  ];

  return options.find((option) => option.sortKey === sortKey)?.requestOrder;
};

const createRequest = (
  page: number,
  pageSize: number,
  filters: BillingsTaxiUnbilledPrivateOrderListingFilter[],
  sortKey: BillingsTaxiUnbilledPrivateOrderListingSortKey | null
): BillingsTaxiUnbilledPrivateOrderListingRequest => {
  const offset = paginationService.calculateOffset(page, pageSize);

  return {
    order: createRequestOrder(sortKey),
    address: filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiUnbilledPrivateOrderListingFilterType.ROUTE_ADDRESS
    )?.value as
      | BillingsTaxiUnbilledPrivateOrderListingRouteAddressFilter["value"]
      | undefined,
    limit: pageSize,
    client: filters.find(
      (filter) =>
        filter.type === BillingsTaxiUnbilledPrivateOrderListingFilterType.CLIENT
    )?.value as
      | BillingsTaxiUnbilledPrivateOrderListingClientFilter["value"]
      | undefined,
    exclude_cargo_company: filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiUnbilledPrivateOrderListingFilterType.EXCLUDE_CARGO_COMPANY
    )?.value as
      | BillingsTaxiUnbilledPrivateOrderListingExcludeClientFilter["value"]
      | undefined,
    dispatch: filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiUnbilledPrivateOrderListingFilterType.DISPATCH
    )?.value as
      | BillingsTaxiUnbilledPrivateOrderListingDispatchFilter["value"]
      | undefined,
    driver: filters.find(
      (filter) =>
        filter.type === BillingsTaxiUnbilledPrivateOrderListingFilterType.DRIVER
    )?.value as
      | BillingsTaxiUnbilledPrivateOrderListingDriverFilter["value"]
      | undefined,
    end_address: filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiUnbilledPrivateOrderListingFilterType.ROUTE_DESTINATION_ADDRESS
    )?.value as
      | BillingsTaxiUnbilledPrivateOrderListingRouteDestinationAddressFilter["value"]
      | undefined,
    external_id: filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiUnbilledPrivateOrderListingFilterType.EXTERNAL_ORDER_ID
    )?.value as
      | BillingsTaxiUnbilledPrivateOrderListingExternalOrderIdFilter["value"]
      | undefined,
    human_id: filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiUnbilledPrivateOrderListingFilterType.INTERNAL_ORDER_ID
    )?.value as
      | BillingsTaxiUnbilledPrivateOrderListingInternalOrderIdFilter["value"]
      | undefined,
    is_cancelled: createRequestIsCancelled(filters),
    mid_address: filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiUnbilledPrivateOrderListingFilterType.ROUTE_INTERMEDIATE_ADDRESS
    )?.value as
      | BillingsTaxiUnbilledPrivateOrderListingRouteIntermediateAddressFilter["value"]
      | undefined,
    offset,
    passenger: filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiUnbilledPrivateOrderListingFilterType.PASSENGER
    )?.value as
      | BillingsTaxiUnbilledPrivateOrderListingPassengerFilter["value"]
      | undefined,
    start_address: filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiUnbilledPrivateOrderListingFilterType.ROUTE_PICKUP_ADDRESS
    )?.value as
      | BillingsTaxiUnbilledPrivateOrderListingRoutePickupAddressFilter["value"]
      | undefined,
    start_time_since: (
      filters.find(
        (filter) =>
          filter.type ===
          BillingsTaxiUnbilledPrivateOrderListingFilterType.START_DATE
      )?.value as
        | BillingsTaxiUnbilledPrivateOrderListingStartDateFilter["value"]
        | undefined
    )?.from?.toJSON(),
    start_time_to: (
      filters.find(
        (filter) =>
          filter.type ===
          BillingsTaxiUnbilledPrivateOrderListingFilterType.START_DATE
      )?.value as
        | BillingsTaxiUnbilledPrivateOrderListingStartDateFilter["value"]
        | undefined
    )?.to?.toJSON(),
  };
};

const billingsTaxiUnbilledPrivateOrderListingRequestFactory = {
  createRequest,
};

export default billingsTaxiUnbilledPrivateOrderListingRequestFactory;
