import CargoOrderAbandonedListRequest, {
  CargoOrderAbandonedListRequestOrder,
} from "../../../api/raily/cargo-order/abandoned-list/cargo-order-abandoned-list.request";
import paginationService from "../../../utils/pagination/pagination.service";
import CargoOrderAbandonedListLoadParams, {
  OrderAbandonedListLoadParamsOrder,
} from "./cargo-order-abandoned-list-load-params";

const createOrder = (
  order: OrderAbandonedListLoadParamsOrder | null
): CargoOrderAbandonedListRequestOrder | null => {
  switch (order) {
    case OrderAbandonedListLoadParamsOrder.CREATED_AT_ASC:
      return CargoOrderAbandonedListRequestOrder.CREATED_AT_ASC;
    case OrderAbandonedListLoadParamsOrder.CREATED_AT_DESC:
      return CargoOrderAbandonedListRequestOrder.CREATED_AT_DESC;
    case OrderAbandonedListLoadParamsOrder.HUMAN_ID_ASC:
      return CargoOrderAbandonedListRequestOrder.HUMAN_ID_ASC;
    case OrderAbandonedListLoadParamsOrder.HUMAN_ID_DESC:
      return CargoOrderAbandonedListRequestOrder.HUMAN_ID_DESC;
    case OrderAbandonedListLoadParamsOrder.MODIFIED_AT_ASC:
      return CargoOrderAbandonedListRequestOrder.MODIFIED_AT_ASC;
    case OrderAbandonedListLoadParamsOrder.MODIFIED_AT_DESC:
      return CargoOrderAbandonedListRequestOrder.MODIFIED_AT_DESC;
    default:
      return null;
  }
};

const create = (
  params: CargoOrderAbandonedListLoadParams
): CargoOrderAbandonedListRequest => {
  return {
    limit: params.pageSize,
    offset: paginationService.calculateOffset(params.page, params.pageSize),
    order: createOrder(params.order),
    address: params.address,
    client: params.client,
    creationEnd: params.creationEnd,
    creationStart: params.creationStart,
    dispatch: params.dispatch,
    endAddress: params.endAddress,
    externalId: params.externalId,
    humanId: params.humanId,
    midAddress: params.midAddress,
    passenger: params.passenger,
    publicStatus: params.publicStatus,
    startAddress: params.startAddress,
    startTimeSince: params.startTimeSince,
    startTimeTo: params.startTimeTo,
  };
};

const cargoOrderAbandonedListRequestFactory = { create };

export default cargoOrderAbandonedListRequestFactory;
