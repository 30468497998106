import appTranslationsHelper from "./app-translations.helper";
import BillingsCargoRailyAddTranslations from "./types/billings/add/billings-cargo-raily-add.translations";
import BillingsCargoTaxiAddTranslations from "./types/billings/add/billings-cargo-taxi-add.translations";
import BillingsRailyTaxiAddTranslations from "./types/billings/add/billings-raily-taxi-add.translations";
import BillingsTaxiDriverAddTranslations from "./types/billings/add/billings-taxi-driver-add.translations";
import BillingsTaxiRailyAddTranslations from "./types/billings/add/billings-taxi-raily-add.translations";
import BillingsCargoTaxiEditTranslations from "./types/billings/edit/billings-cargo-taxi-edit.translations";
import BillingsTaxiDriverEditTranslations from "./types/billings/edit/billings-taxi-driver-edit.translations";
import BillingsCargoTaxiViewTranslations from "./types/billings/view/billings-cargo-taxi-view.translations";
import BillingsAddressAddTranslations from "./types/billings/billings-address-add.translations";
import BillingsTaxiTaxiAddTranslations from "./types/billings/add/billings-taxi-taxi-add.translations";
import BillingsTaxiTaxiEditTranslations from "./types/billings/edit/billings-taxi-taxi-edit.translations";
import BillingsTaxiTaxiViewTranslations from "./types/billings/view/billings-taxi-taxi-view.translations";
import BillingsTaxiDriverViewTranslations from "./types/billings/view/billings-taxi-driver-view.translations";
import BillingsTranslations from "./types/billings/billings.translations";
import BillingsRouteDetails from "./types/billings/billings-route-details.translations";
import BillingsTaxiDriverContributions from "./types/billings/billings-taxi-driver-contributions.translations";
import BillingsRouteSummary from "./types/billings/billings-route-summary.translations";
import BillingsTaxiTaxiContributions from "./types/billings/billings-taxi-taxi-contributions.translations";
import BillingsCargoTaxiContributions from "./types/billings/billings-cargo-taxi-contributions.translations";
import BillingsCargoOrderListingTranslations from "./types/billings/cargo/billings-cargo-order-listing.translations";
import BillingsTaxiOrderListingTranslations from "./types/billings/taxi/billings-taxi-order-listing.translations";
import BillingsTaxiRouteListingTranslations from "./types/billings/taxi/billings-taxi-route-listing.translations";
import BillingsTaxiTransferredOrderListingTranslations from "./types/billings/taxi/billings-taxi-transferred-order-listing.translations";
import BillingsTaxiUnbilledOrderListingTranslations from "./types/billings/taxi/billings-taxi-unbilled-order-listing.translations";
import BillingsTaxiUnbilledPrivateOrderListingTranslations from "./types/billings/taxi/billings-taxi-unbilled-private-order-listing.translations";
import BillingsTaxiUnbilledRouteListingTranslations from "./types/billings/taxi/billing-taxi-unbilled-route-listing.translations";
import BillingsTaxiUnbilledTransferredOrderListingTranslations from "./types/billings/taxi/billings-taxi-unbilled-transferred-order-listing.translations";

const getTranslations = <T>(internalFilePath: string): T => {
  const baseFilePath = `billings`;

  const filePath = `${baseFilePath}/${internalFilePath}`;

  return appTranslationsHelper.getTranslations(filePath);
};

const getCargoRailyAddBillingsTranslations =
  (): BillingsCargoRailyAddTranslations => {
    const translations = getTranslations<BillingsCargoRailyAddTranslations>(
      `add/cargo-raily-billings.translations.json`
    );

    return translations;
  };

const getTaxiRailyAddBillingsTranslations =
  (): BillingsTaxiRailyAddTranslations => {
    const translations = getTranslations<BillingsTaxiRailyAddTranslations>(
      `add/taxi-raily-billings.translations.json`
    );

    return translations;
  };

const getCargoTaxiAddBillingsTranslations =
  (): BillingsCargoTaxiAddTranslations => {
    const translations = getTranslations<BillingsCargoTaxiAddTranslations>(
      `add/cargo-taxi-billings.translations.json`
    );

    return translations;
  };

const getCargoTaxiEditBillingsTranslations =
  (): BillingsCargoTaxiEditTranslations => {
    const translations = getTranslations<BillingsCargoTaxiEditTranslations>(
      `edit/cargo-taxi-billings.translations.json`
    );

    return translations;
  };

const getCargoTaxiViewBillingsTranslations =
  (): BillingsCargoTaxiViewTranslations => {
    const translations = getTranslations<BillingsCargoTaxiViewTranslations>(
      `view/cargo-taxi-billings.translations.json`
    );

    return translations;
  };

const getRailyTaxiAddBillingsTranslations =
  (): BillingsRailyTaxiAddTranslations => {
    const translations = getTranslations<BillingsRailyTaxiAddTranslations>(
      `add/raily-taxi-billings.translations.json`
    );

    return translations;
  };

const getTaxiDriverAddBillingsTranslations =
  (): BillingsTaxiDriverAddTranslations => {
    const translations = getTranslations<BillingsTaxiDriverAddTranslations>(
      `add/taxi-driver-billings.translations.json`
    );

    return translations;
  };

const getTaxiTaxiAddBillingsTranslations =
  (): BillingsTaxiTaxiAddTranslations => {
    const translations = getTranslations<BillingsTaxiTaxiAddTranslations>(
      `add/taxi-taxi-billings.translations.json`
    );

    return translations;
  };

const getTaxiTaxiEditBillingsTranslations =
  (): BillingsTaxiTaxiEditTranslations => {
    const translations = getTranslations<BillingsTaxiTaxiEditTranslations>(
      `edit/taxi-taxi-billings.translations.json`
    );

    return translations;
  };

const getTaxiTaxiViewBillingsTranslations =
  (): BillingsTaxiTaxiViewTranslations => {
    const translations = getTranslations<BillingsTaxiTaxiViewTranslations>(
      `view/taxi-taxi-billings.translations.json`
    );

    return translations;
  };

const getTaxiDriverViewBillingsTranslations =
  (): BillingsTaxiDriverViewTranslations => {
    const translations = getTranslations<BillingsTaxiDriverViewTranslations>(
      `view/taxi-driver-billings.translations.json`
    );

    return translations;
  };

const getTaxiDriverEditBillingsTranslations =
  (): BillingsTaxiDriverEditTranslations => {
    const translations = getTranslations<BillingsTaxiDriverEditTranslations>(
      `edit/taxi-driver-billings.translations.json`
    );

    return translations;
  };

const getTaxiDriverContributionsTranslations =
  (): BillingsTaxiDriverContributions => {
    const translations = getTranslations<BillingsTaxiDriverContributions>(
      `billings-taxi-driver-contributions.translations.json`
    );

    return translations;
  };

const getBillingsAddressAddTranslations =
  (): BillingsAddressAddTranslations => {
    const translations = getTranslations<BillingsAddressAddTranslations>(
      `billings-address-add.translations.json`
    );

    return translations;
  };

const getBillingsTranslations = (): BillingsTranslations => {
  const translations = getTranslations<BillingsTranslations>(
    `billings.translations.json`
  );

  return translations;
};

const getRouteDetailsTranslations = (): BillingsRouteDetails => {
  const translations = getTranslations<BillingsRouteDetails>(
    `billings-route-details.translations.json`
  );

  return translations;
};

const getRouteSummaryTranslations = (): BillingsRouteSummary => {
  const translations = getTranslations<BillingsRouteSummary>(
    `billings-route-summary.translations.json`
  );

  return translations;
};

const getTaxiTaxiContributionsTranslations =
  (): BillingsTaxiTaxiContributions => {
    const translations = getTranslations<BillingsTaxiTaxiContributions>(
      `billings-taxi-taxi-contributions.translations.json`
    );

    return translations;
  };

const getCargoTaxiContributionsTranslations =
  (): BillingsCargoTaxiContributions => {
    const translations = getTranslations<BillingsCargoTaxiContributions>(
      `billings-cargo-taxi-contributions.translations.json`
    );

    return translations;
  };

const getCargoOrderBillingsListingTranslations =
  (): BillingsCargoOrderListingTranslations => {
    const translations = getTranslations<BillingsCargoOrderListingTranslations>(
      `cargo/billings-cargo-order-listing.translations.json`
    );

    return translations;
  };

const getTaxiOrderBillingsListingTranslations =
  (): BillingsTaxiOrderListingTranslations => {
    const translations = getTranslations<BillingsTaxiOrderListingTranslations>(
      `taxi/billings-taxi-order-listing.translations.json`
    );

    return translations;
  };

const getTaxiRouteBillingsListingTranslations =
  (): BillingsTaxiRouteListingTranslations => {
    const translations = getTranslations<BillingsTaxiRouteListingTranslations>(
      `taxi/billings-taxi-route-listing.translations.json`
    );

    return translations;
  };

const getTaxiRouteTransferredOrderBillingsListingTranslations =
  (): BillingsTaxiTransferredOrderListingTranslations => {
    const translations =
      getTranslations<BillingsTaxiTransferredOrderListingTranslations>(
        `taxi/billings-taxi-transferred-order-listing.translations.json`
      );

    return translations;
  };

const getTaxiUnbilledOrderBillingsListingTranslations =
  (): BillingsTaxiUnbilledOrderListingTranslations => {
    const translations =
      getTranslations<BillingsTaxiUnbilledOrderListingTranslations>(
        `taxi/billings-taxi-unbilled-order-listing.translations.json`
      );

    return translations;
  };

const getTaxiUnbilledPrivateOrderBillingsListingTranslations =
  (): BillingsTaxiUnbilledPrivateOrderListingTranslations => {
    const translations =
      getTranslations<BillingsTaxiUnbilledPrivateOrderListingTranslations>(
        `taxi/billings-taxi-unbilled-private-order-listing.translations.json`
      );

    return translations;
  };

const getTaxiUnbilledRouteBillingsListingTranslations =
  (): BillingsTaxiUnbilledRouteListingTranslations => {
    const translations =
      getTranslations<BillingsTaxiUnbilledRouteListingTranslations>(
        `taxi/billings-taxi-unbilled-route-listing.translations.json`
      );

    return translations;
  };

const getTaxiUnbilledTransferredOrderBillingsListingTranslations =
  (): BillingsTaxiUnbilledTransferredOrderListingTranslations => {
    const translations =
      getTranslations<BillingsTaxiUnbilledTransferredOrderListingTranslations>(
        `taxi/billings-taxi-unbilled-transferred-order-listing.translations.json`
      );

    return translations;
  };

const billingsTranslationsHelper = {
  getCargoRailyAddBillingsTranslations,
  getCargoTaxiAddBillingsTranslations,
  getRailyTaxiAddBillingsTranslations,
  getTaxiDriverAddBillingsTranslations,
  getTaxiRailyAddBillingsTranslations,
  getCargoTaxiEditBillingsTranslations,
  getCargoTaxiViewBillingsTranslations,
  getTaxiDriverEditBillingsTranslations,
  getBillingsAddressAddTranslations,
  getTaxiTaxiAddBillingsTranslations,
  getTaxiTaxiEditBillingsTranslations,
  getTaxiDriverContributionsTranslations,
  getTaxiTaxiViewBillingsTranslations,
  getTaxiDriverViewBillingsTranslations,
  getBillingsTranslations,
  getRouteDetailsTranslations,
  getRouteSummaryTranslations,
  getTaxiTaxiContributionsTranslations,
  getCargoTaxiContributionsTranslations,
  getCargoOrderBillingsListingTranslations,
  getTaxiOrderBillingsListingTranslations,
  getTaxiRouteBillingsListingTranslations,
  getTaxiRouteTransferredOrderBillingsListingTranslations,
  getTaxiUnbilledOrderBillingsListingTranslations,
  getTaxiUnbilledPrivateOrderBillingsListingTranslations,
  getTaxiUnbilledRouteBillingsListingTranslations,
  getTaxiUnbilledTransferredOrderBillingsListingTranslations,
};

export default billingsTranslationsHelper;
