import BillingsTaxiOrderListingByRailyTaxiCorporationSelectOption from "./types/billings-taxi-order-listing-by-raily-taxi-corporation-select-option";
import { BillingsTaxiOrderListingByRailyTaxiCorporationsResponseDataItem } from "./api/billings-taxi-order-listing-by-raily-taxi-corporations.response";
import BillingsTaxiOrderListingFactory from "../../common/billings-taxi-order-listing.factory";

const createTaxiCorporationSelectOption = (
  responseTaxiCorporation: BillingsTaxiOrderListingByRailyTaxiCorporationsResponseDataItem
): BillingsTaxiOrderListingByRailyTaxiCorporationSelectOption => {
  return {
    label: responseTaxiCorporation.display_name,
    value: {
      uuid: responseTaxiCorporation.id,
      displayName: responseTaxiCorporation.display_name,
    },
  };
};

const createTaxiCorporationSelectOptions = (
  responseTaxiCorporations: BillingsTaxiOrderListingByRailyTaxiCorporationsResponseDataItem[]
): BillingsTaxiOrderListingByRailyTaxiCorporationSelectOption[] => {
  return responseTaxiCorporations.map(createTaxiCorporationSelectOption);
};

const billingsTaxiOrderListingByRailyFactory = {
  createTaxiCorporationSelectOptions,
  createListingItems: BillingsTaxiOrderListingFactory.createListingItems,
  createStatsSummary: BillingsTaxiOrderListingFactory.createStatsSummary,
};

export default billingsTaxiOrderListingByRailyFactory;
