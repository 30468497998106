import billingsTaxiUnbilledRouteListingRouteQueryParamsService from "../../common/billings-taxi-unbilled-route-listing-route-query-params.service";

const billingsTaxiUnbilledRouteListingByTaxiRouteQueryParamsService = {
  getFilters:
    billingsTaxiUnbilledRouteListingRouteQueryParamsService.getFilters,
  getSortKey:
    billingsTaxiUnbilledRouteListingRouteQueryParamsService.getSortKey,
  getPage: billingsTaxiUnbilledRouteListingRouteQueryParamsService.getPage,
  getPageSize:
    billingsTaxiUnbilledRouteListingRouteQueryParamsService.getPageSize,
  createRouteQueryParams:
    billingsTaxiUnbilledRouteListingRouteQueryParamsService.createRouteQueryParams,
};

export default billingsTaxiUnbilledRouteListingByTaxiRouteQueryParamsService;
