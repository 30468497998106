import billingsTranslationsHelper from "../../../../../../languages/billings-translations.helper";
import BillingsTaxiUnbilledTransferredOrderListingSortKey from "../types/billings-taxi-unbilled-transferred-order-listing-sort-key";
import BillingsTaxiUnbilledTransferredOrderListingSortSelectOption from "../types/billings-taxi-unbilled-transferred-order-listing-sort-select-option";

const getSelectOptions =
  (): BillingsTaxiUnbilledTransferredOrderListingSortSelectOption[] => {
    const translations =
      billingsTranslationsHelper.getTaxiUnbilledTransferredOrderBillingsListingTranslations()
        .sort;

    return [
      {
        label: translations.consumerAscOptionLabel,
        value: BillingsTaxiUnbilledTransferredOrderListingSortKey.CONSUMER_ASC,
      },
      {
        label: translations.consumerDescOptionLabel,
        value: BillingsTaxiUnbilledTransferredOrderListingSortKey.CONSUMER_DESC,
      },
      {
        label: translations.producerAscOptionLabel,
        value: BillingsTaxiUnbilledTransferredOrderListingSortKey.PRODUCER_ASC,
      },
      {
        label: translations.producerDescOptionLabel,
        value: BillingsTaxiUnbilledTransferredOrderListingSortKey.PRODUCER_DESC,
      },
    ];
  };

const billingsTaxiUnbilledTransferredOrderListingSortHelper = {
  getSelectOptions,
};

export default billingsTaxiUnbilledTransferredOrderListingSortHelper;
