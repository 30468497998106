import { FC, useEffect, useMemo, useState } from "react";
import useIsComponentMounted from "../../../../../common/hooks/use-is-component-mounted";
import useRouteQueryParams from "../../../../../common/hooks/use-route-query-params";
import BillingsTaxiOrderListingByRailyRouteQueryParams from "./common/types/billings-taxi-order-listing-by-raily-route-query-params";
import BillingsTaxiOrderListingItem from "../common/types/billings-taxi-order-listing-item";
import BillingsTaxiOrderListingFilter, {
  BillingsTaxiOrderListingOrderStartDateFilter,
} from "../common/types/billings-taxi-order-listing-filter";
import BillingsTaxiOrderListingSortKey from "../common/types/billings-taxi-order-listing-sort-key";
import BillingsTaxiOrderListingSortSelectOption from "../common/types/billings-taxi-order-listing-sort-select-option";
import BillingsTaxiOrderListingSortHelper from "../common/billings-taxi-order-listing-sort.helper";
import usePagination from "../../../../../common/hooks/use-pagination";
import billingsTaxiOrderListingByRailyRouteQueryParamsService from "./common/billings-taxi-order-listing-by-raily-route-query-params.service";
import HeadingComponent from "../../../../../common/components/heading/heading.component";
import BillingsTaxiOrderListingStatusLegendComponent from "../common/status-legend/billings-taxi-order-listing-status-legend.component";
import BillingsTaxiOrderListingFiltersSelectComponent from "../common/filters/select/billings-taxi-order-listing-filters-select.component";
import ListingSortSelectComponent from "../../../../../common/components/listing/filter/sort/select/listing-sort-select.component";
import BillingsTaxiOrderListingFiltersBadgeListComponent from "../common/filters/list/billings-taxi-order-listing-filters-badge-list.component";
import CardComponent from "../../../../../common/components/card/card.component";
import BillingsTaxiOrderListingTableComponent from "../common/table/billings-taxi-order-listing-table.component";
import PaginationComponent from "../../../../../common/components/pagination/pagination.component";
import BillingsTaxiOrderListingViewBasicProps from "../common/types/billings-taxi-order-listing-view-basic-props";
import billingsTaxiOrderListingByRailyApiService from "./common/api/billings-taxi-order-listing-by-raily-api.service";
import billingsTaxiOrderListingByRailyFactory from "./common/billings-taxi-order-listing-by-raily.factory";
import BillingsTaxiOrderListingFilterType from "../common/types/billings-taxi-order-listing-filter-type";
import DateRange from "../../../../../common/types/date-range";
import BillingsTaxiOrderListingByRailyTaxiCorporationSelectOption from "./common/types/billings-taxi-order-listing-by-raily-taxi-corporation-select-option";
import BillingsTaxiOrderListingByRailyTaxiCorporationsResponse, {
  BillingsTaxiOrderListingByRailyTaxiCorporationsResponseDataItem,
} from "./common/api/billings-taxi-order-listing-by-raily-taxi-corporations.response";
import FormFieldComponent from "../../../../../common/components/form/field/form-field.component";
import BillingsTaxiOrderListingResponse from "../common/api/billings-taxi-order-listing.response";
import billingsTaxiOrderListingRequestFactory from "../common/billings-taxi-order-listing-request.factory";
import { useAppContext } from "../../../../../context/app.context";
import BillingsTaxiOrderListingReportResponse, {
  BillingsTaxiOrderListingReportResponseData,
} from "../common/api/billings-taxi-order-listing-report.response";
import fileDownloadService from "../../../../../common/utils/file-download/file-download.service";
import notificationService from "../../../../../common/utils/notification/notification.service";
import ButtonComponent from "../../../../../common/components/button/button.component";
import billingsTaxiOrderListingReportRequestFactory from "../common/billings-taxi-order-listing-report-request.factory";
import DateRangeInputComponent from "../../../../../common/components/form/input/date-range/date-range-input.component";
import SingleSelectComponent from "../../../../../common/components/form/select/single-select/single-select.component";
import BillingsTaxiOrderListingStatsSummaryComponent from "../common/stats-summary/billings-taxi-order-listing-stats-summary.component";
import BillingsTaxiOrderListingStatsSummary from "../common/types/billings-taxi-order-listing-stats-summary";
import billingsTranslationsHelper from "../../../../../languages/billings-translations.helper";

type BillingsTaxiOrderListingByRailyProps =
  BillingsTaxiOrderListingViewBasicProps;

const BillingsTaxiOrderListingByRailyComponent: FC<
  BillingsTaxiOrderListingByRailyProps
> = (props) => {
  const isComponentMounted = useIsComponentMounted();
  const { selectedAppLanguage } = useAppContext();

  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<BillingsTaxiOrderListingByRailyRouteQueryParams>();

  const translations =
    billingsTranslationsHelper.getTaxiOrderBillingsListingTranslations();

  const [filters, setFilters] = useState<BillingsTaxiOrderListingFilter[]>(() =>
    billingsTaxiOrderListingByRailyRouteQueryParamsService.getFilters(
      routeQueryParams
    )
  );

  const [isListingFetching, setIsListingFetching] = useState(false);
  const [isListingFetchingError, setIsListingFetchingError] = useState(false);
  const [listingItems, setListingItems] = useState<
    BillingsTaxiOrderListingItem[]
  >([]);
  const [statsSummary, setStatsSummary] =
    useState<BillingsTaxiOrderListingStatsSummary | null>(null);
  const [totalResults, setTotalResults] = useState(0);

  const [isReportFetching, setIsReportFetching] = useState(false);

  const [selectedSortKey, setSelectedSortKey] =
    useState<BillingsTaxiOrderListingSortKey | null>(
      () =>
        billingsTaxiOrderListingByRailyRouteQueryParamsService.getSortKey(
          routeQueryParams
        ) ?? null
    );

  const [selectedTaxiCorporationUuid, setSelectedTaxiCorporationUuid] =
    useState<string | null>(
      () =>
        billingsTaxiOrderListingByRailyRouteQueryParamsService.getTaxiCorporationCompanyUuid(
          routeQueryParams
        ) ?? null
    );

  const onTaxiCorporationChange = (
    selectedOption: BillingsTaxiOrderListingByRailyTaxiCorporationSelectOption
  ) => {
    setSelectedTaxiCorporationUuid(selectedOption.value.uuid);
    setPage(1);
  };

  const [isTaxiCorporationFetching, setIsTaxiCorporationFetching] =
    useState(false);
  const [isTaxiCorporationFetchingError, setIsTaxiCorporationFetchingError] =
    useState(false);

  const [taxiCorporationSelectOptions, setTaxiCorporationSelectOptions] =
    useState<BillingsTaxiOrderListingByRailyTaxiCorporationSelectOption[]>([]);

  const selectedTaxiCorporationSelectOption = taxiCorporationSelectOptions.find(
    (option) => option.value.uuid === selectedTaxiCorporationUuid
  );

  const sortSelectOptions: BillingsTaxiOrderListingSortSelectOption[] = useMemo(
    () => BillingsTaxiOrderListingSortHelper.getSelectOptions(),
    [selectedAppLanguage]
  );

  const { page, pageSize, setPage, setPageSize } = usePagination({
    totalResults: totalResults,
    defaultPageSize:
      billingsTaxiOrderListingByRailyRouteQueryParamsService.getPageSize(
        routeQueryParams
      ),
    defaultPage:
      billingsTaxiOrderListingByRailyRouteQueryParamsService.getPage(
        routeQueryParams
      ),
  });

  const onTaxiCorporationFetchSuccess = (
    responseDataItems: BillingsTaxiOrderListingByRailyTaxiCorporationsResponseDataItem[]
  ) => {
    const taxiCorporationsSelectOptions =
      billingsTaxiOrderListingByRailyFactory.createTaxiCorporationSelectOptions(
        responseDataItems
      );

    setTaxiCorporationSelectOptions(taxiCorporationsSelectOptions);
  };

  const onTaxiCorporationFetchFailure = () => {
    setIsTaxiCorporationFetchingError(true);
  };

  const handleTaxiCorporationsResponse = (
    response: BillingsTaxiOrderListingByRailyTaxiCorporationsResponse
  ) => {
    if (response.status === 200) {
      onTaxiCorporationFetchSuccess(response.data.data);
      return;
    }

    onTaxiCorporationFetchFailure();
  };

  useEffect(() => {
    setIsTaxiCorporationFetching(true);
    setIsTaxiCorporationFetchingError(false);

    billingsTaxiOrderListingByRailyApiService
      .fetchTaxiCorporations()
      .then(handleTaxiCorporationsResponse)
      .catch(onTaxiCorporationFetchFailure)
      .finally(() => setIsTaxiCorporationFetching(false));
  }, []);

  const onListingFetchSuccess = (
    response: BillingsTaxiOrderListingResponse
  ) => {
    const listingItems =
      billingsTaxiOrderListingByRailyFactory.createListingItems(
        response.data.data
      );

    const statsSummary =
      billingsTaxiOrderListingByRailyFactory.createStatsSummary(
        response.data.stats
      );

    setListingItems(listingItems);
    setStatsSummary(statsSummary);
    setTotalResults(response.data.total_count);
  };

  const onListingFetchFailure = () => {
    setIsListingFetchingError(true);
  };

  const handleListingResponse = (
    response: BillingsTaxiOrderListingResponse
  ) => {
    if (response.status === 200) {
      onListingFetchSuccess(response);
      return;
    }

    onListingFetchFailure();
  };

  const fetchListing = (signal: AbortSignal) => {
    setIsListingFetching(true);
    setIsListingFetchingError(false);

    const request = billingsTaxiOrderListingRequestFactory.createRequest(
      page,
      pageSize,
      filters,
      selectedSortKey
    );

    billingsTaxiOrderListingByRailyApiService
      .fetchListing(selectedTaxiCorporationUuid!, request, signal)
      .then((data) => {
        if (!signal.aborted) {
          handleListingResponse(data);
        }
      })
      .catch(() => {
        if (!signal.aborted) {
          onListingFetchFailure();
        }
      })
      .finally(() => setIsListingFetching(false));
  };

  useEffect(() => {
    if (!page || !pageSize || !selectedTaxiCorporationUuid) {
      return;
    }
    const abortController = new AbortController();
    const signal = abortController.signal;

    fetchListing(signal);
    return () => {
      abortController.abort();
    };
  }, [filters, selectedTaxiCorporationUuid, selectedSortKey, page, pageSize]);

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    const queryParams =
      billingsTaxiOrderListingByRailyRouteQueryParamsService.createRouteQueryParams(
        selectedTaxiCorporationUuid,
        filters,
        selectedSortKey,
        page,
        pageSize
      );

    setRouteQueryParams(queryParams);
  }, [selectedTaxiCorporationUuid, filters, selectedSortKey, page, pageSize]);

  const addNewFilter = (newFilter: BillingsTaxiOrderListingFilter) => {
    setFilters((curr) => [...curr, newFilter]);
    setPage(1);
  };

  const deleteFilter = (index: number) => {
    const newFilters = filters.filter((filter, _index) => _index !== index);

    setFilters(newFilters);
    setPage(1);
  };

  const deleteAllFilters = () => {
    setFilters([]);
    setPage(1);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    window.scroll({ top: 0, behavior: "smooth" });
  };

  const onPageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
    setPage(1);
  };

  const selectedSortSelectOption = useMemo(() => {
    return (
      sortSelectOptions.find((item) => item.value === selectedSortKey) ?? null
    );
  }, [selectedSortKey, selectedAppLanguage]);

  const startDateFilterValue = filters.find(
    (filter) =>
      filter.type === BillingsTaxiOrderListingFilterType.ORDER_START_DATE
  )?.value as BillingsTaxiOrderListingOrderStartDateFilter["value"] | undefined;

  const onOrderStartDateFilterValueChange = (dateRange: DateRange | null) => {
    if (!dateRange) {
      const newFilters = filters.filter(
        (filter) =>
          filter.type !== BillingsTaxiOrderListingFilterType.ORDER_START_DATE
      );

      setFilters(newFilters);
      return;
    }

    const isFilterExists = !!filters.find(
      (filter) =>
        filter.type === BillingsTaxiOrderListingFilterType.ORDER_START_DATE
    );

    const newFilter: BillingsTaxiOrderListingOrderStartDateFilter = {
      type: BillingsTaxiOrderListingFilterType.ORDER_START_DATE,
      value: {
        from: dateRange.from!,
        to: dateRange.to!,
      },
    };

    if (isFilterExists) {
      const newFilters = [
        ...filters.filter(
          (filter) =>
            filter.type !== BillingsTaxiOrderListingFilterType.ORDER_START_DATE
        ),
        newFilter,
      ];

      setFilters(newFilters);
      setPage(1);
      return;
    }

    setFilters((curr) => [...curr, newFilter]);
    setPage(1);
  };

  const downloadReportFile = (
    responseData: BillingsTaxiOrderListingReportResponseData
  ) => {
    fileDownloadService.downloadFromBlob(
      responseData.data,
      responseData.filename
    );
  };

  const onReportFetchSuccess = async (
    response: BillingsTaxiOrderListingReportResponse
  ) => {
    if (response.status === 200) {
      downloadReportFile(response.data);
      return;
    }

    onReportFetchFailure();
  };

  const onReportFetchFailure = () => {
    notificationService.error(
      translations.report.failureDownloadingNotificationText
    );
  };

  const fetchReport = () => {
    setIsReportFetching(true);

    const request = billingsTaxiOrderListingReportRequestFactory.createRequest(
      filters,
      selectedSortKey
    );

    billingsTaxiOrderListingByRailyApiService
      .fetchReport(selectedTaxiCorporationUuid!, request)
      .then(onReportFetchSuccess)
      .catch(onReportFetchFailure)
      .finally(() => setIsReportFetching(false));
  };

  const onReportDownloadButtonClick = () => {
    fetchReport();
  };

  const ReportDownloadButton = (
    <ButtonComponent
      onClick={onReportDownloadButtonClick}
      type="primary"
      isLoading={isReportFetching}
      title={translations.report.downloadButtonTitle}
      data-test-id="billings-taxi-order-listing-by-raily-report-download-button"
    >
      {translations.report.downloadButtonText}
    </ButtonComponent>
  );

  const isReportDownloadButtonVisible = !!selectedTaxiCorporationUuid;
  const isListingContentVisible = !!selectedTaxiCorporationUuid;

  return (
    <div className="billings_taxi_order_listing">
      <HeadingComponent
        title={translations.header.headingText}
        actions={[
          ...props.actionButtons,
          isReportDownloadButtonVisible && ReportDownloadButton,
        ]}
      />
      <BillingsTaxiOrderListingStatusLegendComponent
        taxiCorporationUuid={selectedTaxiCorporationUuid}
      />
      <FormFieldComponent label={translations.taxiCorporationLabel} isRequired>
        <SingleSelectComponent
          placeholder={translations.taxiCorporationPlaceholder}
          value={selectedTaxiCorporationSelectOption ?? null}
          options={taxiCorporationSelectOptions}
          onChange={(selectedOption) =>
            onTaxiCorporationChange(selectedOption!)
          }
          classNames={{
            root: "billings_taxi_order_listing_taxi_corporation_select",
          }}
          idForTesting={`billings-taxi-order-by-raily-listing-taxi-corporation-select`}
          isLoading={isTaxiCorporationFetching}
          isDisabled={isTaxiCorporationFetchingError}
          isSearchable
        />
      </FormFieldComponent>
      {isListingContentVisible && (
        <>
          <div className="billings_taxi_order_listing_tools">
            <div className="d-flex">
              <BillingsTaxiOrderListingFiltersSelectComponent
                filters={filters}
                onAddNewFilter={addNewFilter}
              />
              <DateRangeInputComponent
                date={startDateFilterValue ?? null}
                onChange={onOrderStartDateFilterValueChange}
                classNames={{ root: "ml-2" }}
                placeholder={
                  translations.filters.searchByStartDateSelectInputPlaceholder
                }
                idForTesting="billings-taxi-order-listing-by-raily-date-range"
              />
            </div>
            <ListingSortSelectComponent
              onChange={(option) => setSelectedSortKey(option?.value!)}
              options={sortSelectOptions}
              value={selectedSortSelectOption}
              idForTesting="billings-taxi-order-listing-by-raily-sort"
            />
          </div>
          <BillingsTaxiOrderListingFiltersBadgeListComponent
            filters={filters}
            onDeleteFilterClick={deleteFilter}
            onDeleteAllFiltersButtonClick={deleteAllFilters}
          />
          {!!listingItems.length && (
            <BillingsTaxiOrderListingStatsSummaryComponent
              isError={isListingFetchingError}
              isLoading={isListingFetching}
              statsSummary={statsSummary}
            />
          )}
          <CardComponent classNames={{ root: "mt-4" }}>
            <BillingsTaxiOrderListingTableComponent
              listingItems={listingItems}
              isError={isListingFetchingError}
              isLoading={isListingFetching}
            />
            <div className="billings_taxi_order_listing__pagination_wrapper">
              <PaginationComponent
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
                page={page}
                pageSize={pageSize}
                totalResults={totalResults}
              />
            </div>
          </CardComponent>
        </>
      )}
    </div>
  );
};

export default BillingsTaxiOrderListingByRailyComponent;
