import SearchRoadRoutingRequest from "../../../utils/search-road-route/search-road-routing.request";
import SearchRoadRoutingResponse from "../../../utils/search-road-route/search-road-routing.response";
import searchRoadRoutingService from "../../../utils/search-road-route/search-road-routing.service";
import railyApiService from "../raily-api.service";
import OrderOptimizerDetailsRequest from "./details/order-optimizer-details.request";
import OrderOptimizerDetailsResponse from "./details/order-optimizer-details.response";
import orderOptimizerListRequestFactory from "./list/order-optimizer-list-request.factory";
import OrderOptimizerListRequest, {
  OrderOptimizerListRequestQueryParams,
} from "./list/order-optimizer-list.request";
import OrderOptimizerListResponse from "./list/order-optimizer-list.response";

const orderOptimizerApiService = () => {
  const getList = async (
    request: OrderOptimizerListRequest,
    abortSignal: AbortSignal
  ): Promise<OrderOptimizerListResponse> => {
    const url = `/optimizations`;

    const queryParams: OrderOptimizerListRequestQueryParams =
      orderOptimizerListRequestFactory.createQueryParams(request);

    return railyApiService(
      process.env.OPTIMIZER_URL
    ).get<OrderOptimizerListResponse>({
      url,
      abortSignal,
      queryParams,
    });
  };

  const getRoute = (
    request: SearchRoadRoutingRequest
  ): Promise<SearchRoadRoutingResponse> => {
    return searchRoadRoutingService.search(request);
  };

  const getDetails = async (
    request: OrderOptimizerDetailsRequest,
    abortSignal: AbortSignal
  ): Promise<OrderOptimizerDetailsResponse> => {
    const url = `/optimizations/${request.optimizationId}`;

    return railyApiService(
      process.env.OPTIMIZER_URL
    ).get<OrderOptimizerDetailsResponse>({
      url,
      abortSignal,
    });
  };

  return {
    getList,
    getRoute,
    getDetails,
  };
};

export default orderOptimizerApiService;
