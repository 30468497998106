enum BillingsTaxiUnbilledPrivateOrderListingFilterType {
  CLIENT = "CLIENT",
  EXCLUDE_CARGO_COMPANY = "EXCLUDE_CARGO_COMPANY",
  DISPATCH = "DISPATCH",
  DRIVER = "DRIVER",
  EXTERNAL_ORDER_ID = "EXTERNAL_ORDER_ID",
  INTERNAL_ORDER_ID = "INTERNAL_ORDER_ID",
  PASSENGER = "PASSENGER",
  ROUTE_ADDRESS = "ROUTE_ADDRESS",
  ROUTE_DESTINATION_ADDRESS = "ROUTE_DESTINATION_ADDRESS",
  ROUTE_INTERMEDIATE_ADDRESS = "ROUTE_INTERMEDIATE_ADDRESS",
  ROUTE_PICKUP_ADDRESS = "ROUTE_PICKUP_ADDRESS",
  START_DATE = "START_DATE",
  STATUS = "STATUS",
}

export default BillingsTaxiUnbilledPrivateOrderListingFilterType;
