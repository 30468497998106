import { FC } from "react";
import billingsTaxiUnbilledOrderListingTableHelper from "./billings-taxi-unbilled-order-listing-table.helper";
import TableComponent, {
  TableProps,
} from "../../../../../../common/components/table/table.component";
import BillingsTaxiUnbilledOrderListingItem from "../types/billings-taxi-unbilled-order-listing-item";
import BillingsTaxiUnbilledOrderListingTableColumn from "../types/billings-taxi-unbilled-order-listing-table-column";
import BillingsTaxiUnbilledOrderListingTableRow from "../types/billings-taxi-unbilled-order-listing-table-row";

type BillingsTaxiUnbilledOrderListingTableProps = Pick<
  TableProps,
  "isError" | "isLoading"
> & {
  listingItems: BillingsTaxiUnbilledOrderListingItem[];
};

const BillingsTaxiUnbilledOrderListingTableComponent: FC<
  BillingsTaxiUnbilledOrderListingTableProps
> = (props) => {
  const columns: BillingsTaxiUnbilledOrderListingTableColumn[] =
    billingsTaxiUnbilledOrderListingTableHelper.getColumns();

  const rows: BillingsTaxiUnbilledOrderListingTableRow[] =
    billingsTaxiUnbilledOrderListingTableHelper.getRows(props.listingItems);

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isError={props.isError}
      isLoading={props.isLoading}
    />
  );
};

export default BillingsTaxiUnbilledOrderListingTableComponent;
