import { FC, useEffect, useMemo, useState } from "react";
import useIsComponentMounted from "../../../../../common/hooks/use-is-component-mounted";
import useRouteQueryParams from "../../../../../common/hooks/use-route-query-params";
import BillingsTaxiUnbilledRouteListingByTaxiRouteQueryParams from "./common/types/billings-taxi-unbilled-route-listing-by-taxi-route-query-params";
import BillingsTaxiUnbilledRouteListingItem from "../common/types/billings-taxi-unbilled-route-listing-item";
import BillingsTaxiUnbilledRouteListingFilter, {
  BillingsTaxiUnbilledRouteListingRouteStartDateFilter,
} from "../common/types/billings-taxi-unbilled-route-listing-filter";
import BillingsTaxiUnbilledRouteListingSortKey from "../common/types/billings-taxi-unbilled-route-listing-sort-key";
import BillingsTaxiUnbilledRouteListingSortSelectOption from "../common/types/billings-taxi-unbilled-route-listing-sort-select-option";
import billingsTaxiUnbilledRouteListingSortHelper from "../common/billings-taxi-unbilled-route-listing-sort.helper";
import usePagination from "../../../../../common/hooks/use-pagination";
import billingsTaxiUnbilledRouteListingByTaxiRouteQueryParamsService from "./common/billings-taxi-unbilled-route-listing-by-taxi-route-query-params.service";
import HeadingComponent from "../../../../../common/components/heading/heading.component";
import BillingsTaxiUnbilledRouteListingFiltersSelectComponent from "../common/filters/select/billings-taxi-unbilled-route-listing-filters-select.component";
import ListingSortSelectComponent from "../../../../../common/components/listing/filter/sort/select/listing-sort-select.component";
import BillingsTaxiUnbilledRouteListingFiltersBadgeListComponent from "../common/filters/list/billings-taxi-unbilled-route-listing-filters-badge-list.component";
import CardComponent from "../../../../../common/components/card/card.component";
import BillingsTaxiUnbilledRouteListingTableComponent from "../common/table/billings-taxi-unbilled-route-listing-table.component";
import PaginationComponent from "../../../../../common/components/pagination/pagination.component";
import BillingsTaxiUnbilledRouteListingViewBasicProps from "../common/types/billings-taxi-unbilled-route-listing-view-basic-props";
import billingsTaxiUnbilledRouteListingByTaxiApiService from "./common/api/billings-taxi-unbilled-route-listing-by-taxi-api.service";
import billingsTaxiUnbilledRouteListingByTaxiFactory from "./common/billings-taxi-unbilled-route-listing-by-taxi.factory";
import BillingsTaxiUnbilledRouteListingFilterType from "../common/types/billings-taxi-unbilled-route-listing-filter-type";
import DateRange from "../../../../../common/types/date-range";
import BillingsTaxiUnbilledRouteListingResponse from "../common/api/billings-taxi-unbilled-route-listing.response";
import billingsTaxiUnbilledRouteListingRequestFactory from "../common/billings-taxi-unbilled-route-listing-request.factory";
import { useAppContext } from "../../../../../context/app.context";
import DateRangeInputComponent from "../../../../../common/components/form/input/date-range/date-range-input.component";
import billingsTranslationsHelper from "../../../../../languages/billings-translations.helper";

type BillingsTaxiUnbilledRouteListingByTaxiProps =
  BillingsTaxiUnbilledRouteListingViewBasicProps;

const BillingsTaxiUnbilledRouteListingByTaxiComponent: FC<
  BillingsTaxiUnbilledRouteListingByTaxiProps
> = () => {
  const { user, selectedAppLanguage } = useAppContext();
  const isComponentMounted = useIsComponentMounted();

  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<BillingsTaxiUnbilledRouteListingByTaxiRouteQueryParams>();

  const translations =
    billingsTranslationsHelper.getTaxiUnbilledRouteBillingsListingTranslations();

  const [filters, setFilters] = useState<
    BillingsTaxiUnbilledRouteListingFilter[]
  >(() =>
    billingsTaxiUnbilledRouteListingByTaxiRouteQueryParamsService.getFilters(
      routeQueryParams
    )
  );

  const [isListingFetching, setIsListingFetching] = useState(false);
  const [isListingFetchingError, setIsListingFetchingError] = useState(false);
  const [listingItems, setListingItems] = useState<
    BillingsTaxiUnbilledRouteListingItem[]
  >([]);
  const [totalResults, setTotalResults] = useState(0);

  const [selectedSortKey, setSelectedSortKey] =
    useState<BillingsTaxiUnbilledRouteListingSortKey | null>(
      () =>
        billingsTaxiUnbilledRouteListingByTaxiRouteQueryParamsService.getSortKey(
          routeQueryParams
        ) ?? null
    );

  const sortSelectOptions: BillingsTaxiUnbilledRouteListingSortSelectOption[] =
    useMemo(
      () => billingsTaxiUnbilledRouteListingSortHelper.getSelectOptions(),
      [selectedAppLanguage]
    );

  const { page, pageSize, setPage, setPageSize } = usePagination({
    totalResults: totalResults,
    defaultPageSize:
      billingsTaxiUnbilledRouteListingByTaxiRouteQueryParamsService.getPageSize(
        routeQueryParams
      ),
    defaultPage:
      billingsTaxiUnbilledRouteListingByTaxiRouteQueryParamsService.getPage(
        routeQueryParams
      ),
  });

  const onListingFetchSuccess = (
    response: BillingsTaxiUnbilledRouteListingResponse
  ) => {
    const listingItems =
      billingsTaxiUnbilledRouteListingByTaxiFactory.createListingItems(
        response.data.data
      );

    setListingItems(listingItems);
    setTotalResults(response.data.total_count);
  };

  const onListingFetchFailure = () => {
    setIsListingFetchingError(true);
  };

  const handleListingResponse = (
    response: BillingsTaxiUnbilledRouteListingResponse
  ) => {
    if (response.status === 200) {
      onListingFetchSuccess(response);
      return;
    }

    onListingFetchFailure();
  };

  const fetchListing = () => {
    setIsListingFetching(true);
    setIsListingFetchingError(false);

    const taxiCorporationUuid = user?.aspects.taxiOfficer?.taxiCorporationUuid!;

    const request =
      billingsTaxiUnbilledRouteListingRequestFactory.createRequest(
        page,
        pageSize,
        filters,
        selectedSortKey
      );

    billingsTaxiUnbilledRouteListingByTaxiApiService
      .fetchListing(taxiCorporationUuid, request)
      .then(handleListingResponse)
      .catch(onListingFetchFailure)
      .finally(() => setIsListingFetching(false));
  };

  useEffect(() => {
    if (!page || !pageSize) {
      return;
    }

    fetchListing();
  }, [filters, selectedSortKey, pageSize, page]);

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    const queryParams =
      billingsTaxiUnbilledRouteListingByTaxiRouteQueryParamsService.createRouteQueryParams(
        filters,
        selectedSortKey,
        page,
        pageSize
      );

    setRouteQueryParams(queryParams);
  }, [filters, selectedSortKey, page, pageSize]);

  const addNewFilter = (newFilter: BillingsTaxiUnbilledRouteListingFilter) => {
    setFilters((curr) => [...curr, newFilter]);
    setPage(1);
  };

  const deleteFilter = (index: number) => {
    const newFilters = filters.filter((filter, _index) => _index !== index);

    setFilters(newFilters);
    setPage(1);
  };

  const deleteAllFilters = () => {
    setFilters([]);
    setPage(1);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    window.scroll({ top: 0, behavior: "smooth" });
  };

  const onPageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
    setPage(1);
  };

  const selectedSortSelectOption = useMemo(() => {
    return (
      sortSelectOptions.find((item) => item.value === selectedSortKey) ?? null
    );
  }, [selectedSortKey]);

  const startDateFilterValue = filters.find(
    (filter) =>
      filter.type ===
      BillingsTaxiUnbilledRouteListingFilterType.ORDER_START_DATE
  )?.value as
    | BillingsTaxiUnbilledRouteListingRouteStartDateFilter["value"]
    | undefined;

  const onOrderStartDateFilterValueChange = (dateRange: DateRange | null) => {
    if (!dateRange) {
      const newFilters = filters.filter(
        (filter) =>
          filter.type !==
          BillingsTaxiUnbilledRouteListingFilterType.ORDER_START_DATE
      );

      setFilters(newFilters);
      return;
    }

    const isFilterExists = !!filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiUnbilledRouteListingFilterType.ORDER_START_DATE
    );

    const newFilter: BillingsTaxiUnbilledRouteListingRouteStartDateFilter = {
      type: BillingsTaxiUnbilledRouteListingFilterType.ORDER_START_DATE,
      value: {
        from: dateRange.from!,
        to: dateRange.to!,
      },
    };

    if (isFilterExists) {
      const newFilters = [
        ...filters.filter(
          (filter) =>
            filter.type !==
            BillingsTaxiUnbilledRouteListingFilterType.ORDER_START_DATE
        ),
        newFilter,
      ];

      setFilters(newFilters);
      setPage(1);
      return;
    }

    setFilters((curr) => [...curr, newFilter]);
    setPage(1);
  };

  return (
    <div className="billings_taxi_unbilled_route_listing">
      <HeadingComponent title={translations.header.headingText} />
      <div className="billings_taxi_unbilled_route_listing_tools">
        <div className="d-flex">
          <BillingsTaxiUnbilledRouteListingFiltersSelectComponent
            filters={filters}
            onAddNewFilter={addNewFilter}
          />
          <DateRangeInputComponent
            date={startDateFilterValue ?? null}
            onChange={onOrderStartDateFilterValueChange}
            classNames={{ root: "ml-2" }}
            placeholder={
              translations.filters.searchByStartDateSelectInputPlaceholder
            }
            idForTesting="billings-taxi-unbilled-route-listing-by-taxi-date-range"
          />
        </div>
        <ListingSortSelectComponent
          onChange={(option) => setSelectedSortKey(option?.value!)}
          options={sortSelectOptions}
          value={selectedSortSelectOption}
          idForTesting="billings-taxi-unbilled-route-listing-by-taxi-sort"
        />
      </div>
      <BillingsTaxiUnbilledRouteListingFiltersBadgeListComponent
        filters={filters}
        onDeleteFilterClick={deleteFilter}
        onDeleteAllFiltersButtonClick={deleteAllFilters}
      />
      <CardComponent classNames={{ root: "mt-4" }}>
        <BillingsTaxiUnbilledRouteListingTableComponent
          listingItems={listingItems}
          isError={isListingFetchingError}
          isLoading={isListingFetching}
        />
        <div className="billings_taxi_unbilled_route_listing__pagination_wrapper">
          <PaginationComponent
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            page={page}
            pageSize={pageSize}
            totalResults={totalResults}
          />
        </div>
      </CardComponent>
    </div>
  );
};

export default BillingsTaxiUnbilledRouteListingByTaxiComponent;
