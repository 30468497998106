import UserDispatcherEditRouteParams from "./types/user-dispatcher-edit-route-params";
import UserDriverContractAddRouteParams from "./types/user-driver-contract-add-route-params";
import UserDriverContractListingRouteParams from "./types/user-driver-contract-listing-route-params";
import UserPassengerEditRouteParams from "./types/user-passenger-edit-route-params";
import UserDriverEditRouteParams from "./types/user-driver-edit-route-params";
import UserDriverPlanRouteParams from "./types/user-driver-plan-route-params";
import userRoutesUrls from "./user-routes-urls";
import { UserDriverListingRouteQueryParams } from "./types/user-driver-listing-route-params";
import urlService from "../../../../common/utils/url/url.service";
import UserDriverContractEditRouteParams from "./types/user-driver-contract-edit-route-params";
import UserTaxiOfficerEditRouteParams from "./types/user-taxi-officer-edit-route-params";
import UserDriverFinishedPlanRouteParams from "./types/user-driver-finished-plan-route-params";

const getCargoOfficerAddRoute = (): string => {
  return userRoutesUrls.cargoOfficerAdd;
};

const getCargoOfficerListingRoute = (): string => {
  return userRoutesUrls.cargoOfficerListing;
};

const getDealerAddRoute = (): string => {
  return userRoutesUrls.dealerAdd;
};

const getDealerListingRoute = (): string => {
  return userRoutesUrls.dealerListing;
};

const getDispatcherAddRoute = (): string => {
  return userRoutesUrls.dispatcherAdd;
};

const getDispatcherEditRoute = (
  params: UserDispatcherEditRouteParams
): string => {
  return userRoutesUrls.dispatcherEdit.replace(
    ":dispatcherUuid",
    params.dispatcherUuid
  );
};

const getDispatcherListingRoute = (): string => {
  return userRoutesUrls.dispatcherListing;
};

const getDriverAddRoute = (): string => {
  return userRoutesUrls.driverAdd;
};

const getDriverEditRoute = (params: UserDriverEditRouteParams): string => {
  return userRoutesUrls.driverEdit.replace(":driverUuid", params.driverUuid);
};

const getDriverPlanRoute = (params: UserDriverPlanRouteParams): string => {
  return userRoutesUrls.driverPlan.replace(":driverUuid", params.driverUuid);
};

const getDriverFinishedPlanRoute = (
  params: UserDriverFinishedPlanRouteParams
): string => {
  return userRoutesUrls.driverFinishedPlan.replace(
    ":planEntryUuid",
    params.planEntryUuid
  );
};

const getDriverListingRoute = (
  queryParams?: UserDriverListingRouteQueryParams
): string => {
  return urlService.buildWithoutEmptyParams(
    userRoutesUrls.driverListing,
    queryParams
  );
};

const getDriverContractAddRoute = (
  params: UserDriverContractAddRouteParams
): string => {
  return userRoutesUrls.driverContractAdd.replace(
    ":driverUuid",
    params.driverUuid
  );
};

const getDriverContractEditRoute = (
  params: UserDriverContractEditRouteParams
): string => {
  return userRoutesUrls.driverContractEdit
    .replace(":driverUuid", params.driverUuid)
    .replace(":contractUuid", params.contractUuid);
};

const getDriverContractListingRoute = (
  params: UserDriverContractListingRouteParams
): string => {
  return userRoutesUrls.driverContractListing.replace(
    ":driverUuid",
    params.driverUuid
  );
};

const getOperatorAddRoute = (): string => {
  return userRoutesUrls.operatorAdd;
};

const getOperatorListingRoute = (): string => {
  return userRoutesUrls.operatorListing;
};

const getPassengerAddRoute = (): string => {
  return userRoutesUrls.passengerAdd;
};

const getPassengerListingRoute = (): string => {
  return userRoutesUrls.passengerListing;
};

const getPassengerEditRoute = (
  params: UserPassengerEditRouteParams
): string => {
  return userRoutesUrls.passengerEdit.replace(
    ":passengerUuid",
    params.passengerUuid
  );
};

const getRailyOfficerAddRoute = (): string => {
  return userRoutesUrls.railyOfficerAdd;
};

const getRailyOfficerListingRoute = (): string => {
  return userRoutesUrls.railyOfficerListing;
};

const getTaxiOfficerAddRoute = (): string => {
  return userRoutesUrls.taxiOfficerAdd;
};

const getTaxiOfficerEditRoute = (
  params: UserTaxiOfficerEditRouteParams
): string => {
  return userRoutesUrls.taxiOfficerEdit.replace(
    ":taxiOfficerUuid",
    params.taxiOfficerUuid
  );
};

const getTaxiOfficerListingRoute = (): string => {
  return userRoutesUrls.taxiOfficerListing;
};

const userRoutesHelper = {
  getCargoOfficerAddRoute,
  getCargoOfficerListingRoute,
  getDealerAddRoute,
  getDealerListingRoute,
  getDispatcherAddRoute,
  getDispatcherEditRoute,
  getDispatcherListingRoute,
  getDriverAddRoute,
  getDriverEditRoute,
  getDriverPlanRoute,
  getDriverFinishedPlanRoute,
  getDriverListingRoute,
  getDriverContractAddRoute,
  getDriverContractEditRoute,
  getDriverContractListingRoute,
  getOperatorAddRoute,
  getOperatorListingRoute,
  getPassengerAddRoute,
  getPassengerListingRoute,
  getPassengerEditRoute,
  getRailyOfficerAddRoute,
  getRailyOfficerListingRoute,
  getTaxiOfficerAddRoute,
  getTaxiOfficerEditRoute,
  getTaxiOfficerListingRoute,
};

export default userRoutesHelper;
