enum BillingsCargoOrderListingSortKey {
  ORDER_START_DATE_DESC = "ORDER_START_DATE_DESC",
  ORDER_START_DATE_ASC = "ORDER_START_DATE_ASC",
  DISTANCE_DESC = "DISTANCE_DESC",
  DISTANCE_ASC = "DISTANCE_ASC",
  TOLL_ROADS_COST_DESC = "TOLL_ROADS_COST_DESC",
  TOLL_ROADS_COST_ASC = "TOLL_ROADS_COST_ASC",
  INTERNAL_ORDER_ID_ASC = "INTERNAL_ORDER_ID_ASC",
  INTERNAL_ORDER_ID_DESC = "INTERNAL_ORDER_ID_DESC",
  TAXI_CORPORATION_ASC = "TAXI_CORPORATION_ASC",
  TAXI_CORPORATION_DESC = "TAXI_CORPORATION_DESC",
  EXTERNAL_ORDER_ID_ASC = "EXTERNAL_ORDER_ID_ASC",
  EXTERNAL_ORDER_ID_DESC = "EXTERNAL_ORDER_ID_DESC",
  DISPATCH_ASC = "DISPATCH_ASC",
  DISPATCH_DESC = "DISPATCH_DESC",
  DISTANCE_RATE_ASC = "DISTANCE_RATE_ASC",
  DISTANCE_RATE_DESC = "DISTANCE_RATE_DESC",
  DISTANCE_COST_ASC = "DISTANCE_COST_ASC",
  DISTANCE_COST_DESC = "DISTANCE_COST_DESC",
  STOPOVER_TIME_ASC = "STOPOVER_TIME_ASC",
  STOPOVER_TIME_DESC = "STOPOVER_TIME_DESC",
  STOPOVER_COST_ASC = "STOPOVER_COST_ASC",
  STOPOVER_COST_DESC = "STOPOVER_COST_DESC",
  DISCOUNT_AMOUNT_ASC = "DISCOUNT_AMOUNT_ASC",
  DISCOUNT_AMOUNT_DESC = "DISCOUNT_AMOUNT_DESC",
  DISCOUNT_SAVINGS_ASC = "DISCOUNT_SAVINGS_ASC",
  DISCOUNT_SAVINGS_DESC = "DISCOUNT_SAVINGS_DESC",
  TOTAL_COST_ASC = "TOTAL_COST_ASC",
  TOTAL_COST_DESC = "TOTAL_COST_DESC",
}

export default BillingsCargoOrderListingSortKey;
