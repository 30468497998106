import Joi from "joi";
import BillingsCargoOrderListingByRailyCargoCompany from "./types/billibgs-cargo-order-listing-by-raily-cargo-company";
import BillingsCargoOrderListingFilter from "../../common/types/billings-cargo-order-listing-filter";
import BillingsCargoOrderListingSortKey from "../../common/types/billings-cargo-order-listing-sort-key";
import BillingsCargoOrderListingByRailyRouteQueryParams from "./types/billings-cargo-order-listing-by-raily-route-query-params";
import billingsCargoOrderListingRouteQueryParamsService from "../../common/billings-cargo-order-listing-route-query-params.service";

const createRouteQueryParams = (
  cargoCompanyUuid: BillingsCargoOrderListingByRailyCargoCompany["uuid"] | null,
  filters: BillingsCargoOrderListingFilter[],
  sortKey: BillingsCargoOrderListingSortKey | null,
  page: number,
  pageSize: number
): BillingsCargoOrderListingByRailyRouteQueryParams => {
  const commonParams =
    billingsCargoOrderListingRouteQueryParamsService.createRouteQueryParams(
      filters,
      sortKey,
      page,
      pageSize
    );

  return {
    ...commonParams,
    cargoCompany: cargoCompanyUuid ?? undefined,
  };
};

const getCargoCompanyUuid = (
  routeQueryParams: BillingsCargoOrderListingByRailyRouteQueryParams
): string | undefined => {
  if (!validateCargoCompanyUuid(routeQueryParams.cargoCompany)) {
    return undefined;
  }

  return routeQueryParams.cargoCompany;
};

const validateCargoCompanyUuid = (
  routeQueryCargoCompanyParam: BillingsCargoOrderListingByRailyRouteQueryParams["cargoCompany"]
) => {
  const validationSchema = Joi.string();

  return !validationSchema.validate(routeQueryCargoCompanyParam).error?.message;
};

const billingsCargoOrderListingByRailyRouteQueryParamsService = {
  getFilters: billingsCargoOrderListingRouteQueryParamsService.getFilters,
  getSortKey: billingsCargoOrderListingRouteQueryParamsService.getSortKey,
  getPage: billingsCargoOrderListingRouteQueryParamsService.getPage,
  getPageSize: billingsCargoOrderListingRouteQueryParamsService.getPageSize,
  getCargoCompanyUuid,
  createRouteQueryParams,
};

export default billingsCargoOrderListingByRailyRouteQueryParamsService;
