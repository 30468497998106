import SearchRoadRoutingRequest, {
  SearchRoadRoutingRequestWaypointCoordinate,
} from "../../../utils/search-road-route/search-road-routing.request";
import PlanEntryRoadRouteLoadParams, {
  PlanEntryRoadRouteWaypoints,
} from "./plan-entry-road-route-load-params";

const changeWaypointType = (
  type: PlanEntryRoadRouteWaypoints
): SearchRoadRoutingRequestWaypointCoordinate => {
  return {
    latitude: type.lat,
    longitude: type.lon,
  };
};

const create = (
  loadParams: PlanEntryRoadRouteLoadParams
): SearchRoadRoutingRequest => {
  return {
    waypointCoordinates: loadParams.waypoints.map(changeWaypointType),
    excludeHighway: loadParams.excludeHighway,
  };
};

const planEntryRoadRouteRequestFactory = {
  create,
};

export default planEntryRoadRouteRequestFactory;
