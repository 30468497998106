import UserRole from "../../../../../../common/types/user-role";
import UserPermissions from "../../../../../../common/utils/user/permissions/user-permissions";
import UserPermissionsDefinition from "../../../../../../common/utils/user/permissions/user-permissions-definition";

export type BillingsTaxiUnbilledOrderListingUserPermissionOption =
  | "hasAccessToTaxiView"
  | "hasAccessToRailyView";

export type BillingsTaxiUnbilledOrderListingUserPermissionsDefinition =
  UserPermissionsDefinition<BillingsTaxiUnbilledOrderListingUserPermissionOption>;

export type BillingsTaxiUnbilledOrderListingUserPermissions =
  UserPermissions<BillingsTaxiUnbilledOrderListingUserPermissionOption>;

const billingsTaxiUnbilledOrderListingUserPermissionDefinition: BillingsTaxiUnbilledOrderListingUserPermissionsDefinition =
  {
    hasAccessToTaxiView: [UserRole.TAXI_OFFICER],
    hasAccessToRailyView: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
  };

export default billingsTaxiUnbilledOrderListingUserPermissionDefinition;
