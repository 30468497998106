import billingsTaxiRouteListingRouteQueryParamsService from "../../common/billings-taxi-route-listing-route-query-params.service";

const billingsTaxiRouteListingByTaxiRouteQueryParamsService = {
  getFilters: billingsTaxiRouteListingRouteQueryParamsService.getFilters,
  getSortKey: billingsTaxiRouteListingRouteQueryParamsService.getSortKey,
  getPage: billingsTaxiRouteListingRouteQueryParamsService.getPage,
  getPageSize: billingsTaxiRouteListingRouteQueryParamsService.getPageSize,
  createRouteQueryParams:
    billingsTaxiRouteListingRouteQueryParamsService.createRouteQueryParams,
};

export default billingsTaxiRouteListingByTaxiRouteQueryParamsService;
