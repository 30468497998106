import { FC, useMemo, useState } from "react";
import ListingFilterSelectComponent from "../../../../../../../common/components/listing/filter/select/listing-filter-select.component";
import { useAppContext } from "../../../../../../../context/app.context";
import BillingsTaxiOrderListingFilter from "../../types/billings-taxi-order-listing-filter";
import BillingsTaxiOrderListingFilterSelectOption from "../../types/billings-taxi-order-listing-filter-select-option";
import BillingsTaxiOrderListingFilterHelper from "../../billings-taxi-order-listing-filter.helper";

type BillingsTaxiOrderListingFiltersSelectProps = {
  filters: BillingsTaxiOrderListingFilter[];
  onAddNewFilter: (filter: BillingsTaxiOrderListingFilter) => void;
};

const BillingsTaxiOrderListingFiltersSelectComponent: FC<
  BillingsTaxiOrderListingFiltersSelectProps
> = (props) => {
  const { selectedAppLanguage } = useAppContext();

  const [searchInputValue, setSearchInputValue] = useState("");

  const searchSelectOptions: BillingsTaxiOrderListingFilterSelectOption[] =
    useMemo(() => {
      return BillingsTaxiOrderListingFilterHelper.getSelectOptions(
        searchInputValue,
        props.filters
      );
    }, [searchInputValue, props.filters, selectedAppLanguage]);

  const onFilterSelect = (
    filter: BillingsTaxiOrderListingFilterSelectOption
  ) => {
    props.onAddNewFilter(filter.value);
  };

  return (
    <ListingFilterSelectComponent
      onChange={(option) =>
        onFilterSelect(option as BillingsTaxiOrderListingFilterSelectOption)
      }
      options={searchSelectOptions}
      inputValue={searchInputValue}
      onInputChange={setSearchInputValue}
      idForTesting="billings-taxi-order-listing-filter"
    />
  );
};

export default BillingsTaxiOrderListingFiltersSelectComponent;
