import billingRoutesUrls from "./billing-routes-urls";
import BillingCargoRailyAddRouteParams from "./types/billing-cargo-raily-add-route-params";
import BillingCargoTaxiAddRouteParams from "./types/billing-cargo-taxi-add-route-params";
import BillingCargoTaxiDetailsRouteParams from "./types/billing-cargo-taxi-details-route-params";
import BillingCargoTaxiEditRouteParams from "./types/billing-cargo-taxi-edit-route-params";
import BillingRailyTaxiAddRouteParams from "./types/billing-raily-taxi-add-route-params";
import BillingTaxiDriverAddRouteParams from "./types/billing-taxi-driver-add-route-params";
import BillingTaxiDriverDetailsRouteParams from "./types/billing-taxi-driver-details-route-params";
import BillingTaxiDriverEditRouteParams from "./types/billing-taxi-driver-edit-route-params";
import BillingTaxiRailyAddRouteParams from "./types/billing-taxi-raily-add-route-params";
import BillingTaxiTaxiAddRouteParams from "./types/billing-taxi-taxi-add-route-params";
import BillingTaxiTaxiDetailsRouteParams from "./types/billing-taxi-taxi-details-route-params";
import BillingTaxiTaxiEditRouteParams from "./types/billing-taxi-taxi-edit-route-params";

const getCargoOrderListingRoute = (): string => {
  return billingRoutesUrls.cargoOrderListing;
};

const getRailyUnbilledOrderListingRoute = (): string => {
  return billingRoutesUrls.railyUnbilledOrderListing;
};

const getRailyOrderListingRoute = (): string => {
  return billingRoutesUrls.railyOrderListing;
};

const getTaxiOrderListingRoute = (): string => {
  return billingRoutesUrls.taxiOrderListing;
};

const getTaxiRouteListingRoute = (): string => {
  return billingRoutesUrls.taxiRouteListing;
};

const getTaxiUnbilledOrderListingRoute = (): string => {
  return billingRoutesUrls.taxiUnbilledOrderListing;
};

const getTaxiUnbilledPrivateOrderListingRoute = (): string => {
  return billingRoutesUrls.taxiUnbilledPrivateOrderListing;
};

const getTaxiUnbilledRouteListingRoute = (): string => {
  return billingRoutesUrls.taxiUnbilledRouteListing;
};

const getTaxiUnbilledTransferredOrderListingRoute = (): string => {
  return billingRoutesUrls.taxiUnbilledTransferredOrderListing;
};

const getTaxiTransferredOrderListingRoute = (): string => {
  return billingRoutesUrls.taxiTransferredOrderListing;
};

const getCargoWithRailyAddRoute = (
  params: BillingCargoRailyAddRouteParams
): string => {
  return billingRoutesUrls.cargoWithRailyAdd.replace(
    ":orderUuid",
    params.orderUuid
  );
};

const getRailyWithTaxiAddRoute = (
  params: BillingRailyTaxiAddRouteParams
): string => {
  return billingRoutesUrls.railyWithTaxiAdd.replace(
    ":orderUuid",
    params.orderUuid
  );
};

const getCargoWithTaxiAddRoute = (
  params: BillingCargoTaxiAddRouteParams
): string => {
  return billingRoutesUrls.cargoWithTaxiAdd.replace(
    ":orderUuid",
    params.orderUuid
  );
};

const getTaxiWithDriverAddRoute = (
  params: BillingTaxiDriverAddRouteParams
): string => {
  return billingRoutesUrls.taxiWithDriverAdd.replace(
    ":planEntryUuid",
    params.planEntryUuid
  );
};

const getTaxiWithTaxiAddRoute = (
  params: BillingTaxiTaxiAddRouteParams
): string => {
  return billingRoutesUrls.taxiWithTaxiAdd.replace(
    ":forwardingUuid",
    params.forwardingUuid
  );
};

const getTaxiWithTaxiEditRoute = (
  params: BillingTaxiTaxiEditRouteParams
): string => {
  return billingRoutesUrls.taxiWithTaxiEdit.replace(
    ":billingUuid",
    params.billingUuid
  );
};

const getTaxiWithTaxiDetailsRoute = (
  params: BillingTaxiTaxiDetailsRouteParams
): string => {
  return billingRoutesUrls.taxiWithTaxiDetails.replace(
    ":billingUuid",
    params.billingUuid
  );
};

const getTaxiWithRailyAddRoute = (
  params: BillingTaxiRailyAddRouteParams
): string => {
  return billingRoutesUrls.taxiWithRailyAdd.replace(
    ":orderUuid",
    params.orderUuid
  );
};

const getCargoWithTaxiEditRoute = (
  params: BillingCargoTaxiEditRouteParams
): string => {
  return billingRoutesUrls.cargoWithTaxiEdit.replace(
    ":billingUuid",
    params.billingUuid
  );
};

const getCargoWithTaxiDetailsRoute = (
  params: BillingCargoTaxiDetailsRouteParams
): string => {
  return billingRoutesUrls.cargoWithTaxiDetails.replace(
    ":billingUuid",
    params.billingUuid
  );
};

const getTaxiWithDriverEditRoute = (
  params: BillingTaxiDriverEditRouteParams
): string => {
  return billingRoutesUrls.taxiWithDriverEdit.replace(
    ":billingUuid",
    params.billingUuid
  );
};

const getTaxiWithDriverDetailsRoute = (
  params: BillingTaxiDriverDetailsRouteParams
): string => {
  return billingRoutesUrls.taxiWithDriverDetails.replace(
    ":billingUuid",
    params.billingUuid
  );
};

const billingRoutesHelper = {
  getCargoOrderListingRoute,
  getRailyUnbilledOrderListingRoute,
  getRailyOrderListingRoute,
  getTaxiOrderListingRoute,
  getTaxiRouteListingRoute,
  getTaxiUnbilledOrderListingRoute,
  getTaxiUnbilledPrivateOrderListingRoute,
  getTaxiUnbilledRouteListingRoute,
  getTaxiUnbilledTransferredOrderListingRoute,
  getTaxiTransferredOrderListingRoute,
  getCargoWithRailyAddRoute,
  getRailyWithTaxiAddRoute,
  getCargoWithTaxiAddRoute,
  getTaxiWithDriverAddRoute,
  getTaxiWithRailyAddRoute,
  getCargoWithTaxiEditRoute,
  getCargoWithTaxiDetailsRoute,
  getTaxiWithDriverEditRoute,
  getTaxiWithDriverDetailsRoute,
  getTaxiWithTaxiAddRoute,
  getTaxiWithTaxiEditRoute,
  getTaxiWithTaxiDetailsRoute,
};

export default billingRoutesHelper;
