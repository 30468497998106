import appConfig from "../../../../../../app.config";
import urlService from "../../../../../../common/utils/url/url.service";
import authService from "../../../../../auth/common/auth.service";
import BillingStatus from "../../../../types/billing-status";
import BillingsCargoOrderListingAcceptBillingsRequest, {
  BillingsCargoOrderListingAcceptBillingsRequestBody,
} from "./billings-cargo-order-listing-accept-billings.request";
import BillingsCargoOrderListingAcceptBillingsResponse from "./billings-cargo-order-listing-accept-billings.response";
import BillingsCargoOrderListingReportRequest from "./billings-cargo-order-listing-report.request";
import BillingsCargoOrderListingReportResponse from "./billings-cargo-order-listing-report.response";
import BillingsCargoOrderListingStatusCountersRequest from "./billings-cargo-order-listing-status-counters.request";
import BillingsCargoOrderListingStatusCountersResponse from "./billings-cargo-order-listing-status-counters.response";
import BillingsCargoOrderListingRequest from "./billings-cargo-order-listing.request";
import BillingsCargoOrderListingResponse from "./billings-cargo-order-listing.response";

const fetchListing = async (
  cargoCompanyUuid: string,
  request: BillingsCargoOrderListingRequest,
  signal: AbortSignal
): Promise<BillingsCargoOrderListingResponse> => {
  const path = `${appConfig.baseApiUrl}/cargo-companies/${cargoCompanyUuid}/order-billings`;
  const url = urlService.buildWithoutEmptyParams(path, request);

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
    signal,
  };

  const r = await fetch(url, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const fetchReport = async (
  cargoCompanyUuid: string,
  request: BillingsCargoOrderListingReportRequest
): Promise<BillingsCargoOrderListingReportResponse> => {
  const path = `${appConfig.baseApiUrl}/cargo-companies/${cargoCompanyUuid}/order-billings/csv-dump`;
  const url = urlService.buildWithoutEmptyParams(path, request);

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "attachment",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
    mode: "cors",
    keepalive: true,
  };

  const response = await fetch(url, init);

  const filename = response.headers
    ?.get("content-disposition")
    ?.split(";")
    .find((n) => n.includes("filename="))
    ?.replace("filename=", "")
    .replace(/"/g, "")
    .trim()!;

  const responseData = await response.blob();

  return {
    status: response.status,
    data: {
      data: responseData,
      filename,
    },
  };
};

const fetchStatusCounters = async (
  request: BillingsCargoOrderListingStatusCountersRequest
): Promise<BillingsCargoOrderListingStatusCountersResponse> => {
  const path = `/meta/billing-cargo-taxi-status-counters`;

  const url = urlService.buildWithoutEmptyParams(
    `${appConfig.baseApiUrl}${path}`,
    request
  );

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  const r = await fetch(url, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const acceptBillings = async (
  request: BillingsCargoOrderListingAcceptBillingsRequest
): Promise<BillingsCargoOrderListingAcceptBillingsResponse> => {
  const path = `${appConfig.baseApiUrl}/billings/cargo-taxi/bulk-status`;

  const requestBody: BillingsCargoOrderListingAcceptBillingsRequestBody = {
    status: BillingStatus.ACCEPTED,
  };

  const url = urlService.buildWithoutEmptyParams(path, request);

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "PUT",
    body: JSON.stringify(requestBody),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  const r = await fetch(url, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const billingsCargoOrderListingApiService = {
  fetchListing,
  fetchReport,
  fetchStatusCounters,
  acceptBillings,
};

export default billingsCargoOrderListingApiService;
