import BillingModel from "../../../../types/billing-model";
import BillingsTaxiOrderListingItemBillingStatus from "../types/billings-taxi-order-listing-item-billing-status";
import BillingsTaxiOrderListingItemBillingType from "../types/billings-taxi-order-listing-item-billing-type";

export enum BillingsTaxiOrderListingRequestOrder {
  DISTANCE_ASC = "DISTANCE_ASC",
  DISTANCE_DESC = "DISTANCE_DESC",
  HIGHWAY_CHARGE_ASC = "HIGHWAY_CHARGE_ASC",
  HIGHWAY_CHARGE_DESC = "HIGHWAY_CHARGE_DESC",
  HALTING_AMOUNT_ASC = "HALTING_AMOUNT_ASC",
  HALTING_AMOUNT_DESC = "HALTING_AMOUNT_DESC",
  HUMAN_ID_ASC = "HUMAN_ID_ASC",
  HUMAN_ID_DESC = "HUMAN_ID_DESC",
  BILLING_MODEL_ASC = "BILLING_MODEL_ASC",
  BILLING_MODEL_DESC = "BILLING_MODEL_DESC",
  DISTANCE_RATE_ASC = "DISTANCE_RATE_ASC",
  DISTANCE_RATE_DESC = "DISTANCE_RATE_DESC",
  DISTANCE_AMOUNT_ASC = "DISTANCE_AMOUNT_ASC",
  DISTANCE_AMOUNT_DESC = "DISTANCE_AMOUNT_DESC",
  TOTAL_AMOUNT_ASC = "TOTAL_AMOUNT_ASC",
  TOTAL_AMOUNT_DESC = "TOTAL_AMOUNT_DESC",
}

type BillingsTaxiOrderListingRequest = {
  limit: number;
  offset: number;
  order: BillingsTaxiOrderListingRequestOrder | undefined;
  human_id: number | undefined;
  plan_entry_human_ids: number | undefined;
  date_since: string | undefined;
  date_to: string | undefined;
  cargo_company: string | undefined;
  exclude_cargo_company: string | undefined;
  passenger: string | undefined;
  status: BillingsTaxiOrderListingItemBillingStatus | undefined;
  billing_model: BillingModel | undefined;
  billing_type: BillingsTaxiOrderListingItemBillingType | undefined;
  responsible_taxi: string | undefined;
};

export default BillingsTaxiOrderListingRequest;
