import billingsTranslationsHelper from "../../../../../languages/billings-translations.helper";
import BillingsTaxiOrderListingSortKey from "./types/billings-taxi-order-listing-sort-key";
import BillingsTaxiOrderListingSortSelectOption from "./types/billings-taxi-order-listing-sort-select-option";

const getSelectOptions = (): BillingsTaxiOrderListingSortSelectOption[] => {
  const translations =
    billingsTranslationsHelper.getTaxiOrderBillingsListingTranslations().sort;

  return [
    {
      label: translations.distanceAscOptionLabel,
      value: BillingsTaxiOrderListingSortKey.DISTANCE_ASC,
    },
    {
      label: translations.distanceDescOptionLabel,
      value: BillingsTaxiOrderListingSortKey.DISTANCE_DESC,
    },
    {
      label: translations.tollRoadsCostAscOptionLabel,
      value: BillingsTaxiOrderListingSortKey.TOLL_ROADS_COST_ASC,
    },
    {
      label: translations.tollRoadsCostDescOptionLabel,
      value: BillingsTaxiOrderListingSortKey.TOLL_ROADS_COST_DESC,
    },
    {
      label: translations.stopoverCostAscOptionLabel,
      value: BillingsTaxiOrderListingSortKey.STOPOVER_COST_ASC,
    },
    {
      label: translations.stopoverCostDescOptionLabel,
      value: BillingsTaxiOrderListingSortKey.STOPOVER_COST_DESC,
    },
    {
      label: translations.internalOrderIdAscOptionLabel,
      value: BillingsTaxiOrderListingSortKey.INTERNAL_ORDER_ID_ASC,
    },
    {
      label: translations.internalOrderIdDescOptionLabel,
      value: BillingsTaxiOrderListingSortKey.INTERNAL_ORDER_ID_DESC,
    },
    {
      label: translations.billingModelAscOptionLabel,
      value: BillingsTaxiOrderListingSortKey.BILLING_MODEL_ASC,
    },
    {
      label: translations.billingModelDescOptionLabel,
      value: BillingsTaxiOrderListingSortKey.BILLING_MODEL_DESC,
    },
    {
      label: translations.baseDistanceRateAscOptionLabel,
      value: BillingsTaxiOrderListingSortKey.DISTANCE_RATE_ASC,
    },
    {
      label: translations.baseDistanceRateDescOptionLabel,
      value: BillingsTaxiOrderListingSortKey.DISTANCE_RATE_DESC,
    },
    {
      label: translations.amountForDistanceAscOptionLabel,
      value: BillingsTaxiOrderListingSortKey.DISTANCE_COST_ASC,
    },
    {
      label: translations.amountForDistanceDescOptionLabel,
      value: BillingsTaxiOrderListingSortKey.DISTANCE_COST_DESC,
    },
    {
      label: translations.totalCostAscOptionLabel,
      value: BillingsTaxiOrderListingSortKey.TOTAL_COST_ASC,
    },
    {
      label: translations.totalCostDescOptionLabel,
      value: BillingsTaxiOrderListingSortKey.TOTAL_COST_DESC,
    },
  ];
};

const billingsTaxiOrderListingSortHelper = {
  getSelectOptions,
};

export default billingsTaxiOrderListingSortHelper;
