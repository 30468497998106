import { FC, useEffect, useMemo, useState } from "react";
import useRouteQueryParams from "../../../../../common/hooks/use-route-query-params";
import BillingsTaxiOrderListingByTaxiRouteQueryParams from "./common/types/billings-taxi-order-listing-by-taxi-route-query-params";
import BillingsTaxiOrderListingItem from "../common/types/billings-taxi-order-listing-item";
import BillingsTaxiOrderListingFilter, {
  BillingsTaxiOrderListingOrderStartDateFilter,
} from "../common/types/billings-taxi-order-listing-filter";
import BillingsTaxiOrderListingSortKey from "../common/types/billings-taxi-order-listing-sort-key";
import BillingsTaxiOrderListingSortSelectOption from "../common/types/billings-taxi-order-listing-sort-select-option";
import billingsTaxiOrderListingSortHelper from "../common/billings-taxi-order-listing-sort.helper";
import usePagination from "../../../../../common/hooks/use-pagination";
import billingsTaxiOrderListingByTaxiRouteQueryParamsService from "./common/billings-taxi-order-listing-by-taxi-route-query-params.service";
import HeadingComponent from "../../../../../common/components/heading/heading.component";
import BillingsTaxiOrderListingStatusLegendComponent from "../common/status-legend/billings-taxi-order-listing-status-legend.component";
import BillingsTaxiOrderListingFiltersSelectComponent from "../common/filters/select/billings-taxi-order-listing-filters-select.component";
import ListingSortSelectComponent from "../../../../../common/components/listing/filter/sort/select/listing-sort-select.component";
import BillingsTaxiOrderListingFiltersBadgeListComponent from "../common/filters/list/billings-taxi-order-listing-filters-badge-list.component";
import CardComponent from "../../../../../common/components/card/card.component";
import BillingsTaxiOrderListingTableComponent from "../common/table/billings-taxi-order-listing-table.component";
import PaginationComponent from "../../../../../common/components/pagination/pagination.component";
import BillingsTaxiOrderListingViewBasicProps from "../common/types/billings-taxi-order-listing-view-basic-props";
import billingsTaxiOrderListingByTaxiFactory from "./common/billings-taxi-order-listing-by-taxi.factory";
import { useAppContext } from "../../../../../context/app.context";
import billingsTaxiOrderListingByTaxiApiService from "./common/api/billings-taxi-order-listing-by-taxi-api.service";
import BillingsTaxiOrderListingFilterType from "../common/types/billings-taxi-order-listing-filter-type";
import DateRange from "../../../../../common/types/date-range";
import BillingsTaxiOrderListingResponse, {
  BillingsTaxiOrderListingResponseData,
} from "../common/api/billings-taxi-order-listing.response";
import billingsTaxiOrderListingRequestFactory from "../common/billings-taxi-order-listing-request.factory";
import useIsComponentMounted from "../../../../../common/hooks/use-is-component-mounted";
import ButtonComponent from "../../../../../common/components/button/button.component";
import fileDownloadService from "../../../../../common/utils/file-download/file-download.service";
import BillingsTaxiOrderListingReportResponse, {
  BillingsTaxiOrderListingReportResponseData,
} from "../common/api/billings-taxi-order-listing-report.response";
import notificationService from "../../../../../common/utils/notification/notification.service";
import billingsTaxiOrderListingReportRequestFactory from "../common/billings-taxi-order-listing-report-request.factory";
import DateRangeInputComponent from "../../../../../common/components/form/input/date-range/date-range-input.component";
import BillingsTaxiOrderListingStatsSummary from "../common/types/billings-taxi-order-listing-stats-summary";
import BillingsTaxiOrderListingStatsSummaryComponent from "../common/stats-summary/billings-taxi-order-listing-stats-summary.component";
import billingsTranslationsHelper from "../../../../../languages/billings-translations.helper";

type BillingsTaxiOrderListingByTaxiProps =
  BillingsTaxiOrderListingViewBasicProps;

const BillingsTaxiOrderListingByTaxiComponent: FC<
  BillingsTaxiOrderListingByTaxiProps
> = (props) => {
  const isComponentMounted = useIsComponentMounted();

  const { user, selectedAppLanguage } = useAppContext();

  const taxiCorporationUuid: string =
    user?.aspects.taxiOfficer?.taxiCorporationUuid!;

  const translations =
    billingsTranslationsHelper.getTaxiOrderBillingsListingTranslations();

  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<BillingsTaxiOrderListingByTaxiRouteQueryParams>();

  const [areListingItemsFetching, setAreListingItemsFetching] = useState(false);

  const [isListingItemsFetchingError, setIsListingItemsFetchingError] =
    useState(false);

  const [listingItems, setListingItems] = useState<
    BillingsTaxiOrderListingItem[]
  >([]);
  const [statsSummary, setStatsSummary] =
    useState<BillingsTaxiOrderListingStatsSummary | null>(null);
  const [totalResults, setTotalResults] = useState(0);

  const [isReportFetching, setIsReportFetching] = useState(false);

  const [filters, setFilters] = useState<BillingsTaxiOrderListingFilter[]>(() =>
    billingsTaxiOrderListingByTaxiRouteQueryParamsService.getFilters(
      routeQueryParams
    )
  );

  const [selectedSortKey, setSelectedSortKey] =
    useState<BillingsTaxiOrderListingSortKey | null>(
      () =>
        billingsTaxiOrderListingByTaxiRouteQueryParamsService.getSortKey(
          routeQueryParams
        ) ?? null
    );

  const sortSelectOptions: BillingsTaxiOrderListingSortSelectOption[] = useMemo(
    () => billingsTaxiOrderListingSortHelper.getSelectOptions(),
    [selectedAppLanguage]
  );

  const { page, pageSize, setPage, setPageSize } = usePagination({
    totalResults: totalResults,
    defaultPageSize:
      billingsTaxiOrderListingByTaxiRouteQueryParamsService.getPageSize(
        routeQueryParams
      ),
    defaultPage:
      billingsTaxiOrderListingByTaxiRouteQueryParamsService.getPage(
        routeQueryParams
      ),
  });

  const onListingFetchSuccess = (
    responseData: BillingsTaxiOrderListingResponseData
  ) => {
    const listingItems =
      billingsTaxiOrderListingByTaxiFactory.createListingItems(
        responseData.data
      );

    const statsSummary =
      billingsTaxiOrderListingByTaxiFactory.createStatsSummary(
        responseData.stats
      );

    setListingItems(listingItems);
    setStatsSummary(statsSummary);
    setTotalResults(responseData.total_count);
  };

  const onListingFetchFailure = () => {
    setIsListingItemsFetchingError(true);
  };

  const handleListingResponse = (
    response: BillingsTaxiOrderListingResponse
  ) => {
    if (response.status === 200) {
      onListingFetchSuccess(response.data);
      return;
    }

    onListingFetchFailure();
  };

  const fetchListing = (signal: AbortSignal) => {
    setAreListingItemsFetching(true);
    setIsListingItemsFetchingError(false);

    const request = billingsTaxiOrderListingRequestFactory.createRequest(
      page,
      pageSize,
      filters,
      selectedSortKey
    );

    billingsTaxiOrderListingByTaxiApiService
      .fetchListing(taxiCorporationUuid, request, signal)
      .then((data) => {
        if (!signal.aborted) {
          handleListingResponse(data);
        }
      })
      .catch(() => {
        if (!signal.aborted) {
          onListingFetchFailure();
        }
      })
      .finally(() => setAreListingItemsFetching(false));
  };

  useEffect(() => {
    if (!page || !pageSize) {
      return;
    }
    const abortController = new AbortController();
    const signal = abortController.signal;

    fetchListing(signal);

    return () => {
      abortController.abort();
    };
  }, [page, pageSize, filters, selectedSortKey]);

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    const queryParams =
      billingsTaxiOrderListingByTaxiRouteQueryParamsService.createRouteQueryParams(
        filters,
        selectedSortKey,
        page,
        pageSize
      );

    setRouteQueryParams(queryParams);
  }, [filters, selectedSortKey, page, pageSize]);

  const addNewFilter = (newFilter: BillingsTaxiOrderListingFilter) => {
    setFilters((curr) => [...curr, newFilter]);
    setPage(1);
  };

  const deleteFilter = (index: number) => {
    const newFilters = filters.filter((filter, _index) => _index !== index);

    setFilters(newFilters);
    setPage(1);
  };

  const deleteAllFilters = () => {
    setFilters([]);
    setPage(1);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    window.scroll({ top: 0, behavior: "smooth" });
  };

  const onPageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
    setPage(1);
  };

  const selectedSortSelectOption = useMemo(() => {
    return (
      sortSelectOptions.find((item) => item.value === selectedSortKey) ?? null
    );
  }, [selectedSortKey, selectedAppLanguage]);

  const startDateFilterValue = filters.find(
    (filter) =>
      filter.type === BillingsTaxiOrderListingFilterType.ORDER_START_DATE
  )?.value as BillingsTaxiOrderListingOrderStartDateFilter["value"] | undefined;

  const onStartDateFilterValueChange = (dateRange: DateRange | null) => {
    if (!dateRange) {
      const newFilters = filters.filter(
        (filter) =>
          filter.type !== BillingsTaxiOrderListingFilterType.ORDER_START_DATE
      );

      setFilters(newFilters);
      return;
    }

    const isFilterExists = !!filters.find(
      (filter) =>
        filter.type === BillingsTaxiOrderListingFilterType.ORDER_START_DATE
    );

    const newFilter: BillingsTaxiOrderListingOrderStartDateFilter = {
      type: BillingsTaxiOrderListingFilterType.ORDER_START_DATE,
      value: {
        from: dateRange.from!,
        to: dateRange.to!,
      },
    };

    if (isFilterExists) {
      const newFilters = [
        ...filters.filter(
          (filter) =>
            filter.type !== BillingsTaxiOrderListingFilterType.ORDER_START_DATE
        ),
        newFilter,
      ];

      setFilters(newFilters);
      setPage(1);
      return;
    }

    setFilters((curr) => [...curr, newFilter]);
    setPage(1);
  };

  const downloadReportFile = (
    responseData: BillingsTaxiOrderListingReportResponseData
  ) => {
    fileDownloadService.downloadFromBlob(
      responseData.data,
      responseData.filename
    );
  };

  const onReportFetchSuccess = async (
    response: BillingsTaxiOrderListingReportResponse
  ) => {
    if (response.status === 200) {
      downloadReportFile(response.data);
      return;
    }

    onReportFetchFailure();
  };

  const onReportFetchFailure = () => {
    notificationService.error(
      translations.report.failureDownloadingNotificationText
    );
  };

  const fetchReport = () => {
    setIsReportFetching(true);

    const request = billingsTaxiOrderListingReportRequestFactory.createRequest(
      filters,
      selectedSortKey
    );

    billingsTaxiOrderListingByTaxiApiService
      .fetchReport(taxiCorporationUuid, request)
      .then(onReportFetchSuccess)
      .catch(onReportFetchFailure)
      .finally(() => setIsReportFetching(false));
  };

  const onReportDownloadButtonClick = () => {
    fetchReport();
  };

  const ReportDownloadButton = (
    <ButtonComponent
      onClick={onReportDownloadButtonClick}
      type="primary"
      isLoading={isReportFetching}
      title={translations.report.downloadButtonTitle}
      idForTesting="billings-taxi-order-listing-by-taxi-report-download-button"
    >
      {translations.report.downloadButtonText}
    </ButtonComponent>
  );

  const isReportDownloadButtonVisible = !!taxiCorporationUuid;

  return (
    <div className="billings_taxi_order_listing">
      <HeadingComponent
        title={translations.header.headingText}
        actions={[
          ...props.actionButtons,
          isReportDownloadButtonVisible && ReportDownloadButton,
        ]}
      />
      <BillingsTaxiOrderListingStatusLegendComponent
        taxiCorporationUuid={taxiCorporationUuid}
      />
      <div className="billings_taxi_order_listing_tools">
        <div className="d-flex">
          <BillingsTaxiOrderListingFiltersSelectComponent
            filters={filters}
            onAddNewFilter={addNewFilter}
          />
          <DateRangeInputComponent
            date={startDateFilterValue ?? null}
            onChange={onStartDateFilterValueChange}
            classNames={{ root: "ml-2" }}
            placeholder={
              translations.filters.searchByStartDateSelectInputPlaceholder
            }
            idForTesting="billings_taxi-order-listing-by-taxi-date-range"
          />
        </div>
        <ListingSortSelectComponent
          onChange={(option) => setSelectedSortKey(option?.value!)}
          options={sortSelectOptions}
          value={selectedSortSelectOption}
          idForTesting="billings-taxi-order-listing-by-taxi-sort"
        />
      </div>
      <BillingsTaxiOrderListingFiltersBadgeListComponent
        filters={filters}
        onDeleteFilterClick={deleteFilter}
        onDeleteAllFiltersButtonClick={deleteAllFilters}
      />
      {!!listingItems.length && (
        <BillingsTaxiOrderListingStatsSummaryComponent
          isError={isListingItemsFetchingError}
          isLoading={areListingItemsFetching}
          statsSummary={statsSummary}
        />
      )}
      <CardComponent classNames={{ root: "mt-4" }}>
        <BillingsTaxiOrderListingTableComponent
          listingItems={listingItems}
          isError={isListingItemsFetchingError}
          isLoading={areListingItemsFetching}
        />
        <div className="billings_taxi_order_listing__pagination_wrapper">
          <PaginationComponent
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            page={page}
            pageSize={pageSize}
            totalResults={totalResults}
          />
        </div>
      </CardComponent>
    </div>
  );
};

export default BillingsTaxiOrderListingByTaxiComponent;
