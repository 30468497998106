enum OrderAbandonedListingFilterType {
  INTERNAL_ORDER_ID = "INTERNAL_ORDER_ID",
  EXTERNAL_ORDER_ID = "EXTERNAL_ORDER_ID",
  START_TIME = "START_TIME",
  CLIENT = "CLIENT",
  DISPATCH = "DISPATCH",
  PASSENGER = "PASSENGER",
  ADDRESS = "ADDRESS",
  START_ADDRESS = "START_ADDRESS",
  MID_ADDRESS = "MID_ADDRESS",
  END_ADDRESS = "END_ADDRESS",
}

export default OrderAbandonedListingFilterType;
