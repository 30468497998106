import classNames from "classnames";
import { FC } from "react";
import BillingsTaxiUnbilledPrivateOrderListingItemStatus from "../../common/types/billings-taxi-unbilled-private-order-listing-item-status";

type BillingsTaxiUnbilledPrivateOrderListingTableStatusProps = {
  status: BillingsTaxiUnbilledPrivateOrderListingItemStatus;
};

const BillingsTaxiUnbilledPrivateOrderListingTableStatusComponent: FC<
  BillingsTaxiUnbilledPrivateOrderListingTableStatusProps
> = (props) => {
  const getClassnameByStatus = (): string => {
    switch (props.status) {
      case BillingsTaxiUnbilledPrivateOrderListingItemStatus.CANCELLED:
        return "cancelled";
      case BillingsTaxiUnbilledPrivateOrderListingItemStatus.FINISHED:
        return "finished";
    }
  };

  const statusClassname = getClassnameByStatus();

  const containerClassNames = classNames(
    "billings_taxi_unbilled_private_order_listing_table_status",
    statusClassname
  );

  return <div className={containerClassNames}></div>;
};

export default BillingsTaxiUnbilledPrivateOrderListingTableStatusComponent;
