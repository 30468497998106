import BillingsTaxiUnbilledRouteListingByRailyTaxiCorporationSelectOption from "./types/billings-taxi-unbilled-route-listing-by-raily-taxi-corporation-select-option";
import billingsTaxiUnbilledRouteListingFactory from "../../common/billings-taxi-unbilled-route-listing.factory";
import { BillingsTaxiUnbilledRouteListingByRailyTaxiCoporationsResponseDataItem } from "./api/billings-taxi-unbilled-route-listing-by-raily-taxi-corporations.response";

const createTaxiCorporationSelectOption = (
  responseTaxiCorporation: BillingsTaxiUnbilledRouteListingByRailyTaxiCoporationsResponseDataItem
): BillingsTaxiUnbilledRouteListingByRailyTaxiCorporationSelectOption => {
  return {
    label: responseTaxiCorporation.display_name,
    value: {
      uuid: responseTaxiCorporation.id,
      displayName: responseTaxiCorporation.display_name,
    },
  };
};

const createTaxiCorporationSelectOptions = (
  responseTaxiCorporations: BillingsTaxiUnbilledRouteListingByRailyTaxiCoporationsResponseDataItem[]
): BillingsTaxiUnbilledRouteListingByRailyTaxiCorporationSelectOption[] => {
  return responseTaxiCorporations.map(createTaxiCorporationSelectOption);
};

const billingsTaxiUnbilledRouteListingByRailyFactory = {
  createTaxiCorporationSelectOptions,
  createListingItems:
    billingsTaxiUnbilledRouteListingFactory.createListingItems,
};

export default billingsTaxiUnbilledRouteListingByRailyFactory;
