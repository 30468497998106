import { CargoOrderNodeExclusionResponseItem } from "../../../api/raily/cargo-order/node-exclusion/cargo-order-node-exclusion.response";
import CargoOrderNodeExclusion from "./cargo-order-node-exclusion";

const create = (
  responseItem: CargoOrderNodeExclusionResponseItem
): CargoOrderNodeExclusion => {
  return {
    excludeApproaching: responseItem.excludeApproaching,
    excludeInside: responseItem.excludeInside,
    excludeReturning: responseItem.excludeReturning,
  };
};

const cargoOrderNodeExclusionFactory = { create };

export default cargoOrderNodeExclusionFactory;
