import dateService from "../../../../../../common/utils/date/date.service";
import BillingsTaxiUnbilledTransferredOrderListingTableColumn from "../types/billings-taxi-unbilled-transferred-order-listing-table-column";
import BillingsTaxiUnbilledTransferredOrderListingItem from "../types/billings-taxi-unbilled-transferred-order-listing-item";
import BillingsTaxiUnbilledTransferredOrderListingTableRow from "../types/billings-taxi-unbilled-transferred-order-listing-table-row";
import TableLinkButtonComponent from "../../../../../../common/components/table/button/link/table-link-button.component";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import billingRoutesHelper from "../../../../common/routes/billing-routes.helper";
import billingsTranslationsHelper from "../../../../../../languages/billings-translations.helper";

const getColumns =
  (): BillingsTaxiUnbilledTransferredOrderListingTableColumn[] => {
    const translations =
      billingsTranslationsHelper.getTaxiUnbilledTransferredOrderBillingsListingTranslations()
        .table.headers;

    const columns: BillingsTaxiUnbilledTransferredOrderListingTableColumn[] = [
      {
        accessor: "startDate",
        header: translations.startDateLabel,
        title: translations.startDateTitle,
        colSpan: 10,
      },
      {
        accessor: "transferDate",
        header: translations.transferDateLabel,
        title: translations.transferDateTitle,
        colSpan: 10,
      },
      {
        accessor: "internalOrderId",
        header: translations.internalOrderIdLabel,
        title: translations.internalOrderIdTitle,
        colSpan: 5,
      },
      {
        accessor: "externalOrderId",
        header: translations.externalOrderIdLabel,
        title: translations.externalOrderIdTitle,
        colSpan: 8,
      },
      {
        accessor: "route",
        header: translations.routeLabel,
        title: translations.routeTitle,
        colSpan: 15,
      },
      {
        accessor: "passengers",
        header: translations.passengersLabel,
        title: translations.passengersTitle,
        colSpan: 10,
      },
      {
        accessor: "producerTaxi",
        header: translations.producerTaxiLabel,
        title: translations.producerTaxiTitle,
        colSpan: 10,
      },
      {
        accessor: "consumerTaxi",
        header: translations.consumerTaxiLabel,
        title: translations.consumerTaxiTitle,
        colSpan: 10,
      },
      {
        accessor: "driver",
        header: translations.driverLabel,
        title: translations.driverTitle,
        colSpan: 10,
      },
      {
        accessor: "actions",
        header: "",
        title: "",
        colSpan: 10,
      },
    ];

    return columns;
  };

const checkIsAddressGroup = (
  address: string | string[]
): address is string[] => {
  if (!Array.isArray(address)) {
    return false;
  }

  return true;
};

const getRouteLabel = (
  addresses: BillingsTaxiUnbilledTransferredOrderListingItem["routeAddresses"]
): string => {
  const addressesWithFlattenedGroups = addresses.map((address) => {
    const isAddressGroup = checkIsAddressGroup(address);

    if (!isAddressGroup) {
      return address;
    }

    const connectedAddresses = address.join(" | ");
    return `[ ${connectedAddresses} ]`;
  });

  return addressesWithFlattenedGroups.join(" > ");
};

const getRouteTitle = (
  addresses: BillingsTaxiUnbilledTransferredOrderListingItem["routeAddresses"]
): string => {
  return addresses.join(`\r`);
};

const getRow = (
  listingItem: BillingsTaxiUnbilledTransferredOrderListingItem
): BillingsTaxiUnbilledTransferredOrderListingTableRow => {
  const routeAddressesLabel = getRouteLabel(listingItem.routeAddresses);
  const routeAddressesTitle = getRouteTitle(listingItem.routeAddresses);
  const translations =
    billingsTranslationsHelper.getTaxiUnbilledTransferredOrderBillingsListingTranslations()
      .table;

  const startDateLabel = dateService.format(
    listingItem.startDate,
    "dd.mm.yyyy"
  );

  const startDateTitle = dateService.format(
    listingItem.startDate,
    "dd.mm.yyyy"
  );

  const transferDateLabel = dateService.format(
    listingItem.transferDate,
    "dd.mm.yyyy"
  );

  const transferDateTitle = dateService.format(
    listingItem.transferDate,
    "dd.mm.yyyy"
  );

  const isAddButtonVisible = !listingItem.readOnly;

  return {
    id: listingItem.uuid,
    value: {
      producerTaxi: (
        <div title={listingItem.producerTaxi}>{listingItem.producerTaxi}</div>
      ),
      consumerTaxi: (
        <div title={listingItem.consumerTaxi}>{listingItem.consumerTaxi}</div>
      ),
      driver: (
        <div title={listingItem.driversDisplayName}>
          {listingItem.driversDisplayName}
        </div>
      ),
      externalOrderId: (
        <div title={listingItem.externalOrderId ?? undefined}>
          {listingItem.externalOrderId ?? undefined}
        </div>
      ),
      internalOrderId: (
        <div title={String(listingItem.internalOrderId)}>
          {String(listingItem.internalOrderId)}
        </div>
      ),
      passengers: (
        <div>
          {listingItem.passengers.map((passenger, index) => (
            <div key={index} title={passenger}>
              {passenger}
            </div>
          ))}
        </div>
      ),
      route: <div title={routeAddressesTitle}>{routeAddressesLabel}</div>,
      startDate: <div title={startDateTitle}>{startDateLabel}</div>,
      transferDate: <div title={transferDateTitle}>{transferDateLabel}</div>,
      actions: (
        <div className="d-flex">
          {isAddButtonVisible && (
            <TableLinkButtonComponent
              icon={faAdd}
              to={billingRoutesHelper.getTaxiWithTaxiAddRoute({
                forwardingUuid: listingItem.uuid,
              })}
              title={translations.taxiWithTaxiBillingAddButtonTitle}
              idForTesting={`billings-taxi-unbilled-transferred-order-listing-table-item-${listingItem.uuid}-add-button`}
            />
          )}
        </div>
      ),
    },
  };
};

const getRows = (
  listingItems: BillingsTaxiUnbilledTransferredOrderListingItem[]
): BillingsTaxiUnbilledTransferredOrderListingTableRow[] => {
  return listingItems.map(getRow);
};

const billingsTaxiUnbilledTransferredOrderListingTableHelper = {
  getColumns,
  getRows,
};

export default billingsTaxiUnbilledTransferredOrderListingTableHelper;
