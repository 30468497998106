import HttpResponse from "../../../../../../common/utils/http/http.response";

export enum BillingsTaxiUnbilledPrivateOrderListingResponseDataItemMissingBillings {
  CARGO_TAXI = "CARGO_TAXI",
}

export type BillingsTaxiUnbilledPrivateOrderListingResponseDataItem = {
  id: string;
  start_time: string;
  human_id: number;
  is_cancelled: boolean;
  ride_addresses: (string | string[])[];
  passengers: string[];
  dispatch: string;
  client: { id: string; name: string };
  taxi_corporations: string[];
  drivers: string[];
  external_id: string | null;
  missing_taxi_billings: BillingsTaxiUnbilledPrivateOrderListingResponseDataItemMissingBillings[];
};

export type BillingsTaxiUnbilledPrivateOrderListingResponseData = {
  data: BillingsTaxiUnbilledPrivateOrderListingResponseDataItem[];
  total_count: number;
};

type BillingsTaxiUnbilledPrivateOrderListingResponse =
  HttpResponse<BillingsTaxiUnbilledPrivateOrderListingResponseData>;

export default BillingsTaxiUnbilledPrivateOrderListingResponse;
