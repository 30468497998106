import { useEffect, useState } from "react";
import { BillingsTaxiTransferredOrderStatusLegendOption } from "../../types/billings-taxi-transferred-order-status-legend-option";
import useTransferredOrderStatusCount from "../../../../../../../common/services/billings/order/transferred-order-status-count/use-transferred-order-status-count.hook";
import TransferredOrderStatusCount from "../../../../../../../common/services/billings/order/transferred-order-status-count/transferred-order-status-count.model";
import useAbort from "../../../../../../../common/hooks/use-abort";
import BillingsTaxiTransferredOrderListingItemBillingStatus from "../../types/billings-taxi-transferred-order-listing-item-billing-status";

export const useBillingsTaxiTransferredOrderStatuses = () => {
  const [statusOptions, setStatusOptions] = useState<
    BillingsTaxiTransferredOrderStatusLegendOption[]
  >([]);
  const { data, load } = useTransferredOrderStatusCount();
  const abort = useAbort();

  const taxiTransferredOrderStatusesData = async () => {
    load(abort.signal);
  };

  useEffect(() => {
    if (!data) {
      taxiTransferredOrderStatusesData();
    } else {
      setStatusOptions(getBillingStatusOptions(data));
    }
  }, [data]);

  return {
    statusOptions,
  };
};

const getBillingStatusOptions = (
  responseData: TransferredOrderStatusCount
): BillingsTaxiTransferredOrderStatusLegendOption[] => {
  const options: BillingsTaxiTransferredOrderStatusLegendOption[] = [
    {
      status: BillingsTaxiTransferredOrderListingItemBillingStatus.CREATED,
      totalCount: responseData.created,
    },
    {
      status: BillingsTaxiTransferredOrderListingItemBillingStatus.ACCEPTED,
      totalCount: responseData.accepted,
    },
    {
      status: BillingsTaxiTransferredOrderListingItemBillingStatus.REJECTED,
      totalCount: responseData.rejected,
    },
    {
      status: BillingsTaxiTransferredOrderListingItemBillingStatus.REOPENED,
      totalCount: responseData.reopened,
    },
    {
      status:
        BillingsTaxiTransferredOrderListingItemBillingStatus.REOPEN_REQUEST,
      totalCount: responseData.reopenRequest,
    },
  ];

  return options;
};
