import isEqual from "lodash/isEqual";
import BillingsTaxiUnbilledTransferredOrderListingFilterSelectOption, {
  BillingsTaxiUnbilledTransferredOrderListingInternalOrderIdFilterSelectOption,
} from "../types/billings-taxi-unbilled-transferred-order-listing-filter-select-option";
import BillingsTaxiUnbilledTransferredOrderListingFilterType from "../types/billings-taxi-unbilled-transferred-order-listing-filter-type";
import BillingsTaxiUnbilledTransferredOrderListingFilter from "../types/billings-taxi-unbilled-transferred-order-listing-filter";
import billingsTranslationsHelper from "../../../../../../languages/billings-translations.helper";

const getSearchQueryOptions = (
  query: string
): BillingsTaxiUnbilledTransferredOrderListingFilterSelectOption[] => {
  if (!query) {
    return [];
  }

  const searchFilterTranslations =
    billingsTranslationsHelper.getTaxiUnbilledTransferredOrderBillingsListingTranslations()
      .filters.search;

  const parseStringToNumber = (string: string): number | undefined => {
    const stringAsNumber = Number(string);

    if (Number.isNaN(stringAsNumber)) {
      return undefined;
    }

    return stringAsNumber;
  };

  const getSearchByProducerTaxiLabel = (query: string) => {
    return searchFilterTranslations.searchByProducerTaxiTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByConsumerTaxiLabel = (query: string) => {
    return searchFilterTranslations.searchByConsumerTaxiTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByDriverLabel = (query: string) => {
    return searchFilterTranslations.searchByDriverTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByExternalOrderIdLabel = (query: string) => {
    return searchFilterTranslations.searchByExternalOrderIdTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByInternalOrderIdLabel = (query: string) => {
    return searchFilterTranslations.searchByInternalOrderIdTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByPassengerLabel = (query: string) => {
    return searchFilterTranslations.searchByPassengerTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByRouteAddressLabel = (query: string) => {
    return searchFilterTranslations.searchByRouteAddressTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByRouteDestinationAddressLabel = (query: string) => {
    return searchFilterTranslations.searchByRouteDestinationAddressTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByRouteIntermediateAddressLabel = (query: string) => {
    return searchFilterTranslations.searchByRouteIntermediateAddressTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByRoutePickupAddressLabel = (query: string) => {
    return searchFilterTranslations.searchByRoutePickupAddressTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const stringOptions = [
    {
      label: getSearchByProducerTaxiLabel(query),
      value: {
        type: BillingsTaxiUnbilledTransferredOrderListingFilterType.PRODUCER_TAXI,
        value: query,
      },
    },
    {
      label: getSearchByConsumerTaxiLabel(query),
      value: {
        type: BillingsTaxiUnbilledTransferredOrderListingFilterType.CONSUMER_TAXI,
        value: query,
      },
    },
    {
      label: getSearchByDriverLabel(query),
      value: {
        type: BillingsTaxiUnbilledTransferredOrderListingFilterType.DRIVER,
        value: query,
      },
    },
    {
      label: getSearchByExternalOrderIdLabel(query),
      value: {
        type: BillingsTaxiUnbilledTransferredOrderListingFilterType.EXTERNAL_ORDER_ID,
        value: query,
      },
    },
    {
      label: getSearchByPassengerLabel(query),
      value: {
        type: BillingsTaxiUnbilledTransferredOrderListingFilterType.PASSENGER,
        value: query,
      },
    },
    {
      label: getSearchByRouteAddressLabel(query),
      value: {
        type: BillingsTaxiUnbilledTransferredOrderListingFilterType.ROUTE_ADDRESS,
        value: query,
      },
    },
    {
      label: getSearchByRouteDestinationAddressLabel(query),
      value: {
        type: BillingsTaxiUnbilledTransferredOrderListingFilterType.ROUTE_DESTINATION_ADDRESS,
        value: query,
      },
    },
    {
      label: getSearchByRouteIntermediateAddressLabel(query),
      value: {
        type: BillingsTaxiUnbilledTransferredOrderListingFilterType.ROUTE_INTERMEDIATE_ADDRESS,
        value: query,
      },
    },
    {
      label: getSearchByRoutePickupAddressLabel(query),
      value: {
        type: BillingsTaxiUnbilledTransferredOrderListingFilterType.ROUTE_PICKUP_ADDRESS,
        value: query,
      },
    },
  ];

  const options: BillingsTaxiUnbilledTransferredOrderListingFilterSelectOption[] =
    [
      ...stringOptions,
    ] as BillingsTaxiUnbilledTransferredOrderListingFilterSelectOption[];

  if (parseStringToNumber(query) !== undefined) {
    const internalOrderIdFilterOption: BillingsTaxiUnbilledTransferredOrderListingInternalOrderIdFilterSelectOption =
      {
        label: getSearchByInternalOrderIdLabel(query),
        value: {
          type: BillingsTaxiUnbilledTransferredOrderListingFilterType.INTERNAL_ORDER_ID,
          value: parseStringToNumber(query)!,
        },
      };

    options.push(internalOrderIdFilterOption);
  }

  return options;
};

const getSelectOptionsByQuery = (
  query: string
): BillingsTaxiUnbilledTransferredOrderListingFilterSelectOption[] => {
  const queryOptions = getSearchQueryOptions(query);

  return queryOptions;
};

const getSelectOptions = (
  query: string,
  selectedFilters: BillingsTaxiUnbilledTransferredOrderListingFilter[]
): BillingsTaxiUnbilledTransferredOrderListingFilterSelectOption[] => {
  const allFilterOptions = getSelectOptionsByQuery(query);

  const filterOptionsWithoutSelected = allFilterOptions.filter((option) => {
    return !selectedFilters.find((selectedFilter) =>
      isEqual(selectedFilter.type, option.value.type)
    );
  });

  return filterOptionsWithoutSelected;
};

const billingsTaxiUnbilledTransferredOrderListingFilterHelper = {
  getSelectOptions,
};

export default billingsTaxiUnbilledTransferredOrderListingFilterHelper;
