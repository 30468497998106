import { BillingsTaxiUnbilledRouteListingResponseDataItem } from "./api/billings-taxi-unbilled-route-listing.response";
import BillingsTaxiUnbilledRouteListingItem from "./types/billings-taxi-unbilled-route-listing-item";

const createListingItem = (
  responseDataItem: BillingsTaxiUnbilledRouteListingResponseDataItem
): BillingsTaxiUnbilledRouteListingItem => {
  return {
    uuid: responseDataItem.id,
    routeId: responseDataItem.human_id,
    driverName: responseDataItem.driver,
    startDate: new Date(responseDataItem.start_time),
    addresses: responseDataItem.addresses.join(", "),
    orderIds: responseDataItem.cargo_order_human_ids,
    clients: responseDataItem.clients.join(", "),
    fleetPartner: responseDataItem.fleet_partner,
  };
};

const createListingItems = (
  responseDataItems: BillingsTaxiUnbilledRouteListingResponseDataItem[]
): BillingsTaxiUnbilledRouteListingItem[] => {
  return responseDataItems.map(createListingItem);
};

const billingsTaxiUnbilledRouteListingFactory = {
  createListingItems,
};

export default billingsTaxiUnbilledRouteListingFactory;
