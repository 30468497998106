import { FC, useMemo, useState } from "react";
import ListingFilterSelectComponent from "../../../../../../../common/components/listing/filter/select/listing-filter-select.component";
import { useAppContext } from "../../../../../../../context/app.context";
import BillingsTaxiUnbilledPrivateOrderListingFilter from "../../types/billings-taxi-unbilled-private-order-listing-filter";
import BillingsTaxiUnbilledPrivateOrderListingFilterSelectOption from "../../types/billings-taxi-unbilled-private-order-listing-filter-select-option";
import billingsTaxiUnbilledPrivateOrderListingFilterHelper from "../billings-taxi-unbilled-private-order-listing-filter.helper";

type BillingsTaxiUnbilledPrivateOrderListingFiltersSelectProps = {
  filters: BillingsTaxiUnbilledPrivateOrderListingFilter[];
  onAddNewFilter: (
    filter: BillingsTaxiUnbilledPrivateOrderListingFilter
  ) => void;
};

const BillingsTaxiUnbilledPrivateOrderListingFiltersSelectComponent: FC<
  BillingsTaxiUnbilledPrivateOrderListingFiltersSelectProps
> = (props) => {
  const { selectedAppLanguage } = useAppContext();

  const [searchInputValue, setSearchInputValue] = useState("");

  const searchSelectOptions: BillingsTaxiUnbilledPrivateOrderListingFilterSelectOption[] =
    useMemo(() => {
      return billingsTaxiUnbilledPrivateOrderListingFilterHelper.getSelectOptions(
        searchInputValue,
        props.filters
      );
    }, [searchInputValue, props.filters, selectedAppLanguage]);

  const onFilterSelect = (
    filter: BillingsTaxiUnbilledPrivateOrderListingFilterSelectOption
  ) => {
    props.onAddNewFilter(filter.value);
  };

  return (
    <ListingFilterSelectComponent
      onChange={(option) =>
        onFilterSelect(
          option as BillingsTaxiUnbilledPrivateOrderListingFilterSelectOption
        )
      }
      options={searchSelectOptions}
      inputValue={searchInputValue}
      onInputChange={setSearchInputValue}
      idForTesting="billings-taxi-unbilled-private-order-listing-filter"
    />
  );
};

export default BillingsTaxiUnbilledPrivateOrderListingFiltersSelectComponent;
