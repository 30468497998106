import BillingsTaxiOrderListingReportRequest, {
  BillingsTaxiOrderListingReportRequestOrder,
} from "./api/billings-taxi-order-listing-report-request";
import BillingsTaxiOrderListingFilter, {
  BillingsTaxiOrderListingBillingModelFilter,
  BillingsTaxiOrderListingBillingStatusFilter,
  BillingsTaxiOrderListingBillingTypeFilter,
  BillingsTaxiOrderListingCargoCompanyFilter,
  BillingsTaxiOrderListingDestinationTaxiFilter,
  BillingsTaxiOrderListingExcludeCargoCompanyFilter,
  BillingsTaxiOrderListingInternalOrderIdFilter,
  BillingsTaxiOrderListingOrderStartDateFilter,
  BillingsTaxiOrderListingPassengerFilter,
  BillingsTaxiOrderListingPlanEntryHumanIdsFilter,
} from "./types/billings-taxi-order-listing-filter";
import BillingsTaxiOrderListingFilterType from "./types/billings-taxi-order-listing-filter-type";
import BillingsTaxiOrderListingSortKey from "./types/billings-taxi-order-listing-sort-key";

const createRequestOrder = (
  sortKey: BillingsTaxiOrderListingSortKey | null
): BillingsTaxiOrderListingReportRequest["order"] => {
  const options: {
    sortKey: BillingsTaxiOrderListingSortKey;
    requestOrder: BillingsTaxiOrderListingReportRequestOrder;
  }[] = [
    {
      requestOrder: BillingsTaxiOrderListingReportRequestOrder.DATE_DESC,
      sortKey: BillingsTaxiOrderListingSortKey.ORDER_START_DATE_DESC,
    },
    {
      requestOrder: BillingsTaxiOrderListingReportRequestOrder.DATE_ASC,
      sortKey: BillingsTaxiOrderListingSortKey.ORDER_START_DATE_ASC,
    },
    {
      requestOrder: BillingsTaxiOrderListingReportRequestOrder.DISTANCE_DESC,
      sortKey: BillingsTaxiOrderListingSortKey.DISTANCE_DESC,
    },
    {
      requestOrder: BillingsTaxiOrderListingReportRequestOrder.DISTANCE_ASC,
      sortKey: BillingsTaxiOrderListingSortKey.DISTANCE_ASC,
    },
    {
      requestOrder:
        BillingsTaxiOrderListingReportRequestOrder.HIGHWAY_CHARGE_DESC,
      sortKey: BillingsTaxiOrderListingSortKey.TOLL_ROADS_COST_DESC,
    },
    {
      requestOrder:
        BillingsTaxiOrderListingReportRequestOrder.HIGHWAY_CHARGE_ASC,
      sortKey: BillingsTaxiOrderListingSortKey.TOLL_ROADS_COST_ASC,
    },
    {
      requestOrder:
        BillingsTaxiOrderListingReportRequestOrder.HALTING_AMOUNT_ASC,
      sortKey: BillingsTaxiOrderListingSortKey.STOPOVER_COST_ASC,
    },
    {
      requestOrder:
        BillingsTaxiOrderListingReportRequestOrder.HALTING_AMOUNT_DESC,
      sortKey: BillingsTaxiOrderListingSortKey.STOPOVER_COST_DESC,
    },
    {
      requestOrder: BillingsTaxiOrderListingReportRequestOrder.HUMAN_ID_ASC,
      sortKey: BillingsTaxiOrderListingSortKey.INTERNAL_ORDER_ID_ASC,
    },
    {
      requestOrder: BillingsTaxiOrderListingReportRequestOrder.HUMAN_ID_DESC,
      sortKey: BillingsTaxiOrderListingSortKey.INTERNAL_ORDER_ID_DESC,
    },
    {
      requestOrder:
        BillingsTaxiOrderListingReportRequestOrder.BILLING_MODEL_ASC,
      sortKey: BillingsTaxiOrderListingSortKey.BILLING_MODEL_ASC,
    },
    {
      requestOrder:
        BillingsTaxiOrderListingReportRequestOrder.BILLING_MODEL_DESC,
      sortKey: BillingsTaxiOrderListingSortKey.BILLING_MODEL_DESC,
    },
    {
      requestOrder:
        BillingsTaxiOrderListingReportRequestOrder.DISTANCE_RATE_ASC,
      sortKey: BillingsTaxiOrderListingSortKey.DISTANCE_RATE_ASC,
    },
    {
      requestOrder:
        BillingsTaxiOrderListingReportRequestOrder.DISTANCE_RATE_DESC,
      sortKey: BillingsTaxiOrderListingSortKey.DISTANCE_RATE_DESC,
    },
    {
      requestOrder:
        BillingsTaxiOrderListingReportRequestOrder.DISTANCE_AMOUNT_ASC,
      sortKey: BillingsTaxiOrderListingSortKey.DISTANCE_COST_ASC,
    },
    {
      requestOrder:
        BillingsTaxiOrderListingReportRequestOrder.DISTANCE_AMOUNT_DESC,
      sortKey: BillingsTaxiOrderListingSortKey.DISTANCE_COST_DESC,
    },
    {
      requestOrder: BillingsTaxiOrderListingReportRequestOrder.TOTAL_AMOUNT_ASC,
      sortKey: BillingsTaxiOrderListingSortKey.TOTAL_COST_ASC,
    },
    {
      requestOrder:
        BillingsTaxiOrderListingReportRequestOrder.TOTAL_AMOUNT_DESC,
      sortKey: BillingsTaxiOrderListingSortKey.TOTAL_COST_DESC,
    },
  ];

  return options.find((option) => option.sortKey === sortKey)?.requestOrder;
};

const createRequest = (
  filters: BillingsTaxiOrderListingFilter[],
  sortKey: BillingsTaxiOrderListingSortKey | null
): BillingsTaxiOrderListingReportRequest => {
  return {
    order: createRequestOrder(sortKey),
    human_id: filters.find(
      (filter) =>
        filter.type === BillingsTaxiOrderListingFilterType.INTERNAL_ORDER_ID
    )?.value as
      | BillingsTaxiOrderListingInternalOrderIdFilter["value"]
      | undefined,
    plan_entry_human_ids: filters.find(
      (filter) =>
        filter.type === BillingsTaxiOrderListingFilterType.PLAN_ENTRY_HUMAN_IDS
    )?.value as
      | BillingsTaxiOrderListingPlanEntryHumanIdsFilter["value"]
      | undefined,
    cargo_company: filters.find(
      (filter) =>
        filter.type === BillingsTaxiOrderListingFilterType.CARGO_COMPANY
    )?.value as BillingsTaxiOrderListingCargoCompanyFilter["value"] | undefined,
    exclude_cargo_company: filters.find(
      (filter) =>
        filter.type === BillingsTaxiOrderListingFilterType.EXCLUDE_CARGO_COMPANY
    )?.value as
      | BillingsTaxiOrderListingExcludeCargoCompanyFilter["value"]
      | undefined,
    passenger: filters.find(
      (filter) => filter.type === BillingsTaxiOrderListingFilterType.PASSENGER
    )?.value as BillingsTaxiOrderListingPassengerFilter["value"] | undefined,
    status: filters.find(
      (filter) =>
        filter.type === BillingsTaxiOrderListingFilterType.BILLING_STATUS
    )?.value as
      | BillingsTaxiOrderListingBillingStatusFilter["value"]
      | undefined,
    date_since: (
      filters.find(
        (filter) =>
          filter.type === BillingsTaxiOrderListingFilterType.ORDER_START_DATE
      )?.value as
        | BillingsTaxiOrderListingOrderStartDateFilter["value"]
        | undefined
    )?.from.toJSON(),
    date_to: (
      filters.find(
        (filter) =>
          filter.type === BillingsTaxiOrderListingFilterType.ORDER_START_DATE
      )?.value as
        | BillingsTaxiOrderListingOrderStartDateFilter["value"]
        | undefined
    )?.to.toJSON(),
    billing_model: filters.find(
      (filter) =>
        filter.type === BillingsTaxiOrderListingFilterType.BILLING_MODEL
    )?.value as BillingsTaxiOrderListingBillingModelFilter["value"] | undefined,
    billing_type: filters.find(
      (filter) =>
        filter.type === BillingsTaxiOrderListingFilterType.BILLING_TYPE
    )?.value as BillingsTaxiOrderListingBillingTypeFilter["value"] | undefined,
    responsible_taxi: filters.find(
      (filter) =>
        filter.type === BillingsTaxiOrderListingFilterType.DESTINATION_TAXI
    )?.value as
      | BillingsTaxiOrderListingDestinationTaxiFilter["value"]
      | undefined,
  };
};

const billingsTaxiOrderListingReportRequestFactory = {
  createRequest,
};

export default billingsTaxiOrderListingReportRequestFactory;
