import Joi from "joi";
import BillingsTaxiRouteListingByRailyTaxiCorporation from "./types/billings-taxi-route-listing-by-raily-taxi-corporation-company";
import BillingsTaxiRouteListingFilter from "../../common/types/billings-taxi-route-listing-filter";
import BillingsTaxiRouteListingSortKey from "../../common/types/billings-taxi-route-listing-sort-key";
import BillingsTaxiRouteListingByRailyRouteQueryParams from "./types/billings-taxi-route-listing-by-raily-route-query-params";
import billingsTaxiRouteListingRouteQueryParamsService from "../../common/billings-taxi-route-listing-route-query-params.service";

const createRouteQueryParams = (
  taxiCorporationUuid:
    | BillingsTaxiRouteListingByRailyTaxiCorporation["uuid"]
    | null,
  filters: BillingsTaxiRouteListingFilter[],
  sortKey: BillingsTaxiRouteListingSortKey | null,
  page: number,
  pageSize: number
): BillingsTaxiRouteListingByRailyRouteQueryParams => {
  const commonParams =
    billingsTaxiRouteListingRouteQueryParamsService.createRouteQueryParams(
      filters,
      sortKey,
      page,
      pageSize
    );

  return {
    ...commonParams,
    taxiCorporation: taxiCorporationUuid ?? undefined,
  };
};

const getTaxiCorporationUuid = (
  routeQueryParams: BillingsTaxiRouteListingByRailyRouteQueryParams
): string | undefined => {
  if (!validateTaxiCorporationUuid(routeQueryParams.taxiCorporation)) {
    return undefined;
  }

  return routeQueryParams.taxiCorporation;
};

const validateTaxiCorporationUuid = (
  routeQueryTaxiCorporationParam: BillingsTaxiRouteListingByRailyRouteQueryParams["taxiCorporation"]
) => {
  const validationSchema = Joi.string();

  return !validationSchema.validate(routeQueryTaxiCorporationParam).error
    ?.message;
};

const billingsTaxiRouteListingByRailyRouteQueryParamsService = {
  getFilters: billingsTaxiRouteListingRouteQueryParamsService.getFilters,
  getSortKey: billingsTaxiRouteListingRouteQueryParamsService.getSortKey,
  getPage: billingsTaxiRouteListingRouteQueryParamsService.getPage,
  getPageSize: billingsTaxiRouteListingRouteQueryParamsService.getPageSize,
  getTaxiCorporationUuid,
  createRouteQueryParams,
};

export default billingsTaxiRouteListingByRailyRouteQueryParamsService;
