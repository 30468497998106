import { FC } from "react";

type OrderOptimizerDetailsOrdersTableDotProps = {
  color: string;
};

const OrderOptimizerDetailsOrdersTableDotComponent: FC<OrderOptimizerDetailsOrdersTableDotProps> = ({
  color,
}) => {

  return (
    <div
      style={{
        backgroundColor: color,
        borderRadius: "50%",
        width: "10px",
        height: "10px",
      }}
    ></div>
  );
};

export default OrderOptimizerDetailsOrdersTableDotComponent;
