import BillingsTaxiRouteListingReportRequest, {
  BillingsTaxiRouteListingReportRequestOrder,
} from "./api/billings-taxi-route-listing-report.request";
import BillingsTaxiRouteListingFilter, {
  BillingsTaxiRouteListingBillingModelFilter,
  BillingsTaxiRouteListingCargoOrderInternalIdFilter,
  BillingsTaxiRouteListingDriverFilter,
  BillingsTaxiRouteListingFleetPartnerFilter,
  BillingsTaxiRouteListingRouteIdFilter,
  BillingsTaxiRouteListingRouteStartDateFilter,
} from "./types/billings-taxi-route-listing-filter";
import BillingsTaxiRouteListingFilterType from "./types/billings-taxi-route-listing-filter-type";
import BillingsTaxiRouteListingSortKey from "./types/billings-taxi-route-listing-sort-key";

const createRequestOrder = (
  sortKey: BillingsTaxiRouteListingSortKey | null
): BillingsTaxiRouteListingReportRequest["order"] => {
  const options: {
    sortKey: BillingsTaxiRouteListingSortKey;
    requestOrder: BillingsTaxiRouteListingReportRequestOrder;
  }[] = [
    {
      requestOrder: BillingsTaxiRouteListingReportRequestOrder.DISTANCE_DESC,
      sortKey: BillingsTaxiRouteListingSortKey.DISTANCE_DESC,
    },
    {
      requestOrder: BillingsTaxiRouteListingReportRequestOrder.DISTANCE_ASC,
      sortKey: BillingsTaxiRouteListingSortKey.DISTANCE_ASC,
    },
    {
      requestOrder:
        BillingsTaxiRouteListingReportRequestOrder.HIGHWAY_CHARGE_DESC,
      sortKey: BillingsTaxiRouteListingSortKey.TOLL_ROADS_COST_DESC,
    },
    {
      requestOrder:
        BillingsTaxiRouteListingReportRequestOrder.HIGHWAY_CHARGE_ASC,
      sortKey: BillingsTaxiRouteListingSortKey.TOLL_ROADS_COST_ASC,
    },
    {
      requestOrder: BillingsTaxiRouteListingReportRequestOrder.HUMAN_ID_ASC,
      sortKey: BillingsTaxiRouteListingSortKey.ROUTE_ID_ASC,
    },
    {
      requestOrder: BillingsTaxiRouteListingReportRequestOrder.HUMAN_ID_DESC,
      sortKey: BillingsTaxiRouteListingSortKey.ROUTE_ID_DESC,
    },
    {
      requestOrder: BillingsTaxiRouteListingReportRequestOrder.DRIVER_ASC,
      sortKey: BillingsTaxiRouteListingSortKey.DRIVER_ASC,
    },
    {
      requestOrder: BillingsTaxiRouteListingReportRequestOrder.DRIVER_DESC,
      sortKey: BillingsTaxiRouteListingSortKey.DRIVER_DESC,
    },
    {
      requestOrder:
        BillingsTaxiRouteListingReportRequestOrder.BILLING_MODEL_ASC,
      sortKey: BillingsTaxiRouteListingSortKey.BILLING_MODEL_ASC,
    },
    {
      requestOrder:
        BillingsTaxiRouteListingReportRequestOrder.BILLING_MODEL_DESC,
      sortKey: BillingsTaxiRouteListingSortKey.BILLING_MODEL_DESC,
    },
    {
      requestOrder:
        BillingsTaxiRouteListingReportRequestOrder.DISTANCE_RATE_ASC,
      sortKey: BillingsTaxiRouteListingSortKey.DISTANCE_RATE_ASC,
    },
    {
      requestOrder:
        BillingsTaxiRouteListingReportRequestOrder.DISTANCE_RATE_DESC,
      sortKey: BillingsTaxiRouteListingSortKey.DISTANCE_RATE_DESC,
    },
    {
      requestOrder:
        BillingsTaxiRouteListingReportRequestOrder.DISTANCE_AMOUNT_ASC,
      sortKey: BillingsTaxiRouteListingSortKey.DISTANCE_COST_ASC,
    },
    {
      requestOrder:
        BillingsTaxiRouteListingReportRequestOrder.DISTANCE_AMOUNT_DESC,
      sortKey: BillingsTaxiRouteListingSortKey.DISTANCE_COST_DESC,
    },
    {
      requestOrder: BillingsTaxiRouteListingReportRequestOrder.TOTAL_AMOUNT_ASC,
      sortKey: BillingsTaxiRouteListingSortKey.TOTAL_COST_ASC,
    },
    {
      requestOrder:
        BillingsTaxiRouteListingReportRequestOrder.TOTAL_AMOUNT_DESC,
      sortKey: BillingsTaxiRouteListingSortKey.TOTAL_COST_DESC,
    },
  ];

  return options.find((option) => option.sortKey === sortKey)?.requestOrder;
};

const createRequest = (
  filters: BillingsTaxiRouteListingFilter[],
  sortKey: BillingsTaxiRouteListingSortKey | null
): BillingsTaxiRouteListingReportRequest => {
  return {
    order: createRequestOrder(sortKey),
    human_id: (
      filters.find(
        (filter) => filter.type === BillingsTaxiRouteListingFilterType.ROUTE_ID
      )?.value as BillingsTaxiRouteListingRouteIdFilter["value"] | undefined
    )?.toString(),
    cargo_order_human_id: filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiRouteListingFilterType.CARGO_ORDER_INTERNAL_ID
    )?.value as
      | BillingsTaxiRouteListingCargoOrderInternalIdFilter["value"]
      | undefined,
    driver: filters.find(
      (filter) => filter.type === BillingsTaxiRouteListingFilterType.DRIVER
    )?.value as BillingsTaxiRouteListingDriverFilter["value"] | undefined,
    date_since: (
      filters.find(
        (filter) =>
          filter.type === BillingsTaxiRouteListingFilterType.ORDER_START_DATE
      )?.value as
        | BillingsTaxiRouteListingRouteStartDateFilter["value"]
        | undefined
    )?.from.toJSON(),
    date_to: (
      filters.find(
        (filter) =>
          filter.type === BillingsTaxiRouteListingFilterType.ORDER_START_DATE
      )?.value as
        | BillingsTaxiRouteListingRouteStartDateFilter["value"]
        | undefined
    )?.to.toJSON(),
    billing_model: filters.find(
      (filter) =>
        filter.type === BillingsTaxiRouteListingFilterType.BILLING_MODEL
    )?.value as BillingsTaxiRouteListingBillingModelFilter["value"] | undefined,
    fleet_partner: filters.find(
      (filter) =>
        filter.type === BillingsTaxiRouteListingFilterType.FLEET_PARTNER
    )?.value as BillingsTaxiRouteListingFleetPartnerFilter["value"] | undefined,
  };
};

const billingsTaxiRouteListingReportRequestFactory = {
  createRequest,
};

export default billingsTaxiRouteListingReportRequestFactory;
