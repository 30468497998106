import useAsyncData from "../../../hooks/use-async-data";
import planEntryService from "../plan-entry.service";
import PlanEntryDetails from "./plan-entry-details";
import PlanEntryDetailsLoadParams from "./plan-entry-details-load-params";

const usePlanEntryDetails = () => {
  const { isLoading, setIsLoading, isError, setIsError, data, setData } =
    useAsyncData<PlanEntryDetails | null>(null);

  const load = async (
    params: PlanEntryDetailsLoadParams,
    signal: AbortSignal
  ) => {
    setIsLoading(true);
    setIsError(false);

    try {
      const PlanEntryDetailss = await planEntryService.getDetails(
        params,
        signal
      );

      setData(PlanEntryDetailss);
      setIsLoading(false);
    } catch (_error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    isError,
    data,
    load,
  };
};

export default usePlanEntryDetails;
