import billingsTranslationsHelper from "../../../../../languages/billings-translations.helper";
import BillingsTaxiRouteListingSortKey from "./types/billings-taxi-route-listing-sort-key";
import BillingsTaxiRouteListingSortSelectOption from "./types/billings-taxi-route-listing-sort-select-option";

const getSelectOptions = (): BillingsTaxiRouteListingSortSelectOption[] => {
  const translations =
    billingsTranslationsHelper.getTaxiRouteBillingsListingTranslations();

  return [
    {
      label: translations.sort.distanceAscOptionLabel,
      value: BillingsTaxiRouteListingSortKey.DISTANCE_ASC,
    },
    {
      label: translations.sort.distanceDescOptionLabel,
      value: BillingsTaxiRouteListingSortKey.DISTANCE_DESC,
    },
    {
      label: translations.sort.tollRoadsCostAscOptionLabel,
      value: BillingsTaxiRouteListingSortKey.TOLL_ROADS_COST_ASC,
    },
    {
      label: translations.sort.tollRoadsCostDescOptionLabel,
      value: BillingsTaxiRouteListingSortKey.TOLL_ROADS_COST_DESC,
    },
    {
      label: translations.sort.routeIdAscOptionLabel,
      value: BillingsTaxiRouteListingSortKey.ROUTE_ID_ASC,
    },
    {
      label: translations.sort.routeIdDescOptionLabel,
      value: BillingsTaxiRouteListingSortKey.ROUTE_ID_DESC,
    },
    {
      label: translations.sort.driverAscOptionLabel,
      value: BillingsTaxiRouteListingSortKey.DRIVER_ASC,
    },
    {
      label: translations.sort.driverDescOptionLabel,
      value: BillingsTaxiRouteListingSortKey.DRIVER_DESC,
    },
    {
      label: translations.sort.billingModelAscOptionLabel,
      value: BillingsTaxiRouteListingSortKey.BILLING_MODEL_ASC,
    },
    {
      label: translations.sort.billingModelDescOptionLabel,
      value: BillingsTaxiRouteListingSortKey.BILLING_MODEL_DESC,
    },
    {
      label: translations.sort.baseDistanceRateAscOptionLabel,
      value: BillingsTaxiRouteListingSortKey.DISTANCE_RATE_ASC,
    },
    {
      label: translations.sort.baseDistanceRateDescOptionLabel,
      value: BillingsTaxiRouteListingSortKey.DISTANCE_RATE_DESC,
    },
  ];
};

const billingsTaxiRouteListingSortHelper = {
  getSelectOptions,
};

export default billingsTaxiRouteListingSortHelper;
