import { FC } from "react";
import OrderAbandonedListingFilter from "../common/types/order-abandoned-listing-filter";
import OrderAbandonedListingSortKey from "../common/types/order-abandoned-listing-sort-key";
import orderAbandonedListingFiltersHelper from "./order-abandoned-listing-filters.helper";
import ListingSortDefinition from "../../../../common/components/listing/filter/types/listing-sort-definition";
import ListingFilterDefinition from "../../../../common/components/listing/filter/types/listing-filter-definition";
import ListingFilterComponent from "../../../../common/components/listing/filter/listing-filter.component";

type OrderAbandonedListingFiltersProps = {
  filters: OrderAbandonedListingFilter[];
  onFiltersChange: (filters: OrderAbandonedListingFilter[]) => void;
  sortKey: OrderAbandonedListingSortKey;
  onSortKeyChange: (sortKey: OrderAbandonedListingSortKey) => void;
};

const OrderAbandonedListingFiltersComponent: FC<
  OrderAbandonedListingFiltersProps
> = (props) => {
  const filterDefinition: ListingFilterDefinition<OrderAbandonedListingFilter> =
    orderAbandonedListingFiltersHelper().getFilterDefinition();

  const sortDefinition: ListingSortDefinition =
    orderAbandonedListingFiltersHelper().getSortDefinition();

  return (
    <ListingFilterComponent
      filterDefinition={filterDefinition}
      appliedFilters={props.filters}
      onFiltersChange={(appliedFilters) =>
        props.onFiltersChange(appliedFilters as OrderAbandonedListingFilter[])
      }
      sortDefinition={sortDefinition}
      appliedSortKey={props.sortKey}
      onSortKeyChange={(value) =>
        props.onSortKeyChange(value as OrderAbandonedListingSortKey)
      }
      idForTestingFilter="order-abandoned-listing-filter"
      idForTestingSort="order-abandoned-listing-sort"
    />
  );
};

export default OrderAbandonedListingFiltersComponent;
