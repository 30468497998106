enum BillingsTaxiRouteListingFilterType {
  ORDER_START_DATE = "ROUTE_START_DATE",
  ROUTE_ID = "ROUTE_ID",
  DRIVER = "DRIVER",
  BILLING_MODEL = "BILLING_MODEL",
  FLEET_PARTNER = "FLEET_PARTNER",
  CARGO_ORDER_INTERNAL_ID = "CARGO_ORDER_INTERNAL_ID",
}

export default BillingsTaxiRouteListingFilterType;
