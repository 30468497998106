import orderOptimizerRoutesUrls from "./order-optimizer-routes-urls";
import OrderOptimizerDetailsRouteParams from "./types/order-optimizer-details-route-params";

const getListingRoute = () => orderOptimizerRoutesUrls.listing;

const getListingRouteWithOrderHumanId = (orderHumanId: number) => {
  return `${orderOptimizerRoutesUrls.listing}?orderHumanId=${orderHumanId}`;
};

const getDetailsRoute = (params: OrderOptimizerDetailsRouteParams): string => {
  return orderOptimizerRoutesUrls.details.replace(":id", params.id);
};

const orderOptimizerRoutesHelper = {
  getListingRoute,
  getListingRouteWithOrderHumanId,
  getDetailsRoute,
};

export default orderOptimizerRoutesHelper;
