import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useAppContext } from "../../../context/app.context";
import { useNavigate } from "react-router-dom";
import { faCar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OrderDetailsRidesNodeExlusionsResponse, {
  OrderDetailsRidesNodeExlusionsResponseData,
} from "./common/api/order-details-rides-node-exclusions.response";
import OrderDetailsResponse, {
  OrderDetailsResponseData,
} from "./common/api/order-details.response";
import OrderDetailsCompletedRouteResponse, {
  OrderDetailsCompletedRouteResponseItem,
} from "./common/api/order-details-completed-route.response";
import orderDetailsFactory from "./common/order-details.factory";
import orderDetailsHelper from "./common/order-details.helper";
import orderDetailsApiService from "./common/api/order-details-api.service";
import OrderDetailsRouteResponse from "./common/api/order-details-route.response";
import OrderDetailsDriversComponent from "./drivers/order-details-drivers.component";
import OrderDetailsPassengersComponent from "./passengers/order-details-passengers.component";
import OrderDetailsRouteViewMode from "./common/types/order-details-route-view-mode";
import OrderDetailsRouteComponent from "./route/order-details-route.component";
import orderDetailsWaypointFactory from "./common/order-details-waypoint.factory";
import MessengerComponent from "../../../common/components/messenger/messenger.component";
import orderDetailsPlannedRouteMapMarkersFactory from "./common/order-details-planned-route-map-markers.factory";
import OrderDetailsCargoOrder from "./common/types/order-details-cargo-order";
import orderDetailsUserPermissionsHelper from "./common/user-permissions/order-details-user-permission.helper";
import orderDetailsMapRouteFactory from "./common/order-details-map-route.factory";
import OrderDetailsPassenger from "./common/types/order-details-passenger";
import OrderDetailsDriver from "./common/types/order-details-driver";
import OrderDetailsRouteWaypoint from "./common/types/order-details-route-waypoint";
import orderDetailsCopyToClipboardHelper from "./common/copy-to-clipboard/order-details-copy-to-clipboard.helper";
import orderTranslationsHelper from "../../../languages/order-translations.helper";
import SearchRoadRoutingResponse from "../../../common/utils/search-road-route/search-road-routing.response";
import MapMarker from "../../../common/components/map/types/map-marker";
import SearchRoadRoutingRequest from "../../../common/utils/search-road-route/search-road-routing.request";
import MapRoute from "../../../common/components/map/types/map-route";
import notificationService from "../../../common/utils/notification/notification.service";
import clipboardService from "../../../common/utils/clipboard/clipboard.service";
import Row from "../../../common/components/grid/row";
import Column from "../../../common/components/grid/column";
import NoticeBoxComponent from "../../../common/components/notice-box/notice-box.component";
import NoticeBoxType from "../../../common/components/notice-box/notice-box-type";
import MapComponent from "../../../common/components/map/map.component";
import OrderDetailsHistoryComponent from "./history/order-details-history.component";
import OrderDetailsLoaderComponent from "./loader/order-details-loader.component";
import OrderDetailsErrorComponent from "./error/order-details-error.component";
import orderRoutesHelper from "../common/routes/order-routes.helper";
import OrderDetailsChangesApprovalComponent from "./changes-approval/order-details-changes-approval.component";
import OrderDetailsToolsComponent from "./tools/order-details-tools.component";
import mapMarkerIconFactory from "../../../common/components/map/marker/map-marker-icon.factory";
import useAbort from "../../../common/hooks/use-abort";
import orderService from "../../../common/services/order/order.service";
import OrderCancelError from "../../../common/services/order/cancel/order-cancel-error";
import OrderCancelRequestError from "../../../common/services/order/cancel-request/order-cancel-request-error";
import useRouteQueryParams from "../../../common/hooks/use-route-query-params";
import OrderActiveListingRouteQueryParams from "../common/routes/types/order-active-listing-route-params";
import googleMapsRouteService from "../../../common/utils/google-maps-route/google-maps-route.service";
import { CargoOrderActiveListItemAttentions } from "../../../common/services/cargo-order/active-list/cargo-order-active-list";

type OrderDetailsProps = {
  orderUuid: string;
  isOrderFinished?: boolean;
  cargoCompanyOrderId: number;
  /**TODO detach active listing specific type CargoOrderActiveListItemAttentions from here */
  attentions?: CargoOrderActiveListItemAttentions;
  isVisible: boolean;
  cargoCompanyUuid?: string;
  onCancelOrderSuccess?: () => void;
  onAddCandidatureSuccess?: () => void;
};

const OrderDetailsComponent: FC<OrderDetailsProps> = (props) => {
  const { user } = useAppContext();
  const navigate = useNavigate();
  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<OrderActiveListingRouteQueryParams>();

  const leftColumnContentRef = useRef<HTMLDivElement>(null);
  const rightColumnContentHeight = useMemo(() => {
    return leftColumnContentRef.current?.offsetHeight || 500;
  }, [leftColumnContentRef.current?.offsetHeight]);

  const userPermissions = orderDetailsUserPermissionsHelper.getPermissions(
    user?.roles!
  );

  const translations = orderTranslationsHelper.getDetailsTranslations();

  const [isFetchingDetailsData, setIsFetchingDetailsData] = useState(true);
  const [isFetchingDetailsDataError, setIsFetchingDetailsDataError] =
    useState(false);

  const [passengers, setPassengers] = useState<OrderDetailsPassenger[]>([]);
  const [drivers, setDrivers] = useState<OrderDetailsDriver[]>([]);
  const [waypoints, setWaypoints] = useState<OrderDetailsRouteWaypoint[]>([]);
  const [plannedMapRoutes, setPlannedMapRoutes] = useState<MapRoute[]>([]);
  const [isCompletedRoutesFetching, setIsCompletedRoutesFetching] =
    useState(false);
  const [completedMapRoute, setCompletedMapRoute] = useState<MapRoute | null>(
    null
  );
  const [shouldShowCompletedMapRoutes, setShouldShowCompletedMapRoutes] =
    useState(false);

  const [routeViewMode, setRouteViewMode] = useState<OrderDetailsRouteViewMode>(
    OrderDetailsRouteViewMode.ORDERED
  );
  const [isOrderSolved, setIsOrderSolved] = useState(false);

  const [selectedPassenger, setSelectedPassenger] =
    useState<OrderDetailsPassenger | null>(null);

  const [cargoOrderDetails, setCargoOrderDetails] =
    useState<OrderDetailsCargoOrder | null>(null);

  const [responseCargoOrder, setResponseCargoOrder] =
    useState<OrderDetailsResponseData | null>(null);

  const [nodeExclusionResponse, setNodeExclusionResponse] =
    useState<OrderDetailsRidesNodeExlusionsResponseData | null>(null);

  const [orderHistoryRefetchFlag, setOrderHistoryRefetchFlag] = useState(false);
  const [isCancelOrderFetching, setIsCancelOrderFetching] = useState(false);
  const [isCancelOrderRequestFetching, setIsCancelOrderRequestFetching] =
    useState(false);

  const [isRouteStarted, setIsRouteStarted] = useState(false);
  const [shouldShowOrderHistory, setShouldShowOrderHistory] = useState(false);

  const [planEntryUuid, setPlanEntryUuid] = useState<string>();

  const orderCancelAbort = useAbort();
  const orderCancelRequestAbort = useAbort();

  const isApproveButtonDisabled = props.attentions
    ? !(
        props.attentions.cargoOrderCancel ||
        props.attentions.cargoOrderEdit ||
        props.attentions.newPlanEntryPost ||
        props.attentions.newSharedPost
      )
    : true;

  const setWaypointsForOrder = (
    responseCargoOrder: OrderDetailsResponseData
  ) => {
    const waypoints = orderDetailsWaypointFactory.createWaypoints(
      responseCargoOrder.transporting_orders
    );

    setWaypoints(waypoints);
  };

  useEffect(() => {
    if (!responseCargoOrder) {
      return;
    }

    return setWaypointsForOrder(responseCargoOrder);
  }, [isOrderSolved, responseCargoOrder, routeViewMode]);

  const onNodeExclusionsSuccessResponse = (
    responseData: OrderDetailsRidesNodeExlusionsResponseData
  ) => {
    setNodeExclusionResponse(responseData);
  };

  const handleNodeExclusionsResponse = (
    response: OrderDetailsRidesNodeExlusionsResponse
  ) => {
    if (response.status === 200) {
      onNodeExclusionsSuccessResponse(response.data);
    }
  };

  const fetchRidesNodeExclusions = () => {
    orderDetailsApiService
      .fetchRidesNodeExclusions(props.orderUuid)
      .then(handleNodeExclusionsResponse);
  };

  const onDetailsFetchSuccess = (response: OrderDetailsResponse) => {
    const passengers = orderDetailsFactory.createPassengers(
      response.data.transporting_orders
    );

    const responseCandidateDrivers = orderDetailsHelper.getCandidateDrivers(
      response.data.transporting_orders
    );

    const drivers = orderDetailsFactory.createDrivers(
      response.data.transporting_orders,
      responseCandidateDrivers
    );

    const isOrderSolved = !!response.data.transporting_orders.length;

    setPassengers(passengers);
    setDrivers(drivers);

    setIsOrderSolved(isOrderSolved);
    setRouteViewMode(
      isOrderSolved
        ? OrderDetailsRouteViewMode.SOLVED
        : OrderDetailsRouteViewMode.ORDERED
    );

    setResponseCargoOrder(response.data);

    const isRouteStarted = response.data.transporting_orders.some(
      (transportingOrder) => transportingOrder.solve
    );

    setIsRouteStarted(isRouteStarted);

    const cargoOrderDetails = orderDetailsFactory.createBasicInfo(
      response.data
    );

    setCargoOrderDetails(cargoOrderDetails);
    setIsFetchingDetailsDataError(false);
    fetchRidesNodeExclusions();
  };

  const onDetailsFetchFailure = () => {
    setIsFetchingDetailsDataError(true);
  };

  const handleDetailsResponse = (response: OrderDetailsResponse) => {
    if (response.status === 200) {
      onDetailsFetchSuccess(response);
      setPlanEntryUuid(response.data.transporting_orders[0].plan_entry_id ?? undefined)
      return;
    }
    onDetailsFetchFailure();
  };

  const fetchDetails = () => {
    setIsFetchingDetailsData(true);
    orderDetailsApiService
      .fetchDetails(props.orderUuid)
      .then(handleDetailsResponse)
      .catch(onDetailsFetchFailure)
      .finally(() => setIsFetchingDetailsData(false));
  };

  const onCargoExternalIdChangeSuccess = () => {
    fetchDetails();
  };

  useEffect(() => {
    if (!props.isVisible || waypoints.length) {
      return;
    }

    fetchDetails();
  }, [props.isVisible]);

  const driverPositionMapMarker: MapMarker | undefined = useMemo(() => {
    if (shouldShowCompletedMapRoutes) {
      const lastWaypoint =
        completedMapRoute?.waypoints[completedMapRoute.waypoints.length - 1];

      return {
        coordinate: {
          latitude: lastWaypoint?.latitude ?? 0,
          longitude: lastWaypoint?.longitude ?? 0,
        },
        icon: mapMarkerIconFactory.createStandardIcon({
          content: (
            <div>
              <FontAwesomeIcon icon={faCar} />
            </div>
          ),
        }),
      };
    }
  }, [completedMapRoute]);

  const locationsMapMarkers: MapMarker[] = useMemo(() => {
    const mapMarkers =
      orderDetailsPlannedRouteMapMarkersFactory.createMapMarkers(
        waypoints,
        selectedPassenger?.uuid
      );

    return mapMarkers;
  }, [selectedPassenger?.uuid, waypoints]);

  const mapMarkers: MapMarker[] = useMemo(() => {
    if (driverPositionMapMarker) {
      return [...locationsMapMarkers, driverPositionMapMarker];
    }

    return locationsMapMarkers;
  }, [locationsMapMarkers, driverPositionMapMarker]);

  const onPassengerRowClick = (passenger: OrderDetailsPassenger) => {
    if (passenger.uuid === selectedPassenger?.uuid) {
      setSelectedPassenger(null);
      return;
    }
    setSelectedPassenger(passenger);
  };

  const isRouteSequenceSolved =
    orderDetailsHelper.checkIsRouteSequenceSolved(waypoints);

  const isRouteResolved = isRouteSequenceSolved || !!drivers.length;

  const onTryAgainButtonClick = () => {
    fetchDetails();
  };

  const handleRouteResponse = (response: OrderDetailsRouteResponse) => {
    const mapRoutes = orderDetailsMapRouteFactory.createPlannedMapRoutes(
      response.routes
    );
    setPlannedMapRoutes(mapRoutes);
  };

  const fetchRouteForSolvedOrder = () => {
    const request: SearchRoadRoutingRequest = {
      waypointCoordinates: waypoints.map((waypoint) => ({
        latitude: waypoint.place.latitude,
        longitude: waypoint.place.longitude,
      })),
      excludeHighway: nodeExclusionResponse?.exclude_inside,
    };

    orderDetailsApiService.fetchRoute(request).then(handleRouteResponse);
  };

  const fetchRoute = () => {
    if (isRouteResolved) {
      fetchRouteForSolvedOrder();
      return;
    }

    const waypointsCouldBeConnected =
      orderDetailsHelper.getWaypointGroupsCouldBeConnected(waypoints);

    const coordinatesOfWaypointsCouldBeConnected =
      orderDetailsHelper.getCoordinatesOfWaypointsCouldBeConnected(
        waypointsCouldBeConnected
      );

    const fetchPromises: Promise<SearchRoadRoutingResponse>[] = [];

    coordinatesOfWaypointsCouldBeConnected.forEach((coordinates) => {
      const request: SearchRoadRoutingRequest = {
        waypointCoordinates: coordinates,
        excludeHighway: nodeExclusionResponse?.exclude_inside,
      };

      const fetch = orderDetailsApiService.fetchRoute(request);

      fetchPromises.push(fetch);
    });

    Promise.all(fetchPromises).then((responses) => {
      const mapRouteWaypointGroups: MapRoute["waypoints"][] = [];

      responses.forEach((response) => {
        const mapRoute = response.routes[0]
          ? orderDetailsMapRouteFactory.createPlannedMapRoute(
              response.routes[0].geometry.coordinates
            )
          : null;

        if (mapRoute?.waypoints) {
          mapRouteWaypointGroups.push(mapRoute.waypoints);
        }
      });

      const newMapRoutes: MapRoute[] = mapRouteWaypointGroups.map(
        (routeWaypointGroup) => ({
          waypoints: routeWaypointGroup,
        })
      );

      setPlannedMapRoutes(newMapRoutes);
    });
  };

  useEffect(() => {
    if (
      !waypoints.length ||
      !props.isVisible ||
      plannedMapRoutes.length ||
      nodeExclusionResponse?.exclude_inside === undefined
    ) {
      return;
    }

    fetchRoute();
  }, [waypoints, props.isVisible, nodeExclusionResponse?.exclude_inside]);

  const onCompletedRouteFetchSuccess = (
    responseItems: OrderDetailsCompletedRouteResponseItem[]
  ) => {
    const completedMapRoute: MapRoute =
      orderDetailsMapRouteFactory.createCompletedMapRoute(responseItems);

    setCompletedMapRoute(completedMapRoute);
  };

  const onCompletedRouteFetchFailure = () => {
    notificationService.error(
      translations.failureFetchingCompletedRouteNotificationMessageText
    );
    setShouldShowCompletedMapRoutes(false);
  };

  const handleCompletedRouteResponse = (
    response: OrderDetailsCompletedRouteResponse
  ) => {
    if (response.status === 200) {
      onCompletedRouteFetchSuccess(response.data);
      return;
    }
    onCompletedRouteFetchFailure();
  };

  const fetchCompletedRoute = () => {
    setIsCompletedRoutesFetching(true);

    orderDetailsApiService
      .fetchCompletedRoute(props.orderUuid)
      .then(handleCompletedRouteResponse)
      .catch(onCompletedRouteFetchFailure)
      .finally(() => setIsCompletedRoutesFetching(false));
  };

  useEffect(() => {
    if (!shouldShowCompletedMapRoutes) {
      setCompletedMapRoute(null);

      return;
    }

    fetchCompletedRoute();
  }, [shouldShowCompletedMapRoutes]);

  const toggleCompletedMapRoutesActive = () => {
    setShouldShowCompletedMapRoutes((curr) => !curr);
  };

  const toogleGoogleMapsRoute = () => {
    const googleMapsRouteUrl =
      isRouteSequenceSolved && !!mapMarkers.length
        ? googleMapsRouteService.createGoogleMapsRouteUrl(
            mapMarkers.map((marker) => ({
              latitude: marker.coordinate.latitude,
              longitude: marker.coordinate.longitude,
            }))
          )
        : "";

    window.open(googleMapsRouteUrl, "_blank", "noopener,noreferrer");
  };

  const onCopyDetailsButtonClick = () => {
    const textToCopy = orderDetailsCopyToClipboardHelper.getTextToCopy(
      user!,
      responseCargoOrder!.human_id,
      waypoints,
      passengers,
      cargoOrderDetails?.routeNumber!
    );

    clipboardService.copy(textToCopy);
    notificationService.info(translations.copyOrderDetailsNotificationText);
  };

  const onOriginalOrderPlanButtonClick = () => {
    if (routeViewMode === OrderDetailsRouteViewMode.ORDERED) {
      setRouteViewMode(OrderDetailsRouteViewMode.SOLVED);
      setPlannedMapRoutes([]);
      return;
    }

    setRouteViewMode(OrderDetailsRouteViewMode.ORDERED);
    setPlannedMapRoutes([]);
  };

  const onCancelOrderFetchSuccess = () => {
    const successMessage =
      orderDetailsHelper.getOrderCancellationSuccessMessage(
        cargoOrderDetails?.cargoCompanyOrderId!
      );

    notificationService.success(successMessage);

    props.onCancelOrderSuccess && props.onCancelOrderSuccess();
  };

  const onCancelOrderFetchFailure = (error: OrderCancelError) => {
    notificationService.error(error.message);
  };

  const cancelOrder = async () => {
    if (!cargoOrderDetails) return;

    setIsCancelOrderFetching(true);

    try {
      await orderService.cancel(
        {
          orderUuid: cargoOrderDetails.cargoOrderId,
        },
        orderCancelAbort.signal
      );

      onCancelOrderFetchSuccess();
    } catch (error) {
      onCancelOrderFetchFailure(error as OrderCancelError);
    } finally {
      setIsCancelOrderFetching(false);
    }
  };

  const onOrderCancellationRequestSentSuccess = () => {
    const successMessage =
      orderDetailsHelper.getOrderCancellationRequestSuccessMessage(
        cargoOrderDetails?.cargoCompanyOrderId!
      );

    notificationService.success(successMessage);

    setCargoOrderDetails((currBasicInfo) => ({
      ...currBasicInfo!,
      isCancelRequestSent: true,
    }));
  };

  const onOrderCancellationRequestSentFailure = (
    error: OrderCancelRequestError
  ) => {
    notificationService.error(error.message);
  };

  const sendOrderCancellationRequest = async () => {
    if (!cargoOrderDetails) return;

    setIsCancelOrderRequestFetching(true);

    try {
      await orderService.sendCancelRequest(
        {
          orderUuid: cargoOrderDetails.cargoOrderId,
        },
        orderCancelRequestAbort.signal
      );

      onOrderCancellationRequestSentSuccess();
    } catch (error) {
      onOrderCancellationRequestSentFailure(error as OrderCancelRequestError);
    } finally {
      setIsCancelOrderRequestFetching(false);
    }
  };

  const onEditOrderButtonClick = () => {
    const newSearchParams = { ...routeQueryParams };
    newSearchParams.defaultSelectedOrderUuid = props.orderUuid;
    setRouteQueryParams(newSearchParams);
    navigate(orderRoutesHelper.getEditRoute({ orderId: props.orderUuid }));
  };

  const onOrderHistoryButtonClick = () => {
    if (shouldShowOrderHistory) {
      setShouldShowOrderHistory(false);
      return;
    }

    setShouldShowOrderHistory(true);
  };

  const onAddCandidatureSuccess = () => {
    props.onAddCandidatureSuccess && props.onAddCandidatureSuccess();
  };

  const updateTaxiCorporationUuid = (taxiCorporationUuid: string) => {
    setCargoOrderDetails((curr) => ({
      ...curr!,
      taxiCorporationUuid,
    }));
  };

  const onEditTaxiCorporationSuccess = (newTaxiCorporationUuid: string) => {
    updateTaxiCorporationUuid(newTaxiCorporationUuid);
  };

  const mapRoutes: MapRoute[] = useMemo(() => {
    const routes: MapRoute[] = [...plannedMapRoutes];

    if (shouldShowCompletedMapRoutes && completedMapRoute) {
      routes.push(completedMapRoute);
    }

    return routes;
  }, [plannedMapRoutes, completedMapRoute, shouldShowCompletedMapRoutes]);

  const isCancelRequestNoticeBoxVisible =
    !!cargoOrderDetails?.isCancelRequestSent &&
    !cargoOrderDetails.isFinished &&
    !cargoOrderDetails.isCancelled;

  const isCompletedRouteButtonActive =
    shouldShowCompletedMapRoutes &&
    !!completedMapRoute &&
    !isCompletedRoutesFetching;

  const isOriginalOrderPlanButtonActive =
    routeViewMode === OrderDetailsRouteViewMode.ORDERED;

  const refetchOrderHistory = () => {
    setOrderHistoryRefetchFlag((curr) => !curr);
  };

  const onSuccessApproveChangesByDealer = () => {
    refetchOrderHistory();
    setCargoOrderDetails((curr) => ({
      ...curr!,
      changesApprovals: {
        ...curr!.changesApprovals,
        isDealerApproveNeeded: false,
      },
    }));
  };

  const onSuccessApproveChangesByDispatcher = () => {
    refetchOrderHistory();
    setCargoOrderDetails((curr) => ({
      ...curr!,
      changesApprovals: {
        ...curr!.changesApprovals,
        isDispatcherApproveNeeded: false,
      },
    }));
  };

  const onSuccessApproveChangesByOperator = () => {
    refetchOrderHistory();
    setCargoOrderDetails((curr) => ({
      ...curr!,
      changesApprovals: {
        ...curr!.changesApprovals,
        isOperatorApproveNeeded: false,
      },
    }));
  };

  const orderDetailsToolsProps = {
    drivers,
    userPermissions,
    isRouteSequenceSolved,
    cargoOrderDetails,
    cargoCompanyUuid: props.cargoCompanyUuid ?? "",
    isRouteStarted,
    isOrderSolved,
    isCancelOrderFetching,
    isCompletedRoutesFetching,
    isCompletedRouteButtonActive,
    isCancelOrderRequestFetching,
    isOriginalOrderPlanButtonActive,
    shouldShowOrderHistory,
    cancelOrder,
    onEditTaxiCorporationSuccess,
    onAddCandidatureSuccess,
    onCopyDetailsButtonClick,
    onEditOrderButtonClick,
    onOrderHistoryButtonClick,
    toggleCompletedMapRoutesActive,
    toogleGoogleMapsRoute,
    onOriginalOrderPlanButtonClick,
    onCargoExternalIdChangeSuccess,
    sendOrderCancellationRequest,
  };

  const messengerChannelsAvailability =
    orderDetailsHelper.getMessengerChannelAvailability(
      cargoOrderDetails?.solvedOrderId
    );

  const DetailsContent = (
    <Row>
      <Column lg={7}>
        <div ref={leftColumnContentRef}>
          <Row>
            <Column>
              {isCancelRequestNoticeBoxVisible && (
                <>
                  <NoticeBoxComponent
                    type={NoticeBoxType.DANGER}
                    classNames={{ root: "mb-2" }}
                  >
                    {translations.cancelOrderRequestNoticeText}
                  </NoticeBoxComponent>
                </>
              )}
              <div className="order_details_map_wrapper">
                <MapComponent markers={mapMarkers} autoFit routes={mapRoutes} />
                <OrderDetailsToolsComponent {...orderDetailsToolsProps} />
              </div>
            </Column>
          </Row>
          <Row>
            <Column>
              {!isRouteResolved && (
                <small className="order_details_not_completed_route_notification">
                  {translations.notResolvedRouteNotification}
                </small>
              )}
            </Column>
            <Column>
              <div
                className="order_details_cargo_external_id_wrapper"
                title={`${translations.cargoExternalOrderIdLabel}: ${
                  cargoOrderDetails?.cargoCompanyExternalOrderId ?? "-----"
                }`}
              >
                {`${translations.cargoExternalOrderIdLabel}: ${
                  cargoOrderDetails?.cargoCompanyExternalOrderId ?? "-----"
                }`}
              </div>
            </Column>
            <Column md={4} lg={3}>
              <OrderDetailsPassengersComponent
                onPassengerRowClick={onPassengerRowClick}
                passengers={passengers}
                selectedPassenger={selectedPassenger}
                waypoints={waypoints}
              />
              <OrderDetailsDriversComponent
                drivers={drivers}
                planEntryUuid={props.isOrderFinished ? planEntryUuid : undefined}
                orderUuid={props.orderUuid}
              />
            </Column>
            <Column md={8} lg={9}>
              <OrderDetailsRouteComponent
                isRouteSequenceSolved={isRouteSequenceSolved}
                waypoints={waypoints}
                orderUuid={props.orderUuid}
                selectedPassenger={selectedPassenger}
              />
            </Column>
          </Row>
        </div>
      </Column>
      <Column lg={5}>
        <div style={{ height: rightColumnContentHeight }}>
          {shouldShowOrderHistory ? (
            <div className="order_details_history_column">
              <OrderDetailsChangesApprovalComponent
                orderUuid={props.orderUuid}
                isOrderCancelled={responseCargoOrder?.is_cancelled!}
                changeApprovals={cargoOrderDetails?.changesApprovals!}
                onSuccessApproveByDealer={onSuccessApproveChangesByDealer}
                onSuccessApproveByDispatcher={
                  onSuccessApproveChangesByDispatcher
                }
                onSuccessApproveByOperator={onSuccessApproveChangesByOperator}
                isApproveButtonDisabled={isApproveButtonDisabled}
              />
              <div className="order_details_history_column__history_wrapper">
                <OrderDetailsHistoryComponent
                  orderUuid={props.orderUuid}
                  refetchFlag={orderHistoryRefetchFlag}
                />
              </div>
            </div>
          ) : (
            <MessengerComponent
              channelsAvailability={messengerChannelsAvailability}
              orderUuid={props.orderUuid}
              solvedOrderUuid={cargoOrderDetails?.solvedOrderId}
            />
          )}
        </div>
      </Column>
    </Row>
  );

  const ErrorContent = (
    <OrderDetailsErrorComponent
      cargoCompanyOrderId={props.cargoCompanyOrderId}
      isFetching={isFetchingDetailsData}
      onTryAgainButtonClick={onTryAgainButtonClick}
    />
  );

  const LoaderContent = <OrderDetailsLoaderComponent />;

  return (
    <div className="order_details">
      {isFetchingDetailsDataError
        ? ErrorContent
        : isFetchingDetailsData
        ? LoaderContent
        : DetailsContent}
    </div>
  );
};

export default OrderDetailsComponent;
