export enum DriverListRequestOrder {
  FIRST_NAME_ASC = "FIRST_NAME_ASC",
  FIRST_NAME_DESC = "FIRST_NAME_DESC",
  LAST_NAME_ASC = "LAST_NAME_ASC",
  LAST_NAME_DESC = "LAST_NAME_DESC",
  FLEET_PARTNER_NAME_ASC = "FLEET_PARTNER_NAME_ASC",
  FLEET_PARTNER_NAME_DESC = "FLEET_PARTNER_NAME_DESC",
  TAXI_CORPORATION_DISPLAY_NAME_ASC = "TAXI_CORPORATION_DISPLAY_NAME_ASC",
  TAXI_CORPORATION_DISPLAY_NAME_DESC = "TAXI_CORPORATION_DISPLAY_NAME_DESC",
  LAST_PLAN_ENTRY_END_TIME_ASC = "LAST_PLAN_ENTRY_END_TIME_ASC",
  LAST_PLAN_ENTRY_END_TIME_DESC = "LAST_PLAN_ENTRY_END_TIME_DESC",
}

export enum DriverListRequestStatusCriteria {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
  ALL = "ALL",
}

export type DriverListRequestQueryParams = {
  taxiCorporation: string | undefined;
  taxiCorporationId: string | undefined;
  fleetPartner: string | undefined;
  fleetPartnerId: string | undefined;
  address: string | undefined;
  pendingCandidature: boolean | undefined;
  order: DriverListRequestOrder | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  username: string | undefined;
  email: string | undefined;
  statusCriteria: DriverListRequestStatusCriteria | undefined;
  offset: number | undefined;
  limit: number | undefined;
  creationStart: Date | undefined;
  creationEnd: Date | undefined;
};

type DriverListRequest = {
  taxiCorporation?: string;
  taxiCorporationId?: string;
  fleetPartner?: string;
  fleetPartnerId?: string;
  address?: string;
  pendingCandidature?: boolean;
  order?: DriverListRequestOrder;
  firstName?: string;
  lastName?: string;
  username?: string;
  email?: string;
  statusCriteria?: DriverListRequestStatusCriteria;
  offset?: number;
  limit?: number;
  creationStart?: Date;
  creationEnd?: Date;
};

export default DriverListRequest;
