import UserRole from "../../../../../../common/types/user-role";
import userPermissionsService from "../../../../../../common/utils/user/permissions/user-permissions.service";
import billingsCargoOrderListingUserPermissionDefinition, {
  BillingsCargoOrderListingUserPermissions,
} from "./billings-cargo-order-listing-user-permission";

const getPermissions = (
  userRoles: UserRole[]
): BillingsCargoOrderListingUserPermissions => {
  return userPermissionsService.solvePermissions(
    userRoles,
    billingsCargoOrderListingUserPermissionDefinition
  );
};

const billingsCargoOrderListingUserPermissionsHelper = {
  getPermissions,
};

export default billingsCargoOrderListingUserPermissionsHelper;
