import UserRole from "../../../../../../common/types/user-role";
import userPermissionsService from "../../../../../../common/utils/user/permissions/user-permissions.service";
import billingsTaxiUnbilledOrderListingUserPermissionDefinition, {
  BillingsTaxiUnbilledOrderListingUserPermissions,
} from "./billings-taxi-unbilled-order-listing-user-permission";

const getPermissions = (
  userRoles: UserRole[]
): BillingsTaxiUnbilledOrderListingUserPermissions => {
  const permissions = userPermissionsService.solvePermissions(
    userRoles,
    billingsTaxiUnbilledOrderListingUserPermissionDefinition
  );

  return permissions;
};

const billingsTaxiUnbilledOrderListingUserPermissionsHelper = {
  getPermissions,
};

export default billingsTaxiUnbilledOrderListingUserPermissionsHelper;
