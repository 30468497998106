import Joi from "joi";
import BillingsTaxiUnbilledPrivateOrderListingFilter, {
  BillingsTaxiUnbilledPrivateOrderListingClientFilter,
  BillingsTaxiUnbilledPrivateOrderListingDispatchFilter,
  BillingsTaxiUnbilledPrivateOrderListingDriverFilter,
  BillingsTaxiUnbilledPrivateOrderListingExcludeClientFilter,
  BillingsTaxiUnbilledPrivateOrderListingExternalOrderIdFilter,
  BillingsTaxiUnbilledPrivateOrderListingInternalOrderIdFilter,
  BillingsTaxiUnbilledPrivateOrderListingPassengerFilter,
  BillingsTaxiUnbilledPrivateOrderListingRouteAddressFilter,
  BillingsTaxiUnbilledPrivateOrderListingRouteDestinationAddressFilter,
  BillingsTaxiUnbilledPrivateOrderListingRouteIntermediateAddressFilter,
  BillingsTaxiUnbilledPrivateOrderListingRoutePickupAddressFilter,
  BillingsTaxiUnbilledPrivateOrderListingStartDateFilter,
  BillingsTaxiUnbilledPrivateOrderListingStatusFilter,
} from "./types/billings-taxi-unbilled-private-order-listing-filter";
import BillingsTaxiUnbilledPrivateOrderListingRouteQueryParams from "./types/billings-taxi-unbilled-private-order-listing-route-query-params";
import BillingsTaxiUnbilledPrivateOrderListingSortKey from "./types/billings-taxi-unbilled-private-order-listing-sort-key";
import BillingsTaxiUnbilledPrivateOrderListingFilterType from "./types/billings-taxi-unbilled-private-order-listing-filter-type";
import BillingsTaxiUnbilledPrivateOrderListingRouteQueryFilterParams from "./types/billings-taxi-unbilled-private-order-listing-route-query-filter-params";
import BillingsTaxiUnbilledPrivateOrderListingTaxiCorporation from "./types/billings-taxi-unbilled-private-order-listing-taxi-corporation";

const getFilters = (
  routeQueryFilterParams: BillingsTaxiUnbilledPrivateOrderListingRouteQueryFilterParams
): BillingsTaxiUnbilledPrivateOrderListingFilter[] => {
  if (!validateFilters(routeQueryFilterParams)) {
    return [];
  }

  const filters: BillingsTaxiUnbilledPrivateOrderListingFilter[] = [];

  if (routeQueryFilterParams.client) {
    const clientFilter: BillingsTaxiUnbilledPrivateOrderListingClientFilter = {
      type: BillingsTaxiUnbilledPrivateOrderListingFilterType.CLIENT,
      value: routeQueryFilterParams.client,
    };

    filters.push(clientFilter);
  }

  if (routeQueryFilterParams.excludeCargoCompany) {
    const excludeClientFilter: BillingsTaxiUnbilledPrivateOrderListingExcludeClientFilter =
      {
        type: BillingsTaxiUnbilledPrivateOrderListingFilterType.EXCLUDE_CARGO_COMPANY,
        value: routeQueryFilterParams.excludeCargoCompany,
      };

    filters.push(excludeClientFilter);
  }

  if (routeQueryFilterParams.dispatch) {
    const dispatchFilter: BillingsTaxiUnbilledPrivateOrderListingDispatchFilter =
      {
        type: BillingsTaxiUnbilledPrivateOrderListingFilterType.DISPATCH,
        value: routeQueryFilterParams.dispatch,
      };

    filters.push(dispatchFilter);
  }

  if (routeQueryFilterParams.driver) {
    const driverFilter: BillingsTaxiUnbilledPrivateOrderListingDriverFilter = {
      type: BillingsTaxiUnbilledPrivateOrderListingFilterType.DRIVER,
      value: routeQueryFilterParams.driver,
    };

    filters.push(driverFilter);
  }

  if (routeQueryFilterParams.externalOrderId) {
    const externalOrderIdFilter: BillingsTaxiUnbilledPrivateOrderListingExternalOrderIdFilter =
      {
        type: BillingsTaxiUnbilledPrivateOrderListingFilterType.EXTERNAL_ORDER_ID,
        value: routeQueryFilterParams.externalOrderId,
      };

    filters.push(externalOrderIdFilter);
  }

  if (routeQueryFilterParams.internalOrderId) {
    const internalOrderIdFilter: BillingsTaxiUnbilledPrivateOrderListingInternalOrderIdFilter =
      {
        type: BillingsTaxiUnbilledPrivateOrderListingFilterType.INTERNAL_ORDER_ID,
        value: routeQueryFilterParams.internalOrderId,
      };

    filters.push(internalOrderIdFilter);
  }

  if (routeQueryFilterParams.passenger) {
    const passengerFilter: BillingsTaxiUnbilledPrivateOrderListingPassengerFilter =
      {
        type: BillingsTaxiUnbilledPrivateOrderListingFilterType.PASSENGER,
        value: routeQueryFilterParams.passenger,
      };

    filters.push(passengerFilter);
  }

  if (routeQueryFilterParams.routeAddress) {
    const routeAddressFilter: BillingsTaxiUnbilledPrivateOrderListingRouteAddressFilter =
      {
        type: BillingsTaxiUnbilledPrivateOrderListingFilterType.ROUTE_ADDRESS,
        value: routeQueryFilterParams.routeAddress,
      };

    filters.push(routeAddressFilter);
  }

  if (routeQueryFilterParams.routeDestinationAddress) {
    const routeDestinationFilter: BillingsTaxiUnbilledPrivateOrderListingRouteDestinationAddressFilter =
      {
        type: BillingsTaxiUnbilledPrivateOrderListingFilterType.ROUTE_DESTINATION_ADDRESS,
        value: routeQueryFilterParams.routeDestinationAddress,
      };

    filters.push(routeDestinationFilter);
  }

  if (routeQueryFilterParams.routeIntermediateAddress) {
    const routeIntermediateAddressFilter: BillingsTaxiUnbilledPrivateOrderListingRouteIntermediateAddressFilter =
      {
        type: BillingsTaxiUnbilledPrivateOrderListingFilterType.ROUTE_INTERMEDIATE_ADDRESS,
        value: routeQueryFilterParams.routeIntermediateAddress,
      };

    filters.push(routeIntermediateAddressFilter);
  }

  if (routeQueryFilterParams.routePickupAddress) {
    const routePickupAddressFilter: BillingsTaxiUnbilledPrivateOrderListingRoutePickupAddressFilter =
      {
        type: BillingsTaxiUnbilledPrivateOrderListingFilterType.ROUTE_PICKUP_ADDRESS,
        value: routeQueryFilterParams.routePickupAddress,
      };

    filters.push(routePickupAddressFilter);
  }

  if (
    routeQueryFilterParams.startDateFrom &&
    routeQueryFilterParams.startDateTo
  ) {
    const startDateFilter: BillingsTaxiUnbilledPrivateOrderListingStartDateFilter =
      {
        type: BillingsTaxiUnbilledPrivateOrderListingFilterType.START_DATE,
        value: {
          from: new Date(routeQueryFilterParams.startDateFrom),
          to: new Date(routeQueryFilterParams.startDateTo),
        },
      };

    filters.push(startDateFilter);
  }

  if (routeQueryFilterParams.status) {
    const statusFilter: BillingsTaxiUnbilledPrivateOrderListingStatusFilter = {
      type: BillingsTaxiUnbilledPrivateOrderListingFilterType.STATUS,
      value: routeQueryFilterParams.status,
    };

    filters.push(statusFilter);
  }

  return filters;
};

const getSortKey = (
  routeQueryParams: BillingsTaxiUnbilledPrivateOrderListingRouteQueryParams
): BillingsTaxiUnbilledPrivateOrderListingSortKey | undefined => {
  if (!validateSortKey(routeQueryParams.sort)) {
    return undefined;
  }

  return routeQueryParams.sort;
};

const createRouteQueryParams = (
  taxiCorporationUuid:
    | BillingsTaxiUnbilledPrivateOrderListingTaxiCorporation["uuid"]
    | null,
  filters: BillingsTaxiUnbilledPrivateOrderListingFilter[],
  sortKey: BillingsTaxiUnbilledPrivateOrderListingSortKey | null,
  page: number,
  pageSize: number
): BillingsTaxiUnbilledPrivateOrderListingRouteQueryParams => {
  const routeQueryParams: BillingsTaxiUnbilledPrivateOrderListingRouteQueryParams =
    {
      client: filters.find(
        (filter) =>
          filter.type ===
          BillingsTaxiUnbilledPrivateOrderListingFilterType.CLIENT
      )?.value as
        | BillingsTaxiUnbilledPrivateOrderListingClientFilter["value"]
        | undefined,

      excludeCargoCompany: filters.find(
        (filter) =>
          filter.type ===
          BillingsTaxiUnbilledPrivateOrderListingFilterType.EXCLUDE_CARGO_COMPANY
      )?.value as
        | BillingsTaxiUnbilledPrivateOrderListingExcludeClientFilter["value"]
        | undefined,
      dispatch: filters.find(
        (filter) =>
          filter.type ===
          BillingsTaxiUnbilledPrivateOrderListingFilterType.DISPATCH
      )?.value as
        | BillingsTaxiUnbilledPrivateOrderListingDispatchFilter["value"]
        | undefined,
      driver: filters.find(
        (filter) =>
          filter.type ===
          BillingsTaxiUnbilledPrivateOrderListingFilterType.DRIVER
      )?.value as
        | BillingsTaxiUnbilledPrivateOrderListingDriverFilter["value"]
        | undefined,
      externalOrderId: filters.find(
        (filter) =>
          filter.type ===
          BillingsTaxiUnbilledPrivateOrderListingFilterType.EXTERNAL_ORDER_ID
      )?.value as
        | BillingsTaxiUnbilledPrivateOrderListingExternalOrderIdFilter["value"]
        | undefined,
      internalOrderId: filters.find(
        (filter) =>
          filter.type ===
          BillingsTaxiUnbilledPrivateOrderListingFilterType.INTERNAL_ORDER_ID
      )?.value as
        | BillingsTaxiUnbilledPrivateOrderListingInternalOrderIdFilter["value"]
        | undefined,
      passenger: filters.find(
        (filter) =>
          filter.type ===
          BillingsTaxiUnbilledPrivateOrderListingFilterType.PASSENGER
      )?.value as
        | BillingsTaxiUnbilledPrivateOrderListingPassengerFilter["value"]
        | undefined,
      routeAddress: filters.find(
        (filter) =>
          filter.type ===
          BillingsTaxiUnbilledPrivateOrderListingFilterType.ROUTE_ADDRESS
      )?.value as
        | BillingsTaxiUnbilledPrivateOrderListingRouteAddressFilter["value"]
        | undefined,
      routeDestinationAddress: filters.find(
        (filter) =>
          filter.type ===
          BillingsTaxiUnbilledPrivateOrderListingFilterType.ROUTE_DESTINATION_ADDRESS
      )?.value as
        | BillingsTaxiUnbilledPrivateOrderListingRouteDestinationAddressFilter["value"]
        | undefined,
      routeIntermediateAddress: filters.find(
        (filter) =>
          filter.type ===
          BillingsTaxiUnbilledPrivateOrderListingFilterType.ROUTE_INTERMEDIATE_ADDRESS
      )?.value as
        | BillingsTaxiUnbilledPrivateOrderListingRouteIntermediateAddressFilter["value"]
        | undefined,
      routePickupAddress: filters.find(
        (filter) =>
          filter.type ===
          BillingsTaxiUnbilledPrivateOrderListingFilterType.ROUTE_PICKUP_ADDRESS
      )?.value as
        | BillingsTaxiUnbilledPrivateOrderListingRoutePickupAddressFilter["value"]
        | undefined,
      startDateFrom: (
        filters.find(
          (filter) =>
            filter.type ===
            BillingsTaxiUnbilledPrivateOrderListingFilterType.START_DATE
        )?.value as
          | BillingsTaxiUnbilledPrivateOrderListingStartDateFilter["value"]
          | undefined
      )?.from?.toJSON(),
      startDateTo: (
        filters.find(
          (filter) =>
            filter.type ===
            BillingsTaxiUnbilledPrivateOrderListingFilterType.START_DATE
        )?.value as
          | BillingsTaxiUnbilledPrivateOrderListingStartDateFilter["value"]
          | undefined
      )?.to?.toJSON(),
      status: filters.find(
        (filter) =>
          filter.type ===
          BillingsTaxiUnbilledPrivateOrderListingFilterType.STATUS
      )?.value as
        | BillingsTaxiUnbilledPrivateOrderListingStatusFilter["value"]
        | undefined,
      sort: sortKey ?? undefined,
      page,
      pageSize,
      taxiCorporation: taxiCorporationUuid ?? undefined,
    };

  return routeQueryParams;
};

const getTaxiCorporationUuid = (
  routeQueryParams: BillingsTaxiUnbilledPrivateOrderListingRouteQueryFilterParams
): string | undefined => {
  if (!validateTaxiCorporationUuid(routeQueryParams.taxiCorporation)) {
    return undefined;
  }

  return routeQueryParams.taxiCorporation;
};

const validateTaxiCorporationUuid = (
  routeQueryTaxiCorporationParam: BillingsTaxiUnbilledPrivateOrderListingRouteQueryFilterParams["taxiCorporation"]
) => {
  const validationSchema = Joi.string();

  return !validationSchema.validate(routeQueryTaxiCorporationParam).error
    ?.message;
};

const getPage = (
  routeQueryFilterParams: BillingsTaxiUnbilledPrivateOrderListingRouteQueryParams
): number | undefined => {
  if (!validatePage(routeQueryFilterParams.page)) {
    return undefined;
  }

  return routeQueryFilterParams.page
    ? Number(routeQueryFilterParams.page)
    : undefined;
};

const getPageSize = (
  routeQueryFilterParams: BillingsTaxiUnbilledPrivateOrderListingRouteQueryParams
): number | undefined => {
  if (!validatePageSize(routeQueryFilterParams.pageSize)) {
    return undefined;
  }
  return routeQueryFilterParams.pageSize
    ? Number(routeQueryFilterParams.pageSize)
    : undefined;
};

const validateFilters = (
  routeQueryFilterParams: BillingsTaxiUnbilledPrivateOrderListingRouteQueryParams
) => {
  const filterParams: BillingsTaxiUnbilledPrivateOrderListingRouteQueryParams =
    {
      client: routeQueryFilterParams.client,
      excludeCargoCompany: routeQueryFilterParams.excludeCargoCompany,
      dispatch: routeQueryFilterParams.dispatch,
      driver: routeQueryFilterParams.driver,
      externalOrderId: routeQueryFilterParams.externalOrderId,
      internalOrderId: routeQueryFilterParams.internalOrderId,
      passenger: routeQueryFilterParams.passenger,
      routeAddress: routeQueryFilterParams.routeAddress,
      routeDestinationAddress: routeQueryFilterParams.routeDestinationAddress,
      routeIntermediateAddress: routeQueryFilterParams.routeIntermediateAddress,
      routePickupAddress: routeQueryFilterParams.routePickupAddress,
      startDateFrom: routeQueryFilterParams.startDateFrom,
      startDateTo: routeQueryFilterParams.startDateTo,
    };

  const validationSchema =
    Joi.object<BillingsTaxiUnbilledPrivateOrderListingRouteQueryParams>({
      client: Joi.string(),
      excludeCargoCompany: Joi.string(),
      dispatch: Joi.string(),
      driver: Joi.string(),
      externalOrderId: Joi.string(),
      internalOrderId: Joi.string(),
      passenger: Joi.string(),
      routeAddress: Joi.string(),
      routeDestinationAddress: Joi.string(),
      routeIntermediateAddress: Joi.string(),
      routePickupAddress: Joi.string(),
      startDateFrom: Joi.date(),
      startDateTo: Joi.date(),
    });

  return !validationSchema.validate(filterParams).error?.message;
};

const validateSortKey = (
  routeQuerySortParam: BillingsTaxiUnbilledPrivateOrderListingRouteQueryParams["sort"]
): boolean => {
  const validationSchema = Joi.valid(
    ...Object.values(BillingsTaxiUnbilledPrivateOrderListingSortKey)
  );

  return !validationSchema.validate(routeQuerySortParam).error?.message;
};

const validatePage = (
  routeQueryPageParam: BillingsTaxiUnbilledPrivateOrderListingRouteQueryParams["page"]
) => {
  const validationSchema = Joi.number().min(1);

  return !validationSchema.validate(routeQueryPageParam).error?.message;
};

const validatePageSize = (
  routeQueryPageSizeParam: BillingsTaxiUnbilledPrivateOrderListingRouteQueryParams["pageSize"]
) => {
  const validationSchema = Joi.number().valid(...[50, 100, 200]);

  return !validationSchema.validate(routeQueryPageSizeParam).error?.message;
};

const billingsTaxiUnbilledPrivateOrderListingRouteQueryParamsService = {
  getFilters,
  getSortKey,
  getPage,
  getPageSize,
  createRouteQueryParams,
  getTaxiCorporationUuid,
};

export default billingsTaxiUnbilledPrivateOrderListingRouteQueryParamsService;
