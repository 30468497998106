import appConfig from "../../../../../../../../app.config";
import SearchRoadRoutingRequest from "../../../../../../../../common/utils/search-road-route/search-road-routing.request";
import SearchRoadRoutingResponse from "../../../../../../../../common/utils/search-road-route/search-road-routing.response";
import searchRoadRoutingService from "../../../../../../../../common/utils/search-road-route/search-road-routing.service";
import authService from "../../../../../../../auth/common/auth.service";
import DriverDetailsPlanEntryRidesNodeExlusionsResponse from "../../../common/types/driver-details-plan-entry-rides-node-exclusions.response";
import DriverDetailsActiveRoutePlansByRailyPlanResponse from "./driver-details-active-route-plans-by-raily-plan.response";

const fetchPlan = async (
  driverUuid: string
): Promise<DriverDetailsActiveRoutePlansByRailyPlanResponse> => {
  const path = `/drivers/${driverUuid}/plan`;
  const url = `${appConfig.baseApiUrl}${path}`;
  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  };

  const r = await fetch(url, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const fetchRoute = (
  request: SearchRoadRoutingRequest
): Promise<SearchRoadRoutingResponse> => {
  return searchRoadRoutingService.search(request);
};

const fetchRidesNodeExclusions = (
  orderUuid: string
): Promise<DriverDetailsPlanEntryRidesNodeExlusionsResponse> => {
  const path = `/orders/cargo-orders/${orderUuid}/node-exclusion`;
  const url = `${appConfig.baseApiUrl}${path}`;
  const authToken = authService.getAccessToken();
  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  return fetch(url, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const driverDetailsActiveRoutePlansByRailyApiService = {
  fetchPlan,
  fetchRoute,
  fetchRidesNodeExclusions,
};

export default driverDetailsActiveRoutePlansByRailyApiService;
