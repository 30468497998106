import SearchRoadRoutingResponse, {
  SearchRoadRoutingResponseRoute,
} from "../../../utils/search-road-route/search-road-routing.response";
import OrderOptimizerRoadRouteItem from "./order-optimizer-road-route-item";

const createRoute = (
  route: SearchRoadRoutingResponseRoute
): OrderOptimizerRoadRouteItem => {
  return {
    coordinates: route.geometry.coordinates,
    legs: route.legs,
  };
};

const create = (
  response: SearchRoadRoutingResponse
): OrderOptimizerRoadRouteItem => {
  const listItem: OrderOptimizerRoadRouteItem = createRoute(response.routes[0]);

  return listItem;
};

const orderOptimizerRoadRouteFactory = {
  create,
};

export default orderOptimizerRoadRouteFactory;
