import { FC, useMemo } from "react";
import TableComponent, {
  TableProps,
} from "../../../../common/components/table/table.component";
import OrderAbandonedListingItem from "../common/types/order-abandoned-listing-item";
import orderAbandonedListingTableHelper from "./order-abandoned-listing-table.helper";
import OrderAbandonedListingTableRouteComponent from "../common/table/route/order-abandoned-listing-table-route.component";
import OrderDetailsComponent from "../../details/order-details.component";
import OrderAbandonedListingTableRow from "../common/types/order-abandoned-listing-table-row";
import dateService from "../../../../common/utils/date/date.service";
import { useAppContext } from "../../../../context/app.context";

type OrderAbandonedListingTableProps = Pick<
  TableProps,
  "isError" | "isLoading"
> & {
  listingItems: OrderAbandonedListingItem[];
  expandedRowsOrderUuids: string[];
  onRowClick: (orderUuid: OrderAbandonedListingItem["uuid"]) => void;
};

const OrderAbandonedListingTableComponent: FC<
  OrderAbandonedListingTableProps
> = (props) => {
  const columns = orderAbandonedListingTableHelper.getColumns();

  const createTableRow = (
    listingItem: OrderAbandonedListingItem
  ): OrderAbandonedListingTableRow => {
    const orderDate = dateService.format(listingItem.orderDate, "dd.mm.yyyy");

    const isHiddenComponentVisible = props.expandedRowsOrderUuids.includes(
      listingItem.uuid
    );

    return {
      id: listingItem.uuid,
      onClick: () => props.onRowClick(listingItem.uuid),
      value: {
        date: <div title={orderDate}>{orderDate}</div>,
        cargoCompanyOrderId: (
          <div title={String(listingItem.cargoCompanyOrderId)}>
            {listingItem.cargoCompanyOrderId}
          </div>
        ),
        client: (
          <div title={listingItem.producerName}>{listingItem.producerName}</div>
        ),
        dispatch: (
          <div title={listingItem.dispatchName}>{listingItem.dispatchName}</div>
        ),
        cargoCompanyExternalOrderId: (
          <div title={listingItem.cargoCompanyExternalOrderId ?? ""}>
            {listingItem.cargoCompanyExternalOrderId}
          </div>
        ),
        passenger: (
          <div>
            {listingItem.passengers.map((passenger, index) => (
              <div key={index} title={passenger}>
                {passenger}
              </div>
            ))}
          </div>
        ),
        route: (
          <OrderAbandonedListingTableRouteComponent
            addresses={listingItem.routeAddresses}
          />
        ),
      },
      hiddenComponent: (
        <OrderDetailsComponent
          orderUuid={listingItem.uuid}
          cargoCompanyOrderId={listingItem.cargoCompanyOrderId}
          isVisible={isHiddenComponentVisible}
        />
      ),
      isHiddenComponentVisible,
    };
  };

  const tableRows: OrderAbandonedListingTableRow[] = useMemo(() => {
    return props.listingItems.map((listingItem) => createTableRow(listingItem));
  }, [props.listingItems, props.expandedRowsOrderUuids]);

  return (
    <TableComponent
      columns={columns}
      rows={tableRows}
      isLoading={props.isLoading}
      isError={props.isError}
    />
  );
};

export default OrderAbandonedListingTableComponent;
