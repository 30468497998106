import Joi from "joi";
import BillingModel from "../../../../../common/types/billing-model";
import BillingsTaxiRouteListingRouteQueryFilterParams from "./types/billings-taxi-route-listing-route-query-filter-params";
import BillingsTaxiRouteListingFilter, {
  BillingsTaxiRouteListingBillingModelFilter,
  BillingsTaxiRouteListingCargoOrderInternalIdFilter,
  BillingsTaxiRouteListingDriverFilter,
  BillingsTaxiRouteListingFleetPartnerFilter,
  BillingsTaxiRouteListingRouteIdFilter,
  BillingsTaxiRouteListingRouteStartDateFilter,
} from "./types/billings-taxi-route-listing-filter";
import BillingsTaxiRouteListingFilterType from "./types/billings-taxi-route-listing-filter-type";
import billingsTaxiRouteListingFilterHelper from "./billings-taxi-route-listing-filter.helper";
import BillingsTaxiRouteListingRouteQueryParams from "./types/billings-taxi-route-listing-route-query-params";
import BillingsTaxiRouteListingSortKey from "./types/billings-taxi-route-listing-sort-key";

const getFilters = (
  routeQueryFilterParams: BillingsTaxiRouteListingRouteQueryFilterParams
): BillingsTaxiRouteListingFilter[] => {
  if (!validateFilters(routeQueryFilterParams)) {
    return [];
  }

  const filters: BillingsTaxiRouteListingFilter[] = [];

  if (
    routeQueryFilterParams.routeStartDateFrom &&
    routeQueryFilterParams.routeStartDateTo
  ) {
    const routeStartDateFilter: BillingsTaxiRouteListingRouteStartDateFilter = {
      type: BillingsTaxiRouteListingFilterType.ORDER_START_DATE,
      value: {
        from: new Date(routeQueryFilterParams.routeStartDateFrom),
        to: new Date(routeQueryFilterParams.routeStartDateTo),
      },
    };

    filters.push(routeStartDateFilter);
  }

  if (routeQueryFilterParams.routeId) {
    const routeIdFilter: BillingsTaxiRouteListingRouteIdFilter = {
      type: BillingsTaxiRouteListingFilterType.ROUTE_ID,
      value: Number(routeQueryFilterParams.routeId),
    };
    filters.push(routeIdFilter);
  }

  if (routeQueryFilterParams.cargoOrderInternalId) {
    const cargoOrderInternalIdFilter: BillingsTaxiRouteListingCargoOrderInternalIdFilter =
      {
        type: BillingsTaxiRouteListingFilterType.CARGO_ORDER_INTERNAL_ID,
        value: Number(routeQueryFilterParams.cargoOrderInternalId),
      };
    filters.push(cargoOrderInternalIdFilter);
  }

  if (routeQueryFilterParams.driver) {
    const driverFilter: BillingsTaxiRouteListingDriverFilter = {
      type: BillingsTaxiRouteListingFilterType.DRIVER,
      value: routeQueryFilterParams.driver,
    };

    filters.push(driverFilter);
  }

  if (routeQueryFilterParams.billingModel) {
    const billingModelFilter: BillingsTaxiRouteListingBillingModelFilter = {
      type: BillingsTaxiRouteListingFilterType.BILLING_MODEL,
      value: routeQueryFilterParams.billingModel as BillingModel,
    };

    filters.push(billingModelFilter);
  }

  if (routeQueryFilterParams.fleetPartner) {
    const fleetPartnerFilter: BillingsTaxiRouteListingFleetPartnerFilter = {
      type: BillingsTaxiRouteListingFilterType.FLEET_PARTNER,
      value: routeQueryFilterParams.fleetPartner,
    };

    filters.push(fleetPartnerFilter);
  }

  const updatedFilters =
    billingsTaxiRouteListingFilterHelper.getDefaultFilters(filters);

  return updatedFilters;
};

const getSortKey = (
  routeQueryParams: BillingsTaxiRouteListingRouteQueryParams
): BillingsTaxiRouteListingSortKey | undefined => {
  if (!validateSortKey(routeQueryParams.sort)) {
    return undefined;
  }

  return routeQueryParams.sort;
};

const createRouteQueryParams = (
  filters: BillingsTaxiRouteListingFilter[],
  sortKey: BillingsTaxiRouteListingSortKey | null,
  page: number,
  pageSize: number
): BillingsTaxiRouteListingRouteQueryParams => {
  return {
    page,
    pageSize,
    sort: sortKey ?? undefined,
    routeStartDateFrom: (
      filters.find(
        (filter) =>
          filter.type === BillingsTaxiRouteListingFilterType.ORDER_START_DATE
      )?.value as
        | BillingsTaxiRouteListingRouteStartDateFilter["value"]
        | undefined
    )?.from.toJSON(),
    routeStartDateTo: (
      filters.find(
        (filter) =>
          filter.type === BillingsTaxiRouteListingFilterType.ORDER_START_DATE
      )?.value as
        | BillingsTaxiRouteListingRouteStartDateFilter["value"]
        | undefined
    )?.to.toJSON(),
    routeId: (
      filters.find(
        (filter) => filter.type === BillingsTaxiRouteListingFilterType.ROUTE_ID
      )?.value as BillingsTaxiRouteListingRouteIdFilter["value"] | undefined
    )?.toString(),
    cargoOrderInternalId: filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiRouteListingFilterType.CARGO_ORDER_INTERNAL_ID
    )?.value as
      | BillingsTaxiRouteListingCargoOrderInternalIdFilter["value"]
      | undefined,
    billingModel: filters.find(
      (filter) =>
        filter.type === BillingsTaxiRouteListingFilterType.BILLING_MODEL
    )?.value as BillingsTaxiRouteListingBillingModelFilter["value"] | undefined,
    driver: filters.find(
      (filter) => filter.type === BillingsTaxiRouteListingFilterType.DRIVER
    )?.value as BillingsTaxiRouteListingDriverFilter["value"] | undefined,
    fleetPartner: filters.find(
      (filter) =>
        filter.type === BillingsTaxiRouteListingFilterType.FLEET_PARTNER
    )?.value as BillingsTaxiRouteListingFleetPartnerFilter["value"] | undefined,
  };
};

const getPage = (
  routeQueryFilterParams: BillingsTaxiRouteListingRouteQueryParams
): number | undefined => {
  if (!validatePage(routeQueryFilterParams.page)) {
    return undefined;
  }

  return routeQueryFilterParams.page
    ? Number(routeQueryFilterParams.page)
    : undefined;
};

const getPageSize = (
  routeQueryFilterParams: BillingsTaxiRouteListingRouteQueryParams
): number | undefined => {
  if (!validatePageSize(routeQueryFilterParams.pageSize)) {
    return undefined;
  }
  return routeQueryFilterParams.pageSize
    ? Number(routeQueryFilterParams.pageSize)
    : undefined;
};

const validateFilters = (
  routeQueryFilterParams: BillingsTaxiRouteListingRouteQueryFilterParams
) => {
  const filterParams: BillingsTaxiRouteListingRouteQueryFilterParams = {
    routeStartDateFrom: routeQueryFilterParams.routeStartDateFrom,
    routeStartDateTo: routeQueryFilterParams.routeStartDateTo,
    routeId: routeQueryFilterParams.routeId,
    driver: routeQueryFilterParams.driver,
    billingModel: routeQueryFilterParams.billingModel,
  };

  const validationSchema =
    Joi.object<BillingsTaxiRouteListingRouteQueryFilterParams>({
      routeStartDateFrom: Joi.string(),
      routeStartDateTo: Joi.string(),
      routeId: Joi.number(),
      billingModel: Joi.valid(...Object.values(BillingModel)),
      driver: Joi.string(),
    });

  return !validationSchema.validate(filterParams).error?.message;
};

const validateSortKey = (
  routeQuerySortParam: BillingsTaxiRouteListingRouteQueryParams["sort"]
): boolean => {
  const validationSchema = Joi.valid(
    ...Object.values(BillingsTaxiRouteListingSortKey)
  );

  return !validationSchema.validate(routeQuerySortParam).error?.message;
};

const validatePage = (
  routeQueryPageParam: BillingsTaxiRouteListingRouteQueryParams["page"]
) => {
  const validationSchema = Joi.number().min(1);

  return !validationSchema.validate(routeQueryPageParam).error?.message;
};

const validatePageSize = (
  routeQueryPageSizeParam: BillingsTaxiRouteListingRouteQueryParams["pageSize"]
) => {
  const validationSchema = Joi.number().valid(...[50, 100, 200]);

  return !validationSchema.validate(routeQueryPageSizeParam).error?.message;
};

const billingsTaxiRouteListingRouteQueryParamsService = {
  getFilters,
  getSortKey,
  getPage,
  getPageSize,
  createRouteQueryParams,
};

export default billingsTaxiRouteListingRouteQueryParamsService;
