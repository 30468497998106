import UserRole from "../../../../../../common/types/user-role";
import userPermissionsService from "../../../../../../common/utils/user/permissions/user-permissions.service";
import billingsTaxiUnbilledRouteListingUserPermissionDefinition, {
  BillingsTaxiUnbilledRouteListingUserPermissions,
} from "./billings-taxi-unbilled-route-listing-user-permission";

const getPermissions = (
  userRoles: UserRole[]
): BillingsTaxiUnbilledRouteListingUserPermissions => {
  return userPermissionsService.solvePermissions(
    userRoles,
    billingsTaxiUnbilledRouteListingUserPermissionDefinition
  );
};

const billingsTaxiUnbilledRouteListingUserPermissionsHelper = {
  getPermissions,
};

export default billingsTaxiUnbilledRouteListingUserPermissionsHelper;
