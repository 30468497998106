import paginationService from "../../../../../common/utils/pagination/pagination.service";
import BillingsTaxiUnbilledRouteListingFilterType from "./types/billings-taxi-unbilled-route-listing-filter-type";
import BillingsTaxiUnbilledRouteListingSortKey from "./types/billings-taxi-unbilled-route-listing-sort-key";
import BillingsTaxiUnbilledRouteListingFilter, {
  BillingsTaxiUnbilledRouteListingClientFilter,
  BillingsTaxiUnbilledRouteListingDriverFilter,
  BillingsTaxiUnbilledRouteListingFleetPartnerFilter,
  BillingsTaxiUnbilledRouteListingOrderInternalIdFilter,
  BillingsTaxiUnbilledRouteListingRouteAddressFilter,
  BillingsTaxiUnbilledRouteListingRouteDestinationAddressFilter,
  BillingsTaxiUnbilledRouteListingRouteIdFilter,
  BillingsTaxiUnbilledRouteListingRouteIntermediateAddressFilter,
  BillingsTaxiUnbilledRouteListingRoutePickupAddressFilter,
  BillingsTaxiUnbilledRouteListingRouteStartDateFilter,
} from "./types/billings-taxi-unbilled-route-listing-filter";
import BillingsTaxiUnbilledRouteListingRequest, {
  BillingsTaxiUnbilledRouteListingRequestOrder,
} from "./api/billings-taxi-unbilled-route-listing.request";

const createRequestOrder = (
  sortKey: BillingsTaxiUnbilledRouteListingSortKey | null
): BillingsTaxiUnbilledRouteListingRequest["order"] => {
  const options: {
    sortKey: BillingsTaxiUnbilledRouteListingSortKey;
    requestOrder: BillingsTaxiUnbilledRouteListingRequestOrder;
  }[] = [
    {
      requestOrder:
        BillingsTaxiUnbilledRouteListingRequestOrder.START_TIME_DESC,
      sortKey: BillingsTaxiUnbilledRouteListingSortKey.START_TIME_DESC,
    },
    {
      requestOrder: BillingsTaxiUnbilledRouteListingRequestOrder.START_TIME_ASC,
      sortKey: BillingsTaxiUnbilledRouteListingSortKey.START_TIME_ASC,
    },
    {
      requestOrder: BillingsTaxiUnbilledRouteListingRequestOrder.HUMAN_ID_ASC,
      sortKey: BillingsTaxiUnbilledRouteListingSortKey.ROUTE_ID_ASC,
    },
    {
      requestOrder: BillingsTaxiUnbilledRouteListingRequestOrder.HUMAN_ID_DESC,
      sortKey: BillingsTaxiUnbilledRouteListingSortKey.ROUTE_ID_DESC,
    },
    {
      requestOrder: BillingsTaxiUnbilledRouteListingRequestOrder.DRIVER_ASC,
      sortKey: BillingsTaxiUnbilledRouteListingSortKey.DRIVER_ASC,
    },
    {
      requestOrder: BillingsTaxiUnbilledRouteListingRequestOrder.DRIVER_DESC,
      sortKey: BillingsTaxiUnbilledRouteListingSortKey.DRIVER_DESC,
    },
  ];

  return options.find((option) => option.sortKey === sortKey)?.requestOrder;
};

const createRequest = (
  page: number,
  pageSize: number,
  filters: BillingsTaxiUnbilledRouteListingFilter[],
  sortKey: BillingsTaxiUnbilledRouteListingSortKey | null
): BillingsTaxiUnbilledRouteListingRequest => {
  const offset = paginationService.calculateOffset(page, pageSize);

  return {
    limit: pageSize,
    offset,
    order: createRequestOrder(sortKey),
    human_id: (
      filters.find(
        (filter) =>
          filter.type === BillingsTaxiUnbilledRouteListingFilterType.ROUTE_ID
      )?.value as
        | BillingsTaxiUnbilledRouteListingRouteIdFilter["value"]
        | undefined
    )?.toString(),
    driver: filters.find(
      (filter) =>
        filter.type === BillingsTaxiUnbilledRouteListingFilterType.DRIVER
    )?.value as
      | BillingsTaxiUnbilledRouteListingDriverFilter["value"]
      | undefined,
    start_time_since: (
      filters.find(
        (filter) =>
          filter.type ===
          BillingsTaxiUnbilledRouteListingFilterType.ORDER_START_DATE
      )?.value as
        | BillingsTaxiUnbilledRouteListingRouteStartDateFilter["value"]
        | undefined
    )?.from.toJSON(),
    start_time_to: (
      filters.find(
        (filter) =>
          filter.type ===
          BillingsTaxiUnbilledRouteListingFilterType.ORDER_START_DATE
      )?.value as
        | BillingsTaxiUnbilledRouteListingRouteStartDateFilter["value"]
        | undefined
    )?.to.toJSON(),
    client: filters.find(
      (filter) =>
        filter.type === BillingsTaxiUnbilledRouteListingFilterType.CLIENT
    )?.value as
      | BillingsTaxiUnbilledRouteListingClientFilter["value"]
      | undefined,

    external_id: filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiUnbilledRouteListingFilterType.ORDER_INTERNAL_ID
    )?.value as
      | BillingsTaxiUnbilledRouteListingRoutePickupAddressFilter["value"]
      | undefined,
    start_address: filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiUnbilledRouteListingFilterType.ROUTE_PICKUP_ADDRESS
    )?.value as
      | BillingsTaxiUnbilledRouteListingRoutePickupAddressFilter["value"]
      | undefined,
    mid_address: filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiUnbilledRouteListingFilterType.ROUTE_INTERMEDIATE_ADDRESS
    )?.value as
      | BillingsTaxiUnbilledRouteListingRouteIntermediateAddressFilter["value"]
      | undefined,
    end_address: filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiUnbilledRouteListingFilterType.ROUTE_DESTINATION_ADDRESS
    )?.value as
      | BillingsTaxiUnbilledRouteListingRouteDestinationAddressFilter["value"]
      | undefined,
    address: filters.find(
      (filter) =>
        filter.type === BillingsTaxiUnbilledRouteListingFilterType.ROUTE_ADDRESS
    )?.value as
      | BillingsTaxiUnbilledRouteListingRouteAddressFilter["value"]
      | undefined,
    cargo_order_human_id: filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiUnbilledRouteListingFilterType.ORDER_INTERNAL_ID
    )?.value as
      | BillingsTaxiUnbilledRouteListingOrderInternalIdFilter["value"]
      | undefined,
    fleet_partner: filters.find(
      (filter) =>
        filter.type === BillingsTaxiUnbilledRouteListingFilterType.FLEET_PARTNER
    )?.value as
      | BillingsTaxiUnbilledRouteListingFleetPartnerFilter["value"]
      | undefined,
  };
};
const billingsTaxiUnbilledRouteListingRequestFactory = {
  createRequest,
};

export default billingsTaxiUnbilledRouteListingRequestFactory;
