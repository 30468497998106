import UserRole from "../../../../../../common/types/user-role";
import userPermissionsService from "../../../../../../common/utils/user/permissions/user-permissions.service";
import billingsTaxiRouteListingUserPermissionDefinition, {
  BillingsTaxiRouteListingUserPermissions,
} from "./billings-taxi-route-listing-user-permission";

const getPermissions = (
  userRoles: UserRole[]
): BillingsTaxiRouteListingUserPermissions => {
  return userPermissionsService.solvePermissions(
    userRoles,
    billingsTaxiRouteListingUserPermissionDefinition
  );
};

const billingsTaxiRouteListingUserPermissionsHelper = {
  getPermissions,
};

export default billingsTaxiRouteListingUserPermissionsHelper;
