import CargoOrderNodeExclusionRequest from "../../../api/raily/cargo-order/node-exclusion/cargo-order-node-exclusion.request";
import CargoOrderNodeExclusionLoadParams from "./cargo-order-node-exclusion-load-params";

const create = (
  params: CargoOrderNodeExclusionLoadParams
): CargoOrderNodeExclusionRequest => {
  return {
    orderUuid: params.orderUuid,
  };
};

const cargoOrderNodeExclusionRequestFactory = {
  create,
};

export default cargoOrderNodeExclusionRequestFactory;
