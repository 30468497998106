import { FC, useEffect, useMemo, useState } from "react";
import { useAppContext } from "../../../../context/app.context";
import useIsComponentMounted from "../../../../common/hooks/use-is-component-mounted";
import useRouteQueryParams from "../../../../common/hooks/use-route-query-params";
import BillingsTaxiUnbilledTransferredOrderListingItem from "./common/types/billings-taxi-unbilled-transferred-order-listing-item";
import BillingsTaxiUnbilledTransferredOrderListingFilter, {
  BillingsTaxiUnbilledTransferredOrderListingStartDateFilter,
} from "./common/types/billings-taxi-unbilled-transferred-order-listing-filter";
import billingsTaxiUnbilledTransferredOrderListingRouteQueryParamsService from "./common/billings-taxi-unbilled-transferred-order-listing-route-query-params.service";
import BillingsTaxiUnbilledTransferredOrderListingSortKey from "./common/types/billings-taxi-unbilled-transferred-order-listing-sort-key";
import BillingsTaxiUnbilledTransferredOrderListingSortSelectOption from "./common/types/billings-taxi-unbilled-transferred-order-listing-sort-select-option";
import BillingsTaxiUnbilledTransferredOrderListingSortHelper from "./common/helper/billings-taxi-unbilled-transferred-order-listing-sort.helper";
import usePagination from "../../../../common/hooks/use-pagination";
import BillingsTaxiUnbilledTransferredOrderListingResponse, {
  BillingsTaxiUnbilledTransferredOrderListingResponseData,
} from "./common/api/billings-taxi-unbilled-transferred-order-listing-response";
import billingsTaxiUnbilledTransferredOrderListingRequestFactory from "./common/factory/billings-taxi-unbilled-transferred-order-listing-request.factory";
import billingsTaxiUnbilledTransferredOrderListingApiService from "./common/api/billings-taxi-unbilled-transferred-order-listing-api.service";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import BillingsTaxiUnbilledTransferredOrderListingRouteQueryParams from "./common/types/billings-taxi-unbilled-transferred-order-listing-route-query-params";
import BillingsTaxiUnbilledTransferredOrderListingFactory from "./common/factory/billings-taxi-unbilled-transferred-order-listing.factory";
import BillingsTaxiUnbilledTransferredOrderListingFilterType from "./common/types/billings-taxi-unbilled-transferred-order-listing-filter-type";
import DateRange from "../../../../common/types/date-range";
import BillingsTaxiUnbilledTransferredOrderListingFiltersSelectComponent from "./common/filters/select/billings-taxi-unbilled-transferred-order-listing-filters-select.component";
import BillingsTaxiUnbilledTransferredOrderListingFiltersBadgeListComponent from "./common/filters/list/billings-taxi-unbilled-transferred-order-listing-filters-badge-list.component";
import CardComponent from "../../../../common/components/card/card.component";
import PaginationComponent from "../../../../common/components/pagination/pagination.component";
import BillingsTaxiUnbilledTransferredOrderListingTableComponent from "./common/table/billings-taxi-unbilled-transferred-order-listing-table.component";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import ListingSortSelectComponent from "../../../../common/components/listing/filter/sort/select/listing-sort-select.component";
import DateRangeInputComponent from "../../../../common/components/form/input/date-range/date-range-input.component";
import billingsTranslationsHelper from "../../../../languages/billings-translations.helper";
import billingBreadcrumbsHelper from "../../common/breadcrumbs/billings-breadcrumbs.helper";

const BillingsTaxiUnbilledTransferredOrderListingComponent: FC = () => {
  const translations =
    billingsTranslationsHelper.getTaxiUnbilledTransferredOrderBillingsListingTranslations();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(
    documentTitleTranslations.billingsTaxiUnbilledTransferredOrderListing
  );

  const { selectedAppLanguage, setBreadcrumbs } = useAppContext();

  const isComponentMounted = useIsComponentMounted();

  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<BillingsTaxiUnbilledTransferredOrderListingRouteQueryParams>();

  const [isListingFetching, setIsListingFetching] = useState(false);

  const [isListingFetchingError, setIsListingFetchingError] = useState(false);

  const [listingItems, setListingItems] = useState<
    BillingsTaxiUnbilledTransferredOrderListingItem[]
  >([]);
  const [totalResults, setTotalResults] = useState(0);

  const [filters, setFilters] = useState<
    BillingsTaxiUnbilledTransferredOrderListingFilter[]
  >(() =>
    billingsTaxiUnbilledTransferredOrderListingRouteQueryParamsService.getFilters(
      routeQueryParams
    )
  );

  const [selectedSortKey, setSelectedSortKey] =
    useState<BillingsTaxiUnbilledTransferredOrderListingSortKey | null>(
      () =>
        billingsTaxiUnbilledTransferredOrderListingRouteQueryParamsService.getSortKey(
          routeQueryParams
        ) ?? null
    );

  const sortSelectOptions: BillingsTaxiUnbilledTransferredOrderListingSortSelectOption[] =
    useMemo(
      () =>
        BillingsTaxiUnbilledTransferredOrderListingSortHelper.getSelectOptions(),
      [selectedAppLanguage]
    );

  const { page, pageSize, setPage, setPageSize } = usePagination({
    totalResults: totalResults,
    defaultPageSize:
      billingsTaxiUnbilledTransferredOrderListingRouteQueryParamsService.getPageSize(
        routeQueryParams
      ),
    defaultPage:
      billingsTaxiUnbilledTransferredOrderListingRouteQueryParamsService.getPage(
        routeQueryParams
      ),
  });

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    const queryParams =
      billingsTaxiUnbilledTransferredOrderListingRouteQueryParamsService.createRouteQueryParams(
        filters,
        selectedSortKey,
        page,
        pageSize
      );

    setRouteQueryParams(queryParams);
  }, [filters, selectedSortKey, page, pageSize]);

  useEffect(() => {
    const breadcrumbs =
      billingBreadcrumbsHelper.getTaxiUnbilledTransferredOrderListingBreadcrumbs();
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  const onListingFetchSuccess = (
    responseData: BillingsTaxiUnbilledTransferredOrderListingResponseData
  ) => {
    setTotalResults(responseData.total_count);

    const listingItems =
      BillingsTaxiUnbilledTransferredOrderListingFactory.createListingItems(
        responseData.data
      );

    setListingItems(listingItems);
  };

  const onListingFetchFailure = () => {
    setIsListingFetchingError(true);
  };

  const handleListingResponse = (
    response: BillingsTaxiUnbilledTransferredOrderListingResponse
  ) => {
    if (response.status === 200) {
      onListingFetchSuccess(response.data);
      return;
    }

    onListingFetchFailure();
  };

  const fetchListing = () => {
    setIsListingFetching(true);
    setIsListingFetchingError(false);

    const request =
      billingsTaxiUnbilledTransferredOrderListingRequestFactory.createRequest(
        page,
        pageSize,
        filters,
        selectedSortKey
      );

    billingsTaxiUnbilledTransferredOrderListingApiService
      .fetchListing(request)
      .then(handleListingResponse)
      .catch(onListingFetchFailure)
      .finally(() => setIsListingFetching(false));
  };

  useEffect(() => {
    if (!page || !pageSize) {
      return;
    }

    fetchListing();
  }, [page, pageSize, filters, selectedSortKey]);

  const addNewFilter = (
    newFilter: BillingsTaxiUnbilledTransferredOrderListingFilter
  ) => {
    setFilters((curr) => [...curr, newFilter]);
    setPage(1);
  };

  const deleteFilter = (index: number) => {
    const newFilters = filters.filter((filter, _index) => _index !== index);

    setFilters(newFilters);
    setPage(1);
  };

  const deleteAllFilters = () => {
    setFilters([]);
    setPage(1);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    window.scroll({ top: 0, behavior: "smooth" });
  };

  const onPageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
    setPage(1);
  };

  const selectedSortSelectOption = useMemo(() => {
    return (
      sortSelectOptions.find((item) => item.value === selectedSortKey) ?? null
    );
  }, [selectedSortKey]);

  const startDateFilterValue = filters.find(
    (filter) =>
      filter.type ===
      BillingsTaxiUnbilledTransferredOrderListingFilterType.START_DATE
  )?.value as
    | BillingsTaxiUnbilledTransferredOrderListingStartDateFilter["value"]
    | undefined;

  const onStartDateFilterValueChange = (dateRange: DateRange | null) => {
    if (!dateRange) {
      const newFilters = filters.filter(
        (filter) =>
          filter.type !==
          BillingsTaxiUnbilledTransferredOrderListingFilterType.START_DATE
      );

      setFilters(newFilters);
      return;
    }

    const isFilterExists = !!filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiUnbilledTransferredOrderListingFilterType.START_DATE
    );

    const newFilter: BillingsTaxiUnbilledTransferredOrderListingStartDateFilter =
      {
        type: BillingsTaxiUnbilledTransferredOrderListingFilterType.START_DATE,
        value: {
          from: dateRange.from!,
          to: dateRange.to!,
        },
      };

    if (isFilterExists) {
      const newFilters = [
        ...filters.filter(
          (filter) =>
            filter.type !==
            BillingsTaxiUnbilledTransferredOrderListingFilterType.START_DATE
        ),
        newFilter,
      ];

      setFilters(newFilters);
      setPage(1);
      return;
    }

    setFilters((curr) => [...curr, newFilter]);
    setPage(1);
  };

  return (
    <div className="billings_taxi_unbilled_transferred_order_listing">
      <HeadingComponent title={translations.header.headingText} />
      <div className="billings_taxi_unbilled_transferred_order_listing_tools">
        <div className="d-flex">
          <BillingsTaxiUnbilledTransferredOrderListingFiltersSelectComponent
            filters={filters}
            onAddNewFilter={addNewFilter}
          />
          <DateRangeInputComponent
            date={startDateFilterValue ?? null}
            onChange={onStartDateFilterValueChange}
            classNames={{ root: "ml-2" }}
            placeholder={
              translations.filters.searchByStartDateSelectInputPlaceholder
            }
            idForTesting="billings-taxi-unbilled-transferred-order-listing-date-range"
          />
        </div>
        <ListingSortSelectComponent
          onChange={(option) => setSelectedSortKey(option?.value!)}
          options={sortSelectOptions}
          value={selectedSortSelectOption}
          idForTesting="billings-taxi-unbilled-transferred-order-listing-sort"
        />
      </div>
      <BillingsTaxiUnbilledTransferredOrderListingFiltersBadgeListComponent
        filters={filters}
        onDeleteFilterClick={deleteFilter}
        onDeleteAllFiltersButtonClick={deleteAllFilters}
      />
      <CardComponent classNames={{ root: "mt-4" }}>
        <BillingsTaxiUnbilledTransferredOrderListingTableComponent
          listingItems={listingItems}
          isError={isListingFetchingError}
          isLoading={isListingFetching}
        />
        <div className="billings_taxi_unbilled_transferred_order_listing__pagination_wrapper">
          <PaginationComponent
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            page={page}
            pageSize={pageSize}
            totalResults={totalResults}
          />
        </div>
      </CardComponent>
    </div>
  );
};

export default BillingsTaxiUnbilledTransferredOrderListingComponent;
