import { FC, useEffect, useMemo, useState } from "react";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import FormFieldComponent from "../../../../common/components/form/field/form-field.component";
import BillingsTaxiUnbilledPrivateOrderListingTaxiCorporationSelectOption from "./common/types/billings-taxi-unbilled-private-order-listing-taxi-corporation-select-option";
import usePagination from "../../../../common/hooks/use-pagination";
import BillingsTaxiUnbilledPrivateOrderListingItem from "./common/types/billings-taxi-unbilled-private-order-listing-item";
import billingsTaxiUnbilledPrivateOrderListingRequestFactory from "./common/billings-taxi-unbilled-private-order-listing-request.factory";
import useIsComponentMounted from "../../../../common/hooks/use-is-component-mounted";
import billingsTaxiUnbilledPrivateOrderListingRouteQueryParamsService from "./common/billings-taxi-unbilled-private-order-listing-route-query-params.service";
import BillingsTaxiUnbilledPrivateOrderListingFilter, {
  BillingsTaxiUnbilledPrivateOrderListingStartDateFilter,
} from "./common/types/billings-taxi-unbilled-private-order-listing-filter";
import useRouteQueryParams from "../../../../common/hooks/use-route-query-params";
import BillingsTaxiUnbilledPrivateOrderListingRouteQueryParams from "./common/types/billings-taxi-unbilled-private-order-listing-route-query-params";
import BillingsTaxiUnbilledPrivateOrderListingSortKey from "./common/types/billings-taxi-unbilled-private-order-listing-sort-key";
import billingsTaxiUnbilledPrivateOrderListingApiService from "./common/api/billings-taxi-unbilled-private-order-listing-api.service";
import BillingsTaxiUnbilledPrivateOrderListingResponse, {
  BillingsTaxiUnbilledPrivateOrderListingResponseData,
} from "./common/api/billings-taxi-unbilled-private-order-listing.response";
import billingsTaxiUnbilledPrivateOrderListingFactory from "./common/billings-taxi-unbilled-private-order-listing.factory";
import BillingsTaxiUnbilledPrivateOrderListingTaxiCoporationsResponse, {
  BillingsTaxiUnbilledPrivateOrderListingTaxiCoporationsResponseDataItem,
} from "./common/api/billings-taxi-unbilled-private-order-listing-taxi-corporations.response";
import ListingSortSelectComponent from "../../../../common/components/listing/filter/sort/select/listing-sort-select.component";
import BillingsTaxiUnbilledPrivateOrderListingSortSelectOption from "./common/types/billings-taxi-unbilled-private-order-listing-sort-select-option";
import billingsTaxiUnbilledPrivateOrderListingSortHelper from "./common/billings-taxi-unbilled-private-order-listing-sort.helper";
import { useAppContext } from "../../../../context/app.context";
import CardComponent from "../../../../common/components/card/card.component";
import PaginationComponent from "../../../../common/components/pagination/pagination.component";
import BillingsTaxiUnbilledPrivateOrderListingTableComponent from "./table/billings-taxi-unbilled-private-order-listing-table.component";
import BillingsTaxiUnbilledPrivateOrderListingFilterType from "./common/types/billings-taxi-unbilled-private-order-listing-filter-type";
import DateRange from "../../../../common/types/date-range";
import BillingsTaxiUnbilledPrivateOrderListingFiltersSelectComponent from "./common/filters/select/billings-taxi-unbilled-private-order-listing-filters-select.component";
import BillingsTaxiUnbilledPrivateOrderListingFiltersBadgeListComponent from "./common/filters/list/billings-taxi-unbilled-private-order-listing-filters-badge-list.component";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import BillingsTaxiUnbilledPrivateOrderListingRequest from "./common/api/billings-taxi-unbilled-private-order-listing.request";
import DateRangeInputComponent from "../../../../common/components/form/input/date-range/date-range-input.component";
import SingleSelectComponent from "../../../../common/components/form/select/single-select/single-select.component";
import StatusLegendComponent from "../../../../common/components/status-legend/status-legend.component";
import billingsTaxiUnbilledPrivateOrderListingHelper from "./common/billings-taxi-unbilled-private-order-listing.helper";
import billingsTranslationsHelper from "../../../../languages/billings-translations.helper";
import billingBreadcrumbsHelper from "../../common/breadcrumbs/billings-breadcrumbs.helper";

type BillingsTaxiUnbilledPrivateOrderListingProps = {};

const BillingsTaxiUnbilledPrivateOrderListingComponent: FC<
  BillingsTaxiUnbilledPrivateOrderListingProps
> = () => {
  const translations =
    billingsTranslationsHelper.getTaxiUnbilledPrivateOrderBillingsListingTranslations();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(
    documentTitleTranslations.billingsTaxiUnbilledPrivateOrderListing
  );

  const { selectedAppLanguage, setBreadcrumbs } = useAppContext();

  useEffect(() => {
    const breadcrumbs =
      billingBreadcrumbsHelper.getTaxiUnbilledPrivateOrderListingBreadcrumbs();

    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  const isComponentMounted = useIsComponentMounted();

  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<BillingsTaxiUnbilledPrivateOrderListingRouteQueryParams>();

  const [isTaxiCorporationsFetching, setIsTaxiCorporationsFetching] =
    useState(false);
  const [isTaxiCorporationsFetchingError, setIsTaxiCorporationsFetchingError] =
    useState(false);

  const [taxiCorporationSelectOptions, setTaxiCorporationSelectOptions] =
    useState<
      BillingsTaxiUnbilledPrivateOrderListingTaxiCorporationSelectOption[]
    >([]);

  const [selectedTaxiCorporationUuid, setSelectedTaxiCorporationUuid] =
    useState<string | null>(
      () =>
        billingsTaxiUnbilledPrivateOrderListingRouteQueryParamsService.getTaxiCorporationUuid(
          routeQueryParams
        ) ?? null
    );

  const selectedTaxiCorporationSelectOption = taxiCorporationSelectOptions.find(
    (option) => option.value.uuid === selectedTaxiCorporationUuid
  );

  const onTaxiCorporationFetchSuccess = (
    responseDataItems: BillingsTaxiUnbilledPrivateOrderListingTaxiCoporationsResponseDataItem[]
  ) => {
    const taxiCorporationsSelectOptions =
      billingsTaxiUnbilledPrivateOrderListingFactory.createTaxiCorporationSelectOptions(
        responseDataItems
      );

    setTaxiCorporationSelectOptions(taxiCorporationsSelectOptions);
  };

  const onTaxiCorporationFetchFailure = () => {
    setIsTaxiCorporationsFetchingError(true);
  };

  const handleTaxiCorporationResponse = (
    response: BillingsTaxiUnbilledPrivateOrderListingTaxiCoporationsResponse
  ) => {
    if (response.status === 200) {
      onTaxiCorporationFetchSuccess(response.data.data);
      return;
    }

    onTaxiCorporationFetchFailure();
  };

  useEffect(() => {
    setIsTaxiCorporationsFetching(true);
    setIsTaxiCorporationsFetchingError(false);

    billingsTaxiUnbilledPrivateOrderListingApiService
      .fetchTaxiCorporations()
      .then(handleTaxiCorporationResponse)
      .catch(onListingFetchFailure)
      .finally(() => setIsTaxiCorporationsFetching(false));
  }, []);

  const [isListingFetching, setIsListingFetching] = useState(false);
  const [isListingFetchingError, setIsListingFetchingError] = useState(false);
  const [listingItems, setListingItems] = useState<
    BillingsTaxiUnbilledPrivateOrderListingItem[]
  >([]);
  const [totalResults, setTotalResults] = useState(0);

  const [filters, setFilters] = useState<
    BillingsTaxiUnbilledPrivateOrderListingFilter[]
  >(() =>
    billingsTaxiUnbilledPrivateOrderListingRouteQueryParamsService.getFilters(
      routeQueryParams
    )
  );

  const sortSelectOptions: BillingsTaxiUnbilledPrivateOrderListingSortSelectOption[] =
    useMemo(
      () =>
        billingsTaxiUnbilledPrivateOrderListingSortHelper.getSelectOptions(),
      [selectedAppLanguage]
    );

  const [selectedSortKey, setSelectedSortKey] =
    useState<BillingsTaxiUnbilledPrivateOrderListingSortKey | null>(
      () =>
        billingsTaxiUnbilledPrivateOrderListingRouteQueryParamsService.getSortKey(
          routeQueryParams
        ) ?? null
    );

  const selectedSortSelectOption = useMemo(() => {
    return (
      sortSelectOptions.find((item) => item.value === selectedSortKey) ?? null
    );
  }, [selectedSortKey]);

  const startDateFilterValue = filters.find(
    (filter) =>
      filter.type ===
      BillingsTaxiUnbilledPrivateOrderListingFilterType.START_DATE
  )?.value as
    | BillingsTaxiUnbilledPrivateOrderListingStartDateFilter["value"]
    | undefined;

  const onOrderStartDateFilterValueChange = (dateRange: DateRange | null) => {
    if (!dateRange) {
      const newFilters = filters.filter(
        (filter) =>
          filter.type !==
          BillingsTaxiUnbilledPrivateOrderListingFilterType.START_DATE
      );

      setFilters(newFilters);
      return;
    }

    const isFilterExists = !!filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiUnbilledPrivateOrderListingFilterType.START_DATE
    );

    const newFilter: BillingsTaxiUnbilledPrivateOrderListingStartDateFilter = {
      type: BillingsTaxiUnbilledPrivateOrderListingFilterType.START_DATE,
      value: {
        from: dateRange.from!,
        to: dateRange.to!,
      },
    };

    if (isFilterExists) {
      const newFilters = [
        ...filters.filter(
          (filter) =>
            filter.type !==
            BillingsTaxiUnbilledPrivateOrderListingFilterType.START_DATE
        ),
        newFilter,
      ];

      setFilters(newFilters);
      setPage(1);
      return;
    }

    setFilters((curr) => [...curr, newFilter]);
    setPage(1);
  };

  const { page, pageSize, setPage, setPageSize } = usePagination({
    totalResults: totalResults,
    defaultPageSize:
      billingsTaxiUnbilledPrivateOrderListingRouteQueryParamsService.getPageSize(
        routeQueryParams
      ),
    defaultPage:
      billingsTaxiUnbilledPrivateOrderListingRouteQueryParamsService.getPage(
        routeQueryParams
      ),
  });

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    const queryParams =
      billingsTaxiUnbilledPrivateOrderListingRouteQueryParamsService.createRouteQueryParams(
        selectedTaxiCorporationUuid,
        filters,
        selectedSortKey,
        page,
        pageSize
      );

    setRouteQueryParams(queryParams);
  }, [filters, selectedSortKey, page, pageSize, selectedTaxiCorporationUuid]);

  const onTaxiCorporationChange = (
    selectedOption: BillingsTaxiUnbilledPrivateOrderListingTaxiCorporationSelectOption
  ) => {
    setSelectedTaxiCorporationUuid(selectedOption.value.uuid);
    setPage(1);
  };

  const onListingFetchSuccess = (
    responseData: BillingsTaxiUnbilledPrivateOrderListingResponseData
  ) => {
    setTotalResults(responseData.total_count);

    const listingItems =
      billingsTaxiUnbilledPrivateOrderListingFactory.createListingItems(
        responseData.data
      );

    setListingItems(listingItems);
  };

  const onListingFetchFailure = () => {
    setIsListingFetchingError(true);
  };

  const handleListingResponse = (
    response: BillingsTaxiUnbilledPrivateOrderListingResponse
  ) => {
    if (response.status === 200) {
      onListingFetchSuccess(response.data);
      return;
    }

    onListingFetchFailure();
  };

  const fetchListing = () => {
    setIsListingFetching(true);
    setIsListingFetchingError(false);

    const request: BillingsTaxiUnbilledPrivateOrderListingRequest =
      billingsTaxiUnbilledPrivateOrderListingRequestFactory.createRequest(
        page,
        pageSize,
        filters,
        selectedSortKey
      );

    billingsTaxiUnbilledPrivateOrderListingApiService
      .fetchListing(selectedTaxiCorporationUuid!, request)
      .then(handleListingResponse)
      .catch(onListingFetchFailure)
      .finally(() => setIsListingFetching(false));
  };

  useEffect(() => {
    if (!page || !pageSize || !selectedTaxiCorporationUuid) {
      return;
    }

    fetchListing();
  }, [filters, selectedTaxiCorporationUuid, selectedSortKey, page, pageSize]);

  const onPageChange = (page: number) => {
    setPage(page);
    window.scroll({ top: 0, behavior: "smooth" });
  };

  const onPageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
    setPage(1);
  };

  const deleteFilter = (index: number) => {
    const newFilters = filters.filter((filter, _index) => _index !== index);

    setFilters(newFilters);
    setPage(1);
  };

  const deleteAllFilters = () => {
    setFilters([]);
    setPage(1);
  };

  const addNewFilter = (
    newFilter: BillingsTaxiUnbilledPrivateOrderListingFilter
  ) => {
    setFilters((curr) => [...curr, newFilter]);
    setPage(1);
  };

  const isListingContentVisible = !!selectedTaxiCorporationUuid;
  const statusOptions =
    billingsTaxiUnbilledPrivateOrderListingHelper.getStatusLegendOptions();

  return (
    <div className="billings_taxi_unbilled_private_order_listing">
      <HeadingComponent title={translations.header.headingText} />
      <StatusLegendComponent statusData={statusOptions} />
      <FormFieldComponent label={translations.taxiCorporationLabel} isRequired>
        <SingleSelectComponent
          placeholder={translations.taxiCorporationPlaceholder}
          value={selectedTaxiCorporationSelectOption ?? null}
          options={taxiCorporationSelectOptions}
          onChange={(selectedOption) =>
            onTaxiCorporationChange(selectedOption!)
          }
          classNames={{
            root: "billings_taxi_unbilled_private_order_listing_taxi_corporation_select",
          }}
          idForTesting={`taxi-corporation-select`}
          isLoading={isTaxiCorporationsFetching}
          isDisabled={isTaxiCorporationsFetchingError}
          isSearchable
        />
      </FormFieldComponent>
      {isListingContentVisible && (
        <>
          <div className="billings_taxi_unbilled_private_order_listing_tools">
            <div className="d-flex">
              <BillingsTaxiUnbilledPrivateOrderListingFiltersSelectComponent
                filters={filters}
                onAddNewFilter={addNewFilter}
              />
              <DateRangeInputComponent
                date={startDateFilterValue ?? null}
                onChange={onOrderStartDateFilterValueChange}
                classNames={{ root: "ml-2" }}
                placeholder={
                  translations.filters.searchByStartDateSelectInputPlaceholder
                }
                idForTesting="billings-taxi-unbilled-private-order-listing-date-range"
              />
            </div>

            <ListingSortSelectComponent
              onChange={(option) => setSelectedSortKey(option?.value!)}
              options={sortSelectOptions}
              value={selectedSortSelectOption}
              idForTesting="billings-taxi-unbilled-private-order-listing-sort"
            />
          </div>
          <BillingsTaxiUnbilledPrivateOrderListingFiltersBadgeListComponent
            filters={filters}
            onDeleteFilterClick={deleteFilter}
            onDeleteAllFiltersButtonClick={deleteAllFilters}
          />
          <CardComponent classNames={{ root: "mt-4" }}>
            <BillingsTaxiUnbilledPrivateOrderListingTableComponent
              listingItems={listingItems}
              isError={isListingFetchingError}
              isLoading={isListingFetching}
            />
            <div className="billings_taxi_unbilled_private_order_listing__pagination_wrapper">
              <PaginationComponent
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
                page={page}
                pageSize={pageSize}
                totalResults={totalResults}
              />
            </div>
          </CardComponent>
        </>
      )}
    </div>
  );
};

export default BillingsTaxiUnbilledPrivateOrderListingComponent;
