import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useAppContext } from "../../../../context/app.context";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import BillingsTaxiUnbilledOrderListingUserPermissionsHelper from "./common/user-permissions/billings-taxi-unbilled-order-listing-user-permission.helper";
import BillingsTaxiUnbilledOrderListingViewMode from "./common/types/billings-taxi-unbilled-order-listing-view-mode";
import ButtonComponent from "../../../../common/components/button/button.component";
import BillingsTaxiUnbilledOrderListingByRailyComponent from "./by-raily/billings-taxi-unbilled-order-listing-by-raily.component";
import BillingsTaxiUnbilledOrderListingByTaxiComponent from "./by-taxi/billings-taxi-unbilled-order-listing-by-taxi.component";
import billingsTranslationsHelper from "../../../../languages/billings-translations.helper";
import billingBreadcrumbsHelper from "../../common/breadcrumbs/billings-breadcrumbs.helper";

type BillingsTaxiUnbilledOrderListingProps = {};

const BillingsTaxiUnbilledOrderListingComponent: FC<
  BillingsTaxiUnbilledOrderListingProps
> = () => {
  const { user, selectedAppLanguage, setBreadcrumbs } = useAppContext();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.billingsTaxiUnbilledOrderListing);

  const userPermissions = useMemo(
    () =>
      BillingsTaxiUnbilledOrderListingUserPermissionsHelper.getPermissions(
        user?.roles!
      ),
    []
  );

  const translations = useMemo(
    () =>
      billingsTranslationsHelper.getTaxiUnbilledOrderBillingsListingTranslations(),
    [selectedAppLanguage]
  );

  useEffect(() => {
    const breadcrumbs =
      billingBreadcrumbsHelper.getTaxiUnbilledOrderListingBreadcrumbs();
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  const [selectedViewMode, setSelectedViewMode] =
    useState<BillingsTaxiUnbilledOrderListingViewMode | null>(null);

  const ChangeViewToRailyButton = useMemo(
    () => (
      <ButtonComponent
        type="brand"
        onClick={() =>
          setSelectedViewMode(BillingsTaxiUnbilledOrderListingViewMode.RAILY)
        }
        title={translations.header.changeViewToRailyButtonTitle}
      >
        {translations.header.changeViewToRailyButtonText}
      </ButtonComponent>
    ),
    [translations]
  );

  const ChangeViewToTaxiButton = useMemo(
    () => (
      <ButtonComponent
        type="brand"
        onClick={() =>
          setSelectedViewMode(BillingsTaxiUnbilledOrderListingViewMode.TAXI)
        }
        title={translations.header.changeViewToTaxiButtonTitle}
      >
        {translations.header.changeViewToTaxiButtonText}
      </ButtonComponent>
    ),
    [translations]
  );

  const viewChangeButtonOptions = useMemo(
    () => [
      {
        viewMode: BillingsTaxiUnbilledOrderListingViewMode.RAILY,
        buttonOptions: {
          button: ChangeViewToTaxiButton,
          hasPermission: userPermissions.hasAccessToTaxiView,
        },
      },
      {
        viewMode: BillingsTaxiUnbilledOrderListingViewMode.TAXI,
        buttonOptions: {
          button: ChangeViewToRailyButton,
          hasPermission: userPermissions.hasAccessToRailyView,
        },
      },
    ],
    [userPermissions, ChangeViewToTaxiButton, ChangeViewToRailyButton]
  );

  const getViewChangeButtons = useCallback(
    (viewMode: BillingsTaxiUnbilledOrderListingViewMode) => {
      const buttonOptionsForSelectedViewMode = viewChangeButtonOptions
        .filter((option) => option.viewMode === viewMode)
        .map((option) => option.buttonOptions);

      return buttonOptionsForSelectedViewMode
        .filter((option) => option.hasPermission)
        .map((option) => option.button);
    },
    [viewChangeButtonOptions]
  );

  const viewOptions = useMemo(
    () => [
      {
        mode: BillingsTaxiUnbilledOrderListingViewMode.RAILY,
        component: (
          <BillingsTaxiUnbilledOrderListingByRailyComponent
            actionButtons={getViewChangeButtons(
              BillingsTaxiUnbilledOrderListingViewMode.RAILY
            )}
          />
        ),
        hasPermission: userPermissions.hasAccessToRailyView,
      },
      {
        mode: BillingsTaxiUnbilledOrderListingViewMode.TAXI,
        component: (
          <BillingsTaxiUnbilledOrderListingByTaxiComponent
            actionButtons={getViewChangeButtons(
              BillingsTaxiUnbilledOrderListingViewMode.TAXI
            )}
          />
        ),
        hasPermission: userPermissions.hasAccessToTaxiView,
      },
    ],
    [getViewChangeButtons]
  );

  const getPossibleViewOptions = useCallback(() => {
    return viewOptions.filter((option) => option.hasPermission);
  }, []);

  const possibleViewOptions = useMemo(
    () => getPossibleViewOptions(),
    [getPossibleViewOptions]
  );

  useEffect(() => {
    if (possibleViewOptions.length === 0) {
      return;
    }
    setSelectedViewMode(possibleViewOptions[0].mode);
  }, [possibleViewOptions]);

  const SelectedViewComponent = useMemo(
    () =>
      viewOptions.find((option) => option.mode === selectedViewMode)?.component,
    [selectedViewMode, viewOptions]
  );

  if (!SelectedViewComponent) {
    return null;
  }

  return SelectedViewComponent;
};

export default BillingsTaxiUnbilledOrderListingComponent;
