import { FC, useMemo, useState } from "react";
import ListingFilterSelectComponent from "../../../../../../../common/components/listing/filter/select/listing-filter-select.component";
import { useAppContext } from "../../../../../../../context/app.context";
import BillingsTaxiRouteListingFilter from "../../types/billings-taxi-route-listing-filter";
import BillingsTaxiRouteListingFilterSelectOption from "../../types/billings-taxi-route-listing-filter-select-option";
import billingsTaxiRouteListingFilterHelper from "../../billings-taxi-route-listing-filter.helper";

type BillingsTaxiRouteListingFiltersSelectProps = {
  filters: BillingsTaxiRouteListingFilter[];
  onAddNewFilter: (filter: BillingsTaxiRouteListingFilter) => void;
};

const BillingsTaxiRouteListingFiltersSelectComponent: FC<
  BillingsTaxiRouteListingFiltersSelectProps
> = (props) => {
  const { selectedAppLanguage } = useAppContext();

  const [searchInputValue, setSearchInputValue] = useState("");

  const searchSelectOptions: BillingsTaxiRouteListingFilterSelectOption[] =
    useMemo(() => {
      return billingsTaxiRouteListingFilterHelper.getSelectOptions(
        searchInputValue,
        props.filters
      );
    }, [searchInputValue, props.filters, selectedAppLanguage]);

  const onFilterSelect = (
    filter: BillingsTaxiRouteListingFilterSelectOption
  ) => {
    props.onAddNewFilter(filter.value);
  };

  return (
    <ListingFilterSelectComponent
      onChange={(option) =>
        onFilterSelect(option as BillingsTaxiRouteListingFilterSelectOption)
      }
      options={searchSelectOptions}
      inputValue={searchInputValue}
      onInputChange={setSearchInputValue}
      idForTesting="billings-taxi-route-listing-filter"
    />
  );
};

export default BillingsTaxiRouteListingFiltersSelectComponent;
