export enum OrderAbandonedListReportRequestSortApiKey {
  HUMAN_ID_DESC = "HUMAN_ID_DESC",
  HUMAN_ID_ASC = "HUMAN_ID_ASC",
  MODIFIED_AT_ASC = "MODIFIED_AT_ASC",
  MODIFIED_AT_DESC = "MODIFIED_AT_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
}

export type CargoOrderAbandonedListReportQueryParams = {
  order: OrderAbandonedListReportRequestSortApiKey | null;
  creationStart?: string;
  creationEnd?: string;
  startTimeSince?: string;
  startTimeTo?: string;
  humanId?: number[];
  address?: string[];
  startAddress?: string[];
  midAddress?: string[];
  endAddress?: string[];
  passenger?: string[];
  dispatch?: string[];
  client?: string[];
  externalId?: string[];
  publicStatus?: string[];
};

type CargoOrderAbandonedListReportRequest = {
  order: OrderAbandonedListReportRequestSortApiKey | null;
  creationStart?: string;
  creationEnd?: string;
  startTimeSince?: string;
  startTimeTo?: string;
  humanId?: number[];
  address?: string[];
  startAddress?: string[];
  midAddress?: string[];
  endAddress?: string[];
  passenger?: string[];
  dispatch?: string[];
  client?: string[];
  externalId?: string[];
  publicStatus?: string[];
};

export default CargoOrderAbandonedListReportRequest;
