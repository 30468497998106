import dateService from "../../../common/utils/date/date.service";
import orderOptimizerTranslationsHelper from "../../../languages/order-optimizer-translations.helper";

const getHeadingText = (id: string) => {
  const translations =
    orderOptimizerTranslationsHelper.getDetailsTranslations();

  if (!id) {
    return "";
  }

  return translations.header.headingLabel.replace("#{id}", id);
};

const getDateLabel = (date: Date | null): string => {
  if (!date) {
    return "";
  }

  return dateService.formatDateTime(date);
};

const orderOptimizerDetailsHelper = {
  getHeadingText,
  getDateLabel,
};

export default orderOptimizerDetailsHelper;
