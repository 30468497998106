import { BillingsTaxiTransferredOrderListingResponseDataItem } from "../api/billings-taxi-transferred-order-listing.response";
import BillingsTaxiTransferredOrderListingItem from "../types/billings-taxi-transferred-order-listing-item";
import BillingsTaxiTransferredOrderListingItemBillingStatus from "../types/billings-taxi-transferred-order-listing-item-billing-status";

const createListingItemStatus = (
  responseDataItem: BillingsTaxiTransferredOrderListingResponseDataItem
): BillingsTaxiTransferredOrderListingItemBillingStatus | undefined => {
  switch (responseDataItem.status) {
    case "CREATED":
      return BillingsTaxiTransferredOrderListingItemBillingStatus.CREATED;
    case "ACCEPTED":
      return BillingsTaxiTransferredOrderListingItemBillingStatus.ACCEPTED;
    case "REJECTED":
      return BillingsTaxiTransferredOrderListingItemBillingStatus.REJECTED;
    case "REOPENED":
      return BillingsTaxiTransferredOrderListingItemBillingStatus.REOPENED;
    case "REOPEN_REQUEST":
      return BillingsTaxiTransferredOrderListingItemBillingStatus.REOPEN_REQUEST;
    default:
      return undefined;
  }
};

const createListingItem = (
  responseDataItem: BillingsTaxiTransferredOrderListingResponseDataItem
): BillingsTaxiTransferredOrderListingItem => {
  return {
    status: createListingItemStatus(responseDataItem),
    uuid: responseDataItem.id,
    internalOrderId: responseDataItem.cargo_order.human_id,
    orderStartDate: new Date(responseDataItem.start_time),
    orderTransferDate: new Date(responseDataItem.forwarding_time),
    producerTaxi: responseDataItem.producer.name,
    consumerTaxi: responseDataItem.consumer.name,
    billingModel: responseDataItem.billing_model,
    distance: responseDataItem.distance,
    amountForDistance: responseDataItem.amount_for_distance,
    baseDistanceRate: responseDataItem.base_distance_rate,
    stopoverCost: responseDataItem.amount_for_charge_haltings ?? 0,
    tollRoadsCost: responseDataItem.amount_for_charge_highways ?? 0,
    totalCost: responseDataItem.all_contributions_amount,
    sumOfBonuses: responseDataItem.sum_of_bonuses ?? 0,
    sumOfPenalties: responseDataItem.sum_of_penalties ?? 0,
    canBeModified: responseDataItem.can_be_modified,
    contractName: responseDataItem.contract_name,
  };
};

const createListingItems = (
  responseDataItems: BillingsTaxiTransferredOrderListingResponseDataItem[]
): BillingsTaxiTransferredOrderListingItem[] => {
  return responseDataItems.map(createListingItem);
};

const billingsTaxiTransferredOrderListingFactory = {
  createListingItems,
};

export default billingsTaxiTransferredOrderListingFactory;
