import { FC } from "react";
import TableComponent, {
  TableProps,
} from "../../../../../../common/components/table/table.component";
import BillingsTaxiRouteListingItem from "../types/billings-taxi-route-listing-item";
import BillingsTaxiRouteListingTableColumn from "../types/billings-taxi-route-listing-table-column";
import billingsTaxiRouteListingTableHelper from "./billings-taxi-route-listing-table.helper";
import BillingsTaxiRouteListingTableRow from "../types/billings-taxi-route-listing-table-row";

type BillingsTaxiRouteListingTableProps = Pick<
  TableProps,
  "isError" | "isLoading"
> & {
  listingItems: BillingsTaxiRouteListingItem[];
};

const BillingsTaxiRouteListingTableComponent: FC<
  BillingsTaxiRouteListingTableProps
> = (props) => {
  const columns: BillingsTaxiRouteListingTableColumn[] =
    billingsTaxiRouteListingTableHelper.getColumns();

  const rows: BillingsTaxiRouteListingTableRow[] =
    billingsTaxiRouteListingTableHelper.getRows(props.listingItems);

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isError={props.isError}
      isLoading={props.isLoading}
    />
  );
};

export default BillingsTaxiRouteListingTableComponent;
