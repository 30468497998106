import { FC, useMemo, useState } from "react";
import ListingFilterSelectComponent from "../../../../../../../common/components/listing/filter/select/listing-filter-select.component";
import { useAppContext } from "../../../../../../../context/app.context";
import billingsTaxiUnbilledOrderListingFilterHelper from "../../billings-taxi-unbilled-order-listing-filter.helper";
import BillingsTaxiUnbilledOrderListingFilter from "../../types/billings-taxi-unbilled-order-listing-filter";
import BillingsTaxiUnbilledOrderListingFilterSelectOption from "../../types/billings-taxi-unbilled-order-listing-filter-select-option";

type BillingsTaxiUnbilledOrderListingFiltersSelectProps = {
  filters: BillingsTaxiUnbilledOrderListingFilter[];
  onAddNewFilter: (filter: BillingsTaxiUnbilledOrderListingFilter) => void;
};

const BillingsTaxiUnbilledOrderListingFiltersSelectComponent: FC<
  BillingsTaxiUnbilledOrderListingFiltersSelectProps
> = (props) => {
  const { selectedAppLanguage } = useAppContext();

  const [searchInputValue, setSearchInputValue] = useState("");

  const searchSelectOptions: BillingsTaxiUnbilledOrderListingFilterSelectOption[] =
    useMemo(() => {
      return billingsTaxiUnbilledOrderListingFilterHelper.getSelectOptions(
        searchInputValue,
        props.filters
      );
    }, [searchInputValue, props.filters, selectedAppLanguage]);

  const onFilterSelect = (
    filter: BillingsTaxiUnbilledOrderListingFilterSelectOption
  ) => {
    props.onAddNewFilter(filter.value);
  };

  return (
    <ListingFilterSelectComponent
      onChange={(option) =>
        onFilterSelect(
          option as BillingsTaxiUnbilledOrderListingFilterSelectOption
        )
      }
      options={searchSelectOptions}
      inputValue={searchInputValue}
      onInputChange={setSearchInputValue}
      idForTesting="billings-taxi-unbilled-order-listing-filter"
    />
  );
};

export default BillingsTaxiUnbilledOrderListingFiltersSelectComponent;
