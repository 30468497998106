import billingsTaxiUnbilledRouteListingRouteQueryParamsService from "../../common/billings-taxi-unbilled-route-listing-route-query-params.service";
import BillingsTaxiUnbilledRouteListingFilter from "../../common/types/billings-taxi-unbilled-route-listing-filter";
import BillingsTaxiUnbilledRouteListingSortKey from "../../common/types/billings-taxi-unbilled-route-listing-sort-key";
import BillingsTaxiUnbilledRouteListingByRailyRouteQueryParams from "./types/billings-taxi-unbilled-route-listing-by-raily-route-query-params";
import BillingsTaxiUnbilledRouteListingByRailyTaxiCoporation from "./types/billings-taxi-unbilled-route-listing-by-raily-taxi-corporation-company";
import Joi from "joi";

const createRouteQueryParams = (
  taxiCorporationUuid:
    | BillingsTaxiUnbilledRouteListingByRailyTaxiCoporation["uuid"]
    | null,
  filters: BillingsTaxiUnbilledRouteListingFilter[],
  sortKey: BillingsTaxiUnbilledRouteListingSortKey | null,
  page: number,
  pageSize: number
): BillingsTaxiUnbilledRouteListingByRailyRouteQueryParams => {
  const commonParams =
    billingsTaxiUnbilledRouteListingRouteQueryParamsService.createRouteQueryParams(
      filters,
      sortKey,
      page,
      pageSize
    );

  return {
    ...commonParams,
    taxiCompanyUuid: taxiCorporationUuid ?? undefined,
  };
};

const getTaxiCorporationUuid = (
  routeQueryParams: BillingsTaxiUnbilledRouteListingByRailyRouteQueryParams
): string | undefined => {
  if (!validateTaxiCorporationUuid(routeQueryParams.taxiCompanyUuid)) {
    return undefined;
  }

  return routeQueryParams.taxiCompanyUuid;
};

const validateTaxiCorporationUuid = (
  routeQueryTaxiCorporationParam: BillingsTaxiUnbilledRouteListingByRailyRouteQueryParams["taxiCompanyUuid"]
) => {
  const validationSchema = Joi.string();

  return !validationSchema.validate(routeQueryTaxiCorporationParam).error
    ?.message;
};

const billingsTaxiUnbilledRouteListingByRailyRouteQueryParamsService = {
  getFilters:
    billingsTaxiUnbilledRouteListingRouteQueryParamsService.getFilters,
  getSortKey:
    billingsTaxiUnbilledRouteListingRouteQueryParamsService.getSortKey,
  getPage: billingsTaxiUnbilledRouteListingRouteQueryParamsService.getPage,
  getPageSize:
    billingsTaxiUnbilledRouteListingRouteQueryParamsService.getPageSize,
  getTaxiCorporationUuid,
  createRouteQueryParams,
};

export default billingsTaxiUnbilledRouteListingByRailyRouteQueryParamsService;
