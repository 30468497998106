import paginationService from "../../../../../../common/utils/pagination/pagination.service";
import BillingsTaxiUnbilledOrderListingByRailyRequest, {
  BillingsTaxiUnbilledOrderListingByRailyRequestOrder,
} from "./api/billings-taxi-unbilled-order-listing-by-raily.request";
import BillingsTaxiUnbilledOrderListingFilter, {
  BillingsTaxiUnbilledOrderListingClientFilter,
  BillingsTaxiUnbilledOrderListingDispatchFilter,
  BillingsTaxiUnbilledOrderListingDriverFilter,
  BillingsTaxiUnbilledOrderListingExternalOrderIdFilter,
  BillingsTaxiUnbilledOrderListingInternalOrderIdFilter,
  BillingsTaxiUnbilledOrderListingPassengerFilter,
  BillingsTaxiUnbilledOrderListingRouteAddressFilter,
  BillingsTaxiUnbilledOrderListingRouteDestinationAddressFilter,
  BillingsTaxiUnbilledOrderListingRouteIntermediateAddressFilter,
  BillingsTaxiUnbilledOrderListingRoutePickupAddressFilter,
  BillingsTaxiUnbilledOrderListingStartDateFilter,
  BillingsTaxiUnbilledOrderListingStatusFilter,
  BillingsTaxiUnbilledOrderListingTaxiFilter,
} from "../../common/types/billings-taxi-unbilled-order-listing-filter";
import BillingsTaxiUnbilledOrderListingFilterType from "../../common/types/billings-taxi-unbilled-order-listing-filter-type";
import BillingsTaxiUnbilledOrderListingItemStatus from "../../common/types/billings-taxi-unbilled-order-listing-item-status";
import BillingsTaxiUnbilledOrderListingSortKey from "../../common/types/billings-taxi-unbilled-order-listing-sort-key";

const createRequestIsCancelled = (
  filters: BillingsTaxiUnbilledOrderListingFilter[]
): BillingsTaxiUnbilledOrderListingByRailyRequest["is_cancelled"] => {
  const filterValue = filters.find(
    (filter) =>
      filter.type === BillingsTaxiUnbilledOrderListingFilterType.STATUS
  )?.value as BillingsTaxiUnbilledOrderListingStatusFilter["value"] | undefined;

  if (filterValue === undefined) {
    return undefined;
  }

  if (filterValue === BillingsTaxiUnbilledOrderListingItemStatus.CANCELLED) {
    return true;
  }

  return false;
};

const createRequestOrder = (
  sortKey: BillingsTaxiUnbilledOrderListingSortKey | null
): BillingsTaxiUnbilledOrderListingByRailyRequest["order"] => {
  const options: {
    sortKey: BillingsTaxiUnbilledOrderListingSortKey;
    requestOrder: BillingsTaxiUnbilledOrderListingByRailyRequestOrder;
  }[] = [
    {
      requestOrder:
        BillingsTaxiUnbilledOrderListingByRailyRequestOrder.HUMAN_ID_ASC,
      sortKey: BillingsTaxiUnbilledOrderListingSortKey.INTERNAL_ORDER_ID_ASC,
    },
    {
      requestOrder:
        BillingsTaxiUnbilledOrderListingByRailyRequestOrder.HUMAN_ID_DESC,
      sortKey: BillingsTaxiUnbilledOrderListingSortKey.INTERNAL_ORDER_ID_DESC,
    },
    {
      requestOrder:
        BillingsTaxiUnbilledOrderListingByRailyRequestOrder.START_TIME_ASC,
      sortKey: BillingsTaxiUnbilledOrderListingSortKey.START_DATE_ASC,
    },
    {
      requestOrder:
        BillingsTaxiUnbilledOrderListingByRailyRequestOrder.START_TIME_DESC,
      sortKey: BillingsTaxiUnbilledOrderListingSortKey.START_DATE_DESC,
    },
  ];

  return options.find((option) => option.sortKey === sortKey)?.requestOrder;
};

const createRequest = (
  page: number,
  pageSize: number,
  filters: BillingsTaxiUnbilledOrderListingFilter[],
  sortKey: BillingsTaxiUnbilledOrderListingSortKey | null
): BillingsTaxiUnbilledOrderListingByRailyRequest => {
  const offset = paginationService.calculateOffset(page, pageSize);

  return {
    order: createRequestOrder(sortKey),
    address: filters.find(
      (filter) =>
        filter.type === BillingsTaxiUnbilledOrderListingFilterType.ROUTE_ADDRESS
    )?.value as
      | BillingsTaxiUnbilledOrderListingRouteAddressFilter["value"]
      | undefined,
    limit: pageSize,
    client: filters.find(
      (filter) =>
        filter.type === BillingsTaxiUnbilledOrderListingFilterType.CLIENT
    )?.value as
      | BillingsTaxiUnbilledOrderListingClientFilter["value"]
      | undefined,
    dispatch: filters.find(
      (filter) =>
        filter.type === BillingsTaxiUnbilledOrderListingFilterType.DISPATCH
    )?.value as
      | BillingsTaxiUnbilledOrderListingDispatchFilter["value"]
      | undefined,
    driver: filters.find(
      (filter) =>
        filter.type === BillingsTaxiUnbilledOrderListingFilterType.DRIVER
    )?.value as
      | BillingsTaxiUnbilledOrderListingDriverFilter["value"]
      | undefined,
    end_address: filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiUnbilledOrderListingFilterType.ROUTE_DESTINATION_ADDRESS
    )?.value as
      | BillingsTaxiUnbilledOrderListingRouteDestinationAddressFilter["value"]
      | undefined,
    external_id: filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiUnbilledOrderListingFilterType.EXTERNAL_ORDER_ID
    )?.value as
      | BillingsTaxiUnbilledOrderListingExternalOrderIdFilter["value"]
      | undefined,
    human_id: filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiUnbilledOrderListingFilterType.INTERNAL_ORDER_ID
    )?.value as
      | BillingsTaxiUnbilledOrderListingInternalOrderIdFilter["value"]
      | undefined,
    is_cancelled: createRequestIsCancelled(filters),
    mid_address: filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiUnbilledOrderListingFilterType.ROUTE_INTERMEDIATE_ADDRESS
    )?.value as
      | BillingsTaxiUnbilledOrderListingRouteIntermediateAddressFilter["value"]
      | undefined,
    offset,
    passenger: filters.find(
      (filter) =>
        filter.type === BillingsTaxiUnbilledOrderListingFilterType.PASSENGER
    )?.value as
      | BillingsTaxiUnbilledOrderListingPassengerFilter["value"]
      | undefined,
    start_address: filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiUnbilledOrderListingFilterType.ROUTE_PICKUP_ADDRESS
    )?.value as
      | BillingsTaxiUnbilledOrderListingRoutePickupAddressFilter["value"]
      | undefined,
    start_time_since: (
      filters.find(
        (filter) =>
          filter.type === BillingsTaxiUnbilledOrderListingFilterType.START_DATE
      )?.value as
        | BillingsTaxiUnbilledOrderListingStartDateFilter["value"]
        | undefined
    )?.from?.toJSON(),
    start_time_to: (
      filters.find(
        (filter) =>
          filter.type === BillingsTaxiUnbilledOrderListingFilterType.START_DATE
      )?.value as
        | BillingsTaxiUnbilledOrderListingStartDateFilter["value"]
        | undefined
    )?.to?.toJSON(),
    taxi: filters.find(
      (filter) =>
        filter.type === BillingsTaxiUnbilledOrderListingFilterType.TAXI
    )?.value as BillingsTaxiUnbilledOrderListingTaxiFilter["value"] | undefined,
  };
};

const billingsTaxiUnbilledOrderListingByRailyRequestFactory = {
  createRequest,
};

export default billingsTaxiUnbilledOrderListingByRailyRequestFactory;
