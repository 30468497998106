import planEntryApiService from "../../api/raily/plan-entry/plan-entry-api.service";
import PlanEntryDetailsRequest from "../../api/raily/plan-entry/details/plan-entry-details.request";
import dataLoadErrorFactory from "../../utils/data-load-error/data-load-error.factory";
import HttpError from "../../utils/http/http.error";
import httpHelper from "../../utils/http/http.helper";
import PlanEntryDetails from "./details/plan-entry-details";
import PlanEntryDetailsError from "./details/plan-entry-details-error";
import PlanEntryDetailsLoadParams from "./details/plan-entry-details-load-params";
import PlanEntryDetailsRequestFactory from "./details/plan-entry-details-request.factory";
import PlanEntryDetailsFactory from "./details/plan-entry-details.factory";
import PlanEntryRoadRouteLoadParams from "./road-route/plan-entry-road-route-load-params";
import PlanEntryRoadRouteItem from "./road-route/plan-entry-road-route-item";
import SearchRoadRoutingRequest from "../../utils/search-road-route/search-road-routing.request";
import planEntryRoadRouteRequestFactory from "./road-route/plan-entry-road-route-request.factory";
import SearchRoadRoutingResponse from "../../utils/search-road-route/search-road-routing.response";
import planEntryRoadRouteFactory from "./road-route/plan-entry-road-route.factory";
import { DataLoadDefaultErrors } from "../../utils/data-load-error/data-load-error";

const handleDetailsError = (
  error: HttpError | PlanEntryDetailsError
): PlanEntryDetailsError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getDetails = async (
  params: PlanEntryDetailsLoadParams,
  abortSignal: AbortSignal
): Promise<PlanEntryDetails> => {
  const request: PlanEntryDetailsRequest =
    PlanEntryDetailsRequestFactory.create(params);

  try {
    const response = await planEntryApiService().getPlanEntryDetails(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    return PlanEntryDetailsFactory.create(response.data);
  } catch (_error) {
    const error = _error as HttpError | PlanEntryDetailsError;

    throw handleDetailsError(error);
  }
};

const handleRoadRouteError = (error: HttpError): DataLoadDefaultErrors => {
  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getRoute = async (
  params: PlanEntryRoadRouteLoadParams
): Promise<PlanEntryRoadRouteItem> => {
  const request: SearchRoadRoutingRequest =
    planEntryRoadRouteRequestFactory.create(params);

  try {
    const response: SearchRoadRoutingResponse =
      await planEntryApiService().getRoute(request);

    const RoadRouteItems: PlanEntryRoadRouteItem =
      planEntryRoadRouteFactory.create(response);

    return RoadRouteItems;
  } catch (_error) {
    const error = _error as HttpError;

    throw handleRoadRouteError(error);
  }
};

const planEntryService = { getDetails, getRoute };

export default planEntryService;
