import { FC } from "react";
import billingsTaxiOrderListingTableHelper from "./billings-taxi-order-listing-table.helper";
import TableComponent, {
  TableProps,
} from "../../../../../../common/components/table/table.component";
import { useAppContext } from "../../../../../../context/app.context";
import BillingsTaxiOrderListingItem from "../types/billings-taxi-order-listing-item";
import billingsTaxiOrderListingUserPermissionsHelper from "../user-permissions/billings-taxi-order-listing-user-permission.helper";
import BillingsTaxiOrderListingTableColumn from "../types/billings-taxi-order-listing-table-column";
import BillingsTaxiOrderListingTableRow from "../types/billings-taxi-order-listing-table-row";

type BillingsTaxiOrderListingTableProps = Pick<
  TableProps,
  "isError" | "isLoading"
> & {
  listingItems: BillingsTaxiOrderListingItem[];
};

const BillingsTaxiOrderListingTableComponent: FC<
  BillingsTaxiOrderListingTableProps
> = (props) => {
  const { user } = useAppContext();
  const userPermissions =
    billingsTaxiOrderListingUserPermissionsHelper.getPermissions(user?.roles!);

  const columns: BillingsTaxiOrderListingTableColumn[] =
    billingsTaxiOrderListingTableHelper.getColumns();

  const rows: BillingsTaxiOrderListingTableRow[] =
    billingsTaxiOrderListingTableHelper.getRows(
      props.listingItems,
      userPermissions
    );

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isError={props.isError}
      isLoading={props.isLoading}
    />
  );
};

export default BillingsTaxiOrderListingTableComponent;
