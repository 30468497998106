import { FC } from "react";
import billingsTaxiUnbilledRouteListingTableHelper from "./billings-taxi-unbilled-route-listing-table.helper";
import TableComponent, {
  TableProps,
} from "../../../../../../common/components/table/table.component";
import BillingsTaxiUnbilledRouteListingItem from "../types/billings-taxi-unbilled-route-listing-item";
import BillingsTaxiUnbilledRouteListingTableColumn from "../types/billings-taxi-unbilled-route-listing-table-column";
import BillingsTaxiUnbilledRouteListingTableRow from "../types/billings-taxi-unbilled-route-listing-table-row";

type BillingsTaxiUnbilledRouteListingTableProps = Pick<
  TableProps,
  "isError" | "isLoading"
> & {
  listingItems: BillingsTaxiUnbilledRouteListingItem[];
};

const BillingsTaxiUnbilledRouteListingTableComponent: FC<
  BillingsTaxiUnbilledRouteListingTableProps
> = (props) => {
  const columns: BillingsTaxiUnbilledRouteListingTableColumn[] =
    billingsTaxiUnbilledRouteListingTableHelper.getColumns();

  const rows: BillingsTaxiUnbilledRouteListingTableRow[] =
    billingsTaxiUnbilledRouteListingTableHelper.getRows(props.listingItems);

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isError={props.isError}
      isLoading={props.isLoading}
    />
  );
};

export default BillingsTaxiUnbilledRouteListingTableComponent;
