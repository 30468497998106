import billingsCargoOrderListingFactory from "../../common/billings-cargo-order-listing.factory";
import { BillingsCargoOrderListingByRailyCargoCompaniesResponseDataItem } from "./api/billings-cargo-order-listing-by-raily-cargo-companies.response";
import BillingsCargoOrderListingByRailyCargoCompanySelectOption from "./types/billings-cargo-order-listing-by-raily-cargo-company-select-option";

const createCargoCompanySelectOption = (
  responseCargoCompany: BillingsCargoOrderListingByRailyCargoCompaniesResponseDataItem
): BillingsCargoOrderListingByRailyCargoCompanySelectOption => {
  return {
    label: responseCargoCompany.display_name,
    value: {
      uuid: responseCargoCompany.id,
      displayName: responseCargoCompany.display_name,
    },
  };
};

const createCargoCompanySelectOptions = (
  responseCargoCompanies: BillingsCargoOrderListingByRailyCargoCompaniesResponseDataItem[]
): BillingsCargoOrderListingByRailyCargoCompanySelectOption[] => {
  return responseCargoCompanies.map(createCargoCompanySelectOption);
};

const billingsCargoOrderListingByRailyFactory = {
  createCargoCompanySelectOptions,
  createListingItems: billingsCargoOrderListingFactory.createListingItems,
};

export default billingsCargoOrderListingByRailyFactory;
