import billingsTaxiOrderListingRouteQueryParamsService from "../../common/billings-taxi-order-listing-route-query-params.service";
import BillingsTaxiOrderListingFilter from "../../common/types/billings-taxi-order-listing-filter";
import BillingsTaxiOrderListingSortKey from "../../common/types/billings-taxi-order-listing-sort-key";
import BillingsTaxiOrderListingByRailyRouteQueryParams from "./types/billings-taxi-order-listing-by-raily-route-query-params";
import BillingsTaxiOrderListingByRailyTaxiCorporation from "./types/billings-taxi-order-listing-by-raily-taxi-corporation";
import Joi from "joi";

const createRouteQueryParams = (
  taxiCorporationUuid:
    | BillingsTaxiOrderListingByRailyTaxiCorporation["uuid"]
    | null,
  filters: BillingsTaxiOrderListingFilter[],
  sortKey: BillingsTaxiOrderListingSortKey | null,
  page: number,
  pageSize: number
): BillingsTaxiOrderListingByRailyRouteQueryParams => {
  const commonParams =
    billingsTaxiOrderListingRouteQueryParamsService.createRouteQueryParams(
      filters,
      sortKey,
      page,
      pageSize
    );

  return {
    ...commonParams,
    taxiCorporation: taxiCorporationUuid ?? undefined,
  };
};

const getTaxiCorporationCompanyUuid = (
  routeQueryParams: BillingsTaxiOrderListingByRailyRouteQueryParams
): string | undefined => {
  if (!validateTaxiCorporationUuid(routeQueryParams.taxiCorporation)) {
    return undefined;
  }

  return routeQueryParams.taxiCorporation;
};

const validateTaxiCorporationUuid = (
  routeQueryTaxiCorporationParam: BillingsTaxiOrderListingByRailyRouteQueryParams["taxiCorporation"]
) => {
  const validationSchema = Joi.string();

  return !validationSchema.validate(routeQueryTaxiCorporationParam).error
    ?.message;
};

const billingsTaxiOrderListingByRailyRouteQueryParamsService = {
  getFilters: billingsTaxiOrderListingRouteQueryParamsService.getFilters,
  getSortKey: billingsTaxiOrderListingRouteQueryParamsService.getSortKey,
  getPage: billingsTaxiOrderListingRouteQueryParamsService.getPage,
  getPageSize: billingsTaxiOrderListingRouteQueryParamsService.getPageSize,
  getTaxiCorporationCompanyUuid,
  createRouteQueryParams,
};

export default billingsTaxiOrderListingByRailyRouteQueryParamsService;
