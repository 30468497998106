import billingsTaxiOrderListingRouteQueryParamsService from "../../common/billings-taxi-order-listing-route-query-params.service";

const billingsTaxiOrderListingByTaxiRouteQueryParamsService = {
  getFilters: billingsTaxiOrderListingRouteQueryParamsService.getFilters,
  getSortKey: billingsTaxiOrderListingRouteQueryParamsService.getSortKey,
  getPage: billingsTaxiOrderListingRouteQueryParamsService.getPage,
  getPageSize: billingsTaxiOrderListingRouteQueryParamsService.getPageSize,
  createRouteQueryParams:
    billingsTaxiOrderListingRouteQueryParamsService.createRouteQueryParams,
};

export default billingsTaxiOrderListingByTaxiRouteQueryParamsService;
