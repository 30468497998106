import { FC, useEffect, useMemo, useState } from "react";
import useIsComponentMounted from "../../../../../common/hooks/use-is-component-mounted";
import useRouteQueryParams from "../../../../../common/hooks/use-route-query-params";
import DateRangeInputComponent from "../../../../../common/components/form/input/date-range/date-range-input.component";
import SingleSelectComponent from "../../../../../common/components/form/select/single-select/single-select.component";
import BillingsCargoOrderListingViewBasicProps from "../common/types/billings-cargo-order-listing-view-basic-props";
import { useAppContext } from "../../../../../context/app.context";
import BillingsCargoOrderListingByRailyRouteQueryParams from "./common/types/billings-cargo-order-listing-by-raily-route-query-params";
import billingsTranslationsHelper from "../../../../../languages/billings-translations.helper";
import BillingsCargoOrderListingFilter, {
  BillingsCargoOrderListingOrderStartDateFilter,
} from "../common/types/billings-cargo-order-listing-filter";
import billingsCargoOrderListingByRailyRouteQueryParamsService from "./common/billings-cargo-order-listing-by-raily-route-query-params.service";
import BillingsCargoOrderListingItem from "../common/types/billings-cargo-order-listing-item";
import BillingsCargoOrderListingSortKey from "../common/types/billings-cargo-order-listing-sort-key";
import BillingsCargoOrderListingByRailyCargoCompanySelectOption from "./common/types/billings-cargo-order-listing-by-raily-cargo-company-select-option";
import BillingsCargoOrderListingSortSelectOption from "../common/types/billings-cargo-order-listing-sort-select-option";
import billingsCargoOrderListingSortHelper from "../common/billings-cargo-order-listing-sort.helper";
import usePagination from "../../../../../common/hooks/use-pagination";
import BillingsCargoOrderListingByRailyCargoCompaniesResponse, {
  BillingsCargoOrderListingByRailyCargoCompaniesResponseDataItem,
} from "./common/api/billings-cargo-order-listing-by-raily-cargo-companies.response";
import billingsCargoOrderListingByRailyFactory from "./common/billings-cargo-order-listing-by-raily.factory";
import billingsCargoOrderListingByRailyApiService from "./common/api/billings-cargo-order-listing-by-raily-api.service";
import BillingsCargoOrderListingResponse from "../common/api/billings-cargo-order-listing.response";
import billingsCargoOrderListingRequestFactory from "../common/billings-cargo-order-listing-request.factory";
import BillingsCargoOrderListingFilterType from "../common/types/billings-cargo-order-listing-filter-type";
import DateRange from "../../../../../common/types/date-range";
import BillingsCargoOrderListingReportResponse, {
  BillingsCargoOrderListingReportResponseData,
} from "../common/api/billings-cargo-order-listing-report.response";
import fileDownloadService from "../../../../../common/utils/file-download/file-download.service";
import notificationService from "../../../../../common/utils/notification/notification.service";
import billingsCargoOrderListingReportRequestFactory from "../common/billings-cargo-order-listing-report-request.factory";
import ButtonComponent from "../../../../../common/components/button/button.component";
import HeadingComponent from "../../../../../common/components/heading/heading.component";
import BillingsCargoOrderListingStatusLegendComponent from "../common/status-legend/billings-cargo-order-listing-status-legend.component";
import FormFieldComponent from "../../../../../common/components/form/field/form-field.component";
import BillingsCargoOrderListingFiltersSelectComponent from "../common/filters/select/billings-cargo-order-listing-filters-select.component";
import ListingSortSelectComponent from "../../../../../common/components/listing/filter/sort/select/listing-sort-select.component";
import BillingsCargoOrderListingFiltersBadgeListComponent from "../common/filters/list/billings-cargo-order-listing-filters-badge-list.component";
import CardComponent from "../../../../../common/components/card/card.component";
import BillingsCargoOrderListingTableComponent from "../common/table/billings-cargo-order-listing-table.component";
import PaginationComponent from "../../../../../common/components/pagination/pagination.component";
import BillingsCargoOrderListingBillingsAcceptData from "../common/types/billings-cargo-order-listing-billings-accept-data";
import BillingsCargoOrderListingAcceptBillingsRequest from "../common/api/billings-cargo-order-listing-accept-billings.request";
import BillingsCargoOrderListingBillingsAcceptComponent from "../common/billings-accept/billings-cargo-order-listing-billings-accept.component";
import BillingsCargoOrderListingAcceptBillingsResponse from "../common/api/billings-cargo-order-listing-accept-billings.response";

type BillingsCargoOrderListingByRailyProps =
  BillingsCargoOrderListingViewBasicProps;

const BillingsCargoOrderListingByRailyComponent: FC<
  BillingsCargoOrderListingByRailyProps
> = (props) => {
  const isComponentMounted = useIsComponentMounted();
  const { selectedAppLanguage } = useAppContext();

  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<BillingsCargoOrderListingByRailyRouteQueryParams>();

  const translations =
    billingsTranslationsHelper.getCargoOrderBillingsListingTranslations();

  const [filters, setFilters] = useState<BillingsCargoOrderListingFilter[]>(
    () =>
      billingsCargoOrderListingByRailyRouteQueryParamsService.getFilters(
        routeQueryParams
      )
  );

  const [isListingFetching, setIsListingFetching] = useState(false);
  const [isListingFetchingError, setIsListingFetchingError] = useState(false);

  const [listingItems, setListingItems] = useState<
    BillingsCargoOrderListingItem[]
  >([]);
  const [totalResults, setTotalResults] = useState(0);

  const [isReportFetching, setIsReportFetching] = useState(false);

  const [selectedSortKey, setSelectedSortKey] =
    useState<BillingsCargoOrderListingSortKey | null>(
      () =>
        billingsCargoOrderListingByRailyRouteQueryParamsService.getSortKey(
          routeQueryParams
        ) ?? null
    );

  const [selectedCargoCompanyUuid, setSelectedCargoCompanyUuid] = useState<
    string | null
  >(
    () =>
      billingsCargoOrderListingByRailyRouteQueryParamsService.getCargoCompanyUuid(
        routeQueryParams
      ) ?? null
  );

  const onCargoCompanyChange = (
    selectedOption: BillingsCargoOrderListingByRailyCargoCompanySelectOption
  ) => {
    setSelectedCargoCompanyUuid(selectedOption.value.uuid);
    setPage(1);
  };

  const [isCargoCompanyFetching, setIsCargoCompanyFetching] = useState(false);
  const [isCargoCompanyFetchingError, setIsCargoCompanyFetchingError] =
    useState(false);

  const [cargoCompanySelectOptions, setCargoCompanySelectOptions] = useState<
    BillingsCargoOrderListingByRailyCargoCompanySelectOption[]
  >([]);

  const [isAcceptBillingsEnabled, setIsAcceptBillingsEnabled] = useState(false);

  const [selectedBillingsAcceptData, setSelectedBillingsAcceptData] = useState<
    BillingsCargoOrderListingBillingsAcceptData[]
  >([]);

  const abortController = new AbortController();

  const enableAcceptingBillings = () => setIsAcceptBillingsEnabled(true);

  const disableAcceptingBillings = () => setIsAcceptBillingsEnabled(false);

  const selectedCargoCompanySelectOption = cargoCompanySelectOptions.find(
    (option) => option.value.uuid === selectedCargoCompanyUuid
  );

  const sortSelectOptions: BillingsCargoOrderListingSortSelectOption[] =
    useMemo(
      () => billingsCargoOrderListingSortHelper.getSelectOptions(),
      [selectedAppLanguage]
    );

  const { page, pageSize, setPage, setPageSize } = usePagination({
    totalResults: totalResults,
    defaultPageSize:
      billingsCargoOrderListingByRailyRouteQueryParamsService.getPageSize(
        routeQueryParams
      ),
    defaultPage:
      billingsCargoOrderListingByRailyRouteQueryParamsService.getPage(
        routeQueryParams
      ),
  });

  const onCargoCompanyFetchSuccess = (
    responseDataItems: BillingsCargoOrderListingByRailyCargoCompaniesResponseDataItem[]
  ) => {
    const cargoCompaniesSelectOptions =
      billingsCargoOrderListingByRailyFactory.createCargoCompanySelectOptions(
        responseDataItems
      );

    setCargoCompanySelectOptions(cargoCompaniesSelectOptions);
  };

  const onCargoCompanyFetchFailure = () => {
    setIsCargoCompanyFetchingError(true);
  };

  const handleCargoCompaniesResponse = (
    response: BillingsCargoOrderListingByRailyCargoCompaniesResponse
  ) => {
    if (response.status === 200) {
      onCargoCompanyFetchSuccess(response.data);
      return;
    }

    onCargoCompanyFetchFailure();
  };

  useEffect(() => {
    setIsCargoCompanyFetching(true);
    setIsCargoCompanyFetchingError(false);

    billingsCargoOrderListingByRailyApiService
      .fetchCargoCompanies()
      .then(handleCargoCompaniesResponse)
      .catch(onListingFetchFailure)
      .finally(() => setIsCargoCompanyFetching(false));
  }, []);

  const onListingFetchSuccess = (
    response: BillingsCargoOrderListingResponse
  ) => {
    const listingItems =
      billingsCargoOrderListingByRailyFactory.createListingItems(
        response.data.data
      );

    setListingItems(listingItems);
    setTotalResults(response.data.total_count);
  };

  const onListingFetchFailure = () => {
    setIsListingFetchingError(true);
  };

  const handleListingResponse = (
    response: BillingsCargoOrderListingResponse
  ) => {
    if (response.status === 200) {
      onListingFetchSuccess(response);
      return;
    }

    onListingFetchFailure();
  };

  const fetchListing = () => {
    setIsListingFetching(true);
    setIsListingFetchingError(false);

    const request = billingsCargoOrderListingRequestFactory.createRequest(
      page,
      pageSize,
      filters,
      selectedSortKey
    );

    billingsCargoOrderListingByRailyApiService
      .fetchListing(selectedCargoCompanyUuid!, request, abortController.signal)
      .then((data) => {
        if (!abortController.signal.aborted) {
          handleListingResponse(data);
        }
      })
      .catch(() => {
        if (!abortController.signal.aborted) {
          onListingFetchFailure();
        }
      })
      .finally(() => setIsListingFetching(false));
  };

  useEffect(() => {
    if (!page || !pageSize || !selectedCargoCompanyUuid) {
      return;
    }

    fetchListing();
    return () => {
      abortController.abort();
    };
  }, [filters, selectedCargoCompanyUuid, selectedSortKey, page, pageSize]);

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    const queryParams =
      billingsCargoOrderListingByRailyRouteQueryParamsService.createRouteQueryParams(
        selectedCargoCompanyUuid,
        filters,
        selectedSortKey,
        page,
        pageSize
      );

    setRouteQueryParams(queryParams);
  }, [selectedCargoCompanyUuid, filters, selectedSortKey, page, pageSize]);

  const addNewFilter = (newFilter: BillingsCargoOrderListingFilter) => {
    setFilters((curr) => [...curr, newFilter]);
    setPage(1);
  };

  const deleteFilter = (index: number) => {
    const newFilters = filters.filter((filter, _index) => _index !== index);

    setFilters(newFilters);
    setPage(1);
  };

  const deleteAllFilters = () => {
    setFilters([]);
    setPage(1);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    window.scroll({ top: 0, behavior: "smooth" });
  };

  const onPageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
    setPage(1);
  };

  const selectedSortSelectOption = useMemo(() => {
    return (
      sortSelectOptions.find((item) => item.value === selectedSortKey) ?? null
    );
  }, [selectedSortKey, selectedAppLanguage]);

  const startDateFilterValue = filters.find(
    (filter) =>
      filter.type === BillingsCargoOrderListingFilterType.ORDER_START_DATE
  )?.value as
    | BillingsCargoOrderListingOrderStartDateFilter["value"]
    | undefined;

  const onOrderStartDateFilterValueChange = (dateRange: DateRange | null) => {
    if (!dateRange) {
      const newFilters = filters.filter(
        (filter) =>
          filter.type !== BillingsCargoOrderListingFilterType.ORDER_START_DATE
      );

      setFilters(newFilters);
      return;
    }

    const isFilterExists = !!filters.find(
      (filter) =>
        filter.type === BillingsCargoOrderListingFilterType.ORDER_START_DATE
    );

    const newFilter: BillingsCargoOrderListingOrderStartDateFilter = {
      type: BillingsCargoOrderListingFilterType.ORDER_START_DATE,
      value: {
        from: dateRange.from!,
        to: dateRange.to!,
      },
    };

    if (isFilterExists) {
      const newFilters = [
        ...filters.filter(
          (filter) =>
            filter.type !== BillingsCargoOrderListingFilterType.ORDER_START_DATE
        ),
        newFilter,
      ];

      setFilters(newFilters);
      setPage(1);
      return;
    }

    setFilters((curr) => [...curr, newFilter]);
    setPage(1);
  };

  const downloadReportFile = (
    responseData: BillingsCargoOrderListingReportResponseData
  ) => {
    fileDownloadService.downloadFromBlob(
      responseData.data,
      responseData.filename
    );
  };

  const onReportFetchSuccess = async (
    response: BillingsCargoOrderListingReportResponse
  ) => {
    if (response.status === 200) {
      downloadReportFile(response.data);
      return;
    }

    onReportFetchFailure();
  };

  const onReportFetchFailure = () => {
    notificationService.error(
      translations.report.failureDownloadingNotificationText
    );
  };

  const fetchReport = () => {
    setIsReportFetching(true);

    const request = billingsCargoOrderListingReportRequestFactory.createRequest(
      filters,
      selectedSortKey
    );

    billingsCargoOrderListingByRailyApiService
      .fetchReport(selectedCargoCompanyUuid!, request)
      .then(onReportFetchSuccess)
      .catch(onReportFetchFailure)
      .finally(() => setIsReportFetching(false));
  };

  const onReportDownloadButtonClick = () => {
    fetchReport();
  };

  const ReportDownloadButton = (
    <ButtonComponent
      onClick={onReportDownloadButtonClick}
      type="primary"
      isLoading={isReportFetching}
      title={translations.report.downloadButtonTitle}
      idForTesting="billings-cargo-order-listing-by-raily-report-download-button"
    >
      {translations.report.downloadButtonText}
    </ButtonComponent>
  );

  const onAcceptBillingsButtonClick = () =>
    isAcceptBillingsEnabled
      ? disableAcceptingBillings()
      : enableAcceptingBillings();

  const AcceptBillingsButton = (
    <ButtonComponent
      onClick={onAcceptBillingsButtonClick}
      type={isAcceptBillingsEnabled ? "success" : "primary"}
      title={
        isAcceptBillingsEnabled
          ? translations.header.acceptBillingsDisableTitle
          : translations.header.acceptBillingsEnableTitle
      }
      idForTesting="billings-cargo-order-listing-by-raily-accept-billings-button"
    >
      {translations.header.acceptBillingsLabel}
    </ButtonComponent>
  );

  const onAcceptBillingsSuccess = () => {
    fetchListing();

    notificationService.success(
      translations.billingsAccept.successNotificationLabel
    );
  };

  const onAcceptBillingsFailure = () => {
    notificationService.error(
      translations.billingsAccept.failureNotificationLabel
    );
  };

  const handleAcceptBillingsResponse = (
    response: BillingsCargoOrderListingAcceptBillingsResponse
  ) => {
    if (response.status === 200) {
      return onAcceptBillingsSuccess();
    }

    return onAcceptBillingsFailure();
  };

  const acceptBillings = () => {
    const request: BillingsCargoOrderListingAcceptBillingsRequest = {
      billing_ids: selectedBillingsAcceptData.map((item) => item.uuid),
    };

    billingsCargoOrderListingByRailyApiService
      .acceptBillings(request)
      .then(handleAcceptBillingsResponse)
      .finally(() => {
        setSelectedBillingsAcceptData([]);
        setIsAcceptBillingsEnabled(false);
      });
  };

  const isReportDownloadButtonVisible = !!selectedCargoCompanyUuid;
  const isAcceptBillingsButtonVisible = !!selectedCargoCompanyUuid;
  const isListingContentVisible = !!selectedCargoCompanyUuid;

  return (
    <div className="billings_cargo_order_listing">
      <HeadingComponent
        title={translations.header.headingText}
        actions={[
          ...props.actionButtons,
          isReportDownloadButtonVisible && ReportDownloadButton,
          isAcceptBillingsButtonVisible && AcceptBillingsButton,
        ]}
      />
      <BillingsCargoOrderListingStatusLegendComponent
        cargoCompanyUuid={selectedCargoCompanyUuid}
      />
      <FormFieldComponent label={translations.cargoCompanyLabel} isRequired>
        <SingleSelectComponent
          placeholder={translations.cargoCompanyPlaceholder}
          value={selectedCargoCompanySelectOption ?? null}
          options={cargoCompanySelectOptions}
          onChange={(selectedOption) => onCargoCompanyChange(selectedOption!)}
          classNames={{
            root: "billings_cargo_order_listing_cargo_company_select",
          }}
          idForTesting={`cargo-company-select`}
          isLoading={isCargoCompanyFetching}
          isDisabled={isCargoCompanyFetchingError}
          isSearchable
        />
      </FormFieldComponent>
      {isListingContentVisible && (
        <>
          <div className="billings_cargo_order_listing_tools">
            <div className="d-flex">
              <BillingsCargoOrderListingFiltersSelectComponent
                filters={filters}
                onAddNewFilter={addNewFilter}
              />
              <DateRangeInputComponent
                date={startDateFilterValue ?? null}
                onChange={onOrderStartDateFilterValueChange}
                classNames={{ root: "ml-2" }}
                placeholder={
                  translations.filters.searchByStartDateSelectInputPlaceholder
                }
                idForTesting="billings-cargo-order-listing-by-raily-date-range"
              />
            </div>
            <ListingSortSelectComponent
              onChange={(option) => setSelectedSortKey(option?.value!)}
              options={sortSelectOptions}
              value={selectedSortSelectOption}
              idForTesting="billings-cargo-order-listing-by-raily-sort"
            />
          </div>
          <BillingsCargoOrderListingFiltersBadgeListComponent
            filters={filters}
            onDeleteFilterClick={deleteFilter}
            onDeleteAllFiltersButtonClick={deleteAllFilters}
          />
          <CardComponent classNames={{ root: "mt-4" }}>
            <BillingsCargoOrderListingTableComponent
              listingItems={listingItems}
              isError={isListingFetchingError}
              isLoading={isListingFetching}
              isAcceptBillingsEnabled={isAcceptBillingsEnabled}
              selectedBillingsAcceptData={selectedBillingsAcceptData}
              onSelectedBillingsAcceptDataChange={setSelectedBillingsAcceptData}
            />
            <div className="billings_cargo_order_listing__pagination_wrapper">
              <PaginationComponent
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
                page={page}
                pageSize={pageSize}
                totalResults={totalResults}
              />
            </div>
          </CardComponent>
          {isAcceptBillingsEnabled && (
            <BillingsCargoOrderListingBillingsAcceptComponent
              billingsAcceptData={selectedBillingsAcceptData}
              onSubmit={acceptBillings}
              onCancel={() => {
                setSelectedBillingsAcceptData([]);
                setIsAcceptBillingsEnabled(false);
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default BillingsCargoOrderListingByRailyComponent;
