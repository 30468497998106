import { FC, useEffect, useMemo, useState } from "react";
import CardComponent from "../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../common/components/form/field/form-field.component";
import InputComponent from "../../../../common/components/form/input/input.component";
import Column from "../../../../common/components/grid/column";
import Row from "../../../../common/components/grid/row";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import formValidationService from "../../../../common/utils/validation/form-validation.service";
import { useAppContext } from "../../../../context/app.context";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import orderTranslationsHelper from "../../../../languages/order-translations.helper";
import orderBreadcrumbsHelper from "../../common/breadcrumbs/order-breadcrumbs.helper";
import OrderAddPassengersComponent from "../common/components/passengers/order-add-passengers.component";
import OrderAddRouteValidationModalComponent from "../common/components/route-validation-modal/order-add-route-validation-modal.component";
import OrderAddRoutesComponent from "../common/components/routes/order-add-routes.component";
import OrderAddSummaryComponent from "../common/components/summary/order-add-summary.component";
import orderAddFactory from "../common/factory/order-add.factory";
import OrderAddBasicProps from "../common/types/order-add-basic-props";
import OrderAddFormValidationResults from "../common/types/order-add-form-validation-results";
import OrderAddPassenger, { OrderAddPassengerAddress } from "../common/types/order-add-passenger";
import OrderAddPassengerListItem from "../common/types/order-add-passenger-list-item";
import OrderAddRouteItem, { OrderAddRouteWaypoint } from "../common/types/order-add-route-waypoint";
import OrderAddTaxiCorporationSelectOption from "../common/types/order-add-taxi-corporation-select-option";
import orderAddValidationService from "../common/validation/order-add-validation.service";
import SingleSelectComponent from "../../../../common/components/form/select/single-select/single-select.component";
import contractService from "../../../../common/services/contract/contract.service";
import useAbort from "../../../../common/hooks/use-abort";
import ContractValidCollectionResponse, {
  ContractValidCollectionResponseData,
} from "../../../../common/api/raily/contracts/valid-collection/contract-valid-collection.response";
import uuidService from "../../../../common/utils/uuid/uuid.service";

type CargoOrderAddProps = OrderAddBasicProps;

const CargoOrderAddComponent: FC<CargoOrderAddProps> = (props) => {
  const { user } = useAppContext();
  const [
    selectedTaxiCorporationSelectOption,
    setSelectedTaxiCorporationSelectOption,
  ] = useState<OrderAddTaxiCorporationSelectOption | null>(null);
  const [passengerList, setPassengerList] = useState<
    OrderAddPassengerListItem[]
  >([]);
  const [selectedPassengerUuid, setSelectedPassengerUuid] = useState<
    OrderAddPassenger["uuid"] | null
  >(null);
  const [routes, setRoutes] = useState<OrderAddRouteItem[]>([]);
  const [orderNumber, setOrderNumber] = useState("");
  const [formValidationResults, setFormValidationResults] =
    useState<OrderAddFormValidationResults>({
      contractor: formValidationService.defaultValidationResult,
      dispatcher: formValidationService.defaultValidationResult,
      taxiCorporation: formValidationService.defaultValidationResult,
      orderNumber: formValidationService.defaultValidationResult,
    });
  const [routesValidationErrors, setRoutesValidationErrors] = useState<
    string[]
  >([]);

  const [isReturnRideActive, setIsReturnRideActive] = useState<string[]>([]);

  const [returnRideRouteItemUuids, setReturnRideRouteItemUuids] = useState<
  {onboarding: string | null, outboarding: string | null, passengerUuid: string}[]
>([]);

  const contractAbort = useAbort();

  const translations = orderTranslationsHelper.getAddTranslations();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.orderAdd);

  const { setBreadcrumbs, selectedAppLanguage } = useAppContext();

  const [areTaxiContractsFetching, setAreTaxiContractsFetching] =
    useState(false);

  const [isTaxiContractsFetchingError, setIsTaxiContractsFetchingError] =
    useState(false);

  const [taxiCorporationsSelectOptions, setTaxiCorporationsSelectOptions] =
    useState<OrderAddTaxiCorporationSelectOption[]>([]);

  const [isRouteErrorsModalOpen, setIsRouteErrorsModalOpen] = useState(false);

  useEffect(() => {
    const breadcrumbs = orderBreadcrumbsHelper.getAddBreadcrumbs();
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  // temporary solution - remove after refactor
  useEffect(() => {
    const revalidationFields = Object.keys(formValidationResults).filter(
      (formKey) =>
        formValidationResults[formKey as keyof OrderAddFormValidationResults]
          .isValid === false
    );

    revalidationFields.forEach((field) => {
      switch (field) {
        case "taxiCorporation":
          return validateTaxiCorporation(selectedTaxiCorporationSelectOption);
        case "orderNumber":
          return validateOrderNumber(orderNumber);
      }
    });

    if (routesValidationErrors.length) {
      validateRoutes();
    }
  }, [selectedAppLanguage]);

  const onTaxiContractsFetchSuccess = (
    responseTaxiContractsData: ContractValidCollectionResponseData
  ) => {
    const taxiCorporationsSelectOptions =
      orderAddFactory.createTaxiCorporationsSelectOptions(
        responseTaxiContractsData
      );
    setTaxiCorporationsSelectOptions(taxiCorporationsSelectOptions);
  };

  const handleTaxiContractsResponse = (
    response: ContractValidCollectionResponse
  ) => {
    if (response.status === 200) {
      onTaxiContractsFetchSuccess(response.data);
      return;
    }

    setIsTaxiContractsFetchingError(true);
  };

  useEffect(() => {
    const cargoCompanyUuid = user!.aspects.dispatcher?.cargoCompanyUuid;

    if (!cargoCompanyUuid) return;

    setAreTaxiContractsFetching(true);

    contractService
      .getValidCollection(
        { cargoCompanyUuid: cargoCompanyUuid },
        contractAbort.signal
      )
      .then(handleTaxiContractsResponse)
      .finally(() => {
        setAreTaxiContractsFetching(false);
      });
  }, []);

  const validateTaxiCorporation = (
    taxiCorporation: OrderAddTaxiCorporationSelectOption | null
  ) => {
    const validationResult =
      orderAddValidationService.validateTaxiCorporation(taxiCorporation);

    setFormValidationResults((curr) => ({
      ...curr,
      taxiCorporation: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateOrderNumber = (orderNumber: string) => {
    const validationResult =
      orderAddValidationService.validateOrderNumber(orderNumber);

    setFormValidationResults((curr) => ({
      ...curr,
      orderNumber: validationResult,
    }));

    return validationResult.isValid;
  };

  const onTaxiCorporationSelectValueChange = (
    selectOption: OrderAddTaxiCorporationSelectOption
  ) => {
    setSelectedTaxiCorporationSelectOption(selectOption);
    validateTaxiCorporation(selectOption);
  };

  useEffect(() => {
    if (!routesValidationErrors.length) {
      return;
    }

    setIsRouteErrorsModalOpen(true);
  }, [routesValidationErrors]);

  const isTaxiSelectDisabled = isTaxiContractsFetchingError;

  const restoreForm = () => {
    setSelectedTaxiCorporationSelectOption(null);
    setPassengerList([]);
    setRoutes([]);
    setOrderNumber("");
    setFormValidationResults({
      contractor: formValidationService.defaultValidationResult,
      dispatcher: formValidationService.defaultValidationResult,
      taxiCorporation: formValidationService.defaultValidationResult,
      orderNumber: formValidationService.defaultValidationResult,
    });
    setRoutesValidationErrors([]);
  };

  const validateRoutes = () => {
    const validationErrors = orderAddValidationService.validateRoutes(
      passengerList,
      routes
    );

    const resolvedErrors =
      orderAddValidationService.resolveValidationErrorLabels(validationErrors);

    setRoutesValidationErrors(resolvedErrors);

    return !validationErrors.length;
  };

  const validateForm = () => {
    return (
      validateOrderNumber(orderNumber) &&
      validateTaxiCorporation(selectedTaxiCorporationSelectOption) &&
      validateRoutes()
    );
  };

  const addReturnRide = (passenger: OrderAddPassenger, address: OrderAddPassengerAddress) => {
    if (isReturnRideActive.find((passengerUuid) => passengerUuid === passenger.uuid)) return;

    const filteredWaypoints = routes.filter(
      (route): route is OrderAddRouteWaypoint =>
        "onboardingPassengerListItems" in route && route.address !== null
    );

    const lastDropOffWaypoint = [...filteredWaypoints].reverse().find(
        (route) =>
          route.outboardingPassengerListItems.some(
            (outboardingPassenger) => outboardingPassenger.passenger.uuid === passenger.uuid
          )
    );

    if (!lastDropOffWaypoint || !lastDropOffWaypoint.address) {
        return;
    }

    const passengerItemUuid = lastDropOffWaypoint.outboardingPassengerListItems.find(
        (outboardingPassenger) => outboardingPassenger.passenger.uuid === passenger.uuid
    )?.uuid;

    if (!passengerItemUuid) {
        return;
    }

    const commonOutboardingWaypoint = [...filteredWaypoints].reverse().find((route) =>
        route.outboardingPassengerListItems.some(
            (outboardingPassenger) =>
              outboardingPassenger.passenger.uuid === passenger.uuid
        )
    );

    let existingOutboardingRoute: OrderAddRouteWaypoint | undefined;

    for(let returnWayppintData of returnRideRouteItemUuids){
      existingOutboardingRoute = filteredWaypoints.find((route) => route.uuid === returnWayppintData.onboarding)!
      if(commonOutboardingWaypoint?.address === existingOutboardingRoute?.address){
        break;
      }
    }

    let returnPickupWaypoint: OrderAddRouteWaypoint = {
      uuid: uuidService.generate(),
      address: commonOutboardingWaypoint?.address || lastDropOffWaypoint.address,
      date: null,
      haltingTimeMinutes: null,
      onboardingPassengerListItems: [{ uuid: passengerItemUuid, passenger }],
      outboardingPassengerListItems: [],
    };

    if(existingOutboardingRoute){
      returnPickupWaypoint = {...existingOutboardingRoute, onboardingPassengerListItems: [...existingOutboardingRoute.onboardingPassengerListItems, { uuid: passengerItemUuid, passenger }]}
    }

    const returnDropOffWaypoint: OrderAddRouteWaypoint = {
        uuid: uuidService.generate(),
        address: {
            uuid: address.uuid,
            displayName: address.displayName,
            latitude: address.latitude,
            longitude: address.longitude,
            zipCode: address.zipCode,
            town: address.town,
            street: address.street,
            houseNumber: address.houseNumber,
            apartmentNumber: address.apartmentNumber,
        },
        date: null,
        haltingTimeMinutes: null,
        onboardingPassengerListItems: [],
        outboardingPassengerListItems: [{ uuid: passengerItemUuid, passenger }],
    };

    const newIsReturnRideActive = [...isReturnRideActive, passenger.uuid];

    if(existingOutboardingRoute){
      setRoutes((prevRoutes) => {
        const existingIndex = prevRoutes.findIndex((route) => route.uuid === returnPickupWaypoint.uuid)
        prevRoutes[existingIndex] = returnPickupWaypoint
        return [...prevRoutes, returnDropOffWaypoint]
      });
    }
    else {
      setRoutes((prevRoutes) => [...prevRoutes, returnPickupWaypoint, returnDropOffWaypoint]);
    }
    setReturnRideRouteItemUuids([
        ...returnRideRouteItemUuids,
        {
            onboarding: returnPickupWaypoint.uuid,
            outboarding: returnDropOffWaypoint.uuid,
            passengerUuid: passenger.uuid,
        },
    ]);
    setIsReturnRideActive(newIsReturnRideActive);
};

  return (
    <>
      <div className="order_add">
        <HeadingComponent
          title={translations.header.headingText}
          actions={props.changeViewButtons}
        />
        <Row>
          <Column xl={7}>
            <CardComponent header={{ title: translations.order.headingText }}>
              <Row>
                <Column lg={4}>
                  <FormFieldComponent
                    label={translations.order.taxiLabel}
                    classNames={{ root: `mt-0` }}
                    isRequired
                    errorMessage={
                      formValidationResults.taxiCorporation.errorMessage
                    }
                  >
                    <SingleSelectComponent
                      placeholder={
                        translations.order.taxiSelectInputPlaceholder
                      }
                      value={selectedTaxiCorporationSelectOption}
                      onChange={(value) =>
                        onTaxiCorporationSelectValueChange(value!)
                      }
                      options={taxiCorporationsSelectOptions}
                      isLoading={areTaxiContractsFetching}
                      isDisabled={isTaxiSelectDisabled}
                      idForTesting="taxi-select"
                      hasError={
                        !!formValidationResults.taxiCorporation.errorMessage
                      }
                      isSearchable
                    />
                  </FormFieldComponent>
                </Column>
              </Row>
              <Row>
                <Column lg={4}>
                  <OrderAddPassengersComponent
                    onPassengerListChange={setPassengerList}
                    passengerList={passengerList}
                    onSelectedPassengerUuidChange={setSelectedPassengerUuid}
                    selectedPassengerUuid={selectedPassengerUuid}
                    contractorUuid={user?.aspects.dispatcher?.cargoCompanyUuid ?? null}
                    addReturnRide={addReturnRide}
                    isReturnRideActive={isReturnRideActive}
                    routes={routes}
                    setIsReturnRideActive={setIsReturnRideActive}
                  />
                </Column>
                <Column lg={8}>
                  <OrderAddRoutesComponent
                    onRoutesChange={setRoutes}
                    passengerList={passengerList}
                    routes={routes}
                    contractorUuid={
                      user?.aspects.dispatcher?.cargoCompanyUuid ?? null
                    }
                    isReturnRideActive={isReturnRideActive}
                    setIsReturnRideActive={setIsReturnRideActive}
                    returnRideRouteItemUuids={returnRideRouteItemUuids}
                    setReturnRideRouteItemUuids={setReturnRideRouteItemUuids}
                  />
                </Column>
              </Row>
              <Row>
                <Column lg={4}>
                  <FormFieldComponent
                    label={translations.order.orderNumberLabel}
                    errorMessage={
                      formValidationResults.orderNumber.errorMessage
                    }
                  >
                    <InputComponent
                      placeholder={
                        translations.order.orderNumberInputPlaceholder
                      }
                      value={orderNumber}
                      hasError={
                        !!formValidationResults.orderNumber.errorMessage
                      }
                      onChange={setOrderNumber}
                      onBlur={() => validateOrderNumber(orderNumber)}
                    />
                  </FormFieldComponent>
                </Column>
              </Row>
            </CardComponent>
          </Column>
          <Column xl={5}>
            <OrderAddSummaryComponent
              orderNumber={orderNumber}
              passengerList={passengerList}
              routes={routes}
              restoreForm={restoreForm}
              selectedDispatcherUuid={null}
              selectedTaxiCorporation={
                selectedTaxiCorporationSelectOption?.value ?? null
              }
              validateForm={validateForm}
              selectedPassengerUuid={selectedPassengerUuid}
              setValidationMessage={setRoutesValidationErrors}
              setIsReturnRideActive={setIsReturnRideActive}
            />
          </Column>
        </Row>
      </div>
      <OrderAddRouteValidationModalComponent
        isModalOpen={isRouteErrorsModalOpen}
        onCloseClick={() => {
          setIsRouteErrorsModalOpen(false);
        }}
        routesValidationErrors={routesValidationErrors}
      />
    </>
  );
};

export default CargoOrderAddComponent;
