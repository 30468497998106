import billingsTaxiUnbilledOrderListingRouteQueryParamsService from "../../common/billings-taxi-unbilled-order-listing-route-query-params.service";
import BillingsTaxiUnbilledOrderListingFilter from "../../common/types/billings-taxi-unbilled-order-listing-filter";
import BillingsTaxiUnbilledOrderListingSortKey from "../../common/types/billings-taxi-unbilled-order-listing-sort-key";
import BillingsTaxiUnbilledOrderListingByRailyRouteQueryParams from "./types/billings-taxi-unbilled-order-listing-by-raily-route-query-params";

const createRouteQueryParams = (
  filters: BillingsTaxiUnbilledOrderListingFilter[],
  sortKey: BillingsTaxiUnbilledOrderListingSortKey | null,
  page: number,
  pageSize: number
): BillingsTaxiUnbilledOrderListingByRailyRouteQueryParams => {
  const routeQueryParams: BillingsTaxiUnbilledOrderListingByRailyRouteQueryParams =
    billingsTaxiUnbilledOrderListingRouteQueryParamsService.createRouteQueryParams(
      filters,
      sortKey,
      page,
      pageSize
    );

  return routeQueryParams;
};

const billingsTaxiUnbilledOrderListingByRailyRouteQueryParamsService = {
  getFilters:
    billingsTaxiUnbilledOrderListingRouteQueryParamsService.getFilters,
  getSortKey:
    billingsTaxiUnbilledOrderListingRouteQueryParamsService.getSortKey,
  getPage: billingsTaxiUnbilledOrderListingRouteQueryParamsService.getPage,
  getPageSize:
    billingsTaxiUnbilledOrderListingRouteQueryParamsService.getPageSize,
  createRouteQueryParams,
};

export default billingsTaxiUnbilledOrderListingByRailyRouteQueryParamsService;
