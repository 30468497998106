import UserRole from "../../../../../../common/types/user-role";
import UserPermissions from "../../../../../../common/utils/user/permissions/user-permissions";
import UserPermissionsDefinition from "../../../../../../common/utils/user/permissions/user-permissions-definition";

export type BillingsTaxiRouteListingUserPermissionOption =
  | "hasAccessToTaxiView"
  | "hasAccessToRailyView";

export type BillingsTaxiRouteListingUserPermissionsDefinition =
  UserPermissionsDefinition<BillingsTaxiRouteListingUserPermissionOption>;

export type BillingsTaxiRouteListingUserPermissions =
  UserPermissions<BillingsTaxiRouteListingUserPermissionOption>;

const billingsTaxiRouteListingUserPermissionDefinition: BillingsTaxiRouteListingUserPermissionsDefinition =
  {
    hasAccessToTaxiView: [UserRole.TAXI_OFFICER],
    hasAccessToRailyView: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
  };

export default billingsTaxiRouteListingUserPermissionDefinition;
