import DriverDetailsDriverPlanEntry, {
  DriverDetailsDriverPlanEntryNodeExclusions,
  DriverDetailsDriverPlanEntryWaypoint,
  DriverDetailsDriverPlanEntryWaypointType,
} from "./types/driver-details-plan-entry";
import PlanEntryDetails, {
  PlanEntryDetailsCheckoutEvent,
  PlanEntryDetailsRideNode,
  PlanEntryDetailsSolvedOrder,
} from "../../../../../../common/services/plan-entry/details/plan-entry-details";

const createWaypointForNode = (
  responseNode: PlanEntryDetailsRideNode,
  responseCheckoutEvents: PlanEntryDetailsCheckoutEvent[],
  stageCounter: number
): DriverDetailsDriverPlanEntryWaypoint => {
  const responseCheckoutEvent = responseCheckoutEvents.find(
    (item) => item.nodeId === responseNode.meta.id
  );

  const waypoint: DriverDetailsDriverPlanEntryWaypoint = {
    type: DriverDetailsDriverPlanEntryWaypointType.DRIVER_HOME,
    uuid: responseNode.meta.id,
    address: {
      displayName: responseNode.displayName,
      latitude: responseNode.lat,
      longitude: responseNode.lon,
    },
    checkoutDate: responseCheckoutEvent?.achievedAt
      ? new Date(responseCheckoutEvent.achievedAt)
      : null,
    createdAt: responseCheckoutEvent?.createdAt ?? null,
    createdByName: responseCheckoutEvent?.createdByName ?? null,
    clientDisplayName: "",
    estimatedDate: new Date(responseNode.meta.estimatedTime),
    plannedHaltingTime: responseNode.haltingTime,
    forcedHaltingTime: responseCheckoutEvent?.haltingTime ?? null,
    highwayCost: responseCheckoutEvent?.highwayCharge ?? null,
    order: null,
    plannedDate: responseNode.time ? new Date(responseNode.time) : null,
    isCheckouted: !!responseCheckoutEvent,
    checkoutEventUuid: responseCheckoutEvent?.id ?? null,
    isCheckoutManagementEnabled: true,
    solvedOrderUuid: null,
    stageNo: stageCounter,
  };

  return waypoint;
};

const createWaypointsForSolvedOrder = (
  responseSolvedOrder: PlanEntryDetailsSolvedOrder,
  responseCheckoutEvents: PlanEntryDetailsCheckoutEvent[],
  stageCounter: number,
  onComplete: (stageCounterValue: number) => void
): DriverDetailsDriverPlanEntryWaypoint[] => {
  const waypoints: DriverDetailsDriverPlanEntryWaypoint[] = [];

  let internalStageCounter = stageCounter;

  responseSolvedOrder.ride.seq.forEach((seqItem) => {
    const responseCheckoutEvent = responseCheckoutEvents.find(
      (item) => item.nodeId === seqItem.meta.id
    );

    const waypoint: DriverDetailsDriverPlanEntryWaypoint = {
      type: DriverDetailsDriverPlanEntryWaypointType.ORDER_WAYPOINT,
      uuid: seqItem.meta.id,
      clientDisplayName: responseSolvedOrder.cargoCompany.displayName,
      order: {
        uuid: responseSolvedOrder.transportingOrder.cargoOrder.id,
        cargoInternalOrderId:
          responseSolvedOrder.transportingOrder.cargoOrder.humanId,
      },
      address: {
        displayName: seqItem.displayName,
        latitude: seqItem.lat,
        longitude: seqItem.lon,
      },
      checkoutDate: responseCheckoutEvent?.achievedAt
        ? new Date(responseCheckoutEvent.achievedAt)
        : null,
      createdAt: responseCheckoutEvent?.createdAt ?? null,
      createdByName: responseCheckoutEvent?.createdByName ?? null,
      estimatedDate: new Date(seqItem.meta.estimatedTime),
      plannedHaltingTime: seqItem.haltingTime,
      forcedHaltingTime: responseCheckoutEvent?.haltingTime ?? null,
      highwayCost: responseCheckoutEvent?.highwayCharge ?? null,
      plannedDate: seqItem.time ? new Date(seqItem.time) : null,
      isCheckouted: !!responseCheckoutEvent,
      checkoutEventUuid: responseCheckoutEvent?.id ?? null,
      isCheckoutManagementEnabled: true,
      solvedOrderUuid: responseSolvedOrder.id,
      stageNo: internalStageCounter,
    };

    waypoints.push(waypoint);
    internalStageCounter++;
  });

  onComplete(internalStageCounter);

  return waypoints;
};

const createWaypointsForSolvedOrders = (
  responseSolvedOrders: PlanEntryDetailsSolvedOrder[],
  responseCheckoutEvents: PlanEntryDetailsCheckoutEvent[],
  initialStageCounter: number
): DriverDetailsDriverPlanEntryWaypoint[] => {
  let stageCounter = initialStageCounter;
  const allWaypoints: DriverDetailsDriverPlanEntryWaypoint[] = [];

  responseSolvedOrders.forEach((responseSolvedOrder) => {
    const orderWaypoints = createWaypointsForSolvedOrder(
      responseSolvedOrder,
      responseCheckoutEvents,
      stageCounter,
      (updatedStageCounter) => {
        stageCounter = updatedStageCounter;
      }
    );

    allWaypoints.push(...orderWaypoints);
  });

  return allWaypoints;
};

const createPlanEntryWaypoints = (
  responsePlanEntry: PlanEntryDetails
): DriverDetailsDriverPlanEntryWaypoint[] => {
  const waypoints: DriverDetailsDriverPlanEntryWaypoint[] = [];

  let stageCounter: number = 1;

  const startWaypoint = createWaypointForNode(
    responsePlanEntry.startNode,
    responsePlanEntry.checkoutEvents,
    stageCounter
  );

  stageCounter++;

  waypoints.push(startWaypoint);

  const orderWaypoints = createWaypointsForSolvedOrders(
    responsePlanEntry.solvedOrders,
    responsePlanEntry.checkoutEvents,
    stageCounter
  );

  waypoints.push(...orderWaypoints);

  stageCounter = stageCounter = waypoints[waypoints.length - 1].stageNo + 1;

  const endWaypoint = createWaypointForNode(
    responsePlanEntry.endNode,
    responsePlanEntry.checkoutEvents,
    stageCounter
  );

  waypoints.push(endWaypoint);

  return waypoints;
};

const createNodeExclusions = (
  responsePlanEntry: PlanEntryDetails
): DriverDetailsDriverPlanEntryNodeExclusions[] => {
  const exclusionList = responsePlanEntry.nodeExclusions.exclusions.map(
    (nodeExclusions) => ({
      approaching: nodeExclusions.excludeApproaching,
      inside: nodeExclusions.excludeInside,
      returning: nodeExclusions.excludeReturning,
      nodeIds: nodeExclusions.nodeIds,
    })
  );

  return exclusionList;
};

const createPlanEntry = (
  responsePlanEntry: PlanEntryDetails
): DriverDetailsDriverPlanEntry => {
  const waypoints = createPlanEntryWaypoints(responsePlanEntry);
  const nodeExclusions = createNodeExclusions(responsePlanEntry);

  return {
    isAnonymized: false,
    uuid: responsePlanEntry.id,
    id: responsePlanEntry.humanId,
    waypoints,
    nodeExclusions: nodeExclusions,
  };
};

const driverDetailsFinishedRoutePlansPlanEntryFactory = {
  createPlanEntry,
};

export default driverDetailsFinishedRoutePlansPlanEntryFactory;
