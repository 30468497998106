import CargoOrderAbandonedListReportRequest, {
  CargoOrderAbandonedListReportQueryParams,
} from "./cargo-order-abandoned-list-report.request";

const createQueryParams = (
  request: CargoOrderAbandonedListReportRequest
): CargoOrderAbandonedListReportQueryParams => {
  return {
    order: request.order,
    address: request.address,
    client: request.client,
    creationEnd: request.creationEnd,
    creationStart: request.creationStart,
    dispatch: request.dispatch,
    endAddress: request.endAddress,
    externalId: request.externalId,
    humanId: request.humanId,
    midAddress: request.midAddress,
    passenger: request.passenger,
    publicStatus: request.publicStatus,
    startAddress: request.startAddress,
    startTimeSince: request.startTimeSince,
    startTimeTo: request.startTimeTo,
  };
};

const cargoOrderAbandonedListReportRequestFactory = {
  createQueryParams,
};

export default cargoOrderAbandonedListReportRequestFactory;
