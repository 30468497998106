import {
  BillingsTaxiOrderListingResponseDataItem,
  BillingsTaxiOrderListingResponseDataStats,
} from "./api/billings-taxi-order-listing.response";
import BillingsTaxiOrderListingItem from "./types/billings-taxi-order-listing-item";
import BillingsTaxiOrderListingItemBillingStatus from "./types/billings-taxi-order-listing-item-billing-status";
import BillingsTaxiOrderListingStatsSummary from "./types/billings-taxi-order-listing-stats-summary";

const createListingItemStatus = (
  responseDataItem: BillingsTaxiOrderListingResponseDataItem
): BillingsTaxiOrderListingItemBillingStatus | undefined => {
  switch (responseDataItem.status) {
    case "CREATED":
      return BillingsTaxiOrderListingItemBillingStatus.CREATED;
    case "ACCEPTED":
      return BillingsTaxiOrderListingItemBillingStatus.ACCEPTED;
    case "REJECTED":
      return BillingsTaxiOrderListingItemBillingStatus.REJECTED;
    case "REOPENED":
      return BillingsTaxiOrderListingItemBillingStatus.REOPENED;
    case "REOPEN_REQUEST":
      return BillingsTaxiOrderListingItemBillingStatus.REOPEN_REQUEST_SENT;
    default:
      return undefined;
  }
};

const createListingItem = (
  responseDataItem: BillingsTaxiOrderListingResponseDataItem
): BillingsTaxiOrderListingItem => {
  return {
    uuid: responseDataItem.id,
    internalOrderId: responseDataItem.human_id,
    planEntryHumanIds: responseDataItem.plan_entry_human_ids,
    externalOrderId: responseDataItem.external_id,
    orderStartDate: new Date(responseDataItem.date),
    cargoCompanyName: responseDataItem.cargo_company,
    billingModel: responseDataItem.billing_model,
    billingType: responseDataItem.billing_type,
    status: createListingItemStatus(responseDataItem),
    distance: responseDataItem.distance,
    amountForDistance: responseDataItem.amount_for_distance,
    baseDistanceRate: responseDataItem.base_distance_rate,
    stopoverCost: responseDataItem.amount_for_charge_haltings ?? 0,
    tollRoadsCost: responseDataItem.amount_for_charge_highways ?? 0,
    discountAmountPercent: responseDataItem.contract_correction_percentage ?? 0,
    totalCost: responseDataItem.all_contributions_amount,
    sumOfDiscounts: responseDataItem.sum_of_discounts ?? 0,
    passengers: responseDataItem.passengers,
    destinationTaxi: responseDataItem.responsible_taxi ?? "",
  };
};

const createListingItems = (
  responseDataItems: BillingsTaxiOrderListingResponseDataItem[]
): BillingsTaxiOrderListingItem[] => {
  return responseDataItems.map(createListingItem);
};

const createStatsSummary = (
  responseStatsSummary: BillingsTaxiOrderListingResponseDataStats
): BillingsTaxiOrderListingStatsSummary => {
  return {
    distance: responseStatsSummary.distance,
    amountForChargeHaltings: responseStatsSummary.amount_for_charge_haltings,
    amountForChargeHighways: responseStatsSummary.amount_for_charge_highways,
    amountForDistance: responseStatsSummary.amount_for_distance,
    sumOfDiscounts: responseStatsSummary.sum_of_discounts,
    allContributionsAmount: responseStatsSummary.all_contributions_amount,
  };
};

const billingsTaxiOrderListingFactory = {
  createListingItems,
  createStatsSummary,
};

export default billingsTaxiOrderListingFactory;
