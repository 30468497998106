import BillingModel from "../../../../../../common/types/billing-model";
import billingsTranslationsHelper from "../../../../../../languages/billings-translations.helper";
import BillingsTaxiTransferredOrderListingItemBillingStatus from "../types/billings-taxi-transferred-order-listing-item-billing-status";

const getBillingStatusOptions = () => {
  const translations =
    billingsTranslationsHelper.getTaxiRouteTransferredOrderBillingsListingTranslations()
      .status;

  const options: {
    text: string;
    status: BillingsTaxiTransferredOrderListingItemBillingStatus;
  }[] = [
    {
      text: translations.CREATED,
      status: BillingsTaxiTransferredOrderListingItemBillingStatus.CREATED,
    },
    {
      text: translations.ACCEPTED,
      status: BillingsTaxiTransferredOrderListingItemBillingStatus.ACCEPTED,
    },
    {
      text: translations.REJECTED,
      status: BillingsTaxiTransferredOrderListingItemBillingStatus.REJECTED,
    },
    {
      text: translations.REOPENED,
      status: BillingsTaxiTransferredOrderListingItemBillingStatus.REOPENED,
    },
    {
      text: translations.REOPEN_REQUEST,
      status:
        BillingsTaxiTransferredOrderListingItemBillingStatus.REOPEN_REQUEST,
    },
  ];

  return options;
};

const getBillingStatusText = (
  status: BillingsTaxiTransferredOrderListingItemBillingStatus
): string => {
  const options = getBillingStatusOptions();

  return options.find((option) => option.status === status)?.text ?? "";
};

const getBillingModelOptions = () => {
  const translations =
    billingsTranslationsHelper.getTaxiRouteTransferredOrderBillingsListingTranslations()
      .model;

  const options: {
    text: string;
    billingModel: BillingModel;
  }[] = [
    {
      text: translations.AB,
      billingModel: BillingModel.AB,
    },
    {
      text: translations.ABA,
      billingModel: BillingModel.ABA,
    },
    {
      text: translations.OTHER,
      billingModel: BillingModel.OTHER,
    },
    {
      text: translations.SABS,
      billingModel: BillingModel.SABS,
    },
  ];

  return options;
};

const getBillingModelText = (type: BillingModel): string => {
  const options = getBillingModelOptions();

  return options.find((option) => option.billingModel === type)?.text ?? "";
};

const billingsTaxiTransferredOrderListingHelper = {
  getBillingStatusOptions,
  getBillingStatusText,
  getBillingModelOptions,
  getBillingModelText,
};

export default billingsTaxiTransferredOrderListingHelper;
