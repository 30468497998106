import billingsTranslationsHelper from "../../../../../languages/billings-translations.helper";
import BillingsTaxiUnbilledOrderListingSortKey from "./types/billings-taxi-unbilled-order-listing-sort-key";
import BillingsTaxiUnbilledOrderListingSortSelectOption from "./types/billings-taxi-unbilled-order-listing-sort-select-option";

const getSelectOptions =
  (): BillingsTaxiUnbilledOrderListingSortSelectOption[] => {
    const translations =
      billingsTranslationsHelper.getTaxiUnbilledOrderBillingsListingTranslations()
        .sort;

    return [
      {
        label: translations.startDateAscOptionLabel,
        value: BillingsTaxiUnbilledOrderListingSortKey.START_DATE_ASC,
      },
      {
        label: translations.startDateDescOptionLabel,
        value: BillingsTaxiUnbilledOrderListingSortKey.START_DATE_DESC,
      },
      {
        label: translations.internalOrderIdAscOptionLabel,
        value: BillingsTaxiUnbilledOrderListingSortKey.INTERNAL_ORDER_ID_ASC,
      },
      {
        label: translations.internalOrderIdDescOptionLabel,
        value: BillingsTaxiUnbilledOrderListingSortKey.INTERNAL_ORDER_ID_DESC,
      },
    ];
  };

const billingsTaxiUnbilledOrderListingSortHelper = {
  getSelectOptions,
};

export default billingsTaxiUnbilledOrderListingSortHelper;
