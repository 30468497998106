import CargoOrderAbandonedListReportRequest, {
  OrderAbandonedListReportRequestSortApiKey,
} from "../../../../api/raily/cargo-order/abandoned-list/report/cargo-order-abandoned-list-report.request";
import CargoOrderAbandonedListReportLoadParams, {
  OrderAbandonedListReportLoadParamsOrder,
} from "./cargo-order-abandoned-list-report-load-params";

const createOrder = (
  sortKey: OrderAbandonedListReportLoadParamsOrder | null
): OrderAbandonedListReportRequestSortApiKey | null => {
  switch (sortKey) {
    case OrderAbandonedListReportLoadParamsOrder.CREATED_AT_ASC:
      return OrderAbandonedListReportRequestSortApiKey.CREATED_AT_ASC;
    case OrderAbandonedListReportLoadParamsOrder.CREATED_AT_DESC:
      return OrderAbandonedListReportRequestSortApiKey.CREATED_AT_DESC;
    case OrderAbandonedListReportLoadParamsOrder.HUMAN_ID_ASC:
      return OrderAbandonedListReportRequestSortApiKey.HUMAN_ID_ASC;
    case OrderAbandonedListReportLoadParamsOrder.HUMAN_ID_DESC:
      return OrderAbandonedListReportRequestSortApiKey.HUMAN_ID_DESC;
    case OrderAbandonedListReportLoadParamsOrder.MODIFIED_AT_ASC:
      return OrderAbandonedListReportRequestSortApiKey.MODIFIED_AT_ASC;
    case OrderAbandonedListReportLoadParamsOrder.MODIFIED_AT_DESC:
      return OrderAbandonedListReportRequestSortApiKey.MODIFIED_AT_DESC;
    default:
      return null;
  }
};

const create = (
  params: CargoOrderAbandonedListReportLoadParams
): CargoOrderAbandonedListReportRequest => {
  return {
    order: createOrder(params.order),
    address: params.address,
    client: params.client,
    creationEnd: params.creationEnd,
    creationStart: params.creationStart,
    dispatch: params.dispatch,
    endAddress: params.endAddress,
    externalId: params.externalId,
    humanId: params.humanId,
    midAddress: params.midAddress,
    passenger: params.passenger,
    publicStatus: params.publicStatus,
    startAddress: params.startAddress,
    startTimeSince: params.startTimeSince,
    startTimeTo: params.startTimeTo,
  };
};

const cargoOrderAbandonedListReportRequestFactory = { create };

export default cargoOrderAbandonedListReportRequestFactory;
