import { FC, useEffect, useState } from "react";
import { useAppContext } from "../../../../../../context/app.context";
import StatusLegendComponent from "../../../../../../common/components/status-legend/status-legend.component";
import BillingsTaxiOrderListingStatusCountersResponse, {
  BillingsTaxiOrderListingStatusCountersResponseData,
} from "../api/billings-taxi-order-listing-status-counters.response";
import billingsTaxiOrderListingApiService from "../api/billings-taxi-order-listing-api.service";
import billingsTaxiOrderListingHelper from "../billings-taxi-order-listing.helper";

type BillingsTaxiOrderListingStatusLegendProps = {
  taxiCorporationUuid: string | null;
};

const BillingsTaxiOrderListingStatusLegendComponent: FC<
  BillingsTaxiOrderListingStatusLegendProps
> = (props) => {
  const [countersResponse, setCountersResponse] = useState<
    BillingsTaxiOrderListingStatusCountersResponseData | undefined
  >();

  useAppContext();

  useEffect(() => {
    billingsTaxiOrderListingApiService
      .fetchStatusCounters({
        taxi_corporation_id: props.taxiCorporationUuid ?? undefined,
      })
      .then(handleStatusCountersResponse);
  }, [props.taxiCorporationUuid]);

  const handleStatusCountersResponse = (
    response: BillingsTaxiOrderListingStatusCountersResponse
  ) => {
    setCountersResponse(response.data);
  };

  const statusOptions =
    billingsTaxiOrderListingHelper.getBillingStatusOptions(countersResponse);

  return <StatusLegendComponent statusData={statusOptions} />;
};

export default BillingsTaxiOrderListingStatusLegendComponent;
