import CargoOrderAbandonedListResponse, {
  CargoOrderAbandonedListResponseItem,
  CargoOrderAbandonedListResponseItemProducer,
  CargoOrderAbandonedListResponsePublicStatus,
} from "../../../api/raily/cargo-order/abandoned-list/cargo-order-abandoned-list.response";
import CargoOrderAbandonedList, {
  CargoOrderAbandonedListItem,
  CargoOrderAbandonedListItemProducer,
  CargoOrderAbandonedListPublicStatus,
} from "./cargo-order-abandoned-list";

const createProducer = (
  producer: CargoOrderAbandonedListResponseItemProducer
): CargoOrderAbandonedListItemProducer => {
  return {
    name: producer.name,
    uuid: producer.id,
  };
};

const createPublicStatus = (
  publicStatus: CargoOrderAbandonedListResponsePublicStatus
): CargoOrderAbandonedListPublicStatus => {
  switch (publicStatus) {
    case CargoOrderAbandonedListResponsePublicStatus.PRIVATE:
      return CargoOrderAbandonedListPublicStatus.PRIVATE;
    case CargoOrderAbandonedListResponsePublicStatus.PUBLIC:
      return CargoOrderAbandonedListPublicStatus.PUBLIC;
    case CargoOrderAbandonedListResponsePublicStatus.PUBLISHED:
      return CargoOrderAbandonedListPublicStatus.PUBLISHED;
  }
};

const createItem = (
  item: CargoOrderAbandonedListResponseItem
): CargoOrderAbandonedListItem => {
  return {
    client: item.client,
    dispatch: item.dispatch,
    externalId: item.externalId,
    humanId: item.humanId,
    passengers: item.passengers,
    producer: createProducer(item.producer),
    publicStatus: createPublicStatus(item.publicStatus),
    rideAddresses: item.rideAddresses,
    startTime: item.startTime,
    uuid: item.id,
  };
};

const create = (
  response: CargoOrderAbandonedListResponse
): CargoOrderAbandonedList => {
  return {
    data: response.data.data.map(createItem),
    totalCount: response.data.totalCount,
  };
};

const cargoOrderAbandonedListFactory = { create };

export default cargoOrderAbandonedListFactory;
