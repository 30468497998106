import dateService from "../../../../../../common/utils/date/date.service";
import durationService from "../../../../../../common/utils/duration/duration.service";
import userTranslationsHelper from "../../../../../../languages/user-translations.helper";
import DriverDetailsPlanEntryRouteTableColumn from "../common/types/driver-details-plan-entry-route-table-column";

const getColumns = (): DriverDetailsPlanEntryRouteTableColumn[] => {
  const translations =
    userTranslationsHelper.getDriverDetailsFinishedRoutePlansTranslations()
      .routeListing.table.headers;

  return [
    {
      accessor: "client",
      header: translations.clientLabel,
      title: translations.clientTitle,
      colSpan: 10,
    },
    {
      accessor: "order",
      header: translations.orderNoLabel,
      title: translations.orderNoTitle,
      colSpan: 10,
    },
    {
      accessor: "address",
      header: translations.addressLabel,
      title: translations.addressTitle,
      colSpan: 25,
    },
    {
      accessor: "plannedDate",
      header: translations.plannedDateLabel,
      title: translations.plannedDateTitle,
      colSpan: 12,
    },
    {
      accessor: "estimatedDate",
      header: translations.estimatedDateLabel,
      title: translations.estimatedDateTitle,
      colSpan: 12,
    },
    {
      accessor: "plannedHaltingTime",
      header: translations.plannedHaltingTimeLabel,
      title: translations.plannedHaltingTimeTitle,
      colSpan: 12,
    },
    {
      accessor: "checkoutDate",
      header: translations.checkoutDateLabel,
      title: translations.checkoutDateTitle,
      colSpan: 12,
    },
    {
      accessor: "forcedHaltingTime",
      header: translations.forcedHaltingTimeLabel,
      title: translations.forcedHaltingTimeTitle,
      colSpan: 12,
    },
    {
      accessor: "highwayCost",
      header: translations.highwayCostLabel,
      title: translations.highwayCostTitle,
      colSpan: 12,
    },
    {
      accessor: "createdAt",
      header: translations.createdAtLabel,
      title: translations.createdAtTitle,
      colSpan: 12,
    },
    {
      accessor: "createdByName",
      header: translations.createdByNameLabel,
      title: translations.createdByNameTitle,
      colSpan: 12,
    },
  ];
};

const getEmptyLabel = (): string => {
  return `----`;
};

const getEmptyTitle = (): string => {
  return ``;
};

const getDateLabel = (date: Date | null): string => {
  if (!date) {
    return getEmptyLabel();
  }

  return dateService.formatTime(date);
};

const getDateTitle = (date: Date | null): string => {
  if (!date) {
    return getEmptyTitle();
  }

  return dateService.formatDateTime(date);
};

const getClientLabel = (clientDisplayName: string): string => {
  if (!clientDisplayName) {
    return getEmptyLabel();
  }

  return clientDisplayName;
};

const getClientTitle = (clientDisplayName: string): string => {
  if (!clientDisplayName) {
    return getEmptyTitle();
  }

  return clientDisplayName;
};

const getOrderIdLabel = (orderId: number | undefined): string => {
  if (!orderId) {
    return getEmptyLabel();
  }

  return `${orderId}`;
};

const getOrderIdTitle = (orderId: number | undefined): string => {
  if (!orderId) {
    return getEmptyTitle();
  }

  return `${orderId}`;
};

const getPlannedDateLabel = (plannedDate: Date | null) => {
  return getDateLabel(plannedDate);
};

const getPlannedDateTitle = (plannedDate: Date | null) => {
  return getDateTitle(plannedDate);
};

const getEstimatedDateLabel = (estimatedDate: Date | null) => {
  return getDateLabel(estimatedDate);
};

const getEstimatedDateTitle = (estimatedDate: Date | null) => {
  return getDateTitle(estimatedDate);
};

const getCheckoutDateLabel = (checkoutDate: Date | null) => {
  return getDateLabel(checkoutDate);
};

const getCheckoutDateTitle = (checkoutDate: Date | null) => {
  return getDateTitle(checkoutDate);
};

const getPlannedHaltingTimeLabel = (haltingTime: number | null) => {
  if (haltingTime === null) {
    return getEmptyLabel();
  }

  return `${durationService.format(haltingTime)}h`;
};

const getPlannedHaltingTimeTitle = (haltingTime: number | null) => {
  if (haltingTime === null) {
    return getEmptyTitle();
  }

  return `${durationService.format(haltingTime)}h`;
};

const getForcedHaltingTimeLabel = (haltingTime: number | null) => {
  if (haltingTime === null) {
    return getEmptyLabel();
  }

  return `${durationService.format(haltingTime)} h`;
};

const getForcedHaltingTimeTitle = (haltingTime: number | null) => {
  if (haltingTime === null) {
    return getEmptyTitle();
  }

  return `${durationService.format(haltingTime)} h`;
};

const getHighwayCostLabel = (highwayCost: number | null) => {
  if (highwayCost === null) {
    return getEmptyLabel();
  }

  return `${highwayCost} PLN`;
};

const getHighwayCostTitle = (highwayCost: number | null) => {
  if (highwayCost === null) {
    return getEmptyTitle();
  }

  return `${highwayCost} PLN`;
};

const getCreatedAtLabel = (createdAt: Date | null) => {
  return getDateLabel(createdAt);
};

const getCreatedAtTitle = (createdAt: Date | null) => {
  return getDateTitle(createdAt);
};

const getCreatedByNameLabel = (createdByName: string | null) => {
  if (createdByName === null) {
    return getEmptyLabel();
  }

  return createdByName;
};

const getCreatedByNameTitle = (createdByName: string | null) => {
  if (createdByName === null) {
    return getEmptyTitle();
  }

  return createdByName;
};

const driverDetailsPlanByRailyRouteListingHelper = {
  getColumns,
  getClientLabel,
  getClientTitle,
  getOrderIdLabel,
  getOrderIdTitle,
  getPlannedDateLabel,
  getPlannedDateTitle,
  getEstimatedDateLabel,
  getEstimatedDateTitle,
  getCheckoutDateLabel,
  getCheckoutDateTitle,
  getPlannedHaltingTimeLabel,
  getPlannedHaltingTimeTitle,
  getForcedHaltingTimeLabel,
  getForcedHaltingTimeTitle,
  getHighwayCostLabel,
  getHighwayCostTitle,
  getCreatedByNameLabel,
  getCreatedByNameTitle,
  getCreatedAtLabel,
  getCreatedAtTitle,
};

export default driverDetailsPlanByRailyRouteListingHelper;
