import OrderOptimizerListResponse, {
  OrderOptimizerListResponseItem,
  OrderOptimizerListResponseItemRoute,
} from "../../../api/raily/order-optimizer/list/order-optimizer-list.response";
import OrderOptimizerList, {
  OrderOptimizerListItem,
  OrderOptimizerListItemRoute,
} from "./order-optimizer-list";

const createOrderRoute = (
  responseItemOrderRoute: OrderOptimizerListResponseItemRoute
): OrderOptimizerListItemRoute => ({
  orderHumanId: responseItemOrderRoute.orderHumanId,
  lat: responseItemOrderRoute.lat,
  lon: responseItemOrderRoute.lon,
});

const createOrderRoutes = (
  responseItemOrderRoute: OrderOptimizerListResponseItemRoute[]
): OrderOptimizerListItemRoute[] => {
  return responseItemOrderRoute.map(createOrderRoute);
};

const createItem = (
  responseItem: OrderOptimizerListResponseItem
): OrderOptimizerListItem => ({
  id: responseItem.id,
  orderHumanIds: responseItem.orderHumanIds,
  reviewedAt: responseItem.reviewedAt,
  reviewedBy: responseItem.reviewedBy,
  route: createOrderRoutes(responseItem.route),
  score: responseItem.score,
});

const createData = (
  responseData: OrderOptimizerListResponseItem[]
): OrderOptimizerListItem[] => {
  return responseData.map(createItem);
};

const create = (response: OrderOptimizerListResponse): OrderOptimizerList => {
  return {
    data: createData(response.data.data),
    totalCount: response.data.totalCount,
  };
};

const orderOptimizerListFactory = {
  create,
};

export default orderOptimizerListFactory;
