import Joi from "joi";
import BillingsTaxiUnbilledOrderListingRouteQueryFilterParams from "./types/billings-taxi-unbilled-order-listing-route-query-filter-params";
import BillingsTaxiUnbilledOrderListingRouteQueryParams from "./types/billings-taxi-unbilled-order-listing-route-query-params";
import BillingsTaxiUnbilledOrderListingSortKey from "./types/billings-taxi-unbilled-order-listing-sort-key";
import BillingsTaxiUnbilledOrderListingFilter, {
  BillingsTaxiUnbilledOrderListingClientFilter,
  BillingsTaxiUnbilledOrderListingDispatchFilter,
  BillingsTaxiUnbilledOrderListingDriverFilter,
  BillingsTaxiUnbilledOrderListingExternalOrderIdFilter,
  BillingsTaxiUnbilledOrderListingInternalOrderIdFilter,
  BillingsTaxiUnbilledOrderListingPassengerFilter,
  BillingsTaxiUnbilledOrderListingRouteAddressFilter,
  BillingsTaxiUnbilledOrderListingRouteDestinationAddressFilter,
  BillingsTaxiUnbilledOrderListingRouteIntermediateAddressFilter,
  BillingsTaxiUnbilledOrderListingRoutePickupAddressFilter,
  BillingsTaxiUnbilledOrderListingStartDateFilter,
  BillingsTaxiUnbilledOrderListingStatusFilter,
  BillingsTaxiUnbilledOrderListingTaxiFilter,
} from "./types/billings-taxi-unbilled-order-listing-filter";
import BillingsTaxiUnbilledOrderListingFilterType from "./types/billings-taxi-unbilled-order-listing-filter-type";

const getFilters = (
  routeQueryFilterParams: BillingsTaxiUnbilledOrderListingRouteQueryFilterParams
): BillingsTaxiUnbilledOrderListingFilter[] => {
  if (!validateFilters(routeQueryFilterParams)) {
    return [];
  }

  const filters: BillingsTaxiUnbilledOrderListingFilter[] = [];

  if (routeQueryFilterParams.client) {
    const clientFilter: BillingsTaxiUnbilledOrderListingClientFilter = {
      type: BillingsTaxiUnbilledOrderListingFilterType.CLIENT,
      value: routeQueryFilterParams.client,
    };

    filters.push(clientFilter);
  }

  if (routeQueryFilterParams.dispatch) {
    const dispatchFilter: BillingsTaxiUnbilledOrderListingDispatchFilter = {
      type: BillingsTaxiUnbilledOrderListingFilterType.DISPATCH,
      value: routeQueryFilterParams.dispatch,
    };

    filters.push(dispatchFilter);
  }

  if (routeQueryFilterParams.driver) {
    const driverFilter: BillingsTaxiUnbilledOrderListingDriverFilter = {
      type: BillingsTaxiUnbilledOrderListingFilterType.DRIVER,
      value: routeQueryFilterParams.driver,
    };

    filters.push(driverFilter);
  }

  if (routeQueryFilterParams.externalOrderId) {
    const externalOrderIdFilter: BillingsTaxiUnbilledOrderListingExternalOrderIdFilter =
      {
        type: BillingsTaxiUnbilledOrderListingFilterType.EXTERNAL_ORDER_ID,
        value: routeQueryFilterParams.externalOrderId,
      };

    filters.push(externalOrderIdFilter);
  }

  if (routeQueryFilterParams.internalOrderId) {
    const internalOrderIdFilter: BillingsTaxiUnbilledOrderListingInternalOrderIdFilter =
      {
        type: BillingsTaxiUnbilledOrderListingFilterType.INTERNAL_ORDER_ID,
        value: routeQueryFilterParams.internalOrderId,
      };

    filters.push(internalOrderIdFilter);
  }

  if (routeQueryFilterParams.passenger) {
    const passengerFilter: BillingsTaxiUnbilledOrderListingPassengerFilter = {
      type: BillingsTaxiUnbilledOrderListingFilterType.PASSENGER,
      value: routeQueryFilterParams.passenger,
    };

    filters.push(passengerFilter);
  }

  if (routeQueryFilterParams.taxi) {
    const taxiFilter: BillingsTaxiUnbilledOrderListingTaxiFilter = {
      type: BillingsTaxiUnbilledOrderListingFilterType.TAXI,
      value: routeQueryFilterParams.taxi,
    };

    filters.push(taxiFilter);
  }

  if (routeQueryFilterParams.routeAddress) {
    const routeAddressFilter: BillingsTaxiUnbilledOrderListingRouteAddressFilter =
      {
        type: BillingsTaxiUnbilledOrderListingFilterType.ROUTE_ADDRESS,
        value: routeQueryFilterParams.routeAddress,
      };

    filters.push(routeAddressFilter);
  }

  if (routeQueryFilterParams.routeDestinationAddress) {
    const routeDestinationFilter: BillingsTaxiUnbilledOrderListingRouteDestinationAddressFilter =
      {
        type: BillingsTaxiUnbilledOrderListingFilterType.ROUTE_DESTINATION_ADDRESS,
        value: routeQueryFilterParams.routeDestinationAddress,
      };

    filters.push(routeDestinationFilter);
  }

  if (routeQueryFilterParams.routeIntermediateAddress) {
    const routeIntermediateAddressFilter: BillingsTaxiUnbilledOrderListingRouteIntermediateAddressFilter =
      {
        type: BillingsTaxiUnbilledOrderListingFilterType.ROUTE_INTERMEDIATE_ADDRESS,
        value: routeQueryFilterParams.routeIntermediateAddress,
      };

    filters.push(routeIntermediateAddressFilter);
  }

  if (routeQueryFilterParams.routePickupAddress) {
    const routePickupAddressFilter: BillingsTaxiUnbilledOrderListingRoutePickupAddressFilter =
      {
        type: BillingsTaxiUnbilledOrderListingFilterType.ROUTE_PICKUP_ADDRESS,
        value: routeQueryFilterParams.routePickupAddress,
      };

    filters.push(routePickupAddressFilter);
  }

  if (
    routeQueryFilterParams.startDateFrom &&
    routeQueryFilterParams.startDateTo
  ) {
    const startDateFilter: BillingsTaxiUnbilledOrderListingStartDateFilter = {
      type: BillingsTaxiUnbilledOrderListingFilterType.START_DATE,
      value: {
        from: new Date(routeQueryFilterParams.startDateFrom),
        to: new Date(routeQueryFilterParams.startDateTo),
      },
    };

    filters.push(startDateFilter);
  }

  if (routeQueryFilterParams.status) {
    const statusFilter: BillingsTaxiUnbilledOrderListingStatusFilter = {
      type: BillingsTaxiUnbilledOrderListingFilterType.STATUS,
      value: routeQueryFilterParams.status,
    };

    filters.push(statusFilter);
  }

  return filters;
};

const getSortKey = (
  routeQueryParams: BillingsTaxiUnbilledOrderListingRouteQueryParams
): BillingsTaxiUnbilledOrderListingSortKey | undefined => {
  if (!validateSortKey(routeQueryParams.sort)) {
    return undefined;
  }

  return routeQueryParams.sort;
};

const createRouteQueryParams = (
  filters: BillingsTaxiUnbilledOrderListingFilter[],
  sortKey: BillingsTaxiUnbilledOrderListingSortKey | null,
  page: number,
  pageSize: number
): BillingsTaxiUnbilledOrderListingRouteQueryParams => {
  const routeQueryParams: BillingsTaxiUnbilledOrderListingRouteQueryParams = {
    client: filters.find(
      (filter) =>
        filter.type === BillingsTaxiUnbilledOrderListingFilterType.CLIENT
    )?.value as
      | BillingsTaxiUnbilledOrderListingClientFilter["value"]
      | undefined,
    dispatch: filters.find(
      (filter) =>
        filter.type === BillingsTaxiUnbilledOrderListingFilterType.DISPATCH
    )?.value as
      | BillingsTaxiUnbilledOrderListingDispatchFilter["value"]
      | undefined,
    driver: filters.find(
      (filter) =>
        filter.type === BillingsTaxiUnbilledOrderListingFilterType.DRIVER
    )?.value as
      | BillingsTaxiUnbilledOrderListingDriverFilter["value"]
      | undefined,
    externalOrderId: filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiUnbilledOrderListingFilterType.EXTERNAL_ORDER_ID
    )?.value as
      | BillingsTaxiUnbilledOrderListingExternalOrderIdFilter["value"]
      | undefined,
    internalOrderId: filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiUnbilledOrderListingFilterType.INTERNAL_ORDER_ID
    )?.value as
      | BillingsTaxiUnbilledOrderListingInternalOrderIdFilter["value"]
      | undefined,
    passenger: filters.find(
      (filter) =>
        filter.type === BillingsTaxiUnbilledOrderListingFilterType.PASSENGER
    )?.value as
      | BillingsTaxiUnbilledOrderListingPassengerFilter["value"]
      | undefined,
    routeAddress: filters.find(
      (filter) =>
        filter.type === BillingsTaxiUnbilledOrderListingFilterType.ROUTE_ADDRESS
    )?.value as
      | BillingsTaxiUnbilledOrderListingRouteAddressFilter["value"]
      | undefined,
    routeDestinationAddress: filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiUnbilledOrderListingFilterType.ROUTE_DESTINATION_ADDRESS
    )?.value as
      | BillingsTaxiUnbilledOrderListingRouteDestinationAddressFilter["value"]
      | undefined,
    routeIntermediateAddress: filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiUnbilledOrderListingFilterType.ROUTE_INTERMEDIATE_ADDRESS
    )?.value as
      | BillingsTaxiUnbilledOrderListingRouteIntermediateAddressFilter["value"]
      | undefined,
    routePickupAddress: filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiUnbilledOrderListingFilterType.ROUTE_PICKUP_ADDRESS
    )?.value as
      | BillingsTaxiUnbilledOrderListingRoutePickupAddressFilter["value"]
      | undefined,
    startDateFrom: (
      filters.find(
        (filter) =>
          filter.type === BillingsTaxiUnbilledOrderListingFilterType.START_DATE
      )?.value as
        | BillingsTaxiUnbilledOrderListingStartDateFilter["value"]
        | undefined
    )?.from?.toJSON(),
    startDateTo: (
      filters.find(
        (filter) =>
          filter.type === BillingsTaxiUnbilledOrderListingFilterType.START_DATE
      )?.value as
        | BillingsTaxiUnbilledOrderListingStartDateFilter["value"]
        | undefined
    )?.to?.toJSON(),
    status: filters.find(
      (filter) =>
        filter.type === BillingsTaxiUnbilledOrderListingFilterType.STATUS
    )?.value as
      | BillingsTaxiUnbilledOrderListingStatusFilter["value"]
      | undefined,
    taxi: filters.find(
      (filter) =>
        filter.type === BillingsTaxiUnbilledOrderListingFilterType.TAXI
    )?.value as BillingsTaxiUnbilledOrderListingTaxiFilter["value"] | undefined,
    sort: sortKey ?? undefined,
    page,
    pageSize,
  };

  return routeQueryParams;
};

const getPage = (
  routeQueryFilterParams: BillingsTaxiUnbilledOrderListingRouteQueryParams
): number | undefined => {
  if (!validatePage(routeQueryFilterParams.page)) {
    return undefined;
  }

  return routeQueryFilterParams.page
    ? Number(routeQueryFilterParams.page)
    : undefined;
};

const getPageSize = (
  routeQueryFilterParams: BillingsTaxiUnbilledOrderListingRouteQueryParams
): number | undefined => {
  if (!validatePageSize(routeQueryFilterParams.pageSize)) {
    return undefined;
  }
  return routeQueryFilterParams.pageSize
    ? Number(routeQueryFilterParams.pageSize)
    : undefined;
};

const validateFilters = (
  routeQueryFilterParams: BillingsTaxiUnbilledOrderListingRouteQueryFilterParams
) => {
  const filterParams: BillingsTaxiUnbilledOrderListingRouteQueryFilterParams = {
    client: routeQueryFilterParams.client,
    dispatch: routeQueryFilterParams.dispatch,
    driver: routeQueryFilterParams.driver,
    externalOrderId: routeQueryFilterParams.externalOrderId,
    internalOrderId: routeQueryFilterParams.internalOrderId,
    passenger: routeQueryFilterParams.passenger,
    routeAddress: routeQueryFilterParams.routeAddress,
    routeDestinationAddress: routeQueryFilterParams.routeDestinationAddress,
    routeIntermediateAddress: routeQueryFilterParams.routeIntermediateAddress,
    routePickupAddress: routeQueryFilterParams.routePickupAddress,
    startDateFrom: routeQueryFilterParams.startDateFrom,
    startDateTo: routeQueryFilterParams.startDateTo,
    taxi: routeQueryFilterParams.taxi,
  };

  const validationSchema =
    Joi.object<BillingsTaxiUnbilledOrderListingRouteQueryFilterParams>({
      client: Joi.string(),
      dispatch: Joi.string(),
      driver: Joi.string(),
      externalOrderId: Joi.string(),
      internalOrderId: Joi.string(),
      passenger: Joi.string(),
      routeAddress: Joi.string(),
      routeDestinationAddress: Joi.string(),
      routeIntermediateAddress: Joi.string(),
      routePickupAddress: Joi.string(),
      startDateFrom: Joi.date(),
      startDateTo: Joi.date(),
      taxi: Joi.string(),
    });

  return !validationSchema.validate(filterParams).error?.message;
};

const validateSortKey = (
  routeQuerySortParam: BillingsTaxiUnbilledOrderListingRouteQueryParams["sort"]
): boolean => {
  const validationSchema = Joi.valid(
    ...Object.values(BillingsTaxiUnbilledOrderListingSortKey)
  );

  return !validationSchema.validate(routeQuerySortParam).error?.message;
};

const validatePage = (
  routeQueryPageParam: BillingsTaxiUnbilledOrderListingRouteQueryParams["page"]
) => {
  const validationSchema = Joi.number().min(1);

  return !validationSchema.validate(routeQueryPageParam).error?.message;
};

const validatePageSize = (
  routeQueryPageSizeParam: BillingsTaxiUnbilledOrderListingRouteQueryParams["pageSize"]
) => {
  const validationSchema = Joi.number().valid(...[50, 100, 200]);

  return !validationSchema.validate(routeQueryPageSizeParam).error?.message;
};

const billingsTaxiUnbilledOrderListingRouteQueryParamsService = {
  getFilters,
  getSortKey,
  getPage,
  getPageSize,
  createRouteQueryParams,
};

export default billingsTaxiUnbilledOrderListingRouteQueryParamsService;
