import BillingsCargoOrderListingReportRequest, {
  BillingsCargoOrderListingReportRequestOrder,
} from "./api/billings-cargo-order-listing-report.request";
import BillingsCargoOrderListingFilter, {
  BillingsCargoOrderListingBillingStatusFilter,
  BillingsCargoOrderListingDispatchFilter,
  BillingsCargoOrderListingExternalOrderIdFilter,
  BillingsCargoOrderListingInternalOrderIdFilter,
  BillingsCargoOrderListingOrderStartDateFilter,
  BillingsCargoOrderListingPassengerFilter,
  BillingsCargoOrderListingRouteAddressFilter,
  BillingsCargoOrderListingRouteDestinationAddressFilter,
  BillingsCargoOrderListingRouteIntermediateAddressFilter,
  BillingsCargoOrderListingRoutePickupAddressFilter,
  BillingsCargoOrderListingTaxiFilter,
} from "./types/billings-cargo-order-listing-filter";
import BillingsCargoOrderListingFilterType from "./types/billings-cargo-order-listing-filter-type";
import BillingsCargoOrderListingSortKey from "./types/billings-cargo-order-listing-sort-key";

const createRequestOrder = (
  sortKey: BillingsCargoOrderListingSortKey | null
): BillingsCargoOrderListingReportRequest["order"] => {
  const options: {
    sortKey: BillingsCargoOrderListingSortKey;
    requestOrder: BillingsCargoOrderListingReportRequestOrder;
  }[] = [
    {
      requestOrder: BillingsCargoOrderListingReportRequestOrder.DATE_DESC,
      sortKey: BillingsCargoOrderListingSortKey.ORDER_START_DATE_DESC,
    },
    {
      requestOrder: BillingsCargoOrderListingReportRequestOrder.DATE_ASC,
      sortKey: BillingsCargoOrderListingSortKey.ORDER_START_DATE_ASC,
    },
    {
      requestOrder: BillingsCargoOrderListingReportRequestOrder.DISTANCE_DESC,
      sortKey: BillingsCargoOrderListingSortKey.DISTANCE_DESC,
    },
    {
      requestOrder: BillingsCargoOrderListingReportRequestOrder.DISTANCE_ASC,
      sortKey: BillingsCargoOrderListingSortKey.DISTANCE_ASC,
    },
    {
      requestOrder:
        BillingsCargoOrderListingReportRequestOrder.HIGHWAY_CHARGE_DESC,
      sortKey: BillingsCargoOrderListingSortKey.TOLL_ROADS_COST_DESC,
    },
    {
      requestOrder:
        BillingsCargoOrderListingReportRequestOrder.HIGHWAY_CHARGE_ASC,
      sortKey: BillingsCargoOrderListingSortKey.TOLL_ROADS_COST_ASC,
    },
    {
      requestOrder: BillingsCargoOrderListingReportRequestOrder.HUMAN_ID_ASC,
      sortKey: BillingsCargoOrderListingSortKey.INTERNAL_ORDER_ID_ASC,
    },
    {
      requestOrder: BillingsCargoOrderListingReportRequestOrder.HUMAN_ID_DESC,
      sortKey: BillingsCargoOrderListingSortKey.INTERNAL_ORDER_ID_DESC,
    },
    {
      requestOrder:
        BillingsCargoOrderListingReportRequestOrder.TAXI_CORPORATION_ASC,
      sortKey: BillingsCargoOrderListingSortKey.TAXI_CORPORATION_ASC,
    },
    {
      requestOrder:
        BillingsCargoOrderListingReportRequestOrder.TAXI_CORPORATION_DESC,
      sortKey: BillingsCargoOrderListingSortKey.TAXI_CORPORATION_DESC,
    },
    {
      requestOrder:
        BillingsCargoOrderListingReportRequestOrder.EXTERNAL_ORDER_ID_ASC,
      sortKey: BillingsCargoOrderListingSortKey.EXTERNAL_ORDER_ID_ASC,
    },
    {
      requestOrder:
        BillingsCargoOrderListingReportRequestOrder.EXTERNAL_ORDER_ID_DESC,
      sortKey: BillingsCargoOrderListingSortKey.EXTERNAL_ORDER_ID_DESC,
    },
    {
      requestOrder: BillingsCargoOrderListingReportRequestOrder.DISPATCH_ASC,
      sortKey: BillingsCargoOrderListingSortKey.DISPATCH_ASC,
    },
    {
      requestOrder: BillingsCargoOrderListingReportRequestOrder.DISPATCH_DESC,
      sortKey: BillingsCargoOrderListingSortKey.DISPATCH_DESC,
    },
    {
      requestOrder:
        BillingsCargoOrderListingReportRequestOrder.DISTANCE_AMOUNT_ASC,
      sortKey: BillingsCargoOrderListingSortKey.DISTANCE_COST_ASC,
    },
    {
      requestOrder:
        BillingsCargoOrderListingReportRequestOrder.DISTANCE_AMOUNT_DESC,
      sortKey: BillingsCargoOrderListingSortKey.DISTANCE_COST_DESC,
    },
    {
      requestOrder:
        BillingsCargoOrderListingReportRequestOrder.HALTING_HOURS_ASC,
      sortKey: BillingsCargoOrderListingSortKey.STOPOVER_TIME_ASC,
    },
    {
      requestOrder:
        BillingsCargoOrderListingReportRequestOrder.HALTING_HOURS_DESC,
      sortKey: BillingsCargoOrderListingSortKey.STOPOVER_TIME_DESC,
    },
    {
      requestOrder:
        BillingsCargoOrderListingReportRequestOrder.HALTING_AMOUNT_ASC,
      sortKey: BillingsCargoOrderListingSortKey.STOPOVER_COST_ASC,
    },
    {
      requestOrder:
        BillingsCargoOrderListingReportRequestOrder.HALTING_AMOUNT_DESC,
      sortKey: BillingsCargoOrderListingSortKey.STOPOVER_COST_DESC,
    },
    {
      requestOrder:
        BillingsCargoOrderListingReportRequestOrder.HIGHWAY_CHARGE_ASC,
      sortKey: BillingsCargoOrderListingSortKey.TOLL_ROADS_COST_ASC,
    },
    {
      requestOrder:
        BillingsCargoOrderListingReportRequestOrder.HIGHWAY_CHARGE_DESC,
      sortKey: BillingsCargoOrderListingSortKey.TOLL_ROADS_COST_DESC,
    },
    {
      requestOrder: BillingsCargoOrderListingReportRequestOrder.DISCOUNT_ASC,
      sortKey: BillingsCargoOrderListingSortKey.DISCOUNT_SAVINGS_ASC,
    },
    {
      requestOrder: BillingsCargoOrderListingReportRequestOrder.DISCOUNT_DESC,
      sortKey: BillingsCargoOrderListingSortKey.DISCOUNT_SAVINGS_DESC,
    },
    {
      requestOrder:
        BillingsCargoOrderListingReportRequestOrder.DISCOUNT_AMOUNT_ASC,
      sortKey: BillingsCargoOrderListingSortKey.DISCOUNT_AMOUNT_ASC,
    },
    {
      requestOrder:
        BillingsCargoOrderListingReportRequestOrder.DISCOUNT_AMOUNT_DESC,
      sortKey: BillingsCargoOrderListingSortKey.DISCOUNT_AMOUNT_DESC,
    },
    {
      requestOrder:
        BillingsCargoOrderListingReportRequestOrder.TOTAL_AMOUNT_ASC,
      sortKey: BillingsCargoOrderListingSortKey.TOTAL_COST_ASC,
    },
    {
      requestOrder:
        BillingsCargoOrderListingReportRequestOrder.TOTAL_AMOUNT_DESC,
      sortKey: BillingsCargoOrderListingSortKey.TOTAL_COST_DESC,
    },
  ];

  return options.find((option) => option.sortKey === sortKey)?.requestOrder;
};

const createRequest = (
  filters: BillingsCargoOrderListingFilter[],
  sortKey: BillingsCargoOrderListingSortKey | null
): BillingsCargoOrderListingReportRequest => {
  return {
    order: createRequestOrder(sortKey),
    human_id: filters.find(
      (filter) =>
        filter.type === BillingsCargoOrderListingFilterType.INTERNAL_ORDER_ID
    )?.value as
      | BillingsCargoOrderListingInternalOrderIdFilter["value"]
      | undefined,
    address: filters.find(
      (filter) =>
        filter.type === BillingsCargoOrderListingFilterType.ROUTE_ADDRESS
    )?.value as
      | BillingsCargoOrderListingRouteAddressFilter["value"]
      | undefined,
    start_address: filters.find(
      (filter) =>
        filter.type === BillingsCargoOrderListingFilterType.ROUTE_PICKUP_ADDRESS
    )?.value as
      | BillingsCargoOrderListingRoutePickupAddressFilter["value"]
      | undefined,
    mid_address: filters.find(
      (filter) =>
        filter.type ===
        BillingsCargoOrderListingFilterType.ROUTE_INTERMEDIATE_ADDRESS
    )?.value as
      | BillingsCargoOrderListingRouteIntermediateAddressFilter["value"]
      | undefined,
    end_address: filters.find(
      (filter) =>
        filter.type ===
        BillingsCargoOrderListingFilterType.ROUTE_DESTINATION_ADDRESS
    )?.value as
      | BillingsCargoOrderListingRouteDestinationAddressFilter["value"]
      | undefined,
    passenger: filters.find(
      (filter) => filter.type === BillingsCargoOrderListingFilterType.PASSENGER
    )?.value as BillingsCargoOrderListingPassengerFilter["value"] | undefined,
    taxi: filters.find(
      (filter) => filter.type === BillingsCargoOrderListingFilterType.TAXI
    )?.value as BillingsCargoOrderListingTaxiFilter["value"] | undefined,
    external_id: filters.find(
      (filter) =>
        filter.type === BillingsCargoOrderListingFilterType.EXTERNAL_ORDER_ID
    )?.value as
      | BillingsCargoOrderListingExternalOrderIdFilter["value"]
      | undefined,
    dispatch: filters.find(
      (filter) => filter.type === BillingsCargoOrderListingFilterType.DISPATCH
    )?.value as BillingsCargoOrderListingDispatchFilter["value"] | undefined,
    status: filters.find(
      (filter) =>
        filter.type === BillingsCargoOrderListingFilterType.BILLING_STATUS
    )?.value as
      | BillingsCargoOrderListingBillingStatusFilter["value"]
      | undefined,
    date_since: (
      filters.find(
        (filter) =>
          filter.type === BillingsCargoOrderListingFilterType.ORDER_START_DATE
      )?.value as
        | BillingsCargoOrderListingOrderStartDateFilter["value"]
        | undefined
    )?.from.toJSON(),
    date_to: (
      filters.find(
        (filter) =>
          filter.type === BillingsCargoOrderListingFilterType.ORDER_START_DATE
      )?.value as
        | BillingsCargoOrderListingOrderStartDateFilter["value"]
        | undefined
    )?.to.toJSON(),
  };
};

const billingsCargoOrderListingReportRequestFactory = {
  createRequest,
};

export default billingsCargoOrderListingReportRequestFactory;
