import orderTranslationsHelper from "../../../../languages/order-translations.helper";
import OrderAbandonedListingTableColumn from "../common/types/order-abandoned-listing-table-column";

const getColumns = (): OrderAbandonedListingTableColumn[] => {
  const translations =
    orderTranslationsHelper.getAbandonedListingTranslations().table.headers;

  const tableColumns: OrderAbandonedListingTableColumn[] = [
    {
      header: translations.startTimeLabel,
      title: translations.startTimeTitle,
      accessor: "date",
      colSpan: 10,
    },
    {
      header: translations.internalOrderIdLabel,
      title: translations.internalOrderIdTitle,
      accessor: "cargoCompanyOrderId",
      colSpan: 8,
    },
    {
      header: translations.externalOrderIdLabel,
      title: translations.externalOrderIdTitle,
      accessor: "cargoCompanyExternalOrderId",
      colSpan: 8,
    },
    {
      header: translations.routeLabel,
      title: translations.routeTitle,
      accessor: "route",
      colSpan: 35,
    },
    {
      header: translations.passengersLabel,
      title: translations.passengersTitle,
      accessor: "passenger",
      colSpan: 10,
    },
    {
      header: translations.dispatchLabel,
      title: translations.dispatchTitle,
      accessor: "dispatch",
      colSpan: 8,
    },
    {
      header: translations.clientLabel,
      title: translations.clientTitle,
      accessor: "client",
      colSpan: 8,
    },
  ];

  return tableColumns;
};

const orderAbandonedListingTableHelper = {
  getColumns,
};

export default orderAbandonedListingTableHelper;
