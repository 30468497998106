import { useEffect, useMemo, useState } from "react";
import OrderOptimizerDetailsBreadcrumbsParams from "../common/breadcrumbs/types/order-optimizer-details-breadcrumbs-params";
import { useParams } from "react-router-dom";
import { useAppContext } from "../../../context/app.context";
import orderOptimizerBreadcrumbsHelper from "../common/breadcrumbs/order-optimizer-breadcrumbs.helper";
import appTranslationsHelper from "../../../languages/app-translations.helper";
import useDocumentTitle from "../../../common/hooks/use-document-title";
import MapRoute from "../../../common/components/map/types/map-route";
import useOrderOptimizerRoadRoute from "../../../common/services/order-optimizer/road-route/use-mileage-road-route";
import MapMarker from "../../../common/components/map/types/map-marker";
import orderOptimizerDetailsMapMarkersFactory from "./common/order-optimizer-details-map-markers.factory";
import orderOptimizerDetailsWaypointFactory from "./common/order-optimizer-details-waypoint.factory";
import HeadingComponent from "../../../common/components/heading/heading.component";
import CardComponent from "../../../common/components/card/card.component";
import MapComponent from "../../../common/components/map/map.component";
import ButtonComponent from "../../../common/components/button/button.component";
import orderOptimizerDetailsHelper from "./order-optimizer-details.helper";
import OrderOptimizerRoadRouteLoadParams from "../../../common/services/order-optimizer/road-route/order-optimizer-road-route-load-params";
import orderOptimizerDetailsRouteFactory from "./common/order-optimizer-details-route.factory";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import OrderOptimizerDetailsItem from "./common/types/order-optimizer-details-item";
import orderOptimizerTranslationsHelper from "../../../languages/order-optimizer-translations.helper";
import OrderOptimizerDetailsOrdersTableComponent from "./orders-table/order-optimizer-details-orders-table.component";
import OrderOptimizerDetailsWithoutDriverTableComponent from "./without-driver-table/order-optimizer-details-without-driver-table.component";

const OrderOptimizerDetailsComponent = () => {
  const [waypoints, setWaypoints] = useState<
    { humanId: number; lat: number; lon: number }[]
  >([]);
  const [mapRoutes, setMapRoutes] = useState<MapRoute[]>([]);

  const [detailsExpanded, setDetailsExpanded] = useState(false); //TODO zmiana klikniecia przycisku - na trzecim etapie

  const [detailsItem, setDetailsItem] = useState<OrderOptimizerDetailsItem>();

  const { id } = useParams<OrderOptimizerDetailsBreadcrumbsParams>();
  const { selectedAppLanguage, setBreadcrumbs } = useAppContext();
  const roadRoute = useOrderOptimizerRoadRoute();

  useEffect(() => {
    const breadcrumbs = orderOptimizerBreadcrumbsHelper.getDetailsBreadcrumbs({
      id: id!,
    });
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  const translations =
    orderOptimizerTranslationsHelper.getDetailsTranslations();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(
    documentTitleTranslations.orderOptimizerDetails.replace("#{id}", id!)
  );

  const mapMarkers: MapMarker[] = useMemo(() => {
    const mapMarkers =
      orderOptimizerDetailsMapMarkersFactory.createMapMarkers(waypoints);
    return mapMarkers;
  }, [waypoints]);

  useEffect(() => {
    if(!detailsItem){
      return
    }

    const preparedWaypoints =
      orderOptimizerDetailsWaypointFactory.createWaypoints(detailsItem);
    setWaypoints(preparedWaypoints.flat());

    const loadParams: OrderOptimizerRoadRouteLoadParams[] = [];

    preparedWaypoints.forEach((preparedWaypointsRoute) => {
      if (preparedWaypointsRoute.length > 1) {
        const request =
          orderOptimizerDetailsWaypointFactory.createSearchRoutingRequest(
            preparedWaypointsRoute
          );
        loadParams.push(request);
      }
    });

    roadRoute.load(loadParams);
  }, [detailsItem]);

  useEffect(() => {
    if (!roadRoute.data) return;

    const colors = [
      "#4ea015",
      "#7236ffcc",
      "#0c55ba",
      "#00c9b7",
      "#d1a111",
      "#f38722",
    ];
    
    const routes = roadRoute.data.map((route, index) =>
      orderOptimizerDetailsRouteFactory.createMapRoute(
        route.coordinates,
        colors[index]
      )
    );
    setMapRoutes(routes);
  }, [roadRoute.data]);

  return (
    <>
      <HeadingComponent title={orderOptimizerDetailsHelper.getHeadingText(id!)} />
      <CardComponent>
        <div className="order_optimizer_details">
          <div className="order_optimizer_details__left">
            <div className="order_optimizer_details_map_wrapper">
              <MapComponent markers={mapMarkers} autoFit routes={mapRoutes} />
            </div>
            {detailsExpanded ? (
              <ButtonComponent
                onClick={() => {}}
                classNames={{ root: "order_optimizer_details__left__button" }}
                title={translations.hideDetailsButtonTitle}
                type="primary"
              >                
              {translations.hideDetailsButtonLabel}
              <FontAwesomeIcon icon={faAngleUp} className="order_optimizer_details__left__button__icon" />
              </ButtonComponent>
            ) : (      
            <ButtonComponent
              onClick={() => {}}
              classNames={{ root: "order_optimizer_details__left__button" }}
              title={translations.showDetailsButtonTitle}
              type="primary"
            >                
            {translations.showDetailsButtonLabel}
            <FontAwesomeIcon icon={faAngleDown} className="order_optimizer_details__left__button__icon" />
            </ButtonComponent> 
          )}      
          </div>
          </div>
          <div className="order_optimizer_details__right">
            <div className="order_optimizer_details__right__info">
              <div className="order_optimizer_details__right__info__left"> {/* TODO usunac "!" jak bedzie backend */}
                <h1 className="order_optimizer_details__right__info__left__text">{translations.table.scoreLabel} {detailsItem!.score}</h1>
                {/*<h1 className="order_optimizer_details__right__info__left__text">{translations.table.idLabel} {id}</h1>*/}
              </div>
              <div className="order_optimizer_details__right__info__right">
                <div
                  className="order_optimizer_details__right__info__right__badge"
                  title={`${detailsItem!.reviewedBy}, ${orderOptimizerDetailsHelper.getDateLabel(detailsItem!.reviewedAt)}`}
                >
                  {detailsItem!.reviewedBy}, {orderOptimizerDetailsHelper.getDateLabel(detailsItem!.reviewedAt)}
                </div>
              </div>
            </div>
            <div className="order_optimizer_details_orders_table">
              <OrderOptimizerDetailsOrdersTableComponent
                detailsOrderItems={detailsItem!.orders} //temporary "!"
              />
            </div>
            <div className="order_optimizer_details_without_driver_table">
              <OrderOptimizerDetailsWithoutDriverTableComponent 
                detailsOptimizedStatsItem={detailsItem!.optimizedStats} //temporary "!"
                detailsUnoptimizedStatsItem={detailsItem!.unoptimizedStats} //temporary "!"
              />
            </div>
            <div>
              TODO THIRD TABLE
            </div>
          </div>
      </CardComponent>
    </>
  );
};

export default OrderOptimizerDetailsComponent;