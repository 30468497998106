import DriverListRequest, {
  DriverListRequestStatusCriteria,
  DriverListRequestOrder,
} from "../../../api/raily/driver/list/driver-list.request";
import UserStatus from "../../../types/user-status";
import paginationService from "../../../utils/pagination/pagination.service";
import DriverListLoadParams, {
  DriverListLoadParamsOrder,
} from "./driver-list-load-params";

const createStatusCriteria = (
  status: UserStatus | undefined
): DriverListRequestStatusCriteria => {
  switch (status) {
    case UserStatus.ACTIVE:
      return DriverListRequestStatusCriteria.ACTIVE;
    case UserStatus.DELETED:
      return DriverListRequestStatusCriteria.DELETED;
    default:
      return DriverListRequestStatusCriteria.ALL;
  }
};

const createOrder = (
  status: DriverListLoadParamsOrder | undefined
): DriverListRequestOrder | undefined => {
  switch (status) {
    case DriverListLoadParamsOrder.FIRST_NAME_ASC:
      return DriverListRequestOrder.FIRST_NAME_ASC;
    case DriverListLoadParamsOrder.FIRST_NAME_DESC:
      return DriverListRequestOrder.FIRST_NAME_DESC;
    case DriverListLoadParamsOrder.FLEET_PARTNER_NAME_ASC:
      return DriverListRequestOrder.FLEET_PARTNER_NAME_ASC;
    case DriverListLoadParamsOrder.FLEET_PARTNER_NAME_DESC:
      return DriverListRequestOrder.FLEET_PARTNER_NAME_DESC;
    case DriverListLoadParamsOrder.LAST_NAME_ASC:
      return DriverListRequestOrder.LAST_NAME_ASC;
    case DriverListLoadParamsOrder.LAST_NAME_DESC:
      return DriverListRequestOrder.LAST_NAME_DESC;
    case DriverListLoadParamsOrder.TAXI_CORPORATION_DISPLAY_NAME_ASC:
      return DriverListRequestOrder.TAXI_CORPORATION_DISPLAY_NAME_ASC;
    case DriverListLoadParamsOrder.TAXI_CORPORATION_DISPLAY_NAME_DESC:
      return DriverListRequestOrder.TAXI_CORPORATION_DISPLAY_NAME_DESC;
    case DriverListLoadParamsOrder.LAST_PLAN_ENTRY_END_TIME_ASC:
      return DriverListRequestOrder.LAST_PLAN_ENTRY_END_TIME_ASC;
    case DriverListLoadParamsOrder.LAST_PLAN_ENTRY_END_TIME_DESC:
      return DriverListRequestOrder.LAST_PLAN_ENTRY_END_TIME_DESC;
    default:
      return undefined;
  }
};

const create = (params: DriverListLoadParams): DriverListRequest => {
  return {
    address: params.address,
    creationStart: params.creationDateFrom,
    creationEnd: params.creationDateTo,
    email: params.email,
    firstName: params.firstName,
    fleetPartner: params.fleetPartner,
    fleetPartnerId: params.fleetPartnerUuid,
    lastName: params.lastName,
    limit: params.pageSize,
    offset: paginationService.calculateOffset(params.page, params.pageSize),
    order: createOrder(params.order),
    pendingCandidature: params.isCandidaturePending,
    statusCriteria: createStatusCriteria(params.status),
    taxiCorporation: params.taxiCorporation,
    taxiCorporationId: params.taxiCorporationUuid,
    username: params.username,
  };
};

const driverListRequestFactory = { create };

export default driverListRequestFactory;
