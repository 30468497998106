import isEqual from "lodash/isEqual";
import BillingsTaxiUnbilledPrivateOrderListingFilterSelectOption, {
  BillingsTaxiUnbilledPrivateOrderListingInternalOrderIdFilterSelectOption,
  BillingsTaxiUnbilledPrivateOrderListingStatusFilterSelectOption,
} from "../types/billings-taxi-unbilled-private-order-listing-filter-select-option";
import BillingsTaxiUnbilledPrivateOrderListingFilterType from "../types/billings-taxi-unbilled-private-order-listing-filter-type";
import BillingsTaxiUnbilledPrivateOrderListingItemStatus from "../types/billings-taxi-unbilled-private-order-listing-item-status";
import billingsTaxiUnbilledPrivateOrderListingHelper from "../billings-taxi-unbilled-private-order-listing.helper";
import BillingsTaxiUnbilledPrivateOrderListingFilter from "../types/billings-taxi-unbilled-private-order-listing-filter";
import billingsTranslationsHelper from "../../../../../../languages/billings-translations.helper";

const getSearchQueryOptions = (
  query: string
): BillingsTaxiUnbilledPrivateOrderListingFilterSelectOption[] => {
  if (!query) {
    return [];
  }

  const searchFilterTranslations =
    billingsTranslationsHelper.getTaxiUnbilledPrivateOrderBillingsListingTranslations()
      .filters.search;

  const parseStringToNumber = (string: string): number | undefined => {
    const stringAsNumber = Number(string);

    if (Number.isNaN(stringAsNumber)) {
      return undefined;
    }

    return stringAsNumber;
  };

  const getSearchByClientLabel = (query: string) => {
    return searchFilterTranslations.searchByClientTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByExcludeClientLabel = (query: string) => {
    return searchFilterTranslations.searchByExcludeClientTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByDispatchLabel = (query: string) => {
    return searchFilterTranslations.searchByDispatchTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByDriverLabel = (query: string) => {
    return searchFilterTranslations.searchByDriverTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByExternalOrderIdLabel = (query: string) => {
    return searchFilterTranslations.searchByExternalOrderIdTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByInternalOrderIdLabel = (query: string) => {
    return searchFilterTranslations.searchByInternalOrderIdTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByPassengerLabel = (query: string) => {
    return searchFilterTranslations.searchByPassengerTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByRouteAddressLabel = (query: string) => {
    return searchFilterTranslations.searchByRouteAddressTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByRouteDestinationAddressLabel = (query: string) => {
    return searchFilterTranslations.searchByRouteDestinationAddressTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByRouteIntermediateAddressLabel = (query: string) => {
    return searchFilterTranslations.searchByRouteIntermediateAddressTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByRoutePickupAddressLabel = (query: string) => {
    return searchFilterTranslations.searchByRoutePickupAddressTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const stringOptions = [
    {
      label: getSearchByClientLabel(query),
      value: {
        type: BillingsTaxiUnbilledPrivateOrderListingFilterType.CLIENT,
        value: query,
      },
    },
    {
      label: getSearchByExcludeClientLabel(query),
      value: {
        type: BillingsTaxiUnbilledPrivateOrderListingFilterType.EXCLUDE_CARGO_COMPANY,
        value: query,
      },
    },
    {
      label: getSearchByDispatchLabel(query),
      value: {
        type: BillingsTaxiUnbilledPrivateOrderListingFilterType.DISPATCH,
        value: query,
      },
    },
    {
      label: getSearchByDriverLabel(query),
      value: {
        type: BillingsTaxiUnbilledPrivateOrderListingFilterType.DRIVER,
        value: query,
      },
    },
    {
      label: getSearchByExternalOrderIdLabel(query),
      value: {
        type: BillingsTaxiUnbilledPrivateOrderListingFilterType.EXTERNAL_ORDER_ID,
        value: query,
      },
    },
    {
      label: getSearchByPassengerLabel(query),
      value: {
        type: BillingsTaxiUnbilledPrivateOrderListingFilterType.PASSENGER,
        value: query,
      },
    },
    {
      label: getSearchByRouteAddressLabel(query),
      value: {
        type: BillingsTaxiUnbilledPrivateOrderListingFilterType.ROUTE_ADDRESS,
        value: query,
      },
    },
    {
      label: getSearchByRouteDestinationAddressLabel(query),
      value: {
        type: BillingsTaxiUnbilledPrivateOrderListingFilterType.ROUTE_DESTINATION_ADDRESS,
        value: query,
      },
    },
    {
      label: getSearchByRouteIntermediateAddressLabel(query),
      value: {
        type: BillingsTaxiUnbilledPrivateOrderListingFilterType.ROUTE_INTERMEDIATE_ADDRESS,
        value: query,
      },
    },
    {
      label: getSearchByRoutePickupAddressLabel(query),
      value: {
        type: BillingsTaxiUnbilledPrivateOrderListingFilterType.ROUTE_PICKUP_ADDRESS,
        value: query,
      },
    },
  ];

  const options: BillingsTaxiUnbilledPrivateOrderListingFilterSelectOption[] = [
    ...stringOptions,
  ] as BillingsTaxiUnbilledPrivateOrderListingFilterSelectOption[];

  if (parseStringToNumber(query) !== undefined) {
    const internalOrderIdFilterOption: BillingsTaxiUnbilledPrivateOrderListingInternalOrderIdFilterSelectOption =
      {
        label: getSearchByInternalOrderIdLabel(query),
        value: {
          type: BillingsTaxiUnbilledPrivateOrderListingFilterType.INTERNAL_ORDER_ID,
          value: parseStringToNumber(query)!,
        },
      };

    options.push(internalOrderIdFilterOption);
  }

  return options;
};

const getSearchStatusOptions = (
  query: string
): BillingsTaxiUnbilledPrivateOrderListingFilterSelectOption[] => {
  const searchFilterTranslations =
    billingsTranslationsHelper.getTaxiUnbilledPrivateOrderBillingsListingTranslations()
      .filters.search;

  const allStatusSelectOptions: BillingsTaxiUnbilledPrivateOrderListingStatusFilterSelectOption[] =
    Object.values(BillingsTaxiUnbilledPrivateOrderListingItemStatus).map(
      (status) => {
        const statusLabel =
          searchFilterTranslations.searchByStatusTemplateText.replace(
            "#{option}",
            billingsTaxiUnbilledPrivateOrderListingHelper.getStatusText(status)
          );

        return {
          label: statusLabel,
          value: {
            type: BillingsTaxiUnbilledPrivateOrderListingFilterType.STATUS,
            value: status,
          },
        };
      }
    );

  if (query) {
    return allStatusSelectOptions.filter((option) =>
      option.label.toLowerCase().includes(query.toLowerCase())
    );
  }

  return allStatusSelectOptions;
};

const getSelectOptionsByQuery = (
  query: string
): BillingsTaxiUnbilledPrivateOrderListingFilterSelectOption[] => {
  const statusOptions = getSearchStatusOptions(query);
  const queryOptions = getSearchQueryOptions(query);

  return [...statusOptions, ...queryOptions];
};

const getSelectOptions = (
  query: string,
  selectedFilters: BillingsTaxiUnbilledPrivateOrderListingFilter[]
): BillingsTaxiUnbilledPrivateOrderListingFilterSelectOption[] => {
  const allFilterOptions = getSelectOptionsByQuery(query);

  const filterOptionsWithoutSelected = allFilterOptions.filter((option) => {
    return !selectedFilters.find((selectedFilter) =>
      isEqual(selectedFilter.type, option.value.type)
    );
  });

  return filterOptionsWithoutSelected;
};

const billingsTaxiUnbilledPrivateOrderListingFilterHelper = {
  getSelectOptions,
};

export default billingsTaxiUnbilledPrivateOrderListingFilterHelper;
