import UserRole from "../../../../../../common/types/user-role";
import UserPermissions from "../../../../../../common/utils/user/permissions/user-permissions";
import UserPermissionsDefinition from "../../../../../../common/utils/user/permissions/user-permissions-definition";

export type BillingsTaxiUnbilledRouteListingUserPermissionOption =
  | "hasAccessToTaxiView"
  | "hasAccessToRailyView";

export type BillingsTaxiUnbilledRouteListingUserPermissionsDefinition =
  UserPermissionsDefinition<BillingsTaxiUnbilledRouteListingUserPermissionOption>;

export type BillingsTaxiUnbilledRouteListingUserPermissions =
  UserPermissions<BillingsTaxiUnbilledRouteListingUserPermissionOption>;

const billingsTaxiUnbilledRouteListingUserPermissionDefinition: BillingsTaxiUnbilledRouteListingUserPermissionsDefinition =
  {
    hasAccessToTaxiView: [UserRole.TAXI_OFFICER],
    hasAccessToRailyView: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
  };

export default billingsTaxiUnbilledRouteListingUserPermissionDefinition;
