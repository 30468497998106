import isEqual from "lodash/isEqual";
import BillingsTaxiUnbilledOrderListingFilterSelectOption, {
  BillingsTaxiUnbilledOrderListingInternalOrderIdFilterSelectOption,
  BillingsTaxiUnbilledOrderListingStatusFilterSelectOption,
} from "./types/billings-taxi-unbilled-order-listing-filter-select-option";
import BillingsTaxiUnbilledOrderListingFilterType from "./types/billings-taxi-unbilled-order-listing-filter-type";
import BillingsTaxiUnbilledOrderListingItemStatus from "./types/billings-taxi-unbilled-order-listing-item-status";
import BillingsTaxiUnbilledOrderListingFilter from "./types/billings-taxi-unbilled-order-listing-filter";
import billingsTaxiUnbilledOrderListingHelper from "./billings-taxi-unbilled-order-listing.helper";
import billingsTranslationsHelper from "../../../../../languages/billings-translations.helper";

const getSearchQueryOptions = (
  query: string
): BillingsTaxiUnbilledOrderListingFilterSelectOption[] => {
  if (!query) {
    return [];
  }

  const searchFilterTranslations =
    billingsTranslationsHelper.getTaxiUnbilledOrderBillingsListingTranslations()
      .filters.search;

  const parseStringToNumber = (string: string): number | undefined => {
    const stringAsNumber = Number(string);

    if (Number.isNaN(stringAsNumber)) {
      return undefined;
    }

    return stringAsNumber;
  };

  const getSearchByClientLabel = (query: string) => {
    return searchFilterTranslations.searchByClientTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByDispatchLabel = (query: string) => {
    return searchFilterTranslations.searchByDispatchTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByDriverLabel = (query: string) => {
    return searchFilterTranslations.searchByDriverTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByExternalOrderIdLabel = (query: string) => {
    return searchFilterTranslations.searchByExternalOrderIdTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByInternalOrderIdLabel = (query: string) => {
    return searchFilterTranslations.searchByInternalOrderIdTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByPassengerLabel = (query: string) => {
    return searchFilterTranslations.searchByPassengerTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByRouteAddressLabel = (query: string) => {
    return searchFilterTranslations.searchByRouteAddressTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByRouteDestinationAddressLabel = (query: string) => {
    return searchFilterTranslations.searchByRouteDestinationAddressTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByRouteIntermediateAddressLabel = (query: string) => {
    return searchFilterTranslations.searchByRouteIntermediateAddressTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByRoutePickupAddressLabel = (query: string) => {
    return searchFilterTranslations.searchByRoutePickupAddressTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByTaxiLabel = (query: string) => {
    return searchFilterTranslations.searchByTaxiTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const stringOptions = [
    {
      label: getSearchByClientLabel(query),
      value: {
        type: BillingsTaxiUnbilledOrderListingFilterType.CLIENT,
        value: query,
      },
    },
    {
      label: getSearchByDispatchLabel(query),
      value: {
        type: BillingsTaxiUnbilledOrderListingFilterType.DISPATCH,
        value: query,
      },
    },
    {
      label: getSearchByDriverLabel(query),
      value: {
        type: BillingsTaxiUnbilledOrderListingFilterType.DRIVER,
        value: query,
      },
    },
    {
      label: getSearchByExternalOrderIdLabel(query),
      value: {
        type: BillingsTaxiUnbilledOrderListingFilterType.EXTERNAL_ORDER_ID,
        value: query,
      },
    },
    {
      label: getSearchByPassengerLabel(query),
      value: {
        type: BillingsTaxiUnbilledOrderListingFilterType.PASSENGER,
        value: query,
      },
    },
    {
      label: getSearchByRouteAddressLabel(query),
      value: {
        type: BillingsTaxiUnbilledOrderListingFilterType.ROUTE_ADDRESS,
        value: query,
      },
    },
    {
      label: getSearchByRouteDestinationAddressLabel(query),
      value: {
        type: BillingsTaxiUnbilledOrderListingFilterType.ROUTE_DESTINATION_ADDRESS,
        value: query,
      },
    },
    {
      label: getSearchByRouteIntermediateAddressLabel(query),
      value: {
        type: BillingsTaxiUnbilledOrderListingFilterType.ROUTE_INTERMEDIATE_ADDRESS,
        value: query,
      },
    },
    {
      label: getSearchByRoutePickupAddressLabel(query),
      value: {
        type: BillingsTaxiUnbilledOrderListingFilterType.ROUTE_PICKUP_ADDRESS,
        value: query,
      },
    },
    {
      label: getSearchByTaxiLabel(query),
      value: {
        type: BillingsTaxiUnbilledOrderListingFilterType.TAXI,
        value: query,
      },
    },
  ];

  const options: BillingsTaxiUnbilledOrderListingFilterSelectOption[] = [
    ...stringOptions,
  ] as BillingsTaxiUnbilledOrderListingFilterSelectOption[];

  if (parseStringToNumber(query) !== undefined) {
    const internalOrderIdFilterOption: BillingsTaxiUnbilledOrderListingInternalOrderIdFilterSelectOption =
      {
        label: getSearchByInternalOrderIdLabel(query),
        value: {
          type: BillingsTaxiUnbilledOrderListingFilterType.INTERNAL_ORDER_ID,
          value: parseStringToNumber(query)!,
        },
      };

    options.push(internalOrderIdFilterOption);
  }

  return options;
};

const getSearchStatusOptions = (
  query: string
): BillingsTaxiUnbilledOrderListingFilterSelectOption[] => {
  const searchFilterTranslations =
    billingsTranslationsHelper.getTaxiUnbilledOrderBillingsListingTranslations()
      .filters.search;

  const allStatusSelectOptions: BillingsTaxiUnbilledOrderListingStatusFilterSelectOption[] =
    Object.values(BillingsTaxiUnbilledOrderListingItemStatus).map((status) => {
      const statusLabel =
        searchFilterTranslations.searchByStatusTemplateText.replace(
          "#{option}",
          billingsTaxiUnbilledOrderListingHelper.getStatusText(status)
        );

      return {
        label: statusLabel,
        value: {
          type: BillingsTaxiUnbilledOrderListingFilterType.STATUS,
          value: status,
        },
      };
    });

  if (query) {
    return allStatusSelectOptions.filter((option) =>
      option.label.toLowerCase().includes(query.toLowerCase())
    );
  }

  return allStatusSelectOptions;
};

const getSelectOptionsByQuery = (
  query: string
): BillingsTaxiUnbilledOrderListingFilterSelectOption[] => {
  const statusOptions = getSearchStatusOptions(query);
  const queryOptions = getSearchQueryOptions(query);

  return [...statusOptions, ...queryOptions];
};

const getSelectOptions = (
  query: string,
  selectedFilters: BillingsTaxiUnbilledOrderListingFilter[]
): BillingsTaxiUnbilledOrderListingFilterSelectOption[] => {
  const allFilterOptions = getSelectOptionsByQuery(query);

  const filterOptionsWithoutSelected = allFilterOptions.filter((option) => {
    return !selectedFilters.find((selectedFilter) =>
      isEqual(selectedFilter.type, option.value.type)
    );
  });

  return filterOptionsWithoutSelected;
};

const billingsTaxiUnbilledOrderListingFilterHelper = {
  getSelectOptions,
};

export default billingsTaxiUnbilledOrderListingFilterHelper;
