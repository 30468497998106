import orderTranslationsHelper from "../../../../../languages/order-translations.helper";
import { OrderAbandonedListingPublicStatusesTranslations } from "../../../../../languages/types/order/order-abandoned-listing.translation";
import CargoOrderAbandonedListReportResponse from "../../../../api/raily/cargo-order/abandoned-list/report/cargo-order-abandoned-list-report.response";
import CargoOrderAbandonedListReport, {
  CargoOrderAbandonedListReportPublicStatus,
} from "./cargo-order-abandoned-list-report";

const createPublicStatus = (
  publicStatus: CargoOrderAbandonedListReportPublicStatus,
  publicStatusesTranslations: OrderAbandonedListingPublicStatusesTranslations
): string => {
  switch (publicStatus) {
    case CargoOrderAbandonedListReportPublicStatus.PRIVATE:
      return publicStatusesTranslations.private;
    case CargoOrderAbandonedListReportPublicStatus.PUBLIC:
      return publicStatusesTranslations.public;
    case CargoOrderAbandonedListReportPublicStatus.PUBLISHED:
      return publicStatusesTranslations.published;
    default:
      return "undefined";
  }
};

const create = (
  response: CargoOrderAbandonedListReportResponse
): CargoOrderAbandonedListReport => {
  const translations =
    orderTranslationsHelper.getAbandonedListingTranslations().publicStatuses;

  let reportContent = response.data;

  Object.values(CargoOrderAbandonedListReportPublicStatus).forEach(
    (publicStatus) => {
      reportContent = reportContent.replace(
        new RegExp(`${publicStatus}`, "g"),
        createPublicStatus(publicStatus, translations)
      );
    }
  );

  return {
    data: new Blob([reportContent], { type: "text/csv;charset=utf8" }),
    filename: "",
  };
};

const cargoOrderAbandonedListReportFactory = {
  create,
};

export default cargoOrderAbandonedListReportFactory;
