export enum OrderAddCargoAddressType {
  HOTEL = "HOTEL",
  STATION = "STATION",
  MEETING_POINT = "MEETING_POINT",
}

type OrderAddCargoAddress = {
  uuid: string;
  displayName: string;
  zipCode: string;
  town: string;
  street: string;
  houseNumber: string;
  apartmentNumber: string;
  latitude: number;
  longitude: number;
  type: OrderAddCargoAddressType;
  description: string | null;
};

export default OrderAddCargoAddress;
