import appConfig from "../../../../../../../app.config";
import urlService from "../../../../../../../common/utils/url/url.service";
import authService from "../../../../../../auth/common/auth.service";
import BillingsTaxiUnbilledOrderListingByTaxiRequest from "./billings-taxi-unbilled-order-listing-by-taxi.request";
import BillingsTaxiUnbilledOrderListingByTaxiResponse from "./billings-taxi-unbilled-order-listing-by-taxi.response";

const fetchListing = async (
  taxiCorporationUuid: string,
  request: BillingsTaxiUnbilledOrderListingByTaxiRequest
): Promise<BillingsTaxiUnbilledOrderListingByTaxiResponse> => {
  const path = `${appConfig.baseApiUrl}/taxi-corporations/${taxiCorporationUuid}/orders/not-yet-billed`;
  const url = urlService.buildWithoutEmptyParams(path, request);

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  const r = await fetch(url, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const billingsTaxiUnbilledOrderListingByTaxiApiService = {
  fetchListing,
};

export default billingsTaxiUnbilledOrderListingByTaxiApiService;
