import { FC, useMemo, useState } from "react";
import ListingFilterSelectComponent from "../../../../../../../common/components/listing/filter/select/listing-filter-select.component";
import { useAppContext } from "../../../../../../../context/app.context";
import billingsTaxiUnbilledRouteListingFilterHelper from "../../billings-taxi-unbilled-route-listing-filter.helper";
import BillingsTaxiUnbilledRouteListingFilter from "../../types/billings-taxi-unbilled-route-listing-filter";
import BillingsTaxiUnbilledRouteListingFilterSelectOption from "../../types/billings-taxi-unbilled-route-listing-filter-select-option";

type BillingsTaxiUnbilledRouteListingFiltersSelectProps = {
  filters: BillingsTaxiUnbilledRouteListingFilter[];
  onAddNewFilter: (filter: BillingsTaxiUnbilledRouteListingFilter) => void;
};

const BillingsTaxiUnbilledRouteListingFiltersSelectComponent: FC<
  BillingsTaxiUnbilledRouteListingFiltersSelectProps
> = (props) => {
  const { selectedAppLanguage } = useAppContext();

  const [searchInputValue, setSearchInputValue] = useState("");

  const searchSelectOptions: BillingsTaxiUnbilledRouteListingFilterSelectOption[] =
    useMemo(() => {
      return billingsTaxiUnbilledRouteListingFilterHelper.getSelectOptions(
        searchInputValue,
        props.filters
      );
    }, [searchInputValue, props.filters, selectedAppLanguage]);

  const onFilterSelect = (
    filter: BillingsTaxiUnbilledRouteListingFilterSelectOption
  ) => {
    props.onAddNewFilter(filter.value);
  };

  return (
    <ListingFilterSelectComponent
      onChange={(option) =>
        onFilterSelect(
          option as BillingsTaxiUnbilledRouteListingFilterSelectOption
        )
      }
      options={searchSelectOptions}
      inputValue={searchInputValue}
      onInputChange={setSearchInputValue}
      idForTesting="billings-taxi-unbilled-route-listing-filter"
    />
  );
};

export default BillingsTaxiUnbilledRouteListingFiltersSelectComponent;
