import { FC, useEffect, useMemo, useState } from "react";
import {
  OrderOptimizerListingItem,
  OrderOptimizerListingItemRoute,
} from "../../common/types/order-optimizer-listing-item";
import MapRoute from "../../../../../common/components/map/types/map-route";
import useOrderOptimizerRoadRoute from "../../../../../common/services/order-optimizer/road-route/use-mileage-road-route";
import MapMarker from "../../../../../common/components/map/types/map-marker";
import orderOptimizerListingMapMarkersFactory from "../../common/order-optimizer-listing-map-markers.factory";
import orderOptimizerListingWaypointFactory from "../../common/order-optimizer-listing-waypoint.factory";
import orderOptimizerListingRouteFactory from "../../common/order-optimizer-listing-route.factory";
import MapComponent from "../../../../../common/components/map/map.component";
import { SearchRoadRoutingResponseRouteGeometryCoordinate } from "../../../../../common/utils/search-road-route/search-road-routing.response";
import OrderOptimizerRoadRouteLoadParams from "../../../../../common/services/order-optimizer/road-route/order-optimizer-road-route-load-params";
import orderOptimizerTranslationsHelper from "../../../../../languages/order-optimizer-translations.helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faWrench } from "@fortawesome/free-solid-svg-icons";
import orderOptimizerListingTableRowHelper from "./order-optimizer-listing-table-row.helper";
import LinkButtonComponent from "../../../../../common/components/button/link/link-button.component";
import orderOptimizerRoutesHelper from "../../../common/routes/order-optimizer-routes.helper";

type OrderOptimizerListingTableRowProps = {
  listingItem: OrderOptimizerListingItem;
  selectedHumanId: number | undefined;
};

const OrderOptimizerListingTableRowComponent: FC<
  OrderOptimizerListingTableRowProps
> = (props) => {
  const [waypoints, setWaypoints] = useState<OrderOptimizerListingItemRoute[]>(
    []
  );

  const [mapRoutes, setMapRoutes] = useState<MapRoute[]>([]);

  const roadRoute = useOrderOptimizerRoadRoute();

  const mapMarkers: MapMarker[] = useMemo(() => {
    const mapMarkers =
      orderOptimizerListingMapMarkersFactory.createMapMarkers(waypoints);

    return mapMarkers;
  }, [waypoints]);

  const translations =
  orderOptimizerTranslationsHelper.getListingTranslations().table;

  useEffect(() => {
    if (!props.listingItem) {
      return;
    }

    const preparedWaypoints =
      orderOptimizerListingWaypointFactory.createWaypoints(props.listingItem);

    setWaypoints(preparedWaypoints.flat());

    const loadParams: OrderOptimizerRoadRouteLoadParams[] = [];

    preparedWaypoints.forEach((preparedWaypointsRoute) => {
      if (preparedWaypointsRoute.length > 1) {
        const request =
          orderOptimizerListingWaypointFactory.createSearchRoutingRequest(
            preparedWaypointsRoute
          );
        loadParams.push(request);
      }
    });

    roadRoute.load(loadParams);
  }, [props.listingItem]);

  useEffect(() => {
    if (!roadRoute.data) {
      return;
    }

    const colors = [
      "#4ea015",
      "#7236ffcc",
      "#0c55ba",
      "#00c9b7",
      "#d1a111",
      "#f38722",
    ];

    const routes = roadRoute.data.map((route, index) => {
      const formattedCoordinates: SearchRoadRoutingResponseRouteGeometryCoordinate[] =
        route.coordinates;

      const color = colors[index];
      return orderOptimizerListingRouteFactory.createMapRoute(
        formattedCoordinates,
        color
      );
    });

    setMapRoutes(routes);
  }, [roadRoute.data]);

  return (
      <div className="order_optimizer_table_row">
        <div className="order_optimizer_table_row__left">
          <MapComponent markers={mapMarkers} autoFit routes={mapRoutes} />
        </div>
        <div className="order_optimizer_table_row__right">
          <div className="order_optimizer_table_row__right__upper_section">
            <div className="order_optimizer_table_row__right__upper_section__left">
              <h1 className="order_optimizer_table_row__right__upper_section__text">{translations.scoreLabel} {props.listingItem.score}</h1>
              {/*<h1 className="order_optimizer_table_row__right__upper_section__text">{translations.idLabel} {props.listingItem.id}</h1>*/}
            </div>
            <div className="order_optimizer_table_row__right__upper_section__right">
              {props.listingItem.reviewedBy === null ? (
                <LinkButtonComponent
                  to={orderOptimizerRoutesHelper.getDetailsRoute({id: props.listingItem.id})}
                  type="primary"
                  title={translations.optimizationButtonTitle}
                  classNames={{ root: "order_optimizer_table_row__right__upper_section__right__button" }}
                  openInNewTab
                >
                  <div className="order_optimizer_table_row__text">
                    {translations.optimizationButtonLabel}
                    <FontAwesomeIcon icon={faWrench} className="order_optimizer_table_row__right__upper_section__right__button__icon" />
                  </div>
                </LinkButtonComponent>
              ) : (
                <>
                  <LinkButtonComponent
                    to={orderOptimizerRoutesHelper.getDetailsRoute({id: props.listingItem.id})}
                    type="primary"
                    title={translations.previewOptimizationButtonTitle}
                    classNames={{ root: "order_optimizer_table_row__right__upper_section__right__button_preview" }}
                    openInNewTab
                  >
                  <div className="order_optimizer_table_row__text">
                    {translations.previewOptimizationButtonLabel}
                    <FontAwesomeIcon icon={faEye} className="order_optimizer_table_row__right__upper_section__right__button_preview__icon" />
                  </div>
                  </LinkButtonComponent>
                  <div
                    className="order_optimizer_table_row__right__upper_section__right__badge"
                    title={`${props.listingItem.reviewedBy}, ${orderOptimizerListingTableRowHelper.getDateLabel(props.listingItem.reviewedAt)}`}
                  >
                    {props.listingItem.reviewedBy}, {orderOptimizerListingTableRowHelper.getDateLabel(props.listingItem.reviewedAt)}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="order_optimizer_table_row__lower_section">
            <h3 className="order_optimizer_table_row__right__lower_section__text">{translations.orderNumbersLabel}</h3>
            {props.listingItem.orderHumanIds.map((id) => (
              <div 
                key={id} 
                className={
                  props.selectedHumanId === id ? 'order_optimizer_table_row__lower_section__highlighted' : 'order_optimizer_table_row__lower_section'
                }
              >
                {id}
              </div>
            ))}
          </div>
        </div>
      </div>
  );
};

export default OrderOptimizerListingTableRowComponent;
