import billingsTranslationsHelper from "../../../../../languages/billings-translations.helper";
import BillingsCargoOrderListingSortKey from "./types/billings-cargo-order-listing-sort-key";
import BillingsCargoOrderListingSortSelectOption from "./types/billings-cargo-order-listing-sort-select-option";

const getSelectOptions = (): BillingsCargoOrderListingSortSelectOption[] => {
  const translations =
    billingsTranslationsHelper.getCargoOrderBillingsListingTranslations().sort;

  return [
    {
      label: translations.orderStartDateAscOptionLabel,
      value: BillingsCargoOrderListingSortKey.ORDER_START_DATE_ASC,
    },
    {
      label: translations.orderStartDateDescOptionLabel,
      value: BillingsCargoOrderListingSortKey.ORDER_START_DATE_DESC,
    },
    {
      label: translations.distanceAscOptionLabel,
      value: BillingsCargoOrderListingSortKey.DISTANCE_ASC,
    },
    {
      label: translations.distanceDescOptionLabel,
      value: BillingsCargoOrderListingSortKey.DISTANCE_DESC,
    },
    {
      label: translations.tollRoadsCostAscOptionLabel,
      value: BillingsCargoOrderListingSortKey.TOLL_ROADS_COST_ASC,
    },
    {
      label: translations.tollRoadsCostDescOptionLabel,
      value: BillingsCargoOrderListingSortKey.TOLL_ROADS_COST_DESC,
    },
    {
      label: translations.internalOrderIdAscOptionLabel,
      value: BillingsCargoOrderListingSortKey.INTERNAL_ORDER_ID_ASC,
    },
    {
      label: translations.internalOrderIdDescOptionLabel,
      value: BillingsCargoOrderListingSortKey.INTERNAL_ORDER_ID_DESC,
    },
    {
      label: translations.taxiCorporationAscOptionLabel,
      value: BillingsCargoOrderListingSortKey.TAXI_CORPORATION_ASC,
    },
    {
      label: translations.taxiCorporationDescOptionLabel,
      value: BillingsCargoOrderListingSortKey.TAXI_CORPORATION_DESC,
    },
    {
      label: translations.externalOrderIdAscOptionLabel,
      value: BillingsCargoOrderListingSortKey.EXTERNAL_ORDER_ID_ASC,
    },
    {
      label: translations.externalOrderIdDescOptionLabel,
      value: BillingsCargoOrderListingSortKey.EXTERNAL_ORDER_ID_DESC,
    },
    {
      label: translations.dispatchAscOptionLabel,
      value: BillingsCargoOrderListingSortKey.DISPATCH_ASC,
    },
    {
      label: translations.dispatchDescOptionLabel,
      value: BillingsCargoOrderListingSortKey.DISPATCH_DESC,
    },
    {
      label: translations.baseDistanceRateAscOptionLabel,
      value: BillingsCargoOrderListingSortKey.DISTANCE_RATE_ASC,
    },
    {
      label: translations.baseDistanceRateDescOptionLabel,
      value: BillingsCargoOrderListingSortKey.DISTANCE_RATE_DESC,
    },
    {
      label: translations.amountForDistanceAscOptionLabel,
      value: BillingsCargoOrderListingSortKey.DISTANCE_COST_ASC,
    },
    {
      label: translations.amountForDistanceDescOptionLabel,
      value: BillingsCargoOrderListingSortKey.DISTANCE_COST_DESC,
    },
    {
      label: translations.stopoverTimeAscOptionLabel,
      value: BillingsCargoOrderListingSortKey.STOPOVER_TIME_ASC,
    },
    {
      label: translations.stopoverTimeDescOptionLabel,
      value: BillingsCargoOrderListingSortKey.STOPOVER_TIME_DESC,
    },
    {
      label: translations.stopoverCostAscOptionLabel,
      value: BillingsCargoOrderListingSortKey.STOPOVER_COST_ASC,
    },
    {
      label: translations.stopoverCostDescOptionLabel,
      value: BillingsCargoOrderListingSortKey.STOPOVER_COST_DESC,
    },
    {
      label: translations.discountAmountAscOptionLabel,
      value: BillingsCargoOrderListingSortKey.DISCOUNT_AMOUNT_ASC,
    },
    {
      label: translations.discountAmountDescOptionLabel,
      value: BillingsCargoOrderListingSortKey.DISCOUNT_AMOUNT_DESC,
    },
    {
      label: translations.sumOfDiscountsAscOptionLabel,
      value: BillingsCargoOrderListingSortKey.DISCOUNT_SAVINGS_ASC,
    },
    {
      label: translations.sumOfDiscountsDescOptionLabel,
      value: BillingsCargoOrderListingSortKey.DISCOUNT_SAVINGS_DESC,
    },
    {
      label: translations.totalCostAscOptionLabel,
      value: BillingsCargoOrderListingSortKey.TOTAL_COST_ASC,
    },
    {
      label: translations.totalCostDescOptionLabel,
      value: BillingsCargoOrderListingSortKey.TOTAL_COST_DESC,
    },
  ];
};

const billingsCargoOrderListingSortHelper = {
  getSelectOptions,
};

export default billingsCargoOrderListingSortHelper;
