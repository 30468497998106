import PlanEntryDetailsRequest from "../../../api/raily/plan-entry/details/plan-entry-details.request";
import PlanEntryDetailsLoadParams from "./plan-entry-details-load-params";

const create = (
  params: PlanEntryDetailsLoadParams
): PlanEntryDetailsRequest => {
  return {
    planEntryUuid: params.planEntryUuid,
  };
};

const PlanEntryDetailsRequestFactory = {
  create,
};

export default PlanEntryDetailsRequestFactory;
