import billingsTranslationsHelper from "../../../../../../languages/billings-translations.helper";
import BillingsTaxiOrderListingStatsSummaryTableColumn from "../types/billings-taxi-order-listing-stats-summary-table-column";

const getColumns = (): BillingsTaxiOrderListingStatsSummaryTableColumn[] => {
  const translations =
    billingsTranslationsHelper.getTaxiOrderBillingsListingTranslations().stats
      .table.headers;

  const tableColumns: BillingsTaxiOrderListingStatsSummaryTableColumn[] = [
    {
      header: translations.distanceLabel,
      title: translations.distanceTitle,
      accessor: "distance",
      colSpan: 10,
    },
    {
      header: translations.amountForChargeHaltingsLabel,
      title: translations.amountForChargeHaltingsTitle,
      accessor: "amountForChargeHaltings",
      colSpan: 14,
    },
    {
      header: translations.amountForChargeHighwaysLabel,
      title: translations.amountForChargeHighwaysTitle,
      accessor: "amountForChargeHighways",
      colSpan: 14,
    },
    {
      header: translations.amountForDistanceLabel,
      title: translations.amountForDistanceTitle,
      accessor: "amountForDistance",
      colSpan: 12,
    },
    {
      header: translations.sumOfDiscountsLabel,
      title: translations.sumOfDiscountsTitle,
      accessor: "sumOfDiscounts",
      colSpan: 12,
    },
    {
      header: translations.allContributionsAmountLabel,
      title: translations.allContributionsAmountTitle,
      accessor: "allContributionsAmount",
      colSpan: 14,
    },
  ];

  return tableColumns;
};

const formatNumber = (value: number) => {
  return (+value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
};

const billingsTaxiOrderListingStatsSummaryHelper = {
  formatNumber,
  getColumns,
};

export default billingsTaxiOrderListingStatsSummaryHelper;
