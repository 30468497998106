import { FC } from "react";
import BillingsTaxiTransferredOrderListingFilter from "../../types/billings-taxi-transferred-order-listing-filter";
import billingsTaxiTransferredOrderListingFiltersBadgeListHelper from "./billings-taxi-transferred-order-listing-filters-badge-list.helper";
import ListingFilterBadgeListComponent from "../../../../../../../common/components/listing/filter/badge-list/listing-filter-badge-list.component";

type BillingsTaxiTransferredOrderListingFiltersListProps = {
  filters: BillingsTaxiTransferredOrderListingFilter[];
  onDeleteFilterClick: (filterIndex: number) => void;
  onDeleteAllFiltersButtonClick: () => void;
};

const BillingsTaxiTransferredOrderListingFiltersBadgeListComponent: FC<
  BillingsTaxiTransferredOrderListingFiltersListProps
> = (props) => {
  const badges =
    billingsTaxiTransferredOrderListingFiltersBadgeListHelper.getBadges(
      props.filters
    );

  return (
    <ListingFilterBadgeListComponent
      badges={badges}
      onDeleteAll={props.onDeleteAllFiltersButtonClick}
      onDelete={props.onDeleteFilterClick}
    />
  );
};

export default BillingsTaxiTransferredOrderListingFiltersBadgeListComponent;
