import CargoOrderActiveListRequest from "../../api/raily/cargo-order/active-list/cargo-order-active-list.request";
import cargoOrderApiService from "../../api/raily/cargo-order/cargo-order-api.service";
import CargoOrderFinishedListRequest from "../../api/raily/cargo-order/finished-list/cargo-order-finished-list.request";
import CargoOrderDetailsComplexRequest from "../../api/raily/cargo-order/details-complex/cargo-order-details-complex.request";
import CargoOrderDetailsHandlingLogRequest from "../../api/raily/cargo-order/details-complex/handling-log/cargo-order-details-handling-log.request";
import dataLoadErrorFactory from "../../utils/data-load-error/data-load-error.factory";
import HttpError from "../../utils/http/http.error";
import httpHelper from "../../utils/http/http.helper";
import CargoOrderActiveListError from "./active-list/cargo-order-active-list-error";
import CargoOrderActiveListLoadParams from "./active-list/cargo-order-active-list-load-params";
import cargoOrderActiveListRequestFactory from "./active-list/cargo-order-active-list-request.factory";
import cargoOrderActiveListFactory from "./active-list/cargo-order-active-list.factory";
import CargoOrderFinishedListError from "./finished-list/cargo-order-finished-list-error";
import CargoOrderFinishedListLoadParams from "./finished-list/cargo-order-finished-list-load-params";
import cargoOrderFinishedListRequestFactory from "./finished-list/cargo-order-finished-list-request.factory";
import cargoOrderFinishedListFactory from "./finished-list/cargo-order-finished-list.factory";
import CargoOrderDetailsComplex from "./details-complex/cargo-order-details-complex";
import CargoOrderDetailsComplexError from "./details-complex/cargo-order-details-complex-error";
import CargoOrderDetailsComplexLoadParams from "./details-complex/cargo-order-details-complex-load-params";
import cargoOrderDetailsComplexRequestFactory from "./details-complex/cargo-order-details-complex-request.factory";
import cargoOrderDetailsComplexFactory from "./details-complex/cargo-order-details-complex.factory";
import CargoOrderDetailsHandlingLogError from "./handling-log/cargo-order-details-handling-log-error";
import CargoOrderDetailsHandlingLogLoadParams from "./handling-log/cargo-order-details-handling-log-load-params";
import cargoOrderDetailsHandlingLogRequestFactory from "./handling-log/cargo-order-details-handling-log-request.factory";
import cargoOrderDetailsHandlingLogFactory from "./handling-log/cargo-order-details-handling-log.factory";
import CargoOrderFinishedListReportLoadParams from "./finished-list/report/cargo-order-finished-list-report-load-params";
import CargoOrderFinishedListReportRequest from "../../api/raily/cargo-order/finished-list/report/cargo-order-finished-list-report-request";
import cargoOrderFinishedListReportRequestFactory from "./finished-list/report/cargo-order-finished-list-report-request.factory";
import cargoOrderFinishedListReportFactory from "./finished-list/report/cargo-order-finished-list-report.factory";
import CargoOrderFinishedListReportError from "./finished-list/report/cargo-order-finished-list-report-error";
import CargoOrderDetailsRequest from "../../api/raily/cargo-order/details/cargo-order-details.request";
import CargoOrderDetails from "./details/cargo-order-details";
import CargoOrderDetailsLoadParams from "./details/cargo-order-details-load-params";
import cargoOrderDetailsRequestFactory from "./details/cargo-order-details-request.factory";
import CargoOrderDetailsError from "./details/cargo-order-details-error";
import cargoOrderDetailsFactory from "./details/cargo-order-details.factory";
import CargoOrderAbandonedListError from "./abandoned-list/cargo-order-abandoned-list-error";
import CargoOrderAbandonedListLoadParams from "./abandoned-list/cargo-order-abandoned-list-load-params";
import cargoOrderAbandonedListRequestFactory from "./abandoned-list/cargo-order-abandoned-list-request.factory";
import cargoOrderAbandonedListFactory from "./abandoned-list/cargo-order-abandoned-list.factory";
import CargoOrderAbandonedListReportError from "./abandoned-list/report/cargo-order-abandoned-list-report-error";
import CargoOrderAbandonedListReportRequest from "../../api/raily/cargo-order/abandoned-list/report/cargo-order-abandoned-list-report.request";
import CargoOrderAbandonedListReportLoadParams from "./abandoned-list/report/cargo-order-abandoned-list-report-load-params";
import cargoOrderAbandonedListReportRequestFactory from "./abandoned-list/report/cargo-order-abandoned-list-report-request.factory";
import cargoOrderAbandonedListReportFactory from "./abandoned-list/report/cargo-order-abandoned-list-report.factory";
import CargoOrderNodeExclusionLoadParams from "./node-exclusion/cargo-order-node-exclusion-load-params";
import CargoOrderNodeExclusion from "./node-exclusion/cargo-order-node-exclusion";
import CargoOrderNodeExclusionRequest from "../../api/raily/cargo-order/node-exclusion/cargo-order-node-exclusion.request";
import cargoOrderNodeExclusionRequestFactory from "./node-exclusion/cargo-order-node-exclusion-request.factory";
import CargoOrderNodeExclusionError from "./node-exclusion/cargo-order-node-exclusion-error";
import cargoOrderNodeExclusionFactory from "./node-exclusion/cargo-order-node-exclusion.factory";

const handleDetailsError = (
  error: HttpError | CargoOrderDetailsComplexError
): CargoOrderDetailsComplexError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getDetailsComplex = async (
  params: CargoOrderDetailsComplexLoadParams,
  abortSignal: AbortSignal
): Promise<CargoOrderDetailsComplex> => {
  const request: CargoOrderDetailsComplexRequest =
    cargoOrderDetailsComplexRequestFactory.create(params);

  try {
    const response = await cargoOrderApiService().getDetailsComplex(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    return cargoOrderDetailsComplexFactory.create(response.data);
  } catch (_error) {
    const error = _error as HttpError | CargoOrderDetailsComplexError;

    throw handleDetailsError(error);
  }
};

const getDetails = async (
  params: CargoOrderDetailsLoadParams,
  abortSignal: AbortSignal
): Promise<CargoOrderDetails> => {
  const request: CargoOrderDetailsRequest =
    cargoOrderDetailsRequestFactory.create(params);

  try {
    const response = await cargoOrderApiService().getDetails(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    return cargoOrderDetailsFactory.create(response.data);
  } catch (_error) {
    const error = _error as HttpError | CargoOrderDetailsError;

    throw handleDetailsError(error);
  }
};

const handleActiveListError = (
  error: HttpError | CargoOrderActiveListError
): CargoOrderActiveListError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getActiveList = async (
  params: CargoOrderActiveListLoadParams,
  abortSignal: AbortSignal
) => {
  const request: CargoOrderActiveListRequest =
    cargoOrderActiveListRequestFactory.create(params);

  try {
    const response = await cargoOrderApiService().getActiveList(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    const cargoOrderActiveList = cargoOrderActiveListFactory.create(response);

    return cargoOrderActiveList;
  } catch (_error) {
    const error = _error as HttpError | CargoOrderActiveListError;

    throw handleActiveListError(error);
  }
};

const handleFinishedListError = (
  error: HttpError | CargoOrderFinishedListError
): CargoOrderFinishedListError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const handleFinishedListReportError = (
  error: HttpError | CargoOrderFinishedListReportError
): CargoOrderFinishedListReportError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getFinishedList = async (
  params: CargoOrderFinishedListLoadParams,
  abortSignal: AbortSignal
) => {
  const request: CargoOrderFinishedListRequest =
    cargoOrderFinishedListRequestFactory.create(params);

  try {
    const response = await cargoOrderApiService().getFinishedList(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    const cargoOrderFinishedList =
      cargoOrderFinishedListFactory.create(response);

    return cargoOrderFinishedList;
  } catch (_error) {
    const error = _error as HttpError | CargoOrderFinishedListError;

    throw handleFinishedListError(error);
  }
};

const getFinishedListReport = async (
  params: CargoOrderFinishedListReportLoadParams,
  abortSignal: AbortSignal
) => {
  const request: CargoOrderFinishedListReportRequest =
    cargoOrderFinishedListReportRequestFactory.create(params);

  try {
    const response = await cargoOrderApiService().getFinishedListReport(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    const cargoOrderFinishedListReport =
      cargoOrderFinishedListReportFactory.create(response);

    return cargoOrderFinishedListReport;
  } catch (_error) {
    const error = _error as HttpError | CargoOrderFinishedListReportError;

    throw handleFinishedListReportError(error);
  }
};

const handleAbandonedListError = (
  error: HttpError | CargoOrderAbandonedListError
): CargoOrderAbandonedListError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getAbandonedList = async (
  params: CargoOrderAbandonedListLoadParams,
  abortSignal: AbortSignal
) => {
  const request = cargoOrderAbandonedListRequestFactory.create(params);

  try {
    const response = await cargoOrderApiService().getAbandonedList(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    return cargoOrderAbandonedListFactory.create(response);
  } catch (_error) {
    const error = _error as HttpError | CargoOrderAbandonedListError;

    throw handleAbandonedListError(error);
  }
};

const handleAbandonedListReportError = (
  error: HttpError | CargoOrderAbandonedListReportError
): CargoOrderAbandonedListReportError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getAbandonedListReport = async (
  params: CargoOrderAbandonedListReportLoadParams,
  abortSignal: AbortSignal
) => {
  const request: CargoOrderAbandonedListReportRequest =
    cargoOrderAbandonedListReportRequestFactory.create(params);

  try {
    const response = await cargoOrderApiService().getAbandonedListReport(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    const cargoOrderAbandonedListReport =
      cargoOrderAbandonedListReportFactory.create(response);

    return cargoOrderAbandonedListReport;
  } catch (_error) {
    const error = _error as HttpError | CargoOrderAbandonedListReportError;

    throw handleAbandonedListReportError(error);
  }
};

const handleHandlingLogError = (
  error: HttpError | CargoOrderDetailsHandlingLogError
): CargoOrderDetailsHandlingLogError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getHandlingLog = async (
  params: CargoOrderDetailsHandlingLogLoadParams,
  abortSignal: AbortSignal
) => {
  const request: CargoOrderDetailsHandlingLogRequest =
    cargoOrderDetailsHandlingLogRequestFactory.create(params);

  try {
    const response = await cargoOrderApiService().getHandlingLog(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    const cargoOrderDetailsHandlingLogData =
      cargoOrderDetailsHandlingLogFactory.create(response);

    return cargoOrderDetailsHandlingLogData;
  } catch (_error) {
    const error = _error as HttpError | CargoOrderDetailsHandlingLogError;

    throw handleHandlingLogError(error);
  }
};

const handleNodeExclusionError = (
  error: HttpError | CargoOrderNodeExclusionError
): CargoOrderNodeExclusionError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getNodeExclusion = async (
  params: CargoOrderNodeExclusionLoadParams,
  abortSignal: AbortSignal
): Promise<CargoOrderNodeExclusion> => {
  const request: CargoOrderNodeExclusionRequest =
    cargoOrderNodeExclusionRequestFactory.create(params);

  try {
    const response = await cargoOrderApiService().getNodeExclusion(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    return cargoOrderNodeExclusionFactory.create(response.data);
  } catch (_error) {
    const error = _error as HttpError | CargoOrderNodeExclusionError;

    throw handleNodeExclusionError(error);
  }
};

const cargoOrderService = {
  getActiveList,
  getFinishedList,
  getFinishedListReport,
  getAbandonedList,
  getAbandonedListReport,
  getDetailsComplex,
  getHandlingLog,
  getDetails,
  getNodeExclusion,
};

export default cargoOrderService;
