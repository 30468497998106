import { FC } from "react";
import TableComponent, {
  TableProps,
} from "../../../../../../common/components/table/table.component";
import { useAppContext } from "../../../../../../context/app.context";
import BillingsCargoOrderListingItem from "../types/billings-cargo-order-listing-item";
import billingsCargoOrderListingUserPermissionsHelper from "../user-permissions/billings-cargo-order-listing-user-permission.helper";
import BillingsCargoOrderListingTableColumn from "../types/billings-cargo-order-listing-table-column";
import billingsCargoOrderListingTableHelper from "./billings-cargo-order-listing-table.helper";
import BillingsCargoOrderListingTableRow from "../types/billings-cargo-order-listing-table-row";
import BillingsCargoOrderListingBillingsAcceptData from "../types/billings-cargo-order-listing-billings-accept-data";

type BillingsCargoOrderListingTableProps = Pick<
  TableProps,
  "isError" | "isLoading"
> & {
  listingItems: BillingsCargoOrderListingItem[];
  isAcceptBillingsEnabled: boolean;
  selectedBillingsAcceptData: BillingsCargoOrderListingBillingsAcceptData[];
  onSelectedBillingsAcceptDataChange: (
    selectedBillingsAcceptData: BillingsCargoOrderListingBillingsAcceptData[]
  ) => void;
};

const BillingsCargoOrderListingTableComponent: FC<
  BillingsCargoOrderListingTableProps
> = (props) => {
  const { user } = useAppContext();
  const userPermissions =
    billingsCargoOrderListingUserPermissionsHelper.getPermissions(user?.roles!);

  const columns: BillingsCargoOrderListingTableColumn[] =
    billingsCargoOrderListingTableHelper.getColumns();

  const rows: BillingsCargoOrderListingTableRow[] =
    billingsCargoOrderListingTableHelper.getRows(
      props.listingItems,
      userPermissions,
      props.isAcceptBillingsEnabled,
      props.selectedBillingsAcceptData,
      props.onSelectedBillingsAcceptDataChange
    );

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isError={props.isError}
      isLoading={props.isLoading}
    />
  );
};

export default BillingsCargoOrderListingTableComponent;
