import appConfig from "../../../../../../../app.config";
import authService from "../../../../../../auth/common/auth.service";
import billingsCargoOrderListingApiService from "../../../common/api/billings-cargo-order-listing-api.service";
import BillingsCargoOrderListingByRailyCargoCompaniesResponse from "./billings-cargo-order-listing-by-raily-cargo-companies.response";

const fetchCargoCompanies =
  (): Promise<BillingsCargoOrderListingByRailyCargoCompaniesResponse> => {
    const path = `/cargo-companies`;
    const url = `${appConfig.baseApiUrl}${path}`;

    const authToken = authService.getAccessToken();

    const init: RequestInit = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      credentials: "include",
    };

    return fetch(url, init).then(async (r) => {
      const responseData = await r.json();
      return {
        status: r.status,
        data: responseData,
      };
    });
  };

const billingsCargoOrderListingByRailyApiService = {
  fetchCargoCompanies,
  fetchListing: billingsCargoOrderListingApiService.fetchListing,
  fetchReport: billingsCargoOrderListingApiService.fetchReport,
  acceptBillings: billingsCargoOrderListingApiService.acceptBillings,
};

export default billingsCargoOrderListingByRailyApiService;
