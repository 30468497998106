import { FC } from "react";
import TableComponent, {
  TableProps,
} from "../../../../../../common/components/table/table.component";
import BillingsTaxiUnbilledTransferredOrderListingItem from "../types/billings-taxi-unbilled-transferred-order-listing-item";
import BillingsTaxiUnbilledTransferredOrderListingTableColumn from "../types/billings-taxi-unbilled-transferred-order-listing-table-column";
import BillingsTaxiUnbilledTransferredOrderListingTableRow from "../types/billings-taxi-unbilled-transferred-order-listing-table-row";
import billingsTaxiUnbilledTransferredOrderListingTableHelper from "./billings-taxi-unbilled-transferred-order-listing-table.helper";

type BillingsTaxiUnbilledTransferredOrderListingTableProps = Pick<
  TableProps,
  "isError" | "isLoading"
> & {
  listingItems: BillingsTaxiUnbilledTransferredOrderListingItem[];
};

const BillingsTaxiUnbilledTransferredOrderListingTableComponent: FC<
  BillingsTaxiUnbilledTransferredOrderListingTableProps
> = (props) => {
  const columns: BillingsTaxiUnbilledTransferredOrderListingTableColumn[] =
    billingsTaxiUnbilledTransferredOrderListingTableHelper.getColumns();

  const rows: BillingsTaxiUnbilledTransferredOrderListingTableRow[] =
    billingsTaxiUnbilledTransferredOrderListingTableHelper.getRows(
      props.listingItems
    );

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isError={props.isError}
      isLoading={props.isLoading}
    />
  );
};

export default BillingsTaxiUnbilledTransferredOrderListingTableComponent;
