import billingsTranslationsHelper from "../../../../../languages/billings-translations.helper";
import BillingsTaxiUnbilledPrivateOrderListingSortKey from "./types/billings-taxi-unbilled-private-order-listing-sort-key";
import BillingsTaxiUnbilledPrivateOrderListingSortSelectOption from "./types/billings-taxi-unbilled-private-order-listing-sort-select-option";

const getSelectOptions =
  (): BillingsTaxiUnbilledPrivateOrderListingSortSelectOption[] => {
    const translations =
      billingsTranslationsHelper.getTaxiUnbilledPrivateOrderBillingsListingTranslations();

    return [
      {
        label: translations.sort.startDateAscOptionLabel,
        value: BillingsTaxiUnbilledPrivateOrderListingSortKey.START_DATE_ASC,
      },
      {
        label: translations.sort.startDateDescOptionLabel,
        value: BillingsTaxiUnbilledPrivateOrderListingSortKey.START_DATE_DESC,
      },
      {
        label: translations.sort.internalOrderIdAscOptionLabel,
        value:
          BillingsTaxiUnbilledPrivateOrderListingSortKey.INTERNAL_ORDER_ID_ASC,
      },
      {
        label: translations.sort.internalOrderIdDescOptionLabel,
        value:
          BillingsTaxiUnbilledPrivateOrderListingSortKey.INTERNAL_ORDER_ID_DESC,
      },
    ];
  };

const billingsTaxiUnbilledPrivateOrderListingSortHelper = {
  getSelectOptions,
};

export default billingsTaxiUnbilledPrivateOrderListingSortHelper;
