import UserRole from "../../../../common/types/user-role";
import RouteItemDefinition from "../../../../routes/types/route-item-definition";
import OrderOptimizerDetailsComponent from "../../details/order-optimizer-details.compoment";
import OrderOptimizerListingComponent from "../../listing/order-optimizer-listing.component";
import orderOptimizerRoutesUrls from "./order-optimizer-routes-urls";

const orderOptimizerRoutesDefinition: RouteItemDefinition[] = [
  {
    path: orderOptimizerRoutesUrls.listing,
    component: <OrderOptimizerListingComponent />,
    userRolesWhitelist: [UserRole.ADMIN, UserRole.OPERATOR],
  },
  {
    path: orderOptimizerRoutesUrls.details,
    component: <OrderOptimizerDetailsComponent />,
    userRolesWhitelist: [UserRole.ADMIN, UserRole.OPERATOR],
  },
];

export default orderOptimizerRoutesDefinition;
