import { CargoOrderAbandonedListItem } from "../../../../common/services/cargo-order/abandoned-list/cargo-order-abandoned-list";
import OrderAbandonedListingItem from "./types/order-abandoned-listing-item";

const createOrderListingItem = (
  responseDataItem: CargoOrderAbandonedListItem
): OrderAbandonedListingItem => {
  return {
    uuid: responseDataItem.uuid,
    orderDate: responseDataItem.startTime,
    routeAddresses: responseDataItem.rideAddresses,
    passengers: responseDataItem.passengers,
    dispatchName: responseDataItem.dispatch,
    producerName: responseDataItem.producer.name,
    cargoCompanyOrderId: responseDataItem.humanId,
    cargoCompanyExternalOrderId: responseDataItem.externalId,
  };
};

const createOrderListingItems = (
  responseDataItems: CargoOrderAbandonedListItem[]
): OrderAbandonedListingItem[] => {
  return responseDataItems.map(createOrderListingItem);
};

const orderAbandonedListingItemFactory = {
  createOrderListingItems,
};

export default orderAbandonedListingItemFactory;
