import { FC } from "react";
import BillingsTaxiUnbilledTransferredOrderListingFilter from "../../types/billings-taxi-unbilled-transferred-order-listing-filter";
import billingsTaxiUnbilledTransferredOrderListingFiltersBadgeListHelper from "./billings-taxi-unbilled-transferred-order-listing-filters-badge-list.helper";
import ListingFilterBadgeListComponent from "../../../../../../../common/components/listing/filter/badge-list/listing-filter-badge-list.component";

type BillingsTaxiUnbilledTransferredOrderListingFiltersListProps = {
  filters: BillingsTaxiUnbilledTransferredOrderListingFilter[];
  onDeleteFilterClick: (filterIndex: number) => void;
  onDeleteAllFiltersButtonClick: () => void;
};

const BillingsTaxiUnbilledTransferredOrderListingFiltersBadgeListComponent: FC<
  BillingsTaxiUnbilledTransferredOrderListingFiltersListProps
> = (props) => {
  const badges =
    billingsTaxiUnbilledTransferredOrderListingFiltersBadgeListHelper.getBadges(
      props.filters
    );

  return (
    <ListingFilterBadgeListComponent
      badges={badges}
      onDeleteAll={props.onDeleteAllFiltersButtonClick}
      onDelete={props.onDeleteFilterClick}
    />
  );
};

export default BillingsTaxiUnbilledTransferredOrderListingFiltersBadgeListComponent;
