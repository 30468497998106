import dateService from "../../../../../common/utils/date/date.service";

const getDateLabel = (date: Date | null): string => {
  if (!date) {
    return "";
  }

  return dateService.formatDateTime(date);
};

const orderOptimizerListingTableRowHelper = { getDateLabel };

export default orderOptimizerListingTableRowHelper;
