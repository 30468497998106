import OrderOptimizerListRequest from "../../../api/raily/order-optimizer/list/order-optimizer-list.request";
import OrderOptimizerListLoadParams from "./order-optimizer-list-load-paramts";

const create = (
  params: OrderOptimizerListLoadParams
): OrderOptimizerListRequest => {
  return {
    limit: params.pageSize,
    orderHumanId: params.orderHumanId,
    offset: params.offset,
  };
};

const orderOptimizerListRequestFactory = { create };

export default orderOptimizerListRequestFactory;
