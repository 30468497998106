import StatusLegendStatuses from "../../../../../common/components/status-legend/types/status-legend-statuses";
import StatusLegendData from "../../../../../common/components/status-legend/types/status-legend-data";
import BillingsCargoOrderListingItemBillingStatus from "./types/billings-cargo-order-listing-item-billing-status";
import { BillingsCargoOrderListingStatusCountersResponseData } from "./api/billings-cargo-order-listing-status-counters.response";
import billingsTranslationsHelper from "../../../../../languages/billings-translations.helper";

const getStatusText = (
  status: BillingsCargoOrderListingItemBillingStatus
): string => {
  const translations =
    billingsTranslationsHelper.getCargoOrderBillingsListingTranslations()
      .status;

  switch (status) {
    case BillingsCargoOrderListingItemBillingStatus.CREATED:
      return translations.CREATED;
    case BillingsCargoOrderListingItemBillingStatus.ACCEPTED:
      return translations.ACCEPTED;
    case BillingsCargoOrderListingItemBillingStatus.REJECTED:
      return translations.REJECTED;
    case BillingsCargoOrderListingItemBillingStatus.REOPENED:
      return translations.REOPENED;
    case BillingsCargoOrderListingItemBillingStatus.REOPEN_REQUEST_SENT:
      return translations.REOPEN_REQUEST_SENT;
    default:
      return "";
  }
};

const getStatusOptions = (
  data: BillingsCargoOrderListingStatusCountersResponseData
): StatusLegendData[] => {
  type ObjKey = keyof typeof data;
  const result = [];

  for (const key in data) {
    const status = key.toUpperCase();

    if (
      data.hasOwnProperty(key) &&
      status !== StatusLegendStatuses.ACCEPTED &&
      status !== StatusLegendStatuses.CREATED
    ) {
      result.push({
        status: status,
        totalCount: data[key as ObjKey],
      });
    }
  }
  return result;
};

const billingsCargoOrderListingHelper = {
  getStatusText,
  getStatusOptions,
};

export default billingsCargoOrderListingHelper;
