import useAsyncData from "../../../hooks/use-async-data";
import cargoOrderService from "../cargo-order.service";
import CargoOrderAbandonedList from "./cargo-order-abandoned-list";
import CargoOrderAbandonedListLoadParams from "./cargo-order-abandoned-list-load-params";

const useCargoOrderAbandonedList = () => {
  const { data, isError, isLoading, setData, setIsError, setIsLoading } =
    useAsyncData<CargoOrderAbandonedList>({
      data: [],
      totalCount: 0,
    });

  const load = async (
    params: CargoOrderAbandonedListLoadParams,
    signal: AbortSignal
  ) => {
    setIsError(false);
    setIsLoading(true);

    try {
      const cargoOrderAbandonedList = await cargoOrderService.getAbandonedList(
        params,
        signal
      );

      setData(cargoOrderAbandonedList);
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    isError,
    isLoading,
    load,
  };
};

export default useCargoOrderAbandonedList;
