import ListingFilterBadge from "../../../../../../../common/components/listing/filter/badge/listing-filter-badge";
import dateService from "../../../../../../../common/utils/date/date.service";
import billingsTranslationsHelper from "../../../../../../../languages/billings-translations.helper";
import billingsCargoOrderListingHelper from "../../billings-cargo-order-listing.helper";
import BillingsCargoOrderListingFilter, {
  BillingsCargoOrderListingBillingStatusFilter,
  BillingsCargoOrderListingDispatchFilter,
  BillingsCargoOrderListingExternalOrderIdFilter,
  BillingsCargoOrderListingInternalOrderIdFilter,
  BillingsCargoOrderListingOrderStartDateFilter,
  BillingsCargoOrderListingPassengerFilter,
  BillingsCargoOrderListingRouteAddressFilter,
  BillingsCargoOrderListingRouteDestinationAddressFilter,
  BillingsCargoOrderListingRouteIntermediateAddressFilter,
  BillingsCargoOrderListingRoutePickupAddressFilter,
  BillingsCargoOrderListingTaxiFilter,
} from "../../types/billings-cargo-order-listing-filter";
import BillingsCargoOrderListingFilterType from "../../types/billings-cargo-order-listing-filter-type";
import BillingsCargoOrderListingItemBillingStatus from "../../types/billings-cargo-order-listing-item-billing-status";

const getStartDateBadgeOption = (
  filter: BillingsCargoOrderListingOrderStartDateFilter
): {
  badge: ListingFilterBadge;
  filter: BillingsCargoOrderListingOrderStartDateFilter;
} => {
  const translations =
    billingsTranslationsHelper.getCargoOrderBillingsListingTranslations()
      .filters;

  const formattedFromDate = dateService.formatDate(filter.value.from);

  const formattedToDate = dateService.formatDate(filter.value.to);

  return {
    badge: {
      text: translations.orderStartDateFilterText
        .replace("#{fromDate}", formattedFromDate)
        .replace("#{toDate}", formattedToDate),
      title: translations.orderStartDateFilterTitle
        .replace("#{fromDate}", formattedFromDate)
        .replace("#{toDate}", formattedToDate),
    },
    filter: {
      type: BillingsCargoOrderListingFilterType.ORDER_START_DATE,
      value: filter.value,
    },
  };
};

const getStatusBadgeOptions = (): {
  badge: ListingFilterBadge;
  filter: BillingsCargoOrderListingBillingStatusFilter;
}[] => {
  const translations =
    billingsTranslationsHelper.getCargoOrderBillingsListingTranslations()
      .filters;

  return Object.values(BillingsCargoOrderListingItemBillingStatus).map(
    (statusFilterValue) => {
      return {
        badge: {
          text: translations.statusFilterText.replace(
            "#{option}",
            billingsCargoOrderListingHelper.getStatusText(statusFilterValue!)
          ),
          title: translations.statusFilterTitle.replace(
            "#{option}",
            billingsCargoOrderListingHelper.getStatusText(statusFilterValue!)
          ),
        },
        filter: {
          type: BillingsCargoOrderListingFilterType.BILLING_STATUS,
          value: statusFilterValue,
        },
      };
    }
  );
};

const getBadgeOptions = (filter: BillingsCargoOrderListingFilter) => {
  const translations =
    billingsTranslationsHelper.getCargoOrderBillingsListingTranslations()
      .filters;

  const options: {
    badge: ListingFilterBadge;
    filter: BillingsCargoOrderListingFilter;
  }[] = [
    {
      badge: {
        text: translations.internalOrderIdFilterText.replace(
          "#{query}",
          filter.value as BillingsCargoOrderListingInternalOrderIdFilter["value"]
        ),
        title: translations.internalOrderIdFilterTitle.replace(
          "#{query}",
          filter.value as BillingsCargoOrderListingInternalOrderIdFilter["value"]
        ),
      },
      filter: {
        type: BillingsCargoOrderListingFilterType.INTERNAL_ORDER_ID,
        value:
          filter.value as BillingsCargoOrderListingInternalOrderIdFilter["value"],
      },
    },
    {
      badge: {
        text: translations.routeAddressFilterText.replace(
          "#{query}",
          filter.value as BillingsCargoOrderListingRouteAddressFilter["value"]
        ),
        title: translations.routeAddressFilterTitle.replace(
          "#{query}",
          filter.value as BillingsCargoOrderListingRouteAddressFilter["value"]
        ),
      },
      filter: {
        type: BillingsCargoOrderListingFilterType.ROUTE_ADDRESS,
        value:
          filter.value as BillingsCargoOrderListingRouteAddressFilter["value"],
      },
    },
    {
      badge: {
        text: translations.routeIntermediateAddressFilterText.replace(
          "#{query}",
          filter.value as BillingsCargoOrderListingRouteIntermediateAddressFilter["value"]
        ),
        title: translations.routeIntermediateAddressFilterTitle.replace(
          "#{query}",
          filter.value as BillingsCargoOrderListingRouteIntermediateAddressFilter["value"]
        ),
      },
      filter: {
        type: BillingsCargoOrderListingFilterType.ROUTE_INTERMEDIATE_ADDRESS,
        value:
          filter.value as BillingsCargoOrderListingRouteIntermediateAddressFilter["value"],
      },
    },
    {
      badge: {
        text: translations.routeDestinationAddressFilterText.replace(
          "#{query}",
          filter.value as BillingsCargoOrderListingRouteDestinationAddressFilter["value"]
        ),
        title: translations.routeDestinationAddressFilterTitle.replace(
          "#{query}",
          filter.value as BillingsCargoOrderListingRouteDestinationAddressFilter["value"]
        ),
      },
      filter: {
        type: BillingsCargoOrderListingFilterType.ROUTE_DESTINATION_ADDRESS,
        value:
          filter.value as BillingsCargoOrderListingRouteDestinationAddressFilter["value"],
      },
    },
    {
      badge: {
        text: translations.routePickupAddressFilterText.replace(
          "#{query}",
          filter.value as BillingsCargoOrderListingRoutePickupAddressFilter["value"]
        ),
        title: translations.routePickupAddressFilterTitle.replace(
          "#{query}",
          filter.value as BillingsCargoOrderListingRoutePickupAddressFilter["value"]
        ),
      },
      filter: {
        type: BillingsCargoOrderListingFilterType.ROUTE_PICKUP_ADDRESS,
        value:
          filter.value as BillingsCargoOrderListingRoutePickupAddressFilter["value"],
      },
    },
    {
      badge: {
        text: translations.passengerFilterText.replace(
          "#{query}",
          String(
            filter.value as BillingsCargoOrderListingPassengerFilter["value"]
          )
        ),
        title: translations.passengerFilterTitle.replace(
          "#{query}",
          String(
            filter.value as BillingsCargoOrderListingPassengerFilter["value"]
          )
        ),
      },
      filter: {
        type: BillingsCargoOrderListingFilterType.PASSENGER,
        value:
          filter.value as BillingsCargoOrderListingPassengerFilter["value"],
      },
    },
    {
      badge: {
        text: translations.externalOrderIdFilterText.replace(
          "#{query}",
          String(
            filter.value as BillingsCargoOrderListingExternalOrderIdFilter["value"]
          )
        ),
        title: translations.externalOrderIdFilterTitle.replace(
          "#{query}",
          String(
            filter.value as BillingsCargoOrderListingExternalOrderIdFilter["value"]
          )
        ),
      },
      filter: {
        type: BillingsCargoOrderListingFilterType.EXTERNAL_ORDER_ID,
        value:
          filter.value as BillingsCargoOrderListingExternalOrderIdFilter["value"],
      },
    },
    {
      badge: {
        text: translations.taxiFilterText.replace(
          "#{query}",
          String(filter.value as BillingsCargoOrderListingTaxiFilter["value"])
        ),
        title: translations.taxiFilterTitle.replace(
          "#{query}",
          String(filter.value as BillingsCargoOrderListingTaxiFilter["value"])
        ),
      },
      filter: {
        type: BillingsCargoOrderListingFilterType.TAXI,
        value: filter.value as BillingsCargoOrderListingTaxiFilter["value"],
      },
    },
    {
      badge: {
        text: translations.dispatchFilterText.replace(
          "#{query}",
          String(
            filter.value as BillingsCargoOrderListingDispatchFilter["value"]
          )
        ),
        title: translations.dispatchFilterTitle.replace(
          "#{query}",
          String(
            filter.value as BillingsCargoOrderListingDispatchFilter["value"]
          )
        ),
      },
      filter: {
        type: BillingsCargoOrderListingFilterType.DISPATCH,
        value: filter.value as BillingsCargoOrderListingDispatchFilter["value"],
      },
    },
    getStartDateBadgeOption(
      filter as BillingsCargoOrderListingOrderStartDateFilter
    ),
    ...getStatusBadgeOptions(),
  ];

  return options;
};

const getBadge = (filter: BillingsCargoOrderListingFilter) => {
  const options = getBadgeOptions(filter);

  return options.find(
    (item) =>
      item.filter.type === filter.type && item.filter.value === filter.value
  )?.badge;
};

const getBadges = (
  filters: BillingsCargoOrderListingFilter[]
): ListingFilterBadge[] => {
  const badges: ListingFilterBadge[] = [];

  filters.forEach((filter) => {
    const badge = getBadge(filter);

    if (!badge) {
      return;
    }

    badges.push(badge);
  });

  return badges;
};

const billingsCargoOrderListingFiltersBadgeListHelper = {
  getBadges,
};

export default billingsCargoOrderListingFiltersBadgeListHelper;
