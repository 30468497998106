import UserRole from "../../../../../../common/types/user-role";
import UserPermissions from "../../../../../../common/utils/user/permissions/user-permissions";
import UserPermissionsDefinition from "../../../../../../common/utils/user/permissions/user-permissions-definition";

export type BillingsCargoOrderListingUserPermissionOption =
  | "hasAccessToCargoView"
  | "hasAccessToRailyView"
  | "hasAccessToEditCargoWithTaxiBilling"
  | "hasAccessToDetailsCargoWithTaxiBilling";

export type BillingsCargoOrderListingUserPermissionsDefinition =
  UserPermissionsDefinition<BillingsCargoOrderListingUserPermissionOption>;

export type BillingsCargoOrderListingUserPermissions =
  UserPermissions<BillingsCargoOrderListingUserPermissionOption>;

const billingsCargoOrderListingUserPermissionDefinition: BillingsCargoOrderListingUserPermissionsDefinition =
  {
    hasAccessToCargoView: [UserRole.CARGO_OFFICER],
    hasAccessToRailyView: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
    hasAccessToEditCargoWithTaxiBilling: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
    ],
    hasAccessToDetailsCargoWithTaxiBilling: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.CARGO_OFFICER,
    ],
  };

export default billingsCargoOrderListingUserPermissionDefinition;
