import Joi from "joi";
import BillingsCargoOrderListingFilter, {
  BillingsCargoOrderListingBillingStatusFilter,
  BillingsCargoOrderListingDispatchFilter,
  BillingsCargoOrderListingExternalOrderIdFilter,
  BillingsCargoOrderListingInternalOrderIdFilter,
  BillingsCargoOrderListingOrderStartDateFilter,
  BillingsCargoOrderListingPassengerFilter,
  BillingsCargoOrderListingRouteAddressFilter,
  BillingsCargoOrderListingRouteDestinationAddressFilter,
  BillingsCargoOrderListingRouteIntermediateAddressFilter,
  BillingsCargoOrderListingRoutePickupAddressFilter,
  BillingsCargoOrderListingTaxiFilter,
} from "./types/billings-cargo-order-listing-filter";
import BillingsCargoOrderListingFilterType from "./types/billings-cargo-order-listing-filter-type";
import BillingsCargoOrderListingRouteQueryFilterParams from "./types/billings-cargo-order-listing-route-query-filter-params";
import BillingsCargoOrderListingRouteQueryParams from "./types/billings-cargo-order-listing-route-query-params";
import BillingsCargoOrderListingSortKey from "./types/billings-cargo-order-listing-sort-key";
import billingsCargoOrderListingFilterHelper from "./billings-cargo-order-listing-filter.helper";

const getFilters = (
  routeQueryFilterParams: BillingsCargoOrderListingRouteQueryFilterParams
): BillingsCargoOrderListingFilter[] => {
  if (!validateFilters(routeQueryFilterParams)) {
    return [];
  }

  const filters: BillingsCargoOrderListingFilter[] = [];

  if (
    routeQueryFilterParams.orderStartDateFrom &&
    routeQueryFilterParams.orderStartDateTo
  ) {
    const startDateFilter: BillingsCargoOrderListingOrderStartDateFilter = {
      type: BillingsCargoOrderListingFilterType.ORDER_START_DATE,
      value: {
        from: new Date(routeQueryFilterParams.orderStartDateFrom),
        to: new Date(routeQueryFilterParams.orderStartDateTo),
      },
    };

    filters.push(startDateFilter);
  }

  if (routeQueryFilterParams.internalOrderId) {
    const idFilter: BillingsCargoOrderListingInternalOrderIdFilter = {
      type: BillingsCargoOrderListingFilterType.INTERNAL_ORDER_ID,
      value: routeQueryFilterParams.internalOrderId,
    };
    filters.push(idFilter);
  }

  if (routeQueryFilterParams.routeAddress) {
    const routeIntermediateAddressFilter: BillingsCargoOrderListingRouteAddressFilter =
      {
        type: BillingsCargoOrderListingFilterType.ROUTE_ADDRESS,
        value: routeQueryFilterParams.routeAddress,
      };

    filters.push(routeIntermediateAddressFilter);
  }

  if (routeQueryFilterParams.routeIntermediateAddress) {
    const routeIntermediateAddressFilter: BillingsCargoOrderListingRouteIntermediateAddressFilter =
      {
        type: BillingsCargoOrderListingFilterType.ROUTE_INTERMEDIATE_ADDRESS,
        value: routeQueryFilterParams.routeIntermediateAddress,
      };

    filters.push(routeIntermediateAddressFilter);
  }

  if (routeQueryFilterParams.routeDestinationAddress) {
    const routeDestinationAddressFilter: BillingsCargoOrderListingRouteDestinationAddressFilter =
      {
        type: BillingsCargoOrderListingFilterType.ROUTE_DESTINATION_ADDRESS,
        value: routeQueryFilterParams.routeDestinationAddress,
      };

    filters.push(routeDestinationAddressFilter);
  }

  if (routeQueryFilterParams.routePickupAddress) {
    const routePickupAddressFilter: BillingsCargoOrderListingRoutePickupAddressFilter =
      {
        type: BillingsCargoOrderListingFilterType.ROUTE_PICKUP_ADDRESS,
        value: routeQueryFilterParams.routePickupAddress,
      };

    filters.push(routePickupAddressFilter);
  }

  if (routeQueryFilterParams.passenger) {
    const passengersFilter: BillingsCargoOrderListingPassengerFilter = {
      type: BillingsCargoOrderListingFilterType.PASSENGER,
      value: routeQueryFilterParams.passenger,
    };
    filters.push(passengersFilter);
  }

  if (routeQueryFilterParams.taxi) {
    const taxiFilter: BillingsCargoOrderListingTaxiFilter = {
      type: BillingsCargoOrderListingFilterType.TAXI,
      value: routeQueryFilterParams.taxi,
    };
    filters.push(taxiFilter);
  }

  if (routeQueryFilterParams.externalOrderId) {
    const externalIdFilter: BillingsCargoOrderListingExternalOrderIdFilter = {
      type: BillingsCargoOrderListingFilterType.EXTERNAL_ORDER_ID,
      value: routeQueryFilterParams.externalOrderId,
    };
    filters.push(externalIdFilter);
  }

  if (routeQueryFilterParams.dispatch) {
    const dispatchFilter: BillingsCargoOrderListingDispatchFilter = {
      type: BillingsCargoOrderListingFilterType.DISPATCH,
      value: routeQueryFilterParams.dispatch,
    };
    filters.push(dispatchFilter);
  }

  if (routeQueryFilterParams.billingStatus) {
    const statusFilter: BillingsCargoOrderListingBillingStatusFilter = {
      type: BillingsCargoOrderListingFilterType.BILLING_STATUS,
      value: routeQueryFilterParams.billingStatus,
    };
    filters.push(statusFilter);
  }

  const updatedFilters =
    billingsCargoOrderListingFilterHelper.getDefaultFilters(filters);

  return updatedFilters;
};

const getSortKey = (
  routeQueryParams: BillingsCargoOrderListingRouteQueryParams
): BillingsCargoOrderListingSortKey | undefined => {
  if (!validateSortKey(routeQueryParams.sort)) {
    return undefined;
  }

  return routeQueryParams.sort;
};

const createRouteQueryParams = (
  filters: BillingsCargoOrderListingFilter[],
  sortKey: BillingsCargoOrderListingSortKey | null,
  page: number,
  pageSize: number
): BillingsCargoOrderListingRouteQueryParams => {
  return {
    page,
    pageSize,
    sort: sortKey ?? undefined,
    orderStartDateFrom: (
      filters.find(
        (filter) =>
          filter.type === BillingsCargoOrderListingFilterType.ORDER_START_DATE
      )?.value as
        | BillingsCargoOrderListingOrderStartDateFilter["value"]
        | undefined
    )?.from.toJSON(),
    orderStartDateTo: (
      filters.find(
        (filter) =>
          filter.type === BillingsCargoOrderListingFilterType.ORDER_START_DATE
      )?.value as
        | BillingsCargoOrderListingOrderStartDateFilter["value"]
        | undefined
    )?.to.toJSON(),
    internalOrderId: filters.find(
      (filter) =>
        filter.type === BillingsCargoOrderListingFilterType.INTERNAL_ORDER_ID
    )?.value as
      | BillingsCargoOrderListingInternalOrderIdFilter["value"]
      | undefined,
    routeAddress: filters.find(
      (filter) =>
        filter.type === BillingsCargoOrderListingFilterType.ROUTE_ADDRESS
    )?.value as
      | BillingsCargoOrderListingRouteAddressFilter["value"]
      | undefined,
    routeDestinationAddress: filters.find(
      (filter) =>
        filter.type ===
        BillingsCargoOrderListingFilterType.ROUTE_DESTINATION_ADDRESS
    )?.value as
      | BillingsCargoOrderListingRouteDestinationAddressFilter["value"]
      | undefined,
    routeIntermediateAddress: filters.find(
      (filter) =>
        filter.type ===
        BillingsCargoOrderListingFilterType.ROUTE_INTERMEDIATE_ADDRESS
    )?.value as
      | BillingsCargoOrderListingRouteIntermediateAddressFilter["value"]
      | undefined,
    routePickupAddress: filters.find(
      (filter) =>
        filter.type === BillingsCargoOrderListingFilterType.ROUTE_PICKUP_ADDRESS
    )?.value as
      | BillingsCargoOrderListingRoutePickupAddressFilter["value"]
      | undefined,
    passenger: filters.find(
      (filter) => filter.type === BillingsCargoOrderListingFilterType.PASSENGER
    )?.value as BillingsCargoOrderListingPassengerFilter["value"] | undefined,
    taxi: filters.find(
      (filter) => filter.type === BillingsCargoOrderListingFilterType.TAXI
    )?.value as BillingsCargoOrderListingTaxiFilter["value"] | undefined,
    externalOrderId: filters.find(
      (filter) =>
        filter.type === BillingsCargoOrderListingFilterType.EXTERNAL_ORDER_ID
    )?.value as
      | BillingsCargoOrderListingExternalOrderIdFilter["value"]
      | undefined,
    dispatch: filters.find(
      (filter) => filter.type === BillingsCargoOrderListingFilterType.DISPATCH
    )?.value as BillingsCargoOrderListingDispatchFilter["value"] | undefined,
    billingStatus: filters.find(
      (filter) =>
        filter.type === BillingsCargoOrderListingFilterType.BILLING_STATUS
    )?.value as
      | BillingsCargoOrderListingBillingStatusFilter["value"]
      | undefined,
  };
};

const getPage = (
  routeQueryFilterParams: BillingsCargoOrderListingRouteQueryParams
): number | undefined => {
  if (!validatePage(routeQueryFilterParams.page)) {
    return undefined;
  }

  return routeQueryFilterParams.page
    ? Number(routeQueryFilterParams.page)
    : undefined;
};

const getPageSize = (
  routeQueryFilterParams: BillingsCargoOrderListingRouteQueryParams
): number | undefined => {
  if (!validatePageSize(routeQueryFilterParams.pageSize)) {
    return undefined;
  }
  return routeQueryFilterParams.pageSize
    ? Number(routeQueryFilterParams.pageSize)
    : undefined;
};

const validateFilters = (
  routeQueryFilterParams: BillingsCargoOrderListingRouteQueryFilterParams
) => {
  const filterParams: BillingsCargoOrderListingRouteQueryFilterParams = {
    orderStartDateFrom: routeQueryFilterParams.orderStartDateFrom,
    orderStartDateTo: routeQueryFilterParams.orderStartDateTo,
    internalOrderId: routeQueryFilterParams.internalOrderId,
    routeAddress: routeQueryFilterParams.routeAddress,
    routeDestinationAddress: routeQueryFilterParams.routeDestinationAddress,
    routeIntermediateAddress: routeQueryFilterParams.routeIntermediateAddress,
    routePickupAddress: routeQueryFilterParams.routePickupAddress,
    passenger: routeQueryFilterParams.passenger,
    taxi: routeQueryFilterParams.taxi,
    externalOrderId: routeQueryFilterParams.externalOrderId,
    dispatch: routeQueryFilterParams.dispatch,
    billingStatus: routeQueryFilterParams.billingStatus,
  };

  const validationSchema =
    Joi.object<BillingsCargoOrderListingRouteQueryFilterParams>({
      orderStartDateFrom: Joi.string(),
      orderStartDateTo: Joi.string(),
      internalOrderId: Joi.number(),
      routeAddress: Joi.string(),
      routeDestinationAddress: Joi.string(),
      routeIntermediateAddress: Joi.string(),
      routePickupAddress: Joi.string(),
      passenger: Joi.string(),
      taxi: Joi.string(),
      externalOrderId: Joi.string(),
      dispatch: Joi.string(),
      billingStatus: Joi.string(),
    });

  return !validationSchema.validate(filterParams).error?.message;
};

const validateSortKey = (
  routeQuerySortParam: BillingsCargoOrderListingRouteQueryParams["sort"]
): boolean => {
  const validationSchema = Joi.valid(
    ...Object.values(BillingsCargoOrderListingSortKey)
  );

  return !validationSchema.validate(routeQuerySortParam).error?.message;
};

const validatePage = (
  routeQueryPageParam: BillingsCargoOrderListingRouteQueryParams["page"]
) => {
  const validationSchema = Joi.number().min(1);

  return !validationSchema.validate(routeQueryPageParam).error?.message;
};

const validatePageSize = (
  routeQueryPageSizeParam: BillingsCargoOrderListingRouteQueryParams["pageSize"]
) => {
  const validationSchema = Joi.number().valid(...[50, 100, 200]);

  return !validationSchema.validate(routeQueryPageSizeParam).error?.message;
};

const billingsCargoOrderListingRouteQueryParamsService = {
  getFilters,
  getSortKey,
  getPage,
  getPageSize,
  createRouteQueryParams,
};

export default billingsCargoOrderListingRouteQueryParamsService;
