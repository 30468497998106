import ListingFilterBadge from "../../../../../../../common/components/listing/filter/badge/listing-filter-badge";
import dateService from "../../../../../../../common/utils/date/date.service";
import billingsTranslationsHelper from "../../../../../../../languages/billings-translations.helper";
import billingsTaxiUnbilledOrderListingHelper from "../../billings-taxi-unbilled-order-listing.helper";
import BillingsTaxiUnbilledOrderListingFilter, {
  BillingsTaxiUnbilledOrderListingStatusFilter,
  BillingsTaxiUnbilledOrderListingClientFilter,
  BillingsTaxiUnbilledOrderListingDispatchFilter,
  BillingsTaxiUnbilledOrderListingDriverFilter,
  BillingsTaxiUnbilledOrderListingInternalOrderIdFilter,
  BillingsTaxiUnbilledOrderListingExternalOrderIdFilter,
  BillingsTaxiUnbilledOrderListingPassengerFilter,
  BillingsTaxiUnbilledOrderListingRouteIntermediateAddressFilter,
  BillingsTaxiUnbilledOrderListingRoutePickupAddressFilter,
  BillingsTaxiUnbilledOrderListingStartDateFilter,
  BillingsTaxiUnbilledOrderListingTaxiFilter,
} from "../../types/billings-taxi-unbilled-order-listing-filter";
import BillingsTaxiUnbilledOrderListingFilterType from "../../types/billings-taxi-unbilled-order-listing-filter-type";
import BillingsTaxiUnbilledOrderListingItemStatus from "../../types/billings-taxi-unbilled-order-listing-item-status";

const getStartDateBadgeOption = (
  filter: BillingsTaxiUnbilledOrderListingStartDateFilter
): {
  badge: ListingFilterBadge;
  filter: BillingsTaxiUnbilledOrderListingStartDateFilter;
} => {
  const translations =
    billingsTranslationsHelper.getTaxiUnbilledOrderBillingsListingTranslations()
      .filters;

  const formattedFromDate = dateService.formatDate(filter.value.from);

  const formattedToDate = dateService.formatDate(filter.value.to);

  return {
    badge: {
      text: translations.startDateFilterText
        .replace("#{fromDate}", formattedFromDate)
        .replace("#{toDate}", formattedToDate),
      title: translations.startDateFilterTitle
        .replace("#{fromDate}", formattedFromDate)
        .replace("#{toDate}", formattedToDate),
    },
    filter: {
      type: BillingsTaxiUnbilledOrderListingFilterType.START_DATE,
      value: filter.value,
    },
  };
};

const getStatusBadgeOptions = (): {
  badge: ListingFilterBadge;
  filter: BillingsTaxiUnbilledOrderListingStatusFilter;
}[] => {
  const translations =
    billingsTranslationsHelper.getTaxiUnbilledOrderBillingsListingTranslations()
      .filters;

  return Object.values(BillingsTaxiUnbilledOrderListingItemStatus).map(
    (statusFilterValue) => {
      return {
        badge: {
          text: translations.statusFilterText.replace(
            "#{option}",
            billingsTaxiUnbilledOrderListingHelper.getStatusText(
              statusFilterValue
            )
          ),
          title: translations.statusFilterTitle.replace(
            "#{option}",
            billingsTaxiUnbilledOrderListingHelper.getStatusText(
              statusFilterValue
            )
          ),
        },
        filter: {
          type: BillingsTaxiUnbilledOrderListingFilterType.STATUS,
          value: statusFilterValue,
        },
      };
    }
  );
};

const getBadgeOptions = (filter: BillingsTaxiUnbilledOrderListingFilter) => {
  const translations =
    billingsTranslationsHelper.getTaxiUnbilledOrderBillingsListingTranslations()
      .filters;

  const options: {
    badge: ListingFilterBadge;
    filter: BillingsTaxiUnbilledOrderListingFilter;
  }[] = [
    {
      badge: {
        text: translations.clientNameFilterText.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledOrderListingClientFilter["value"]
        ),
        title: translations.clientNameFilterTitle.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledOrderListingClientFilter["value"]
        ),
      },
      filter: {
        type: BillingsTaxiUnbilledOrderListingFilterType.CLIENT,
        value:
          filter.value as BillingsTaxiUnbilledOrderListingClientFilter["value"],
      },
    },
    {
      badge: {
        text: translations.dispatchNameFilterText.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledOrderListingDispatchFilter["value"]
        ),
        title: translations.dispatchNameFilterTitle.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledOrderListingDispatchFilter["value"]
        ),
      },
      filter: {
        type: BillingsTaxiUnbilledOrderListingFilterType.DISPATCH,
        value:
          filter.value as BillingsTaxiUnbilledOrderListingDispatchFilter["value"],
      },
    },
    {
      badge: {
        text: translations.driverFilterText.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledOrderListingDriverFilter["value"]
        ),
        title: translations.driverFilterTitle.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledOrderListingDriverFilter["value"]
        ),
      },
      filter: {
        type: BillingsTaxiUnbilledOrderListingFilterType.DRIVER,
        value:
          filter.value as BillingsTaxiUnbilledOrderListingDriverFilter["value"],
      },
    },
    {
      badge: {
        text: translations.internalOrderIdFilterText.replace(
          "#{query}",
          String(
            filter.value as BillingsTaxiUnbilledOrderListingInternalOrderIdFilter["value"]
          )
        ),
        title: translations.internalOrderIdFilterTitle.replace(
          "#{query}",
          String(
            filter.value as BillingsTaxiUnbilledOrderListingInternalOrderIdFilter["value"]
          )
        ),
      },
      filter: {
        type: BillingsTaxiUnbilledOrderListingFilterType.INTERNAL_ORDER_ID,
        value:
          filter.value as BillingsTaxiUnbilledOrderListingInternalOrderIdFilter["value"],
      },
    },
    {
      badge: {
        text: translations.externalOrderIdFilterText.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledOrderListingExternalOrderIdFilter["value"]
        ),
        title: translations.externalOrderIdFilterTitle.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledOrderListingExternalOrderIdFilter["value"]
        ),
      },
      filter: {
        type: BillingsTaxiUnbilledOrderListingFilterType.EXTERNAL_ORDER_ID,
        value:
          filter.value as BillingsTaxiUnbilledOrderListingExternalOrderIdFilter["value"],
      },
    },
    {
      badge: {
        text: translations.passengerFilterText.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledOrderListingPassengerFilter["value"]
        ),
        title: translations.passengerFilterTitle.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledOrderListingPassengerFilter["value"]
        ),
      },
      filter: {
        type: BillingsTaxiUnbilledOrderListingFilterType.PASSENGER,
        value:
          filter.value as BillingsTaxiUnbilledOrderListingPassengerFilter["value"],
      },
    },
    {
      badge: {
        text: translations.passengerFilterText.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledOrderListingPassengerFilter["value"]
        ),
        title: translations.passengerFilterTitle.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledOrderListingPassengerFilter["value"]
        ),
      },
      filter: {
        type: BillingsTaxiUnbilledOrderListingFilterType.ROUTE_ADDRESS,
        value:
          filter.value as BillingsTaxiUnbilledOrderListingPassengerFilter["value"],
      },
    },
    {
      badge: {
        text: translations.passengerFilterText.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledOrderListingPassengerFilter["value"]
        ),
        title: translations.passengerFilterTitle.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledOrderListingPassengerFilter["value"]
        ),
      },
      filter: {
        type: BillingsTaxiUnbilledOrderListingFilterType.ROUTE_DESTINATION_ADDRESS,
        value:
          filter.value as BillingsTaxiUnbilledOrderListingPassengerFilter["value"],
      },
    },
    {
      badge: {
        text: translations.routeIntermediateAddressFilterText.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledOrderListingRouteIntermediateAddressFilter["value"]
        ),
        title: translations.routeIntermediateAddressFilterTitle.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledOrderListingRouteIntermediateAddressFilter["value"]
        ),
      },
      filter: {
        type: BillingsTaxiUnbilledOrderListingFilterType.ROUTE_INTERMEDIATE_ADDRESS,
        value:
          filter.value as BillingsTaxiUnbilledOrderListingRouteIntermediateAddressFilter["value"],
      },
    },
    {
      badge: {
        text: translations.routePickupAddressFilterText.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledOrderListingRoutePickupAddressFilter["value"]
        ),
        title: translations.routePickupAddressFilterTitle.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledOrderListingRoutePickupAddressFilter["value"]
        ),
      },
      filter: {
        type: BillingsTaxiUnbilledOrderListingFilterType.ROUTE_PICKUP_ADDRESS,
        value:
          filter.value as BillingsTaxiUnbilledOrderListingRoutePickupAddressFilter["value"],
      },
    },
    {
      badge: {
        text: translations.taxiFilterText.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledOrderListingTaxiFilter["value"]
        ),
        title: translations.taxiFilterTitle.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledOrderListingTaxiFilter["value"]
        ),
      },
      filter: {
        type: BillingsTaxiUnbilledOrderListingFilterType.TAXI,
        value:
          filter.value as BillingsTaxiUnbilledOrderListingTaxiFilter["value"],
      },
    },
    getStartDateBadgeOption(
      filter as BillingsTaxiUnbilledOrderListingStartDateFilter
    ),
    ...getStatusBadgeOptions(),
  ];

  return options;
};

const getBadge = (filter: BillingsTaxiUnbilledOrderListingFilter) => {
  const options = getBadgeOptions(filter);

  return options.find(
    (item) =>
      item.filter.type === filter.type && item.filter.value === filter.value
  )?.badge;
};

const getBadges = (
  filters: BillingsTaxiUnbilledOrderListingFilter[]
): ListingFilterBadge[] => {
  const badges: ListingFilterBadge[] = [];

  filters.forEach((filter) => {
    const badge = getBadge(filter);

    if (!badge) {
      return;
    }

    badges.push(badge);
  });

  return badges;
};

const billingsTaxiUnbilledOrderListingFiltersBadgeListHelper = {
  getBadges,
};

export default billingsTaxiUnbilledOrderListingFiltersBadgeListHelper;
