import CargoOrderAbandonedListRequest, {
  CargoOrderAbandonedListQueryParams,
} from "./cargo-order-abandoned-list.request";

const createQueryParams = (
  request: CargoOrderAbandonedListRequest
): CargoOrderAbandonedListQueryParams => {
  return {
    limit: request.limit,
    order: request.order,
    address: request.address,
    client: request.client,
    creationEnd: request.creationEnd,
    creationStart: request.creationStart,
    dispatch: request.dispatch,
    endAddress: request.endAddress,
    externalId: request.externalId,
    humanId: request.humanId,
    midAddress: request.midAddress,
    offset: request.offset,
    passenger: request.passenger,
    publicStatus: request.publicStatus,
    startAddress: request.startAddress,
    startTimeSince: request.startTimeSince,
    startTimeTo: request.startTimeTo,
  };
};

const cargoOrderAbandonedListRequestFactory = { createQueryParams };

export default cargoOrderAbandonedListRequestFactory;
