import { ReactNode } from "react";
import MapMarker from "../../../../common/components/map/types/map-marker";
import mapMarkerIconFactory from "../../../../common/components/map/marker/map-marker-icon.factory";
import isEqual from "lodash/isEqual";
import OrderOptimizerDetailsWaypoint from "./types/order-optimizer-details-waypoint";

const createMapMarker = (
  points: OrderOptimizerDetailsWaypoint,
  content?: ReactNode
): MapMarker => {
  const icon = mapMarkerIconFactory.createIcon({
    className: "map_marker standard",
    content: content,
  });

  const marker: MapMarker = {
    coordinate: {
      latitude: points.lat,
      longitude: points.lon,
    },
    icon,
  };

  return marker;
};

const createMapMarkers = (
  waypoints: OrderOptimizerDetailsWaypoint[]
): MapMarker[] => {
  let mapMarkers: MapMarker[] = [];

  for (const waypoint of waypoints) {
    const allWaypointsOnThisPlace = waypoints
      .map((w, index) => ({ waypoint: w, index: index + 1 }))
      .filter((w) => isEqual(w.waypoint, waypoint));

    const signature = allWaypointsOnThisPlace.map((w) => w.index).join("/");

    const newMarker: MapMarker = createMapMarker(waypoint, signature);

    mapMarkers = [...mapMarkers, newMarker];
  }

  return mapMarkers;
};

const orderOptimizerDetailsMapMarkersFactory = {
  createMapMarkers,
};

export default orderOptimizerDetailsMapMarkersFactory;
