import orderOptimizerTranslationsHelper from "../../../../languages/order-optimizer-translations.helper";
import OrderOptimizerDetailsOrdersTableColumn from "../common/types/order-optimizer-details-orders-table-column";


const getColumns = (): OrderOptimizerDetailsOrdersTableColumn[] => {

  const translations =
    orderOptimizerTranslationsHelper.getDetailsTranslations().table.orders.headers;

  const tableColumns: OrderOptimizerDetailsOrdersTableColumn[] = [
    {
      header: translations.humanIdLabel,
      title: translations.humanIdTitle,
      accessor: "humanId",
      colSpan: 3,
    },
    {
      header: translations.cargoCompanyLabel,
      title: translations.cargoCompanyTitle,
      accessor: "cargoCompany",
      colSpan: 3,
    },
    {
      header: translations.billingModelLabel,
      title: translations.billingModelTitle,
      accessor: "billingModel",
      colSpan: 3,
    },
    {
      header: translations.taxiCorporationLabel,
      title: translations.taxiCorporationTitle,
      accessor: "taxiCorporation",
      colSpan: 3,
    },
    {
      header: translations.totalTimeLabel,
      title: translations.totalTimeTitle,
      accessor: "totalTime",
      colSpan: 3,
    },
    {
      header: translations.haltingTimeLabel,
      title: translations.haltingTimeTitle,
      accessor: "haltingTime",
      colSpan: 3,
    },
    {
      header: translations.distanceLabel,
      title: translations.distanceTitle,
      accessor: "distance",
      colSpan: 3,
    },
  ];

  return tableColumns;
};

const orderOptimizerDetailsOrdersTableHelper = {
  getColumns,
};

export default orderOptimizerDetailsOrdersTableHelper;
