import { FC, useEffect } from "react";
import orderRouteEditRoutesHelper from "./order-route-edit-routes.helper";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import orderRouteEditRoutesFactory from "./order-route-edit-routes.factory";
import OrderRouteEditRouteWaypointComponent from "./waypoint/order-route-edit-route-waypoint.component";
import OrderRouteEditRouteGroupWaypointComponent from "./waypoint/order-route-edit-route-group-waypoint.component";
import ButtonComponent from "../../../../../../common/components/button/button.component";
import FormFieldComponent from "../../../../../../common/components/form/field/form-field.component";
import uuidService from "../../../../../../common/utils/uuid/uuid.service";
import orderTranslationsHelper from "../../../../../../languages/order-translations.helper";
import orderRouteEditHelper from "../../helper/order-route-edit.helper";
import OrderRouteEditContractor from "../../types/order-route-edit-contractor";
import OrderRouteEditPassengerListItem from "../../types/order-route-edit-passenger-list-item";
import OrderRouteEditRouteItem, {
  OrderRouteEditRouteWaypoint,
  OrderRouteEditRouteWaypointGroup,
  OrderRouteEditRouteWaypointGroupWaypoint,
} from "../../types/order-route-edit-route-waypoint";

type OrderRouteEditRoutesProps = {
  passengerList: OrderRouteEditPassengerListItem[];
  routes: OrderRouteEditRouteItem[];
  onRoutesChange: (routes: OrderRouteEditRouteItem[]) => void;
  contractorUuid: OrderRouteEditContractor["uuid"] | null;
  isReturnRideActive: string[];
  setIsReturnRideActive: (isReturnRideActive: string[]) => void;
  returnRideRouteItemUuids: {onboarding: string | null, outboarding: string | null,  passengerUuid: string}[];
  setReturnRideRouteItemUuids: (returnAddressRouteItemUuid: {onboarding: string | null, outboarding: string | null, passengerUuid: string}[]) => void;
};

const OrderRouteEditRoutesComponent: FC<OrderRouteEditRoutesProps> = (
  props
) => {
  const translations = orderTranslationsHelper.getEditTranslations().routes;

  const onAddRouteButtonClick = () => {
    const newEmptyRoute = orderRouteEditRoutesHelper.getNewEmptyRoute();

    const newRoutes: OrderRouteEditRouteItem[] = [
      ...props.routes,
      newEmptyRoute,
    ];

    props.onRoutesChange(newRoutes);
  };

  const updateWaypoint = (
    waypointUuid: OrderRouteEditRouteWaypoint["uuid"],
    updatedWaypoint: OrderRouteEditRouteWaypoint
  ) => {
    let newRoutes: OrderRouteEditRouteItem[] = [...props.routes];

    const routeToUpdate = props.routes.find(
      (route) => route.uuid === waypointUuid
    );

    if (!routeToUpdate) {
      return;
    }

    const indexOfRouteToUpdate = newRoutes.indexOf(routeToUpdate);

    newRoutes[indexOfRouteToUpdate] = updatedWaypoint;

    props.onRoutesChange(newRoutes);
  };

  const updateWaypointGroupWaypoint = (
    routeUuid: OrderRouteEditRouteWaypointGroup["uuid"],
    waypointUuid: OrderRouteEditRouteWaypointGroupWaypoint["uuid"],
    updatedWaypoint: OrderRouteEditRouteWaypointGroupWaypoint
  ) => {
    let newRoutes: OrderRouteEditRouteItem[] = [...props.routes];

    const routeToUpdate = props.routes.find(
      (route) => route.uuid === routeUuid
    );

    if (!routeToUpdate) {
      return;
    }

    const indexOfRouteToUpdate = newRoutes.indexOf(routeToUpdate);

    let routeItemToUpdate: OrderRouteEditRouteWaypointGroup = newRoutes[
      indexOfRouteToUpdate
    ] as OrderRouteEditRouteWaypointGroup;

    const waypointToUpdate = routeItemToUpdate.waypoints.find(
      (waypoint) => waypoint.uuid === waypointUuid
    );

    if (!waypointToUpdate) {
      return;
    }

    const indexOfWaypointToUpdate =
      routeItemToUpdate.waypoints.indexOf(waypointToUpdate);

    newRoutes[indexOfRouteToUpdate] = {
      ...routeItemToUpdate,
      waypoints: [
        ...routeItemToUpdate.waypoints.slice(0, indexOfWaypointToUpdate),
        updatedWaypoint,
        ...routeItemToUpdate.waypoints.slice(indexOfWaypointToUpdate + 1),
      ],
    };

    props.onRoutesChange(newRoutes);
  };

  const deleteRouteItem = (routeUuid: OrderRouteEditRouteItem["uuid"]) => {
    const newRoutes: OrderRouteEditRouteItem[] = props.routes.filter(
      (route) => route.uuid !== routeUuid
    );
  
    const foundReturnAddressRouteItem = props.returnRideRouteItemUuids.find(
      (returnAddressRouteItem) =>
        returnAddressRouteItem.onboarding === routeUuid ||
        returnAddressRouteItem.outboarding === routeUuid
    );
  
    if (!foundReturnAddressRouteItem) {
      props.onRoutesChange(newRoutes);
    } else {
      const updatedReturnAddressRouteItems = props.returnRideRouteItemUuids.filter(
        (item) => item.passengerUuid !== foundReturnAddressRouteItem.passengerUuid
      );
  
      props.onRoutesChange(newRoutes);
      props.setReturnRideRouteItemUuids(updatedReturnAddressRouteItems);
  
      props.setIsReturnRideActive(
        props.isReturnRideActive.filter(
          (passengerUuid) => passengerUuid !== foundReturnAddressRouteItem.passengerUuid
        )
      );
    }
  };

  const deleteWaypoint = (routeUuid: OrderRouteEditRouteWaypoint["uuid"]) => {
    deleteRouteItem(routeUuid);
  };

  const deleteGroupWaypoint = (
    routeUuid: OrderRouteEditRouteWaypointGroup["uuid"],
    waypointUuid: OrderRouteEditRouteWaypointGroupWaypoint["uuid"]
  ) => {
    let newRoutes: OrderRouteEditRouteItem[] = [...props.routes];

    const routeToUpdate = props.routes.find(
      (route) => route.uuid === routeUuid
    );

    if (
      !routeToUpdate ||
      orderRouteEditHelper.checkIsRouteItemAWaypoint(routeToUpdate)
    ) {
      return;
    }

    const indexOfRouteToUpdate = newRoutes.indexOf(routeToUpdate);

    if (routeToUpdate.waypoints.length >= 2) {
      const waypointToStay = routeToUpdate.waypoints.find(
        (waypoint) => waypoint.uuid !== waypointUuid
      );

      if (!waypointToStay) {
        return;
      }

      const newWaypointFromGroup =
        orderRouteEditRoutesFactory.createWaypointFromGroupWaypoint(
          waypointToStay
        );

      const newRoutes: OrderRouteEditRouteItem[] = [
        ...props.routes.slice(0, indexOfRouteToUpdate),
        newWaypointFromGroup,

        ...props.routes.slice(indexOfRouteToUpdate + 1),
      ];

      props.onRoutesChange(newRoutes);

      return;
    }

    let routeItemToRemove: OrderRouteEditRouteWaypointGroup = newRoutes[
      indexOfRouteToUpdate
    ] as OrderRouteEditRouteWaypointGroup;

    const waypointToRemove = routeItemToRemove.waypoints.find(
      (waypoint) => waypoint.uuid === waypointUuid
    );

    if (!waypointToRemove) {
      return;
    }

    const indexOfWaypointToRemove =
      routeItemToRemove.waypoints.indexOf(waypointToRemove);

    newRoutes[indexOfRouteToUpdate] = {
      ...routeItemToRemove,
      waypoints: [
        ...routeItemToRemove.waypoints.slice(0, indexOfWaypointToRemove),
        ...routeItemToRemove.waypoints.slice(indexOfWaypointToRemove + 1),
      ],
    };

    props.onRoutesChange(newRoutes);
  };

  const getReorderedRouteWaypoints = (
    startIndex: number,
    endIndex: number
  ): OrderRouteEditRouteItem[] => {
    const result = [...props.routes];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragRouteListItemEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const reorderedPassengerList = getReorderedRouteWaypoints(
      result.source.index,
      result.destination.index
    );

    props.onRoutesChange(reorderedPassengerList);
  };

  const onJoinWaypointIntoGroupButtonClick = (
    waypointUuid: OrderRouteEditRouteWaypoint["uuid"]
  ) => {
    const routeItemInRoutes = props.routes.find(
      (routeItem) => routeItem.uuid === waypointUuid
    ) as OrderRouteEditRouteWaypoint | undefined;

    if (!routeItemInRoutes) {
      return;
    }

    const indexOfRouteItemInRoutes = props.routes.indexOf(routeItemInRoutes);

    const previousRouteItem = props.routes[indexOfRouteItemInRoutes - 1];

    const isPreviousRouteItemAWaypoint =
      orderRouteEditHelper.checkIsRouteItemAWaypoint(previousRouteItem);

    if (isPreviousRouteItemAWaypoint) {
      const newGroup: OrderRouteEditRouteWaypointGroup = {
        uuid: uuidService.generate(),
        waypoints: [previousRouteItem, routeItemInRoutes],
      };

      const newRoutes = [
        ...props.routes.slice(0, indexOfRouteItemInRoutes - 1),
        newGroup,
        ...props.routes.slice(indexOfRouteItemInRoutes + 1),
      ];

      props.onRoutesChange(newRoutes);
    }

    const newGroup: OrderRouteEditRouteWaypointGroup = {
      uuid: uuidService.generate(),
      waypoints: [
        ...(previousRouteItem as OrderRouteEditRouteWaypointGroup).waypoints,
        routeItemInRoutes,
      ],
    };

    const newRoutes = [
      ...props.routes.slice(0, indexOfRouteItemInRoutes - 1),
      newGroup,
      ...props.routes.slice(indexOfRouteItemInRoutes + 1),
    ];

    props.onRoutesChange(newRoutes);
  };

  const onSeparateWaypointIntoGroupButtonClick = (
    waypointGroupUuid: OrderRouteEditRouteWaypointGroup["uuid"],
    groupWaypoint: OrderRouteEditRouteWaypointGroupWaypoint
  ) => {
    const routeItemInRoutes = props.routes.find(
      (routeItem) => routeItem.uuid === waypointGroupUuid
    ) as OrderRouteEditRouteWaypointGroup | undefined;

    if (!routeItemInRoutes) {
      return;
    }

    const indexOfRouteItemInRoutes = props.routes.indexOf(routeItemInRoutes);

    const waypointsAfterFilter = routeItemInRoutes.waypoints.filter(
      (waypoint) => waypoint.uuid !== groupWaypoint.uuid
    );

    if (waypointsAfterFilter.length >= 2) {
      const modifiedGroup: OrderRouteEditRouteWaypointGroup = {
        uuid: routeItemInRoutes.uuid,
        waypoints: routeItemInRoutes.waypoints.filter(
          (waypoint) => waypoint.uuid !== groupWaypoint.uuid
        ),
      };

      const newWaypoint =
        orderRouteEditRoutesFactory.createWaypointFromGroupWaypoint(
          groupWaypoint
        );

      const newRoutes: OrderRouteEditRouteItem[] = [
        ...props.routes.slice(0, indexOfRouteItemInRoutes),
        modifiedGroup,
        newWaypoint,
        ...props.routes.slice(indexOfRouteItemInRoutes + 1),
      ];

      props.onRoutesChange(newRoutes);

      return;
    }

    if (waypointsAfterFilter.length === 1) {
      const newWaypointFromGroup =
        orderRouteEditRoutesFactory.createWaypointFromGroupWaypoint(
          waypointsAfterFilter[0]
        );

      const newWaypoint =
        orderRouteEditRoutesFactory.createWaypointFromGroupWaypoint(
          groupWaypoint
        );

      const newRoutes: OrderRouteEditRouteItem[] = [
        ...props.routes.slice(0, indexOfRouteItemInRoutes),
        newWaypointFromGroup,
        newWaypoint,
        ...props.routes.slice(indexOfRouteItemInRoutes + 1),
      ];

      props.onRoutesChange(newRoutes);

      return;
    }

    const newWaypoint =
      orderRouteEditRoutesFactory.createWaypointFromGroupWaypoint(
        groupWaypoint
      );

    const newRoutes: OrderRouteEditRouteItem[] = [
      ...props.routes.slice(0, indexOfRouteItemInRoutes),
      newWaypoint,
      ...props.routes.slice(indexOfRouteItemInRoutes + 1),
    ];

    props.onRoutesChange(newRoutes);
  };

  useEffect(() => {
    const newRoutes: OrderRouteEditRouteItem[] =
      orderRouteEditRoutesHelper.getUpdatedRouteItemsOnPassengerListChange(
        props.routes,
        props.passengerList
      );

    props.onRoutesChange(newRoutes);
  }, [JSON.stringify(props.passengerList)]);

  const isDraggingEnabled = props.routes.length > 1;

  const isAddRouteButtonDisabled = !props.contractorUuid;

  return (
    <FormFieldComponent label={translations.headingText} isRequired>
      <DragDropContext onDragEnd={onDragRouteListItemEnd}>
        <Droppable droppableId="order_edit_passenger_list_drop_area">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <div className="order_edit_route_list">
                {props.routes.map((route, routeIndex) => {
                  const isWaypoint =
                    orderRouteEditHelper.checkIsRouteItemAWaypoint(route);

                  const waypointNumber = routeIndex + 1;

                  const onboardingPassengerCandidateList: OrderRouteEditPassengerListItem[] =
                    orderRouteEditRoutesHelper.getOnboardingPassengerCandidateList(
                      props.passengerList,
                      route,
                      props.routes
                    );

                  const outboardingPassengerCandidateList: OrderRouteEditPassengerListItem[] =
                    orderRouteEditRoutesHelper.getOutboardingPassengerCandidateList(
                      props.passengerList,
                      route,
                      props.routes
                    );

                  if (isWaypoint) {
                    const isJoinWaypointIntoGroupButtonVisible =
                      !!props.routes[routeIndex - 1];

                    return (
                      <Draggable
                        key={route.uuid}
                        draggableId={route.uuid}
                        index={routeIndex}
                        isDragDisabled={!isDraggingEnabled}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <OrderRouteEditRouteWaypointComponent
                              number={waypointNumber}
                              dragHandleProps={provided.dragHandleProps}
                              waypoint={route}
                              updateWaypoint={(updatedWaypoint) =>
                                updateWaypoint(route.uuid, updatedWaypoint)
                              }
                              deleteWaypoint={() => deleteWaypoint(route.uuid)}
                              isJoinWaypointIntoGroupButtonVisible={
                                isJoinWaypointIntoGroupButtonVisible
                              }
                              onboardingPassengerCandidateList={
                                onboardingPassengerCandidateList
                              }
                              outboardingPassengerCandidateList={
                                outboardingPassengerCandidateList
                              }
                              onJoinWaypointIntoGroupButtonClick={() =>
                                onJoinWaypointIntoGroupButtonClick(route.uuid)
                              }
                              passengerList={props.passengerList}
                              selectedContractorUuid={props.contractorUuid}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  }

                  return (
                    <div
                      key={`order_edit_route_group_weypoint_item_${routeIndex}`}
                    >
                      {route.waypoints.map((waypoint) => {
                        return (
                          <OrderRouteEditRouteGroupWaypointComponent
                            key={`order_edit_route_group_waypoint_component_${waypoint.uuid}`}
                            number={waypointNumber}
                            waypoint={waypoint}
                            updateWaypoint={(updatedWaypoint) =>
                              updateWaypointGroupWaypoint(
                                route.uuid,
                                waypoint.uuid,
                                updatedWaypoint
                              )
                            }
                            deleteWaypoint={() =>
                              deleteGroupWaypoint(route.uuid, waypoint.uuid)
                            }
                            onboardingPassengerCandidateList={
                              onboardingPassengerCandidateList
                            }
                            outboardingPassengerCandidateList={
                              outboardingPassengerCandidateList
                            }
                            onSeparateWaypointIntoGroupButtonClick={() =>
                              onSeparateWaypointIntoGroupButtonClick(
                                route.uuid,
                                waypoint
                              )
                            }
                            passengerList={props.passengerList}
                            selectedContractorUuid={props.contractorUuid}
                          />
                        );
                      })}
                    </div>
                  );
                })}
              </div>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <ButtonComponent
        onClick={onAddRouteButtonClick}
        type="primary"
        title={translations.addNewButtonTitle}
        isDisabled={isAddRouteButtonDisabled}
      >
        {translations.addNewButtonText}
      </ButtonComponent>
    </FormFieldComponent>
  );
};

export default OrderRouteEditRoutesComponent;
