import { BillingsTaxiUnbilledPrivateOrderListingTaxiCoporationsResponseDataItem } from "./api/billings-taxi-unbilled-private-order-listing-taxi-corporations.response";
import {
  BillingsTaxiUnbilledPrivateOrderListingResponseDataItem,
  BillingsTaxiUnbilledPrivateOrderListingResponseDataItemMissingBillings,
} from "./api/billings-taxi-unbilled-private-order-listing.response";
import BillingsTaxiUnbilledPrivateOrderListingItem, {
  BillingsTaxiUnbilledPrivateOrderListingItemMissingBillingType,
} from "./types/billings-taxi-unbilled-private-order-listing-item";
import BillingsTaxiUnbilledPrivateOrderListingItemStatus from "./types/billings-taxi-unbilled-private-order-listing-item-status";
import BillingsTaxiUnbilledPrivateOrderListingTaxiCorporationSelectOption from "./types/billings-taxi-unbilled-private-order-listing-taxi-corporation-select-option";

const createListingItemStatus = (
  responseDataItem: BillingsTaxiUnbilledPrivateOrderListingResponseDataItem
): BillingsTaxiUnbilledPrivateOrderListingItemStatus => {
  if (responseDataItem.is_cancelled) {
    return BillingsTaxiUnbilledPrivateOrderListingItemStatus.CANCELLED;
  }

  return BillingsTaxiUnbilledPrivateOrderListingItemStatus.FINISHED;
};

const createListingItemMissingBillingTypes = (
  responseItemMissingBillings: BillingsTaxiUnbilledPrivateOrderListingResponseDataItemMissingBillings[]
): BillingsTaxiUnbilledPrivateOrderListingItemMissingBillingType[] => {
  const missingBillingTypes: BillingsTaxiUnbilledPrivateOrderListingItemMissingBillingType[] =
    [];

  if (
    responseItemMissingBillings.includes(
      BillingsTaxiUnbilledPrivateOrderListingResponseDataItemMissingBillings.CARGO_TAXI
    )
  ) {
    missingBillingTypes.push(
      BillingsTaxiUnbilledPrivateOrderListingItemMissingBillingType.CARGO_WITH_TAXI
    );
  }

  return missingBillingTypes;
};

const createListingItem = (
  responseDataItem: BillingsTaxiUnbilledPrivateOrderListingResponseDataItem
): BillingsTaxiUnbilledPrivateOrderListingItem => {
  return {
    clientName: responseDataItem.client.name,
    dispatchName: responseDataItem.dispatch,
    driversDisplayNames: responseDataItem.drivers,
    externalOrderId: responseDataItem.external_id,
    internalOrderId: responseDataItem.human_id,
    passengers: responseDataItem.passengers,
    routeAddresses: responseDataItem.ride_addresses,
    startDate: new Date(responseDataItem.start_time),
    status: createListingItemStatus(responseDataItem),
    taxiCorporations: responseDataItem.taxi_corporations,
    uuid: responseDataItem.id,
    missingBillingTypes: createListingItemMissingBillingTypes(
      responseDataItem.missing_taxi_billings
    ),
  };
};

const createListingItems = (
  responseDataItems: BillingsTaxiUnbilledPrivateOrderListingResponseDataItem[]
): BillingsTaxiUnbilledPrivateOrderListingItem[] => {
  return responseDataItems.map(createListingItem);
};

const createTaxiCorporationSelectOption = (
  responseTaxiCorporation: BillingsTaxiUnbilledPrivateOrderListingTaxiCoporationsResponseDataItem
): BillingsTaxiUnbilledPrivateOrderListingTaxiCorporationSelectOption => {
  return {
    label: responseTaxiCorporation.display_name,
    value: {
      uuid: responseTaxiCorporation.id,
      displayName: responseTaxiCorporation.display_name,
    },
  };
};

const createTaxiCorporationSelectOptions = (
  responseTaxiCorporations: BillingsTaxiUnbilledPrivateOrderListingTaxiCoporationsResponseDataItem[]
): BillingsTaxiUnbilledPrivateOrderListingTaxiCorporationSelectOption[] => {
  return responseTaxiCorporations.map(createTaxiCorporationSelectOption);
};

const billingsTaxiUnbilledPrivateOrderListingFactory = {
  createListingItems,
  createTaxiCorporationSelectOptions,
};

export default billingsTaxiUnbilledPrivateOrderListingFactory;
