import appTranslationsHelper from "./app-translations.helper";
import CargoOfficerAddTranslations from "./types/user/cargo-officer/cargo-officer-add.translations";
import DealerAddTranslations from "./types/user/dealer/dealer-add.translations";
import PassengerAddTranslations from "./types/user/passenger/passenger-add.translations";
import DriverAddTranslations from "./types/user/driver/driver-add.translations";
import PassengerListingTranslations from "./types/user/passenger/passenger-listing.translations";
import DriverEditTranslations from "./types/user/driver/driver-edit.translations";
import DealerListingTranslations from "./types/user/dealer/dealer-listing.translations";
import RailyOfficerAddTranslations from "./types/user/raily-officer/raily-officer-add.translations";
import TaxiOfficerLisitingTranslations from "./types/user/taxi-officer/taxi-officer-listing.translations";
import TaxiOfficerAddTranslations from "./types/user/taxi-officer/taxi-officer-add.translations";
import DriverListingTranslations from "./types/user/driver/driver-listing.translations";
import OperatorAddTranslations from "./types/user/operator/operator-add.translations";
import DriverDetailsActiveRoutePlansTranslations from "./types/user/driver/driver-details-active-route-plans.translations";
import DriverDetailsCandidatureTranslations from "./types/user/driver/driver-details-candidature.translations";
import DriverContractListingTranslations from "./types/user/driver/driver-contract-listing.translations";
import DriverContractAddTranslations from "./types/user/driver/driver-contract-add.translations";
import PassengerEditTranslations from "./types/user/passenger/passenger-edit.translations";
import PassengerAddressTranslations from "./types/user/passenger/passenger-address.translations";
import DispatcherEditTranslations from "./types/user/dispatcher/dispatcher-edit.translations";
import DispatcherAddTranslations from "./types/user/dispatcher/dispatcher-add.translations";
import DispatcherListingTranslations from "./types/user/dispatcher/dispatcher-listing.translations";
import UserDeleteTranslations from "./types/user/user-delete.translations";
import UserAddTranslations from "./types/user/user-add.translations";
import CargoOfficerListingTranslations from "./types/user/cargo-officer/cargo-officer-listing.translations";
import OperatorListingTranslations from "./types/user/operator/operator-listing.translations";
import RailyOfficerListingTranslations from "./types/user/raily-officer/raily-officer-listing.translations";
import UserRestoreTranslations from "./types/user/user-restore.translations";
import DriverContractEditTranslations from "./types/user/driver/driver-contract-edit.translations";
import TaxiOfficerEditTranslations from "./types/user/taxi-officer/taxi-officer-edit.translations";
import DriverDetailsFinishedRoutePlansTranslations from "./types/user/driver/driver-details-finished-route-plans.translations";

const getTranslations = <T>(internalFilePath: string): T => {
  const baseFilePath = `user`;

  const filePath = `${baseFilePath}/${internalFilePath}`;

  return appTranslationsHelper.getTranslations(filePath);
};

const getCargoOfficerAddTranslations = (): CargoOfficerAddTranslations => {
  return getTranslations<CargoOfficerAddTranslations>(
    `cargo-officer/cargo-officer-add.translations.json`
  );
};

const getCargoOfficerListingTranslations =
  (): CargoOfficerListingTranslations => {
    return getTranslations<CargoOfficerListingTranslations>(
      `cargo-officer/cargo-officer-listing.translations.json`
    );
  };

const getDealerAddTranslations = (): DealerAddTranslations => {
  return getTranslations<DealerAddTranslations>(
    `dealer/dealer-add.translations.json`
  );
};

const getDealerListingTranslations = (): DealerListingTranslations => {
  return getTranslations<DealerListingTranslations>(
    `dealer/dealer-listing.translations.json`
  );
};

const getDispatcherAddTranslations = (): DispatcherAddTranslations => {
  return getTranslations<DispatcherAddTranslations>(
    `dispatcher/dispatcher-add.translations.json`
  );
};

const getDispatcherEditTranslations = (): DispatcherEditTranslations => {
  return getTranslations<DispatcherEditTranslations>(
    `dispatcher/dispatcher-edit.translations.json`
  );
};

const getDispatcherListingTranslations = (): DispatcherListingTranslations => {
  return getTranslations<DispatcherListingTranslations>(
    `dispatcher/dispatcher-listing.translations.json`
  );
};

const getPassengerAddTranslations = (): PassengerAddTranslations => {
  return getTranslations<PassengerAddTranslations>(
    `passenger/passenger-add.translations.json`
  );
};

const getPassengerEditTranslations = (): PassengerEditTranslations => {
  return getTranslations<PassengerEditTranslations>(
    `passenger/passenger-edit.translations.json`
  );
};

const getPassengerAddressTranslations = (): PassengerAddressTranslations => {
  return getTranslations<PassengerAddressTranslations>(
    `passenger/passenger-address.translations.json`
  );
};

const getPassengerListingTranslations = (): PassengerListingTranslations => {
  return getTranslations<PassengerListingTranslations>(
    `passenger/passenger-listing.translations.json`
  );
};

const getTaxiOfficerAddTranslations = (): TaxiOfficerAddTranslations => {
  return getTranslations<TaxiOfficerAddTranslations>(
    `taxi-officer/taxi-officer-add.translations.json`
  );
};

const getTaxiOfficerEditTranslations = (): TaxiOfficerEditTranslations => {
  return getTranslations<TaxiOfficerEditTranslations>(
    `taxi-officer/taxi-officer-edit.translations.json`
  );
};

const getTaxiOfficerListingTranslations =
  (): TaxiOfficerLisitingTranslations => {
    return getTranslations<TaxiOfficerLisitingTranslations>(
      `taxi-officer/taxi-officer-listing.translations.json`
    );
  };

const getRailyOfficerAddTranslations = (): RailyOfficerAddTranslations => {
  return getTranslations<RailyOfficerAddTranslations>(
    `raily-officer/raily-officer-add.translations.json`
  );
};

const getRailyOfficerListingTranslations =
  (): RailyOfficerListingTranslations => {
    return getTranslations<RailyOfficerListingTranslations>(
      `raily-officer/raily-officer-listing.translations.json`
    );
  };

const getDriverAddTranslations = (): DriverAddTranslations => {
  return getTranslations<DriverAddTranslations>(
    `driver/driver-add.translations.json`
  );
};

const getDriverEditTranslations = (): DriverEditTranslations => {
  return getTranslations<DriverEditTranslations>(
    `driver/driver-edit.translations.json`
  );
};

const getDriverListingTranslations = (): DriverListingTranslations => {
  return getTranslations<DriverListingTranslations>(
    `driver/driver-listing.translations.json`
  );
};

const getDriverContractAddTranslations = (): DriverContractAddTranslations => {
  return getTranslations<DriverContractAddTranslations>(
    `driver/driver-contract-add.translations.json`
  );
};

const getDriverContractEditTranslations =
  (): DriverContractEditTranslations => {
    return getTranslations<DriverContractEditTranslations>(
      `driver/driver-contract-edit.translations.json`
    );
  };

const getDriverContractListingTranslations =
  (): DriverContractListingTranslations => {
    return getTranslations<DriverContractListingTranslations>(
      `driver/driver-contract-listing.translations.json`
    );
  };

const getDriverDetailsActiveRoutePlansTranslations =
  (): DriverDetailsActiveRoutePlansTranslations => {
    return getTranslations<DriverDetailsActiveRoutePlansTranslations>(
      `driver/driver-details-active-route-plans.translations.json`
    );
  };

const getDriverDetailsFinishedRoutePlansTranslations =
  (): DriverDetailsFinishedRoutePlansTranslations => {
    return getTranslations<DriverDetailsFinishedRoutePlansTranslations>(
      `driver/driver-details-finished-route-plans.translations.json`
    );
  };

const getDriverDetailsCandidatureTranslations =
  (): DriverDetailsCandidatureTranslations => {
    return getTranslations<DriverDetailsCandidatureTranslations>(
      `driver/driver-details-candidature.translations.json`
    );
  };

const getOperatorAddTranslations = (): OperatorAddTranslations => {
  return getTranslations<OperatorAddTranslations>(
    `operator/operator-add.translations.json`
  );
};

const getOperatorListingTranslations = (): OperatorListingTranslations => {
  return getTranslations<OperatorListingTranslations>(
    `operator/operator-listing.translations.json`
  );
};

const getUserAddTranslations = (): UserAddTranslations => {
  return getTranslations<UserAddTranslations>(`user-add.translations.json`);
};

const getUserDeleteTranslations = (): UserDeleteTranslations => {
  return getTranslations<UserDeleteTranslations>(
    `user-delete.translations.json`
  );
};

const getUserRestoreTranslations = (): UserRestoreTranslations => {
  return getTranslations<UserRestoreTranslations>(
    `user-restore.translations.json`
  );
};

const userTranslationsHelper = {
  getCargoOfficerAddTranslations,
  getCargoOfficerListingTranslations,
  getDealerAddTranslations,
  getDealerListingTranslations,
  getDispatcherAddTranslations,
  getDispatcherEditTranslations,
  getDispatcherListingTranslations,
  getDriverAddTranslations,
  getDriverEditTranslations,
  getDriverListingTranslations,
  getDriverDetailsActiveRoutePlansTranslations,
  getDriverDetailsFinishedRoutePlansTranslations,
  getDriverDetailsCandidatureTranslations,
  getDriverContractAddTranslations,
  getDriverContractEditTranslations,
  getDriverContractListingTranslations,
  getOperatorAddTranslations,
  getOperatorListingTranslations,
  getPassengerAddTranslations,
  getPassengerEditTranslations,
  getPassengerAddressTranslations,
  getPassengerListingTranslations,
  getRailyOfficerAddTranslations,
  getRailyOfficerListingTranslations,
  getTaxiOfficerListingTranslations,
  getTaxiOfficerAddTranslations,
  getTaxiOfficerEditTranslations,
  getUserAddTranslations,
  getUserDeleteTranslations,
  getUserRestoreTranslations,
};
export default userTranslationsHelper;
