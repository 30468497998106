import appConfig from "../../../../../../../app.config";
import authService from "../../../../../../auth/common/auth.service";
import billingsTaxiUnbilledRouteListingApiService from "../../../common/api/billings-taxi-unbilled-route-listing-api.service";
import BillingsTaxiUnbilledRouteListingByRailyTaxiCoporationsResponse from "./billings-taxi-unbilled-route-listing-by-raily-taxi-corporations.response";

const fetchTaxiCorporations =
  (): Promise<BillingsTaxiUnbilledRouteListingByRailyTaxiCoporationsResponse> => {
    const path = `/taxi-corporations`;
    const url = `${appConfig.baseApiUrl}${path}`;

    const authToken = authService.getAccessToken();

    const init: RequestInit = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      credentials: "include",
    };

    return fetch(url, init).then(async (r) => {
      const responseData = await r.json();
      return {
        status: r.status,
        data: responseData,
      };
    });
  };

const billingsTaxiUnbilledRouteListingByRailyApiService = {
  fetchTaxiCorporations,
  fetchListing: billingsTaxiUnbilledRouteListingApiService.fetchListing,
};

export default billingsTaxiUnbilledRouteListingByRailyApiService;
