import BillingsTaxiUnbilledPrivateOrderListingItemStatus from "./billings-taxi-unbilled-private-order-listing-item-status";

export enum BillingsTaxiUnbilledPrivateOrderListingItemMissingBillingType {
  CARGO_WITH_RAILY = "CARGO_WITH_RAILY",
  CARGO_WITH_TAXI = "CARGO_WITH_TAXI",
  TAXI_WITH_RAILY = "TAXI_WITH_RAILY",
  RAILY_WITH_TAXI = "RAILY_WITH_TAXI",
}

type BillingsTaxiUnbilledPrivateOrderListingItem = {
  uuid: string;
  startDate: Date;
  internalOrderId: number;
  externalOrderId: string | null;
  routeAddresses: (string | string[])[];
  passengers: string[];
  dispatchName: string;
  clientName: string;
  taxiCorporations: string[];
  driversDisplayNames: string[];
  status: BillingsTaxiUnbilledPrivateOrderListingItemStatus;
  missingBillingTypes: BillingsTaxiUnbilledPrivateOrderListingItemMissingBillingType[];
};

export default BillingsTaxiUnbilledPrivateOrderListingItem;
