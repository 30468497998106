import Joi from "joi";
import UserVehicleOwnership from "../../../../../../common/types/user-vehicle-ownership-form";
import formValidationService from "../../../../../../common/utils/validation/form-validation.service";
import FormValidationResult from "../../../../../../common/utils/validation/types/form-validation-result";
import DriverAddVehicleFormData from "../types/driver-add-vehicle-form-data";

const validateMake = (
  make: DriverAddVehicleFormData["make"]
): FormValidationResult => {
  const validationSchema = Joi.string().max(50).trim().required();

  return formValidationService.validate(make, validationSchema);
};

const validateModel = (
  model: DriverAddVehicleFormData["model"]
): FormValidationResult => {
  const validationSchema = Joi.string().max(50).trim().required();

  return formValidationService.validate(model, validationSchema);
};

const validateProductionYear = (
  productionYear: DriverAddVehicleFormData["productionYear"]
): FormValidationResult => {
  const validationSchema = Joi.number()
    .min(1971)
    .max(new Date().getFullYear())
    .required();

  return formValidationService.validate(productionYear, validationSchema);
};

const validateNumberOfSeats = (
  numberOfSeats: DriverAddVehicleFormData["numberOfSeats"]
): FormValidationResult => {
  const validationSchema = Joi.number().min(2).max(25).required();

  return formValidationService.validate(numberOfSeats, validationSchema);
};

const validateRegistrationNumber = (
  registrationNumber: DriverAddVehicleFormData["registrationNumber"]
): FormValidationResult => {
  const registrationPattern = new RegExp(`^[A-Z0-9]*$`);
  const validationSchema = Joi.string()
    .min(4)
    .max(8)
    .trim()
    .pattern(registrationPattern)
    .required();

  return formValidationService.validate(registrationNumber, validationSchema);
};

const validateOwnership = (
  ownershipOption: DriverAddVehicleFormData["ownership"]
): FormValidationResult => {
  const validValues = Object.values(UserVehicleOwnership);

  const validationSchema = Joi.string()
    .valid(...validValues)
    .required();

  const ownership = ownershipOption?.value;

  return formValidationService.validate(ownership, validationSchema);
};

const driverAddVehicleDataFormValidationService = {
  validateMake,
  validateModel,
  validateNumberOfSeats,
  validateProductionYear,
  validateRegistrationNumber,
  validateOwnership,
};

export default driverAddVehicleDataFormValidationService;
