import { FC, useMemo, useState } from "react";
import ListingFilterSelectComponent from "../../../../../../../common/components/listing/filter/select/listing-filter-select.component";
import { useAppContext } from "../../../../../../../context/app.context";
import BillingsCargoOrderListingFilter from "../../types/billings-cargo-order-listing-filter";
import BillingsCargoOrderListingFilterSelectOption from "../../types/billings-cargo-order-listing-filter-select-option";
import billingsCargoOrderListingFilterHelper from "../../billings-cargo-order-listing-filter.helper";

type BillingsCargoOrderListingFiltersSelectProps = {
  filters: BillingsCargoOrderListingFilter[];
  onAddNewFilter: (filter: BillingsCargoOrderListingFilter) => void;
};

const BillingsCargoOrderListingFiltersSelectComponent: FC<
  BillingsCargoOrderListingFiltersSelectProps
> = (props) => {
  const { selectedAppLanguage } = useAppContext();

  const [searchInputValue, setSearchInputValue] = useState("");

  const searchSelectOptions: BillingsCargoOrderListingFilterSelectOption[] =
    useMemo(() => {
      return billingsCargoOrderListingFilterHelper.getSelectOptions(
        searchInputValue,
        props.filters
      );
    }, [searchInputValue, props.filters, selectedAppLanguage]);

  const onFilterSelect = (
    filter: BillingsCargoOrderListingFilterSelectOption
  ) => {
    props.onAddNewFilter(filter.value);
  };

  return (
    <ListingFilterSelectComponent
      onChange={(option) =>
        onFilterSelect(option as BillingsCargoOrderListingFilterSelectOption)
      }
      options={searchSelectOptions}
      inputValue={searchInputValue}
      onInputChange={setSearchInputValue}
      idForTesting="billings-cargo-order-listing-filter"
    />
  );
};

export default BillingsCargoOrderListingFiltersSelectComponent;
