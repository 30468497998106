import classNames from "classnames";
import { FC } from "react";
import BillingsTaxiUnbilledOrderListingItemStatus from "../../types/billings-taxi-unbilled-order-listing-item-status";

type BillingsTaxiUnbilledOrderListingTableStatusProps = {
  status: BillingsTaxiUnbilledOrderListingItemStatus;
};

const BillingsTaxiUnbilledOrderListingTableStatusComponent: FC<
  BillingsTaxiUnbilledOrderListingTableStatusProps
> = (props) => {
  const getClassnameByStatus = (): string => {
    switch (props.status) {
      case BillingsTaxiUnbilledOrderListingItemStatus.CANCELLED:
        return "cancelled";
      case BillingsTaxiUnbilledOrderListingItemStatus.FINISHED:
        return "finished";
    }
  };

  const statusClassname = getClassnameByStatus();

  const containerClassNames = classNames(
    "billings_taxi_unbilled_order_listing_table_status",
    statusClassname
  );

  return <div className={containerClassNames}></div>;
};

export default BillingsTaxiUnbilledOrderListingTableStatusComponent;
