import paginationService from "../../../../../../common/utils/pagination/pagination.service";
import BillingsTaxiTransferredOrderListingRequest, {
  BillingsTaxiTransferredOrderListingRequestOrder,
} from "../api/billings-taxi-transferred-order-listing.request";
import BillingsTaxiTransferredOrderListingFilter, {
  BillingsTaxiTransferredOrderListingConsumerTaxiFilter,
  BillingsTaxiTransferredOrderListingInternalOrderIdFilter,
  BillingsTaxiTransferredOrderListingOrderStartDateFilter,
  BillingsTaxiTransferredOrderListingProducerTaxiFilter,
  BillingsTaxiTransferredOrderListingStatusFilter,
} from "../types/billings-taxi-transferred-order-listing-filter";
import BillingsTaxiTransferredOrderListingFilterType from "../types/billings-taxi-transferred-order-listing-filter-type";
import BillingsTaxiTransferredOrderListingSortKey from "../types/billings-taxi-transferred-order-listing-sort-key";

const createRequestOrder = (
  sortKey: BillingsTaxiTransferredOrderListingSortKey | null
): BillingsTaxiTransferredOrderListingRequest["order"] => {
  const options: {
    sortKey: BillingsTaxiTransferredOrderListingSortKey;
    requestOrder: BillingsTaxiTransferredOrderListingRequestOrder;
  }[] = [
    {
      requestOrder: BillingsTaxiTransferredOrderListingRequestOrder.DATE_AT_ASC,
      sortKey: BillingsTaxiTransferredOrderListingSortKey.ORDER_START_DATE_ASC,
    },
    {
      requestOrder:
        BillingsTaxiTransferredOrderListingRequestOrder.DATE_AT_DESC,
      sortKey: BillingsTaxiTransferredOrderListingSortKey.ORDER_START_DATE_DESC,
    },
    {
      requestOrder:
        BillingsTaxiTransferredOrderListingRequestOrder.HUMAN_ID_ASC,
      sortKey: BillingsTaxiTransferredOrderListingSortKey.INTERNAL_ORDER_ID_ASC,
    },
    {
      requestOrder:
        BillingsTaxiTransferredOrderListingRequestOrder.HUMAN_ID_DESC,
      sortKey:
        BillingsTaxiTransferredOrderListingSortKey.INTERNAL_ORDER_ID_DESC,
    },
    {
      requestOrder:
        BillingsTaxiTransferredOrderListingRequestOrder.DISTANCE_DESC,
      sortKey: BillingsTaxiTransferredOrderListingSortKey.DISTANCE_DESC,
    },
    {
      requestOrder:
        BillingsTaxiTransferredOrderListingRequestOrder.DISTANCE_ASC,
      sortKey: BillingsTaxiTransferredOrderListingSortKey.DISTANCE_ASC,
    },
    {
      requestOrder:
        BillingsTaxiTransferredOrderListingRequestOrder.DISTANCE_RATE_ASC,
      sortKey: BillingsTaxiTransferredOrderListingSortKey.DISTANCE_RATE_ASC,
    },
    {
      requestOrder:
        BillingsTaxiTransferredOrderListingRequestOrder.DISTANCE_RATE_DESC,
      sortKey: BillingsTaxiTransferredOrderListingSortKey.DISTANCE_RATE_DESC,
    },
    {
      requestOrder:
        BillingsTaxiTransferredOrderListingRequestOrder.DISTANCE_AMOUNT_ASC,
      sortKey: BillingsTaxiTransferredOrderListingSortKey.DISTANCE_COST_ASC,
    },
    {
      requestOrder:
        BillingsTaxiTransferredOrderListingRequestOrder.DISTANCE_AMOUNT_DESC,
      sortKey: BillingsTaxiTransferredOrderListingSortKey.DISTANCE_COST_DESC,
    },
    {
      requestOrder:
        BillingsTaxiTransferredOrderListingRequestOrder.HALTING_AMOUNT_ASC,
      sortKey: BillingsTaxiTransferredOrderListingSortKey.STOPOVER_COST_ASC,
    },
    {
      requestOrder:
        BillingsTaxiTransferredOrderListingRequestOrder.HALTING_AMOUNT_DESC,
      sortKey: BillingsTaxiTransferredOrderListingSortKey.STOPOVER_COST_DESC,
    },
    {
      requestOrder:
        BillingsTaxiTransferredOrderListingRequestOrder.HIGHWAY_CHARGE_ASC,
      sortKey: BillingsTaxiTransferredOrderListingSortKey.TOLL_ROADS_COST_ASC,
    },
    {
      requestOrder:
        BillingsTaxiTransferredOrderListingRequestOrder.HIGHWAY_CHARGE_DESC,
      sortKey: BillingsTaxiTransferredOrderListingSortKey.TOLL_ROADS_COST_DESC,
    },
    {
      requestOrder:
        BillingsTaxiTransferredOrderListingRequestOrder.TOTAL_AMOUNT_ASC,
      sortKey: BillingsTaxiTransferredOrderListingSortKey.TOTAL_COST_ASC,
    },
    {
      requestOrder:
        BillingsTaxiTransferredOrderListingRequestOrder.TOTAL_AMOUNT_DESC,
      sortKey: BillingsTaxiTransferredOrderListingSortKey.TOTAL_COST_DESC,
    },
    {
      requestOrder:
        BillingsTaxiTransferredOrderListingRequestOrder.PRODUCER_ASC,
      sortKey: BillingsTaxiTransferredOrderListingSortKey.PRODUCER_ASC,
    },
    {
      requestOrder:
        BillingsTaxiTransferredOrderListingRequestOrder.PRODUCER_DESC,
      sortKey: BillingsTaxiTransferredOrderListingSortKey.PRODUCER_DESC,
    },
    {
      requestOrder:
        BillingsTaxiTransferredOrderListingRequestOrder.CONSUMER_ASC,
      sortKey: BillingsTaxiTransferredOrderListingSortKey.CONSUMER_ASC,
    },
    {
      requestOrder:
        BillingsTaxiTransferredOrderListingRequestOrder.CONSUMER_DESC,
      sortKey: BillingsTaxiTransferredOrderListingSortKey.CONSUMER_DESC,
    },
    {
      requestOrder:
        BillingsTaxiTransferredOrderListingRequestOrder.BILLING_MODEL_ASC,
      sortKey: BillingsTaxiTransferredOrderListingSortKey.BILLING_MODEL_ASC,
    },
    {
      requestOrder:
        BillingsTaxiTransferredOrderListingRequestOrder.BILLING_MODEL_DESC,
      sortKey: BillingsTaxiTransferredOrderListingSortKey.BILLING_MODEL_DESC,
    },
  ];

  return options.find((option) => option.sortKey === sortKey)?.requestOrder;
};

const createRequest = (
  taxiCorporationId: string | undefined,
  page: number,
  pageSize: number,
  filters: BillingsTaxiTransferredOrderListingFilter[],
  sortKey: BillingsTaxiTransferredOrderListingSortKey | null
): BillingsTaxiTransferredOrderListingRequest => {
  const offset = paginationService.calculateOffset(page, pageSize);

  return {
    limit: pageSize,
    offset,
    order: createRequestOrder(sortKey),
    taxi_corporation_id: taxiCorporationId,
    human_id: filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiTransferredOrderListingFilterType.INTERNAL_ORDER_ID
    )?.value as
      | BillingsTaxiTransferredOrderListingInternalOrderIdFilter["value"]
      | undefined,
    producer: filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiTransferredOrderListingFilterType.PRODUCER_TAXI
    )?.value as
      | BillingsTaxiTransferredOrderListingProducerTaxiFilter["value"]
      | undefined,
    consumer: filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiTransferredOrderListingFilterType.CONSUMER_TAXI
    )?.value as
      | BillingsTaxiTransferredOrderListingConsumerTaxiFilter["value"]
      | undefined,
    start_time_since: (
      filters.find(
        (filter) =>
          filter.type ===
          BillingsTaxiTransferredOrderListingFilterType.ORDER_START_DATE
      )?.value as
        | BillingsTaxiTransferredOrderListingOrderStartDateFilter["value"]
        | undefined
    )?.from.toJSON(),
    start_time_to: (
      filters.find(
        (filter) =>
          filter.type ===
          BillingsTaxiTransferredOrderListingFilterType.ORDER_START_DATE
      )?.value as
        | BillingsTaxiTransferredOrderListingOrderStartDateFilter["value"]
        | undefined
    )?.to.toJSON(),
    status: filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiTransferredOrderListingFilterType.BILLING_STATUS
    )?.value as
      | BillingsTaxiTransferredOrderListingStatusFilter["value"]
      | undefined,
  };
};

const billingsTaxiTransferredOrderListingRequestFactory = {
  createRequest,
};

export default billingsTaxiTransferredOrderListingRequestFactory;
