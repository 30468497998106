import { round } from "lodash";
import { FC, useMemo } from "react";
import TableComponent, { TableProps } from "../../../../common/components/table/table.component";
import { useAppContext } from "../../../../context/app.context";
import { OrderOptimizerDetailsItemOptimizedStats, OrderOptimizerDetailsItemUnoptimizedStats } from "../common/types/order-optimizer-details-item";
import OrderOptimizerDetailsWithoutDriverTableRow from "../common/types/order-optimizer-details-without-driver-table-row";
import orderOptimizerDetailsWithoutDriverTableHelper from "./order-optimizer-details-without-driver-table.helper";
import uuidService from "../../../../common/utils/uuid/uuid.service";
import orderOptimizerTranslationsHelper from "../../../../languages/order-optimizer-translations.helper";

type OrderOptimizerDetailsWithoutDriverTableProps = Pick<TableProps, "isError" | "isLoading"> & {
  detailsOptimizedStatsItem: OrderOptimizerDetailsItemOptimizedStats;
  detailsUnoptimizedStatsItem: OrderOptimizerDetailsItemUnoptimizedStats;
};

const OrderOptimizerDetailsWithoutDriverTableComponent: FC<OrderOptimizerDetailsWithoutDriverTableProps> = (props) => {
  const { selectedAppLanguage } = useAppContext();

  const columns = useMemo(() => {
    return orderOptimizerDetailsWithoutDriverTableHelper.getColumns();
  }, [selectedAppLanguage]);

  const createOptimizedStatsTableRow = (
    detailsOptimizedStatsItem: OrderOptimizerDetailsItemOptimizedStats
): OrderOptimizerDetailsWithoutDriverTableRow => {
    const distanceLabel = `${round(detailsOptimizedStatsItem.distance, 1)} KM`;
    const distanceTitle = `${round(detailsOptimizedStatsItem.distance, 1)} KM`;

    const totalTimeLabel = `${detailsOptimizedStatsItem.totalTime.toFixed(2)} h`;
    const totalTimeTitle = `${detailsOptimizedStatsItem.totalTime.toFixed(2)} h`;

    const haltingTimeLabel = `${detailsOptimizedStatsItem.totalTime.toFixed(2)} h`;
    const haltingTimeTitle = `${detailsOptimizedStatsItem.totalTime.toFixed(2)} h`;

    const contractRateLabel = `${detailsOptimizedStatsItem.contractRate.toFixed(2)} zł`;
    const contractRateTitle = `${detailsOptimizedStatsItem.contractRate.toFixed(2)} zł`;

    const marginLabel = `${detailsOptimizedStatsItem.margin}%`;
    const marginTitle = `${detailsOptimizedStatsItem.margin}%`;

    const translations =
        orderOptimizerTranslationsHelper.getDetailsTranslations().table.withoutDriver;

    return {
      id: uuidService.generate(), //rozwiazanie tymczasowe
      value: {
        withoutDriver: (
          <div title={translations.sumOfOrdersTitle}>
            {translations.sumOfOrdersLabel}
          </div>
        ),
        billingModel: (
          <div title="ABA">
            ABA
          </div>
        ),
        contractRate: (
          <div title={contractRateTitle}>
            {contractRateLabel}
          </div>
        ),
        totalTime: (
          <div title={totalTimeTitle}>
            {totalTimeLabel}
          </div>
        ),
        haltingTime: (
          <div title={haltingTimeTitle}>{haltingTimeLabel}</div>
        ),
        distance: (
          <div title={distanceTitle}>{distanceLabel}</div>
        ),
        margin: (
          <div title={marginTitle}>{marginLabel}</div>
        ),
      },
    };
  };

  const createUnoptimizedStatsTableRow = (
    detailsUnoptimizedStatsItem: OrderOptimizerDetailsItemUnoptimizedStats
): OrderOptimizerDetailsWithoutDriverTableRow => {
    const distanceLabel = `${round(detailsUnoptimizedStatsItem.distance, 1)} KM`;
    const distanceTitle = `${round(detailsUnoptimizedStatsItem.distance, 1)} KM`;

    const totalTimeLabel = `${detailsUnoptimizedStatsItem.totalTime.toFixed(2)} h`;
    const totalTimeTitle = `${detailsUnoptimizedStatsItem.totalTime.toFixed(2)} h`;

    const haltingTimeLabel = `${detailsUnoptimizedStatsItem.totalTime.toFixed(2)} h`;
    const haltingTimeTitle = `${detailsUnoptimizedStatsItem.totalTime.toFixed(2)} h`;

    const contractRateLabel = `${detailsUnoptimizedStatsItem.contractRate.toFixed(2)} zł`;
    const contractRateTitle = `${detailsUnoptimizedStatsItem.contractRate.toFixed(2)} zł`;

    const marginLabel = `${detailsUnoptimizedStatsItem.margin}%`;
    const marginTitle = `${detailsUnoptimizedStatsItem.margin}%`;

    const translations =
        orderOptimizerTranslationsHelper.getDetailsTranslations().table.withoutDriver;

    return {
      id: uuidService.generate(), //rozwiazanie tymczasowe
      value: {
        withoutDriver: (
          <div title={translations.routeTitle}>
            {translations.routeLabel}
          </div>
        ),
        billingModel: (
          <div title="ABA">
            ABA
          </div>
        ),
        contractRate: (
          <div title={contractRateTitle}>
            {contractRateLabel}
          </div>
        ),
        totalTime: (
          <div title={totalTimeTitle}>
            {totalTimeLabel}
          </div>
        ),
        haltingTime: (
          <div title={haltingTimeTitle}>{haltingTimeLabel}</div>
        ),
        distance: (
          <div title={distanceTitle}>{distanceLabel}</div>
        ),
        margin: (
          <div title={marginTitle}>{marginLabel}</div>
        ),
      },
    };
  };

  const rows: OrderOptimizerDetailsWithoutDriverTableRow[] = useMemo(() => {
    const optimizedRows = createOptimizedStatsTableRow(props.detailsOptimizedStatsItem);

    const unoptimizedRows = createUnoptimizedStatsTableRow(props.detailsUnoptimizedStatsItem)

    return [optimizedRows, unoptimizedRows];
  }, [props.detailsOptimizedStatsItem, props.detailsUnoptimizedStatsItem, selectedAppLanguage]);


  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isLoading={props.isLoading}
      isError={props.isError}
    />
  );
};

export default OrderOptimizerDetailsWithoutDriverTableComponent;