import { FC, useEffect, useMemo, useState } from "react";
import DriverContractAddViewBasicProps from "../common/types/driver-contract-add-view-basic-props";
import DriverContractAddByTaxiFormData from "./common/types/driver-contract-add-by-taxi-form-data";
import driverContractAddByTaxiFormHelper from "./common/driver-contract-add-by-taxi-form.helper";
import driverContractAddFactory from "../common/driver-contract-add.factory";
import driverContractAddFormValidationService from "../common/driver-contract-add-form-validation.service";
import DriverContractAddBillingModelSelectOption from "../common/types/driver-contract-add-billing-model-select-option";
import driverContractAddByTaxiApiService from "./common/api/driver-contract-add-by-taxi-api.service";
import DriverContractAddResponse from "../common/api/driver-contract-add.response";
import DriverContractAddRequest from "../common/api/driver-contract-add.request";
import DriverContractAddByTaxiFormValidationResults from "./common/types/driver-contract-add-by-taxi-form-validation-results";
import ButtonComponent from "../../../../../../common/components/button/button.component";
import CardComponent from "../../../../../../common/components/card/card.component";
import DateRange from "../../../../../../common/types/date-range";
import FormFieldComponent from "../../../../../../common/components/form/field/form-field.component";
import NumericInputComponent from "../../../../../../common/components/form/input/numeric-input/numeric-input.component";
import Column from "../../../../../../common/components/grid/column";
import Row from "../../../../../../common/components/grid/row";
import HeadingComponent from "../../../../../../common/components/heading/heading.component";
import notificationService from "../../../../../../common/utils/notification/notification.service";
import userTranslationsHelper from "../../../../../../languages/user-translations.helper";
import DriverContractAddTaxiAssocationsResponse, {
  DriverContractAddTaxiAssociationsResponseDataItem,
} from "../common/api/driver-contract-add-taxi-assocations.response";
import { useAppContext } from "../../../../../../context/app.context";
import userRoutesHelper from "../../../../common/routes/user-routes.helper";
import { useNavigate } from "react-router-dom";
import DateRangeInputComponent from "../../../../../../common/components/form/input/date-range/date-range-input.component";
import SingleSelectComponent from "../../../../../../common/components/form/select/single-select/single-select.component";
import BooleanSelectComponent from "../../../../../../common/components/form/select/boolean/boolean-select.component";
import InputComponent from "../../../../../../common/components/form/input/input.component";

type DriverContractAddByTaxiProps = DriverContractAddViewBasicProps;

const DriverContractAddByTaxiComponent: FC<DriverContractAddByTaxiProps> = (
  props
) => {
  const { user } = useAppContext();
  const navigate = useNavigate();

  const translations =
    userTranslationsHelper.getDriverContractAddTranslations();

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const [formData, setFormData] = useState<DriverContractAddByTaxiFormData>(
    driverContractAddByTaxiFormHelper.getDefaultFormData()
  );

  const [taxiAssocationUuid, setTaxiAssocationUuid] = useState("");
  const [areTaxiAssociationsFetching, setAreTaxiAssociationsFetching] =
    useState(false);
  const [isTaxiAssociationsFetchingError, setIsTaxiAssociationsFetchingError] =
    useState(false);
  const [formValidationResults, setFormValidationResults] =
    useState<DriverContractAddByTaxiFormValidationResults>(() =>
      driverContractAddByTaxiFormHelper.getDefaultFormValidationResults()
    );

  const billingModelSelectOptions: DriverContractAddBillingModelSelectOption[] =
    useMemo(
      () => driverContractAddFactory.createBillingModelSelectOptions(),
      []
    );

  const onTaxiAssociationsFetchSuccess = (
    responseTaxiAssocations: DriverContractAddTaxiAssociationsResponseDataItem[]
  ) => {
    const taxiCorporationUuid =
      user?.aspects.dealer?.taxiCorporationUuid ||
      user?.aspects.taxiOfficer?.taxiCorporationUuid!;

    const taxiAssocationUuid = responseTaxiAssocations.find(
      (assocation) => assocation.taxi_corporation.id === taxiCorporationUuid
    )?.id;

    setTaxiAssocationUuid(taxiAssocationUuid ?? "");
  };

  const onTaxiAssociationsFetchFailure = () => {
    setIsTaxiAssociationsFetchingError(true);
  };

  const handleTaxiAssocationsResponse = (
    response: DriverContractAddTaxiAssocationsResponse
  ) => {
    if (response.status === 200) {
      onTaxiAssociationsFetchSuccess(response.data);
      return;
    }

    setIsTaxiAssociationsFetchingError(true);
  };

  const fetchTaxiAssociations = () => {
    setAreTaxiAssociationsFetching(true);
    setIsTaxiAssociationsFetchingError(false);

    driverContractAddByTaxiApiService
      .fetchTaxiAssociations(props.driverUuid!)
      .then(handleTaxiAssocationsResponse)
      .catch(onTaxiAssociationsFetchFailure)
      .finally(() => {
        setAreTaxiAssociationsFetching(false);
      });
  };

  useEffect(() => {
    if (!props.driverUuid) {
      return;
    }

    fetchTaxiAssociations();
  }, [props.driverUuid]);

  const validateBillingModel = (
    value: DriverContractAddByTaxiFormData["billingModel"] = formData.billingModel
  ) => {
    const validationResult =
      driverContractAddFormValidationService.validateBillingModel(value);

    setFormValidationResults((curr) => ({
      ...curr,
      billingModel: validationResult,
    }));

    return validationResult.isValid;
  };

  const validatePeriodOfValidity = (
    value: DriverContractAddByTaxiFormData["periodOfValidity"] = formData.periodOfValidity
  ) => {
    const validationResult =
      driverContractAddFormValidationService.validatePeriodOfValidity(value);

    setFormValidationResults((curr) => ({
      ...curr,
      periodOfValidity: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateDistanceRate = (
    value: DriverContractAddByTaxiFormData["distanceRate"] = formData.distanceRate
  ) => {
    const validationResult =
      driverContractAddFormValidationService.validateDistanceRate(value);

    setFormValidationResults((curr) => ({
      ...curr,
      distanceRate: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateHaltingTimeGracePeriodMinutes = (
    value: DriverContractAddByTaxiFormData["haltingTimeGracePeriodMinutes"] = formData.haltingTimeGracePeriodMinutes
  ) => {
    const validationResult =
      driverContractAddFormValidationService.validateHaltingTimeGracePeriodMinutes(
        value
      );

    setFormValidationResults((curr) => ({
      ...curr,
      haltingTimeGracePeriod: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateIsActive = (
    value: DriverContractAddByTaxiFormData["isActive"] = formData.isActive
  ) => {
    const validationResult =
      driverContractAddFormValidationService.validateIsActive(value);

    setFormValidationResults((curr) => ({
      ...curr,
      isActive: validationResult,
    }));

    return validationResult.isValid;
  };

  const validateHaltingTimeRate = (
    value: DriverContractAddByTaxiFormData["haltingTimeRate"] = formData.haltingTimeRate
  ) => {
    const validationResult =
      driverContractAddFormValidationService.validateHaltingTimeRate(value);

    setFormValidationResults((curr) => ({
      ...curr,
      haltingTimeRate: validationResult,
    }));

    return validationResult.isValid;
  };

  const checkIsFormValid = () => {
    const isBillingModelValid = validateBillingModel();
    const isPeriodOfValidityValid = validatePeriodOfValidity();
    const isDistanceRateValid = validateDistanceRate();
    const isHaltingTimeGracePeriodMinutesValid =
      validateHaltingTimeGracePeriodMinutes();
    const isHaltingTimeRateValid = validateHaltingTimeRate();
    const isActiveValid = validateIsActive();

    return (
      isBillingModelValid &&
      isPeriodOfValidityValid &&
      isDistanceRateValid &&
      isHaltingTimeGracePeriodMinutesValid &&
      isHaltingTimeRateValid &&
      isActiveValid
    );
  };

  const onContractAddSuccess = () => {
    notificationService.success(translations.successAddNotificationText);

    navigate(
      userRoutesHelper.getDriverContractListingRoute({
        driverUuid: props.driverUuid!,
      })
    );
  };

  const onContractAddFailure = () => {
    notificationService.error(translations.failureAddNotificationText);
  };

  const handleContractAddResponse = (response: DriverContractAddResponse) => {
    if (response.status === 201) {
      onContractAddSuccess();
      return;
    }
    onContractAddFailure();
  };

  const submitForm = () => {
    const isFormValid = checkIsFormValid();

    if (!isFormValid) return;

    setIsFormSubmitting(true);

    const request: DriverContractAddRequest =
      driverContractAddFactory.createContractAddRequest(
        formData,
        taxiAssocationUuid
      );

    driverContractAddByTaxiApiService
      .addDriverContract(request)
      .then(handleContractAddResponse)
      .catch(onContractAddFailure)
      .finally(() => setIsFormSubmitting(false));
  };

  const setBillingModel = (
    value: DriverContractAddByTaxiFormData["billingModel"]
  ) => setFormData((curr) => ({ ...curr, billingModel: value }));

  const setPeriodOfValidity = (
    value: DriverContractAddByTaxiFormData["periodOfValidity"]
  ) => setFormData((curr) => ({ ...curr, periodOfValidity: value }));

  const setDistanceRate = (
    value: DriverContractAddByTaxiFormData["distanceRate"] | undefined
  ) => setFormData((curr) => ({ ...curr, distanceRate: value ?? null }));

  const setHaltingTimeGracePeriodMinutes = (
    value:
      | DriverContractAddByTaxiFormData["haltingTimeGracePeriodMinutes"]
      | undefined
  ) =>
    setFormData((curr) => ({
      ...curr,
      haltingTimeGracePeriodMinutes: value ?? null,
    }));

  const setHaltingTimeRate = (
    value: DriverContractAddByTaxiFormData["haltingTimeRate"]
  ) => setFormData((curr) => ({ ...curr, haltingTimeRate: value }));

  const setIsActive = (value: DriverContractAddByTaxiFormData["isActive"]) =>
    setFormData((curr) => ({
      ...curr,
      isActive: value,
    }));

  const setNotes = (
    value: DriverContractAddByTaxiFormData["notes"] | undefined
  ) => setFormData((curr) => ({ ...curr, notes: value ?? null }));

  const onModelChange = (
    value: DriverContractAddByTaxiFormData["billingModel"]
  ) => {
    setBillingModel(value);
    validateBillingModel(value);
  };

  const onPeriodOfValidityChange = (date: DateRange | null) => {
    if (!date) {
      setPeriodOfValidity(null);
      return;
    }

    setPeriodOfValidity(date);
    validatePeriodOfValidity(date);
  };

  const validateNotes = (
    value: DriverContractAddByTaxiFormData["notes"] = formData.notes
  ) => {
    const validationResult =
      driverContractAddFormValidationService.validateNotes(value!);

    setFormValidationResults((curr) => ({
      ...curr,
      notes: validationResult,
    }));

    return validationResult.isValid;
  };

  return (
    <div className="driver_contract_add">
      <HeadingComponent
        title={
          props.driverName
            ? translations.header.headingTextWithName.replace(
                "#{driverName}",
                props.driverName
              )
            : translations.header.headingText
        }
        actions={props.changeViewButtons}
      />
      <Row>
        <Column xl={8}>
          <CardComponent>
            <Row>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.periodOfValidityLabel}
                  classNames={{ root: `mt-0` }}
                  isRequired
                  errorMessage={
                    formValidationResults.periodOfValidity.errorMessage
                  }
                >
                  <DateRangeInputComponent
                    onChange={onPeriodOfValidityChange}
                    date={formData.periodOfValidity}
                    hasError={
                      !!formValidationResults.periodOfValidity.errorMessage
                    }
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.billingModelLabel}
                  classNames={{ root: `mt-0` }}
                  isRequired
                  errorMessage={formValidationResults.billingModel.errorMessage}
                >
                  <SingleSelectComponent
                    placeholder={translations.form.billingModelPlaceholder}
                    value={formData.billingModel}
                    onChange={(value) => onModelChange(value)}
                    options={billingModelSelectOptions}
                    hasError={!!formValidationResults.billingModel.errorMessage}
                    idForTesting={`billing-model`}
                  />
                </FormFieldComponent>
              </Column>
            </Row>
            <Row>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.distanceRateLabel}
                  isRequired
                  errorMessage={formValidationResults.distanceRate.errorMessage}
                >
                  <NumericInputComponent
                    placeholder={translations.form.distanceRatePlaceholder}
                    value={formData.distanceRate}
                    onChange={setDistanceRate}
                    onBlur={validateDistanceRate}
                    hasError={!!formValidationResults.distanceRate.errorMessage}
                    decimalPrecision={2}
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.haltingTimeRateLabel}
                  isRequired
                  errorMessage={
                    formValidationResults.haltingTimeRate.errorMessage
                  }
                >
                  <NumericInputComponent
                    value={formData.haltingTimeRate}
                    placeholder={translations.form.haltingTimeRatePlaceholder}
                    onChange={setHaltingTimeRate}
                    onBlur={validateHaltingTimeRate}
                    hasError={
                      !!formValidationResults.haltingTimeGracePeriod
                        .errorMessage
                    }
                    decimalPrecision={2}
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.haltingTimeGracePeriodLabel}
                  isRequired
                  errorMessage={
                    formValidationResults.haltingTimeGracePeriod.errorMessage
                  }
                >
                  <NumericInputComponent
                    value={formData.haltingTimeGracePeriodMinutes}
                    placeholder={
                      translations.form.haltingTimeGracePeriodPlaceholder
                    }
                    onChange={setHaltingTimeGracePeriodMinutes}
                    onBlur={validateHaltingTimeGracePeriodMinutes}
                    isIntegerOnly
                    hasError={
                      !!formValidationResults.haltingTimeGracePeriod
                        .errorMessage
                    }
                  />
                </FormFieldComponent>
              </Column>
              <Column lg={6}>
                <FormFieldComponent
                  label={translations.form.isActiveLabel}
                  isRequired
                  errorMessage={formValidationResults.isActive.errorMessage}
                >
                  <BooleanSelectComponent
                    value={formData.isActive}
                    placeholder={translations.form.isActivePlaceholder}
                    onChange={setIsActive}
                    onBlur={validateIsActive}
                    isError={!!formValidationResults.isActive.errorMessage}
                  />
                </FormFieldComponent>
              </Column>
              <Column md={6}>
                <FormFieldComponent
                  label={translations.form.notesLabel}
                  errorMessage={formValidationResults.notes.errorMessage}
                >
                  <InputComponent
                    placeholder={translations.form.notesPlaceholder}
                    value={formData.notes!}
                    onChange={setNotes}
                    onBlur={validateNotes}
                    hasError={!!formValidationResults.notes.errorMessage}
                    idForTesting="notes-input"
                  />
                </FormFieldComponent>
              </Column>
            </Row>
          </CardComponent>
        </Column>
      </Row>
      <ButtonComponent
        onClick={submitForm}
        type="primary"
        isDisabled={isFormSubmitting}
        classNames={{ root: "mt-4" }}
        idForTesting={`submit-button`}
        title={translations.form.submitButtonTitle}
      >
        {translations.form.submitButtonText}
      </ButtonComponent>
    </div>
  );
};

export default DriverContractAddByTaxiComponent;
