import ListingFilterBadge from "../../../../../../../common/components/listing/filter/badge/listing-filter-badge";
import dateService from "../../../../../../../common/utils/date/date.service";
import billingsTranslationsHelper from "../../../../../../../languages/billings-translations.helper";
import BillingsTaxiRouteListingFilter, {
  BillingsTaxiRouteListingBillingModelFilter,
  BillingsTaxiRouteListingCargoOrderInternalIdFilter,
  BillingsTaxiRouteListingDriverFilter,
  BillingsTaxiRouteListingFleetPartnerFilter,
  BillingsTaxiRouteListingRouteIdFilter,
  BillingsTaxiRouteListingRouteStartDateFilter,
} from "../../types/billings-taxi-route-listing-filter";
import BillingsTaxiRouteListingFilterType from "../../types/billings-taxi-route-listing-filter-type";

const getRouteStartDateBadgeOption = (
  filter: BillingsTaxiRouteListingRouteStartDateFilter
): {
  badge: ListingFilterBadge;
  filter: BillingsTaxiRouteListingRouteStartDateFilter;
} => {
  const translations =
    billingsTranslationsHelper.getTaxiRouteBillingsListingTranslations()
      .filters;

  const formattedFromDate = dateService.formatDate(filter.value.from);

  const formattedToDate = dateService.formatDate(filter.value.to);

  return {
    badge: {
      text: translations.routeStartDateFilterText
        .replace("#{fromDate}", formattedFromDate)
        .replace("#{toDate}", formattedToDate),
      title: translations.routeStartDateFilterTitle
        .replace("#{fromDate}", formattedFromDate)
        .replace("#{toDate}", formattedToDate),
    },
    filter: {
      type: BillingsTaxiRouteListingFilterType.ORDER_START_DATE,
      value: filter.value,
    },
  };
};

const getBadgeOptions = (filter: BillingsTaxiRouteListingFilter) => {
  const translations =
    billingsTranslationsHelper.getTaxiRouteBillingsListingTranslations()
      .filters;

  const options: {
    badge: ListingFilterBadge;
    filter: BillingsTaxiRouteListingFilter;
  }[] = [
    {
      badge: {
        text: translations.billingModelFilterText.replace(
          "#{option}",
          filter.value as BillingsTaxiRouteListingBillingModelFilter["value"]
        ),
        title: translations.billingModelFilterTitle.replace(
          "#{option}",
          filter.value as BillingsTaxiRouteListingBillingModelFilter["value"]
        ),
      },
      filter: {
        type: BillingsTaxiRouteListingFilterType.BILLING_MODEL,
        value:
          filter.value as BillingsTaxiRouteListingBillingModelFilter["value"],
      },
    },
    {
      badge: {
        text: translations.driverFilterText.replace(
          "#{query}",
          filter.value as BillingsTaxiRouteListingDriverFilter["value"]
        ),
        title: translations.driverFilterTitle.replace(
          "#{query}",
          filter.value as BillingsTaxiRouteListingDriverFilter["value"]
        ),
      },
      filter: {
        type: BillingsTaxiRouteListingFilterType.DRIVER,
        value: filter.value as BillingsTaxiRouteListingDriverFilter["value"],
      },
    },
    {
      badge: {
        text: translations.routeIdFilterText.replace(
          "#{query}",
          String(filter.value as BillingsTaxiRouteListingRouteIdFilter["value"])
        ),
        title: translations.routeIdFilterTitle.replace(
          "#{query}",
          String(filter.value as BillingsTaxiRouteListingRouteIdFilter["value"])
        ),
      },
      filter: {
        type: BillingsTaxiRouteListingFilterType.ROUTE_ID,
        value: filter.value as BillingsTaxiRouteListingRouteIdFilter["value"],
      },
    },
    {
      badge: {
        text: translations.fleetPartnerFilterText.replace(
          "#{query}",
          filter.value as BillingsTaxiRouteListingFleetPartnerFilter["value"]
        ),
        title: translations.fleetPartnerFilterTitle.replace(
          "#{query}",
          filter.value as BillingsTaxiRouteListingFleetPartnerFilter["value"]
        ),
      },
      filter: {
        type: BillingsTaxiRouteListingFilterType.FLEET_PARTNER,
        value:
          filter.value as BillingsTaxiRouteListingFleetPartnerFilter["value"],
      },
    },
    {
      badge: {
        text: translations.cargoOrderInternalIdFilterLabel.replace(
          "#{query}",
          String(
            filter.value as BillingsTaxiRouteListingCargoOrderInternalIdFilter["value"]
          )
        ),
        title: translations.cargoOrderInternalIdFilterTitle.replace(
          "#{query}",
          String(
            filter.value as BillingsTaxiRouteListingCargoOrderInternalIdFilter["value"]
          )
        ),
      },
      filter: {
        type: BillingsTaxiRouteListingFilterType.CARGO_ORDER_INTERNAL_ID,
        value:
          filter.value as BillingsTaxiRouteListingCargoOrderInternalIdFilter["value"],
      },
    },
    getRouteStartDateBadgeOption(
      filter as BillingsTaxiRouteListingRouteStartDateFilter
    ),
  ];

  return options;
};

const getBadge = (filter: BillingsTaxiRouteListingFilter) => {
  const options = getBadgeOptions(filter);

  return options.find(
    (item) =>
      item.filter.type === filter.type && item.filter.value === filter.value
  )?.badge;
};

const getBadges = (
  filters: BillingsTaxiRouteListingFilter[]
): ListingFilterBadge[] => {
  const badges: ListingFilterBadge[] = [];

  filters.forEach((filter) => {
    const badge = getBadge(filter);

    if (!badge) {
      return;
    }

    badges.push(badge);
  });

  return badges;
};

const billingsTaxiRouteListingFiltersBadgeListHelper = {
  getBadges,
};

export default billingsTaxiRouteListingFiltersBadgeListHelper;
