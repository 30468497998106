import {
  PlanEntryDetailsResponseCheckoutEvent,
  PlanEntryDetailsResponseDriver,
  PlanEntryDetailsResponseItem,
  PlanEntryDetailsResponseNodeExclusion,
  PlanEntryDetailsResponseNodeExclusions,
  PlanEntryDetailsResponseRideNode,
  PlanEntryDetailsResponseRideNodeMeta,
  PlanEntryDetailsResponseSolvedOrder,
  PlanEntryDetailsResponseSolvedOrderCargoCompany,
  PlanEntryDetailsResponseSolvedOrderRide,
  PlanEntryDetailsResponseSolvedOrderTransportingOrder,
  PlanEntryDetailsResponseSolvedOrderTransportingOrderCargoOrder,
} from "../../../api/raily/plan-entry/details/plan-entry-details.response";
import PlanEntryDetails, {
  PlanEntryDetailsCheckoutEvent,
  PlanEntryDetailsDriver,
  PlanEntryDetailsNodeExclusion,
  PlanEntryDetailsNodeExclusions,
  PlanEntryDetailsRideNode,
  PlanEntryDetailsRideNodeMeta,
  PlanEntryDetailsSolvedOrder,
  PlanEntryDetailsSolvedOrderCargoCompany,
  PlanEntryDetailsSolvedOrderRide,
  PlanEntryDetailsSolvedOrderTransportingOrder,
  PlanEntryDetailsSolvedOrderTransportingOrderCargoOrder,
} from "./plan-entry-details";

const createSolvedOrderRideNodeMeta = (
  meta: PlanEntryDetailsResponseRideNodeMeta
): PlanEntryDetailsRideNodeMeta => {
  return {
    estimatedTime: meta.estimatedTime,
    id: meta.id,
  };
};

const createSolvedOrderRideNode = (
  node: PlanEntryDetailsResponseRideNode
): PlanEntryDetailsRideNode => {
  return {
    displayName: node.displayName,
    haltingTime: node.haltingTime,
    lat: node.lat,
    lon: node.lon,
    meta: createSolvedOrderRideNodeMeta(node.meta),
    time: node.time,
  };
};

const createSolvedOrderRideNodes = (
  nodes: PlanEntryDetailsResponseRideNode[]
): PlanEntryDetailsRideNode[] => {
  return nodes.map(createSolvedOrderRideNode);
};

const createSolvedOrderRide = (
  ride: PlanEntryDetailsResponseSolvedOrderRide
): PlanEntryDetailsSolvedOrderRide => {
  return {
    seq: createSolvedOrderRideNodes(ride.seq),
  };
};

const createSolvedOrderTransportingOrderCargoOrder = (
  cargoOrder: PlanEntryDetailsResponseSolvedOrderTransportingOrderCargoOrder
): PlanEntryDetailsSolvedOrderTransportingOrderCargoOrder => {
  return {
    humanId: cargoOrder.humanId,
    id: cargoOrder.id,
  };
};

const createSolvedOrderTransportingOrder = (
  transportingOrder: PlanEntryDetailsResponseSolvedOrderTransportingOrder
): PlanEntryDetailsSolvedOrderTransportingOrder => {
  return {
    cargoOrder: createSolvedOrderTransportingOrderCargoOrder(
      transportingOrder.cargoOrder
    ),
  };
};

const createSolvedOrderCargoCompany = (
  cargoCompany: PlanEntryDetailsResponseSolvedOrderCargoCompany
): PlanEntryDetailsSolvedOrderCargoCompany => {
  return {
    displayName: cargoCompany.displayName,
    id: cargoCompany.id,
  };
};

const createSolvedOrder = (
  solvedOrder: PlanEntryDetailsResponseSolvedOrder
): PlanEntryDetailsSolvedOrder => {
  return {
    cargoCompany: createSolvedOrderCargoCompany(solvedOrder.cargoCompany),
    id: solvedOrder.id,
    ride: createSolvedOrderRide(solvedOrder.ride),
    transportingOrder: createSolvedOrderTransportingOrder(
      solvedOrder.transportingOrder
    ),
  };
};

const createSolvedOrders = (
  solvedOrders: PlanEntryDetailsResponseSolvedOrder[]
): PlanEntryDetailsSolvedOrder[] => {
  return solvedOrders.map(createSolvedOrder);
};

const createNodeExclusionsItem = (
  nodeExclusionsItem: PlanEntryDetailsResponseNodeExclusion
): PlanEntryDetailsNodeExclusion => {
  return {
    excludeApproaching: nodeExclusionsItem.excludeApproaching,
    excludeInside: nodeExclusionsItem.excludeInside,
    excludeReturning: nodeExclusionsItem.excludeReturning,
    nodeIds: nodeExclusionsItem.nodeIds,
  };
};

const createNodeExclusionsItems = (
  nodeExclusionsItems: PlanEntryDetailsResponseNodeExclusion[]
): PlanEntryDetailsNodeExclusion[] => {
  return nodeExclusionsItems.map(createNodeExclusionsItem);
};

const createNodeExclusions = (
  nodeExclusions: PlanEntryDetailsResponseNodeExclusions
): PlanEntryDetailsNodeExclusions => {
  return {
    exclusions: createNodeExclusionsItems(nodeExclusions.exclusions),
  };
};

const createDriver = (
  driver: PlanEntryDetailsResponseDriver
): PlanEntryDetailsDriver => {
  return {
    firstName: driver.firstName,
    id: driver.id,
    lastName: driver.lastName,
  };
};

const createCheckoutEvent = (
  checkoutsEvent: PlanEntryDetailsResponseCheckoutEvent
): PlanEntryDetailsCheckoutEvent => {
  return {
    achievedAt: checkoutsEvent.achievedAt,
    createdAt: checkoutsEvent.createdAt,
    createdByName: checkoutsEvent.createdByName,
    haltingTime: checkoutsEvent.haltingTime,
    highwayCharge: checkoutsEvent.highwayCharge,
    id: checkoutsEvent.id,
    nodeId: checkoutsEvent.nodeId,
  };
};

const createCheckoutEvents = (
  checkoutsEvents: PlanEntryDetailsResponseCheckoutEvent[]
): PlanEntryDetailsCheckoutEvent[] => {
  return checkoutsEvents.map(createCheckoutEvent);
};

const create = (
  responseItem: PlanEntryDetailsResponseItem
): PlanEntryDetails => {
  return {
    checkoutEvents: createCheckoutEvents(responseItem.checkoutEvents),
    driver: createDriver(responseItem.driver),
    endNode: createSolvedOrderRideNode(responseItem.endNode),
    humanId: responseItem.humanId,
    id: responseItem.id,
    nodeExclusions: createNodeExclusions(responseItem.nodeExclusions),
    solvedOrders: createSolvedOrders(responseItem.solvedOrders),
    startNode: createSolvedOrderRideNode(responseItem.startNode),
  };
};

const cargoOrderNodeExclusionFactory = { create };

export default cargoOrderNodeExclusionFactory;
