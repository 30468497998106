import Joi from "joi";
import formValidationService from "../../../../../../common/utils/validation/form-validation.service";
import FormValidationResult from "../../../../../../common/utils/validation/types/form-validation-result";
import DriverContractAddFormData from "./types/driver-contract-add-form-data";

const validatePeriodOfValidity = (
  value: DriverContractAddFormData["periodOfValidity"]
): FormValidationResult => {
  const validationSchema = Joi.object<
    DriverContractAddFormData["periodOfValidity"]
  >()
    .keys({
      from: Joi.date().min("1970-01-01").required(),
      to: Joi.date().required(),
    })
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateBillingModel = (
  value: DriverContractAddFormData["billingModel"]
): FormValidationResult => {
  const validationSchema = Joi.object().required();

  return formValidationService.validate(value, validationSchema);
};

const validateDistanceRate = (
  value: DriverContractAddFormData["distanceRate"]
): FormValidationResult => {
  const validationSchema = Joi.number()
    .min(0)
    .less(100)
    .precision(2)
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateHaltingTimeRate = (
  value: DriverContractAddFormData["haltingTimeRate"]
): FormValidationResult => {
  const validationSchema = Joi.number()
    .min(0)
    .less(100)
    .precision(2)
    .required();

  return formValidationService.validate(value, validationSchema);
};

const validateHaltingTimeGracePeriodMinutes = (
  value: DriverContractAddFormData["haltingTimeGracePeriodMinutes"]
): FormValidationResult => {
  const validationSchema = Joi.number().min(0).max(60).required();

  return formValidationService.validate(value, validationSchema);
};

const validateIsActive = (
  value: DriverContractAddFormData["isActive"]
): FormValidationResult => {
  const validationSchema = Joi.any().invalid(null).required();

  return formValidationService.validate(value, validationSchema);
};

const validateNotes = (notes: string): FormValidationResult => {
  const validationSchema = Joi.string().allow("").max(100).trim().required();

  return formValidationService.validate(notes, validationSchema);
};

const driverContractAddFormValidationService = {
  validatePeriodOfValidity,
  validateBillingModel,
  validateDistanceRate,
  validateHaltingTimeRate,
  validateHaltingTimeGracePeriodMinutes,
  validateIsActive,
  validateNotes,
};
export default driverContractAddFormValidationService;
