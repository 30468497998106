import { FC, useState, useEffect, useMemo } from "react";
import StatusLegendComponent from "../../../../../../common/components/status-legend/status-legend.component";
import BillingsCargoOrderListingStatusCountersResponse, {
  BillingsCargoOrderListingStatusCountersResponseData,
} from "../api/billings-cargo-order-listing-status-counters.response";
import billingsCargoOrderListingApiService from "../api/billings-cargo-order-listing-api.service";
import billingsCargoOrderListingHelper from "../billings-cargo-order-listing.helper";

type BillingsCargoOrderListingStatusLegendProps = {
  cargoCompanyUuid: string | null;
};

const BillingsCargoOrderListingStatusLegendComponent: FC<
  BillingsCargoOrderListingStatusLegendProps
> = (props) => {
  const [statusCount, setStatusCount] = useState<
    BillingsCargoOrderListingStatusCountersResponseData | undefined
  >();

  useEffect(() => {
    billingsCargoOrderListingApiService
      .fetchStatusCounters({
        cargo_company_id: props.cargoCompanyUuid ?? undefined,
      })
      .then(handleStatusCountersResponse);
  }, [props.cargoCompanyUuid]);

  const handleStatusCountersResponse = (
    response: BillingsCargoOrderListingStatusCountersResponse
  ) => {
    setStatusCount(
      response.data as BillingsCargoOrderListingStatusCountersResponseData
    );
  };

  const statusOptions = useMemo(
    () => billingsCargoOrderListingHelper.getStatusOptions(statusCount!),
    [statusCount]
  );

  return <StatusLegendComponent statusData={statusOptions} />;
};

export default BillingsCargoOrderListingStatusLegendComponent;
