import ListingFilterBadge from "../../../../../../../common/components/listing/filter/badge/listing-filter-badge";
import dateService from "../../../../../../../common/utils/date/date.service";
import billingsTranslationsHelper from "../../../../../../../languages/billings-translations.helper";
import BillingsTaxiUnbilledTransferredOrderListingFilter, {
  BillingsTaxiUnbilledTransferredOrderListingProducerTaxiFilter,
  BillingsTaxiUnbilledTransferredOrderListingDriverFilter,
  BillingsTaxiUnbilledTransferredOrderListingExternalOrderIdFilter,
  BillingsTaxiUnbilledTransferredOrderListingInternalOrderIdFilter,
  BillingsTaxiUnbilledTransferredOrderListingPassengerFilter,
  BillingsTaxiUnbilledTransferredOrderListingRouteAddressFilter,
  BillingsTaxiUnbilledTransferredOrderListingRouteDestinationAddressFilter,
  BillingsTaxiUnbilledTransferredOrderListingRouteIntermediateAddressFilter,
  BillingsTaxiUnbilledTransferredOrderListingRoutePickupAddressFilter,
  BillingsTaxiUnbilledTransferredOrderListingStartDateFilter,
  BillingsTaxiUnbilledTransferredOrderListingConsumerTaxiFilter,
} from "../../types/billings-taxi-unbilled-transferred-order-listing-filter";
import BillingsTaxiUnbilledTransferredOrderListingFilterType from "../../types/billings-taxi-unbilled-transferred-order-listing-filter-type";

const getStartDateBadgeOption = (
  filter: BillingsTaxiUnbilledTransferredOrderListingStartDateFilter
): {
  badge: ListingFilterBadge;
  filter: BillingsTaxiUnbilledTransferredOrderListingStartDateFilter;
} => {
  const translations =
    billingsTranslationsHelper.getTaxiUnbilledTransferredOrderBillingsListingTranslations()
      .filters;

  const formattedFromDate = dateService.formatDate(filter.value.from);

  const formattedToDate = dateService.formatDate(filter.value.to);

  return {
    badge: {
      text: translations.startDateFilterText
        .replace("#{fromDate}", formattedFromDate)
        .replace("#{toDate}", formattedToDate),
      title: translations.startDateFilterTitle
        .replace("#{fromDate}", formattedFromDate)
        .replace("#{toDate}", formattedToDate),
    },
    filter: {
      type: BillingsTaxiUnbilledTransferredOrderListingFilterType.START_DATE,
      value: filter.value,
    },
  };
};

const getBadgeOptions = (
  filter: BillingsTaxiUnbilledTransferredOrderListingFilter
) => {
  const translations =
    billingsTranslationsHelper.getTaxiUnbilledTransferredOrderBillingsListingTranslations()
      .filters;

  const options: {
    badge: ListingFilterBadge;
    filter: BillingsTaxiUnbilledTransferredOrderListingFilter;
  }[] = [
    {
      badge: {
        text: translations.producerTaxiFilterText.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledTransferredOrderListingProducerTaxiFilter["value"]
        ),
        title: translations.producerTaxiFilterTitle.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledTransferredOrderListingProducerTaxiFilter["value"]
        ),
      },
      filter: {
        type: BillingsTaxiUnbilledTransferredOrderListingFilterType.PRODUCER_TAXI,
        value:
          filter.value as BillingsTaxiUnbilledTransferredOrderListingProducerTaxiFilter["value"],
      },
    },
    {
      badge: {
        text: translations.consumerTaxiFilterText.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledTransferredOrderListingConsumerTaxiFilter["value"]
        ),
        title: translations.consumerTaxiFilterTitle.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledTransferredOrderListingConsumerTaxiFilter["value"]
        ),
      },
      filter: {
        type: BillingsTaxiUnbilledTransferredOrderListingFilterType.CONSUMER_TAXI,
        value:
          filter.value as BillingsTaxiUnbilledTransferredOrderListingConsumerTaxiFilter["value"],
      },
    },
    {
      badge: {
        text: translations.driverFilterText.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledTransferredOrderListingDriverFilter["value"]
        ),
        title: translations.driverFilterTitle.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledTransferredOrderListingDriverFilter["value"]
        ),
      },
      filter: {
        type: BillingsTaxiUnbilledTransferredOrderListingFilterType.DRIVER,
        value:
          filter.value as BillingsTaxiUnbilledTransferredOrderListingDriverFilter["value"],
      },
    },
    {
      badge: {
        text: translations.internalOrderIdFilterText.replace(
          "#{query}",
          String(
            filter.value as BillingsTaxiUnbilledTransferredOrderListingInternalOrderIdFilter["value"]
          )
        ),
        title: translations.internalOrderIdFilterTitle.replace(
          "#{query}",
          String(
            filter.value as BillingsTaxiUnbilledTransferredOrderListingInternalOrderIdFilter["value"]
          )
        ),
      },
      filter: {
        type: BillingsTaxiUnbilledTransferredOrderListingFilterType.INTERNAL_ORDER_ID,
        value:
          filter.value as BillingsTaxiUnbilledTransferredOrderListingInternalOrderIdFilter["value"],
      },
    },
    {
      badge: {
        text: translations.externalOrderIdFilterTitle.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledTransferredOrderListingExternalOrderIdFilter["value"]
        ),
        title: translations.externalOrderIdFilterTitle.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledTransferredOrderListingExternalOrderIdFilter["value"]
        ),
      },
      filter: {
        type: BillingsTaxiUnbilledTransferredOrderListingFilterType.EXTERNAL_ORDER_ID,
        value:
          filter.value as BillingsTaxiUnbilledTransferredOrderListingExternalOrderIdFilter["value"],
      },
    },
    {
      badge: {
        text: translations.passengerFilterText.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledTransferredOrderListingPassengerFilter["value"]
        ),
        title: translations.passengerFilterTitle.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledTransferredOrderListingPassengerFilter["value"]
        ),
      },
      filter: {
        type: BillingsTaxiUnbilledTransferredOrderListingFilterType.PASSENGER,
        value:
          filter.value as BillingsTaxiUnbilledTransferredOrderListingPassengerFilter["value"],
      },
    },
    {
      badge: {
        text: translations.routeAddressFilterText.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledTransferredOrderListingRouteAddressFilter["value"]
        ),
        title: translations.routeAddressFilterTitle.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledTransferredOrderListingRouteAddressFilter["value"]
        ),
      },
      filter: {
        type: BillingsTaxiUnbilledTransferredOrderListingFilterType.ROUTE_ADDRESS,
        value:
          filter.value as BillingsTaxiUnbilledTransferredOrderListingRouteAddressFilter["value"],
      },
    },
    {
      badge: {
        text: translations.routeDestinationAddressFilterText.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledTransferredOrderListingRouteDestinationAddressFilter["value"]
        ),
        title: translations.routeDestinationAddressFilterTitle.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledTransferredOrderListingRouteDestinationAddressFilter["value"]
        ),
      },
      filter: {
        type: BillingsTaxiUnbilledTransferredOrderListingFilterType.ROUTE_DESTINATION_ADDRESS,
        value:
          filter.value as BillingsTaxiUnbilledTransferredOrderListingRouteDestinationAddressFilter["value"],
      },
    },
    {
      badge: {
        text: translations.routeIntermediateAddressFilterText.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledTransferredOrderListingRouteIntermediateAddressFilter["value"]
        ),
        title: translations.routeIntermediateAddressFilterTitle.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledTransferredOrderListingRouteIntermediateAddressFilter["value"]
        ),
      },
      filter: {
        type: BillingsTaxiUnbilledTransferredOrderListingFilterType.ROUTE_INTERMEDIATE_ADDRESS,
        value:
          filter.value as BillingsTaxiUnbilledTransferredOrderListingRouteIntermediateAddressFilter["value"],
      },
    },
    {
      badge: {
        text: translations.routePickupAddressFilterText.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledTransferredOrderListingRoutePickupAddressFilter["value"]
        ),
        title: translations.routePickupAddressFilterTitle.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledTransferredOrderListingRoutePickupAddressFilter["value"]
        ),
      },
      filter: {
        type: BillingsTaxiUnbilledTransferredOrderListingFilterType.ROUTE_PICKUP_ADDRESS,
        value:
          filter.value as BillingsTaxiUnbilledTransferredOrderListingRoutePickupAddressFilter["value"],
      },
    },
    getStartDateBadgeOption(
      filter as BillingsTaxiUnbilledTransferredOrderListingStartDateFilter
    ),
  ];

  return options;
};

const getBadge = (
  filter: BillingsTaxiUnbilledTransferredOrderListingFilter
) => {
  const options = getBadgeOptions(filter);

  return options.find(
    (item) =>
      item.filter.type === filter.type && item.filter.value === filter.value
  )?.badge;
};

const getBadges = (
  filters: BillingsTaxiUnbilledTransferredOrderListingFilter[]
): ListingFilterBadge[] => {
  const badges: ListingFilterBadge[] = [];

  filters.forEach((filter) => {
    const badge = getBadge(filter);

    if (!badge) {
      return;
    }

    badges.push(badge);
  });

  return badges;
};

const billingsTaxiUnbilledTransferredOrderListingFiltersBadgeListHelper = {
  getBadges,
};

export default billingsTaxiUnbilledTransferredOrderListingFiltersBadgeListHelper;
