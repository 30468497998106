import StatusLegendStatuses from "../../../../../common/components/status-legend/types/status-legend-statuses";
import billingsTranslationsHelper from "../../../../../languages/billings-translations.helper";
import BillingsTaxiUnbilledOrderListingItemStatus from "./types/billings-taxi-unbilled-order-listing-item-status";

const getStatusOptions = () => {
  const translations =
    billingsTranslationsHelper.getTaxiUnbilledOrderBillingsListingTranslations()
      .status;

  const options: {
    text: string;
    status: BillingsTaxiUnbilledOrderListingItemStatus;
  }[] = [
    {
      text: translations.CANCELLED,
      status: BillingsTaxiUnbilledOrderListingItemStatus.CANCELLED,
    },
    {
      text: translations.FINISHED,
      status: BillingsTaxiUnbilledOrderListingItemStatus.FINISHED,
    },
  ];

  return options;
};

const getStatusText = (
  status: BillingsTaxiUnbilledOrderListingItemStatus
): string => {
  const options = getStatusOptions();

  return options.find((option) => option.status === status)?.text ?? "";
};

const getStatusLegendOptions = () => {
  const options: {
    status: StatusLegendStatuses;
  }[] = [
    {
      status: StatusLegendStatuses.CANCELLED,
    },
    {
      status: StatusLegendStatuses.FINISHED,
    },
  ];

  return options;
};

const billingsTaxiUnbilledOrderListingHelper = {
  getStatusOptions,
  getStatusText,
  getStatusLegendOptions,
};

export default billingsTaxiUnbilledOrderListingHelper;
