import HttpResponse from "../../../../utils/http/http.response";

export enum CargoOrderAbandonedListResponsePublicStatus {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE",
  PUBLISHED = "PUBLISHED",
}

export type CargoOrderAbandonedListResponseItemProducer = {
  id: string;
  name: string;
};

export type CargoOrderAbandonedListResponseItem = {
  id: string;
  startTime: Date;
  humanId: number;
  rideAddresses: (string | string[])[];
  passengers: string[];
  dispatch: string;
  client: string;
  producer: CargoOrderAbandonedListResponseItemProducer;
  externalId: string | null;
  publicStatus: CargoOrderAbandonedListResponsePublicStatus;
};

export type CargoOrderAbandonedListResponseData = {
  data: CargoOrderAbandonedListResponseItem[];
  totalCount: number;
};

type CargoOrderAbandonedListResponse =
  HttpResponse<CargoOrderAbandonedListResponseData>;

export default CargoOrderAbandonedListResponse;
