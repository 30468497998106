import Joi from "joi";
import BillingsTaxiTransferredOrderListingRouteQueryFilterParams from "./types/billings-taxi-transferred-order-listing-route-query-filter-params";
import BillingsTaxiTransferredOrderListingFilter, {
  BillingsTaxiTransferredOrderListingConsumerTaxiFilter,
  BillingsTaxiTransferredOrderListingInternalOrderIdFilter,
  BillingsTaxiTransferredOrderListingTaxiCorporationIdFilter,
  BillingsTaxiTransferredOrderListingOrderStartDateFilter,
  BillingsTaxiTransferredOrderListingProducerTaxiFilter,
} from "./types/billings-taxi-transferred-order-listing-filter";
import BillingsTaxiTransferredOrderListingFilterType from "./types/billings-taxi-transferred-order-listing-filter-type";
import BillingsTaxiTransferredOrderListingRouteQueryParams from "./types/billings-taxi-transferred-order-listing-route-query-params";
import BillingsTaxiTransferredOrderListingSortKey from "./types/billings-taxi-transferred-order-listing-sort-key";
import billingsTaxiTransferredOrderListingFilterHelper from "./helper/billings-taxi-transferred-order-listing-filter.helper";

const getFilters = (
  routeQueryFilterParams: BillingsTaxiTransferredOrderListingRouteQueryFilterParams
): BillingsTaxiTransferredOrderListingFilter[] => {
  if (!validateFilters(routeQueryFilterParams)) {
    return [];
  }

  const filters: BillingsTaxiTransferredOrderListingFilter[] = [];

  if (
    routeQueryFilterParams.orderStartDateFrom &&
    routeQueryFilterParams.orderStartDateTo
  ) {
    const startDateFilter: BillingsTaxiTransferredOrderListingOrderStartDateFilter =
      {
        type: BillingsTaxiTransferredOrderListingFilterType.ORDER_START_DATE,
        value: {
          from: new Date(routeQueryFilterParams.orderStartDateFrom),
          to: new Date(routeQueryFilterParams.orderStartDateTo),
        },
      };

    filters.push(startDateFilter);
  }

  if (routeQueryFilterParams.taxiCorporationId) {
    const taxiCorporationFilter: BillingsTaxiTransferredOrderListingTaxiCorporationIdFilter =
      {
        type: BillingsTaxiTransferredOrderListingFilterType.TAXI_CORPORATION_ID,
        value: routeQueryFilterParams.taxiCorporationId,
      };
    filters.push(taxiCorporationFilter);
  }

  if (routeQueryFilterParams.internalOrderId) {
    const internalOrderIdFilter: BillingsTaxiTransferredOrderListingInternalOrderIdFilter =
      {
        type: BillingsTaxiTransferredOrderListingFilterType.INTERNAL_ORDER_ID,
        value: Number(routeQueryFilterParams.internalOrderId),
      };
    filters.push(internalOrderIdFilter);
  }

  if (routeQueryFilterParams.producerTaxi) {
    const producerTaxiFilter: BillingsTaxiTransferredOrderListingProducerTaxiFilter =
      {
        type: BillingsTaxiTransferredOrderListingFilterType.PRODUCER_TAXI,
        value: routeQueryFilterParams.producerTaxi,
      };
    filters.push(producerTaxiFilter);
  }

  if (routeQueryFilterParams.consumerTaxi) {
    const consumerTaxiFilter: BillingsTaxiTransferredOrderListingConsumerTaxiFilter =
      {
        type: BillingsTaxiTransferredOrderListingFilterType.CONSUMER_TAXI,
        value: routeQueryFilterParams.consumerTaxi,
      };
    filters.push(consumerTaxiFilter);
  }

  const updatedFilters =
    billingsTaxiTransferredOrderListingFilterHelper.getDefaultFilters(filters);

  return updatedFilters;
};

const getSortKey = (
  routeQueryParams: BillingsTaxiTransferredOrderListingRouteQueryParams
): BillingsTaxiTransferredOrderListingSortKey | undefined => {
  if (!validateSortKey(routeQueryParams.sort)) {
    return undefined;
  }

  return routeQueryParams.sort;
};

const createRouteQueryParams = (
  filters: BillingsTaxiTransferredOrderListingFilter[],
  sortKey: BillingsTaxiTransferredOrderListingSortKey | null,
  page: number,
  pageSize: number
): BillingsTaxiTransferredOrderListingRouteQueryParams => {
  return {
    page,
    pageSize,
    sort: sortKey ?? undefined,
    orderStartDateFrom: (
      filters.find(
        (filter) =>
          filter.type ===
          BillingsTaxiTransferredOrderListingFilterType.ORDER_START_DATE
      )?.value as
        | BillingsTaxiTransferredOrderListingOrderStartDateFilter["value"]
        | undefined
    )?.from.toJSON(),
    orderStartDateTo: (
      filters.find(
        (filter) =>
          filter.type ===
          BillingsTaxiTransferredOrderListingFilterType.ORDER_START_DATE
      )?.value as
        | BillingsTaxiTransferredOrderListingOrderStartDateFilter["value"]
        | undefined
    )?.to.toJSON(),
    internalOrderId: (
      filters.find(
        (filter) =>
          filter.type ===
          BillingsTaxiTransferredOrderListingFilterType.INTERNAL_ORDER_ID
      )?.value as
        | BillingsTaxiTransferredOrderListingInternalOrderIdFilter["value"]
        | undefined
    )?.toString(),
    consumerTaxi: filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiTransferredOrderListingFilterType.CONSUMER_TAXI
    )?.value as
      | BillingsTaxiTransferredOrderListingConsumerTaxiFilter["value"]
      | undefined,
    producerTaxi: filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiTransferredOrderListingFilterType.PRODUCER_TAXI
    )?.value as
      | BillingsTaxiTransferredOrderListingProducerTaxiFilter["value"]
      | undefined,
  };
};

const getPage = (
  routeQueryFilterParams: BillingsTaxiTransferredOrderListingRouteQueryParams
): number | undefined => {
  if (!validatePage(routeQueryFilterParams.page)) {
    return undefined;
  }

  return routeQueryFilterParams.page
    ? Number(routeQueryFilterParams.page)
    : undefined;
};

const getPageSize = (
  routeQueryFilterParams: BillingsTaxiTransferredOrderListingRouteQueryParams
): number | undefined => {
  if (!validatePageSize(routeQueryFilterParams.pageSize)) {
    return undefined;
  }
  return routeQueryFilterParams.pageSize
    ? Number(routeQueryFilterParams.pageSize)
    : undefined;
};

const validateFilters = (
  routeQueryFilterParams: BillingsTaxiTransferredOrderListingRouteQueryFilterParams
) => {
  const filterParams: BillingsTaxiTransferredOrderListingRouteQueryFilterParams =
    {
      orderStartDateFrom: routeQueryFilterParams.orderStartDateFrom,
      orderStartDateTo: routeQueryFilterParams.orderStartDateTo,
      taxiCorporationId: routeQueryFilterParams.taxiCorporationId,
      internalOrderId: routeQueryFilterParams.internalOrderId,
      consumerTaxi: routeQueryFilterParams.consumerTaxi,
      producerTaxi: routeQueryFilterParams.producerTaxi,
    };

  const validationSchema =
    Joi.object<BillingsTaxiTransferredOrderListingRouteQueryFilterParams>({
      orderStartDateFrom: Joi.string(),
      orderStartDateTo: Joi.string(),
      taxiCorporationId: Joi.string(),
      internalOrderId: Joi.number(),
      producerTaxi: Joi.string(),
      consumerTaxi: Joi.string(),
    });

  return !validationSchema.validate(filterParams).error?.message;
};

const validateSortKey = (
  routeQuerySortParam: BillingsTaxiTransferredOrderListingRouteQueryParams["sort"]
): boolean => {
  const validationSchema = Joi.valid(
    ...Object.values(BillingsTaxiTransferredOrderListingSortKey)
  );

  return !validationSchema.validate(routeQuerySortParam).error?.message;
};

const validatePage = (
  routeQueryPageParam: BillingsTaxiTransferredOrderListingRouteQueryParams["page"]
) => {
  const validationSchema = Joi.number().min(1);

  return !validationSchema.validate(routeQueryPageParam).error?.message;
};

const validatePageSize = (
  routeQueryPageSizeParam: BillingsTaxiTransferredOrderListingRouteQueryParams["pageSize"]
) => {
  const validationSchema = Joi.number().valid(...[50, 100, 200]);

  return !validationSchema.validate(routeQueryPageSizeParam).error?.message;
};

const billingsTaxiTransferredOrderListingRouteQueryParamsService = {
  getFilters,
  getSortKey,
  getPage,
  getPageSize,
  createRouteQueryParams,
};

export default billingsTaxiTransferredOrderListingRouteQueryParamsService;
