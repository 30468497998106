import classNames from "classnames";
import { FC } from "react";
import BillingsTaxiOrderListingItemBillingStatus from "../../types/billings-taxi-order-listing-item-billing-status";

type BillingsTaxiOrderListingTableStatusProps = {
  status: BillingsTaxiOrderListingItemBillingStatus | undefined;
};

const BillingsTaxiOrderListingTableStatusComponent: FC<
  BillingsTaxiOrderListingTableStatusProps
> = (props) => {
  const getClassnameByStatus = (): string => {
    switch (props.status) {
      case BillingsTaxiOrderListingItemBillingStatus.CREATED:
        return "created";
      case BillingsTaxiOrderListingItemBillingStatus.ACCEPTED:
        return "accepted";
      case BillingsTaxiOrderListingItemBillingStatus.REJECTED:
        return "rejected";
      case BillingsTaxiOrderListingItemBillingStatus.REOPENED:
        return "reopened";
      case BillingsTaxiOrderListingItemBillingStatus.REOPEN_REQUEST_SENT:
        return "reopen_request_sent";
      default:
        return "";
    }
  };

  const statusClassname = getClassnameByStatus();

  const containerClassNames = classNames(
    "billings_taxi_order_listing_table_status",
    statusClassname
  );

  return <div className={containerClassNames}></div>;
};

export default BillingsTaxiOrderListingTableStatusComponent;
