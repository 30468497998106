import SearchRoadRoutingRequest, {
  SearchRoadRoutingRequestWaypointCoordinate,
} from "../../../utils/search-road-route/search-road-routing.request";
import OrderOptimizerRoadRouteLoadParams, {
  OrderOptimizerRoadRouteWaypoints,
} from "./order-optimizer-road-route-load-params";

const changeWaypointType = (
  type: OrderOptimizerRoadRouteWaypoints
): SearchRoadRoutingRequestWaypointCoordinate => {
  return {
    latitude: type.lat,
    longitude: type.lon,
  };
};

const create = (
  loadParams: OrderOptimizerRoadRouteLoadParams
): SearchRoadRoutingRequest => {
  return {
    waypointCoordinates: loadParams.waypoints.map(changeWaypointType),
  };
};

const orderOptimizerRoadRouteRequestFactory = {
  create,
};

export default orderOptimizerRoadRouteRequestFactory;
