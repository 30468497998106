import BillingModel from "../../../../../common/types/billing-model";
import billingsTranslationsHelper from "../../../../../languages/billings-translations.helper";
import { BillingsTaxiOrderListingStatusCountersResponseData } from "./api/billings-taxi-order-listing-status-counters.response";
import BillingsTaxiOrderListingItemBillingStatus from "./types/billings-taxi-order-listing-item-billing-status";
import BillingsTaxiOrderListingItemBillingType from "./types/billings-taxi-order-listing-item-billing-type";
import BillingsTaxiOrderListingStatusLegendOption from "./types/billings-taxi-order-listing-status-legend-option";

const getBillingStatusOptions = (
  responseData?: BillingsTaxiOrderListingStatusCountersResponseData
): BillingsTaxiOrderListingStatusLegendOption[] => {
  const translations =
    billingsTranslationsHelper.getTaxiOrderBillingsListingTranslations().status;

  const options: BillingsTaxiOrderListingStatusLegendOption[] = [
    {
      text: translations.CREATED,
      status: BillingsTaxiOrderListingItemBillingStatus.CREATED,
      totalCount: responseData?.created,
    },
    {
      text: translations.ACCEPTED,
      status: BillingsTaxiOrderListingItemBillingStatus.ACCEPTED,
      totalCount: responseData?.accepted,
    },
    {
      text: translations.REJECTED,
      status: BillingsTaxiOrderListingItemBillingStatus.REJECTED,
      totalCount: responseData?.rejected,
    },
    {
      text: translations.REOPENED,
      status: BillingsTaxiOrderListingItemBillingStatus.REOPENED,
      totalCount: responseData?.reopened,
    },
    {
      text: translations.REOPEN_REQUEST_SENT,
      status: BillingsTaxiOrderListingItemBillingStatus.REOPEN_REQUEST_SENT,
      totalCount: responseData?.reopen_request,
    },
  ];

  return options;
};

const getBillingStatusText = (
  status: BillingsTaxiOrderListingItemBillingStatus
): string => {
  const options = getBillingStatusOptions();

  return options.find((option) => option.status === status)?.text ?? "";
};

const getBillingTypeOptions = () => {
  const translations =
    billingsTranslationsHelper.getTaxiOrderBillingsListingTranslations().type;

  const options: {
    text: string;
    billingType: BillingsTaxiOrderListingItemBillingType;
  }[] = [
    {
      text: translations.PUB_RAILY_2_TAXI,
      billingType: BillingsTaxiOrderListingItemBillingType.PUB_RAILY_2_TAXI,
    },
    {
      text: translations.PRIV_CARGO_2_TAXI,
      billingType: BillingsTaxiOrderListingItemBillingType.PRIV_CARGO_2_TAXI,
    },
    {
      text: translations.PRIV_TAXI_2_RAILY,
      billingType: BillingsTaxiOrderListingItemBillingType.PRIV_TAXI_2_RAILY,
    },
  ];

  return options;
};

const getBillingTypeText = (
  type: BillingsTaxiOrderListingItemBillingType
): string => {
  const options = getBillingTypeOptions();

  return options.find((option) => option.billingType === type)?.text ?? "";
};

const getBillingModelOptions = () => {
  const translations =
    billingsTranslationsHelper.getTaxiOrderBillingsListingTranslations().model;

  const options: {
    text: string;
    billingModel: BillingModel;
  }[] = [
    {
      text: translations.AB,
      billingModel: BillingModel.AB,
    },
    {
      text: translations.ABA,
      billingModel: BillingModel.ABA,
    },
    {
      text: translations.OTHER,
      billingModel: BillingModel.OTHER,
    },
    {
      text: translations.SABS,
      billingModel: BillingModel.SABS,
    },
  ];

  return options;
};

const getBillingModelText = (type: BillingModel): string => {
  const options = getBillingModelOptions();

  return options.find((option) => option.billingModel === type)?.text ?? "";
};

const billingsTaxiOrderListingHelper = {
  getBillingStatusOptions,
  getBillingStatusText,
  getBillingTypeOptions,
  getBillingTypeText,
  getBillingModelOptions,
  getBillingModelText,
};

export default billingsTaxiOrderListingHelper;
