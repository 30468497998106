import mapMarkerIconFactory from "../../../../common/components/map/marker/map-marker-icon.factory";
import MapMarker from "../../../../common/components/map/types/map-marker";
import MapRoute from "../../../../common/components/map/types/map-route";
import { SearchRoadRoutingResponseRouteGeometryCoordinate } from "../../../../common/utils/search-road-route/search-road-routing.response";
import { OrderOptimizerDetailsItemStepsOrdersPoints } from "./types/order-optimizer-details-item";

const createMapMarker = (
  adres: OrderOptimizerDetailsItemStepsOrdersPoints,
  addressNum: number
) => {
  const signature = `${addressNum + 1}`;

  const markerIcon = mapMarkerIconFactory.createIcon({
    className: "map_marker standard",
    content: signature,
  });

  const newMapMarker: MapMarker = {
    coordinate: {
      latitude: adres.lat,
      longitude: adres.lon,
    },
    icon: markerIcon,
  };

  return newMapMarker;
};

const createMapMarkers = (
  addressSequence: OrderOptimizerDetailsItemStepsOrdersPoints[]
): MapMarker[] => {
  return addressSequence.map(createMapMarker);
};

const createMapRoute = (
  routesResponseGeometryCoordinates: SearchRoadRoutingResponseRouteGeometryCoordinate[],
  color: string
): MapRoute => {
  return {
    waypoints: routesResponseGeometryCoordinates.map((coordinate) => {
      return {
        latitude: coordinate[1],
        longitude: coordinate[0],
      };
    }),
    options: {
      color: color,
    },
  };
};

const orderOptimizerDetailsRouteFactory = {
  createMapMarkers,
  createMapRoute,
};

export default orderOptimizerDetailsRouteFactory;
