import paginationService from "../../../../../../common/utils/pagination/pagination.service";
import BillingsTaxiUnbilledTransferredOrderListingRequest, {
  BillingsTaxiUnbilledTransferredOrderListingRequestOrder,
} from "../api/billings-taxi-unbilled-transferred-order-listing-request";
import BillingsTaxiUnbilledTransferredOrderListingFilter, {
  BillingsTaxiUnbilledTransferredOrderListingProducerTaxiFilter,
  BillingsTaxiUnbilledTransferredOrderListingDriverFilter,
  BillingsTaxiUnbilledTransferredOrderListingExternalOrderIdFilter,
  BillingsTaxiUnbilledTransferredOrderListingInternalOrderIdFilter,
  BillingsTaxiUnbilledTransferredOrderListingPassengerFilter,
  BillingsTaxiUnbilledTransferredOrderListingRouteAddressFilter,
  BillingsTaxiUnbilledTransferredOrderListingRouteDestinationAddressFilter,
  BillingsTaxiUnbilledTransferredOrderListingRouteIntermediateAddressFilter,
  BillingsTaxiUnbilledTransferredOrderListingRoutePickupAddressFilter,
  BillingsTaxiUnbilledTransferredOrderListingStartDateFilter,
  BillingsTaxiUnbilledTransferredOrderListingConsumerTaxiFilter,
} from "../types/billings-taxi-unbilled-transferred-order-listing-filter";
import BillingsTaxiUnbilledTransferredOrderListingFilterType from "../types/billings-taxi-unbilled-transferred-order-listing-filter-type";
import BillingsTaxiUnbilledTransferredOrderListingSortKey from "../types/billings-taxi-unbilled-transferred-order-listing-sort-key";

const createRequestOrder = (
  sortKey: BillingsTaxiUnbilledTransferredOrderListingSortKey | null
): BillingsTaxiUnbilledTransferredOrderListingRequest["order"] => {
  const options: {
    sortKey: BillingsTaxiUnbilledTransferredOrderListingSortKey;
    requestOrder: BillingsTaxiUnbilledTransferredOrderListingRequestOrder;
  }[] = [
    {
      requestOrder:
        BillingsTaxiUnbilledTransferredOrderListingRequestOrder.CONSUMER_ASC,
      sortKey: BillingsTaxiUnbilledTransferredOrderListingSortKey.CONSUMER_ASC,
    },
    {
      requestOrder:
        BillingsTaxiUnbilledTransferredOrderListingRequestOrder.CONSUMER_DESC,
      sortKey: BillingsTaxiUnbilledTransferredOrderListingSortKey.CONSUMER_DESC,
    },
    {
      requestOrder:
        BillingsTaxiUnbilledTransferredOrderListingRequestOrder.PRODUCER_ASC,
      sortKey: BillingsTaxiUnbilledTransferredOrderListingSortKey.PRODUCER_ASC,
    },
    {
      requestOrder:
        BillingsTaxiUnbilledTransferredOrderListingRequestOrder.PRODUCER_DESC,
      sortKey: BillingsTaxiUnbilledTransferredOrderListingSortKey.PRODUCER_DESC,
    },
  ];

  return options.find((option) => option.sortKey === sortKey)?.requestOrder;
};

const createRequest = (
  page: number,
  pageSize: number,
  filters: BillingsTaxiUnbilledTransferredOrderListingFilter[],
  sortKey: BillingsTaxiUnbilledTransferredOrderListingSortKey | null
): BillingsTaxiUnbilledTransferredOrderListingRequest => {
  const offset = paginationService.calculateOffset(page, pageSize);

  return {
    order: createRequestOrder(sortKey),
    address: filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiUnbilledTransferredOrderListingFilterType.ROUTE_ADDRESS
    )?.value as
      | BillingsTaxiUnbilledTransferredOrderListingRouteAddressFilter["value"]
      | undefined,
    limit: pageSize,
    producer: filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiUnbilledTransferredOrderListingFilterType.PRODUCER_TAXI
    )?.value as
      | BillingsTaxiUnbilledTransferredOrderListingProducerTaxiFilter["value"]
      | undefined,
    consumer: filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiUnbilledTransferredOrderListingFilterType.CONSUMER_TAXI
    )?.value as
      | BillingsTaxiUnbilledTransferredOrderListingConsumerTaxiFilter["value"]
      | undefined,
    driver: filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiUnbilledTransferredOrderListingFilterType.DRIVER
    )?.value as
      | BillingsTaxiUnbilledTransferredOrderListingDriverFilter["value"]
      | undefined,
    end_address: filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiUnbilledTransferredOrderListingFilterType.ROUTE_DESTINATION_ADDRESS
    )?.value as
      | BillingsTaxiUnbilledTransferredOrderListingRouteDestinationAddressFilter["value"]
      | undefined,
    external_id: filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiUnbilledTransferredOrderListingFilterType.EXTERNAL_ORDER_ID
    )?.value as
      | BillingsTaxiUnbilledTransferredOrderListingExternalOrderIdFilter["value"]
      | undefined,
    human_id: filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiUnbilledTransferredOrderListingFilterType.INTERNAL_ORDER_ID
    )?.value as
      | BillingsTaxiUnbilledTransferredOrderListingInternalOrderIdFilter["value"]
      | undefined,
    mid_address: filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiUnbilledTransferredOrderListingFilterType.ROUTE_INTERMEDIATE_ADDRESS
    )?.value as
      | BillingsTaxiUnbilledTransferredOrderListingRouteIntermediateAddressFilter["value"]
      | undefined,
    offset,
    passenger: filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiUnbilledTransferredOrderListingFilterType.PASSENGER
    )?.value as
      | BillingsTaxiUnbilledTransferredOrderListingPassengerFilter["value"]
      | undefined,
    start_address: filters.find(
      (filter) =>
        filter.type ===
        BillingsTaxiUnbilledTransferredOrderListingFilterType.ROUTE_PICKUP_ADDRESS
    )?.value as
      | BillingsTaxiUnbilledTransferredOrderListingRoutePickupAddressFilter["value"]
      | undefined,
    start_time_since: (
      filters.find(
        (filter) =>
          filter.type ===
          BillingsTaxiUnbilledTransferredOrderListingFilterType.START_DATE
      )?.value as
        | BillingsTaxiUnbilledTransferredOrderListingStartDateFilter["value"]
        | undefined
    )?.from?.toJSON(),
    start_time_to: (
      filters.find(
        (filter) =>
          filter.type ===
          BillingsTaxiUnbilledTransferredOrderListingFilterType.START_DATE
      )?.value as
        | BillingsTaxiUnbilledTransferredOrderListingStartDateFilter["value"]
        | undefined
    )?.to?.toJSON(),
  };
};

const billingsTaxiUnbilledTransferredOrderListingRequestFactory = {
  createRequest,
};

export default billingsTaxiUnbilledTransferredOrderListingRequestFactory;
