export enum CargoOrderAbandonedListPublicStatus {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE",
  PUBLISHED = "PUBLISHED",
}

export type CargoOrderAbandonedListItemProducer = {
  uuid: string;
  name: string;
};

export type CargoOrderAbandonedListItem = {
  uuid: string;
  startTime: Date;
  humanId: number;
  rideAddresses: (string | string[])[];
  passengers: string[];
  dispatch: string;
  client: string;
  producer: CargoOrderAbandonedListItemProducer;
  externalId: string | null;
  publicStatus: CargoOrderAbandonedListPublicStatus;
};

type CargoOrderAbandonedList = {
  data: CargoOrderAbandonedListItem[];
  totalCount: number;
};

export default CargoOrderAbandonedList;
