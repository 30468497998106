import { FC } from "react";
import CardComponent from "../../../../../../common/components/card/card.component";
import TableComponent from "../../../../../../common/components/table/table.component";
import BillingsTaxiRouteListingStatsSummary from "../types/billings-taxi-route-listing-stats-summary";
import billingsTranslationsHelper from "../../../../../../languages/billings-translations.helper";
import BillingsTaxiRouteListingStatsSummaryTableRow from "../types/billings-taxi-route-listing-stats-summary-table-row";
import billingsTaxiRouteListingStatsSummaryHelper from "./billings-taxi-route-listing-stats-summary.helper";

type BillingsTaxiRouteListingStatsSummaryProps = {
  isError: boolean;
  isLoading: boolean;
  statsSummary: BillingsTaxiRouteListingStatsSummary | null;
};

const BillingsTaxiRouteListingStatsSummaryComponent: FC<
  BillingsTaxiRouteListingStatsSummaryProps
> = (props) => {
  if (!props.statsSummary) {
    return null;
  }

  const translations =
    billingsTranslationsHelper.getTaxiRouteBillingsListingTranslations().stats;

  const createTableRow = (
    statsSummary: BillingsTaxiRouteListingStatsSummary
  ): BillingsTaxiRouteListingStatsSummaryTableRow => {
    return {
      id: "stats-summary-row",
      idForTesting: "stats-summary-row",
      value: {
        distance: (
          <div title={String(statsSummary.distance)}>
            {statsSummary.distance &&
              billingsTaxiRouteListingStatsSummaryHelper.formatNumber(
                statsSummary.distance
              ) + " KM"}
          </div>
        ),
        amountForChargeHaltings: (
          <div title={String(statsSummary.amountForChargeHaltings)}>
            {statsSummary.amountForChargeHaltings &&
              billingsTaxiRouteListingStatsSummaryHelper.formatNumber(
                statsSummary.amountForChargeHaltings
              ) + " ZŁ"}
          </div>
        ),
        amountForChargeHighways: (
          <div title={String(statsSummary.amountForChargeHighways)}>
            {statsSummary.amountForChargeHighways &&
              billingsTaxiRouteListingStatsSummaryHelper.formatNumber(
                statsSummary.amountForChargeHighways
              ) + " ZŁ"}
          </div>
        ),
        sumOfBonuses: (
          <div title={String(statsSummary.sumOfBonuses)}>
            {statsSummary.sumOfBonuses &&
              billingsTaxiRouteListingStatsSummaryHelper.formatNumber(
                statsSummary.sumOfBonuses
              ) + " ZŁ"}
          </div>
        ),
        sumOfPenalties: (
          <div title={String(statsSummary.sumOfPenalties)}>
            {statsSummary.sumOfPenalties &&
              billingsTaxiRouteListingStatsSummaryHelper.formatNumber(
                statsSummary.sumOfPenalties
              ) + " ZŁ"}
          </div>
        ),
        allContributionsAmount: (
          <div title={String(statsSummary.allContributionsAmount)}>
            {statsSummary.allContributionsAmount &&
              billingsTaxiRouteListingStatsSummaryHelper.formatNumber(
                statsSummary.allContributionsAmount
              ) + " ZŁ"}
          </div>
        ),
      },
    };
  };

  const columns = billingsTaxiRouteListingStatsSummaryHelper.getColumns();
  const rows = [createTableRow(props.statsSummary)];

  return (
    <CardComponent
      classNames={{ root: "mt-4" }}
      header={{ title: translations.summaryLabel }}
    >
      <TableComponent
        columns={columns}
        isError={props.isError}
        isLoading={props.isLoading}
        rows={rows}
      />
    </CardComponent>
  );
};

export default BillingsTaxiRouteListingStatsSummaryComponent;
