import ListingFilterBadge from "../../../../../../../common/components/listing/filter/badge/listing-filter-badge";
import dateService from "../../../../../../../common/utils/date/date.service";
import billingsTranslationsHelper from "../../../../../../../languages/billings-translations.helper";
import billingsTaxiUnbilledPrivateOrderListingHelper from "../../billings-taxi-unbilled-private-order-listing.helper";
import BillingsTaxiUnbilledPrivateOrderListingFilter, {
  BillingsTaxiUnbilledPrivateOrderListingClientFilter,
  BillingsTaxiUnbilledPrivateOrderListingExcludeClientFilter,
  BillingsTaxiUnbilledPrivateOrderListingDispatchFilter,
  BillingsTaxiUnbilledPrivateOrderListingDriverFilter,
  BillingsTaxiUnbilledPrivateOrderListingExternalOrderIdFilter,
  BillingsTaxiUnbilledPrivateOrderListingInternalOrderIdFilter,
  BillingsTaxiUnbilledPrivateOrderListingPassengerFilter,
  BillingsTaxiUnbilledPrivateOrderListingRouteIntermediateAddressFilter,
  BillingsTaxiUnbilledPrivateOrderListingRoutePickupAddressFilter,
  BillingsTaxiUnbilledPrivateOrderListingStartDateFilter,
  BillingsTaxiUnbilledPrivateOrderListingStatusFilter,
} from "../../types/billings-taxi-unbilled-private-order-listing-filter";
import BillingsTaxiUnbilledPrivateOrderListingFilterType from "../../types/billings-taxi-unbilled-private-order-listing-filter-type";
import BillingsTaxiUnbilledPrivateOrderListingItemStatus from "../../types/billings-taxi-unbilled-private-order-listing-item-status";

const getStartDateBadgeOption = (
  filter: BillingsTaxiUnbilledPrivateOrderListingStartDateFilter
): {
  badge: ListingFilterBadge;
  filter: BillingsTaxiUnbilledPrivateOrderListingStartDateFilter;
} => {
  const translations =
    billingsTranslationsHelper.getTaxiUnbilledPrivateOrderBillingsListingTranslations()
      .filters;

  const formattedFromDate = dateService.formatDate(filter.value.from);

  const formattedToDate = dateService.formatDate(filter.value.to);

  return {
    badge: {
      text: translations.startDateFilterText
        .replace("#{fromDate}", formattedFromDate)
        .replace("#{toDate}", formattedToDate),
      title: translations.startDateFilterTitle
        .replace("#{fromDate}", formattedFromDate)
        .replace("#{toDate}", formattedToDate),
    },
    filter: {
      type: BillingsTaxiUnbilledPrivateOrderListingFilterType.START_DATE,
      value: filter.value,
    },
  };
};

const getStatusBadgeOptions = (): {
  badge: ListingFilterBadge;
  filter: BillingsTaxiUnbilledPrivateOrderListingStatusFilter;
}[] => {
  const translations =
    billingsTranslationsHelper.getTaxiUnbilledPrivateOrderBillingsListingTranslations()
      .filters;

  return Object.values(BillingsTaxiUnbilledPrivateOrderListingItemStatus).map(
    (statusFilterValue) => {
      return {
        badge: {
          text: translations.statusFilterText.replace(
            "#{option}",
            billingsTaxiUnbilledPrivateOrderListingHelper.getStatusText(
              statusFilterValue
            )
          ),
          title: translations.statusFilterTitle.replace(
            "#{option}",
            billingsTaxiUnbilledPrivateOrderListingHelper.getStatusText(
              statusFilterValue
            )
          ),
        },
        filter: {
          type: BillingsTaxiUnbilledPrivateOrderListingFilterType.STATUS,
          value: statusFilterValue,
        },
      };
    }
  );
};

const getBadgeOptions = (
  filter: BillingsTaxiUnbilledPrivateOrderListingFilter
) => {
  const translations =
    billingsTranslationsHelper.getTaxiUnbilledPrivateOrderBillingsListingTranslations()
      .filters;

  const options: {
    badge: ListingFilterBadge;
    filter: BillingsTaxiUnbilledPrivateOrderListingFilter;
  }[] = [
    {
      badge: {
        text: translations.clientNameFilterText.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledPrivateOrderListingClientFilter["value"]
        ),
        title: translations.clientNameFilterTitle.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledPrivateOrderListingClientFilter["value"]
        ),
      },
      filter: {
        type: BillingsTaxiUnbilledPrivateOrderListingFilterType.CLIENT,
        value:
          filter.value as BillingsTaxiUnbilledPrivateOrderListingClientFilter["value"],
      },
    },
    {
      badge: {
        text: translations.excludeClientNameFilterText.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledPrivateOrderListingExcludeClientFilter["value"]
        ),
        title: translations.excludeClientNameFilterTitle.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledPrivateOrderListingExcludeClientFilter["value"]
        ),
      },
      filter: {
        type: BillingsTaxiUnbilledPrivateOrderListingFilterType.EXCLUDE_CARGO_COMPANY,
        value:
          filter.value as BillingsTaxiUnbilledPrivateOrderListingExcludeClientFilter["value"],
      },
    },
    {
      badge: {
        text: translations.dispatchNameFilterText.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledPrivateOrderListingDispatchFilter["value"]
        ),
        title: translations.dispatchNameFilterTitle.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledPrivateOrderListingDispatchFilter["value"]
        ),
      },
      filter: {
        type: BillingsTaxiUnbilledPrivateOrderListingFilterType.DISPATCH,
        value:
          filter.value as BillingsTaxiUnbilledPrivateOrderListingDispatchFilter["value"],
      },
    },
    {
      badge: {
        text: translations.driverFilterText.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledPrivateOrderListingDriverFilter["value"]
        ),
        title: translations.driverFilterTitle.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledPrivateOrderListingDriverFilter["value"]
        ),
      },
      filter: {
        type: BillingsTaxiUnbilledPrivateOrderListingFilterType.DRIVER,
        value:
          filter.value as BillingsTaxiUnbilledPrivateOrderListingDriverFilter["value"],
      },
    },
    {
      badge: {
        text: translations.internalOrderIdFilterText.replace(
          "#{query}",
          String(
            filter.value as BillingsTaxiUnbilledPrivateOrderListingInternalOrderIdFilter["value"]
          )
        ),
        title: translations.internalOrderIdFilterTitle.replace(
          "#{query}",
          String(
            filter.value as BillingsTaxiUnbilledPrivateOrderListingInternalOrderIdFilter["value"]
          )
        ),
      },
      filter: {
        type: BillingsTaxiUnbilledPrivateOrderListingFilterType.INTERNAL_ORDER_ID,
        value:
          filter.value as BillingsTaxiUnbilledPrivateOrderListingInternalOrderIdFilter["value"],
      },
    },
    {
      badge: {
        text: translations.externalOrderIdFilterText.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledPrivateOrderListingExternalOrderIdFilter["value"]
        ),
        title: translations.externalOrderIdFilterTitle.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledPrivateOrderListingExternalOrderIdFilter["value"]
        ),
      },
      filter: {
        type: BillingsTaxiUnbilledPrivateOrderListingFilterType.EXTERNAL_ORDER_ID,
        value:
          filter.value as BillingsTaxiUnbilledPrivateOrderListingExternalOrderIdFilter["value"],
      },
    },
    {
      badge: {
        text: translations.passengerFilterText.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledPrivateOrderListingPassengerFilter["value"]
        ),
        title: translations.passengerFilterTitle.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledPrivateOrderListingPassengerFilter["value"]
        ),
      },
      filter: {
        type: BillingsTaxiUnbilledPrivateOrderListingFilterType.PASSENGER,
        value:
          filter.value as BillingsTaxiUnbilledPrivateOrderListingPassengerFilter["value"],
      },
    },
    {
      badge: {
        text: translations.passengerFilterText.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledPrivateOrderListingPassengerFilter["value"]
        ),
        title: translations.passengerFilterTitle.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledPrivateOrderListingPassengerFilter["value"]
        ),
      },
      filter: {
        type: BillingsTaxiUnbilledPrivateOrderListingFilterType.ROUTE_ADDRESS,
        value:
          filter.value as BillingsTaxiUnbilledPrivateOrderListingPassengerFilter["value"],
      },
    },
    {
      badge: {
        text: translations.passengerFilterText.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledPrivateOrderListingPassengerFilter["value"]
        ),
        title: translations.passengerFilterTitle.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledPrivateOrderListingPassengerFilter["value"]
        ),
      },
      filter: {
        type: BillingsTaxiUnbilledPrivateOrderListingFilterType.ROUTE_DESTINATION_ADDRESS,
        value:
          filter.value as BillingsTaxiUnbilledPrivateOrderListingPassengerFilter["value"],
      },
    },
    {
      badge: {
        text: translations.routeIntermediateAddressFilterText.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledPrivateOrderListingRouteIntermediateAddressFilter["value"]
        ),
        title: translations.routeIntermediateAddressFilterTitle.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledPrivateOrderListingRouteIntermediateAddressFilter["value"]
        ),
      },
      filter: {
        type: BillingsTaxiUnbilledPrivateOrderListingFilterType.ROUTE_INTERMEDIATE_ADDRESS,
        value:
          filter.value as BillingsTaxiUnbilledPrivateOrderListingRouteIntermediateAddressFilter["value"],
      },
    },
    {
      badge: {
        text: translations.routePickupAddressFilterText.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledPrivateOrderListingRoutePickupAddressFilter["value"]
        ),
        title: translations.routePickupAddressFilterTitle.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledPrivateOrderListingRoutePickupAddressFilter["value"]
        ),
      },
      filter: {
        type: BillingsTaxiUnbilledPrivateOrderListingFilterType.ROUTE_PICKUP_ADDRESS,
        value:
          filter.value as BillingsTaxiUnbilledPrivateOrderListingRoutePickupAddressFilter["value"],
      },
    },
    getStartDateBadgeOption(
      filter as BillingsTaxiUnbilledPrivateOrderListingStartDateFilter
    ),
    ...getStatusBadgeOptions(),
  ];

  return options;
};

const getBadge = (filter: BillingsTaxiUnbilledPrivateOrderListingFilter) => {
  const options = getBadgeOptions(filter);

  return options.find(
    (item) =>
      item.filter.type === filter.type && item.filter.value === filter.value
  )?.badge;
};

const getBadges = (
  filters: BillingsTaxiUnbilledPrivateOrderListingFilter[]
): ListingFilterBadge[] => {
  const badges: ListingFilterBadge[] = [];

  filters.forEach((filter) => {
    const badge = getBadge(filter);

    if (!badge) {
      return;
    }

    badges.push(badge);
  });

  return badges;
};

const billingsTaxiUnbilledPrivateOrderListingFiltersBadgeListHelper = {
  getBadges,
};

export default billingsTaxiUnbilledPrivateOrderListingFiltersBadgeListHelper;
