import ListingFilterBadge from "../../../../../../../common/components/listing/filter/badge/listing-filter-badge";
import BillingsTaxiUnbilledRouteListingFilter, {
  BillingsTaxiUnbilledRouteListingClientFilter,
  BillingsTaxiUnbilledRouteListingDriverFilter,
  BillingsTaxiUnbilledRouteListingFleetPartnerFilter,
  BillingsTaxiUnbilledRouteListingOrderInternalIdFilter,
  BillingsTaxiUnbilledRouteListingRouteAddressFilter,
  BillingsTaxiUnbilledRouteListingRouteDestinationAddressFilter,
  BillingsTaxiUnbilledRouteListingRouteIdFilter,
  BillingsTaxiUnbilledRouteListingRouteIntermediateAddressFilter,
  BillingsTaxiUnbilledRouteListingRoutePickupAddressFilter,
  BillingsTaxiUnbilledRouteListingRouteStartDateFilter,
} from "../../types/billings-taxi-unbilled-route-listing-filter";
import BillingsTaxiUnbilledRouteListingFilterType from "../../types/billings-taxi-unbilled-route-listing-filter-type";
import dateService from "../../../../../../../common/utils/date/date.service";
import billingsTranslationsHelper from "../../../../../../../languages/billings-translations.helper";

const getRouteStartDateBadgeOption = (
  filter: BillingsTaxiUnbilledRouteListingRouteStartDateFilter
): {
  badge: ListingFilterBadge;
  filter: BillingsTaxiUnbilledRouteListingRouteStartDateFilter;
} => {
  const translations =
    billingsTranslationsHelper.getTaxiUnbilledRouteBillingsListingTranslations()
      .filters;

  const formattedFromDate = dateService.formatDate(filter.value.from);

  const formattedToDate = dateService.formatDate(filter.value.to);

  return {
    badge: {
      text: translations.startDateFilterText
        .replace("#{fromDate}", formattedFromDate)
        .replace("#{toDate}", formattedToDate),
      title: translations.startDateFilterTitle
        .replace("#{fromDate}", formattedFromDate)
        .replace("#{toDate}", formattedToDate),
    },
    filter: {
      type: BillingsTaxiUnbilledRouteListingFilterType.ORDER_START_DATE,
      value: filter.value,
    },
  };
};

const getBadgeOptions = (filter: BillingsTaxiUnbilledRouteListingFilter) => {
  const translations =
    billingsTranslationsHelper.getTaxiUnbilledRouteBillingsListingTranslations()
      .filters;

  const options: {
    badge: ListingFilterBadge;
    filter: BillingsTaxiUnbilledRouteListingFilter;
  }[] = [
    {
      badge: {
        text: translations.driverFilterText.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledRouteListingDriverFilter["value"]
        ),
        title: translations.driverFilterTitle.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledRouteListingDriverFilter["value"]
        ),
      },
      filter: {
        type: BillingsTaxiUnbilledRouteListingFilterType.DRIVER,
        value:
          filter.value as BillingsTaxiUnbilledRouteListingDriverFilter["value"],
      },
    },
    {
      badge: {
        text: translations.fleetPartnerFilterText.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledRouteListingFleetPartnerFilter["value"]
        ),
        title: translations.fleetPartnerFilterTitle.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledRouteListingFleetPartnerFilter["value"]
        ),
      },
      filter: {
        type: BillingsTaxiUnbilledRouteListingFilterType.FLEET_PARTNER,
        value:
          filter.value as BillingsTaxiUnbilledRouteListingFleetPartnerFilter["value"],
      },
    },
    {
      badge: {
        text: translations.clientFilterText.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledRouteListingClientFilter["value"]
        ),
        title: translations.clientFilterTitle.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledRouteListingClientFilter["value"]
        ),
      },
      filter: {
        type: BillingsTaxiUnbilledRouteListingFilterType.CLIENT,
        value:
          filter.value as BillingsTaxiUnbilledRouteListingClientFilter["value"],
      },
    },
    {
      badge: {
        text: translations.orderInternalIdFilterText.replace(
          "#{query}",
          String(
            filter.value as BillingsTaxiUnbilledRouteListingOrderInternalIdFilter["value"]
          )
        ),
        title: translations.orderInternalIdFilterTitle.replace(
          "#{query}",
          String(
            filter.value as BillingsTaxiUnbilledRouteListingOrderInternalIdFilter["value"]
          )
        ),
      },
      filter: {
        type: BillingsTaxiUnbilledRouteListingFilterType.ORDER_INTERNAL_ID,
        value:
          filter.value as BillingsTaxiUnbilledRouteListingOrderInternalIdFilter["value"],
      },
    },
    {
      badge: {
        text: translations.routeIdFilterText.replace(
          "#{query}",
          String(
            filter.value as BillingsTaxiUnbilledRouteListingRouteIdFilter["value"]
          )
        ),
        title: translations.routeIdFilterTitle.replace(
          "#{query}",
          String(
            filter.value as BillingsTaxiUnbilledRouteListingRouteIdFilter["value"]
          )
        ),
      },
      filter: {
        type: BillingsTaxiUnbilledRouteListingFilterType.ROUTE_ID,
        value:
          filter.value as BillingsTaxiUnbilledRouteListingRouteIdFilter["value"],
      },
    },
    {
      badge: {
        text: translations.routeIntermediateAddressFilterText.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledRouteListingRouteIntermediateAddressFilter["value"]
        ),
        title: translations.routeIntermediateAddressFilterTitle.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledRouteListingRouteIntermediateAddressFilter["value"]
        ),
      },
      filter: {
        type: BillingsTaxiUnbilledRouteListingFilterType.ROUTE_INTERMEDIATE_ADDRESS,
        value:
          filter.value as BillingsTaxiUnbilledRouteListingRouteIntermediateAddressFilter["value"],
      },
    },
    {
      badge: {
        text: translations.routePickupAddressFilterText.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledRouteListingRoutePickupAddressFilter["value"]
        ),
        title: translations.routePickupAddressFilterTitle.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledRouteListingRoutePickupAddressFilter["value"]
        ),
      },
      filter: {
        type: BillingsTaxiUnbilledRouteListingFilterType.ROUTE_PICKUP_ADDRESS,
        value:
          filter.value as BillingsTaxiUnbilledRouteListingRoutePickupAddressFilter["value"],
      },
    },
    {
      badge: {
        text: translations.routeDestinationAddressFilterText.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledRouteListingRouteDestinationAddressFilter["value"]
        ),
        title: translations.routeDestinationAddressFilterTitle.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledRouteListingRouteDestinationAddressFilter["value"]
        ),
      },
      filter: {
        type: BillingsTaxiUnbilledRouteListingFilterType.ROUTE_DESTINATION_ADDRESS,
        value:
          filter.value as BillingsTaxiUnbilledRouteListingRouteDestinationAddressFilter["value"],
      },
    },
    {
      badge: {
        text: translations.routeAddressFilterText.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledRouteListingRouteAddressFilter["value"]
        ),
        title: translations.routeAddressFilterTitle.replace(
          "#{query}",
          filter.value as BillingsTaxiUnbilledRouteListingRouteAddressFilter["value"]
        ),
      },
      filter: {
        type: BillingsTaxiUnbilledRouteListingFilterType.ROUTE_ADDRESS,
        value:
          filter.value as BillingsTaxiUnbilledRouteListingRouteAddressFilter["value"],
      },
    },
    getRouteStartDateBadgeOption(
      filter as BillingsTaxiUnbilledRouteListingRouteStartDateFilter
    ),
  ];

  return options;
};

const getBadge = (filter: BillingsTaxiUnbilledRouteListingFilter) => {
  const options = getBadgeOptions(filter);

  return options.find(
    (item) =>
      item.filter.type === filter.type && item.filter.value === filter.value
  )?.badge;
};

const getBadges = (
  filters: BillingsTaxiUnbilledRouteListingFilter[]
): ListingFilterBadge[] => {
  const badges: ListingFilterBadge[] = [];

  filters.forEach((filter) => {
    const badge = getBadge(filter);

    if (!badge) {
      return;
    }

    badges.push(badge);
  });

  return badges;
};

const billingsTaxiUnbilledRouteListingFiltersBadgeListHelper = {
  getBadges,
};

export default billingsTaxiUnbilledRouteListingFiltersBadgeListHelper;
