import { FC } from "react";
import TableComponent from "../../../../../../common/components/table/table.component";
import CardComponent from "../../../../../../common/components/card/card.component";
import BillingsTaxiOrderListingStatsSummary from "../types/billings-taxi-order-listing-stats-summary";
import BillingsTaxiOrderListingStatsSummaryTableRow from "../types/billings-taxi-order-listing-stats-summary-table-row";
import billingsTaxiOrderListingStatsSummaryHelper from "./billings-taxi-order-listing-stats-summary.helper";
import billingsTranslationsHelper from "../../../../../../languages/billings-translations.helper";

type BillingsTaxiOrderListingStatsSummaryProps = {
  isError: boolean;
  isLoading: boolean;
  statsSummary: BillingsTaxiOrderListingStatsSummary | null;
};

const BillingsTaxiOrderListingStatsSummaryComponent: FC<
  BillingsTaxiOrderListingStatsSummaryProps
> = (props) => {
  if (!props.statsSummary) {
    return null;
  }

  const translations =
    billingsTranslationsHelper.getTaxiOrderBillingsListingTranslations().stats;

  const createTableRow = (
    statsSummary: BillingsTaxiOrderListingStatsSummary
  ): BillingsTaxiOrderListingStatsSummaryTableRow => {
    return {
      id: "stats-summary-row",
      idForTesting: "stats-summary-row",
      value: {
        distance: (
          <div title={String(statsSummary.distance)}>
            {statsSummary.distance &&
              billingsTaxiOrderListingStatsSummaryHelper.formatNumber(
                statsSummary.distance
              ) + " KM"}
          </div>
        ),
        amountForChargeHaltings: (
          <div title={String(statsSummary.amountForChargeHaltings)}>
            {statsSummary.amountForChargeHaltings &&
              billingsTaxiOrderListingStatsSummaryHelper.formatNumber(
                statsSummary.amountForChargeHaltings
              ) + " ZŁ"}
          </div>
        ),
        amountForChargeHighways: (
          <div title={String(statsSummary.amountForChargeHighways)}>
            {statsSummary.amountForChargeHighways &&
              billingsTaxiOrderListingStatsSummaryHelper.formatNumber(
                statsSummary.amountForChargeHighways
              ) + " ZŁ"}
          </div>
        ),
        amountForDistance: (
          <div title={String(statsSummary.amountForDistance)}>
            {statsSummary.amountForDistance &&
              billingsTaxiOrderListingStatsSummaryHelper.formatNumber(
                statsSummary.amountForDistance
              ) + " ZŁ"}
          </div>
        ),
        sumOfDiscounts: (
          <div title={String(statsSummary.sumOfDiscounts)}>
            {statsSummary.sumOfDiscounts &&
              billingsTaxiOrderListingStatsSummaryHelper.formatNumber(
                statsSummary.sumOfDiscounts
              ) + " ZŁ"}
          </div>
        ),
        allContributionsAmount: (
          <div title={String(statsSummary.allContributionsAmount)}>
            {statsSummary.allContributionsAmount &&
              billingsTaxiOrderListingStatsSummaryHelper.formatNumber(
                statsSummary.allContributionsAmount
              ) + " ZŁ"}
          </div>
        ),
      },
    };
  };

  const columns = billingsTaxiOrderListingStatsSummaryHelper.getColumns();
  const rows = [createTableRow(props.statsSummary)];

  return (
    <CardComponent
      classNames={{ root: "mt-4" }}
      header={{ title: translations.summaryLabel }}
    >
      <TableComponent
        columns={columns}
        isError={props.isError}
        isLoading={props.isLoading}
        rows={rows}
      />
    </CardComponent>
  );
};

export default BillingsTaxiOrderListingStatsSummaryComponent;
