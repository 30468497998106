import UserRole from "../../../../../../common/types/user-role";
import UserPermissions from "../../../../../../common/utils/user/permissions/user-permissions";
import UserPermissionsDefinition from "../../../../../../common/utils/user/permissions/user-permissions-definition";

export type BillingsTaxiOrderListingUserPermissionOption =
  | "hasAccessToTaxiView"
  | "hasAccessToRailyView"
  | "hasAccessToEditCargoWithTaxiBilling"
  | "hasAccessToDetailsCargoWithTaxiBilling";

export type BillingsTaxiOrderListingUserPermissionsDefinition =
  UserPermissionsDefinition<BillingsTaxiOrderListingUserPermissionOption>;

export type BillingsTaxiOrderListingUserPermissions =
  UserPermissions<BillingsTaxiOrderListingUserPermissionOption>;

const billingsTaxiOrderListingUserPermissionDefinition: BillingsTaxiOrderListingUserPermissionsDefinition =
  {
    hasAccessToTaxiView: [UserRole.TAXI_OFFICER],
    hasAccessToRailyView: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
    hasAccessToEditCargoWithTaxiBilling: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
    hasAccessToDetailsCargoWithTaxiBilling: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
  };

export default billingsTaxiOrderListingUserPermissionDefinition;
