import UserRole from "../../../../common/types/user-role";
import RouteItemDefinition from "../../../../routes/types/route-item-definition";
import BillingsCargoRailyAddComponent from "../../cargo-raily/add/billings-cargo-raily.component";
import BillingsCargoTaxiAddComponent from "../../cargo-taxi/add/billings-cargo-taxi.component";
import BillingsCargoTaxiEditComponent from "../../cargo-taxi/edit/billings-cargo-taxi.component";
import BillingsCargoTaxiViewComponent from "../../cargo-taxi/view/billings-cargo-taxi.component";
import BillingsCargoOrderListingComponent from "../../cargo/order-listing/billings-cargo-order-listing.component";
import BillingsRailyTaxiAddComponent from "../../raily-taxi/add/billings-raily-taxi.component";
import BillingsTaxiDriverAddComponent from "../../taxi-driver/add/billings-taxi-driver.component";
import BillingsTaxiDriverEditComponent from "../../taxi-driver/edit/billings-taxi-driver.component";
import BillingsTaxiDriverViewComponent from "../../taxi-driver/view/billings-taxi-driver.component";
import BillingsTaxiRailyAddComponent from "../../taxi-raily/add/billings-taxi-raily.component";
import BillingsTaxiTaxiAddComponent from "../../taxi-taxi/add/billings-taxi-taxi.component";
import BillingsTaxiTaxiEditComponent from "../../taxi-taxi/edit/billings-taxi-taxi.component";
import BillingsTaxiTaxiViewComponent from "../../taxi-taxi/view/billings-taxi-taxi.component";
import BillingsTaxiOrderListingComponent from "../../taxi/order-listing/billings-taxi-order-listing.component";
import BillingsTaxiRouteListingComponent from "../../taxi/route-listing/billings-taxi-route-listing.component";
import BillingsTaxiTransferredOrderListingComponent from "../../taxi/transferred-order-listing/billings-taxi-transferred-order-listing.component";
import BillingsTaxiUnbilledOrderListingComponent from "../../taxi/unbilled-order-listing/billings-taxi-unbilled-order-listing.component";
import BillingsTaxiUnbilledPrivateOrderListingComponent from "../../taxi/unbilled-private-order-listing/billings-taxi-unbilled-private-order-listing.component";
import BillingsTaxiUnbilledRouteListingComponent from "../../taxi/unbilled-route-listing/billings-taxi-unbilled-route-listing.component";
import BillingsTaxiUnbilledTransferredOrderListingComponent from "../../taxi/unbilled-transferred-order-listing/billings-taxi-unbilled-transferred-order-listing.component";
import billingRoutesUrls from "./billing-routes-urls";

const billingRoutesDefinition: RouteItemDefinition[] = [
  {
    path: billingRoutesUrls.cargoOrderListing,
    component: <BillingsCargoOrderListingComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.CARGO_OFFICER,
    ],
  },
  {
    path: billingRoutesUrls.taxiRouteListing,
    component: <BillingsTaxiRouteListingComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
  },
  {
    path: billingRoutesUrls.taxiUnbilledRouteListing,
    component: <BillingsTaxiUnbilledRouteListingComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
  },
  {
    path: billingRoutesUrls.taxiUnbilledPrivateOrderListing,
    component: <BillingsTaxiUnbilledPrivateOrderListingComponent />,
    userRolesWhitelist: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
  },
  {
    path: billingRoutesUrls.taxiOrderListing,
    component: <BillingsTaxiOrderListingComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
  },
  {
    path: billingRoutesUrls.taxiUnbilledTransferredOrderListing,
    component: <BillingsTaxiUnbilledTransferredOrderListingComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
  },
  {
    path: billingRoutesUrls.taxiTransferredOrderListing,
    component: <BillingsTaxiTransferredOrderListingComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
  },
  {
    path: billingRoutesUrls.taxiUnbilledOrderListing,
    component: <BillingsTaxiUnbilledOrderListingComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
  },
  {
    path: billingRoutesUrls.cargoWithRailyAdd,
    component: <BillingsCargoRailyAddComponent />,
    userRolesWhitelist: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
  },
  {
    path: billingRoutesUrls.railyWithTaxiAdd,
    component: <BillingsRailyTaxiAddComponent />,
    userRolesWhitelist: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
  },
  {
    path: billingRoutesUrls.cargoWithTaxiAdd,
    component: <BillingsCargoTaxiAddComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
  },
  {
    path: billingRoutesUrls.taxiWithDriverAdd,
    component: <BillingsTaxiDriverAddComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
  },
  {
    path: billingRoutesUrls.taxiWithRailyAdd,
    component: <BillingsTaxiRailyAddComponent />,
    userRolesWhitelist: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
  },
  {
    path: billingRoutesUrls.cargoWithTaxiEdit,
    component: <BillingsCargoTaxiEditComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
  },
  {
    path: billingRoutesUrls.cargoWithTaxiDetails,
    component: <BillingsCargoTaxiViewComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
      UserRole.CARGO_OFFICER,
    ],
  },
  {
    path: billingRoutesUrls.taxiWithDriverEdit,
    component: <BillingsTaxiDriverEditComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
  },
  {
    path: billingRoutesUrls.taxiWithDriverDetails,
    component: <BillingsTaxiDriverViewComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
  },
  {
    path: billingRoutesUrls.taxiWithTaxiAdd,
    component: <BillingsTaxiTaxiAddComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
  },
  {
    path: billingRoutesUrls.taxiWithTaxiEdit,
    component: <BillingsTaxiTaxiEditComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
  },
  {
    path: billingRoutesUrls.taxiWithTaxiDetails,
    component: <BillingsTaxiTaxiViewComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
  },
];

export default billingRoutesDefinition;
