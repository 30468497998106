import { FC, useEffect, useMemo, useState } from "react";
import useIsComponentMounted from "../../../../../common/hooks/use-is-component-mounted";
import useRouteQueryParams from "../../../../../common/hooks/use-route-query-params";
import BillingsTaxiUnbilledOrderListingByTaxiRouteQueryParams from "./common/types/billings-taxi-unbilled-order-listing-by-taxi-route-query-params";
import BillingsTaxiUnbilledOrderListingItem from "../common/types/billings-taxi-unbilled-order-listing-item";
import BillingsTaxiUnbilledOrderListingFilter, {
  BillingsTaxiUnbilledOrderListingStartDateFilter,
} from "../common/types/billings-taxi-unbilled-order-listing-filter";
import BillingsTaxiUnbilledOrderListingSortKey from "../common/types/billings-taxi-unbilled-order-listing-sort-key";
import BillingsTaxiUnbilledOrderListingSortSelectOption from "../common/types/billings-taxi-unbilled-order-listing-sort-select-option";
import billingsTaxiUnbilledOrderListingSortHelper from "../common/billings-taxi-unbilled-order-listing-sort.helper";
import usePagination from "../../../../../common/hooks/use-pagination";
import billingsTaxiUnbilledOrderListingByTaxiRouteQueryParamsService from "./common/billings-taxi-unbilled-order-listing-by-taxi-route-query-params.service";
import HeadingComponent from "../../../../../common/components/heading/heading.component";
import BillingsTaxiUnbilledOrderListingFiltersSelectComponent from "../common/filters/select/billings-taxi-unbilled-order-listing-filters-select.component";
import ListingSortSelectComponent from "../../../../../common/components/listing/filter/sort/select/listing-sort-select.component";
import BillingsTaxiUnbilledOrderListingFiltersBadgeListComponent from "../common/filters/list/billings-taxi-unbilled-order-listing-filters-badge-list.component";
import CardComponent from "../../../../../common/components/card/card.component";
import BillingsTaxiUnbilledOrderListingTableComponent from "../common/table/billings-taxi-unbilled-order-listing-table.component";
import PaginationComponent from "../../../../../common/components/pagination/pagination.component";
import BillingsTaxiUnbilledOrderListingViewBasicProps from "../common/types/billings-taxi-unbilled-order-listing-view-basic-props";
import billingsTaxiUnbilledOrderListingByTaxiFactory from "./common/billings-taxi-unbilled-order-listing-by-taxi.factory";
import { useAppContext } from "../../../../../context/app.context";
import BillingsTaxiUnbilledOrderListingFilterType from "../common/types/billings-taxi-unbilled-order-listing-filter-type";
import DateRange from "../../../../../common/types/date-range";
import BillingsTaxiUnbilledOrderListingByTaxiResponse, {
  BillingsTaxiUnbilledOrderListingByTaxiResponseData,
} from "./common/api/billings-taxi-unbilled-order-listing-by-taxi.response";
import billingsTaxiUnbilledOrderListingByTaxiApiService from "./common/api/billings-taxi-unbilled-order-listing-by-taxi-api.service";
import billingsTaxiUnbilledOrderListingByTaxiRequestFactory from "./common/billings-taxi-unbilled-order-listing-by-taxi-request.factory";
import DateRangeInputComponent from "../../../../../common/components/form/input/date-range/date-range-input.component";
import billingsTaxiUnbilledOrderListingHelper from "../common/billings-taxi-unbilled-order-listing.helper";
import StatusLegendComponent from "../../../../../common/components/status-legend/status-legend.component";
import billingsTranslationsHelper from "../../../../../languages/billings-translations.helper";

type BillingsTaxiUnbilledOrderListingByTaxiProps =
  BillingsTaxiUnbilledOrderListingViewBasicProps;

const BillingsTaxiUnbilledOrderListingByTaxiComponent: FC<
  BillingsTaxiUnbilledOrderListingByTaxiProps
> = () => {
  const { user, selectedAppLanguage } = useAppContext();
  const translations =
    billingsTranslationsHelper.getTaxiUnbilledOrderBillingsListingTranslations();

  const isComponentMounted = useIsComponentMounted();

  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<BillingsTaxiUnbilledOrderListingByTaxiRouteQueryParams>();

  const [areOrderListingItemsFetching, setAreOrderListingItemsFetching] =
    useState(false);

  const [
    isOrderListingItemsFetchingError,
    setIsOrderListingItemsFetchingError,
  ] = useState(false);

  const [orderListingItems, setOrderListingItems] = useState<
    BillingsTaxiUnbilledOrderListingItem[]
  >([]);
  const [totalListingItems, setTotalListingItems] = useState(0);

  const [filters, setFilters] = useState<
    BillingsTaxiUnbilledOrderListingFilter[]
  >(() =>
    billingsTaxiUnbilledOrderListingByTaxiRouteQueryParamsService.getFilters(
      routeQueryParams
    )
  );

  const [selectedSortKey, setSelectedSortKey] =
    useState<BillingsTaxiUnbilledOrderListingSortKey | null>(
      () =>
        billingsTaxiUnbilledOrderListingByTaxiRouteQueryParamsService.getSortKey(
          routeQueryParams
        ) ?? null
    );

  const sortSelectOptions: BillingsTaxiUnbilledOrderListingSortSelectOption[] =
    useMemo(
      () => billingsTaxiUnbilledOrderListingSortHelper.getSelectOptions(),
      [selectedAppLanguage]
    );

  const { page, pageSize, setPage, setPageSize } = usePagination({
    totalResults: totalListingItems,
    defaultPageSize:
      billingsTaxiUnbilledOrderListingByTaxiRouteQueryParamsService.getPageSize(
        routeQueryParams
      ),
    defaultPage:
      billingsTaxiUnbilledOrderListingByTaxiRouteQueryParamsService.getPage(
        routeQueryParams
      ),
  });

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    const queryParams =
      billingsTaxiUnbilledOrderListingByTaxiRouteQueryParamsService.createRouteQueryParams(
        filters,
        selectedSortKey,
        page,
        pageSize
      );

    setRouteQueryParams(queryParams);
  }, [filters, selectedSortKey, page, pageSize]);

  const onListingFetchSuccess = (
    responseData: BillingsTaxiUnbilledOrderListingByTaxiResponseData
  ) => {
    setTotalListingItems(responseData.total_count);

    const listingItems =
      billingsTaxiUnbilledOrderListingByTaxiFactory.createListingItems(
        responseData.data
      );

    setOrderListingItems(listingItems);
  };

  const onListingFetchFailure = () => {
    setIsOrderListingItemsFetchingError(true);
  };

  const handleListingResponse = (
    response: BillingsTaxiUnbilledOrderListingByTaxiResponse
  ) => {
    if (response.status === 200) {
      onListingFetchSuccess(response.data);
      return;
    }

    onListingFetchFailure();
  };

  const fetchListing = () => {
    setAreOrderListingItemsFetching(true);
    setIsOrderListingItemsFetchingError(false);

    const request =
      billingsTaxiUnbilledOrderListingByTaxiRequestFactory.createRequest(
        page,
        pageSize,
        filters,
        selectedSortKey
      );

    const taxiCorporationUuid = user?.aspects.taxiOfficer?.taxiCorporationUuid!;

    billingsTaxiUnbilledOrderListingByTaxiApiService
      .fetchListing(taxiCorporationUuid, request)
      .then(handleListingResponse)
      .catch(onListingFetchFailure)
      .finally(() => setAreOrderListingItemsFetching(false));
  };

  useEffect(() => {
    if (!page || !pageSize) {
      return;
    }

    fetchListing();
  }, [page, pageSize, filters, selectedSortKey]);

  const addNewFilter = (newFilter: BillingsTaxiUnbilledOrderListingFilter) => {
    setFilters((curr) => [...curr, newFilter]);
    setPage(1);
  };

  const deleteFilter = (index: number) => {
    const newFilters = filters.filter((filter, _index) => _index !== index);

    setFilters(newFilters);
    setPage(1);
  };

  const deleteAllFilters = () => {
    setFilters([]);
    setPage(1);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    window.scroll({ top: 0, behavior: "smooth" });
  };

  const onPageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
    setPage(1);
  };

  const selectedSortSelectOption = useMemo(() => {
    return (
      sortSelectOptions.find((item) => item.value === selectedSortKey) ?? null
    );
  }, [selectedSortKey]);

  const startDateFilterValue = filters.find(
    (filter) =>
      filter.type === BillingsTaxiUnbilledOrderListingFilterType.START_DATE
  )?.value as
    | BillingsTaxiUnbilledOrderListingStartDateFilter["value"]
    | undefined;

  const onStartDateFilterValueChange = (dateRange: DateRange | null) => {
    if (!dateRange) {
      const newFilters = filters.filter(
        (filter) =>
          filter.type !== BillingsTaxiUnbilledOrderListingFilterType.START_DATE
      );

      setFilters(newFilters);
      return;
    }

    const isFilterExists = !!filters.find(
      (filter) =>
        filter.type === BillingsTaxiUnbilledOrderListingFilterType.START_DATE
    );

    const newFilter: BillingsTaxiUnbilledOrderListingStartDateFilter = {
      type: BillingsTaxiUnbilledOrderListingFilterType.START_DATE,
      value: {
        from: dateRange.from!,
        to: dateRange.to!,
      },
    };

    if (isFilterExists) {
      const newFilters = [
        ...filters.filter(
          (filter) =>
            filter.type !==
            BillingsTaxiUnbilledOrderListingFilterType.START_DATE
        ),
        newFilter,
      ];

      setFilters(newFilters);
      setPage(1);
      return;
    }

    setFilters((curr) => [...curr, newFilter]);
    setPage(1);
  };

  const statusOptions =
    billingsTaxiUnbilledOrderListingHelper.getStatusLegendOptions();

  return (
    <div className="billings_taxi_unbilled_order_listing">
      <HeadingComponent title={translations.header.headingText} />
      <StatusLegendComponent statusData={statusOptions} />
      <div className="billings_taxi_unbilled_order_listing_tools">
        <div className="d-flex">
          <BillingsTaxiUnbilledOrderListingFiltersSelectComponent
            filters={filters}
            onAddNewFilter={addNewFilter}
          />
          <DateRangeInputComponent
            date={startDateFilterValue ?? null}
            onChange={onStartDateFilterValueChange}
            classNames={{ root: "ml-2" }}
            placeholder={
              translations.filters.searchByStartDateSelectInputPlaceholder
            }
            idForTesting="billings-taxi-unbilled-order-listing-by-taxi-date-range"
          />
        </div>

        <ListingSortSelectComponent
          onChange={(option) => setSelectedSortKey(option?.value!)}
          options={sortSelectOptions}
          value={selectedSortSelectOption}
          idForTesting="billings-taxi-unbilled-order-listing-by-taxi-sort"
        />
      </div>
      <BillingsTaxiUnbilledOrderListingFiltersBadgeListComponent
        filters={filters}
        onDeleteFilterClick={deleteFilter}
        onDeleteAllFiltersButtonClick={deleteAllFilters}
      />
      <CardComponent classNames={{ root: "mt-4" }}>
        <BillingsTaxiUnbilledOrderListingTableComponent
          listingItems={orderListingItems}
          isError={isOrderListingItemsFetchingError}
          isLoading={areOrderListingItemsFetching}
        />
        <div className="billings_taxi_unbilled_order_listing__pagination_wrapper">
          <PaginationComponent
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            page={page}
            pageSize={pageSize}
            totalResults={totalListingItems}
          />
        </div>
      </CardComponent>
    </div>
  );
};

export default BillingsTaxiUnbilledOrderListingByTaxiComponent;
