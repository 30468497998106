enum DriverListingSortKey {
  FIRST_NAME_ASC = "FIRST_NAME_ASC",
  FIRST_NAME_DESC = "FIRST_NAME_DESC",
  LAST_NAME_ASC = "LAST_NAME_ASC",
  LAST_NAME_DESC = "LAST_NAME_DESC",
  FLEET_PARTNER_ASC = "FLEET_PARTNER_ASC",
  FLEET_PARTNER_DESC = "FLEET_PARTNER_DESC",
  TAXI_CORPORATION_ASC = "TAXI_CORPORATION_ASC",
  TAXI_CORPORATION_DESC = "TAXI_CORPORATION_DESC",
  LAST_PLAN_ENTRY_END_TIME_ASC = "LAST_PLAN_ENTRY_END_TIME_ASC",
  LAST_PLAN_ENTRY_END_TIME_DESC = "LAST_PLAN_ENTRY_END_TIME_DESC",
}

export default DriverListingSortKey;
