import { FC, useMemo, useState } from "react";
import ListingFilterSelectComponent from "../../../../../../../common/components/listing/filter/select/listing-filter-select.component";
import { useAppContext } from "../../../../../../../context/app.context";
import BillingsTaxiUnbilledTransferredOrderListingFilter from "../../types/billings-taxi-unbilled-transferred-order-listing-filter";
import BillingsTaxiUnbilledTransferredOrderListingFilterSelectOption from "../../types/billings-taxi-unbilled-transferred-order-listing-filter-select-option";
import billingsTaxiUnbilledTransferredOrderListingFilterHelper from "../../helper/billings-taxi-unbilled-transferred-order-listing-filter.helper";

type BillingsTaxiUnbilledTransferredOrderListingFiltersSelectProps = {
  filters: BillingsTaxiUnbilledTransferredOrderListingFilter[];
  onAddNewFilter: (
    filter: BillingsTaxiUnbilledTransferredOrderListingFilter
  ) => void;
};

const BillingsTaxiUnbilledTransferredOrderListingFiltersSelectComponent: FC<
  BillingsTaxiUnbilledTransferredOrderListingFiltersSelectProps
> = (props) => {
  const { selectedAppLanguage } = useAppContext();

  const [searchInputValue, setSearchInputValue] = useState("");

  const searchSelectOptions: BillingsTaxiUnbilledTransferredOrderListingFilterSelectOption[] =
    useMemo(() => {
      return billingsTaxiUnbilledTransferredOrderListingFilterHelper.getSelectOptions(
        searchInputValue,
        props.filters
      );
    }, [searchInputValue, props.filters, selectedAppLanguage]);

  const onFilterSelect = (
    filter: BillingsTaxiUnbilledTransferredOrderListingFilterSelectOption
  ) => {
    props.onAddNewFilter(filter.value);
  };

  return (
    <ListingFilterSelectComponent
      onChange={(option) =>
        onFilterSelect(
          option as BillingsTaxiUnbilledTransferredOrderListingFilterSelectOption
        )
      }
      options={searchSelectOptions}
      inputValue={searchInputValue}
      onInputChange={setSearchInputValue}
      idForTesting="billings-taxi-unbilled-transferred-order-listing-filter"
    />
  );
};

export default BillingsTaxiUnbilledTransferredOrderListingFiltersSelectComponent;
