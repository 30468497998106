import { FC, useEffect, useMemo, useState } from "react";
import CardComponent from "../../../common/components/card/card.component";
import HeadingComponent from "../../../common/components/heading/heading.component";
import { useAppContext } from "../../../context/app.context";
import OrderAbandonedListingItem from "./common/types/order-abandoned-listing-item";
import OrderAbandonedListingFilter from "./common/types/order-abandoned-listing-filter";
import OrderAbandonedListingSortKey from "./common/types/order-abandoned-listing-sort-key";
import orderTranslationsHelper from "../../../languages/order-translations.helper";
import orderBreadcrumbsHelper from "../common/breadcrumbs/order-breadcrumbs.helper";
import appTranslationsHelper from "../../../languages/app-translations.helper";
import useDocumentTitle from "../../../common/hooks/use-document-title";
import ButtonComponent from "../../../common/components/button/button.component";
import OrderAbandonedListingFiltersComponent from "./filter/order-abandoned-listing-filters.component";
import usePagination from "../../../common/hooks/use-pagination";
import useCargoOrderAbandonedList from "../../../common/services/cargo-order/abandoned-list/use-cargo-order-abandoned-list";
import useAbort from "../../../common/hooks/use-abort";
import useOrderAbandonedListingFiltersDao from "./filter/use-order-abandoned-listing-filters.dao";
import ListingPaginationComponent from "../../../common/components/listing/pagination/listing-pagination.component";
import useIsComponentMounted from "../../../common/hooks/use-is-component-mounted";
import orderAbandonedListingLoadParamsFactory from "./common/order-abandoned-listing-load-params.factory";
import CargoOrderAbandonedListLoadParams from "../../../common/services/cargo-order/abandoned-list/cargo-order-abandoned-list-load-params";
import orderAbandonedListingItemFactory from "./common/order-abandoned-listing-item.factory";
import OrderAbandonedListingTableComponent from "./table/order-abandoned-listing-table.component";
import CargoOrderAbandonedListReportLoadParams, {
  OrderAbandonedListReportLoadParamsOrder,
} from "../../../common/services/cargo-order/abandoned-list/report/cargo-order-abandoned-list-report-load-params";
import orderAbandonedListingReportLoadParamsFactory from "./common/order-abandoned-listing-report-load-params.factory";
import CargoOrderAbandonedListReport from "../../../common/services/cargo-order/abandoned-list/report/cargo-order-abandoned-list-report";
import cargoOrderService from "../../../common/services/cargo-order/cargo-order.service";
import CargoOrderAbandonedListReportError from "../../../common/services/cargo-order/abandoned-list/report/cargo-order-abandoned-list-report-error";
import fileDownloadService from "../../../common/utils/file-download/file-download.service";
import notificationService from "../../../common/utils/notification/notification.service";

const OrderAbandonedListingComponent: FC = () => {
  const isComponentMounted = useIsComponentMounted();
  const { setBreadcrumbs, selectedAppLanguage } = useAppContext();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.orderAbandonedListing);

  useEffect(() => {
    const breadcrumbs = orderBreadcrumbsHelper.getAbandonedListingBreadcrumbs();
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  const translations =
    orderTranslationsHelper.getAbandonedListingTranslations();

  const orderAbandonedList = useCargoOrderAbandonedList();
  const orderAbandonedListAbort = useAbort();
  const orderAbandonedListReportAbort = useAbort();

  const filtersDao = useOrderAbandonedListingFiltersDao();

  const { page, pageSize, setPage, setPageSize } = usePagination({
    totalResults: orderAbandonedList.data.totalCount,
    defaultPageSize: filtersDao.getPageSize(),
    defaultPage: filtersDao.getPage(),
  });

  const [filters, setFilters] = useState<OrderAbandonedListingFilter[]>(() =>
    filtersDao.getFilters()
  );

  const [sortKey, setSortKey] = useState<OrderAbandonedListingSortKey>(
    () =>
      filtersDao.getSortKey() ??
      OrderAbandonedListingSortKey.CARGO_COMPANY_INTERNAL_ID_ASC
  );

  const [expandedRowsOrderUuids, setExpandedRowsOrderUuids] = useState<
    OrderAbandonedListingItem["uuid"][]
  >([]);

  const [isReportFetching, setIsReportFetching] = useState(false);

  const saveFilters = () => {
    filtersDao.saveFilters(filters, sortKey, page, pageSize);
  };

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    saveFilters();
  }, [filters, sortKey, page, pageSize]);

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, [page]);

  const loadListingItems = async () => {
    const params: CargoOrderAbandonedListLoadParams =
      orderAbandonedListingLoadParamsFactory.create(
        page,
        pageSize,
        sortKey,
        filters
      );

    orderAbandonedList.load(params, orderAbandonedListAbort.signal);
  };

  useEffect(() => {
    setExpandedRowsOrderUuids([]);
    loadListingItems();

    return orderAbandonedListAbort.revoke;
  }, [page, pageSize, filters, sortKey]);

  const listingItems: OrderAbandonedListingItem[] = useMemo(() => {
    return orderAbandonedListingItemFactory.createOrderListingItems(
      orderAbandonedList.data.data
    );
  }, [orderAbandonedList.data.data]);

  const onTableRowClick = (orderUuid: OrderAbandonedListingItem["uuid"]) => {
    if (expandedRowsOrderUuids.includes(orderUuid)) {
      setExpandedRowsOrderUuids((current) =>
        current.filter((expandedOrderUuid) => expandedOrderUuid !== orderUuid)
      );
    } else {
      setExpandedRowsOrderUuids((current) => [...current, orderUuid]);
    }
  };

  const downloadReportFile = (report: CargoOrderAbandonedListReport) => {
    fileDownloadService.downloadFromBlob(report.data, report.filename);
  };

  const onReportFetchSuccess = async (
    report: CargoOrderAbandonedListReport
  ) => {
    downloadReportFile(report);
  };

  const onReportFetchFailure = (error: CargoOrderAbandonedListReportError) => {
    notificationService.error(error.message);
  };

  const fetchReport = async () => {
    setIsReportFetching(true);

    const params: CargoOrderAbandonedListReportLoadParams =
      orderAbandonedListingReportLoadParamsFactory.create(sortKey, filters);

    try {
      const orderAbandonedListReport: CargoOrderAbandonedListReport =
        await cargoOrderService.getAbandonedListReport(
          params,
          orderAbandonedListReportAbort.signal
        );

      onReportFetchSuccess(orderAbandonedListReport);
    } catch (error) {
      onReportFetchFailure(error as CargoOrderAbandonedListReportError);
    } finally {
      setIsReportFetching(false);
    }
  };

  const ReportDownloadButton = (
    <ButtonComponent
      type={"primary"}
      isLoading={isReportFetching}
      title={translations.header.downloadButtonTitle}
      onClick={fetchReport}
      idForTesting="order-abandoned-listing-download-report-button"
    >
      {translations.header.downloadButtonLabel}
    </ButtonComponent>
  );

  return (
    <div className="order_abandoned_listing">
      <HeadingComponent
        title={translations.header.headingLabel}
        actions={[ReportDownloadButton]}
      />
      <OrderAbandonedListingFiltersComponent
        filters={filters}
        onFiltersChange={setFilters}
        sortKey={sortKey}
        onSortKeyChange={setSortKey}
      />
      <CardComponent classNames={{ root: "mt-4" }}>
        <OrderAbandonedListingTableComponent
          listingItems={listingItems}
          isLoading={orderAbandonedList.isLoading}
          isError={orderAbandonedList.isError}
          expandedRowsOrderUuids={expandedRowsOrderUuids}
          onRowClick={onTableRowClick}
        />
        <ListingPaginationComponent
          page={page}
          pageSize={pageSize}
          totalResults={orderAbandonedList.data.totalCount}
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
        />
      </CardComponent>
    </div>
  );
};

export default OrderAbandonedListingComponent;
