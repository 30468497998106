import { faTaxi, faTruck } from "@fortawesome/free-solid-svg-icons";
import dateService from "../../../../../../common/utils/date/date.service";
import BillingsTaxiUnbilledOrderListingItem, {
  BillingsTaxiUnbilledOrderListingItemMissingBillingType,
} from "../types/billings-taxi-unbilled-order-listing-item";
import BillingsTaxiUnbilledOrderListingTableColumn from "../types/billings-taxi-unbilled-order-listing-table-column";
import BillingsTaxiUnbilledOrderListingTableRow from "../types/billings-taxi-unbilled-order-listing-table-row";
import BillingsTaxiUnbilledOrderListingTableStatusComponent from "./status/billings-taxi-unbilled-order-listing-table-status.component";
import TableLinkButtonComponent from "../../../../../../common/components/table/button/link/table-link-button.component";
import billingRoutesHelper from "../../../../common/routes/billing-routes.helper";
import billingsTranslationsHelper from "../../../../../../languages/billings-translations.helper";

const getColumns = (): BillingsTaxiUnbilledOrderListingTableColumn[] => {
  const translations =
    billingsTranslationsHelper.getTaxiUnbilledOrderBillingsListingTranslations()
      .table.headers;

  const columns: BillingsTaxiUnbilledOrderListingTableColumn[] = [
    {
      accessor: "status",
      header: "",
      title: "",
      colSpan: 2,
    },
    {
      accessor: "startDate",
      header: translations.startDateLabel,
      title: translations.startDateTitle,
      colSpan: 10,
    },
    {
      accessor: "internalOrderId",
      header: translations.internalOrderIdLabel,
      title: translations.internalOrderIdTitle,
      colSpan: 8,
    },
    {
      accessor: "externalOrderId",
      header: translations.externalOrderIdLabel,
      title: translations.externalOrderIdTitle,
      colSpan: 8,
    },
    {
      accessor: "route",
      header: translations.routeLabel,
      title: translations.routeTitle,
      colSpan: 35,
    },
    {
      accessor: "passengers",
      header: translations.passengersLabel,
      title: translations.passengersTitle,
      colSpan: 10,
    },
    {
      accessor: "dispatch",
      header: translations.dispatchLabel,
      title: translations.dispatchTitle,
      colSpan: 8,
    },
    {
      accessor: "client",
      header: translations.clientLabel,
      title: translations.clientTitle,
      colSpan: 8,
    },
    {
      accessor: "taxiCorporations",
      header: translations.taxiCorporationsLabel,
      title: translations.taxiCorporationsTitle,
      colSpan: 8,
    },
    {
      accessor: "drivers",
      header: translations.driversLabel,
      title: translations.driversTitle,
      colSpan: 10,
    },
    {
      accessor: "actions",
      header: "",
      title: "",
      colSpan: 10,
    },
  ];

  return columns;
};

const checkIsAddressGroup = (
  address: string | string[]
): address is string[] => {
  if (!Array.isArray(address)) {
    return false;
  }

  return true;
};

const getRouteLabel = (
  addresses: BillingsTaxiUnbilledOrderListingItem["routeAddresses"]
): string => {
  const addressesWithFlattenedGroups = addresses.map((address) => {
    const isAddressGroup = checkIsAddressGroup(address);

    if (!isAddressGroup) {
      return address;
    }

    const connectedAddresses = address.join(" | ");
    return `[ ${connectedAddresses} ]`;
  });

  return addressesWithFlattenedGroups.join(" > ");
};

const getRouteTitle = (
  addresses: BillingsTaxiUnbilledOrderListingItem["routeAddresses"]
): string => {
  return addresses.join(`\r`);
};

const getRow = (
  listingItem: BillingsTaxiUnbilledOrderListingItem
): BillingsTaxiUnbilledOrderListingTableRow => {
  const translations =
    billingsTranslationsHelper.getTaxiUnbilledOrderBillingsListingTranslations()
      .table;

  const routeAddressesLabel = getRouteLabel(listingItem.routeAddresses);
  const routeAddressesTitle = getRouteTitle(listingItem.routeAddresses);

  const startDateLabel = dateService.format(
    listingItem.startDate,
    "dd.mm.yyyy"
  );

  const startDateTitle = dateService.format(
    listingItem.startDate,
    "dd.mm.yyyy"
  );

  const isCargoWithTaxiBillingAddButtonVisible =
    listingItem.missingBillingTypes.includes(
      BillingsTaxiUnbilledOrderListingItemMissingBillingType.CARGO_WITH_TAXI
    );

  const isRailyWithTaxiBillingAddButtonVisible =
    listingItem.missingBillingTypes.includes(
      BillingsTaxiUnbilledOrderListingItemMissingBillingType.RAILY_WITH_TAXI
    );

  return {
    id: listingItem.uuid,
    value: {
      client: (
        <div title={listingItem.clientName}>{listingItem.clientName}</div>
      ),
      dispatch: (
        <div title={listingItem.dispatchName}>{listingItem.dispatchName}</div>
      ),
      drivers: (
        <div>
          {listingItem.driversDisplayNames.map((driverDisplayName, index) => (
            <div key={index} title={driverDisplayName}>
              {driverDisplayName}
            </div>
          ))}
        </div>
      ),
      externalOrderId: (
        <div title={listingItem.externalOrderId ?? undefined}>
          {listingItem.externalOrderId ?? undefined}
        </div>
      ),
      internalOrderId: (
        <div title={String(listingItem.internalOrderId)}>
          {String(listingItem.internalOrderId)}
        </div>
      ),
      passengers: (
        <div>
          {listingItem.passengers.map((passenger, index) => (
            <div key={index} title={passenger}>
              {passenger}
            </div>
          ))}
        </div>
      ),
      route: <div title={routeAddressesTitle}>{routeAddressesLabel}</div>,
      startDate: <div title={startDateTitle}>{startDateLabel}</div>,
      status: (
        <BillingsTaxiUnbilledOrderListingTableStatusComponent
          status={listingItem.status}
        />
      ),
      taxiCorporations: (
        <div>
          {listingItem.taxiCorporations.map((taxiCorporation, index) => (
            <div key={index} title={taxiCorporation}>
              {taxiCorporation}
            </div>
          ))}
        </div>
      ),
      actions: (
        <div className="d-flex">
          {isCargoWithTaxiBillingAddButtonVisible && (
            <TableLinkButtonComponent
              icon={faTruck}
              to={billingRoutesHelper.getCargoWithTaxiAddRoute({
                orderUuid: listingItem.uuid,
              })}
              title={translations.addCargoWithTaxiBillingButtonTitle}
              idForTesting={`billings-taxi-unbilled-order-listing-table-item-${listingItem.uuid}-add-cargo-button`}
            />
          )}
          {isRailyWithTaxiBillingAddButtonVisible && (
            <TableLinkButtonComponent
              icon={faTaxi}
              to={billingRoutesHelper.getRailyWithTaxiAddRoute({
                orderUuid: listingItem.uuid,
              })}
              title={translations.addRailyWithTaxiBillingButtonTitle}
              idForTesting={`billings-taxi-unbilled-order-listing-table-item-${listingItem.uuid}-add-raily-button`}
            />
          )}
        </div>
      ),
    },
  };
};

const getRows = (
  listingItems: BillingsTaxiUnbilledOrderListingItem[]
): BillingsTaxiUnbilledOrderListingTableRow[] => {
  return listingItems.map(getRow);
};

const billingsTaxiUnbilledOrderListingTableHelper = {
  getColumns,
  getRows,
};

export default billingsTaxiUnbilledOrderListingTableHelper;
