import ListingFilterBadge from "../../../../../../../common/components/listing/filter/badge/listing-filter-badge";
import dateService from "../../../../../../../common/utils/date/date.service";
import billingsTranslationsHelper from "../../../../../../../languages/billings-translations.helper";
import billingsTaxiTransferredOrderListingHelper from "../../helper/billings-taxi-transferred-order-listing.helper";
import BillingsTaxiTransferredOrderListingFilter, {
  BillingsTaxiTransferredOrderListingConsumerTaxiFilter,
  BillingsTaxiTransferredOrderListingInternalOrderIdFilter,
  BillingsTaxiTransferredOrderListingOrderStartDateFilter,
  BillingsTaxiTransferredOrderListingProducerTaxiFilter,
  BillingsTaxiTransferredOrderListingStatusFilter,
} from "../../types/billings-taxi-transferred-order-listing-filter";
import BillingsTaxiTransferredOrderListingFilterType from "../../types/billings-taxi-transferred-order-listing-filter-type";
import BillingsTaxiTransferredOrderListingItemBillingStatus from "../../types/billings-taxi-transferred-order-listing-item-billing-status";

const getStartDateBadgeOption = (
  filter: BillingsTaxiTransferredOrderListingOrderStartDateFilter
): {
  badge: ListingFilterBadge;
  filter: BillingsTaxiTransferredOrderListingOrderStartDateFilter;
} => {
  const translations =
    billingsTranslationsHelper.getTaxiRouteTransferredOrderBillingsListingTranslations()
      .filters;

  const formattedFromDate = dateService.formatDate(filter.value.from);

  const formattedToDate = dateService.formatDate(filter.value.to);

  return {
    badge: {
      text: translations.orderStartDateFilterText
        .replace("#{fromDate}", formattedFromDate)
        .replace("#{toDate}", formattedToDate),
      title: translations.orderStartDateFilterTitle
        .replace("#{fromDate}", formattedFromDate)
        .replace("#{toDate}", formattedToDate),
    },
    filter: {
      type: BillingsTaxiTransferredOrderListingFilterType.ORDER_START_DATE,
      value: filter.value,
    },
  };
};

const getBillingStatusBadgeOptions = (): {
  badge: ListingFilterBadge;
  filter: BillingsTaxiTransferredOrderListingStatusFilter;
}[] => {
  const translations =
    billingsTranslationsHelper.getTaxiRouteTransferredOrderBillingsListingTranslations()
      .filters;

  return Object.values(
    BillingsTaxiTransferredOrderListingItemBillingStatus
  ).map((statusFilterValue) => {
    return {
      badge: {
        text: translations.billingStatusFilterText.replace(
          "#{option}",
          billingsTaxiTransferredOrderListingHelper.getBillingStatusText(
            statusFilterValue
          )
        ),
        title: translations.billingStatusFilterTitle.replace(
          "#{option}",
          billingsTaxiTransferredOrderListingHelper.getBillingStatusText(
            statusFilterValue
          )
        ),
      },
      filter: {
        type: BillingsTaxiTransferredOrderListingFilterType.BILLING_STATUS,
        value: statusFilterValue,
      },
    };
  });
};

const getBadgeOptions = (filter: BillingsTaxiTransferredOrderListingFilter) => {
  const translations =
    billingsTranslationsHelper.getTaxiRouteTransferredOrderBillingsListingTranslations()
      .filters;

  const options: {
    badge: ListingFilterBadge;
    filter: BillingsTaxiTransferredOrderListingFilter;
  }[] = [
    {
      badge: {
        text: translations.internalOrderIdFilterText.replace(
          "#{query}",
          String(
            filter.value as BillingsTaxiTransferredOrderListingInternalOrderIdFilter["value"]
          )
        ),
        title: translations.internalOrderIdFilterTitle.replace(
          "#{query}",
          String(
            filter.value as BillingsTaxiTransferredOrderListingInternalOrderIdFilter["value"]
          )
        ),
      },
      filter: {
        type: BillingsTaxiTransferredOrderListingFilterType.INTERNAL_ORDER_ID,
        value:
          filter.value as BillingsTaxiTransferredOrderListingInternalOrderIdFilter["value"],
      },
    },
    {
      badge: {
        text: translations.producerTaxiFilterText.replace(
          "#{query}",
          String(
            filter.value as BillingsTaxiTransferredOrderListingProducerTaxiFilter["value"]
          )
        ),
        title: translations.producerTaxiFilterTitle.replace(
          "#{query}",
          String(
            filter.value as BillingsTaxiTransferredOrderListingProducerTaxiFilter["value"]
          )
        ),
      },
      filter: {
        type: BillingsTaxiTransferredOrderListingFilterType.PRODUCER_TAXI,
        value:
          filter.value as BillingsTaxiTransferredOrderListingProducerTaxiFilter["value"],
      },
    },
    {
      badge: {
        text: translations.consumerTaxiFilterText.replace(
          "#{query}",
          String(
            filter.value as BillingsTaxiTransferredOrderListingConsumerTaxiFilter["value"]
          )
        ),
        title: translations.consumerTaxiFilterTitle.replace(
          "#{query}",
          String(
            filter.value as BillingsTaxiTransferredOrderListingConsumerTaxiFilter["value"]
          )
        ),
      },
      filter: {
        type: BillingsTaxiTransferredOrderListingFilterType.CONSUMER_TAXI,
        value:
          filter.value as BillingsTaxiTransferredOrderListingConsumerTaxiFilter["value"],
      },
    },
    getStartDateBadgeOption(
      filter as BillingsTaxiTransferredOrderListingOrderStartDateFilter
    ),
    ...getBillingStatusBadgeOptions(),
  ];

  return options;
};

const getBadge = (filter: BillingsTaxiTransferredOrderListingFilter) => {
  const options = getBadgeOptions(filter);

  return options.find(
    (item) =>
      item.filter.type === filter.type && item.filter.value === filter.value
  )?.badge;
};

const getBadges = (
  filters: BillingsTaxiTransferredOrderListingFilter[]
): ListingFilterBadge[] => {
  const badges: ListingFilterBadge[] = [];

  filters.forEach((filter) => {
    const badge = getBadge(filter);

    if (!badge) {
      return;
    }

    badges.push(badge);
  });

  return badges;
};

const billingsTaxiTransferredOrderListingFiltersBadgeListHelper = {
  getBadges,
};

export default billingsTaxiTransferredOrderListingFiltersBadgeListHelper;
