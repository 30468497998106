enum BillingsTaxiUnbilledTransferredOrderListingFilterType {
  PRODUCER_TAXI = "PRODUCER_TAXI",
  CONSUMER_TAXI = "CONSUMER_TAXI",
  DRIVER = "DRIVER",
  EXTERNAL_ORDER_ID = "EXTERNAL_ORDER_ID",
  INTERNAL_ORDER_ID = "INTERNAL_ORDER_ID",
  PASSENGER = "PASSENGER",
  ROUTE_ADDRESS = "ROUTE_ADDRESS",
  ROUTE_DESTINATION_ADDRESS = "ROUTE_DESTINATION_ADDRESS",
  ROUTE_INTERMEDIATE_ADDRESS = "ROUTE_INTERMEDIATE_ADDRESS",
  ROUTE_PICKUP_ADDRESS = "ROUTE_PICKUP_ADDRESS",
  START_DATE = "START_DATE",
}

export default BillingsTaxiUnbilledTransferredOrderListingFilterType;
