import { faCheck, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FC } from "react";
import TableComponent from "../../../../../../common/components/table/table.component";
import { DriverDetailsDriverPlanEntryWaypoint } from "../common/types/driver-details-plan-entry";
import DriverDetailsPlanEntryRouteTableRow from "../common/types/driver-details-plan-entry-route-table-row";
import driverDetailsPlanByRailyRouteListingHelper from "./driver-details-plan-by-raily-route-listing.helper";
import TableButtonComponent from "../../../../../../common/components/table/button/table-button.component";
import TableDeleteButtonComponent from "../../../../../../common/components/table/button/delete/table-delete-button.component";
import userTranslationsHelper from "../../../../../../languages/user-translations.helper";
import { useAppContext } from "../../../../../../context/app.context";
import driverDetailsUserPermissionsHelper from "../../common/user-permissions/driver-details-user-permission.helper";

type DriverDetailsPlanByRailyRouteListingProps = {
  routeWaypoints: DriverDetailsDriverPlanEntryWaypoint[];
  onAddCheckoutButtonClick: (
    waypointUuid: DriverDetailsDriverPlanEntryWaypoint["uuid"]
  ) => void;
  onEditCheckoutButtonClick: (
    waypointUuid: DriverDetailsDriverPlanEntryWaypoint["uuid"],
    checkoutEventUuid: DriverDetailsDriverPlanEntryWaypoint["checkoutEventUuid"]
  ) => void;
  onDeleteCheckoutButtonClick: (
    waypointUuid: DriverDetailsDriverPlanEntryWaypoint["uuid"],
    checkoutEventUuid: DriverDetailsDriverPlanEntryWaypoint["checkoutEventUuid"]
  ) => void;
};

const DriverDetailsPlanByRailyRouteListingComponent: FC<
  DriverDetailsPlanByRailyRouteListingProps
> = (props) => {
  const translations =
    userTranslationsHelper.getDriverDetailsActiveRoutePlansTranslations()
      .routeListing.table;
  const columns = driverDetailsPlanByRailyRouteListingHelper.getColumns();

  const { user } = useAppContext();

  const userPermissions = driverDetailsUserPermissionsHelper.getPermissions(
    user?.roles!
  );

  const createTableRow = (
    routeWaypoint: DriverDetailsDriverPlanEntryWaypoint,
    index: number
  ): DriverDetailsPlanEntryRouteTableRow => {
    const clientLabel =
      driverDetailsPlanByRailyRouteListingHelper.getClientLabel(
        routeWaypoint.clientDisplayName
      );

    const clientTitle =
      driverDetailsPlanByRailyRouteListingHelper.getClientTitle(
        routeWaypoint.clientDisplayName
      );

    const orderIdLabel =
      driverDetailsPlanByRailyRouteListingHelper.getOrderIdLabel(
        routeWaypoint.order?.cargoInternalOrderId
      );

    const orderIdTitle =
      driverDetailsPlanByRailyRouteListingHelper.getOrderIdTitle(
        routeWaypoint.order?.cargoInternalOrderId
      );

    const plannedDateLabel =
      driverDetailsPlanByRailyRouteListingHelper.getPlannedDateLabel(
        routeWaypoint.plannedDate
      );

    const plannedDateTitle =
      driverDetailsPlanByRailyRouteListingHelper.getPlannedDateTitle(
        routeWaypoint.plannedDate
      );

    const estimatedDateLabel =
      driverDetailsPlanByRailyRouteListingHelper.getEstimatedDateLabel(
        routeWaypoint.estimatedDate
      );

    const estimatedDateTitle =
      driverDetailsPlanByRailyRouteListingHelper.getEstimatedDateTitle(
        routeWaypoint.estimatedDate
      );

    const checkoutDateLabel =
      driverDetailsPlanByRailyRouteListingHelper.getCheckoutDateLabel(
        routeWaypoint.checkoutDate
      );

    const checkoutDateTitle =
      driverDetailsPlanByRailyRouteListingHelper.getCheckoutDateTitle(
        routeWaypoint.checkoutDate
      );

    const plannedHaltingTimeLabel =
      driverDetailsPlanByRailyRouteListingHelper.getPlannedHaltingTimeLabel(
        routeWaypoint.plannedHaltingTime
      );

    const plannedHaltingTimeTitle =
      driverDetailsPlanByRailyRouteListingHelper.getPlannedHaltingTimeTitle(
        routeWaypoint.plannedHaltingTime
      );

    const forcedHaltingTimeLabel =
      driverDetailsPlanByRailyRouteListingHelper.getForcedHaltingTimeLabel(
        routeWaypoint.forcedHaltingTime
      );

    const forcedHaltingTimeTitle =
      driverDetailsPlanByRailyRouteListingHelper.getForcedHaltingTimeTitle(
        routeWaypoint.forcedHaltingTime
      );

    const highwayCostLabel =
      driverDetailsPlanByRailyRouteListingHelper.getHighwayCostLabel(
        routeWaypoint.highwayCost
      );

    const highwayCostTitle =
      driverDetailsPlanByRailyRouteListingHelper.getHighwayCostTitle(
        routeWaypoint.highwayCost
      );

    const createdAtLabel =
      driverDetailsPlanByRailyRouteListingHelper.getCreatedAtLabel(
        routeWaypoint.createdAt
      );

    const createdAtTitle =
      driverDetailsPlanByRailyRouteListingHelper.getCreatedAtTitle(
        routeWaypoint.createdAt
      );

    const createdByNameLabel =
      driverDetailsPlanByRailyRouteListingHelper.getCreatedByNameLabel(
        routeWaypoint.createdByName
      );

    const createdByNameTitle =
      driverDetailsPlanByRailyRouteListingHelper.getCreatedByNameTitle(
        routeWaypoint.createdByName
      );

    const isPreviousRouteWaypointCheckouted =
      props.routeWaypoints[index - 1]?.isCheckouted ?? true;

    const isNextRouteWaypointCheckouted =
      props.routeWaypoints[index + 1]?.isCheckouted ?? true;

    const isAddCheckoutButtonVisible =
      routeWaypoint.isCheckoutManagementEnabled &&
      !routeWaypoint.isCheckouted &&
      isPreviousRouteWaypointCheckouted &&
      userPermissions.hasAccessToAddCheckoutEvent;

    const isEditCheckoutButtonVisible =
      routeWaypoint.isCheckoutManagementEnabled &&
      routeWaypoint.isCheckouted &&
      userPermissions.hasAccessToEditCheckoutEvent;

    const isDeleteCheckoutButtonVisible =
      routeWaypoint.isCheckoutManagementEnabled &&
      routeWaypoint.isCheckouted &&
      !isNextRouteWaypointCheckouted &&
      userPermissions.hasAccessToDeleteCheckoutEvent;

    return {
      id: routeWaypoint.uuid,
      value: {
        client: <div title={clientTitle}>{clientLabel}</div>,
        order: <div title={orderIdTitle}>{orderIdLabel}</div>,
        address: (
          <div title={routeWaypoint.address.displayName}>
            {routeWaypoint.address.displayName}
          </div>
        ),
        plannedDate: <div title={plannedDateTitle}>{plannedDateLabel}</div>,
        estimatedDate: (
          <div title={estimatedDateTitle}>{estimatedDateLabel}</div>
        ),
        checkoutDate: <div title={checkoutDateTitle}>{checkoutDateLabel}</div>,
        plannedHaltingTime: (
          <div title={plannedHaltingTimeTitle}>{plannedHaltingTimeLabel}</div>
        ),
        forcedHaltingTime: (
          <div title={forcedHaltingTimeTitle}>{forcedHaltingTimeLabel}</div>
        ),
        highwayCost: <div title={highwayCostTitle}>{highwayCostLabel}</div>,
        createdAt: <div title={createdAtTitle}>{createdAtLabel}</div>,
        createdByName: <div title={createdByNameTitle}>{createdByNameLabel}</div>,
        actions: (
          <div>
            {isAddCheckoutButtonVisible && (
              <TableButtonComponent
                onClick={() =>
                  props.onAddCheckoutButtonClick(routeWaypoint.uuid)
                }
                title={translations.addCheckoutButtonTitle}
                type="success"
                icon={faCheck}
              />
            )}
            {isEditCheckoutButtonVisible && (
              <TableButtonComponent
                onClick={() =>
                  props.onEditCheckoutButtonClick(
                    routeWaypoint.uuid,
                    routeWaypoint.checkoutEventUuid!
                  )
                }
                title={translations.editCheckoutButtonTitle}
                type="primary"
                icon={faEdit}
                classNames={{ root: "mb-1" }}
              />
            )}
            {isDeleteCheckoutButtonVisible && (
              <TableDeleteButtonComponent
                title={translations.deleteCheckoutButtonTitle}
                onClick={() =>
                  props.onDeleteCheckoutButtonClick(
                    routeWaypoint.uuid,
                    routeWaypoint.checkoutEventUuid
                  )
                }
              />
            )}
          </div>
        ),
      },
    };
  };

  const rows = props.routeWaypoints.map(createTableRow);

  return <TableComponent columns={columns} rows={rows} />;
};

export default DriverDetailsPlanByRailyRouteListingComponent;
