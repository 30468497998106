import { FC, useEffect, useMemo, useState } from "react";
import billingDataRouteRequestsFactory from "./factory/billing-data-route-requests.factory";
import billingMapRoutesFactory from "./factory/billing-map-routes.factory";
import BillingsNode from "../../common/types/billings-node";
import billingRecalculateRequestFactory from "./factory/billing-recalculate-request.factory";
import billingDataFactory from "./factory/billing-data.factory";
import BillingDetailsComponent from "./billing-details/billing-details.component";
import ContractDetailsComponent from "./contract-details/contract-details.component";
import billingSaveRequestFactory from "./factory/billing-save-request.factory";
import BonusType from "./types/bonus-type";
import PenaltyType from "./types/penalty-type";
import RelatedBillingsTaxiDriverComponent from "../common/related-billings/cargo-taxi/related-billings-cargo-taxi.component";
import ButtonComponent from "../../../../common/components/button/button.component";
import CardComponent from "../../../../common/components/card/card.component";
import Column from "../../../../common/components/grid/column";
import Row from "../../../../common/components/grid/row";
import MapComponent from "../../../../common/components/map/map.component";
import MapMarker from "../../../../common/components/map/types/map-marker";
import MapRoute from "../../../../common/components/map/types/map-route";
import SearchRoadRoutingResponse from "../../../../common/utils/search-road-route/search-road-routing.response";
import BillingFormData, { BillingSummaryData } from "./types/billing-form.data";
import billingsTranslationsHelper from "../../../../languages/billings-translations.helper";
import billingTaxiDriverApiService from "./api/billings-taxi-driver-api.service";
import billingsApiService from "./api/billing-api.service";
import BillingDataResponse from "./api/billing-data.response";
import BillingGpsResponse from "./api/billing-gps-data.response";
import BillingSaveResponse from "./api/billing-save.response";
import BillingTaxiDriverEditRouteParams from "../../common/routes/types/billing-taxi-driver-edit-route-params";
import { useNavigate, useParams } from "react-router-dom";
import formValidationService from "../../../../common/utils/validation/form-validation.service";
import notificationService from "../../../../common/utils/notification/notification.service";
import billingRoutesHelper from "../../common/routes/billing-routes.helper";
import { useAppContext } from "../../../../context/app.context";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import billingBreadcrumbsHelper from "../../common/breadcrumbs/billings-breadcrumbs.helper";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import BillingsSummaryConfirmationComponent from "./billings-summary-confirmation.component";
import RelatedBillingsTaxiTaxiComponent from "../common/related-billings/taxi-taxi/related-billings-taxi-taxi.component";
import billingRouteDetailsHelper from "../../common/route-details/common/billings-route-details.helper";
import { NodeValidationResult } from "../../common/route-details/types/billings-route-details-form-data-validation-result";
import BillingFormDataValidationResult from "./types/billing-form-data-validation-result";
import billingRouteDetailsDataValidationService from "../../common/route-details/common/billing-route-details-form-data-validation.service";
import BillingsRouteDetailsFormComponent from "../../common/route-details/billings-route-details-form.component";
import MessengerComponent from "../../../../common/components/messenger/messenger.component";
import billingsTaxiDriverHelper from "./billings-taxi-driver.helper";
import billingsMapMarkersFactory from "../../common/map-markers/billings-map-markers.factory";
import BillingRouteDetailsComponent from "./billing-route-details/billing-route-details.component";
import TabsComponent from "../../../../common/components/tabs/tabs.component";
import TabsData from "../../../../common/components/tabs/common/types/tabs-data";
import OrderDetailsHistoryComponent from "../../../order/details/history/order-details-history.component";
import ContentWrapper from "../../../../common/components/content-wrapper/content-wrapper.component";
import { PlanEntryCargoOrder } from "./api/types/plan-entry";
import BillingsTaxiDriverContributionBonus, {
  BillingsTaxiDriverContributionBonusFavorableDistance,
  BillingsTaxiDriverContributionBonusOther,
  BillingsTaxiDriverContributionBonusType,
} from "../common/contributions/bonus/types/billings-taxi-driver-contributions-bonus";
import BillingsTaxiDriverContributionPenalty, {
  BillingsTaxiDriverContributionPenaltyType,
} from "../common/contributions/penalty/types/billings-taxi-driver-contributions-penalty";
import BillingsTaxiDriverContributionContractCorrection, {
  BillingsTaxiDriverContributionBaseAmountExternalTaxi,
  BillingsTaxiDriverContributionContractCorrectionRate,
  BillingsTaxiDriverContributionContractCorrectionType,
} from "../common/contributions/contract-correction/types/billings-taxi-driver-contributions-contract-correction";
import BillingsToolsComponent from "../../common/tools/billings-tools.component";
import BillingContributionsValidationResult from "./types/billing-contributions-validation-result";
import BillingContributionType from "./types/billing-contribution-type";
import billingsTaxiDriverContributionsValidationService from "../common/contributions/billings-taxi-driver-contributions-bonus-validation.service";
import useApiCall from "../../common/hooks/use-api-call";

type BillingsTaxiDriverAddProps = { asCardComponent?: boolean };

const BillingsTaxiDriverEditComponent: FC<BillingsTaxiDriverAddProps> = (
  props
) => {
  const { billingUuid } = useParams<BillingTaxiDriverEditRouteParams>();

  const { setBreadcrumbs, selectedAppLanguage } = useAppContext();

  const [routeId, setRouteId] = useState("");

  const [isBillingSavePending, setIsBillingSavePending] = useState(false);

  const [billingData, setBillingData] = useState<BillingFormData>();
  const [billingFormData, setBillingFormData] = useState<BillingFormData>();
  const [billingSummaryData, setBillingSummaryData] = useState<BillingSummaryData>();

  const [mapMarkers, setMapMarkers] = useState<MapMarker[]>();
  const [plannedMapRoute, setPlannedMapRoute] = useState<MapRoute | null>(null);
  const [completedMapRoute, setCompletedMapRoute] = useState<MapRoute | null>(
    null
  );

  const [contributionsValidationResults, setContributionsValidationResults] =
    useState<BillingContributionsValidationResult>({
      bonus: [],
      penalty: [],
      contractCorrection: [],
    });

  const [activeTab, setActiveTab] = useState(0);
  const [selectedCargoOrderHumanId, setSelectedCargoOrderHumanId] = useState(0);

  const [isSummaryConfirmationVisible, setIsSummaryConfirmationVisible] =
    useState(false);

  const [planEntryUuid, setPlanEntryUuid] = useState("");
  const [shouldShowOrderHistory, setShouldShowOrderHistory] = useState(false);

  const [shouldRetrieveRouteData, setShouldRetrieveRouteData] = useState(false);

  const [isBillingDataLoading, setIsBillingDataLoading] = useState(false);
  const [formValidationResults, setFormValidationResults] =
    useState<BillingFormDataValidationResult>({
      discountValue: formValidationService.defaultValidationResult,
      nodeValidations: [],
      penaltyValue: formValidationService.defaultValidationResult,
    });

  useEffect(() => {
    setIsBillingDataLoading(true);
    billingTaxiDriverApiService
      .fetchBillingData(billingUuid!)
      .then(handleBillingDataResponse)
      .finally(() => {
        setIsBillingDataLoading(false);
      });
  }, [billingUuid]);

  const navigate = useNavigate();

  const documentTitle = appTranslationsHelper
    .getDocumentTitleTranslations()
    .billingsTaxiWithDriverEdit.replace("#{routeId}", routeId);

  useDocumentTitle(documentTitle);

  const onCargoOrderSelectButtonClick = (
    cargoOrderUuid: PlanEntryCargoOrder["id"],
    cargoOrderHumanId: PlanEntryCargoOrder["human_id"]
  ) => {
    setPlanEntryUuid(cargoOrderUuid);
    setSelectedCargoOrderHumanId(cargoOrderHumanId);
  };

  const hasBaseAmountExternalTaxi = useMemo(() => {
    if (!billingFormData) {
      return;
    }

    return billingFormData.billingContributions.contractCorrection.some(
      (contribution) =>
        contribution.type ===
        BillingsTaxiDriverContributionContractCorrectionType.BASE_AMOUNT_EXTERNAL_TAXI
    );
  }, [billingFormData]);

  useEffect(() => {
    const breadcrumbs =
      billingBreadcrumbsHelper.getTaxiWithDriverEditBreadcrumbs({
        billingUuid: billingUuid!,
        routeId,
      });

    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage, routeId]);

  useEffect(() => {
    if (!planEntryUuid) {
      return;
    }

    billingsApiService.fetchGpsData(planEntryUuid).then(handleGpsDataResponse);
  }, [planEntryUuid]);

  const onModalClose = () => {
    setIsSummaryConfirmationVisible(false);
  };

  const openModal = () => {
    setIsSummaryConfirmationVisible(true);
  };

  useEffect(() => {
    if (!billingData || !shouldRetrieveRouteData) {
      return;
    }

    const mapMarkers = billingsMapMarkersFactory.createMapMarkers(
      billingData.billingNodes
    );

    const routeRequests =
      billingDataRouteRequestsFactory.createBillingDataRouteRequests(
        billingData.billingNodes
      );

    const fetchPromises: Promise<SearchRoadRoutingResponse>[] = [];

    routeRequests.forEach((routeRequest) => {
      const fetch = billingTaxiDriverApiService.fetchRoute(routeRequest);

      fetchPromises.push(fetch);
    });

    Promise.all(fetchPromises).then((responses) => {
      const mapRouteWaypointGroups: MapRoute["waypoints"][] = [];

      responses.forEach((response, index) => {
        const mapRoute = response.routes[0]
          ? billingMapRoutesFactory.createMapRoute(
              response.routes[0].geometry.coordinates
            )
          : null;

        if (mapRoute?.waypoints) {
          mapRouteWaypointGroups.push(mapRoute.waypoints);
        }
      });

      const newMapRouteWaypoints: MapRoute["waypoints"] = [];

      mapRouteWaypointGroups.forEach((waypoint) => {
        newMapRouteWaypoints.push(...waypoint);
      });

      const newMapRoute: MapRoute = {
        waypoints: newMapRouteWaypoints,
        options: { color: "red" },
      };

      setPlannedMapRoute(newMapRoute);
      setMapMarkers(mapMarkers);
    });
  }, [shouldRetrieveRouteData]);

  const recalculateBillingFunction = (formData: BillingFormData) => {
    const recalculateRequest =
      billingRecalculateRequestFactory.createRecalculateRequest(formData, billingSummaryData!);

    billingTaxiDriverApiService
      .recalculateBilling(planEntryUuid, recalculateRequest)
      .then(handleRecalculateResponse);
  };

  const recalculateBilling = useApiCall(recalculateBillingFunction, 50);
  const deleteContributionRecalculateBilling = useApiCall(
    recalculateBillingFunction,
    250
  );

  const handleBillingDataResponse = (response: BillingDataResponse) => {
    if (response.status === 200) {
      onBillingDataFetchSuccess(response);
    }
  };

  const handleGpsDataResponse = (response: BillingGpsResponse) => {
    if (response.status === 200) {
      onGpsDataFetchSuccess(response);
    }
  };

  const handleRecalculateResponse = (response: BillingDataResponse) => {
    if (response.status === 200) {
      onRecalculateSuccess(response);
    }
  };

  const navigateToListing = () => {
    navigate(billingRoutesHelper.getTaxiRouteListingRoute());
  };

  const handleBillingSaveResponse = (response: BillingSaveResponse) => {
    if (response.status === 200) {
      notificationService.success(translations.successNotificationText);
      navigateToListing();

      return;
    }
    notificationService.error(translations.failureNotificationText);
  };

  const onBillingDataFetchSuccess = (response: BillingDataResponse) => {
    const billingData = billingDataFactory.createBillingData(response.data);
    const billingSummaryData = billingDataFactory.createBillingSummaryData(response.data);

    setBillingData(billingData);
    setBillingFormData(billingData);
    setBillingSummaryData(billingSummaryData);
    setPlanEntryUuid(response.data.plan_entry.id);
    setRouteId(String(response.data.plan_entry.human_id));
    setShouldRetrieveRouteData(true);

    setFormValidationResults((current) => ({
      discountValue: current.discountValue,
      nodeValidations: billingData.billingNodes.map((x) => {
        return {
          distance: formValidationService.defaultValidationResult,
          highwayCharge: formValidationService.defaultValidationResult,
          haltingTime: formValidationService.defaultValidationResult,
          position: x.position,
        };
      }),
      penaltyValue: current.penaltyValue,
    }));

    const contributionsValidationResult: BillingContributionsValidationResult =
      {
        bonus: billingData.billingContributions.bonus.map(
          (contribution, index) => ({
            position: index,
            type: formValidationService.defaultValidationResult,
            distance: formValidationService.defaultValidationResult,
            rate: formValidationService.defaultValidationResult,
            amount: formValidationService.defaultValidationResult,
            comment: formValidationService.defaultValidationResult,
          })
        ),
        penalty: billingData.billingContributions.penalty.map(
          (contribution, index) => ({
            position: index,
            type: formValidationService.defaultValidationResult,
            amount: formValidationService.defaultValidationResult,
            comment: formValidationService.defaultValidationResult,
          })
        ),
        contractCorrection:
          billingData.billingContributions.contractCorrection.map(
            (contribution, index) => ({
              position: index,
              type: formValidationService.defaultValidationResult,
              rate: formValidationService.defaultValidationResult,
              amount: formValidationService.defaultValidationResult,
              distance: formValidationService.defaultValidationResult,
            })
          ),
      };

    setContributionsValidationResults(contributionsValidationResult);
  };

  const onGpsDataFetchSuccess = (response: BillingGpsResponse) => {
    const gpsData: MapRoute = {
      waypoints: response.data.map((x) => {
        return { latitude: x.lat, longitude: x.lon };
      }),
      options: { color: "blue" },
    };

    setCompletedMapRoute(gpsData);
  };

  const onBillingDataSave = () => {
    if (!billingFormData || !billingSummaryData) {
      return;
    }

    const areNodesValid =
      billingFormData?.billingNodes
        .map((node) => {
          return billingRouteDetailsHelper.haltingTimeValidation(
            formValidationResults,
            node.haltingTime,
            node.position
          );
        })
        .every((x) => x) ?? false;

    const areContributionsValid = validateContributions();

    const isFormValid =
      areNodesValid && areContributionsValid && validatePenalty();

    if (!isFormValid) {
      onModalClose();

      notificationService.error(translations.failureValidationNotificationText);
      return;
    }

    if (billingFormData && billingSummaryData) {
      setIsBillingSavePending(true);
      const data = billingSaveRequestFactory.createSaveRequest(billingFormData, billingSummaryData);

      billingTaxiDriverApiService
        .saveBillingData(billingUuid!, data)
        .then(handleBillingSaveResponse)
        .finally(() => setIsBillingSavePending(false));
    }
  };

  const onPenaltyBlur = () => {
    const shouldRecalculate = validatePenalty();

    if (shouldRecalculate) {
      recalculateBilling(billingFormData!);
    }
  };

  const onCopyFromPlannedDistance = () => {
    const newBillingNodes = billingFormData?.billingNodes.map((node) => {
      node.distance = node.plannedDistance;

      return node;
    });

    const newBillingFormData: BillingFormData = {
      ...billingFormData!,
      billingNodes: newBillingNodes!,
    };

    setBillingFormData(newBillingFormData);

    const result = billingRouteDetailsHelper.validateAllFieldsByType(
      formValidationResults,
      newBillingFormData.billingNodes,
      "distance"
    );

    nodeValidationResults(result.nodeResult!);

    if (result.isAllFormValid) recalculateBilling(newBillingFormData);
  };

  const validatePenalty = () => {
    const maximumPenaltyValue = billingSummaryData?.allContributionsAmount ?? 0;

    const validationResult =
      billingRouteDetailsDataValidationService.validatePenalty(
        billingSummaryData?.sumOfPenalties ?? 0,
        maximumPenaltyValue
      );

    setFormValidationResults((curr) => ({
      ...curr,
      penaltyValue: validationResult,
    }));

    return validationResult.isValid;
  };

  const onBonusBlur = () => {
    const shouldRecalculate = validateBonus();

    if (shouldRecalculate) recalculateBilling(billingFormData!);
  };

  const validateBonus = () => {
    const validationResult =
      billingRouteDetailsDataValidationService.validateBonus(
        billingSummaryData?.sumOfBonuses!
      );

    setFormValidationResults((curr) => ({
      ...curr,
      discountValue: validationResult,
    }));

    return validationResult.isValid;
  };

  const validationOnBlur = (position: number, fieldName: string) => {
    type Node = keyof typeof node;

    const node = billingFormData?.billingNodes.find(
      (x) => x.position === position
    );

    const fieldToValidate = node && node[fieldName as Node];

    const results = billingRouteDetailsHelper.validationOnBlur(
      fieldToValidate,
      formValidationResults,
      position,
      fieldName
    );

    nodeValidationResults(results.nodeResult!);

    if (results.isAllFormValid) recalculateBilling(billingFormData!);
  };

  const nodeValidationResults = (node: NodeValidationResult[]) => {
    setFormValidationResults((curr) => ({
      ...curr,
      nodeValidations: node,
    }));
  };

  const onBonusChanged = (bonusValue: number) => {
    setBillingFormData((current) => ({ ...current!, bonus: bonusValue }));
  };

  const onBonusTypeChanged = (bonusType: BonusType) => {
    setBillingFormData((current) => ({
      ...current!,
      bonusType: bonusType,
    }));
  };

  const onPenaltyChanged = (penaltyValue: number) => {
    setBillingFormData((current) => ({ ...current!, penalty: penaltyValue }));
  };

  const onPenaltyTypeChanged = (penaltyType: PenaltyType) => {
    setBillingFormData((current) => ({
      ...current!,
      penaltyType: penaltyType,
    }));
  };

  const onBillingNodeChanged = (node: BillingsNode) => {
    const nodes = billingFormData?.billingNodes.filter(
      (x) => x.position !== node.position
    )!;

    nodes.push(node);

    const sortedArray = nodes.sort((a, b) =>
      a.position < b.position ? -1 : 1
    );

    setBillingFormData((current) => ({
      ...current!,
      billingNodes: sortedArray,
    }));
  };

  const onInputBlur = () => {
    recalculateBilling(billingFormData!);
  };


  const onRecalculateSuccess = (response: BillingDataResponse) => {
    const billingData = billingDataFactory.createBillingData(response.data);
    const billingSummaryData = billingDataFactory.createBillingSummaryData(response.data);
    
    setBillingData(billingData);
    setBillingFormData(billingData);
    setBillingSummaryData(billingSummaryData);
  };

  const mapRoutes: MapRoute[] = useMemo(() => {
    const finalMapRoutes: MapRoute[] = [];
    if (plannedMapRoute) {
      finalMapRoutes.push(plannedMapRoute);
    }
    if (completedMapRoute) {
      finalMapRoutes.push(completedMapRoute);
    }
    return finalMapRoutes;
  }, [plannedMapRoute, completedMapRoute]);

  const translations =
    billingsTranslationsHelper.getTaxiDriverEditBillingsTranslations();

  const messengerChannelsAvailability =
    billingsTaxiDriverHelper.getMessengerChannelAvailability();

  const validateContributionType = (
    type:
      | BillingsTaxiDriverContributionBonusType
      | BillingsTaxiDriverContributionPenaltyType
      | BillingsTaxiDriverContributionContractCorrectionType
      | null,
    position: number,
    contributionType: BillingContributionType
  ) => {
    const validationResult =
      billingsTaxiDriverContributionsValidationService.validateType(type);

    const validationResults: BillingContributionsValidationResult = {
      ...contributionsValidationResults,
      ...(contributionType === BillingContributionType.BONUS && {
        bonus: contributionsValidationResults.bonus.map((validation) =>
          validation.position === position
            ? {
                ...validation,
                type: validationResult,
              }
            : validation
        ),
      }),
      ...(contributionType === BillingContributionType.PENALTY && {
        penalty: contributionsValidationResults.penalty.map((validation) =>
          validation.position === position
            ? {
                ...validation,
                type: validationResult,
              }
            : validation
        ),
      }),
      ...(contributionType === BillingContributionType.CONTRACT_CORRECTION && {
        contractCorrection:
          contributionsValidationResults.contractCorrection.map((validation) =>
            validation.position === position
              ? {
                  ...validation,
                  type: validationResult,
                }
              : validation
          ),
      }),
    };

    setContributionsValidationResults(validationResults);

    return validationResult.isValid;
  };

  const validateContributionDistance = (
    distance: number | null,
    position: number,
    contributionType: BillingContributionType
  ) => {
    const validationResult =
      billingsTaxiDriverContributionsValidationService.validateDistance(
        distance
      );

    const validationResults: BillingContributionsValidationResult = {
      ...contributionsValidationResults,
      ...(contributionType === BillingContributionType.BONUS && {
        bonus: contributionsValidationResults.bonus.map((validation) =>
          validation.position === position
            ? {
                ...validation,
                distance: validationResult,
              }
            : validation
        ),
      }),
      ...(contributionType === BillingContributionType.CONTRACT_CORRECTION && {
        contractCorrection:
          contributionsValidationResults.contractCorrection.map((validation) =>
            validation.position === position
              ? {
                  ...validation,
                  distance: validationResult,
                }
              : validation
          ),
      }),
    };

    setContributionsValidationResults(validationResults);

    return validationResult.isValid;
  };

  const validateContributionRate = (
    rate: number | null,
    position: number,
    contributionType: BillingContributionType
  ) => {
    const validationResult =
      billingsTaxiDriverContributionsValidationService.validateRate(rate);

    const validationResults: BillingContributionsValidationResult = {
      ...contributionsValidationResults,
      ...(contributionType === BillingContributionType.BONUS && {
        bonus: contributionsValidationResults.bonus.map((validation) =>
          validation.position === position
            ? {
                ...validation,
                rate: validationResult,
              }
            : validation
        ),
      }),
      ...(contributionType === BillingContributionType.CONTRACT_CORRECTION && {
        contractCorrection:
          contributionsValidationResults.contractCorrection.map((validation) =>
            validation.position === position
              ? {
                  ...validation,
                  rate: validationResult,
                }
              : validation
          ),
      }),
    };

    setContributionsValidationResults(validationResults);

    return validationResult.isValid;
  };

  const validateContributionAmount = (
    amount: number | null,
    position: number,
    contributionType: BillingContributionType
  ) => {
    const validationResult =
      billingsTaxiDriverContributionsValidationService.validateAmount(amount);

    const validationResults: BillingContributionsValidationResult = {
      ...contributionsValidationResults,
      ...(contributionType === BillingContributionType.BONUS && {
        bonus: contributionsValidationResults.bonus.map((validation) =>
          validation.position === position
            ? {
                ...validation,
                amount: validationResult,
              }
            : validation
        ),
      }),
      ...(contributionType === BillingContributionType.PENALTY && {
        penalty: contributionsValidationResults.penalty.map((validation) =>
          validation.position === position
            ? {
                ...validation,
                amount: validationResult,
              }
            : validation
        ),
      }),
      ...(contributionType === BillingContributionType.CONTRACT_CORRECTION && {
        contractCorrection:
          contributionsValidationResults.contractCorrection.map((validation) =>
            validation.position === position
              ? {
                  ...validation,
                  amount: validationResult,
                }
              : validation
          ),
      }),
    };

    setContributionsValidationResults(validationResults);

    return validationResult.isValid;
  };

  const validateContributionComment = (
    comment: string | null,
    position: number,
    contributionType: BillingContributionType
  ) => {
    const validationResult =
      billingsTaxiDriverContributionsValidationService.validateComment(
        comment ?? ""
      );

    const validationResults: BillingContributionsValidationResult = {
      ...contributionsValidationResults,
      ...(contributionType === BillingContributionType.BONUS && {
        bonus: contributionsValidationResults.bonus.map((validation) =>
          validation.position === position
            ? {
                ...validation,
                comment: validationResult,
              }
            : validation
        ),
      }),
      ...(contributionType === BillingContributionType.PENALTY && {
        penalty: contributionsValidationResults.penalty.map(
          (validation, index) =>
            validation.position === position
              ? {
                  ...validation,
                  comment: validationResult,
                }
              : validation
        ),
      }),
    };

    setContributionsValidationResults(validationResults);

    return validationResult.isValid;
  };

  const onContributionTypeChange = (
    position: number,
    type:
      | BillingsTaxiDriverContributionBonusType
      | BillingsTaxiDriverContributionPenaltyType
      | BillingsTaxiDriverContributionContractCorrectionType,
    contributionType: BillingContributionType
  ) => {
    let formData: BillingFormData = {
      ...billingFormData!,
      billingContributions: {
        ...billingFormData!.billingContributions,
        ...(contributionType === BillingContributionType.BONUS && {
          bonus: billingFormData!.billingContributions.bonus.map(
            (contribution, index) =>
              index === position
                ? type ===
                  BillingsTaxiDriverContributionBonusType.BONUS_FAVORABLE_DISTANCE
                  ? ({
                      ...contribution,
                      type,
                      rate: billingSummaryData?.contractDetails?.distanceRate,
                    } as BillingsTaxiDriverContributionBonusFavorableDistance)
                  : ({
                      ...contribution,
                      type,
                    } as BillingsTaxiDriverContributionBonusOther)
                : contribution
          ),
        }),
        ...(contributionType === BillingContributionType.PENALTY && {
          penalty: billingFormData!.billingContributions.penalty.map(
            (contribution, index) =>
              index === position
                ? ({
                    ...contribution,
                    type,
                  } as BillingsTaxiDriverContributionPenalty)
                : contribution
          ),
        }),
        ...(contributionType ===
          BillingContributionType.CONTRACT_CORRECTION && {
          contractCorrection:
            billingFormData!.billingContributions.contractCorrection.map(
              (contribution, index) =>
                index === position
                  ? ({
                      ...contribution,
                      type,
                    } as BillingsTaxiDriverContributionContractCorrection)
                  : contribution
            ),
        }),
      },
    };

    if (contributionType === BillingContributionType.CONTRACT_CORRECTION) {
      if (
        formData.billingContributions.contractCorrection[position].type ===
        BillingsTaxiDriverContributionContractCorrectionType.BASE_AMOUNT_EXTERNAL_TAXI
      ) {
        formData = {
          ...formData,
          billingContributions: {
            bonus: [],
            penalty: [],
            contractCorrection:
              formData.billingContributions.contractCorrection.filter(
                (contribution) =>
                  contribution.type ===
                  BillingsTaxiDriverContributionContractCorrectionType.BASE_AMOUNT_EXTERNAL_TAXI
              ),
          },
        };
        recalculateBilling(formData);
      }
    }

    setBillingFormData(formData);
    recalculateBilling(formData);
  };

  const onContributionDistanceChange = (
    position: number,
    distance: number | null,
    contributionType: BillingContributionType
  ) => {
    const formData: BillingFormData = {
      ...billingFormData!,
      billingContributions: {
        ...billingFormData!.billingContributions,
        ...(contributionType === BillingContributionType.BONUS && {
          bonus: billingFormData!.billingContributions.bonus.map(
            (contribution, index) =>
              index === position
                ? ({
                    ...contribution,
                    distance,
                  } as BillingsTaxiDriverContributionBonus)
                : contribution
          ),
        }),
        ...(contributionType ===
          BillingContributionType.CONTRACT_CORRECTION && {
          contractCorrection:
            billingFormData!.billingContributions.contractCorrection.map(
              (contribution, index) =>
                index === position
                  ? ({
                      ...contribution,
                      distance,
                    } as BillingsTaxiDriverContributionContractCorrection)
                  : contribution
            ),
        }),
      },
    };

    setBillingFormData(formData);
  };

  const onContributionRateChange = (
    position: number,
    rate: number | null,
    contributionType: BillingContributionType
  ) => {
    const formData: BillingFormData = {
      ...billingFormData!,
      billingContributions: {
        ...billingFormData!.billingContributions,
        ...(contributionType === BillingContributionType.BONUS && {
          bonus: billingFormData!.billingContributions.bonus.map(
            (contribution, index) =>
              index === position
                ? ({
                    ...contribution,
                    rate,
                  } as BillingsTaxiDriverContributionBonus)
                : contribution
          ),
        }),
        ...(contributionType ===
          BillingContributionType.CONTRACT_CORRECTION && {
          contractCorrection:
            billingFormData!.billingContributions.contractCorrection.map(
              (contribution, index) =>
                index === position
                  ? ({
                      ...contribution,
                      rate,
                    } as BillingsTaxiDriverContributionContractCorrection)
                  : contribution
            ),
        }),
      },
    };

    setBillingFormData(formData);
  };

  const onContributionAmountChange = (
    position: number,
    amount: number | null,
    contributionType: BillingContributionType
  ) => {
    const formData: BillingFormData = {
      ...billingFormData!,
      billingContributions: {
        ...billingFormData!.billingContributions,
        ...(contributionType === BillingContributionType.BONUS && {
          bonus: billingFormData!.billingContributions.bonus.map(
            (contribution, index) =>
              index === position
                ? ({
                    ...contribution,
                    amount,
                  } as BillingsTaxiDriverContributionBonus)
                : contribution
          ),
        }),
        ...(contributionType === BillingContributionType.PENALTY && {
          penalty: billingFormData!.billingContributions.penalty.map(
            (contribution, index) =>
              index === position
                ? ({
                    ...contribution,
                    amount,
                  } as BillingsTaxiDriverContributionPenalty)
                : contribution
          ),
        }),
        ...(contributionType ===
          BillingContributionType.CONTRACT_CORRECTION && {
          contractCorrection:
            billingFormData!.billingContributions.contractCorrection.map(
              (contribution, index) =>
                index === position
                  ? ({
                      ...contribution,
                      amount,
                    } as BillingsTaxiDriverContributionContractCorrection)
                  : contribution
            ),
        }),
      },
    };

    setBillingFormData(formData);
  };

  const onContributionCommentChange = (
    position: number,
    comment: string,
    contributionType: BillingContributionType
  ) => {
    const formData: BillingFormData = {
      ...billingFormData!,
      billingContributions: {
        ...billingFormData!.billingContributions,
        ...(contributionType === BillingContributionType.BONUS && {
          bonus: billingFormData!.billingContributions.bonus.map(
            (contribution, index) =>
              index === position
                ? ({
                    ...contribution,
                    comment,
                  } as BillingsTaxiDriverContributionBonus)
                : contribution
          ),
        }),
        ...(contributionType === BillingContributionType.PENALTY && {
          penalty: billingFormData!.billingContributions.penalty.map(
            (contribution, index) =>
              index === position
                ? ({
                    ...contribution,
                    comment,
                  } as BillingsTaxiDriverContributionPenalty)
                : contribution
          ),
        }),
      },
    };

    setBillingFormData(formData);
  };

  const onContributionTypeBlur = (
    position: number,
    contributionType: BillingContributionType
  ) => {
    const contributions = billingsTaxiDriverHelper.getContributionsByType(
      billingFormData!.billingContributions,
      contributionType
    );

    const type = contributions[position].type;

    if (!type) {
      deleteContribution(position, contributionType, false);
    } else {
      validateContributionType(type, position, contributionType);
    }
  };

  const onContributionDistanceBlur = (
    position: number,
    contributionType: BillingContributionType
  ) => {
    const distance =
      contributionType === BillingContributionType.BONUS
        ? (
            billingFormData!.billingContributions.bonus[
              position
            ] as BillingsTaxiDriverContributionBonusFavorableDistance
          ).distance
        : (
            billingFormData!.billingContributions.contractCorrection[
              position
            ] as BillingsTaxiDriverContributionBaseAmountExternalTaxi
          ).distance;

    const isDistanceValid = validateContributionDistance(
      distance,
      position,
      contributionType
    );

    if (isDistanceValid) {
      recalculateBilling(billingFormData!);
    }
  };

  const onContributionRateBlur = (
    position: number,
    contributionType: BillingContributionType
  ) => {
    const rate =
      contributionType === BillingContributionType.BONUS
        ? (
            billingFormData!.billingContributions.bonus[
              position
            ] as BillingsTaxiDriverContributionBonusFavorableDistance
          ).rate
        : (
            billingFormData!.billingContributions.contractCorrection[
              position
            ] as BillingsTaxiDriverContributionContractCorrectionRate
          ).rate;

    const isRateValid = validateContributionRate(
      rate,
      position,
      contributionType
    );

    if (isRateValid) {
      recalculateBilling(billingFormData!);
    }
  };

  const onContributionAmountBlur = (
    position: number,
    contributionType: BillingContributionType
  ) => {
    const contributions = billingsTaxiDriverHelper.getContributionsByType(
      billingFormData!.billingContributions,
      contributionType
    );

    const amount = (
      contributions[position] as
        | BillingsTaxiDriverContributionBonusOther
        | BillingsTaxiDriverContributionPenalty
        | BillingsTaxiDriverContributionBaseAmountExternalTaxi
    ).amount;

    const isAmountValid = validateContributionAmount(
      amount,
      position,
      contributionType
    );

    if (isAmountValid) {
      recalculateBilling(billingFormData!);
    }
  };

  const onContributionCommentBlur = (
    position: number,
    contributionType: BillingContributionType
  ) => {
    const contributions = billingsTaxiDriverHelper.getContributionsByType(
      billingFormData!.billingContributions,
      contributionType
    );

    const comment = (
      contributions[position] as
        | BillingsTaxiDriverContributionPenalty
        | BillingsTaxiDriverContributionBonus
    ).comment;

    const isCommentValid = validateContributionComment(
      comment,
      position,
      contributionType
    );

    if (isCommentValid) {
      recalculateBilling(billingFormData!);
    }
  };

  const validateContributions = () => {
    const contributionsValidation: BillingContributionsValidationResult = {
      bonus: billingFormData!.billingContributions.bonus.map(
        (contribution, index) => {
          switch (contribution.type) {
            case BillingsTaxiDriverContributionBonusType.BONUS_FAVORABLE_DISTANCE: {
              return {
                position: index,
                type: billingsTaxiDriverContributionsValidationService.validateType(
                  contribution.type
                ),
                distance:
                  billingsTaxiDriverContributionsValidationService.validateDistance(
                    contribution.distance
                  ),
                rate: billingsTaxiDriverContributionsValidationService.validateRate(
                  contribution.rate
                ),
                amount: formValidationService.defaultValidationResult,
                comment:
                  billingsTaxiDriverContributionsValidationService.validateComment(
                    contribution.comment
                  ),
              };
            }
            case BillingsTaxiDriverContributionBonusType.BONUS_OTHER: {
              return {
                position: index,
                type: billingsTaxiDriverContributionsValidationService.validateType(
                  contribution.type
                ),
                distance: formValidationService.defaultValidationResult,
                rate: formValidationService.defaultValidationResult,
                amount:
                  billingsTaxiDriverContributionsValidationService.validateAmount(
                    contribution.amount
                  ),
                comment:
                  billingsTaxiDriverContributionsValidationService.validateComment(
                    contribution.comment
                  ),
              };
            }
            default: {
              return {
                position: index,
                type: formValidationService.defaultValidationResult,
                distance: formValidationService.defaultValidationResult,
                rate: formValidationService.defaultValidationResult,
                amount: formValidationService.defaultValidationResult,
                comment: formValidationService.defaultValidationResult,
              };
            }
          }
        }
      ),
      penalty: billingFormData!.billingContributions.penalty.map(
        (contribution, index) => {
          return {
            position: index,
            type: billingsTaxiDriverContributionsValidationService.validateType(
              contribution.type
            ),
            amount:
              billingsTaxiDriverContributionsValidationService.validateAmount(
                contribution.amount
              ),
            comment:
              billingsTaxiDriverContributionsValidationService.validateComment(
                contribution.comment
              ),
          };
        }
      ),
      contractCorrection:
        billingFormData!.billingContributions.contractCorrection.map(
          (contribution, index) => {
            switch (contribution.type) {
              case BillingsTaxiDriverContributionContractCorrectionType.BASE_AMOUNT_EXTERNAL_TAXI: {
                return {
                  position: index,
                  type: billingsTaxiDriverContributionsValidationService.validateType(
                    contribution.type
                  ),
                  distance:
                    billingsTaxiDriverContributionsValidationService.validateDistance(
                      contribution.distance
                    ),
                  rate: formValidationService.defaultValidationResult,
                  amount:
                    billingsTaxiDriverContributionsValidationService.validateAmount(
                      contribution.amount
                    ),
                  comment: formValidationService.defaultValidationResult,
                };
              }
              case BillingsTaxiDriverContributionContractCorrectionType.CONTRACT_CORRECTION_RATE: {
                return {
                  position: index,
                  type: billingsTaxiDriverContributionsValidationService.validateType(
                    contribution.type
                  ),
                  distance: formValidationService.defaultValidationResult,
                  rate: billingsTaxiDriverContributionsValidationService.validateRate(
                    contribution.rate
                  ),
                  amount: formValidationService.defaultValidationResult,
                  comment: formValidationService.defaultValidationResult,
                };
              }
              default: {
                return {
                  position: index,
                  type: formValidationService.defaultValidationResult,
                  distance: formValidationService.defaultValidationResult,
                  rate: formValidationService.defaultValidationResult,
                  amount: formValidationService.defaultValidationResult,
                  comment: formValidationService.defaultValidationResult,
                };
              }
            }
          }
        ),
    };

    setContributionsValidationResults(contributionsValidation);

    return (
      contributionsValidation.bonus.every(
        (validation) =>
          validation.amount.isValid &&
          validation.comment.isValid &&
          validation.distance.isValid &&
          validation.rate.isValid &&
          validation.type.isValid
      ) &&
      contributionsValidation.penalty.every(
        (validation) =>
          validation.amount.isValid &&
          validation.comment.isValid &&
          validation.type.isValid
      ) &&
      contributionsValidation.contractCorrection.every(
        (validation) =>
          validation.amount.isValid &&
          validation.distance.isValid &&
          validation.type.isValid &&
          validation.rate.isValid
      )
    );
  };

  const deleteContribution = (
    position: number,
    type: BillingContributionType,
    recalculate: boolean = true
  ) => {
    const formData: BillingFormData = {
      ...billingFormData!,
      billingContributions: {
        ...billingFormData!.billingContributions,
        ...(type === BillingContributionType.BONUS && {
          bonus: billingFormData!.billingContributions.bonus.filter(
            (contribution, index) => index !== position
          ),
        }),
        ...(type === BillingContributionType.PENALTY && {
          penalty: billingFormData!.billingContributions.penalty.filter(
            (contribution, index) => index !== position
          ),
        }),
        ...(type === BillingContributionType.CONTRACT_CORRECTION && {
          contractCorrection:
            billingFormData!.billingContributions.contractCorrection.filter(
              (contribution, index) => index !== position
            ),
        }),
      },
    };

    const validationResults: BillingContributionsValidationResult = {
      ...contributionsValidationResults,
      ...(type === BillingContributionType.BONUS && {
        bonus: contributionsValidationResults.bonus
          .filter((validation) => validation.position !== position)
          .map((validation, index) => ({ ...validation, position: index })),
      }),
      ...(type === BillingContributionType.PENALTY && {
        penalty: contributionsValidationResults.penalty
          .filter((validation) => validation.position !== position)
          .map((validation, index) => ({ ...validation, position: index })),
      }),
      ...(type === BillingContributionType.CONTRACT_CORRECTION && {
        contractCorrection: contributionsValidationResults.contractCorrection
          .filter((validation) => validation.position !== position)
          .map((validation, index) => ({ ...validation, position: index })),
      }),
    };

    setBillingFormData(formData);
    setContributionsValidationResults(validationResults);

    if (recalculate) {
      deleteContributionRecalculateBilling(formData);
    }
  };

  const addNewContribution = () => {
    const newContributionType =
      billingsTaxiDriverHelper.getContributionTypeFromTabIndex(activeTab);

    if (!billingSummaryData || !newContributionType) {
      return;
    }

    const newContribution = billingsTaxiDriverHelper.getNewContribution(
      newContributionType,
      billingSummaryData?.distance
    );

    const formData: BillingFormData = {
      ...billingFormData!,
      billingContributions: {
        ...billingFormData!.billingContributions,
        ...(newContributionType === BillingContributionType.BONUS && {
          bonus: [
            ...billingFormData!.billingContributions.bonus,
            newContribution as BillingsTaxiDriverContributionBonus,
          ],
        }),
        ...(newContributionType === BillingContributionType.PENALTY && {
          penalty: [
            ...billingFormData!.billingContributions.penalty,
            newContribution as BillingsTaxiDriverContributionPenalty,
          ],
        }),
        ...(newContributionType ===
          BillingContributionType.CONTRACT_CORRECTION && {
          contractCorrection: [
            ...billingFormData!.billingContributions.contractCorrection,
            newContribution as BillingsTaxiDriverContributionContractCorrection,
          ],
        }),
      },
    };

    const validationResults: BillingContributionsValidationResult = {
      ...contributionsValidationResults,
      ...(newContributionType === BillingContributionType.BONUS && {
        bonus: [
          ...contributionsValidationResults.bonus,
          {
            position: contributionsValidationResults.bonus.length,
            type: formValidationService.defaultValidationResult,
            distance: formValidationService.defaultValidationResult,
            rate: formValidationService.defaultValidationResult,
            amount: formValidationService.defaultValidationResult,
            comment: formValidationService.defaultValidationResult,
          },
        ],
      }),
      ...(newContributionType === BillingContributionType.PENALTY && {
        penalty: [
          ...contributionsValidationResults.penalty,
          {
            position: contributionsValidationResults.penalty.length,
            type: formValidationService.defaultValidationResult,
            amount: formValidationService.defaultValidationResult,
            comment: formValidationService.defaultValidationResult,
          },
        ],
      }),
      ...(newContributionType ===
        BillingContributionType.CONTRACT_CORRECTION && {
        contractCorrection: [
          ...contributionsValidationResults.contractCorrection,
          {
            position: contributionsValidationResults.contractCorrection.length,
            type: formValidationService.defaultValidationResult,
            amount: formValidationService.defaultValidationResult,
            distance: formValidationService.defaultValidationResult,
            rate: formValidationService.defaultValidationResult,
          },
        ],
      }),
    };

    setBillingFormData(formData);
    setContributionsValidationResults(validationResults);
  };

  const tabsData: TabsData = useMemo(() => {

    if (!billingFormData || !billingSummaryData) {
      return [];
    }

    return billingDataFactory.createTabsData(
      billingFormData.billingContributions,
      isBillingDataLoading,
      billingSummaryData,
      deleteContribution,
      contributionsValidationResults,
      onContributionTypeChange,
      onContributionDistanceChange,
      onContributionRateChange,
      onContributionAmountChange,
      onContributionCommentChange,
      onContributionTypeBlur,
      onContributionDistanceBlur,
      onContributionRateBlur,
      onContributionAmountBlur,
      onContributionCommentBlur
    );
  }, [billingFormData, billingSummaryData, contributionsValidationResults, selectedAppLanguage]);

  const tabMaxContributions = useMemo(
    () => billingsTaxiDriverHelper.getMaxContributionsFromTabIndex(activeTab),
    [activeTab]
  );

  const onOrderHistoryButtonClick = () => {
    if (shouldShowOrderHistory) {
      setShouldShowOrderHistory(false);
      return;
    }

    if (!billingSummaryData?.solvedOrderUuids) {
      return;
    }

    if (billingSummaryData?.solvedOrderUuids?.length > 0) {
      const firstOrderUuid = billingSummaryData.solvedOrderUuids[0];
      const firstOrderHumanId = billingSummaryData.solvedOrderHumanIds[0];

      setPlanEntryUuid(firstOrderUuid);
      setSelectedCargoOrderHumanId(firstOrderHumanId);
    }

    setShouldShowOrderHistory(true);
  };

  const billingsTaxiDriverToolsProps = {
    shouldShowOrderHistory,
    onOrderHistoryButtonClick,
  };

  return (
    <>
      <div className="billings">
        <HeadingComponent
          title={translations.header.headingText.replace("#{routeId}", routeId)}
        />
        <Row>
          <Column lg={8}>
            <Row>
              <Column withPaddings>
                <div className="billings_map_wrapper">
                  <MapComponent
                    markers={mapMarkers}
                    autoFit
                    autoFitOnUpdate
                    routes={mapRoutes}
                  />
                  <BillingsToolsComponent {...billingsTaxiDriverToolsProps} />
                </div>
              </Column>
              <Column withPaddings>
                <ContractDetailsComponent
                  isLoading={isBillingDataLoading}
                  contractDetails={billingSummaryData?.contractDetails ?? null}
                />
              </Column>
              <Column withPaddings>
                <Row>
                  <Column lg={12}>
                    <BillingsRouteDetailsFormComponent
                      billingNodes={billingFormData?.billingNodes}
                      formValidationResults={
                        formValidationResults.nodeValidations
                      }
                      onBillingNodeChanged={onBillingNodeChanged}
                      onInputBlur={onInputBlur}
                      onCopyFromPlannedDistance={onCopyFromPlannedDistance}
                      validationOnBlur={validationOnBlur}
                    />
                  </Column>
                </Row>
              </Column>
              <Column withPaddings>
                <BillingRouteDetailsComponent billingSummaryData={billingSummaryData} />
              </Column>
              {!!billingData && (
                <Column withPaddings>
                  <TabsComponent
                    data={tabsData}
                    onActiveTabChange={setActiveTab}
                  />
                  <div className="billings_contribution_add_button">
                    <ButtonComponent
                      onClick={addNewContribution}
                      type="success"
                      title={translations.contributionAddButtonTitle}
                      isDisabled={
                        tabsData[activeTab].totalTableRows >=
                          tabMaxContributions || hasBaseAmountExternalTaxi
                      }
                    >
                      {translations.contributionAddButtonLabel}
                    </ButtonComponent>
                  </div>
                </Column>
              )}
              <Column withPaddings>
                <CardComponent
                  classNames={{ root: "billing_details" }}
                  header={{ title: translations.billingSummary.summaryLabel }}
                >
                  <BillingDetailsComponent billingData={billingFormData} billingSummaryData={billingSummaryData}/>
                </CardComponent>
              </Column>
              <Column>
                <ButtonComponent type="primary" onClick={openModal}>
                  {translations.submitLabel}
                </ButtonComponent>
              </Column>
              <Column withPaddings>
                <RelatedBillingsTaxiDriverComponent
                  planEntryId={planEntryUuid}
                />
              </Column>
              <Column withPaddings>
                <RelatedBillingsTaxiTaxiComponent planEntryId={planEntryUuid} />
              </Column>
            </Row>
          </Column>
          <Column lg={4} withPaddings>
            <CardComponent classNames={{ root: "billings_history_messenger_container", content: "h-100" }}>
              {shouldShowOrderHistory ? (
                <ContentWrapper asCardComponent={props.asCardComponent}>
                  <div className="d-flex mb-2">
                    {billingSummaryData?.solvedOrderUuids.map(
                      (solvedOrderUuid, index) => {
                        const solvedOrderHumanId =
                        billingSummaryData.solvedOrderHumanIds[index];
                        const isSelected = planEntryUuid === solvedOrderUuid;

                        return (
                          <ButtonComponent
                            key={solvedOrderUuid}
                            onClick={() =>
                              onCargoOrderSelectButtonClick(
                                solvedOrderUuid,
                                solvedOrderHumanId
                              )
                            }
                            type={isSelected ? "success" : undefined}
                          >
                            {solvedOrderHumanId}
                          </ButtonComponent>
                        );
                      }
                    )}
                  </div>
                  <div className="billings_history">
                    <OrderDetailsHistoryComponent
                      key={planEntryUuid} 
                      orderUuid={planEntryUuid}
                      refetchFlag={false}
                    />
                  </div>
                </ContentWrapper>
              ) : (
                !!planEntryUuid.length && (
                  <MessengerComponent
                    channelsAvailability={messengerChannelsAvailability}
                    planEntryUuid={planEntryUuid}
                  />
                )
              )}
            </CardComponent>
          </Column>
        </Row>
        <BillingsSummaryConfirmationComponent
          isVisible={isSummaryConfirmationVisible}
          onSubmit={onBillingDataSave}
          onClose={onModalClose}
          billingData={billingFormData!}
          billingSummaryData={billingSummaryData!}
          isLoading={isBillingSavePending}
        />
      </div>
    </>
  );
};

export default BillingsTaxiDriverEditComponent;
