import Joi from "joi";
import BillingsTaxiOrderListingRouteQueryFilterParams from "./types/billings-taxi-order-listing-route-query-filter-params";
import BillingsTaxiOrderListingRouteQueryParams from "./types/billings-taxi-order-listing-route-query-params";
import BillingsTaxiOrderListingSortKey from "./types/billings-taxi-order-listing-sort-key";
import BillingsTaxiOrderListingFilter, {
  BillingsTaxiOrderListingInternalOrderIdFilter,
  BillingsTaxiOrderListingOrderStartDateFilter,
  BillingsTaxiOrderListingBillingStatusFilter,
  BillingsTaxiOrderListingBillingModelFilter,
  BillingsTaxiOrderListingBillingTypeFilter,
  BillingsTaxiOrderListingCargoCompanyFilter,
  BillingsTaxiOrderListingDestinationTaxiFilter,
  BillingsTaxiOrderListingExcludeCargoCompanyFilter,
  BillingsTaxiOrderListingPlanEntryHumanIdsFilter,
} from "./types/billings-taxi-order-listing-filter";
import BillingsTaxiOrderListingFilterType from "./types/billings-taxi-order-listing-filter-type";
import BillingsTaxiOrderListingItemBillingStatus from "./types/billings-taxi-order-listing-item-billing-status";
import BillingModel from "../../../types/billing-model";
import BillingsTaxiOrderListingItemBillingType from "./types/billings-taxi-order-listing-item-billing-type";
import billingsTaxiOrderListingFilterHelper from "./billings-taxi-order-listing-filter.helper";
import { BillingsTaxiOrderListingPassengerFilter } from "./types/billings-taxi-order-listing-filter";

const getFilters = (
  routeQueryFilterParams: BillingsTaxiOrderListingRouteQueryFilterParams
): BillingsTaxiOrderListingFilter[] => {
  if (!validateFilters(routeQueryFilterParams)) {
    return [];
  }

  const filters: BillingsTaxiOrderListingFilter[] = [];

  if (
    routeQueryFilterParams.orderStartDateFrom &&
    routeQueryFilterParams.orderStartDateTo
  ) {
    const startDateFilter: BillingsTaxiOrderListingOrderStartDateFilter = {
      type: BillingsTaxiOrderListingFilterType.ORDER_START_DATE,
      value: {
        from: new Date(routeQueryFilterParams.orderStartDateFrom),
        to: new Date(routeQueryFilterParams.orderStartDateTo),
      },
    };

    filters.push(startDateFilter);
  }

  if (routeQueryFilterParams.internalOrderId) {
    const internalOrderIdFilter: BillingsTaxiOrderListingInternalOrderIdFilter =
      {
        type: BillingsTaxiOrderListingFilterType.INTERNAL_ORDER_ID,
        value: Number(routeQueryFilterParams.internalOrderId),
      };
    filters.push(internalOrderIdFilter);
  }

  if (routeQueryFilterParams.planEntryHumanIds) {
    const planEntryHumanIdsFilter: BillingsTaxiOrderListingPlanEntryHumanIdsFilter =
      {
        type: BillingsTaxiOrderListingFilterType.PLAN_ENTRY_HUMAN_IDS,
        value: Number(routeQueryFilterParams.planEntryHumanIds),
      };
    filters.push(planEntryHumanIdsFilter);
  }

  if (routeQueryFilterParams.billingStatus) {
    const billingStatusFilter: BillingsTaxiOrderListingBillingStatusFilter = {
      type: BillingsTaxiOrderListingFilterType.BILLING_STATUS,
      value: routeQueryFilterParams.billingStatus,
    };
    filters.push(billingStatusFilter);
  }

  if (routeQueryFilterParams.billingModel) {
    const billingModelFilter: BillingsTaxiOrderListingBillingModelFilter = {
      type: BillingsTaxiOrderListingFilterType.BILLING_MODEL,
      value: routeQueryFilterParams.billingModel,
    };
    filters.push(billingModelFilter);
  }

  if (routeQueryFilterParams.billingType) {
    const billingTypeFilter: BillingsTaxiOrderListingBillingTypeFilter = {
      type: BillingsTaxiOrderListingFilterType.BILLING_TYPE,
      value: routeQueryFilterParams.billingType,
    };
    filters.push(billingTypeFilter);
  }

  if (routeQueryFilterParams.cargoCompany) {
    const cargoCompanyFilter: BillingsTaxiOrderListingCargoCompanyFilter = {
      type: BillingsTaxiOrderListingFilterType.CARGO_COMPANY,
      value: routeQueryFilterParams.cargoCompany,
    };
    filters.push(cargoCompanyFilter);
  }

  if (routeQueryFilterParams.excludeCargoCompany) {
    const excludeCargoCompanyFilter: BillingsTaxiOrderListingExcludeCargoCompanyFilter =
      {
        type: BillingsTaxiOrderListingFilterType.EXCLUDE_CARGO_COMPANY,
        value: routeQueryFilterParams.excludeCargoCompany,
      };
    filters.push(excludeCargoCompanyFilter);
  }

  if (routeQueryFilterParams.passenger) {
    const passengerFilter: BillingsTaxiOrderListingPassengerFilter = {
      type: BillingsTaxiOrderListingFilterType.PASSENGER,
      value: routeQueryFilterParams.passenger,
    };
    filters.push(passengerFilter);
  }

  if (routeQueryFilterParams.destinationTaxi) {
    const destinationTaxiFilter: BillingsTaxiOrderListingDestinationTaxiFilter =
      {
        type: BillingsTaxiOrderListingFilterType.DESTINATION_TAXI,
        value: routeQueryFilterParams.destinationTaxi,
      };
    filters.push(destinationTaxiFilter);
  }

  const updatedFilters =
    billingsTaxiOrderListingFilterHelper.getDefaultFilters(filters);

  return updatedFilters;
};

const getSortKey = (
  routeQueryParams: BillingsTaxiOrderListingRouteQueryParams
): BillingsTaxiOrderListingSortKey | undefined => {
  if (!validateSortKey(routeQueryParams.sort)) {
    return undefined;
  }

  return routeQueryParams.sort;
};

const createRouteQueryParams = (
  filters: BillingsTaxiOrderListingFilter[],
  sortKey: BillingsTaxiOrderListingSortKey | null,
  page: number,
  pageSize: number
): BillingsTaxiOrderListingRouteQueryParams => {
  return {
    page,
    pageSize,
    sort: sortKey ?? undefined,
    orderStartDateFrom: (
      filters.find(
        (filter) =>
          filter.type === BillingsTaxiOrderListingFilterType.ORDER_START_DATE
      )?.value as
        | BillingsTaxiOrderListingOrderStartDateFilter["value"]
        | undefined
    )?.from.toJSON(),
    orderStartDateTo: (
      filters.find(
        (filter) =>
          filter.type === BillingsTaxiOrderListingFilterType.ORDER_START_DATE
      )?.value as
        | BillingsTaxiOrderListingOrderStartDateFilter["value"]
        | undefined
    )?.to.toJSON(),
    internalOrderId: (
      filters.find(
        (filter) =>
          filter.type === BillingsTaxiOrderListingFilterType.INTERNAL_ORDER_ID
      )?.value as
        | BillingsTaxiOrderListingInternalOrderIdFilter["value"]
        | undefined
    )?.toString(),
    planEntryHumanIds: (
      filters.find(
        (filter) =>
          filter.type ===
          BillingsTaxiOrderListingFilterType.PLAN_ENTRY_HUMAN_IDS
      )?.value as
        | BillingsTaxiOrderListingPlanEntryHumanIdsFilter["value"]
        | undefined
    )?.toString(),
    billingModel: filters.find(
      (filter) =>
        filter.type === BillingsTaxiOrderListingFilterType.BILLING_MODEL
    )?.value as BillingsTaxiOrderListingBillingModelFilter["value"] | undefined,
    billingType: filters.find(
      (filter) =>
        filter.type === BillingsTaxiOrderListingFilterType.BILLING_TYPE
    )?.value as BillingsTaxiOrderListingBillingTypeFilter["value"] | undefined,
    billingStatus: filters.find(
      (filter) =>
        filter.type === BillingsTaxiOrderListingFilterType.BILLING_STATUS
    )?.value as
      | BillingsTaxiOrderListingBillingStatusFilter["value"]
      | undefined,
    cargoCompany: filters.find(
      (filter) =>
        filter.type === BillingsTaxiOrderListingFilterType.CARGO_COMPANY
    )?.value as BillingsTaxiOrderListingCargoCompanyFilter["value"] | undefined,
    excludeCargoCompany: filters.find(
      (filter) =>
        filter.type === BillingsTaxiOrderListingFilterType.EXCLUDE_CARGO_COMPANY
    )?.value as
      | BillingsTaxiOrderListingExcludeCargoCompanyFilter["value"]
      | undefined,
    passenger: filters.find(
      (filter) => filter.type === BillingsTaxiOrderListingFilterType.PASSENGER
    )?.value as BillingsTaxiOrderListingPassengerFilter["value"] | undefined,
    destinationTaxi: filters.find(
      (filter) =>
        filter.type === BillingsTaxiOrderListingFilterType.DESTINATION_TAXI
    )?.value as
      | BillingsTaxiOrderListingDestinationTaxiFilter["value"]
      | undefined,
  };
};

const getPage = (
  routeQueryFilterParams: BillingsTaxiOrderListingRouteQueryParams
): number | undefined => {
  if (!validatePage(routeQueryFilterParams.page)) {
    return undefined;
  }

  return routeQueryFilterParams.page
    ? Number(routeQueryFilterParams.page)
    : undefined;
};

const getPageSize = (
  routeQueryFilterParams: BillingsTaxiOrderListingRouteQueryParams
): number | undefined => {
  if (!validatePageSize(routeQueryFilterParams.pageSize)) {
    return undefined;
  }
  return routeQueryFilterParams.pageSize
    ? Number(routeQueryFilterParams.pageSize)
    : undefined;
};

const validateFilters = (
  routeQueryFilterParams: BillingsTaxiOrderListingRouteQueryFilterParams
) => {
  const filterParams: BillingsTaxiOrderListingRouteQueryFilterParams = {
    orderStartDateFrom: routeQueryFilterParams.orderStartDateFrom,
    orderStartDateTo: routeQueryFilterParams.orderStartDateTo,
    internalOrderId: routeQueryFilterParams.internalOrderId,
    planEntryHumanIds: routeQueryFilterParams.planEntryHumanIds,
    cargoCompany: routeQueryFilterParams.cargoCompany,
    excludeCargoCompany: routeQueryFilterParams.excludeCargoCompany,
    passenger: routeQueryFilterParams.passenger,
    billingStatus: routeQueryFilterParams.billingStatus,
    billingModel: routeQueryFilterParams.billingModel,
    billingType: routeQueryFilterParams.billingType,
    destinationTaxi: routeQueryFilterParams.destinationTaxi,
  };

  const validationSchema =
    Joi.object<BillingsTaxiOrderListingRouteQueryFilterParams>({
      orderStartDateFrom: Joi.string(),
      orderStartDateTo: Joi.string(),
      internalOrderId: Joi.number(),
      planEntryHumanIds: Joi.number(),
      billingStatus: Joi.valid(
        ...Object.values(BillingsTaxiOrderListingItemBillingStatus)
      ),
      billingType: Joi.valid(
        ...Object.values(BillingsTaxiOrderListingItemBillingType)
      ),
      billingModel: Joi.valid(...Object.values(BillingModel)),
      cargoCompany: Joi.string(),
      excludeCargoCompany: Joi.string(),
      passenger: Joi.string(),
      destinationTaxi: Joi.string(),
    });

  return !validationSchema.validate(filterParams).error?.message;
};

const validateSortKey = (
  routeQuerySortParam: BillingsTaxiOrderListingRouteQueryParams["sort"]
): boolean => {
  const validationSchema = Joi.valid(
    ...Object.values(BillingsTaxiOrderListingSortKey)
  );

  return !validationSchema.validate(routeQuerySortParam).error?.message;
};

const validatePage = (
  routeQueryPageParam: BillingsTaxiOrderListingRouteQueryParams["page"]
) => {
  const validationSchema = Joi.number().min(1);

  return !validationSchema.validate(routeQueryPageParam).error?.message;
};

const validatePageSize = (
  routeQueryPageSizeParam: BillingsTaxiOrderListingRouteQueryParams["pageSize"]
) => {
  const validationSchema = Joi.number().valid(...[50, 100, 200]);

  return !validationSchema.validate(routeQueryPageSizeParam).error?.message;
};

const billingsTaxiOrderListingRouteQueryParamsService = {
  getFilters,
  getSortKey,
  getPage,
  getPageSize,
  createRouteQueryParams,
};

export default billingsTaxiOrderListingRouteQueryParamsService;
