import { FC } from "react";
import TableComponent, {
  TableProps,
} from "../../../../../../common/components/table/table.component";
import BillingsTaxiTransferredOrderListingItem from "../types/billings-taxi-transferred-order-listing-item";
import BillingsTaxiTransferredOrderListingTableColumn from "../types/billings-taxi-transferred-order-listing-table-column";
import BillingsTaxiTransferredOrderListingTableRow from "../types/billings-taxi-transferred-order-listing-table-row";
import BillingsTaxiTransferredOrderListingTableHelper from "./billings-taxi-transferred-order-listing-table.helper";

type BillingsTaxiTransferredOrderListingTableProps = Pick<
  TableProps,
  "isError" | "isLoading"
> & {
  listingItems: BillingsTaxiTransferredOrderListingItem[];
};

const BillingsTaxiTransferredOrderListingTableComponent: FC<
  BillingsTaxiTransferredOrderListingTableProps
> = (props) => {
  const columns: BillingsTaxiTransferredOrderListingTableColumn[] =
    BillingsTaxiTransferredOrderListingTableHelper.getColumns();

  const rows: BillingsTaxiTransferredOrderListingTableRow[] =
    BillingsTaxiTransferredOrderListingTableHelper.getRows(props.listingItems);

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isError={props.isError}
      isLoading={props.isLoading}
    />
  );
};

export default BillingsTaxiTransferredOrderListingTableComponent;
