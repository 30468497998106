import isEqual from "lodash/isEqual";
import BillingsTaxiUnbilledRouteListingFilterSelectOption from "./types/billings-taxi-unbilled-route-listing-filter-select-option";
import BillingsTaxiUnbilledRouteListingFilterType from "./types/billings-taxi-unbilled-route-listing-filter-type";
import BillingsTaxiUnbilledRouteListingFilter from "./types/billings-taxi-unbilled-route-listing-filter";
import billingsTranslationsHelper from "../../../../../languages/billings-translations.helper";

const getSearchQueryOptions = (
  query: string
): BillingsTaxiUnbilledRouteListingFilterSelectOption[] => {
  if (!query) {
    return [];
  }

  const searchFilterTranslations =
    billingsTranslationsHelper.getTaxiUnbilledRouteBillingsListingTranslations()
      .filters.search;

  const parseStringToNumber = (string: string): number | undefined => {
    const stringAsNumber = Number(string);

    if (Number.isNaN(stringAsNumber)) {
      return undefined;
    }

    return stringAsNumber;
  };

  const getSearchByRouteIdLabel = (query: string) => {
    return searchFilterTranslations.searchByRouteIdTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByDriverLabel = (query: string) => {
    return searchFilterTranslations.searchByDriverTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByClientLabel = (query: string) => {
    return searchFilterTranslations.searchByClientTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByRouteAddressLabel = (query: string) => {
    return searchFilterTranslations.searchByRouteAddressTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByRouteDestinationAddressLabel = (query: string) => {
    return searchFilterTranslations.searchByRouteDestinationAddressTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByRouteIntermediateAddressLabel = (query: string) => {
    return searchFilterTranslations.searchByRouteIntermediateAddressTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByRoutePickupAddressLabel = (query: string) => {
    return searchFilterTranslations.searchByRoutePickupAddressTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByOrderInternalIdLabel = (query: string) => {
    return searchFilterTranslations.searchByOrderInternalIdTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByFleetPartnerLabel = (query: string) => {
    return searchFilterTranslations.searchByFleetPartnerTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const stringOptions: BillingsTaxiUnbilledRouteListingFilterSelectOption[] = [
    {
      label: getSearchByDriverLabel(query),
      value: {
        type: BillingsTaxiUnbilledRouteListingFilterType.DRIVER,
        value: query,
      },
    },
    {
      label: getSearchByFleetPartnerLabel(query),
      value: {
        type: BillingsTaxiUnbilledRouteListingFilterType.FLEET_PARTNER,
        value: query,
      },
    },
    {
      label: getSearchByClientLabel(query),
      value: {
        type: BillingsTaxiUnbilledRouteListingFilterType.CLIENT,
        value: query,
      },
    },
    {
      label: getSearchByRouteAddressLabel(query),
      value: {
        type: BillingsTaxiUnbilledRouteListingFilterType.ROUTE_ADDRESS,
        value: query,
      },
    },
    {
      label: getSearchByRouteDestinationAddressLabel(query),
      value: {
        type: BillingsTaxiUnbilledRouteListingFilterType.ROUTE_DESTINATION_ADDRESS,
        value: query,
      },
    },
    {
      label: getSearchByRouteIntermediateAddressLabel(query),
      value: {
        type: BillingsTaxiUnbilledRouteListingFilterType.ROUTE_INTERMEDIATE_ADDRESS,
        value: query,
      },
    },
    {
      label: getSearchByRoutePickupAddressLabel(query),
      value: {
        type: BillingsTaxiUnbilledRouteListingFilterType.ROUTE_PICKUP_ADDRESS,
        value: query,
      },
    },
  ];

  const options: BillingsTaxiUnbilledRouteListingFilterSelectOption[] = [
    ...stringOptions,
  ] as BillingsTaxiUnbilledRouteListingFilterSelectOption[];

  if (parseStringToNumber(query) !== undefined) {
    const numericFilterOptions: BillingsTaxiUnbilledRouteListingFilterSelectOption[] =
      [
        {
          label: getSearchByRouteIdLabel(query),
          value: {
            type: BillingsTaxiUnbilledRouteListingFilterType.ROUTE_ID,
            value: Number(query),
          },
        },
        {
          label: getSearchByOrderInternalIdLabel(query),
          value: {
            type: BillingsTaxiUnbilledRouteListingFilterType.ORDER_INTERNAL_ID,
            value: Number(query),
          },
        },
      ];

    options.push(...numericFilterOptions);
  }

  return options;
};

const getSelectOptionsByQuery = (
  query: string
): BillingsTaxiUnbilledRouteListingFilterSelectOption[] => {
  const queryOptions = getSearchQueryOptions(query);

  return [...queryOptions];
};

const getSelectOptions = (
  query: string,
  selectedFilters: BillingsTaxiUnbilledRouteListingFilter[]
): BillingsTaxiUnbilledRouteListingFilterSelectOption[] => {
  const allFilterOptions = getSelectOptionsByQuery(query);

  return allFilterOptions.filter((option) => {
    return !selectedFilters.find((selectedFilter) =>
      isEqual(selectedFilter.type, option.value.type)
    );
  });
};

const billingsTaxiUnbilledRouteListingFilterHelper = {
  getSelectOptions,
};

export default billingsTaxiUnbilledRouteListingFilterHelper;
