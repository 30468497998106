const userRouteBasePath = `/user`;

const userRoutesUrls = {
  cargoOfficerAdd: `${userRouteBasePath}/cargo-officer/add`,
  cargoOfficerListing: `${userRouteBasePath}/cargo-officer/listing`,
  dealerAdd: `${userRouteBasePath}/dealer/add`,
  dealerListing: `${userRouteBasePath}/dealer/listing`,
  dispatcherAdd: `${userRouteBasePath}/dispatcher/add`,
  dispatcherEdit: `${userRouteBasePath}/dispatcher/:dispatcherUuid/edit`,
  dispatcherListing: `${userRouteBasePath}/dispatcher/listing`,
  driverAdd: `${userRouteBasePath}/driver/add`,
  driverEdit: `${userRouteBasePath}/driver/:driverUuid/edit`,
  driverPlan: `${userRouteBasePath}/driver/:driverUuid/plan`,
  driverFinishedPlan: `${userRouteBasePath}/driver/:planEntryUuid/details/finished-route-plans`,
  driverListing: `${userRouteBasePath}/driver/listing`,
  driverContractAdd: `${userRouteBasePath}/driver/:driverUuid/contract/add`,
  driverContractEdit: `${userRouteBasePath}/driver/:driverUuid/contract/:contractUuid/edit`,
  driverContractListing: `${userRouteBasePath}/driver/:driverUuid/contract/listing`,
  operatorAdd: `${userRouteBasePath}/operator/add`,
  operatorListing: `${userRouteBasePath}/operator/listing`,
  passengerAdd: `${userRouteBasePath}/passenger/add`,
  passengerListing: `${userRouteBasePath}/passenger/listing`,
  passengerEdit: `${userRouteBasePath}/passenger/:passengerUuid/edit`,
  railyOfficerAdd: `${userRouteBasePath}/raily-officer/add`,
  railyOfficerListing: `${userRouteBasePath}/raily-officer/listing`,
  taxiOfficerAdd: `${userRouteBasePath}/taxi-officer/add`,
  taxiOfficerEdit: `${userRouteBasePath}/taxi-officer/:taxiOfficerUuid/edit`,
  taxiOfficerListing: `${userRouteBasePath}/taxi-officer/listing`,
};

export default userRoutesUrls;
