import orderTranslationsHelper from "../../../../../../languages/order-translations.helper";
import RoutesWaypointAddressAddFormAddressData from "../../../../common/routes/waypoint-address-add-form/types/routes-waypoint-address-add-form-adress-data";
import OrderAddCargoAddress from "../../types/order-add-cargo-address";
import OrderAddPassenger, {
  OrderAddPassengerAddress,
} from "../../types/order-add-passenger";
import OrderAddRouteAddress from "../../types/order-add-route-address";
import OrderAddRouteAddressSelectOption from "../../types/order-add-route-address-select-option";
import {
  OrderAddRouteWaypointGroupWaypoint,
  OrderAddRouteWaypoint,
} from "../../types/order-add-route-waypoint";
import orderAddRoutesHelper from "./order-add-routes.helper";

const createWaypointFromGroupWaypoint = (
  waypoint: OrderAddRouteWaypointGroupWaypoint
): OrderAddRouteWaypoint => {
  return {
    uuid: waypoint.uuid,
    address: waypoint.address,
    date: null,
    haltingTimeMinutes: waypoint.haltingTimeMinutes,
    onboardingPassengerListItems: waypoint.onboardingPassengerListItems,
    outboardingPassengerListItems: waypoint.outboardingPassengerListItems,
  };
};

const createGroupWaypointFromWaypoint = (
  waypoint: OrderAddRouteWaypoint
): OrderAddRouteWaypointGroupWaypoint => {
  return {
    uuid: waypoint.uuid,
    address: waypoint.address,
    haltingTimeMinutes: waypoint.haltingTimeMinutes,
    onboardingPassengerListItems: waypoint.onboardingPassengerListItems,
    outboardingPassengerListItems: waypoint.outboardingPassengerListItems,
  };
};

const createRouteAddressSelectOptionFromPassengerAddress = (
  passenger: OrderAddPassenger,
  passengerAddress: OrderAddPassengerAddress
): OrderAddRouteAddressSelectOption => {
  const translations =
    orderTranslationsHelper.getAddTranslations().routes.waypoint;

  return {
    label: passengerAddress.displayName,
    subText: `${translations.passengerAddressHomeType}: ${passenger.firstName} ${passenger.lastName}`,
    value: passengerAddress,
  };
};

const createRouteAddressFromCargoAddress = (
  cargoAddress: OrderAddCargoAddress
): OrderAddRouteAddress => {
  return {
    uuid: cargoAddress.uuid,
    apartmentNumber: cargoAddress.apartmentNumber,
    houseNumber: cargoAddress.houseNumber,
    street: cargoAddress.street,
    town: cargoAddress.town,
    zipCode: cargoAddress.zipCode,
    displayName: cargoAddress.displayName,
    latitude: cargoAddress.latitude,
    longitude: cargoAddress.longitude,
    description: cargoAddress.description,
  };
};

const createRouteAddressSelectOptionFromCargoAddress = (
  cargoAddress: OrderAddCargoAddress
): OrderAddRouteAddressSelectOption => {
  const subText =
    orderAddRoutesHelper.getRouteAddressSelectOptionSubTextForCargoAddress(
      cargoAddress.type
    );
  const value = createRouteAddressFromCargoAddress(cargoAddress);

  return {
    label: cargoAddress.displayName,
    subText: subText,
    value,
  };
};

const createUpdatedWaypointAddress = (
  address: RoutesWaypointAddressAddFormAddressData
) => {
  const apartmentPart = address.apartmentNumber
    ? `/${address.apartmentNumber}`
    : "";

  const housePart = address.houseNumber ? `${address.houseNumber}` : "";

  const descriptionPart = address.description ? `${address.description},` : "";

  return {
    displayName: `${descriptionPart} ${address.street} ${housePart}${apartmentPart}, ${address.town} ${address.zipCode}`,
    latitude: address.latitude,
    longitude: address.longitude,
    zipCode: address.zipCode,
    town: address.town,
    street: address.street,
    houseNumber: address.houseNumber,
    apartmentNumber: address.apartmentNumber,
    description: address.description,
  };
};

const orderAddRoutesFactory = {
  createWaypointFromGroupWaypoint,
  createGroupWaypointFromWaypoint,
  createRouteAddressSelectOptionFromPassengerAddress,
  createRouteAddressSelectOptionFromCargoAddress,
  createUpdatedWaypointAddress,
};

export default orderAddRoutesFactory;
