import { FC } from "react";
import ListingFilterBadgeListComponent from "../../../../../../../common/components/listing/filter/badge-list/listing-filter-badge-list.component";
import BillingsTaxiOrderListingFilter from "../../types/billings-taxi-order-listing-filter";
import billingsTaxiOrderListingFiltersBadgeListHelper from "./billings-taxi-order-listing-filters-badge-list.helper";

type BillingsTaxiOrderListingFiltersListProps = {
  filters: BillingsTaxiOrderListingFilter[];
  onDeleteFilterClick: (filterIndex: number) => void;
  onDeleteAllFiltersButtonClick: () => void;
};

const BillingsTaxiOrderListingFiltersBadgeListComponent: FC<
  BillingsTaxiOrderListingFiltersListProps
> = (props) => {
  const badges = billingsTaxiOrderListingFiltersBadgeListHelper.getBadges(
    props.filters
  );

  return (
    <ListingFilterBadgeListComponent
      badges={badges}
      onDeleteAll={props.onDeleteAllFiltersButtonClick}
      onDelete={props.onDeleteFilterClick}
    />
  );
};

export default BillingsTaxiOrderListingFiltersBadgeListComponent;
