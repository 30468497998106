import isEqual from "lodash/isEqual";
import BillingsCargoOrderListingFilterSelectOption, {
  BillingsCargoOrderListingInternalOrderIdFilterSelectOption,
} from "./types/billings-cargo-order-listing-filter-select-option";
import BillingsCargoOrderListingFilterType from "./types/billings-cargo-order-listing-filter-type";
import BillingsCargoOrderListingItemBillingStatus from "./types/billings-cargo-order-listing-item-billing-status";
import BillingsCargoOrderListingFilter, {
  BillingsCargoOrderListingOrderStartDateFilter,
} from "./types/billings-cargo-order-listing-filter";
import billingsTranslationsHelper from "../../../../../languages/billings-translations.helper";
import billingsCargoOrderListingHelper from "./billings-cargo-order-listing.helper";

const getSearchQueryOptions = (
  query: string
): BillingsCargoOrderListingFilterSelectOption[] => {
  if (!query) {
    return [];
  }

  const searchFilterTranslations =
    billingsTranslationsHelper.getCargoOrderBillingsListingTranslations()
      .filters.search;

  const parseStringToNumber = (string: string): number | undefined => {
    const stringAsNumber = Number(string);

    if (Number.isNaN(stringAsNumber)) {
      return undefined;
    }

    return stringAsNumber;
  };

  const getSearchByInternalOrderIdLabel = (query: string) => {
    return searchFilterTranslations.searchByInternalOrderIdTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByPassengerLabel = (query: string) => {
    return searchFilterTranslations.searchByPassengerTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByTaxiLabel = (query: string) => {
    return searchFilterTranslations.searchByTaxiTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByExternalOrderIdLabel = (query: string) => {
    return searchFilterTranslations.searchByExternalOrderIdTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByDispatchLabel = (query: string) => {
    return searchFilterTranslations.searchByDispatchTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByRouteAddressQueryLabel = (query: string) => {
    return searchFilterTranslations.searchByRouteAddressTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByRouteIntermediateAddressQueryLabel = (query: string) => {
    return searchFilterTranslations.searchByRouteIntermediateAddressTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByRoutePickupAddressQueryLabel = (query: string) => {
    return searchFilterTranslations.searchByRoutePickupAddressTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByRouteDestinationAddressQueryLabel = (query: string) => {
    return searchFilterTranslations.searchByRouteDestinationAddressTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const stringOptions = [
    {
      label: getSearchByPassengerLabel(query),
      value: {
        type: BillingsCargoOrderListingFilterType.PASSENGER,
        value: query,
      },
    },
    {
      label: getSearchByRouteAddressQueryLabel(query),
      value: {
        type: BillingsCargoOrderListingFilterType.ROUTE_ADDRESS,
        value: query,
      },
    },
    {
      label: getSearchByRouteIntermediateAddressQueryLabel(query),
      value: {
        type: BillingsCargoOrderListingFilterType.ROUTE_INTERMEDIATE_ADDRESS,
        value: query,
      },
    },
    {
      label: getSearchByRoutePickupAddressQueryLabel(query),
      value: {
        type: BillingsCargoOrderListingFilterType.ROUTE_PICKUP_ADDRESS,
        value: query,
      },
    },
    {
      label: getSearchByRouteDestinationAddressQueryLabel(query),
      value: {
        type: BillingsCargoOrderListingFilterType.ROUTE_DESTINATION_ADDRESS,
        value: query,
      },
    },
    {
      label: getSearchByTaxiLabel(query),
      value: {
        type: BillingsCargoOrderListingFilterType.TAXI,
        value: query,
      },
    },
    {
      label: getSearchByExternalOrderIdLabel(query),
      value: {
        type: BillingsCargoOrderListingFilterType.EXTERNAL_ORDER_ID,
        value: query,
      },
    },
    {
      label: getSearchByDispatchLabel(query),
      value: {
        type: BillingsCargoOrderListingFilterType.DISPATCH,
        value: query,
      },
    },
  ];
  BillingsCargoOrderListingFilterType;
  const options: BillingsCargoOrderListingFilterSelectOption[] = [
    ...stringOptions,
  ] as BillingsCargoOrderListingFilterSelectOption[];

  if (parseStringToNumber(query) !== undefined) {
    const routeIdFilterOption: BillingsCargoOrderListingInternalOrderIdFilterSelectOption =
      {
        label: getSearchByInternalOrderIdLabel(query),
        value: {
          type: BillingsCargoOrderListingFilterType.INTERNAL_ORDER_ID,
          value: query,
        },
      };

    options.push(routeIdFilterOption);
  }

  return options;
};

const getSearchStatusOptions = (
  query: string
): BillingsCargoOrderListingFilterSelectOption[] => {
  const searchFilterTranslations =
    billingsTranslationsHelper.getCargoOrderBillingsListingTranslations()
      .filters.search;

  const allStatusSelectOptions: BillingsCargoOrderListingFilterSelectOption[] =
    Object.values(BillingsCargoOrderListingItemBillingStatus).map((status) => {
      const statusLabel =
        searchFilterTranslations.searchByStatusTemplateText.replace(
          "#{option}",
          billingsCargoOrderListingHelper.getStatusText(status!)
        );

      return {
        label: statusLabel,
        value: {
          type: BillingsCargoOrderListingFilterType.BILLING_STATUS,
          value: status,
        },
      };
    });

  if (query) {
    return allStatusSelectOptions.filter((option) =>
      option.label.toLowerCase().includes(query.toLowerCase())
    );
  }

  return allStatusSelectOptions;
};

const getSelectOptionsByQuery = (
  query: string
): BillingsCargoOrderListingFilterSelectOption[] => {
  const statusOptions = getSearchStatusOptions(query);
  const queryOptions = getSearchQueryOptions(query);

  return [...statusOptions, ...queryOptions];
};

const getSelectOptions = (
  query: string,
  selectedFilters: BillingsCargoOrderListingFilter[]
): BillingsCargoOrderListingFilterSelectOption[] => {
  const allFilterOptions = getSelectOptionsByQuery(query);

  return allFilterOptions.filter((option) => {
    return !selectedFilters.find((selectedFilter) =>
      isEqual(selectedFilter.type, option.value.type)
    );
  });
};

const getDefaultFilters = (
  filters: BillingsCargoOrderListingFilter[]
): BillingsCargoOrderListingFilter[] => {
  const currentDate = new Date();

  const defaultFilters = [
    {
      type: BillingsCargoOrderListingFilterType.ORDER_START_DATE,
      value: {
        from: new Date(currentDate.setDate(1)),
        to: new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0),
      },
    } as BillingsCargoOrderListingOrderStartDateFilter,
  ];

  defaultFilters.forEach((defaultFilter) => {
    const existingFilter = filters.find(
      (filter) => filter.type === defaultFilter.type
    );

    if (existingFilter) {
      return;
    }

    filters.push(defaultFilter);
  });

  return filters;
};

const billingsCargoOrderListingFilterHelper = {
  getDefaultFilters,
  getSelectOptions,
};

export default billingsCargoOrderListingFilterHelper;
