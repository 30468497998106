import { FC } from "react";
import ListingFilterBadgeListComponent from "../../../../../../../common/components/listing/filter/badge-list/listing-filter-badge-list.component";
import BillingsTaxiRouteListingFilter from "../../types/billings-taxi-route-listing-filter";
import billingsTaxiRouteListingFiltersBadgeListHelper from "./billings-taxi-route-listing-filters-badge-list.helper";

type BillingsTaxiRouteListingFiltersListProps = {
  filters: BillingsTaxiRouteListingFilter[];
  onDeleteFilterClick: (filterIndex: number) => void;
  onDeleteAllFiltersButtonClick: () => void;
};

const BillingsTaxiRouteListingFiltersBadgeListComponent: FC<
  BillingsTaxiRouteListingFiltersListProps
> = (props) => {
  const badges = billingsTaxiRouteListingFiltersBadgeListHelper.getBadges(
    props.filters
  );

  return (
    <ListingFilterBadgeListComponent
      badges={badges}
      onDeleteAll={props.onDeleteAllFiltersButtonClick}
      onDelete={props.onDeleteFilterClick}
    />
  );
};

export default BillingsTaxiRouteListingFiltersBadgeListComponent;
