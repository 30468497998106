import { FC } from "react";
import BillingsCargoOrderListingBillingsAcceptData from "../types/billings-cargo-order-listing-billings-accept-data";
import billingsCargoOrderListingBillingsAcceptHelper from "./billings-cargo-order-listing-billings-accept.helper";
import ButtonComponent from "../../../../../../common/components/button/button.component";
import billingsTranslationsHelper from "../../../../../../languages/billings-translations.helper";

type BillingsCargoOrderListingBillingsAcceptProps = {
  billingsAcceptData: BillingsCargoOrderListingBillingsAcceptData[];
  onSubmit: () => void;
  onCancel: () => void;
};

const BillingsCargoOrderListingBillingsAcceptComponent: FC<
  BillingsCargoOrderListingBillingsAcceptProps
> = (props) => {
  const translations =
    billingsTranslationsHelper.getCargoOrderBillingsListingTranslations()
      .billingsAccept;

  const selectedBillingsLabel =
    billingsCargoOrderListingBillingsAcceptHelper.getBillingLabel(
      props.billingsAcceptData
    );

  const isSubmitButtonEnabled = !!props.billingsAcceptData.length;

  return (
    <div className="billings_cargo_order_listing_billings_accept">
      <div className="billings_cargo_order_listing_billings_accept__heading">
        {translations.headingLabel}
      </div>
      <div className="billings_cargo_order_listing_billings_accept__content">
        <div>
          <div>{selectedBillingsLabel}</div>
        </div>
        <div className="d-flex mt-2">
          <ButtonComponent
            type="success"
            onClick={props.onSubmit}
            isDisabled={!isSubmitButtonEnabled}
            title={translations.submitButtonLabel}
          >
            {translations.submitButtonLabel}
          </ButtonComponent>
          <ButtonComponent
            type="brand"
            onClick={props.onCancel}
            classNames={{ root: "ml-1" }}
            title={translations.cancelButtonLabel}
          >
            {translations.cancelButtonLabel}
          </ButtonComponent>
        </div>
      </div>
    </div>
  );
};

export default BillingsCargoOrderListingBillingsAcceptComponent;
