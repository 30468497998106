import billingsCargoOrderListingRouteQueryParamsService from "../../common/billings-cargo-order-listing-route-query-params.service";

const billingsCargoOrderListingByCargoRouteQueryParamsService = {
  getFilters: billingsCargoOrderListingRouteQueryParamsService.getFilters,
  getSortKey: billingsCargoOrderListingRouteQueryParamsService.getSortKey,
  getPage: billingsCargoOrderListingRouteQueryParamsService.getPage,
  getPageSize: billingsCargoOrderListingRouteQueryParamsService.getPageSize,
  createRouteQueryParams:
    billingsCargoOrderListingRouteQueryParamsService.createRouteQueryParams,
};

export default billingsCargoOrderListingByCargoRouteQueryParamsService;
