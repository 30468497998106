import { BillingsTaxiUnbilledTransferredOrderListingResponseDataItem } from "../api/billings-taxi-unbilled-transferred-order-listing-response";
import BillingsTaxiUnbilledTransferredOrderListingItem from "../types/billings-taxi-unbilled-transferred-order-listing-item";

const createListingItem = (
  responseDataItem: BillingsTaxiUnbilledTransferredOrderListingResponseDataItem
): BillingsTaxiUnbilledTransferredOrderListingItem => {
  return {
    startDate: new Date(responseDataItem.start_time),
    transferDate: new Date(responseDataItem.forwarding_time),
    internalOrderId: responseDataItem.human_id,
    externalOrderId: responseDataItem.external_id,
    routeAddresses: responseDataItem.ride_addresses,
    passengers: responseDataItem.passengers,
    producerTaxi: responseDataItem.producer.name,
    consumerTaxi: responseDataItem.consumer.name,
    driversDisplayName: responseDataItem.driver,
    uuid: responseDataItem.id,
    readOnly: responseDataItem.read_only,
  };
};

const createListingItems = (
  responseDataItems: BillingsTaxiUnbilledTransferredOrderListingResponseDataItem[]
): BillingsTaxiUnbilledTransferredOrderListingItem[] => {
  return responseDataItems.map(createListingItem);
};

const billingsTaxiUnbilledTransferredOrderListingFactory = {
  createListingItems,
};

export default billingsTaxiUnbilledTransferredOrderListingFactory;
