import billingsTranslationsHelper from "../../../../../languages/billings-translations.helper";
import BillingsTaxiUnbilledRouteListingSortKey from "./types/billings-taxi-unbilled-route-listing-sort-key";
import BillingsTaxiUnbilledRouteListingSortSelectOption from "./types/billings-taxi-unbilled-route-listing-sort-select-option";

const getSelectOptions =
  (): BillingsTaxiUnbilledRouteListingSortSelectOption[] => {
    const translations =
      billingsTranslationsHelper.getTaxiUnbilledRouteBillingsListingTranslations();
    return [
      {
        label: translations.sort.orderStartDateAscOptionLabel,
        value: BillingsTaxiUnbilledRouteListingSortKey.START_TIME_ASC,
      },
      {
        label: translations.sort.orderStartDateDescOptionLabel,
        value: BillingsTaxiUnbilledRouteListingSortKey.START_TIME_DESC,
      },
      {
        label: translations.sort.routeIdAscOptionLabel,
        value: BillingsTaxiUnbilledRouteListingSortKey.ROUTE_ID_ASC,
      },
      {
        label: translations.sort.routeIdDescOptionLabel,
        value: BillingsTaxiUnbilledRouteListingSortKey.ROUTE_ID_DESC,
      },
      {
        label: translations.sort.driverAscOptionLabel,
        value: BillingsTaxiUnbilledRouteListingSortKey.DRIVER_ASC,
      },
      {
        label: translations.sort.driverDescOptionLabel,
        value: BillingsTaxiUnbilledRouteListingSortKey.DRIVER_DESC,
      },
    ];
  };

const billingsTaxiUnbilledRouteListingSortHelper = {
  getSelectOptions,
};

export default billingsTaxiUnbilledRouteListingSortHelper;
