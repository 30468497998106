import billingsTaxiRouteListingFactory from "../../common/billings-taxi-route-listing.factory";
import { BillingsTaxiRouteListingByRailyTaxiCorporationsResponseDataItem } from "./api/billings-taxi-route-listing-by-raily-taxi-corporations.response";
import BillingsTaxiRouteListingByRailyTaxiCorporationSelectOption from "./types/billings-taxi-route-listing-by-raily-taxi-corporation-select-option";

const createTaxiCorporationSelectOption = (
  responseTaxiCorporation: BillingsTaxiRouteListingByRailyTaxiCorporationsResponseDataItem
): BillingsTaxiRouteListingByRailyTaxiCorporationSelectOption => {
  return {
    label: responseTaxiCorporation.display_name,
    value: {
      uuid: responseTaxiCorporation.id,
      displayName: responseTaxiCorporation.display_name,
    },
  };
};

const createTaxiCorporationSelectOptions = (
  responseTaxiCorporations: BillingsTaxiRouteListingByRailyTaxiCorporationsResponseDataItem[]
): BillingsTaxiRouteListingByRailyTaxiCorporationSelectOption[] => {
  return responseTaxiCorporations.map(createTaxiCorporationSelectOption);
};

const billingsTaxiRouteListingByRailyFactory = {
  createTaxiCorporationSelectOptions,
  createListingItems: billingsTaxiRouteListingFactory.createListingItems,
  createStatsSummary: billingsTaxiRouteListingFactory.createStatsSummary,
};

export default billingsTaxiRouteListingByRailyFactory;
