import StatusLegendStatuses from "../../../../../common/components/status-legend/types/status-legend-statuses";
import billingsTranslationsHelper from "../../../../../languages/billings-translations.helper";
import BillingsTaxiUnbilledPrivateOrderListingItemStatus from "./types/billings-taxi-unbilled-private-order-listing-item-status";

const getStatusOptions = () => {
  const translations =
    billingsTranslationsHelper.getTaxiUnbilledPrivateOrderBillingsListingTranslations()
      .status;

  const options: {
    text: string;
    status: BillingsTaxiUnbilledPrivateOrderListingItemStatus;
  }[] = [
    {
      text: translations.CANCELLED,
      status: BillingsTaxiUnbilledPrivateOrderListingItemStatus.CANCELLED,
    },
    {
      text: translations.FINISHED,
      status: BillingsTaxiUnbilledPrivateOrderListingItemStatus.FINISHED,
    },
  ];

  return options;
};

const getStatusText = (
  status: BillingsTaxiUnbilledPrivateOrderListingItemStatus
): string => {
  const options = getStatusOptions();

  return options.find((option) => option.status === status)?.text ?? "";
};

const getStatusLegendOptions = () => {
  const options: {
    status: StatusLegendStatuses;
  }[] = [
    {
      status: StatusLegendStatuses.CANCELLED,
    },
    {
      status: StatusLegendStatuses.FINISHED,
    },
  ];

  return options;
};

const billingsTaxiUnbilledPrivateOrderListingHelper = {
  getStatusOptions,
  getStatusText,
  getStatusLegendOptions,
};

export default billingsTaxiUnbilledPrivateOrderListingHelper;
