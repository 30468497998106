import BillingsTaxiUnbilledOrderListingItemStatus from "./billings-taxi-unbilled-order-listing-item-status";

export enum BillingsTaxiUnbilledOrderListingItemMissingBillingType {
  CARGO_WITH_RAILY = "CARGO_WITH_RAILY",
  CARGO_WITH_TAXI = "CARGO_WITH_TAXI",
  TAXI_WITH_RAILY = "TAXI_WITH_RAILY",
  RAILY_WITH_TAXI = "RAILY_WITH_TAXI",
}

type BillingsTaxiUnbilledOrderListingItem = {
  uuid: string;
  startDate: Date;
  internalOrderId: number;
  externalOrderId: string | null;
  routeAddresses: (string | string[])[];
  passengers: string[];
  dispatchName: string;
  clientName: string;
  taxiCorporations: string[];
  driversDisplayNames: string[];
  status: BillingsTaxiUnbilledOrderListingItemStatus;
  missingBillingTypes: BillingsTaxiUnbilledOrderListingItemMissingBillingType[];
};

export default BillingsTaxiUnbilledOrderListingItem;
