import HttpResponse from "../../../../../../../../common/utils/http/http.response";

export enum DriverDetailsActiveRoutePlansByTaxiResponsePlanEntryType {
  RESTRICTED = "RESTRICTED",
  FULL = "FULL",
}

export type DriverDetailsActiveRoutePlansByTaxiResponsePlanEntryCheckoutEvent =
  {
    id: string;
    node_id: string;
    achieved_at: string;
    halting_time: number | null;
    highway_charge: number;
    created_at: Date | null;
    created_by_name: string | null;
  };

export type DriverDetailsActiveRoutePlansByTaxiResponsePlanEntryRideNode = {
  display_name: string;
  lat: number;
  lon: number;
  time: string | null;
  halting_time: number | null;
  meta: {
    estimated_time: string;
    id: string;
  };
};

export type DriverDetailsActiveRoutePlansByTaxiResponsePlanEntryStartNode =
  DriverDetailsActiveRoutePlansByTaxiResponsePlanEntryRideNode;

export type DriverDetailsActiveRoutePlansByTaxiResponsePlanEntryEndNode =
  DriverDetailsActiveRoutePlansByTaxiResponsePlanEntryRideNode;

export type DriverDetailsActiveRoutePlansByTaxiResponseRestrictedPlanEntry = {
  id: string;
  human_id: number;
  view_type: DriverDetailsActiveRoutePlansByTaxiResponsePlanEntryType.RESTRICTED;
  start_node: DriverDetailsActiveRoutePlansByTaxiResponsePlanEntryStartNode;
  end_node: DriverDetailsActiveRoutePlansByTaxiResponsePlanEntryEndNode;
  node_exclusions: DriverDetailsActiveRoutePlansByTaxiResponsePlanEntryNodeExclusions;
};

export type DriverDetailsActiveRoutePlansByTaxiResponsePlanEntrySolvedOrder = {
  id: string;
  ride: {
    seq: DriverDetailsActiveRoutePlansByTaxiResponsePlanEntryRideNode[];
  };
  transporting_order: {
    cargo_order: {
      id: string;
      human_id: number;
    };
  };
  cargo_company: {
    id: string;
    display_name: string;
  };
};

export type DriverDetailsActiveRoutePlansByTaxiResponsePlanEntryNodeExclusion =
  {
    exclude_approaching: boolean;
    exclude_inside: boolean;
    exclude_returning: boolean;
    node_ids: string[];
  };

export type DriverDetailsActiveRoutePlansByTaxiResponsePlanEntryNodeExclusions =
  {
    exclusions: DriverDetailsActiveRoutePlansByTaxiResponsePlanEntryNodeExclusion[];
  };

export type DriverDetailsActiveRoutePlansByTaxiResponseFullPlanEntry = {
  id: string;
  human_id: number;
  view_type: DriverDetailsActiveRoutePlansByTaxiResponsePlanEntryType.FULL;
  checkout_events: DriverDetailsActiveRoutePlansByTaxiResponsePlanEntryCheckoutEvent[];
  start_node: DriverDetailsActiveRoutePlansByTaxiResponsePlanEntryStartNode;
  end_node: DriverDetailsActiveRoutePlansByTaxiResponsePlanEntryEndNode;
  solved_orders: DriverDetailsActiveRoutePlansByTaxiResponsePlanEntrySolvedOrder[];
  node_exclusions: DriverDetailsActiveRoutePlansByTaxiResponsePlanEntryNodeExclusions;
};

export type DriverDetailsActiveRoutePlansByTaxiResponsePlanEntry =
  | DriverDetailsActiveRoutePlansByTaxiResponseRestrictedPlanEntry
  | DriverDetailsActiveRoutePlansByTaxiResponseFullPlanEntry;

export type DriverDetailsActiveRoutePlansByTaxiPlanResponseData = {
  entries: DriverDetailsActiveRoutePlansByTaxiResponsePlanEntry[];
};

type DriverDetailsActiveRoutePlansByTaxiPlanResponse =
  HttpResponse<DriverDetailsActiveRoutePlansByTaxiPlanResponseData>;

export default DriverDetailsActiveRoutePlansByTaxiPlanResponse;
