import CargoOrderAbandonedListReportLoadParams, {
  OrderAbandonedListReportLoadParamsOrder,
} from "../../../../common/services/cargo-order/abandoned-list/report/cargo-order-abandoned-list-report-load-params";
import OrderAbandonedListingFilter, {
  OrderAbandonedListingClientFilter,
  OrderAbandonedListingDispatchFilter,
  OrderAbandonedListingPassengerFilter,
  OrderAbandonedListingExternalOrderIdFilter,
  OrderAbandonedListingInternalOrderIdFilter,
  OrderAbandonedListingAddressFilter,
  OrderAbandonedListingStartAddressFilter,
  OrderAbandonedListingMidAddressFilter,
  OrderAbandonedListingEndAddressFilter,
  OrderAbandonedListingStartTimeFilter,
} from "./types/order-abandoned-listing-filter";
import OrderAbandonedListingFilterType from "./types/order-abandoned-listing-filter-type";
import OrderAbandonedListingSortKey from "./types/order-abandoned-listing-sort-key";

const createOrder = (
  order: OrderAbandonedListingSortKey | null
): OrderAbandonedListReportLoadParamsOrder | null => {
  switch (order) {
    case OrderAbandonedListingSortKey.CARGO_COMPANY_INTERNAL_ID_ASC:
      return OrderAbandonedListReportLoadParamsOrder.HUMAN_ID_ASC;
    case OrderAbandonedListingSortKey.CARGO_COMPANY_INTERNAL_ID_DESC:
      return OrderAbandonedListReportLoadParamsOrder.HUMAN_ID_DESC;
    case OrderAbandonedListingSortKey.CREATED_ASC:
      return OrderAbandonedListReportLoadParamsOrder.CREATED_AT_ASC;
    case OrderAbandonedListingSortKey.CREATED_DESC:
      return OrderAbandonedListReportLoadParamsOrder.CREATED_AT_DESC;
    case OrderAbandonedListingSortKey.MODIFIED_ASC:
      return OrderAbandonedListReportLoadParamsOrder.MODIFIED_AT_ASC;
    case OrderAbandonedListingSortKey.MODIFIED_DESC:
      return OrderAbandonedListReportLoadParamsOrder.MODIFIED_AT_DESC;
    default:
      return null;
  }
};

const create = (
  sortKey: OrderAbandonedListingSortKey | null,
  filters: OrderAbandonedListingFilter[]
): CargoOrderAbandonedListReportLoadParams => {
  const clientFilterValue = filters.find(
    (filter) => filter.type === OrderAbandonedListingFilterType.CLIENT
  )?.value as OrderAbandonedListingClientFilter["value"] | undefined;
  const dispatchFilterValue = filters.find(
    (filter) => filter.type === OrderAbandonedListingFilterType.DISPATCH
  )?.value as OrderAbandonedListingDispatchFilter["value"] | undefined;
  const passengerFilterValue = filters.find(
    (filter) => filter.type === OrderAbandonedListingFilterType.PASSENGER
  )?.value as OrderAbandonedListingPassengerFilter["value"] | undefined;
  const externalIdFilterValue = filters.find(
    (filter) =>
      filter.type === OrderAbandonedListingFilterType.EXTERNAL_ORDER_ID
  )?.value as OrderAbandonedListingExternalOrderIdFilter["value"] | undefined;
  const internalIdFilterValue = filters.find(
    (filter) =>
      filter.type === OrderAbandonedListingFilterType.INTERNAL_ORDER_ID
  )?.value as OrderAbandonedListingInternalOrderIdFilter["value"] | undefined;
  const addressFilterValue = filters.find(
    (filter) => filter.type === OrderAbandonedListingFilterType.ADDRESS
  )?.value as OrderAbandonedListingAddressFilter["value"] | undefined;
  const startAddressFilterValue = filters.find(
    (filter) => filter.type === OrderAbandonedListingFilterType.START_ADDRESS
  )?.value as OrderAbandonedListingStartAddressFilter["value"] | undefined;
  const midAddressFilterValue = filters.find(
    (filter) => filter.type === OrderAbandonedListingFilterType.MID_ADDRESS
  )?.value as OrderAbandonedListingMidAddressFilter["value"] | undefined;
  const endAddressFilterValue = filters.find(
    (filter) => filter.type === OrderAbandonedListingFilterType.END_ADDRESS
  )?.value as OrderAbandonedListingEndAddressFilter["value"] | undefined;
  const startTimeFilterValue = filters.find(
    (filter) => filter.type === OrderAbandonedListingFilterType.START_TIME
  )?.value as OrderAbandonedListingStartTimeFilter["value"] | undefined;

  return {
    order: createOrder(sortKey),
    address: addressFilterValue ? [addressFilterValue] : undefined,
    client: clientFilterValue ? [clientFilterValue] : undefined,
    dispatch: dispatchFilterValue ? [dispatchFilterValue] : undefined,
    endAddress: endAddressFilterValue ? [endAddressFilterValue] : undefined,
    externalId: externalIdFilterValue
      ? [externalIdFilterValue.toString()]
      : undefined,
    humanId: internalIdFilterValue ? [internalIdFilterValue] : undefined,
    midAddress: midAddressFilterValue ? [midAddressFilterValue] : undefined,
    passenger: passengerFilterValue ? [passengerFilterValue] : undefined,
    startAddress: startAddressFilterValue
      ? [startAddressFilterValue]
      : undefined,
    startTimeSince: startTimeFilterValue
      ? startTimeFilterValue.from.toJSON()
      : undefined,
    startTimeTo: startTimeFilterValue
      ? startTimeFilterValue.to.toJSON()
      : undefined,
  };
};

const orderAbandonedListingReportLoadParamsFactory = {
  create,
};

export default orderAbandonedListingReportLoadParamsFactory;
