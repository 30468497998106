import billingsTranslationsHelper from "../../../../../../languages/billings-translations.helper";
import BillingsCargoOrderListingBillingsAcceptData from "../types/billings-cargo-order-listing-billings-accept-data";

const getBillingLabel = (
  billingsAcceptData: BillingsCargoOrderListingBillingsAcceptData[]
): string => {
  const translations =
    billingsTranslationsHelper.getCargoOrderBillingsListingTranslations()
      .billingsAccept;

  return translations.selectedBillingsTemplateLabel.replace(
    "#{internalOrderIds}",
    billingsAcceptData.length
      ? billingsAcceptData.map((item) => item.internalOrderId).join(", ")
      : translations.billingsNotSelectedLabel
  );
};

const billingsCargoOrderListingBillingsAcceptHelper = {
  getBillingLabel,
};

export default billingsCargoOrderListingBillingsAcceptHelper;
