import Joi from "joi";
import BillingsTaxiUnbilledTransferredOrderListingFilter, {
  BillingsTaxiUnbilledTransferredOrderListingProducerTaxiFilter,
  BillingsTaxiUnbilledTransferredOrderListingConsumerTaxiFilter,
  BillingsTaxiUnbilledTransferredOrderListingDriverFilter,
  BillingsTaxiUnbilledTransferredOrderListingExternalOrderIdFilter,
  BillingsTaxiUnbilledTransferredOrderListingInternalOrderIdFilter,
  BillingsTaxiUnbilledTransferredOrderListingPassengerFilter,
  BillingsTaxiUnbilledTransferredOrderListingRouteAddressFilter,
  BillingsTaxiUnbilledTransferredOrderListingRouteDestinationAddressFilter,
  BillingsTaxiUnbilledTransferredOrderListingRouteIntermediateAddressFilter,
  BillingsTaxiUnbilledTransferredOrderListingRoutePickupAddressFilter,
  BillingsTaxiUnbilledTransferredOrderListingStartDateFilter,
} from "./types/billings-taxi-unbilled-transferred-order-listing-filter";
import BillingsTaxiUnbilledTransferredOrderListingFilterType from "./types/billings-taxi-unbilled-transferred-order-listing-filter-type";
import BillingsTaxiUnbilledTransferredOrderListingRouteQueryFilterParams from "./types/billings-taxi-unbilled-transferred-order-listing-route-query-filter-params";
import BillingsTaxiUnbilledTransferredOrderListingRouteQueryParams from "./types/billings-taxi-unbilled-transferred-order-listing-route-query-params";
import BillingsTaxiUnbilledTransferredOrderListingSortKey from "./types/billings-taxi-unbilled-transferred-order-listing-sort-key";

const getFilters = (
  routeQueryFilterParams: BillingsTaxiUnbilledTransferredOrderListingRouteQueryFilterParams
): BillingsTaxiUnbilledTransferredOrderListingFilter[] => {
  if (!validateFilters(routeQueryFilterParams)) {
    return [];
  }

  const filters: BillingsTaxiUnbilledTransferredOrderListingFilter[] = [];

  if (routeQueryFilterParams.producerTaxi) {
    const producerTaxiFilter: BillingsTaxiUnbilledTransferredOrderListingProducerTaxiFilter =
      {
        type: BillingsTaxiUnbilledTransferredOrderListingFilterType.PRODUCER_TAXI,
        value: routeQueryFilterParams.producerTaxi,
      };

    filters.push(producerTaxiFilter);
  }

  if (routeQueryFilterParams.consumerTaxi) {
    const consumerTaxiFilter: BillingsTaxiUnbilledTransferredOrderListingConsumerTaxiFilter =
      {
        type: BillingsTaxiUnbilledTransferredOrderListingFilterType.CONSUMER_TAXI,
        value: routeQueryFilterParams.consumerTaxi,
      };

    filters.push(consumerTaxiFilter);
  }

  if (routeQueryFilterParams.driver) {
    const driverFilter: BillingsTaxiUnbilledTransferredOrderListingDriverFilter =
      {
        type: BillingsTaxiUnbilledTransferredOrderListingFilterType.DRIVER,
        value: routeQueryFilterParams.driver,
      };

    filters.push(driverFilter);
  }

  if (routeQueryFilterParams.externalOrderId) {
    const externalOrderIdFilter: BillingsTaxiUnbilledTransferredOrderListingExternalOrderIdFilter =
      {
        type: BillingsTaxiUnbilledTransferredOrderListingFilterType.EXTERNAL_ORDER_ID,
        value: routeQueryFilterParams.externalOrderId,
      };

    filters.push(externalOrderIdFilter);
  }

  if (routeQueryFilterParams.internalOrderId) {
    const internalOrderIdFilter: BillingsTaxiUnbilledTransferredOrderListingInternalOrderIdFilter =
      {
        type: BillingsTaxiUnbilledTransferredOrderListingFilterType.INTERNAL_ORDER_ID,
        value: routeQueryFilterParams.internalOrderId,
      };

    filters.push(internalOrderIdFilter);
  }

  if (routeQueryFilterParams.passenger) {
    const passengerFilter: BillingsTaxiUnbilledTransferredOrderListingPassengerFilter =
      {
        type: BillingsTaxiUnbilledTransferredOrderListingFilterType.PASSENGER,
        value: routeQueryFilterParams.passenger,
      };

    filters.push(passengerFilter);
  }

  if (routeQueryFilterParams.routeAddress) {
    const routeAddressFilter: BillingsTaxiUnbilledTransferredOrderListingRouteAddressFilter =
      {
        type: BillingsTaxiUnbilledTransferredOrderListingFilterType.ROUTE_ADDRESS,
        value: routeQueryFilterParams.routeAddress,
      };

    filters.push(routeAddressFilter);
  }

  if (routeQueryFilterParams.routeDestinationAddress) {
    const routeDestinationFilter: BillingsTaxiUnbilledTransferredOrderListingRouteDestinationAddressFilter =
      {
        type: BillingsTaxiUnbilledTransferredOrderListingFilterType.ROUTE_DESTINATION_ADDRESS,
        value: routeQueryFilterParams.routeDestinationAddress,
      };

    filters.push(routeDestinationFilter);
  }

  if (routeQueryFilterParams.routeIntermediateAddress) {
    const routeIntermediateAddressFilter: BillingsTaxiUnbilledTransferredOrderListingRouteIntermediateAddressFilter =
      {
        type: BillingsTaxiUnbilledTransferredOrderListingFilterType.ROUTE_INTERMEDIATE_ADDRESS,
        value: routeQueryFilterParams.routeIntermediateAddress,
      };

    filters.push(routeIntermediateAddressFilter);
  }

  if (routeQueryFilterParams.routePickupAddress) {
    const routePickupAddressFilter: BillingsTaxiUnbilledTransferredOrderListingRoutePickupAddressFilter =
      {
        type: BillingsTaxiUnbilledTransferredOrderListingFilterType.ROUTE_PICKUP_ADDRESS,
        value: routeQueryFilterParams.routePickupAddress,
      };

    filters.push(routePickupAddressFilter);
  }

  if (
    routeQueryFilterParams.startDateFrom &&
    routeQueryFilterParams.startDateTo
  ) {
    const startDateFilter: BillingsTaxiUnbilledTransferredOrderListingStartDateFilter =
      {
        type: BillingsTaxiUnbilledTransferredOrderListingFilterType.START_DATE,
        value: {
          from: new Date(routeQueryFilterParams.startDateFrom),
          to: new Date(routeQueryFilterParams.startDateTo),
        },
      };

    filters.push(startDateFilter);
  }

  return filters;
};

const getSortKey = (
  routeQueryParams: BillingsTaxiUnbilledTransferredOrderListingRouteQueryParams
): BillingsTaxiUnbilledTransferredOrderListingSortKey | undefined => {
  if (!validateSortKey(routeQueryParams.sort)) {
    return undefined;
  }

  return routeQueryParams.sort;
};

const createRouteQueryParams = (
  filters: BillingsTaxiUnbilledTransferredOrderListingFilter[],
  sortKey: BillingsTaxiUnbilledTransferredOrderListingSortKey | null,
  page: number,
  pageSize: number
): BillingsTaxiUnbilledTransferredOrderListingRouteQueryParams => {
  const routeQueryParams: BillingsTaxiUnbilledTransferredOrderListingRouteQueryParams =
    {
      producerTaxi: filters.find(
        (filter) =>
          filter.type ===
          BillingsTaxiUnbilledTransferredOrderListingFilterType.PRODUCER_TAXI
      )?.value as
        | BillingsTaxiUnbilledTransferredOrderListingProducerTaxiFilter["value"]
        | undefined,
      consumerTaxi: filters.find(
        (filter) =>
          filter.type ===
          BillingsTaxiUnbilledTransferredOrderListingFilterType.CONSUMER_TAXI
      )?.value as
        | BillingsTaxiUnbilledTransferredOrderListingConsumerTaxiFilter["value"]
        | undefined,
      driver: filters.find(
        (filter) =>
          filter.type ===
          BillingsTaxiUnbilledTransferredOrderListingFilterType.DRIVER
      )?.value as
        | BillingsTaxiUnbilledTransferredOrderListingDriverFilter["value"]
        | undefined,
      externalOrderId: filters.find(
        (filter) =>
          filter.type ===
          BillingsTaxiUnbilledTransferredOrderListingFilterType.EXTERNAL_ORDER_ID
      )?.value as
        | BillingsTaxiUnbilledTransferredOrderListingExternalOrderIdFilter["value"]
        | undefined,
      internalOrderId: filters.find(
        (filter) =>
          filter.type ===
          BillingsTaxiUnbilledTransferredOrderListingFilterType.INTERNAL_ORDER_ID
      )?.value as
        | BillingsTaxiUnbilledTransferredOrderListingInternalOrderIdFilter["value"]
        | undefined,
      passenger: filters.find(
        (filter) =>
          filter.type ===
          BillingsTaxiUnbilledTransferredOrderListingFilterType.PASSENGER
      )?.value as
        | BillingsTaxiUnbilledTransferredOrderListingPassengerFilter["value"]
        | undefined,
      routeAddress: filters.find(
        (filter) =>
          filter.type ===
          BillingsTaxiUnbilledTransferredOrderListingFilterType.ROUTE_ADDRESS
      )?.value as
        | BillingsTaxiUnbilledTransferredOrderListingRouteAddressFilter["value"]
        | undefined,
      routeDestinationAddress: filters.find(
        (filter) =>
          filter.type ===
          BillingsTaxiUnbilledTransferredOrderListingFilterType.ROUTE_DESTINATION_ADDRESS
      )?.value as
        | BillingsTaxiUnbilledTransferredOrderListingRouteDestinationAddressFilter["value"]
        | undefined,
      routeIntermediateAddress: filters.find(
        (filter) =>
          filter.type ===
          BillingsTaxiUnbilledTransferredOrderListingFilterType.ROUTE_INTERMEDIATE_ADDRESS
      )?.value as
        | BillingsTaxiUnbilledTransferredOrderListingRouteIntermediateAddressFilter["value"]
        | undefined,
      routePickupAddress: filters.find(
        (filter) =>
          filter.type ===
          BillingsTaxiUnbilledTransferredOrderListingFilterType.ROUTE_PICKUP_ADDRESS
      )?.value as
        | BillingsTaxiUnbilledTransferredOrderListingRoutePickupAddressFilter["value"]
        | undefined,
      startDateFrom: (
        filters.find(
          (filter) =>
            filter.type ===
            BillingsTaxiUnbilledTransferredOrderListingFilterType.START_DATE
        )?.value as
          | BillingsTaxiUnbilledTransferredOrderListingStartDateFilter["value"]
          | undefined
      )?.from?.toJSON(),
      startDateTo: (
        filters.find(
          (filter) =>
            filter.type ===
            BillingsTaxiUnbilledTransferredOrderListingFilterType.START_DATE
        )?.value as
          | BillingsTaxiUnbilledTransferredOrderListingStartDateFilter["value"]
          | undefined
      )?.to?.toJSON(),
      sort: sortKey ?? undefined,
      page,
      pageSize,
    };

  return routeQueryParams;
};

const getPage = (
  routeQueryFilterParams: BillingsTaxiUnbilledTransferredOrderListingRouteQueryParams
): number | undefined => {
  if (!validatePage(routeQueryFilterParams.page)) {
    return undefined;
  }

  return routeQueryFilterParams.page
    ? Number(routeQueryFilterParams.page)
    : undefined;
};

const getPageSize = (
  routeQueryFilterParams: BillingsTaxiUnbilledTransferredOrderListingRouteQueryParams
): number | undefined => {
  if (!validatePageSize(routeQueryFilterParams.pageSize)) {
    return undefined;
  }
  return routeQueryFilterParams.pageSize
    ? Number(routeQueryFilterParams.pageSize)
    : undefined;
};

const validateFilters = (
  routeQueryFilterParams: BillingsTaxiUnbilledTransferredOrderListingRouteQueryFilterParams
) => {
  const filterParams: BillingsTaxiUnbilledTransferredOrderListingRouteQueryFilterParams =
    {
      producerTaxi: routeQueryFilterParams.producerTaxi,
      consumerTaxi: routeQueryFilterParams.consumerTaxi,
      driver: routeQueryFilterParams.driver,
      externalOrderId: routeQueryFilterParams.externalOrderId,
      internalOrderId: routeQueryFilterParams.internalOrderId,
      passenger: routeQueryFilterParams.passenger,
      routeAddress: routeQueryFilterParams.routeAddress,
      routeDestinationAddress: routeQueryFilterParams.routeDestinationAddress,
      routeIntermediateAddress: routeQueryFilterParams.routeIntermediateAddress,
      routePickupAddress: routeQueryFilterParams.routePickupAddress,
      startDateFrom: routeQueryFilterParams.startDateFrom,
      startDateTo: routeQueryFilterParams.startDateTo,
    };

  const validationSchema =
    Joi.object<BillingsTaxiUnbilledTransferredOrderListingRouteQueryFilterParams>(
      {
        producerTaxi: Joi.string(),
        consumerTaxi: Joi.string(),
        driver: Joi.string(),
        externalOrderId: Joi.string(),
        internalOrderId: Joi.string(),
        passenger: Joi.string(),
        routeAddress: Joi.string(),
        routeDestinationAddress: Joi.string(),
        routeIntermediateAddress: Joi.string(),
        routePickupAddress: Joi.string(),
        startDateFrom: Joi.date(),
        startDateTo: Joi.date(),
      }
    );

  return !validationSchema.validate(filterParams).error?.message;
};

const validateSortKey = (
  routeQuerySortParam: BillingsTaxiUnbilledTransferredOrderListingRouteQueryParams["sort"]
): boolean => {
  const validationSchema = Joi.valid(
    ...Object.values(BillingsTaxiUnbilledTransferredOrderListingSortKey)
  );

  return !validationSchema.validate(routeQuerySortParam).error?.message;
};

const validatePage = (
  routeQueryPageParam: BillingsTaxiUnbilledTransferredOrderListingRouteQueryParams["page"]
) => {
  const validationSchema = Joi.number().min(1);

  return !validationSchema.validate(routeQueryPageParam).error?.message;
};

const validatePageSize = (
  routeQueryPageSizeParam: BillingsTaxiUnbilledTransferredOrderListingRouteQueryParams["pageSize"]
) => {
  const validationSchema = Joi.number().valid(...[50, 100, 200]);

  return !validationSchema.validate(routeQueryPageSizeParam).error?.message;
};

const billingsTaxiUnbilledTransferredOrderListingRouteQueryParamsService = {
  getFilters,
  getSortKey,
  getPage,
  getPageSize,
  createRouteQueryParams,
};

export default billingsTaxiUnbilledTransferredOrderListingRouteQueryParamsService;
