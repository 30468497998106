enum BillingsCargoOrderListingFilterType {
  ORDER_START_DATE = "ORDER_START_DATE",
  INTERNAL_ORDER_ID = "INTERNAL_ORDER_ID",
  ROUTE_ADDRESS = "ROUTE_ADDRESS",
  ROUTE_DESTINATION_ADDRESS = "ROUTE_DESTINATION_ADDRESS",
  ROUTE_INTERMEDIATE_ADDRESS = "ROUTE_INTERMEDIATE_ADDRESS",
  ROUTE_PICKUP_ADDRESS = "ROUTE_PICKUP_ADDRESS",
  PASSENGER = "PASSENGER",
  TAXI = "TAXI",
  EXTERNAL_ORDER_ID = "EXTERNAL_ORDER_ID",
  DISPATCH = "DISPATCH",
  BILLING_STATUS = "BILLING_STATUS",
}

export default BillingsCargoOrderListingFilterType;
