import { FC } from "react";
import billingsTaxiUnbilledPrivateOrderListingFiltersBadgeListHelper from "./billings-taxi-unbilled-private-order-listing-filters-badge-list.helper";
import ListingFilterBadgeListComponent from "../../../../../../../common/components/listing/filter/badge-list/listing-filter-badge-list.component";
import BillingsTaxiUnbilledPrivateOrderListingFilter from "../../types/billings-taxi-unbilled-private-order-listing-filter";

type BillingsTaxiUnbilledPrivateOrderListingFiltersListProps = {
  filters: BillingsTaxiUnbilledPrivateOrderListingFilter[];
  onDeleteFilterClick: (filterIndex: number) => void;
  onDeleteAllFiltersButtonClick: () => void;
};

const BillingsTaxiUnbilledPrivateOrderListingFiltersBadgeListComponent: FC<
  BillingsTaxiUnbilledPrivateOrderListingFiltersListProps
> = (props) => {
  const badges =
    billingsTaxiUnbilledPrivateOrderListingFiltersBadgeListHelper.getBadges(
      props.filters
    );

  return (
    <ListingFilterBadgeListComponent
      badges={badges}
      onDeleteAll={props.onDeleteAllFiltersButtonClick}
      onDelete={props.onDeleteFilterClick}
    />
  );
};

export default BillingsTaxiUnbilledPrivateOrderListingFiltersBadgeListComponent;
