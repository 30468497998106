import { SearchAddressResponseItem } from "../../../../../common/utils/search-address/search-address.response";
import RoutesWaypointAddressAddFormAddressSelected from "./types/routes-waypoint-address-add-form-address-selected";
import RoutesWaypointAddressAddFormAddressData from "./types/routes-waypoint-address-add-form-adress-data";
import RoutesWaypointAddressAddFormData from "./types/routes-waypoint-address-add-form-data";
import RoutesWaypointAddressAddFormSearchOption from "./types/routes-waypoint-address-add-form-search-option";
import RoutesWaypointAddressAddFormSearchSelectOption from "./types/routes-waypoint-address-add-form-search-select-option";

const createSearchAddressOption = (
  searchAddressResponseItem: SearchAddressResponseItem
): RoutesWaypointAddressAddFormSearchOption => {
  return {
    displayName: searchAddressResponseItem.display_name,
    apartmentNumber: "",
    country: searchAddressResponseItem.address.country,
    countryCode: searchAddressResponseItem.address.country_code,
    houseNumber: searchAddressResponseItem.address.house_number ?? null,
    latitude: Number(searchAddressResponseItem.lat),
    longitude: Number(searchAddressResponseItem.lon),
    street: searchAddressResponseItem.address.road ?? null,
    town:
      searchAddressResponseItem.address.city ??
      searchAddressResponseItem.address.town ??
      searchAddressResponseItem.address.village ??
      null,
    zipCode: searchAddressResponseItem.address.postcode ?? null,
    description: searchAddressResponseItem.address.description,
  };
};

const createSearchAddressSelectOption = (
  searchAddressResponseItem: SearchAddressResponseItem
): RoutesWaypointAddressAddFormSearchSelectOption => {
  const address = createSearchAddressOption(searchAddressResponseItem);

  return {
    label: searchAddressResponseItem.display_name,
    value: address,
  };
};

const createAddressSelectOptions = (
  searchAddressResponseItems: SearchAddressResponseItem[]
): RoutesWaypointAddressAddFormSearchSelectOption[] => {
  return searchAddressResponseItems.map(createSearchAddressSelectOption);
};

const createAddress = (
  formData: RoutesWaypointAddressAddFormData
): RoutesWaypointAddressAddFormAddressData => {
  return {
    apartmentNumber: formData.apartmentNumber,
    houseNumber: formData.houseNumber,
    latitude: formData.foundAddress?.value.latitude!,
    longitude: formData.foundAddress?.value.longitude!,
    street: formData.street,
    town: formData.town,
    zipCode: formData.zipCode,
    description: formData.description,
  };
};

const createFoundAddress = (
  value: RoutesWaypointAddressAddFormAddressSelected
) => {
  return {
    label: value.name,
    value: value,
  };
};

const createSelectedAddress = (
  value: RoutesWaypointAddressAddFormAddressSelected
) => {
  return {
    foundAddress: createFoundAddress(value),
    type: null,
    street: value.street ?? "",
    houseNumber: value.houseNumber ?? "",
    apartmentNumber: value.apartmentNumber ?? "",
    zipCode: value.zipCode ?? "",
    country: value.country ?? "",
    town: value.town ?? "",
    description: value.description ?? "",
  };
};

const addressAddModalFactory = {
  createSearchAddressOption,
  createAddressSelectOptions,
  createAddress,
  createFoundAddress,
  createSelectedAddress,
};

export default addressAddModalFactory;
