import BillingsTaxiUnbilledOrderListingItem, {
  BillingsTaxiUnbilledOrderListingItemMissingBillingType,
} from "../../common/types/billings-taxi-unbilled-order-listing-item";
import BillingsTaxiUnbilledOrderListingItemStatus from "../../common/types/billings-taxi-unbilled-order-listing-item-status";
import {
  BillingsTaxiUnbilledOrderListingByTaxiResponseDataItem,
  BillingsTaxiUnbilledOrderListingByTaxiResponseDataItemMissingBillings,
} from "./api/billings-taxi-unbilled-order-listing-by-taxi.response";

const createListingItemStatus = (
  responseDataItem: BillingsTaxiUnbilledOrderListingByTaxiResponseDataItem
): BillingsTaxiUnbilledOrderListingItemStatus => {
  if (responseDataItem.is_cancelled) {
    return BillingsTaxiUnbilledOrderListingItemStatus.CANCELLED;
  }

  return BillingsTaxiUnbilledOrderListingItemStatus.FINISHED;
};

const createListingItemMissingBillingTypes = (
  responseItemMissingBillings: BillingsTaxiUnbilledOrderListingByTaxiResponseDataItemMissingBillings[]
): BillingsTaxiUnbilledOrderListingItemMissingBillingType[] => {
  const missingBillingTypes: BillingsTaxiUnbilledOrderListingItemMissingBillingType[] =
    [];

  if (
    responseItemMissingBillings.includes(
      BillingsTaxiUnbilledOrderListingByTaxiResponseDataItemMissingBillings.CARGO_TAXI
    )
  ) {
    missingBillingTypes.push(
      BillingsTaxiUnbilledOrderListingItemMissingBillingType.CARGO_WITH_TAXI
    );
  }

  return missingBillingTypes;
};

const createListingItem = (
  responseDataItem: BillingsTaxiUnbilledOrderListingByTaxiResponseDataItem
): BillingsTaxiUnbilledOrderListingItem => {
  return {
    clientName: responseDataItem.client.name,
    dispatchName: responseDataItem.dispatch,
    driversDisplayNames: responseDataItem.drivers,
    externalOrderId: responseDataItem.external_id,
    internalOrderId: responseDataItem.human_id,
    passengers: responseDataItem.passengers,
    routeAddresses: responseDataItem.ride_addresses,
    startDate: new Date(responseDataItem.start_time),
    status: createListingItemStatus(responseDataItem),
    taxiCorporations: responseDataItem.taxi_corporations,
    uuid: responseDataItem.id,
    missingBillingTypes: createListingItemMissingBillingTypes(
      responseDataItem.missing_taxi_billings
    ),
  };
};

const createListingItems = (
  responseDataItems: BillingsTaxiUnbilledOrderListingByTaxiResponseDataItem[]
): BillingsTaxiUnbilledOrderListingItem[] => {
  return responseDataItems.map(createListingItem);
};

const billingsTaxiUnbilledOrderListingByTaxiFactory = {
  createListingItems,
};

export default billingsTaxiUnbilledOrderListingByTaxiFactory;
