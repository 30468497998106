import SearchRoadRoutingRequest from "../../../utils/search-road-route/search-road-routing.request";
import SearchRoadRoutingResponse from "../../../utils/search-road-route/search-road-routing.response";
import searchRoadRoutingService from "../../../utils/search-road-route/search-road-routing.service";
import railyApiService from "../raily-api.service";
import PlanEntryDetailsRequest from "./details/plan-entry-details.request";
import PlanEntryDetailsResponse from "./details/plan-entry-details.response";

const planEntryApiService = () => {
  const getPlanEntryDetails = (
    request: PlanEntryDetailsRequest,
    abortSignal: AbortSignal
  ): Promise<PlanEntryDetailsResponse> => {
    const url = `/plan-entries/${request.planEntryUuid}`;

    return railyApiService().get<PlanEntryDetailsResponse>({
      url,
      abortSignal,
    });
  };

  const getRoute = (
    request: SearchRoadRoutingRequest
  ): Promise<SearchRoadRoutingResponse> => {
    return searchRoadRoutingService.search(request);
  };

  return { getPlanEntryDetails, getRoute };
};

export default planEntryApiService;
